export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS';
export const MARK_ALL_AS_READ_FAILURE = 'MARK_ALL_AS_READ_FAILURE';

export const markAllAsRead = () => ({
    type: MARK_ALL_AS_READ,
});

export const markAllAsReadSuccess = (data) => ({
    type: MARK_ALL_AS_READ_SUCCESS,
    payload: { data }
});

export const markAllAsReadFailure = (message) => ({
    type: MARK_ALL_AS_READ_FAILURE,
    payload: { message }
});