export const GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS = 'GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS';
export const GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_FAILURE';

export const getTicketOptionsInCompanyReservations = (reservationTypeId) => ({
    type: GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS,
    reservationTypeId
}); 

export const getTicketOptionsInCompanyReservationsSuccess = (ticketOptions) => ({
    type: GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { ticketOptions }
});

export const getTicketOptionsInCompanyReservationsFailure = (message) => ({
    type: GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});