export const GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES = 'GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES';
export const GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_SUCCESS = 'GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_SUCCESS';
export const GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_FAILURE = 'GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_FAILURE';

export const getSchoolTourRanges = (pageNumber=1,pageSize=25,search=null) => {
return({
    type: GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES,
    pageNumber,
    pageSize,
    search
}); 
}
export const getSchoolTourRangesSuccess = (schoolTourRanges,pageNumber,pageSize) => ({
    type: GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_SUCCESS,
    payload: { schoolTourRanges ,pageNumber,pageSize}
});

export const getSchoolTourRangesFailure = (message) => ({
    type: GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_FAILURE,
    payload: { message }
});