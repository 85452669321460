export const GET_REQUESTS_IN_RESERVATIONS = 'GET_REQUESTS_IN_RESERVATIONS';
export const GET_REQUESTS_IN_RESERVATIONS_SUCCESS = 'GET_REQUESTS_IN_RESERVATIONS_SUCCESS';
export const GET_REQUESTS_IN_RESERVATIONS_FAILURE = 'GET_REQUESTS_IN_RESERVATIONS_FAILURE';

export const getRequestsInReservations = (pageNumber = 1, pageSize = 10,search = null,selectedDate=null) => ({
    type: GET_REQUESTS_IN_RESERVATIONS,
    pageNumber,
    pageSize,
    search,
    selectedDate
});

export const getRequestsInReservationsSuccess = (requests, pageNumber, pageSize) => ({
    type: GET_REQUESTS_IN_RESERVATIONS_SUCCESS,
    payload: { requests, pageNumber, pageSize }
});

export const getRequestsInReservationsFailure = (message) => ({
    type: GET_REQUESTS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});