export const CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES =
    'CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES';
export const CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_SUCCESS =
    'CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_SUCCESS';
export const CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_FAILURE =
    'CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_FAILURE';

export const checkRoleNameEnAvailabilityInRoles = (nameEn, roleId = null) => ({
    type: CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES,
    nameEn,
    roleId
});

export const checkRoleNameEnAvailabilityInRolesSuccess = (isAvailable) => ({
    type: CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_SUCCESS,
    payload: { isAvailable }
});

export const checkRoleNameEnAvailabilityInRolesFailure = (message) => ({
    type: CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_FAILURE,
    payload: message
});