import {
    GET_SCREENS_IN_ROLE_SCREENS,
    GET_SCREENS_IN_ROLE_SCREENS_SUCCESS,
    GET_SCREENS_IN_ROLE_SCREENS_FAILURE
} from '../../../actions/Roles/RoleScreens/getScreensInRoleScreensActions';

export function getScreensInRoleScreensReducer(state, action) {
    switch (action.type) {
        case GET_SCREENS_IN_ROLE_SCREENS:
            return {
                ...state,
                isGetScreensInRoleScreens: true,
                getScreensInRoleScreensError: null,
            };
        case GET_SCREENS_IN_ROLE_SCREENS_SUCCESS:
            return {
                ...state,
                isGetScreensInRoleScreens: false,
                getScreensInRoleScreensError: null,
                screensInRoleScreens: action.payload.screens,
                screenInRoleScreens:state.editedRoleScreenInRoleScreens !== null ? action.payload.screens.filter(x=> x.value === state.editedRoleScreenInRoleScreens.screenId)[0] : null
            };
        case GET_SCREENS_IN_ROLE_SCREENS_FAILURE:
            return {
                ...state,
                isGetScreensInRoleScreens: false,
                getScreensInRoleScreensError: action.payload.message,
            };
        default:
            return state;
}}