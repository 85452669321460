import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS,
    updateSelectedDateInCompanyReservationsSuccess,
    updateSelectedDateInCompanyReservationsFailure
} from '../../actions/CompanyReservations/updateSelectedDateInCompanyReservationsActions';

import {
    getCompanyReservationTimesInCompanyReservations
} from '../../actions/CompanyReservations/getCompanyReservationTimesInCompanyReservationsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function updateSelectedDateInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS),
        map(action => ({
            companyReservationTypeSettingId: action.companyReservationTypeSettingId,
            selectedDate: action.selectedDate
        })),
        mergeMap(response => {
            return of(updateSelectedDateInCompanyReservationsSuccess(response.selectedDate), getCompanyReservationTimesInCompanyReservations(response.companyReservationTypeSettingId, response.selectedDate))
        }),
        catchError(error => of(updateSelectedDateInCompanyReservationsFailure
            (strings.somethingWrongHappened)))
    )
}