export const ADD_NEW_RESERVATION_TYPE = 'ADD_NEW_RESERVATION_TYPE';
export const ADD_NEW_RESERVATION_TYPE_SUCCESS = 'ADD_NEW_RESERVATION_TYPE_SUCCESS';
export const ADD_NEW_RESERVATION_TYPE_FAILURE = 'ADD_NEW_RESERVATION_TYPE_FAILURE';

export const addNewReservationType = (reservationTypeData) => ({
    type: ADD_NEW_RESERVATION_TYPE,
    reservationTypeData
});

export const addNewReservationTypeSuccess = (data) => ({
    type: ADD_NEW_RESERVATION_TYPE_SUCCESS,
    payload: { data }
});

export const addNewReservationTypeFailure = (message) => ({
    type: ADD_NEW_RESERVATION_TYPE_FAILURE,
    payload: { message }
});