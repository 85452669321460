import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInSchoolTourRangesConstant } from "../../constants/SchoolTourRanges/DisplayTypesInSchoolTourRangesConstant";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Loading from "../Shared/Loading/Loading";

function SchoolTourRange(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getSchoolTourRanges(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getSchoolTourRanges();
  }, []);

  useEffect(() => {
    if (
      props.deleteSchoolTourRangeResult !== null &&
      props.deleteSchoolTourRangeResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteSchoolTourRangeResult.message, "success").then(result => {
        props.getSchoolTourRanges(props.schoolTourRangesPageNumber, props.schoolTourRangesPageSize, search);
      });
    }
  }, [props.deleteSchoolTourRangeResult]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.schoolTourRangesPageNumber) {
      props.getSchoolTourRanges(pageNumber, props.schoolTourRangesPageSize, search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getSchoolTourRanges(1, props.schoolTourRangesPageSize, selected.target.value)
  }
  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetSchoolTourRangesInSchoolTourRanges && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length, { label: strings.all, value: props.schoolTourRangesTotalCount }]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.schoolTourRangesPageSize)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.schoolTourRangesTotalCount}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-0">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                  <th className="min-w-125px px-0">{strings.countFrom}</th>
                  <th className="min-w-125px px-0">{strings.countTo}</th>
                  <th className="min-w-125px px-0">{strings.price}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                    {strings.action}
                  </th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {props.schoolTourRanges !== [] &&
                  props.schoolTourRanges.length > 0 &&
                  props.schoolTourRanges.map((schoolTourRange, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{index + 1}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{schoolTourRange.countFrom}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{schoolTourRange.countTo}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{schoolTourRange.price}</h4>
                            </div>
                          </div>
                        </td>
                        <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                          <div className="d-flex justify-content-center">
                            <div
                              className="actions"
                              style={{ backgroundColor: "#e4e6ef" }}
                            >
                              <div className="action1">
                                <a onClick={() => {
                                  props.getEditedSchoolTourRange(schoolTourRange.id);
                                  props.updateDisplayTypeInSchoolTourRanges(
                                    DisplayTypesInSchoolTourRangesConstant.EDIT
                                  )
                                }}>

                                  <i className="fas fa-edit edit"></i>
                                </a>

                              </div>
                              <div className="action2">
                                <a
                                  onClick={() => { Delete(props, schoolTourRange.id) }}>
                                  <i className="fas fa-trash delete"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="card-footer">
            {
              props.schoolTourRangesTotalCount !== null
              && props.schoolTourRangesTotalCount > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.schoolTourRangesPageNumber - 1) * props.schoolTourRangesPageSize) + 1} {strings.to} {(props.schoolTourRangesPageNumber * props.schoolTourRangesPageSize) > props.schoolTourRangesTotalCount ? props.schoolTourRangesTotalCount : (props.schoolTourRangesPageNumber * props.schoolTourRangesPageSize)} {strings.of} {props.schoolTourRangesTotalCount} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.schoolTourRangesPageNumber}
                    itemsCountPerPage={props.schoolTourRangesPageSize}
                    totalItemsCount={props.schoolTourRangesTotalCount}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
export default SchoolTourRange;
