import {
    GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS,
    GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getUnpaidReservationsInReservationsActions';

export function getUnpaidReservationsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS:
            return {
                ...state,
                isGetUnpaidReservationsInReservations: true,
                getUnpaidReservationsInReservationsError: null,
                unpaidReservationsInReservations: [],
            };
        case GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetUnpaidReservationsInReservations: false,
                getUnpaidReservationsInReservationsError: null,
                unpaidReservationsInReservations: action.payload.unpaidReservations.data,
                unpaidReservationsPageNumberInReservations: action.payload.pageNumber,
                unpaidReservationsPageSizeInReservations: action.payload.pageSize,
                unpaidReservationsTotalCountInReservations: action.payload.unpaidReservations.total,
            };
        case GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetUnpaidReservationsInReservations: false,
                getUnpaidReservationsInReservationsError: action.payload.message,
            };
        default:
            return state;
}}