import {
    DELETE_CANCELLED_RESERVATION,
    DELETE_CANCELLED_RESERVATION_SUCCESS,
    DELETE_CANCELLED_RESERVATION_FAILURE
} from '../../actions/Reservations/deleteCancelledReservationActions';

export function deleteCancelledReservationReducer(state, action) {
    switch (action.type) {
        case DELETE_CANCELLED_RESERVATION:
            return {
                ...state,
                isDeletingCancelledReservation: true,
                deleteCancelledReservationError: null,
            };
        case DELETE_CANCELLED_RESERVATION_SUCCESS:
            return {
                ...state,
                isDeletingCancelledReservation: false,
                deleteCancelledReservationError: null,
                deleteCancelledReservationResult: action.payload.data,
                
            };
        case DELETE_CANCELLED_RESERVATION_FAILURE:
            return {
                ...state,
                isDeletingCancelledReservation: false,
                deleteCancelledReservationError: action.payload.message,
            };
        default:
            return state;
}}