import {
    DELETE_RESERVATION_SCHEDULE,
    DELETE_RESERVATION_SCHEDULE_SUCCESS,
    DELETE_RESERVATION_SCHEDULE_FAILURE
} from '../../actions/ReservationSchedules/deleteReservationScheduleActions';

import { DisplayTypesInReservationSchedulesConstant } from '../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant';

export function deleteReservationScheduleReducer(state, action) {
    switch (action.type) {
        case DELETE_RESERVATION_SCHEDULE:
            return {
                ...state,
                isDeletingReservationSchedule: true,
                deleteReservationScheduleError: null,
            };
        case DELETE_RESERVATION_SCHEDULE_SUCCESS:
            return {
                ...state,
                isDeletingReservationSchedule: false,
                deleteReservationScheduleError: null,
                deleteReservationScheduleResult: action.payload.data,
                //displayTypeInReservationSchedules: DisplayTypesInReservationSchedulesConstant.LIST,
            };
        case DELETE_RESERVATION_SCHEDULE_FAILURE:
            return {
                ...state,
                isDeletingReservationSchedule: false,
                deleteReservationScheduleError: action.payload.message,
            };
        default:
            return state;
}}