import {
    UPDATE_DISPLAY_TYPE_IN_NEWS,
    UPDATE_DISPLAY_TYPE_IN_NEWS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_NEWS_FAILURE
} from '../../../src/actions/News/updateDisplayTypeInNewsActions';

export function updateDisplayTypeInNewsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_NEWS:
            return {
                ...state,
                isUpdatingDisplayTypeInNews: true,
                updateDisplayTypeErrorInNews: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_NEWS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInNews: false,
                displayTypeInNews: action.payload.displayType,
                updateDisplayTypeErrorInNews: null,

                isEditingNew: false,
                editNewError: null,
                editNewResult:null,

                isDeletingNew: false,
                deleteNewError: null,
                deleteNewResult:null,

                isAddNewNew: false,
                addNewNewError: null,
                addNewNewResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_NEWS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInNews: false,
                updateDisplayTypeErrorInNews: action.payload
            };
        default:
            return state;
    }
}