export const GET_EDITED_SCHOOL_TOUR_RANGE = 'GET_EDITED_SCHOOL_TOUR_RANGE';
export const GET_EDITED_SCHOOL_TOUR_RANGE_SUCCESS = 'GET_EDITED_SCHOOL_TOUR_RANGE_SUCCESS';
export const GET_EDITED_SCHOOL_TOUR_RANGE_FAILURE = 'GET_EDITED_SCHOOL_TOUR_RANGE_FAILURE';

export const getEditedSchoolTourRange = (schoolTourRangeId) => {
    return ({
    type: GET_EDITED_SCHOOL_TOUR_RANGE,
    schoolTourRangeId
});
}
export const getEditedSchoolTourRangeSuccess = (schoolTourRangeId) => {
    return ({
    type: GET_EDITED_SCHOOL_TOUR_RANGE_SUCCESS,
    payload: { schoolTourRangeId }
});
}
export const getEditedSchoolTourRangeFailure = (message) => {
    return ({
    type: GET_EDITED_SCHOOL_TOUR_RANGE_FAILURE,
    payload: { message }
});
}