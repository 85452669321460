import {
    GET_USERS_IN_USERS,
    GET_USERS_IN_USERS_SUCCESS,
    GET_USERS_IN_USERS_FAILURE
} from '../../actions/Users/getUsersInUsersActions';

export function getUsersInUsersReducer(state, action) {
    switch (action.type) {
        case GET_USERS_IN_USERS:
            return {
                ...state,
                isGetUsersInUsers: true,
                getUsersInUsersError: null,
            };
        case GET_USERS_IN_USERS_SUCCESS:
            console.log(action.payload.users)
            return {
                ...state,
                isGetUsersInUsers: false,
                getUsersInUsersError: null,
                usersInUsers: action.payload.users.data,
                usersPageNumberInUsers: action.payload.pageNumber,
                usersPageSizeInUsers: action.payload.pageSize,
                usersTotalCountInUsers: action.payload.users.total,
            };
        case GET_USERS_IN_USERS_FAILURE:
            return {
                ...state,
                isGetUsersInUsers: false,
                getUsersInUsersError: action.payload.message,
            };
        default:
            return state;
}}