import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_FAILURE
} from '../../../src/actions/ReservationTypes/updateDisplayTypeInReservationTypesActions';

export function updateDisplayTypeInReservationTypesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypes: true,
                updateDisplayTypeErrorInReservationTypes: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypes: false,
                displayTypeInReservationTypes: action.payload.displayType,
                updateDisplayTypeErrorInReservationTypes: null,

                isEditingReservationType: false,
                editReservationTypeError: null,
                editReservationTypeResult:null,

                isDeletingReservationType: false,
                deleteReservationTypeError: null,
                deleteReservationTypeResult:null,

                isAddNewReservationType: false,
                addNewReservationTypeError: null,
                addNewReservationTypeResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypes: false,
                updateDisplayTypeErrorInReservationTypes: action.payload
            };
        default:
            return state;
    }
}