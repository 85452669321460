export const DELETE_RESERVATION_TYPE = 'DELETE_RESERVATION_TYPE';
export const DELETE_RESERVATION_TYPE_SUCCESS = 'DELETE_RESERVATION_TYPE_SUCCESS';
export const DELETE_RESERVATION_TYPE_FAILURE = 'DELETE_RESERVATION_TYPE_FAILURE';

export const deleteReservationType = (reservationTypeId) => ({
    type: DELETE_RESERVATION_TYPE,
    reservationTypeId
});

export const deleteReservationTypeSuccess = (data) => ({
    type: DELETE_RESERVATION_TYPE_SUCCESS,
    payload: { data }
});

export const deleteReservationTypeFailure = (message) => ({
    type: DELETE_RESERVATION_TYPE_FAILURE,
    payload: { message }
});