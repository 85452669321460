import {
    UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS,
    UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateDisplayTypeInCancelledReservationsActions';

export function updateDisplayTypeInCancelledReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInCancelledReservations: true,
                updateDisplayTypeErrorInCancelledReservations: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInCancelledReservations: false,
                displayTypeInCancelledReservations: action.payload.displayType,
                updateDisplayTypeErrorInCancelledReservations: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInCancelledReservations: false,
                updateDisplayTypeErrorInCancelledReservations: action.payload
            };
        default:
            return state;
    }
}