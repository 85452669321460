export const EDIT_RESERVATION_SCHEDULE = 'EDIT_RESERVATION_SCHEDULE';
export const EDIT_RESERVATION_SCHEDULE_SUCCESS = 'EDIT_RESERVATION_SCHEDULE_SUCCESS';
export const EDIT_RESERVATION_SCHEDULE_FAILURE = 'EDIT_RESERVATION_SCHEDULE_FAILURE';

export const editReservationSchedule = (reservationScheduleData) => ({
    type: EDIT_RESERVATION_SCHEDULE,
    reservationScheduleData
});

export const editReservationScheduleSuccess = (data) => ({
    type: EDIT_RESERVATION_SCHEDULE_SUCCESS,
    payload: { data }
});

export const editReservationScheduleFailure = (message) => ({
    type: EDIT_RESERVATION_SCHEDULE_FAILURE,
    payload: { message }
});