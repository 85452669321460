export const GET_EDITED_ROLE_SCREEN = 'GET_EDITED_ROLE_SCREEN';
export const GET_EDITED_ROLE_SCREEN_SUCCESS = 'GET_EDITED_ROLE_SCREEN_SUCCESS';
export const GET_EDITED_ROLE_SCREEN_FAILURE = 'GET_EDITED_ROLE_SCREEN_FAILURE';

export const getEditedRoleScreen = (roleScreenId) => ({
    type: GET_EDITED_ROLE_SCREEN,
    roleScreenId
});

export const getEditedRoleScreenSuccess = (roleScreenId) => ({
    type: GET_EDITED_ROLE_SCREEN_SUCCESS,
    payload: { roleScreenId }
});

export const getEditedRoleScreenFailure = (message) => ({
    type: GET_EDITED_ROLE_SCREEN_FAILURE,
    payload: { message }
});