import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_REPORTS,
    updateDisplayTypeInReportsSuccess,
    updateDisplayTypeInReportsFailure
} from '../../../src/actions/Reports/updateDisplayTypeInReportsActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInReportsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_REPORTS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInReportsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInReportsFailure(strings.somethingWrongHappened)))
    )
}

