import React, { useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import logo from '../../../images/white.png';
import strings from "../../localizations/strings";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import $ from "jquery";
//#4995d1 blue color
//#eb6618 orange color
import Switch from "react-switch";
import { Link } from 'react-router-dom';
import logo1 from "../../images/ic_logo.png";
import Language from "./Lan/Language";
import Notification from "./Notifications/Notification";
import { HideSection, ShowSection } from "./Fun/HideAndShowSection";

function Topnav(props) {

    useEffect(() => {
        //Language
        ShowSection("languageMenu", "languageIcon");
        HideSection("languageMenu", "languageIcon");

        //End Language
    }, [])

    useEffect(() => {
        if (props.isUserLoggedIn) {
            //Notificatio
            ShowSection("NotificatioContent", "NotificatioIcon");
            HideSection("NotificatioContent", "NotificatioIcon");

            //End Notificatio
        }
    }, [props.isUserLoggedIn])

    const handleredirect = () => {
        localStorage.removeItem("AquaContext");
        window.location.href = `/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/home`;
    }

    return (
        <>
            <div id="kt_header" className="bg-image py-0 header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{ animationDuration: "0.3s" }}>
                <div className="container-fluid d-flex align-items-center">
                    <div id="Menu" className="mx-2" title="Show header menu">
                        {!props.show ? (
                            <div className="cursor-pointer d-md-none" onClick={props.handletoggle}>
                                <span className="svg-icon svg-icon-1"  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                        <path
                                            d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                                            fill="black"
                                        ></path>
                                        <path
                                            opacity="0.3"
                                            d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                                            fill="black"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        ) : (
                            <div className="cursor-pointer d-md-none" onClick={props.close}>
                                {/* <span className="svg-icon svg-icon-1" >
                                        <i className="fas fa-times "></i>
                                    </span> */}
                            </div>
                        )}
                    </div>
                    <div className="header-logo me-5 me-md-30 flex-grow-1 flex-lg-grow-0">
                        <Link to={`/${strings.getLanguage()}/home`}>
                            <img alt="Logo" src={logo1} className="logo-default h-65px" />
                            {/* <img alt="Logo" src={logo1} className="logo-sticky h-65px"/> */}
                        </Link>
                    </div>
                    <div className="d-flex align-items-end flex-row-reverse justify-content-between flex-lg-grow-1"
                    style={{ paddingRight: "5rem", paddingLeft: "5rem" }}
                    >

                        <div className="topbar d-flex align-items-stretch flex-shrink-0">
                            {
                                props.isUserLoggedIn
                                && (
                                    <div className="d-flex align-items-center ms-1 ms-lg-3 position-relative">
                                        <div id="NotificatioIcon" className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px" >

                                            {/* NotificatioIcon unread number */}
                                            {
                                                props.unReadNotificationsUserCount > 0
                                                && (
                                                    <span className="noti-num">
                                                        {props.unReadNotificationsUserCount}
                                                    </span>
                                                )
                                            }

                                            {/* end NotificatioIcon unread number */}

                                            <span className="svg-icon svg-icon-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z"
                                                        fill="currentColor"

                                                    ></path>
                                                    <path
                                                        d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z"
                                                        fill="currentColor"

                                                    ></path>
                                                    <path
                                                        opacity="0.3"
                                                        d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z"
                                                        fill="currentColor"

                                                    ></path>
                                                    <path
                                                        opacity="0.3"
                                                        d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z"
                                                        fill="currentColor"

                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>

                                        <Notification {...props} />
                                    </div>
                                )}
                            <div className="d-flex align-items-stretch ms-1 ms-lg-3 position-relative">
                                <div id="languageIcon" className="btn btn-icon btn-icon-lan btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" >
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" ></rect>
                                            <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                            <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </div>
                                <Language {...props} />
                            </div>
                            <div className="d-flex flex-stack mx-2">
                                {
                                    props.isUserLoggedIn
                                    && (
                                        <button type="button" onClick={handleredirect} className="btn btn-primary bg-transparent">{strings.logout}</button>
                                    )
                                }
                                {
                                    !props.isUserLoggedIn
                                    && (
                                        <Link to={`/${strings.getLanguage() == LanguagesConstant.EN ? "en" : "ar"}/login`}>
                                            <button type="button" className="btn btn-primary bg-transparent">{strings.login}</button>
                                        </Link>
                                    )
                                }
                                {/* <h4 className="text-white fw-bolder fs-3 mt-1"></h4> */}
                            </div>
                            {/* <div className="d-flex align-items-center me-n3 ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
         
         <div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px show menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
             <img className="h-30px w-30px rounded" src={avatar} alt=""/>
         </div>
         
         <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"data-kt-menu="true">
             
             <div className="menu-item px-3">
                 <div className="menu-content d-flex align-items-center px-3">
                     
                     <div className="symbol symbol-50px me-5">
                         <img alt="Logo" src="/metronic8/demo2/assets/media/avatars/300-2.jpg"/>
                     </div>
                    
                     <div className="d-flex flex-column">
                         <div className="fw-bolder d-flex align-items-center fs-5">Max Smith 
                         <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span></div>
                         <a href="#" className="fw-bold text-muted text-hover-primary fs-7">max@kt.com</a>
                     </div>
                     
                 </div>
             </div>
             
             
             <div className="separator my-2"></div>
             
             
             <div className="menu-item px-5">
                 <a href="/metronic8/demo2/../demo2/account/overview.html" className="menu-link px-5">My Profile</a>
             </div>
             
             
             <div className="menu-item px-5">
                 <a href="/metronic8/demo2/../demo2/apps/projects/list.html" className="menu-link px-5">
                     <span className="menu-text">My Projects</span>
                     <span className="menu-badge">
                         <span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
                     </span>
                 </a>
             </div>
             
             
             <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                 <a href="#" className="menu-link px-5">
                     <span className="menu-title">My Subscription</span>
                     <span className="menu-arrow"></span>
                 </a>
                 
                 <div className="menu-sub menu-sub-dropdown w-175px py-4">
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/referrals.html" className="menu-link px-5">Referrals</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/billing.html" className="menu-link px-5">Billing</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/statements.html" className="menu-link px-5">Payments</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/statements.html" className="menu-link d-flex flex-stack px-5">Statements 
                         <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="View your statements" aria-label="View your statements"></i></a>
                     </div>
                     
                     
                     <div className="separator my-2"></div>
                     
                     
                     <div className="menu-item px-3">
                         <div className="menu-content px-3">
                             <label className="form-check form-switch form-check-custom form-check-solid">
                                 <input className="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications"/>
                                 <span className="form-check-label text-muted fs-7">Notifications</span>
                             </label>
                         </div>
                     </div>
                     
                 </div>
                 
             </div>
             
             
             <div className="menu-item px-5">
                 <a href="/metronic8/demo2/../demo2/account/statements.html" className="menu-link px-5">My Statements</a>
             </div>
             
             
             <div className="separator my-2"></div>
             
             
             <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                 <a href="#" className="menu-link px-5">
                     <span className="menu-title position-relative">Language 
                     <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English 
                     <img className="w-15px h-15px rounded-1 ms-2" src="/metronic8/demo2/assets/media/flags/united-states.svg" alt=""/></span></span>
                 </a>
                 
                 <div className="menu-sub menu-sub-dropdown w-175px py-4">
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link d-flex px-5 active">
                         <span className="symbol symbol-20px me-4">
                             <img className="rounded-1" src="/metronic8/demo2/assets/media/flags/united-states.svg" alt=""/>
                         </span>English</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link d-flex px-5">
                         <span className="symbol symbol-20px me-4">
                             <img className="rounded-1" src="/metronic8/demo2/assets/media/flags/spain.svg" alt=""/>
                         </span>Spanish</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link d-flex px-5">
                         <span className="symbol symbol-20px me-4">
                             <img className="rounded-1" src="/metronic8/demo2/assets/media/flags/germany.svg" alt=""/>
                         </span>German</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link d-flex px-5">
                         <span className="symbol symbol-20px me-4">
                             <img className="rounded-1" src="/metronic8/demo2/assets/media/flags/japan.svg" alt=""/>
                         </span>Japanese</a>
                     </div>
                     
                     
                     <div className="menu-item px-3">
                         <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link d-flex px-5">
                         <span className="symbol symbol-20px me-4">
                             <img className="rounded-1" src="/metronic8/demo2/assets/media/flags/france.svg" alt=""/>
                         </span>French</a>
                     </div>
                     
                 </div>
                 
             </div>
             
             
             <div className="menu-item px-5 my-1">
                 <a href="/metronic8/demo2/../demo2/account/settings.html" className="menu-link px-5">Account Settings</a>
             </div>
             
             
             <div className="menu-item px-5">
                 <a href="/metronic8/demo2/../demo2/authentication/flows/basic/sign-in.html" className="menu-link px-5">Sign Out</a>
             </div>
             
             
             <div className="separator my-2"></div>
             
             
             <div className="menu-item px-5">
                 <div className="menu-content px-5">
                     <label className="form-check form-switch form-check-custom form-check-solid pulse pulse-success" for="kt_user_menu_dark_mode_toggle">
                         <input className="form-check-input w-30px h-20px" type="checkbox" value="1"
                          name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="/metronic8/demo2/../demo2/dark/index.html"/>
                                                            <span className="pulse-ring ms-n1"></span>
                                                            <span className="form-check-label text-gray-600 fs-7">Dark Mode</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            
                                        </div> */}
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Topnav;
