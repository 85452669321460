import {
    EDIT_ROLE,
    EDIT_ROLE_SUCCESS,
    EDIT_ROLE_FAILURE
} from '../../../actions/Roles/Roles/editRoleActions';

export function editRoleReducer(state, action) {
    switch (action.type) {
        case EDIT_ROLE:
            return {
                ...state,
                isEditingRole: true,
                editRoleError: null,
            };
        case EDIT_ROLE_SUCCESS:
            return {
                ...state,
                isEditingRole: false,
                editRoleError: null,
                editRoleResult: action.payload.data,
            };
        case EDIT_ROLE_FAILURE:
            return {
                ...state,
                isEditingRole: false,
                editRoleError: action.payload.message,
            };
        default:
            return state;
}}