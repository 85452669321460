import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_DISPLAY_TYPE_IN_ROLES,
    updateDisplayTypeInRolesSuccess,
    updateDisplayTypeInRolesFailure
} from '../../../actions/Roles/Roles/updateDisplayTypeInRolesActions';

import strings from '../../../localizations/strings';

export function updateDisplayTypeInRolesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_ROLES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>
            updateDisplayTypeInRolesSuccess(response.displayType)
        ),
        catchError(error => of(updateDisplayTypeInRolesFailure(strings.somethingWrongHappened)))
    )
}

