import {
    UPDATE_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATIONS_FAILURE
} from '../../actions/Notifications/updateNotificationsActions';


export function updateNotificationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATIONS:
            return {
                ...state,
                isUpdateNotifications: true,
                updateNotificationsError: null,
            };
        case UPDATE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isUpdateNotifications: false,
                updateNotificationsError: null,
                notificationsUser: [action.payload.data,...state.notificationsUser],
                unReadNotificationsUserCount:state.unReadNotificationsUserCount + 1,
            };
        case UPDATE_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isUpdateNotifications: false,
                updateNotificationsError: action.payload.message,
            };
        default:
            return state;
    }
}