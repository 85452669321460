import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES,
    updateDisplayTypeInReservationSchedulesSuccess,
    updateDisplayTypeInReservationSchedulesFailure
} from '../../../src/actions/ReservationSchedules/updateDisplayTypeInReservationSchedulesActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInReservationSchedulesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInReservationSchedulesSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInReservationSchedulesFailure(strings.somethingWrongHappened)))
    )
}

