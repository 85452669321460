import {
    UPDATE_RESERVATION_TYPES_IN_REQUESTS,
    UPDATE_RESERVATION_TYPES_IN_REQUESTS_SUCCESS,
    UPDATE_RESERVATION_TYPES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/updateReservationTypesInRequestsActions';

export function updateReservationTypesInRequestsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TYPES_IN_REQUESTS:
            return {
                ...state,
                isUpdateReservationTypesInRequests: true,
                updateReservationTypesInRequestsError: null,
            };
        case UPDATE_RESERVATION_TYPES_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isUpdateReservationTypesInRequests: false,
                updateReservationTypesInRequestsError: null,
                reservationTypeInRequests: action.payload.reservationType,
            };
        case UPDATE_RESERVATION_TYPES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isUpdateReservationTypesInRequests: false,
                updateReservationTypesInRequestsError: action.payload.message,
            };
        default:
            return state;
}}