const arabicStrings = {

  home: "الرئيسية",
  ourServices: "خدمتنا",
  youhealth: "صحتك",
  notifications: "الاشعارات",
  logout: "تسجيل الخروج",
  newAccount: "حساب جديد",
  county: "البلد",
  doctors: "الأطباء",
  doctor: "الطبيب",
  clinics: "العيادات",
  medicalCenter: "مركز طبي",
  medicalCenters: "مراكز طبية",
  hospitals: "مسشفيات",
  intensiveCare: "عنايات مركزة",
  incubators: "حضانات",
  laboratories: "معامل",
  radiologyCenters: "مراكز الأشعة",
  pharmacies: " صيدليات",
  all: "الكل",
  seachWithDrName: "ابحث باسم الدكتور",
  searchWithClinicName: "ابحث باسم العيادة",
  searchWithMedicalCenterName: " ابحث باسم المركز الطبى",
  searchWithHospitalName: "ابحث باسم المستشفى",
  searchWithIntensiveCareName: "ابحث باسم العناية المركزة",
  SearchWithIncubatorName: "ابحث باسم الحضانة",
  searchWithLaboratoryName: "ابحث باسم المعمل",
  searchWithRadiologyCenterName: "ابحث باسم مركز الأشعه",
  searchWithPharamcyName: "ابحث باسم الصيدلية",
  hideFilters: " إخفاء -",
  showFilters: " خيارات أخرى +",
  fields: "التخصصات",
  field: "التخصص",
  specialities: "التخصصات الفرعية",
  insuranceCompany: "شركات التأمين",
  title: "اللقب",
  governorate: "المحافظة",
  city: "المدينة",
  area: "المنطقة",
  fees: "سعر الكشف",
  institutionsName: "اسم المنشأة",
  intensiveCareType: "نوع العناية المركزه",
  dailyCost: "التكلفة اليومية",
  testCost: "تكلفة التحليل",
  medicalTestType: "نوع التحليل الطبى",
  medicalTestName: " اسم التحليل الطبى",
  radiationCost: "تكلفة الاشعه",
  medicalRadiationType: "نوع الاشعه الطبى",
  medicalRadiationName: " اسم الاشعه الطبى",
  iSearchFor: "ابحث عن دكتور",
  male: "ذكر",
  female: "أنثى",
  loremSM:
    "عندما يريد العالم أن ‪يتكلّم ‬ ، فهو يتحدّث بلغة يونيكود. تسجّل الآن لحضور المؤتمر الدولي",
  loremL:
    " عندما يريد العالم أن ‪يتكلّم ‬ ، فهو يتحدّث بلغة يونيكود. تسجّل الآن لحضور المؤتمر الدولي   يريد العالم أن ‪يتكلّم ‬ ، فهو يتحدّث بلغة يونيكود. تسجّل الآن لحضور المؤتمر الدولي",
  loremXl:
    "عادَ لتوّهِ من الجّبهة، يبدُو تعباً مرهقاً بعض الّشيءْ، هزيلاً فقد كانَ قبلَ سفرهِ مكتملاً، شيءٌ منْ زرقةِ عينيهِ مالَ إلى السّوادْ، وشعرهُ الأشقر الجميلْ لمْ يعدْ تماماً كذلك، غابتْ ضحكتهُ الجميلةُ الآسرة، وحضرتْ مكانها ابتسامةٌ خافتةٌ على شفيرِ الحزنْ، سألهُ ابنُ أخيهِ الطّفلُ ذو السابعة، عمّي: يقُولُ أبّي إنّك بطلٌ، وأنا أخبرتُ عنكَ كلّ أصدِقائِي، وجعلتُ الجميعَ يرى صورتكَ بالبزّة العسكرية، نحنُ فخُورونَ بكْ، وأنا أريدُ أنْ أصبحَ مثلك حين أكبرْ.",
  whyMedicalOnHand: "لماذا ميديكال أون هاند ؟",
  institutions: "المنشآت",
  aboutUs: "من نحن",
  chooseFromPopular: "اختر من التصنيفات الشائعة",
  categories: "التصنيفات",
  HowUse: "إزاى أستخدم",
  medicalOnHand: "ميديكال أون هاند",
  search: "ابحث",
  compare: "قارن",
  reserve: "احجز",
  contactUs: "تواصل معانا",
  termsAndConditions: "الشروط والأحكام",
  topSpeciality: " التخصصات الأكثر بحثا",
  topHospitals: " المستشفيات الأكثر بحثا",
  topGovernorates: " المحافظات الأكثر بحثا",
  appointments: "المواعيد",
  services: "الخدمات",
  noAvailableAppointmentsInSelectedDate:
    "عفوا لا يوجد ميعاد متاح فى هذا التوقيت",
  opsThisTimeNotAvailable: "عفوا ...هذا الوقت غير متاح",
  select: "اختر",
  nextAvailableTime: "أقرب موعد متاح",
  next: "التالى",
  previous: "رجوع",
  yourAppointmentWillBeAt: "موعك سيكون فى",
  from: "من",
  to: "إلى",
  nameRequierd: "برجاء إدخال الاسم",
  mobileRequierd: "برجاء ادخال رقم التليفون",
  emailIsInvalid: "البريد الألكترونى غير صحيح",
  emailRequired: "برجاء إدخال البريد الألكترونى",
  enterpatientname: "ادخل اسم المريض",
  enterYourEmail: "ادخل بريدك الالكترونى",
  enterYourMobile: "ادخل رقم الموبيل",
  cancel: "إلغاء",
  reserveNow: "احجز الآن",
  you: "أنت",
  yourRelevent: "أقاربك",
  reservationTypes: "نوع الحجز",
  paymentType: "نوع الدفع",
  patientCard: "بطاقة التأمين الصحى",
  selectInsurancCompany: "اختر شركة التأمين الصحى",
  enterPatientCardNumber: "ادخل رقم بطاقة التأمين",
  back: "رجوع",
  insuranceCompanyisRequired: "يجب ادخال شركة التأمين",
  relevantTypeIsRequired: "يجب اختيار قريبك",
  enterReleventName: "ادخل اسم قريبك",
  releventType: "صلة القرابة",
  patientRelevantIsRequierd: "يجب ادخال القريب",
  patientRelevantNameIsRequired: "يجب إدخال أسم القريب",
  selectReleventFirst: "اختر قريبك أولا",
  PaymentTypeIsRequired: "يجب إختيار طريقة الدفع",
  PaymentTypeIsRequiredFirst: "يجب إختيار طريقة الدفع أولا",
  patientCardIsRequierd: "يجب إدخال بطاقة التأمين",
  patientCardNumberIsRequired: "يجب إدخال رقم البطاقة",
  reservationTypeIsRequierd: "يجب اختيار نوع الحجز",
  reservationTypeIsRequierdFirst: "يجب اختيار نوع الحجز اولا",
  yourRservationDoneSuccessfullyAt: " لقد تم انهاء حجزك بنجاح فى ",
  yourRservationDoneSuccessfully: "لقد تم انهاء حجزك بنجاح",
  at: "فى",
  showMap: "اظهر الخريطة",
  drImg: "صورة الدكتور",
  noOptions: "لا يوجد خيارات",
  addNew: "إضافة جديد",
  nameEn: "الاسم الانجليزى",
  nameAr: "الاسم العربى",
  enterNameAr: "أدخل الاسم العربى",
  enterNameEn: "أدخل الاسم الانجليزى",
  nameArRequired: "الاسم العربى مطلوب",
  nameEnRequired: "الاسم الانجليزى مطلوب",
  englishName: "الاسم الانجليزى",
  arabicName: "الاسم العربى",
  thereIsNoDataToDisplay: "لا تتوافر بيانات لعرضها.....",
  opsThereIsSomethingHappenedInRetrievingData:
    "عذرا! حدث خطأ ما فى الوصول للبيانات",
  showing: "إظهار",
  of: "من",
  pleaseEnterEnglishName: "من فضلك ادخل الاسم الانجليزى",
  maxLengthIs: "الحد الأقصى هو",
  englishNameEnteredBefore: "الاسم الانجليزى تم ادخاله من قبل",
  pleaseEnterArabicName: "من فضلك ادخل الاسم العربى",
  arabicNameEnteredBefore: "الاسم العربى تم ادخاله من قبل",
  addInstitutionType: "إضافة نوع المنشأة",
  addFail: "خطأ فى الإضافة",
  save: "حفظ",
  editInstitutionType: "تعديل نوع المنشأة",
  editFail: "خطأ فى التعديل",
  close: "إغلاق",
  areYouSureYouWantToDelete: "هل تريد حذف",
  institutionType: "نوع المنشأة",
  delete: "حذف",
  somethingWrongHappened: "حدث خطأ ما",
  invalidInputs: "خطأ فى المدخلات",
  egypt: "مصر",
  country: "الدولة",
  code: "الكود",
  active: "فعال",
  editCountry: "تعديل الدولة",
  pleaseEnterCode: "من فضلك ادخل الكود",
  codeEnteredBefore: "الكود تم ادخاله من قبل",
  institutionIsRequired: "يجب اختيار المنشأة",
  branchIsRequired: "يجب إختيار الفرع",
  fieldIsRequired: "يجب إختيار التخصص",
  selectInstitution: "اختر المنشأه",
  selectBranch: "اختر الفرع",
  selectField: "اختر المجال",
  about: "عن الدكتور",
  hospitals: "المستشفيات",
  educationes: "الشهادات",
  contacts: "جهات الأتصال",
  aboutDoctor: "عن الدكتور",
  hospitalsAndClinics: "المستشفيات والعيادات",
  doctorGallery: "معرض صور الدكتور",
  thereIsNoImg: "لايوجد صور",
  gallery: "معرض الصور",
  doctorsArticals: "مقالات الدكتور",
  thereIsNoArticals: "لا يوجد مقالات",
  articalImg: "صورة المقال",
  readMore: "قرأة المزيد",
  commentImg: "صورة تعليق",
  ourClientsComments: "تعليقات عملائنا",
  thereIsNoAnyComments: "لايوجد اى تعليقات",
  selectCountry: "اختر الدولة",
  pleaseSelectCountry: "من فضلك اختر الدولة",
  branches: "فروع",
  insuranceCompanies: "شركات التأمين",
  available: "متاح",
  instensiveCares: "عنايات مركزة",
  institutionsBranchIsRequierd: "يجب اختيار الفرع ",
  doctorNameIsRequired: "يجب إختيارأسم الدكتور",
  dateIsRequired: "يجب إختيار التاريخ",
  error: "خطأ",
  timeIsRequired: "يجب إختيار الوقت ",
  selectDoctorName: "اختر أسم الطبيب",
  selectDate: "اختر التاريخ",
  selectTime: "اختر الوقت",
  theDeductionCost: "تكلفة الكشف ",
  showBranchContact: "اظهر جهات الاتصال بالفرع ",
  hideBranchContact: "اخفى جهات الاتصال بالفرع ",
  incubatorIsRequierd: "يجب إختيار الحضانة",
  selectIncubator: "اختر الحضانة",
  intensiveCareIsRequierd: "يجب اختيار العناية المركزة",
  selectIntensiveCare: "اختر العناية المركزة",
  done: "تم",
  enterBabyName: "ادخل أسم الطفل",
  babyNameRequired: "يجب إدخال أسم الطفل",
  addNewRelevant: "اضف قريب",
  thisIntesiveCareNotAvailableNow: "عفوا.. هذه العناية المركزه غير متاحة الآن ",
  thisIncubatorIsNotAvailableNow: "عفوا.. هذه الحضانة غير متاحة الآن ",
  intensiveCareImg: "صورة للعناية المركزة",
  intensiveCareWillBeHiddenSoon: "سيتم إخفاء العناية المركزة قريبًا ",
  intensiveCareAlreadyReserved: " العناية المركزة محجوزه",
  reservation: "الحجز",
  reservations: "الحجزات",
  branch: "فرع",
  thisIntensiveCareIsNotAvailableNow: "العناية المركزه غير متاحه الآن",
  IncubatorAlreadyReserved: "الحضانه محجوزه من قبل",
  incubatorWillBehiddenSoon: "الحضانة سوف تختفى قريبا...",
  thisIncubationsNotAvailableNow: "الحضانة غير متاحة الآن",
  specialisteAt: "متخصص فى ",
  institutionGallery: "معرض صور المؤسسة",
  ourBranches: "فروعنا",
  thereIsNpBranchMapData: "لا توجد بيانات للخريطه الخاصة بالفرع ",
  map: "الخريطة",
  ourFields: "تخصصاتنا",
  phone: "الهاتف",
  mobile: "التليفون المحمول",
  hotline: "الخط الساخن",
  links: "الروابط",
  link: "الرابط",
  doctorDoesNotSupportThisReservationType: "الدكتور لا يدعم نوع الحجز",
  youHaveNotAnyDetectionReservationBeforeOnThisDoctor:
    "لم تقم بحجز من نوع كشف جديد مع الدكتور من قبل",
  thereIsNoAvailableReservationsAsLimitOfReservationsReached:
    "لا يوجد حجوزات متاحة بسبب الوصول للحد الأقصى للحالات",
  noCasesSpecifiedForSelectedReservationType: "لم يتم تحديد حالات لنوع الحجز",
  maxCasesForSelectedReservationTypeIsReached:
    "تم الوصول للحد الأقصى لنوع الحجز",
  followedReservationAvailabilityIsExpiredCanNotUseThisReservationType:
    "لم يعد متاح الحجز من هذا النوع بسبب تخطى الفترة المتاحة للحجز",
  selectGovernorate: "اختر المحافطة",
  pleaseSelectGovernorate: "من فضلك اختر المحافطة",
  selectCity: "اختر المدينة",
  pleaseSelectCity: "من فضلك اختر المدينة",
  laboratoryBranchIsRequired: "يجب اختيار فرع المعمل ",
  medicalTestTypeIsRequired: "يجب اختيار نوع التحليل ",
  medicalTestIsRequired: "يجب اختيار التحليل ",
  selectTestType: "اختر نوع التحليل",
  selectTest: "اختر التحليل",
  TestCost: "تكلفة التحليل",
  hideTestInstructions: "اخفى تعليمات التحليل",
  showTestInstructions: "اظهر تعليمات التحليل",
  yourReservationFor: "حجزك ل",
  doneSuccessfullyOn: "تم بنجاح فى ",
  testInstructions: "تعليمات التحليل ",
  surgeryRooms: "غرف العمليات",
  radiations: "الأشعة",
  tests: "التحاليل الطبية",
  address: "العنوان",
  countries: "الدول",
  governorates: "المحافظات",
  cities: "المدن",
  areas: "المناطق",
  ourTeam: "فريق العمل",
  enterYourEmailOrPhoneOrMOHCode: "أدخل البريد الالكتروني او الموبايل او MOH",
  emailOrPhoneOrMohRequierd: "يجب ادخال البريد الالكتروني او الموبايل او MOH",
  invalidPassword: "كلمة المرور غير متطابقة....",
  enterYourPassword: "ادخل كلمة المرور",
  thisAccountYouEnteredWasInvalidEnterADifferentAccount:
    "الحساب الذى ادخلته غير صالح برجاء ادخال حساب أخر",
  emailEnteredBefore: "تم ادخال البريد الالكتروني من قبل",
  mobileEnteredBefore: "تم ادخال رقم الهاتف من قبل",
  withFacebook: "بواسطه الفيسبوك",
  enterPassword: " *ادخل كلمة المرور",
  enterConfirmPassword: "  تأكيد كلمة المرور *",
  passwordRequired: "برجاء أدخل كلمة المرور",
  confirmPasswordRequired: "برجاء تأكيد كلمة المرور",
  registerNow: "سجل الآن",
  iDontHaveAnAccount: "ليس لدي حساب ",
  WithGoogle: "بواسطة جوجل",
  youHaveMoreThanRole: "لديك أكثر من مجموعة صلاحيات ",
  selectRole: "اختر مجموعة الصلاحية",
  ThereAreMoreThanOrganization: "يوجد أكثر من منظمة",
  selectOrgainzation: "اختر المنظمة",
  ThereAreMoreThanInstitution: "يوجد أكثر من منشأه",
  ThereAreMoreThanBranch: "يوجد أكثر من فرع",
  selectAccountType: "اختر نوع الحساب",
  enterYourName: "ادخل أسمك ",
  loginForm: "تسجيل الدخول",
  registrationForm: "إنشاء حساب ",
  registrationFinishNeedToConfirmYourEmail:
    "لقد تم انهاء التسجيل برجاء تأكيد البريد الالكترونى ",
  verificationMailMassage: " رساله التحقق من البريد الإلكتروني",
  resend: "إعادة إرسال",
  patientForm: "إنشاء حساب للمريض",
  doctorForm: "إنشاء حساب طبيب",
  selectYourNationality: "اختر جنسيتك",
  dateOfBirth: "تاريخ الميلاد",
  gender: " النوع",
  or: "أو",
  institutionForm: "إنشاء حساب للمنشأه",
  enterInstitutionName: "ادخل اسم المنشأه",
  enterNumberOfBranch: "ادخل عدد الفروع *",
  enterNumberOfUsers: "ادخل عدد المستخدمين *",
  enterNumberOfFields: " أدخل عدد التخصصات * ",
  enterNumberOfDoctors: "ادخل عدد الاطباء *",
  weContactWithYouSoon: "سنتواصل معك قريبا",
  accountTypeIsRequired: "يجب اختيار نوع الحساب",
  patientNationalityIsRequired: "يجب اختيار الجنسية",
  makeAReservation: "احجز",
  radiologyCenterBranchIsRequired: "يجب إختيار فرع مركز الاشعه",
  medicalReadiationTypeIsRequired: "يجب اختيار نوع الاشعه",
  medicalRadiationIsRequired: "يجب اختيار الاشعه",
  radiationCenterBranchIsRequired: "يجب اختيار فرع مركز الاشعه",
  selectRadiationType: "اختر نوع الاشعه",
  selectRadiationName: "اختر اسم الاشعه",
  hideMedicalRadiationInstructions: " اخفى تعليمات الاشعه  ",
  showMedicalRadiationInstructions: "اظهر تعليمات الاشعه ",
  medicalRadiationInstructions: "تعليمات الاشعه",
  thereIsNo: "لا يوجد ",
  result: "نتائج",
  backToHome: "اذهب للصفحه الرئيسية",
  numberOfOurBranches: "عدد فروعنا",
  myProfile: "صفحتى الشخصية",
  MyReservations: "حجزاتى",
  myReleventsReservations: "حجزات أقاربى ",
  settings: "الاعدادات",
  changeMyPassword: "تغير كلمة المرور",
  currentPasswordIsRequired: "يجب ادخال كلمة المرور الحاليه ",
  enterCurrentPassword: "ادخل كلمة المرور الحاليه",
  enterNewPassword: "ادخل كلمة المرور الجديده",
  changePassword: "تغير كلمة المرور",
  search: "ابحث....",
  latestArticals: "أحدث المقالات",
  tags: "الأوسمة",
  recommended: "الموصى به",
  like: " اعجبنى",
  comments: "تعليقات",
  leaveComment: "اترك تعليق",
  enterYourComment: "ضع تعليقك",
  addComment: "اضف التعليق",
  replay: "رد",
  userName: "اسم المستخدم",
  RadiationCost: "تكلفة الأشعة",
  hideRadiationInstructions: "اخفى تعليمات الأشعة",
  showRadiationInstructions: "اظهر تعليمات الأشعة",
  radiationInstructions: "تعليمات الأشعة",
  laboratoryForm: "إنشاء حساب للمعمل",
  enterLaboratoryName: "ادخل اسم المعمل ",
  enterNumberOfTestTyps: "ادخل عدد انواع التحاليل ",
  enterNumberOfTests: "ادخل عدد التحاليل",
  RadiologyCenterForm: "إنشاء حساب لمركز الأشعة",
  enterRadiologyCenterName: "ادخل اسم مركز الأشعة",
  enterNumberOfRadiationTyps: "ادخل عدد أنواع الأشعة",
  enterNumberOfRadiations: "ادخل عدد الأشعة",
  pharamcyForm: "إنشاء حساب لصيدلية",
  enterPharamcyName: "ادخل اسم الصيدلية",
  enterNumberMedicineItem: "ادخل عدد الادويه",
  mohCode: "MOH كود ",
  logo: "اللوجو",
  selectInstitutionType: "اختر نوع المنشأة",
  selectLicenseStatus: "اختر حالة الترخيص",
  loading: "تحميل",
  displayedInSearch: "يظهر فى البحث",
  forgetPassword: "لقد نسيت كلمة المرور",
  enterYourEmailToSendConfirmationMail:
    "ادخل البريد الالكترونى الخاص بك لارسال رساله تاكيد",
  send: "إرسال",
  addCountry: "إضافة دولة",
  addGovernorate: "إضافة محافظة",
  editGovernorate: "تعديل المحافظة",
  addCity: "إضافة مدينة",
  editCity: "تعديل المدينة",
  addArea: "إضافة منطقة",
  editArea: "تعديل المنطقة",
  addInstitution: "إضافة منشأة",
  editInstitution: "تعديل المنشأة",
  institutionTypes: "أنواع المنشأة",
  insertedSuccessfully: "تم التسجيل بنجاح",
  howDoYouWantToReceiveTheCodeToResetYourPassword:
    "كيف تريد استقبال الرمز لإعادة تعيين كلمة المرور الخاصة بك؟",
  sendCodeViaEmail: "ارسال الكود عبر البريد الالكترونى ",
  sendCodeViaMobile: "ارسال الكود عبر الهاتف ",
  enterCode: "أدخل الكود",
  dateOfBirthIsRequired: "برجاء ادخال تاريخ الميلاد",
  dateOfBirthNotValid: "التاريخ غير متاح",
  skip: "تخطي",
  mobileMustStartsWith: "يجب ان يبدأ رقم الهاتف ب ",
  mobileCountNumbers: "يجب ان يكون عدد اراقام الهاتف ",
  mobileEnterBefore: "تم إدخال رقم الهاتف من قبل ",
  institution: "المنشأة الطبية",
  details: "التفاصيل",
  emailOrMobileRequired: "يجب ادخال رقم الهاتف او البريد الالكتروني",
  adminAccount: "حساب مدير المنشأة",
  license: "الترخيص",
  institutionTypesRequired: "يجب ادخال نوع المنشأه",
  minimumNumberIs: "الحد الأدنى هو",
  requiredMinNumber: " يجب الا يقل الرقم عن 1",
  requiredMaxNumber: "يجب الا يزيد الرقم عن 100 ",
  requiredIntNumber: "يجب ان يكون الرقم صحيحا",
  numberCannotBeDecimal: "غير مسموح بالأرقام العشرية",
  requiredBranchNumber: "يجب ادخال عدد الفروع",
  requiredInstitutionFieldNumber: "يجب ادخال عدد التخصصات",
  requiredDoctorNumber: "يجب ادخال عدد الأطباء",
  requiredUserNumber: "يجب ادخال عدد المستخدمين",
  users: "المستخدمون",
  user: "المستخدم",
  requiredLaboratoryMedicalTestNumber: "يجب ادخال عدد التحاليل الطبية",
  medicalTests: "التحاليل الطبية",
  medicalTest: "التحليل الطبي",
  requiredRadiologyCenterMedicalRadiationNumber: "يجب ادخال عدد الأشعة الطبية",
  medicalRadiations: "الأشعة الطبية",
  medicalRadiation: "الأشعة الطبية",
  countryCallingCode: "كود الاتصال بالدولة",
  mobileAlreadyExist: "رقم المحمول مستخدم من قبل",
  emailAlreadyExist: "البريد الالكترونى مستخدم من قبل",
  flag: "العلم",
  emailNotExist: "البريد الالكتروني غير موجود",
  accountIsDeactivated: " الحساب غير مفعل",
  emailNotActive: "البريد الالكتروني غير مفعل",
  noRole: "لا يوجد دور لهذا الحساب",
  loginSuccessfully: " تم الدخول بنجاح",
  noLicense: "لا يوجد صلاحيات للمنظمه",
  licenseExpired: "انتهت صلاحيات المنظمه",
  noActiveOrganization: "لا توجد منظمه مفعله",
  noActiveOrganizationBranch: "لا يوجد فرع بالمنظمه مفعل",
  selectOrganizationBranch: "اختر الفرع الخاص بالمنظمه",
  selectOrganization: "اختر المنظمه",
  roleCheck: "اختر نوع الحساب",
  mobileNotExist: "رقم التليفون غير موجود",
  mobileNotActive: "رقم التليفو غير مفعل",
  contactWithAdmin: "حدثت مشكلة ما تواصل مع الادمن",
  haveNotAccount: "لا تملك حساب من قبل",
  roleIsRequired: "يجب اختيار الوظيفة",
  organizationIsRequired: "يجب اختيار المنظمة",
  organizationBranchIsRequired: "يجب اختيار فرع المنظمة",
  descriptionEn: "الوصف بالانجليزي",
  descriptionAr: "الوصف بالعربي",
  contactType: "انواع جهات الاتصال",
  pleaseSelectContactType: "من فضلك اختر نوع جهات الاتصال",
  contactValue: "جهة الاتصال",
  contactValueIsRequired: "يجب ادخال جهة الاتصال",
  addPharmaceuticalCompanyContact: "اضافة جهة اتصال جديده",
  contactValueEnteredBefore: "تم دخال جهة الاتصال من قبل",
  editPharmaceuticalCompanyContact: "تعديل جهة اتصال",
  pharmaceuticalCompanyContact: "جهة اتصال",
  addInstitutionAdmin: "إضافة مسئول نظام للمؤسسة",
  licenseStatus: "الحالة",
  fromDate: "من تاريخ",
  toDate: "إلي تاريخ",
  isCurrent: "الرخصة الحالية",
  allowedAddScreen: "يسمح بإضافة شاشات جديده",
  allowedAddScreenPermission: "يسمح بإضافة صلاحية جديد",
  pleaseSelectLicenseStatus: "من فضلك اختر الحالة",
  addInstitutionLicense: "إضافة تراخيص جديدة",
  editInstitutionLicense: "تعديل ترخيص",
  institutionLicense: "ترخيص المنشأة",
  screenDiplayEn: "اسم الشاشة بالانجليزي",
  screenDiplayAr: "اسم الشاشة بالعربي",
  parentScreenDiplayEn: "اسم الشاشة الاساسية بالانجليزي",
  parentScreenDiplayAr: "اسم الشاشة الاساسية بالعربي",
  pleaseSelectScreen: "من فضلك اختر الشاشة",
  addLicenseScreen: "إضافة شاشة جديدة",
  editLicenseScreen: "تعديل صلاحية شاشة",
  selectScreen: "اختر الشاشة",
  screen: "الشاشة",
  permissionDiplayEn: "اسم الصلاحية بالانجليزي",
  permissionDiplayAr: "اسم الصلاحية بالعربي",
  pleaseSelectPermission: "من فضلك اختر الصلاحية",
  addLicenseScreenPermission: "إضافة صلاحية جديد",
  editLicenseScreenPermission: "تعديل صلاحية جديدة",
  selectPermission: "اختر الصلاحية",
  permission: "الصلاحية",
  parentScreen: "الشاشة الأساسية",
  screens: "الشاشات",
  permissions: "الصلاحيات",
  institutionLicenses: "تراخيص المنشآة",
  addLicense: "إضافة ترخيص",
  editLicense: "تعديل ترخيص",
  numOf: "عدد",
  isClinicModuleNeeded: "وحدة العيادات",
  isIncubationModuleNeeded: "وحدة الحضانات",
  isIntensiveCareModuleNeeded: "وحدة العناية المركزة",
  isSurgeryRoomModuleNeeded: "وحدة غرف العمليات",
  genericName: "الاسم الطبي",
  commonName: "الاسم التجاري",
  pharmaceuticalCompany: "شركة الادوية",
  selectPharmaceuticalCompany: "اختر شركة الادوية",
  addMedicine: "إضافة دواء",
  editMedicine: "تعديل الدواء",
  medicine: "دواء",
  pleaseSelectPharmaceuticalCompany: "من فضلك اختر شركة الادوية",
  genericNameEn: "الاسم الطبي بالانجليزي",
  commonNameEn: "الاسم التجاري بالانجليزي",
  noteEn: "ملحوظة بالانجليزي",
  genericNameAr: "الاسم الطبي بالعربي",
  commonNameAr: "الاسم التجاري بالعربي",
  noteAr: "ملحوظة بالعربي",
  pleaseEnterBasicNameEn: "من فضلك ادخل الاسم الطبي بالانجليزي",
  pleaseEnterBasicNameAr: "من فضلك ادخل الاسم الطبي بالعربي",
  pleaseEnterCommonNameEn: "من فضلك ادخل الاسم التجاري بالانجليزي",
  pleaseEnterCommonNameAr: "من فضلك ادخل الاسم التجاري بالعربي",
  genericNameArEnteredBefore: "تم ادخال الاسم الطبي بالعربي من قبل",
  genericNameEnEnteredBefore: "تم ادخال الاسم الطبي بالانجليزي من قبل",
  commonNameArEnteredBefore: "تم ادخال الاسم التجاري بالعربي من قبل",
  commonNameEnEnteredBefore: "تم ادخال الاسم التجاري بالانجليزي من قبل",
  addPharmaceuticalCompany: "إضافة شركة أدوية",
  editPharmaceuticalCompany: "تعديل شركة الأدوية",
  pleaseSelectCountryFirst: "من فضلك اختر الدولة اولا",
  pleaseSelectContactTypeFirst: "من فضلك اختر نوع الاتصال اولا",
  addMedicalTestType: "إضافة نوع التحليل الطبي",
  editMedicalTestType: "تعديل نوع التحليل الطبي",
  pleaseSelectMedicalTestType: "من فضلك اختر نوع التحليل الطبي",
  selectMedicalTestType: "اختر نوع التحليل الطبي",
  medicalTestInstructions: "إرشادات التحليل الطبي",
  instructions: "الارشادات",
  addMedicalTestInstructions: "إضافة ارشادات التحليل الطبي",
  editMedicalTestInstructions: "تعديل ارشادات التحليل الطبي",
  instructionBodyEn: "الارشادات بالانجليزي",
  instructionBodyAr: "الارشادات بالعربي",
  pleaseEnterInstructionBodyEn: "من فضلك ادخل الارشادات بالانجليزي",
  pleaseEnterInstructionBodyAr: "من فضلك ادخل الارشادات بالعربي",
  addMedicalRadiationType: "إضافة نوع الاشعة الطبية",
  editMedicalRadiationType: "تعديل نوع الاشعة الطبية",
  pleaseSelectMedicalRadiationType: "من فضلك اختر نوع الاشعة الطبية",
  selectMedicalRadiationType: "اختر نوع الاشعة الطبية",
  medicalRadiationInstructions: "إرشادات الاشعة الطبية",
  addMedicalRadiationInstructions: "إضافة ارشادات الاشعة الطبية",
  editMedicalRadiationInstructions: "تعديل ارشادات الاشعة الطبية",
  addMedicalTest: "إضافة تحليل طبي",
  editMedicalTest: "تعديل تحليل طبي",
  addMedicalRadiation: "إضافة اشعة الطبية",
  editMedicalRadiation: "تعديل اشعة الطبية",
  addSurgery: "إضافة عملية",
  editSurgery: "تعديل عملية",
  surgery: "عملية",
  addLaboratory: "إضافة معمل",
  editLaboratory: "تعديل معمل",
  laboratory: "معمل",
  addLaboratoryAdmin: "إضافة مسئول نظام للمعمل",
  laboratoryLicense: "ترخيص المعمل",
  laboratoryLicenses: "تراخيص المعمل",
  addRadiologyCenter: "إضافة مركز أشعة",
  editRadiologyCenter: "تعديل مركز أشعة",
  radiologyCenter: "مركز أشعة",
  addRadiologyCenterAdmin: "إضافة مسئول نظام لمركز أشعة",
  radiologyCenterLicense: "ترخيص مركز أشعة",
  radiologyCenterLicenses: "تراخيص مركز أشعة",
  addPharmacy: "إضافة صيدلية",
  editPharmacy: "تعديل صيدلية",
  pharmacy: "صيدلية",
  addPharmacyAdmin: "إضافة مسئول نظام للصيدلية",
  pharmacyLicense: "ترخيص الصيدلية",
  pharmacyLicenses: "تراخيص الصيدلية",
  editInfo: "تعديل البيانات",
  aboutEn: "معلومات بالانجليزي",
  aboutAr: "معلومات بالعربي",
  roleName: "اسم الوظيفة",
  roleBase: "اسم الوظيفة الاساسية",
  allowedAddNotifications: "السماح بإضافة إشعارات",
  pleaseSelectInstitutionRoleBase: "من فضلك اختر الوظيفة الاساسية",
  selectInstitutionRoleBase: "اختر الوظيفة الاساسية",
  addRole: "إضافة وظيفة",
  editRole: "تعديل وظيفة",
  role: "وظيفة",
  addScreen: "إضافة شاشة",
  editScreen: "تعديل شاشة",
  addScreenPermission: "إضافة صلاحية",
  editScreenPermission: "تعديل صلاحية",
  addBranch: "إضافة فرع",
  editBranch: "تعديل فرع",
  addExistingUser: "إضافة مستخدم موجود",
  changeWithNewUser: "تغيير بمستخدم جديد",
  changeWithExistingUser: "تغيير بمستخدم موجود",
  addUser: "إضافة مستخدم",
  pleaseSelectUser: "من فضلك اختر مستخدم",
  isPrimaryField: "التخصص الاساسي",
  addField: "إضافة تخصص",
  editField: "تعديل تخصص",
  pleaseSelectField: "من فضلك اختر التخصص",
  fieldName: "اسم التخصص",
  doctorName: "اسم الدكتور",
  doctorTitleName: "لقب الدكتور",
  addDoctor: "إضافة دكتور",
  editDoctor: "تعديل دكتور",
  selectDoctor: "اختر الدكتور",
  pleaseSelectDoctor: "من فضلك اختر الدكتور",
  selectDoctorTitle: "اختر لقب الدكتور",
  pleaseSelectDoctorTitle: "من فضلك اختر لقب الدكتور",
  specialityName: "اسم التخصص",
  addSpeciality: "إضافة تخصص",
  editSpeciality: "تعديل تخصص",
  selectSpeciality: "اختر التخصص",
  pleaseSelectSpeciality: "من فضلك اختر التخصص",
  speciality: "التخصص",
  editableData: "تعديل البيانات",
  account: "حساب",
  addExistingDoctor: "إضافة دكتور موجود",
  titleDescriptionEn: "وصف اللقب يالانجليزي",
  titleDescriptionAr: "وصف اللقب بالعربي",
  selectGender: "اختر النوع",
  codeEnteredBefore: "تم ادخال الكود من قبل",
  PasswordNotMatch: "الباسورد غير متطابق",
  detectionPrice: "سعر الكشف",
  detectionPriceIsRequired: "يجب ادخال سعر الكشف",
  specificTime: "وقت محدد",
  patientTimeAverage: "متوسط وقت المريض",
  minute: "دقيقة",
  calendarNumberOfDays: "عدد الايام في التقويم",
  days: "الايام",
  cannotDeleteItemAsChildDataFound: "لا يمكن الحذف لوجود تفاصيل مرتبطة به",
  hasAcoount: "يمتلك حساب",
  branchName: "اسم الفرع",
  mainBranch: "الفرع الرئيسي",
  addressEn: "العنوان باللغة الانجليزية",
  addressAr: "العنوان باللغة العربية",
  pleaseSelectGovernorateFirst: "من فضلك اختر المحافظة اولا",
  pleaseSelectCityFirst: "من فضلك اختر المدينة اولا",
  selectArea: "اختر المنطقة",
  pleaseSelectArea: "من فضلك اختر المنطقة",
  addContact: "إضافة جهة اتصال",
  editContact: "تعديل جهة اتصال",
  contact: "جهة اتصال",
  searchDate: "تاريخ البحث",
  day: "اليوم",
  reservationType: "نوع الحجز",
  patientName: "اسم المريض",
  reservationStatus: "حالة الحجز",
  date: "التاريخ",
  history: "السجل",
  addReservation: "إضافة حجز",
  gridReservations: "جدول الحجز",
  existingPatient: "مريض متواجد",
  patientsCreatedAccountBefore: "مريض له حساب بالنظام",
  yourPatients: "مرضي المنشأة",
  newPatient: "مريض جديد",
  selectPatient: "اختر المريض",
  pleaseSelectPatient: "من فضلك اختر المريض اولا",
  selectPatientFirst: "اختر المريض اولا",
  surgeryName: "اسم العملية",
  cost: "التكلفة",
  institutionSurgery: "العملية",
  pleaseSelectSurgery: "من فضلك اختر العملية",
  pleaseEnterCost: "من فضلك ادخل التكلفة",
  addInstitutionSurgery: "إضافة عملية",
  englishDescription: "الوصف الانجليزى",
  arabicDescription: "الوصف العربى",
  incubationName: "اسم الحضانة",
  status: "الحالة",
  addIncubation: "إضافة حضانة",
  editIncubation: "تعديل الحضانة",
  incubation: "حضانة",
  intensiveCareName: "اسم العناية المركزة",
  addIntensiveCare: "إضافة عناية مركزة",
  editIntensiveCare: "تعديل عناية مركزة",
  intensiveCare: "عناية مركزة",
  intensiveCareTypeIsRequired: "يجب اختيار نوع العناية المركزة",
  showIsCurrentOnly: "عرض الحالي فقط",
  selectIncubation: "اختر الحضانة",
  pleaseSelectIncubation: "من فضلك اختر الحضانة",
  schedule: "المواعيد",
  pleaseSelectBranch: "من فضلك اختر الفرع",
  followedReservationAvailabilityWithinDays: "عدد الأيام المتاحة للحجز التابع",
  diagnosisPrice: "ثمن الكشف",
  surgeryRoomName: "اسم غرفة العمليات",
  addSurgeryRoom: "إضافة غرفة عمليات",
  editSurgeryRoom: "تعديل غرفة عمليات",
  surgeryRoom: "غرفة عمليات",
  addReservationType: "إضافة نوع الحجز",
  schedules: "جدول مواعيد",
  addSchedule: "إضافة مواعيد",
  editSchedule: "تعديل مواعيد",
  selectDay: "اختر اليوم",
  pleaseSelectDay: "من فضلك اختر اليوم",
  fromTime: "من وقت",
  toTime: "إلي وقت",
  selectSurgeryRoom: "اختر غرفة العمليات",
  pleaseSelectSurgeryRoom: "من فضلك اختر غرفة العمليات",
  pleaseSelectReservationType: "من فضلك اختر نوع الحجز",
  pleaseEnterDiagnosisPrice: "من فضلك ادخل تكلفة الكشف",
  selectReservationType: "اختر نوع الحجز",
  editReservationType: "تعديل نوع الحجز",
  maxCases: "الحد الأقصى للحالات",
  isCasesSpecified: "توزيع الحالات",
  selectSurgery: "اختر العملية",
  selectSchedule: "اختر الموعيد",
  pleaseSelectSchedule: "من فضلك اختر الموعيد",
  divideCasesBySupportedReservationTypes: "توزيع عدد الحالات على أنواع الحجز",
  maxCasesDivisions: "توزيع الحالات",
  maxCasesCount: "عدد الحالات",
  modify: "تعديل",
  item: "العنصر",
  addInsuranceCompany: "إضافة شركة تأمين",
  editInsuranceCompany: "تعديل شركة تأمين",
  selectCateory: "اختر الفئة",
  pleaseSelectCategory: "من فضلك اختر الفئة",
  addCategory: "إضافة فئة",
  editCategory: "تعديل فئة",
  category: "الفئة",
  scheduleMaxCases: "الحد الأقصى للحالات فى جدول المواعيد",
  addPatient: "إضافة مريض",
  editPatient: "تعديل مريض",
  addExistingPatient: "إضافة مريض موجود",
  modifyReservationTypeMaxCases: "تعديل الحد الأقصى لنوع الحالات",
  serviceName: "اسم الخدمة",
  addService: "إضافة خدمة",
  editService: "تعديل الخدمة",
  service: "الخدمة",
  patient: "مريض",
  institutionProfile: "بيانات المنشآة",
  institutionRoles: "مجموعة الصلاحيات",
  systemAdmin: "مدير النظام",
  institutionFieldSettings: "إعدات التخصصات بالمنشآة",
  patients: "المرضى",
  branchPatients: "مرضى الفرع",
  branchDoctors: "أطباء الفرع",
  systemAdministration: "إدارة النظام",
  doctorReservations: "حجوزات الأطباء",
  incubations: "الحضانات",
  incubationReservations: "حجوزات الحضانات",
  intensiveCares: "العناية المركزة",
  intensiveCareReservations: "حجوزات العناية المركزة",
  surgeryRoomReservations: "حجوزات غرفة العمليات",
  institutionSurgeries: "أسماء العمليات",
  hideMap: "إخفاء الخريطة",
  institutionBranchFieldSettings: "إعدادات التخصصات بالفرع",
  pictureName: "اسم الصورة",
  pictureDescription: "وصف الصورة",
  picture: "الصورة",
  browse: "عرض",
  selectPicture: "اختار الصورة",
  addPicture: "إضافة صورة",
  editPicture: "تعديل الصورة",
  institutionContacts: "جهات الاتصال للمنشآة",
  institutionBranchContacts: "جهات الاتصال للفرع",
  institutionPictures: "صور المنشآة",
  relevantType: "نوع القرابة",
  maritalStatus: "الحالة الاجتماعية",
  yourAreSuccessfullyLoggedInPleasePickAPageFromMenu:
    "تم تسجيل الدخول بنجاح، من فضلك قم باختيار احدى الصفح من القائمة",
  pleaseWait: "برجاء الانتظار...",
  youAreNotAuthorizedToViewThisPage: "لا تمتلك الصلاحية لرؤية هذه الصفحة",
  addRelevant: "إضافة قرابة",
  editRelevant: "تعديل قرابة",
  selectRelevantType: "اختار نوع القرابة",
  pleaseSelectRelevantType: "من فضلك اختر نوع القرابة",
  pleaseSelectRelevantTypeFirst: "من فضلك اختر نوع القرابة أولا",
  selectMaritalStatus: "اختار الحالة الاجتماعية",
  relevantName: "اسم ذو القرابة",
  cardNumber: "رقم الكرت",
  photoFaceOne: "صورة الوجه الاول",
  photoFaceTwo: "صورة الوجه الثاني",
  faceOne: "الوجه الاول",
  faceTwo: "الوجه الثاني",
  addPatientCard: "إضافة بطاقة التأمين الصحي",
  editPatientCard: "تعديل بطاقة التأمين الصحي",
  pleaseSelectInsuranceCompanyFirst: "من فضلك اختر شركة التأمين اولا",
  cardNumberEnteredBefore: "تم إدخال رقم كارت التأمين من قبل",
  reset: "إعادة البيانات",
  no: "لا",
  hasNoAccount: "لا يمتلك حساب",
  pictures: "الصور",
  beneficialRelevant: "الاقارب المستفيدون",
  selectRelevant: "اختار الاقارب",
  pleaseSelectRelevant: "من فضلك اختر الاقارب",
  relevant: "الاقارب",
  enterAValidDate: "التاريخ غير صحيح",
  updateSuccessful: "تعديل ناجح",
  withRegisterationYouAgree: "بتسجيل الحساب أنت توافق على",
  termsAndCond: "الشروط والأحكام",
  language: "اللغة",
  favorites: "المفضلة",
  more: "المزيد",
  onlyForPatient: "للمريض فقط",
  signUp: "تسجيل",
  alreadyHaveAccount: "لديك حساب بالفعل ",
  loginNow: "سجل الدخول الآن",
  year: "السنة",
  month: "الشهر",

  // Ahmed's translation

  // login

  enterEmail: "أدخل الإيميل",
  enterPass: "أدخل كلمة المرور*        ",
  notHaveAccount: "لا أمتلك حساب. إنشاء",
  new: "جديد",
  signUpWithFace: "التسجيل بواسطة الفيس بوك",
  signUpWithGoogle: "التسجيل بواسطة جوجل",
  selectLanguage: "اختر اللغة",
  accountType: "نوع الحساب",

  // notification

  notifications: "الإشعارات",
  turnOff: "وقف تشغيل",
  deleteThisNot: "حذف هذا الإشعار",
  stopReceiveNot: "وقف إستلام مثل هذه الإشعارات",

  // home

  welcome: "مرحبا بك",

  // drawer

  yourReservation: "حجوزاتي",
  releventsReservation: "الحجوزات المرتبطة",
  relevents: "الإرتباطات",
  intitutions: "مؤسسات",

  // search

  searchByDrName: "ابحث بواسطة اسم الطبيب         ",
  searchByInstitutionName: "ابحث بواسطة اسم المؤسسة",
  searchByRaiologyCenterName: "ابحث بواسطة اسم مركز الأشعة",
  searchByPharmacyName: "ابحث بواسطة اسم الصيديلية",
  searchByIncubatorName: "ابحث بواسطة اسم جليسة الأطفال",
  searchByIntensiveCareName: "ابحث بواسطة اسم العناية المركزة",
  searchByDrNameOrUseFilter: "ابحث بواسطة اسم الطبيب أو استخدم الفلتر",
  searchByInstitutionNameOrUseFilter:
    "ابحث بواسطة اسم المؤسسة أو استخدم الفلتر",
  searchByIntensiveCareNameOrUseFilter:
    "ابحث بواسطة اسم العناية المركزة أو استخدم الفلتر",
  searchByRadiologyCenterNameOrUseFilter:
    "ابحث بواسطة اسم مركز الأشعة أو استخدم الفلتر",
  searchByLaboratoryNameOrUseFilter: "ابحث بواسطة اسم المعمل أو استخدم الفلتر",
  searchByIncubatorNameOrUseFilter:
    "ابحث بواسطة اسم جليسة الاطفال أو استخدم الفلتر",
  searchByPharmacyNameOrUseFilter: "ابحث بواسطة اسم الصيدلية أو استخدم الفلتر",
  ourPharmacyHave: "تمتلك صيدليتنا",

  // filter

  // selectField: "اختر المجال",
  selectGovernorate: "اختر المحافظة",
  selectInsuranceCompany: "اختر شركة التأمين",
  selectDrGender: "اختر نوع الطبيب",
  feesRange: "مقدار الرسوم",
  showResults: "اعرض النتائج",
  institutionName: "اسم المؤسسة",
  intensiveCareType: "نوع العناية المركزة",
  radiationName: "اسم مركز الأشعة",
  radiationType: "نوع مركز الأشعة",

  // profile

  ssnCard: "بطاقة ssn",
  medicalCard: "البطاقة الطبية",
  setting: "الإعدادت",
  changePass: "تغيير كلمة المرور",

  // reservation

  selectDataToShow: "اختار التاريخ لعرض الأوقات المتاحة",
  reservationFor: "حجز لـ",
  paymentType: "طريقة الدفع",
  now: "الآن",
  selectFromAvailableTime: "اختر من الأوقات المتاحة",
  selectCardId: "اختر Id الخاص بالبطاقة",
  selectMedicalCompany: "اختر الشركة الطبية",
  attachMedicalCard: "ألحق بطاقتك الطبية هنا",
  front: "الأمام",
  back: "الخلف",
  goBack: "الرجوع",
  saveAndBack: "حفظ ورجوع",
  selectRelevant: "اختر الحجز",
  enterRelevantName: "ادخل اسم صاحب الحجز",
  selectRelevantRelationship: "حدد نوع علاقتك بصاحب الحجز",
  fees: "الرسوم",
  succesMess: "تم الحجز بنجاح وستصلك رسالة التأكيد قريبا",
  selectInstitution: "اختر المؤسسة",
  makeAppointment: "تحديد موعد",
  galary: "المعرض",
  reviews: "التقيمات",
  contacts: "وسائل الإتصال",
  about: "عن",
  education: "التعليم",
  reserveAppointment: "حجز موعد",
  availableToday: "متاح اليوم",
  doctorInfo: "بيانات الطبيب",
  institutionInfo: "بيانات المؤسسة",
  incubatorInfo: "بيانات الجليسة",
  radiologyCenterInfo: "بيانات مركز الأشعة",
  branchContact: "الإتصال بالفرع",
  selectIncubator: "اختر جليسة الأطفال",
  dailyFees: "الرسوم اليومية",
  radiationInstruction: "تعليمات الأشعة",

  // footer

  categories: "الفئات",
  reservations: "الحجوزات",
  more: "أكثر",

  // reset password

  resetMess: "كيف تريد إستقبال كود تغيير كلمة المرور؟",
  sendViaMobile: "أرسل عن طريق الموبايل",
  sendViaEmail: "أرسل عن طريق الإيميل",
  enterCode: "أدخل الكود الذي استلمته",
  enterCodeHere: "ادخل الكود هنا*",
  ok: "موافق",
  enterNewPass: "ادخل كلمة المرور الجديدة*",
  confirmNewPass: "تأكيد كلمة المرور الجديدة*",
  change: "تغيير",

  // more Tab

  aboutUs: "عن الشركة",
  contactUs: "اتصل بنا",
  typeMessHere: "اكتب رسالتك هنا",

  // register

  enterFullName: "ادخل اسمك كاملا*     ",
  enterMobile: "ادخل رقم الموبايل*        ",
  confirmPass: "تأكيد كلمة المرور*     ",
  selectGender: "النوع*     ",
  birthOfDate: "تاريخ ميلادك*",

  someThingWrongHappened: "يوجد خطأ ما",
  loading: "جاري التحميل",
  emailNotConfirmed: " البريد الالكتروني غير مؤكد",
  mobileNotConfirmed: " رقم الهاتف غير مؤكد",
  contactWithAdmin: "تواصل مع الادمن",
  enterCodeYouRecieved: "ادخل الكود المستلم",
  enterCodeHere: "ادخل الكود هنا",
  confirm: "تأكيد",
  codeIsRequired: "يجب إدخال الكود",
  sendCodeAgain: "إرسال الكود مرة اخري",
  cannotVerifyUserEmailBecauseOfInValidCode:
    "لا يمكن التحقق من البريد الإلكتروني للمستخدم بسبب رمز غير صالح",
  cannotVerifyUserEmailAsCodeHasBeenExpired:
    "لا يمكن التحقق من البريد الإلكتروني للمستخدم حيث انتهت صلاحية الرمز",
  cannotResetPasswordBecauseOfInValidCode:
    "لا يمكن إعادة تعيين كلمة المرور بسبب رمز غير صالح",
  cannotResetPasswordAsCodeHasBeenExpired:
    "لا يمكن إعادة تعيين كلمة المرور حيث انتهت صلاحية الرمز",
  willRedirectInFiveSecondsToConfirmedIt:
    "سيتم إعادة التوجيه في خمس ثوان لتأكيد ذلك",
  exitFromApp: "الخروج من التطبيق !",
  yes: "نعم",
  areYouSureYouWantToExistApp: "هل تريد الخروج من التطبيق ؟",
  emailSent: "تم الارسال الي البريد الإلكتروني",
  howDoYouWantToRecieveTheCode: "كيف تريد استلام الرمز ؟",
  toResetYourPassword: "لإعادة تعيين كلمة المرور الخاصة بك ؟",
  resetPassword: "إعادة تعيين كلمة المرور",
  resetPasswordSuccessfully: "تم إعادة تعيين كمة المرور بنجاح",
  emailConfirmedSuccessfully: "تم تأكيد البريد الإلكتروني",
  registeredSuccessfully: "تم التسجيل بنجاح",
  codeConfirmed: "تم تأكيد الرمز",
  logoutSuccessfully: "تم تسجيل الخروج بنجاح",
  medical: "ميديكال",
  onName: "اون",
  hand: "هاند",
  noResult: "لا توجد نتائج",
  eg: "جنيه مصري",
  showMap: "أظهر الموقع",
  notAvialable: "غير متاح",
  loadingMore: "جاري تحميل المزيد",
  camera: "كاميرا",
  attachYourMedicalCardHere: "اللحق البطاقة الصحية هنا",
  reviews: "المراجعات",
  showProfile: "عرض البروفايل",
  thereAreNotSupportedInsuranceCompanyForThisInstitution:
    "لا يوجد شركات تأمين تدعم هذه المؤسسة",
  info: "البيانات",
  thereIsNoInformation: "لا توجد معلومات",
  update: "تحديث",
  noDoctorFavorite: "لا يوجد اطباء مفضلين",
  noInstitutionFavorite: "لا يوجد مؤسسات مفضلة",
  noIncubationFavorite: "لا يوجد حضانات مفضلة",
  noIntensiveCareFavorite: "لا يوجد عنايات مركزة مفضلة",
  noLaboratoryFavorite: "لا يوجد معامل مفضلة",
  noRadiologyCenterFavorite: "لا يوجد مراكز أشعة مفضلة",
  noPharmacyFavorite: "لا يوجد صيدليات مفضلة",
  prescription: "الوصفة الطبية",
  edit: "تعديل",
  relevants: "الاقارب",
  relevantReservations: "حجوزات الاقارب",
  patientCards: "بطاقات التأمين",
  deletedSuccessfully: "تم المسح بنجاح",
  redirectIn: "إعادة توجيه",
  noInternet: "لا يوجد اتصال بالانترنت",
  autoUpdate: "تحديث تلقائي",
  off: "غير مفعل",
  on: "مفعل",
  wifiOnly: "واي فاي فقط",
  english: "إنجليزي",
  arabic: "عربي",
  checkYourConnection: "تحقق من اتصالك بالشبكة",
  reloadPage: "تحديث الصفحة",
  backOnline: "عودة الإتصال",
  selectLanguage: "اختار اللغة",
  dateNotValid: "التاريخ ليس صحيحا",
  timeNotValid: "الوقت ليس صحيحا",

  //weekDays
  sat: "سبت",
  sun: "أحد",
  mon: "اثنين",
  tue: "ثلاثاء",
  wed: "أربعاء",
  thu: "خميس",
  fri: "جمعة",

  //months
  january: "يناير",
  february: "فبراير",
  march: "مارس",
  april: "أبريل",
  may: "مايو",
  june: "يونيو",
  july: "يوليو",
  august: "أغسطس",
  september: "سبتمبر",
  october: "أكتوبر",
  november: "نوفمبر",
  december: "ديسمبر",

  //app
  inValidMobileNumber: "الرقم غير صحيح",
  thisIsNotKuwaitNumber: "هذا ليس رقم كويتي",
  youDoNothaveAccount: "لا تمتلك حساب ؟",
  clickHereToRegister: "اضغط هنل لتسجيل الدخول",
  verificationCode: "رمز التأكيد",
  verificationCodeSent: "تم إرسال رمز التأكيد إالي هاتفك",
  pleaseEnterCodeBelow: "من فضلك ادخل رمز التأكيد أدناه",
  didNotReceiveCode: "لم يتم أرسال رمز ألتأكيد",
  sendAgain: "إعادة إرسال",
  sendCode: "تم إرسال الكود",
  messageSent: "تم إرسال الرسالة",
  enterYourArabicName: "ادخل الاسم العربي",
  enterYourEnglishName: "ادخل الاسم الانجليزي",
  enterCount: "ادخل العدد",
  enterPaymentLink: "ادخل رابط الدفع",
  enterAmount: "ادخل الاجمالي",
  howManyPersons: "كم عدد الاشخاص ؟",
  showCurrency: "دينار/للفرد",
  hasCoupon: "لديك كوبون ؟",
  pleaseEnterCount: "من فضلك ادخل عدد الافراد",
  countMustBeLessThanIt: "عدد الافراد يجب ان يكون اقل من هذا",
  check: "تحقق",
  pleaseSelectTime: "من فضلك اختر الوقت",
  acceptAndCheckout: "قبول والدفع",
  places: "أماكن",
  currency: "دينار",
  instruction1: "يسمح بدخول الاطفال من سن 3 سنوات فما فوق,ولن يتم استرداد قيمة الحجز المدفوعة لمن هم دون ذلك.",
  instruction2: "سيتم إلغاء الحجز فى حال عدم الحضور فى الوقت المحدد والمبلغ المدفوع غير مسترجع.",
  instruction3: "يسمح بتغيير الحجز أو إلغائه مع استرجاع القيمة قبل الموعد ب24 ساعة.",
  youCanEditTimeBefore: "يمكنك تغيير الوقت قبل",
  bookTicket: "حجز موعد",
  tickets: "التذاكر",
  ticket: "تذكرة",
  news: "الاخبار",
  myTickets: "تذاكري",
  expired: "المنتهية",
  close: "غلق",
  cancelReservation: "إلغاء الحجز",
  pleaseEnterPaymentLink: "من فضلك ادخل رابط الدفع",
  shareApplication: "مشاركة التطبيق",
  bookATicket: "احجز تذكرة",
  bookedTickets: "التذاكر المحجوزة",
  noTicketAvailable: "لا توجد تذاكر متاحة",
  bookNowAndEnjoyWithFamily: "احجز الآن واستمتع مع العائلة",
  makeSureWhatsAppInstalledOnYourDevice: "تأكد من تثبيت الواتس اب علي جهازك",
  makeSureFacebookInstalledOnYourDevice: "تأكد من تثبيت الفيس بوك علي جهازك",
  makeSureInstgramInstalledOnYourDevice: "تأكد من تثبيت إنستجرام علي جهازك",
  makeSureYoutubeInstalledOnYourDevice: "تأكد من تثبيت اليوتيوب علي جهازك",
  makeSureTwitterInstalledOnYourDevice: "تأكد من تثبيت تويتر علي جهازك",
  today: "اليوم",
  at: "في",
  count: "العدد",
  amount: "الإجمالي",
  totalAmount: "الإجمالي الكلي",
  editReservation: "تعديل الحجز",
  //reservation
  reservationType: "نوع الحجز",
  editReservationTypeData: "تعديل بيانات نوع الحجز",
  addReservationType: "إضافة نوع حجز",

  //discount
  discounts: "التخفيضات",
  addDiscount: "إضافة تخفيض",
  editDiscount: "تعديل التخفيض",
  reservationSettings: "إعدادت الحجز",
  reservationTypeName: "اسم نوع الحجز",
  count: "العدد",
  time: "الوقت",
  pleaseSelectReservationType: "من فضلك اختر نوع الحجز",
  addReservationSetting: "إضافة اعداد للحجز",
  pleaseEnterIntegerNumber: "من فضلك ادخل عدد صحيح",
  pleaseEnterNumberGreaterThanIt: "من فضلك ادخل العدد اكبر من هذا",
  pleaseEnterCount: "من فضلك ادخل العدد",
  pleaseEnterTime: "من فضلك ادخل الوقت",
  pleaseEnterPrice: "من فضلك ادخل السعر",
  selectReservationType: "اختار نوع الحجز",
  enterCount: "ادخل العدد",
  enterTime: "ادخل الوقت",
  enterPrice: "ادخل السعر",
  editReservationSetting: "تعديل اعداد الحجز",
  rateDiscount: "معدل الخصم",
  numberOfUse: "عدد الاستخدام",
  discountRateShouldBeGreaterThan: "يجب ان يكون معدل الخصم اكبر من 0",
  discountRateShouldBeLessThan: "يجب ان يكون معدل الخصم اقل من او يساوى 100",
  numberOfUseShouldBeGreaterThan: "يجب ان تكون عدد مرات الاستخدام اكبر من 0",
  numberOfUseIsRequired: "عدد مرات الاستخدام مطلوب",
  //home
  socialMedia: "التواصل الاجتماعى",
  facebook: "فيسبوك",
  instagram: "انستغرام",
  twitter: "تويتر",
  visitOurPageOnFacebook: "قم بزيارة صفحتنا على فيسبوك",
  visitOurPageOnInstagram: "قم بزيارة صفحتنا على انستغرام",
  visitOurPageOnTwitter: "قم بزيارة صفحتنا على تويتر",
  aboutUs: "معلومات عنا",
  show: "عرض",
  reservationDate: "تاريخ الحجز",
  action: "الحدث",
  payment: "الدفع",
  attendence: "الحضور",
  cancel: "إلغاء",
  pay: "ادفع",
  paid: "تم الدفع",
  attend: "حضر",
  attended: "تم الحضور",
  canNotCancel: "لا يمكن الإلغاء",
  create: "إضافة",
  successfullyAdded: "تمت الاضافة بنجاح",
  successfullyEdited: "تم التعديل بنجاح",
  areYouSure: "هل أنت متاكد ؟",
  Items: "العناصر",
  price: "السعر",
  paymentLink: "رابط الدفع",
  returnMoney: "استرداد المال",
  noPaymentLink: "لا يوجد رابط دفع",
  returnedMoney: "تم استرداد المال",
  reservationSchedule: "مواعيد الحجوزات",
  vacationDate: "تاريخ الاجازة",
  officialVacation: "اجازة رسمي",
  addReservationSchedule: "إضافة مواعيد الحجز",
  enterFromTime: "ادخل بداية الوقت",
  enterToTime: "ادخل نهاية الوقت",
  enterVacationDate: "ادخل تاريخ الاجازة",
  roles: "الصلاحيات",
  createdDate: "تاريخ الاضافة",
  number: "الرقم",
  entries: "المدخلات",
  cannotChangeYourStatus: "لا يمكن تغيير حالتك",
  changedSuccessfully: "تم التغيير بنجاح",

  //paused time
  pausedTime: "وقت التوقف",
  pausedDate: "تاريخ التوقف",
  addPausedTime: "إضافة تاريخ توقف",
  editPausedTime: "تعديل تاريخ التوقف",
  enterPausedDate: "أدخل تاريخ التوقف",

  //attended
  attendedReservation: "الحضور",

  //cancelled
  cancelledReservation: "الحجوزات الملغية",

  //unpaid
  unpaidReservation: "الحجوزات الغير مدفوعة",

  // notification

  notifications: "الإشعارات",
  turnOff: "وقف تشغيل",
  deleteThisNot: "حذف هذا الإشعار",
  stopReceiveNot: "وقف إستلام مثل هذه الإشعارات",

  // home

  welcome: "مرحبا بك",

  // drawer

  yourReservation: "حجوزاتي",
  releventsReservation: "الحجوزات المرتبطة",
  relevents: "الإرتباطات",
  intitutions: "مؤسسات",

  // search

  searchByDrName: "ابحث بواسطة اسم الطبيب         ",
  searchByInstitutionName: "ابحث بواسطة اسم المؤسسة",
  searchByRaiologyCenterName: "ابحث بواسطة اسم مركز الأشعة",
  searchByPharmacyName: "ابحث بواسطة اسم الصيديلية",
  searchByIncubatorName: "ابحث بواسطة اسم جليسة الأطفال",
  searchByIntensiveCareName: "ابحث بواسطة اسم العناية المركزة",
  searchByDrNameOrUseFilter: "ابحث بواسطة اسم الطبيب أو استخدم الفلتر",
  searchByInstitutionNameOrUseFilter:
    "ابحث بواسطة اسم المؤسسة أو استخدم الفلتر",
  searchByIntensiveCareNameOrUseFilter:
    "ابحث بواسطة اسم العناية المركزة أو استخدم الفلتر",
  searchByRadiologyCenterNameOrUseFilter:
    "ابحث بواسطة اسم مركز الأشعة أو استخدم الفلتر",
  searchByLaboratoryNameOrUseFilter: "ابحث بواسطة اسم المعمل أو استخدم الفلتر",
  searchByIncubatorNameOrUseFilter:
    "ابحث بواسطة اسم جليسة الاطفال أو استخدم الفلتر",
  searchByPharmacyNameOrUseFilter: "ابحث بواسطة اسم الصيدلية أو استخدم الفلتر",
  ourPharmacyHave: "تمتلك صيدليتنا",

  // filter

  // selectField: "اختر المجال",
  selectGovernorate: "اختر المحافظة",
  selectInsuranceCompany: "اختر شركة التأمين",
  selectDrGender: "اختر نوع الطبيب",
  feesRange: "مقدار الرسوم",
  showResults: "اعرض النتائج",
  institutionName: "اسم المؤسسة",
  intensiveCareType: "نوع العناية المركزة",
  radiationName: "اسم مركز الأشعة",
  radiationType: "نوع مركز الأشعة",

  // profile

  ssnCard: "بطاقة ssn",
  medicalCard: "البطاقة الطبية",
  setting: "الإعدادت",
  changePass: "تغيير كلمة المرور",

  // reservation

  selectDataToShow: "اختار التاريخ لعرض الأوقات المتاحة",
  reservationFor: "حجز لـ",
  paymentType: "طريقة الدفع",
  now: "الآن",
  selectFromAvailableTime: "اختر من الأوقات المتاحة",
  selectCardId: "اختر Id الخاص بالبطاقة",
  selectMedicalCompany: "اختر الشركة الطبية",
  attachMedicalCard: "ألحق بطاقتك الطبية هنا",
  front: "الأمام",
  back: "الخلف",
  goBack: "الرجوع",
  saveAndBack: "حفظ ورجوع",
  selectRelevant: "اختر الحجز",
  enterRelevantName: "ادخل اسم صاحب الحجز",
  selectRelevantRelationship: "حدد نوع علاقتك بصاحب الحجز",
  fees: "الرسوم",
  succesMess: "تم الحجز بنجاح وستصلك رسالة التأكيد قريبا",
  selectInstitution: "اختر المؤسسة",
  makeAppointment: "تحديد موعد",
  galary: "المعرض",
  reviews: "التقيمات",
  contacts: "وسائل الإتصال",
  about: "عن",
  education: "التعليم",
  reserveAppointment: "حجز موعد",
  availableToday: "متاح اليوم",
  doctorInfo: "بيانات الطبيب",
  institutionInfo: "بيانات المؤسسة",
  incubatorInfo: "بيانات الجليسة",
  radiologyCenterInfo: "بيانات مركز الأشعة",
  branchContact: "الإتصال بالفرع",
  selectIncubator: "اختر جليسة الأطفال",
  dailyFees: "الرسوم اليومية",
  radiationInstruction: "تعليمات الأشعة",

  // footer

  categories: "الفئات",
  reservations: "الحجوزات",
  more: "أكثر",

  // reset password

  resetMess: "كيف تريد إستقبال كود تغيير كلمة المرور؟",
  sendViaMobile: "أرسل عن طريق الموبايل",
  sendViaEmail: "أرسل عن طريق الإيميل",
  enterCode: "أدخل الكود الذي استلمته",
  enterCodeHere: "ادخل الكود هنا*",
  ok: "موافق",
  enterNewPass: "ادخل كلمة المرور الجديدة*",
  confirmNewPass: "تأكيد كلمة المرور الجديدة*",
  change: "تغيير",

  // more Tab

  aboutUs: "عن الشركة",
  contactUs: "اتصل بنا",
  typeMessHere: "اكتب رسالتك هنا",

  // register

  enterFullName: "ادخل اسمك كاملا*     ",
  enterMobile: "ادخل رقم الموبايل*        ",
  confirmPass: "تأكيد كلمة المرور*     ",
  selectGender: "النوع*     ",
  birthOfDate: "تاريخ ميلادك*",

  someThingWrongHappened: "يوجد خطأ ما",
  loading: "جاري التحميل",
  emailNotConfirmed: " البريد الالكتروني غير مؤكد",
  mobileNotConfirmed: " رقم الهاتف غير مؤكد",
  contactWithAdmin: "تواصل مع الادمن",
  enterCodeYouRecieved: "ادخل الكود المستلم",
  enterCodeHere: "ادخل الكود هنا",
  confirm: "تأكيد",
  codeIsRequired: "يجب إدخال الكود",
  sendCodeAgain: "إرسال الكود مرة اخري",
  cannotVerifyUserEmailBecauseOfInValidCode:
    "لا يمكن التحقق من البريد الإلكتروني للمستخدم بسبب رمز غير صالح",
  cannotVerifyUserEmailAsCodeHasBeenExpired:
    "لا يمكن التحقق من البريد الإلكتروني للمستخدم حيث انتهت صلاحية الرمز",
  cannotResetPasswordBecauseOfInValidCode:
    "لا يمكن إعادة تعيين كلمة المرور بسبب رمز غير صالح",
  cannotResetPasswordAsCodeHasBeenExpired:
    "لا يمكن إعادة تعيين كلمة المرور حيث انتهت صلاحية الرمز",
  willRedirectInFiveSecondsToConfirmedIt:
    "سيتم إعادة التوجيه في خمس ثوان لتأكيد ذلك",
  exitFromApp: "الخروج من التطبيق !",
  yes: "نعم",
  areYouSureYouWantToExistApp: "هل تريد الخروج من التطبيق ؟",
  emailSent: "تم الارسال الي البريد الإلكتروني",
  howDoYouWantToRecieveTheCode: "كيف تريد استلام الرمز ؟",
  toResetYourPassword: "لإعادة تعيين كلمة المرور الخاصة بك ؟",
  resetPassword: "إعادة تعيين كلمة المرور",
  resetPasswordSuccessfully: "تم إعادة تعيين كمة المرور بنجاح",
  emailConfirmedSuccessfully: "تم تأكيد البريد الإلكتروني",
  registeredSuccessfully: "تم التسجيل بنجاح",
  codeConfirmed: "تم تأكيد الرمز",
  logoutSuccessfully: "تم تسجيل الخروج بنجاح",
  medical: "ميديكال",
  onName: "اون",
  hand: "هاند",
  noResult: "لا توجد نتائج",
  eg: "جنيه مصري",
  showMap: "أظهر الموقع",
  notAvialable: "غير متاح",
  loadingMore: "جاري تحميل المزيد",
  camera: "كاميرا",
  attachYourMedicalCardHere: "اللحق البطاقة الصحية هنا",
  reviews: "المراجعات",
  showProfile: "عرض البروفايل",
  thereAreNotSupportedInsuranceCompanyForThisInstitution:
    "لا يوجد شركات تأمين تدعم هذه المؤسسة",
  info: "البيانات",
  thereIsNoInformation: "لا توجد معلومات",
  update: "تحديث",
  noDoctorFavorite: "لا يوجد اطباء مفضلين",
  noInstitutionFavorite: "لا يوجد مؤسسات مفضلة",
  noIncubationFavorite: "لا يوجد حضانات مفضلة",
  noIntensiveCareFavorite: "لا يوجد عنايات مركزة مفضلة",
  noLaboratoryFavorite: "لا يوجد معامل مفضلة",
  noRadiologyCenterFavorite: "لا يوجد مراكز أشعة مفضلة",
  noPharmacyFavorite: "لا يوجد صيدليات مفضلة",
  prescription: "الوصفة الطبية",
  edit: "تعديل",
  relevants: "الاقارب",
  relevantReservations: "حجوزات الاقارب",
  patientCards: "بطاقات التأمين",
  deletedSuccessfully: "تم المسح بنجاح",
  redirectIn: "إعادة توجيه",
  noInternet: "لا يوجد اتصال بالانترنت",
  autoUpdate: "تحديث تلقائي",
  off: "غير مفعل",
  on: "مفعل",
  wifiOnly: "واي فاي فقط",
  english: "إنجليزي",
  arabic: "عربي",
  checkYourConnection: "تحقق من اتصالك بالشبكة",
  reloadPage: "تحديث الصفحة",
  backOnline: "عودة الإتصال",
  selectLanguage: "اختار اللغة",

  //weekDays
  sat: "سبت",
  sun: "أحد",
  mon: "اثنين",
  tue: "ثلاثاء",
  wed: "أربعاء",
  thu: "خميس",
  fri: "جمعة",

  //months
  january: "يناير",
  february: "فبراير",
  march: "مارس",
  april: "أبريل",
  may: "مايو",
  june: "يونيو",
  july: "يوليو",
  august: "أغسطس",
  september: "سبتمبر",
  october: "أكتوبر",
  november: "نوفمبر",
  december: "ديسمبر",

  //app
  inValidMobileNumber: "الرقم غير صحيح",
  thisIsNotKuwaitNumber: "هذا ليس رقم كويتي",
  youDoNothaveAccount: "لا تمتلك حساب ؟",
  clickHereToRegister: "اضغط هنل لتسجيل الدخول",
  verificationCode: "رمز التأكيد",
  verificationCodeSent: "تم إرسال رمز التأكيد إالي هاتفك",
  pleaseEnterCodeBelow: "من فضلك ادخل رمز التأكيد أدناه",
  didNotReceiveCode: "لم يتم أرسال رمز ألتأكيد",
  sendAgain: "إعادة إرسال",
  sendCode: "تم إرسال الكود",
  messageSent: "تم إرسال الرسالة",
  enterYourArabicName: "ادخل الاسم العربي",
  enterYourEnglishName: "ادخل الاسم الانجليزي",
  enterCount: "ادخل العدد",
  enterPaymentLink: "ادخل رابط الدفع",
  enterAmount: "ادخل الاجمالي",
  howManyPersons: "كم عدد الاشخاص ؟",
  showCurrency: "دينار/للفرد",
  hasCoupon: "لديك كوبون ؟",
  pleaseEnterCount: "من فضلك ادخل عدد الافراد",
  countMustBeLessThanIt: "عدد الافراد يجب ان يكون اقل من هذا",
  check: "تحقق",
  pleaseSelectTime: "من فضلك اختر الوقت",
  acceptAndCheckout: "قبول والدفع",
  places: "أماكن",
  currency: "دينار",
  instruction1: "يسمح بدخول الاطفال من سن 3 سنوات فما فوق,ولن يتم استرداد قيمة الحجز المدفوعة لمن هم دون ذلك.",
  instruction2: "سيتم إلغاء الحجز فى حال عدم الحضور فى الوقت المحدد والمبلغ المدفوع غير مسترجع.",
  instruction3: "يسمح بتغيير الحجز أو إلغائه مع استرجاع القيمة قبل الموعد ب24 ساعة.",
  youCanEditTimeBefore: "يمكنك تغيير الوقت قبل",
  bookTicket: "حجز موعد",
  tickets: "التذاكر",
  ticket: "تذكرة",
  news: "الاخبار",
  myTickets: "تذاكري",
  expired: "المنتهية",
  close: "غلق",
  cancelReservation: "إلغاء الحجز",
  pleaseEnterPaymentLink: "من فضلك ادخل رابط الدفع",
  shareApplication: "مشاركة التطبيق",
  bookATicket: "احجز تذكرة",
  bookedTickets: "التذاكر المحجوزة",
  noTicketAvailable: "لا توجد تذاكر متاحة",
  bookNowAndEnjoyWithFamily: "احجز الآن واستمتع مع العائلة",
  makeSureWhatsAppInstalledOnYourDevice: "تأكد من تثبيت الواتس اب علي جهازك",
  makeSureFacebookInstalledOnYourDevice: "تأكد من تثبيت الفيس بوك علي جهازك",
  makeSureInstgramInstalledOnYourDevice: "تأكد من تثبيت إنستجرام علي جهازك",
  makeSureYoutubeInstalledOnYourDevice: "تأكد من تثبيت اليوتيوب علي جهازك",
  makeSureTwitterInstalledOnYourDevice: "تأكد من تثبيت تويتر علي جهازك",
  today: "اليوم",
  at: "في",
  count: "العدد",
  amount: "الإجمالي",
  totalAmount: "الإجمالي الكلي",
  editReservation: "تعديل الحجز",
  //reservation
  reservationType: "نوع الحجز",
  editReservationTypeData: "تعديل بيانات نوع الحجز",
  addReservationType: "إضافة نوع حجز",

  //discount
  discounts: "التخفيضات",
  addDiscount: "إضافة تخفيض",
  editDiscount: "تعديل التخفيض",
  reservationSettings: "إعدادت الحجز",
  reservationTypeName: "اسم نوع الحجز",
  count: "العدد",
  time: "الوقت",
  pleaseSelectReservationType: "من فضلك اختر نوع الحجز",
  addReservationSetting: "إضافة اعداد للحجز",
  pleaseEnterIntegerNumber: "من فضلك ادخل عدد صحيح",
  pleaseEnterNumberGreaterThanIt: "من فضلك ادخل العدد اكبر من هذا",
  pleaseEnterCount: "من فضلك ادخل العدد",
  pleaseEnterTime: "من فضلك ادخل الوقت",
  pleaseEnterPrice: "من فضلك ادخل السعر",
  selectReservationType: "اختار نوع الحجز",
  enterCount: "ادخل العدد",
  enterTime: "ادخل الوقت",
  enterPrice: "ادخل السعر",
  editReservationSetting: "تعديل اعداد الحجز",
  rateDiscount: "معدل الخصم",
  numberOfUse: "عدد الاستخدام",

  //home
  socialMedia: "التواصل الاجتماعى",
  facebook: "فيسبوك",
  instagram: "انستغرام",
  twitter: "تويتر",
  visitOurPageOnFacebook: "قم بزيارة صفحتنا على فيسبوك",
  visitOurPageOnInstagram: "قم بزيارة صفحتنا على انستغرام",
  visitOurPageOnTwitter: "قم بزيارة صفحتنا على تويتر",
  aboutUs: "معلومات عنا",
  show: "عرض",
  reservationDate: "تاريخ الحجز",
  action: "الحدث",
  payment: "الدفع",
  attendence: "الحضور",
  cancel: "إلغاء",
  pay: "ادفع",
  paid: "تم الدفع",
  attend: "حضر",
  attended: "تم الحضور",
  canNotCancel: "لا يمكن الإلغاء",
  create: "إضافة",
  successfullyAdded: "تمت الاضافة بنجاح",
  successfullyEdited: "تم التعديل بنجاح",
  areYouSure: "هل أنت متاكد ؟",
  Items: "العناصر",
  price: "السعر",
  paymentLink: "رابط الدفع",
  returnMoney: "استرداد المال",
  noPaymentLink: "لا يوجد رابط دفع",
  returnedMoney: "تم استرداد المال",
  reservationSchedule: "مواعيد الحجوزات",
  vacationDate: "تاريخ الاجازة",
  officialVacation: "اجازة رسمي",
  addReservationSchedule: "إضافة مواعيد الحجز",
  enterFromTime: "ادخل بداية الوقت",
  enterToTime: "ادخل نهاية الوقت",
  enterVacationDate: "ادخل تاريخ الاجازة",
  roles: "الصلاحيات",
  createdDate: "تاريخ الاضافة",
  number: "الرقم",
  entries: "المدخلات",
  cannotChangeYourStatus: "لا يمكن تغيير حالتك",
  password: "كلمة المرور",

  //paused time
  pausedTime: "وقت التوقف",
  pausedDate: "تاريخ التوقف",
  addPausedTime: "إضافة تاريخ توقف",
  editPausedTime: "تعديل تاريخ التوقف",
  enterPausedDate: "أدخل تاريخ التوقف",
  smsSettings: "اعدادت الرسائل",
  smsTypeName: "اسم نوع الرساله",
  text: "النص",
  selectSmsType: "اختار نوع الرساله",
  enterText: "ادخل النص",
  pleaseSelectSmsType: "يجب اختيار نوع الرساله",
  textRequired: "يجب ادخال النص",
  addSmsSetting: "إضافة اعدادت الرسالة",

  //reports
  filter: "تصفية",
  filterReports: "تصفية التقاير",
  reports: "التقارير",
  report: "التقرير",
  cancelled: "ألغيت",
  unpaid: "غير مدفوعه",
  Export: "اصدار",
  export: "اصدار",
  editUser: "تعديل بيانات المستخدم",
  notificationTypeName: "اسم نوع الاشعار",
  textEn: "النص بالانجليزية",
  textAr: "النص بالعربية",
  titleEn: "العنوان بالانجليزية",
  titleAr: "العنوان بالعربية",
  addNotificationSetting: "إضافة اعداد الاشعار",
  editNotificationSetting: "تعديل اعداد الاشعار",
  textEnRequired: "يجب ادخال النص بالانجليزية",
  textArRequired: "يجب ادخال النص بالعربية",
  titleEnRequired: "يجب ادخال العنوان بالانجليزية",
  titleArRequired: "يجب ادخال العنوان بالعربية",
  enterTextEn: "ادخل النص بالانجليزية",
  enterTextAr: "ادخل النص بالعربية",
  enterTitleEn: "ادخل العنوان بالانجليزية",
  enterTitleAr: "ادخل العنوان بالعربية",
  selectNotificationType: "اختار نوع الاشعار",
  pleaseSelectRole: "يجب اختيار الصلاحية",
  pleaseSelectNotificationType: "يجب اختيار نوع الاشعار",
  unattended: "لم يحضر",
  loadedSuccessfully: "تم التحميل بنجاح",
  reservationReport: "تقرير الحجوزات",
  downloadPdf: "تحميل pdf",
  image: "الصورة",
  complaints: "الشكاوى",
  thereIsNoNotifications: "لا يوجد اشعارات",
  addNews: "إضافة أخبار",
  editNews: "تعديل ألاخبار",
  ViewAll: "عرض الكل",
  Markallasread: "تحديد الكل كمقروء",

  //aqua kids
  aquaKids:"أكوا كيدز",
  signInToAquaKids: "تسجيل الدخول الى أكوا كيدز",
  email: "البريد الالكترونى ",
  password: "كلمة المرور",
  forgetPass: "هل نسيت كلمة المرور ؟",
  login: "تسجيل الدخول",
  signIn: "تسجيل الدخول",
  ticketTypes: "انواع التذكرة",
  priceRequired: "السعر مطلوب",
  ticketOptions: "خيارات التذكرة",
  showInput: "اظهار صندوق الادخال",
  requests: "الطلبات",
  request: "الطلب",
  approve: "موافقة",
  selectUser: "اختار المستخدم",
  notSpecified: "لم يحدد",
  paymentId: "كود الدفع",
  result: "النتيجة",
  referenceId: "كود المرجع",
  reservationCode: "كود الحجز",
  transactionId: "كود التحويل",
  insertedDate: "تاريخ الادخال",
  amount: "المبلغ",
  errorText: "نص الخطأ",
  error: "الخطأ",
  errorCode: "كود الخطأ",
  transactionSuccess: "نجح التحويل",
  transactionFailed: "فشل التحويل",
  selectToTime: "اختار الي وقت",
  selectFromTime: "اختار من وقت",
  pleaseSelectToTime: "يجب اختيار الي وقت",
  pleaseSelectFromTime: "يجب اختيار من وقت",
  requestStatus: "حالة الطلب",
  ticketOptionList: "قائمة صلاحيات التذكرة",
  reApprove: "إعادة الموافقة",
  paying: "يدفع",
  aquaInfo1:"مغامرة اكواكيدز سفارى هي تجربة تعليمية تفاعلية تناسب جميع أفراد الأسرة (الكبار والأطفال).",
  aquaInfo2:"إنها جولة إرشادية في أربع مناطق فريدة (الصحراء والغابات المطيرة والأمازون ومنطقة أكوا).",
  aquaInfo3:"تعال وتعرف على بعض الحيوانات الغريبة لدينا واستمتع بوقتك مع فريقنا الودود.",
  aquaInfo4:"يرجى إنشاء حساب أو تسجيل الدخول والاستمتاع بمغامراتنا.",
  whatsapp:"واتساب",
  howToBookATicket:"كيف تحجز تذكرة",
  downloadTheApp:"حمل التطبيق",
  selectTime:"اختر الوقت",
  bookYourTicket:"احجز تذكرتك",
  scanForDetails:"قم بالمسح للحصول علي التفاصيل",
  galleries:"الاستوديو",
  gallery:"استوديو",
  addImage:"إضافة صورة",
  editImage:"تعديل الصورة",
  print:"طباعة",
  completed:"مكتمل",
  thereAreReservationsInThisDate:"يوجد حجوزات فى هذا التاريخ",
  mobileVerified:"التحقق من الموبايل",
  schoolTourRanges:"نطاقات الرحلة المدرسية",
  schoolTourRange:"نطاق الرحله المدرسية",
  toMustBeGreaterThanFrom:"من يجب ان تكون اكبر من إلى",
  theNumberMustBeGreaterThan1:"يجب ان يكون الرقم اكبر من 1",
  countFromRequired:"يجب ادخال العدد من",
  countToRequired:"يجب ادخال العدد الي",
  countFrom:"العدد من",
  countTo:"العدد الي",
  company:"الشركة",
  companies:"الشركات",
  addRequest:"إضافة طلب",
  enterReservationDate:"ادخل تاريخ الحجز",
  companyReservation:"حجز الشركة",
  companyReservations:"حجوزات الشركة",
  addCompanyReservation:"اضافة حجز شركة",
  editCompanyReservation:"تعديل حجز شركة",
  pleaseEnterCorrectCount:"يجب ادخال الرقم الصحيح",
  totalPrice:"السعر الكلي",
  editRequest:"تعديل الطلب",
  selectCompany:"اختار الشركة",
  pleaseSelectCompany:"يجب اختيار الشركة",
  pleaseSelectTicketType:"يجب اختيار نوع التذكرة",
  selectTicketType:"اختار نوع التذكرة",
  ticketType:"نوع التذكرة",
  ReservationDetails:"تفاصيل الحجز",
  pleaseEnterUserName:"يجب ادخال اسم المستخدم",
  pleaseSelectTicketOption: "يجب اختيار خيارات التذكرة",
  selectTicketOption: "اختار خيارات التذكرة",
  timeNotAvailable:"الوقت غير متاح",
  ticketOption:"خيارات التذكرة",
  companyReservationReport:"تقرير حجوزات الشركة",
};

export default arabicStrings;