import {
    UPDATE_STATUS_ROLE,
    UPDATE_STATUS_ROLE_SUCCESS,
    UPDATE_STATUS_ROLE_FAILURE
} from '../../../actions/Roles/Roles/updateStatusRoleActions';

export function updateStatusRoleReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_ROLE:
            return {
                ...state,
                isUpdatingStatusRole: true,
                updateStatusRoleError: null,
            };
        case UPDATE_STATUS_ROLE_SUCCESS:
            return {
                ...state,
                isUpdatingStatusRole: false,
                updateStatusRoleError: null,
                updateStatusRoleResult: action.payload.data,
            };
        case UPDATE_STATUS_ROLE_FAILURE:
            return {
                ...state,
                isUpdatingStatusRole: false,
                updateStatusRoleError: action.payload.message,
            };
        default:
            return state;
}}