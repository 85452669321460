import {
    GET_TICKET_OPTIONS_IN_REQUESTS,
    GET_TICKET_OPTIONS_IN_REQUESTS_SUCCESS,
    GET_TICKET_OPTIONS_IN_REQUESTS_FAILURE
} from '../../actions/Requests/getTicketOptionsInRequestsActions';

export function getTicketOptionsInRequestsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_OPTIONS_IN_REQUESTS:
            return {
                ...state,
                isGetTicketOptionsInRequests: true,
                getTicketOptionsInRequestsError: null,
            };
        case GET_TICKET_OPTIONS_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isGetTicketOptionsInRequests: false,
                getTicketOptionsInRequestsError: null,
                ticketOptionsInRequests: action.payload.ticketOptions,
                //reservationTypeInRequests: action.payload.ticketOptions[0],
            };
        case GET_TICKET_OPTIONS_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isGetTicketOptionsInRequests: false,
                getTicketOptionsInRequestsError: action.payload.message,
            };
        default:
            return state;
    }
}