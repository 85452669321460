export const UPDATE_DISPLAY_TYPE_IN_NEWS = 'UPDATE_DISPLAY_TYPE_IN_NEWS';
export const UPDATE_DISPLAY_TYPE_IN_NEWS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_NEWS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_NEWS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_NEWS_FAILURE';

export const updateDisplayTypeInNews = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_NEWS,
    displayType
});

}
export const updateDisplayTypeInNewsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_NEWS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInNewsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_NEWS_FAILURE,
    payload: message
});