import React, { useState, useEffect } from "react";
import { DisplayTypesInReservationsConstant } from "../../constants/Reservations/DisplayTypesInReservationsConstant";
import Reservation from './Reservation';
import Add from './Add';
import Edit from './Edit';
import Cancel from './Cancel';
import Details from './Details';
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function Reservations(props) {


    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInReservations === DisplayTypesInReservationsConstant.LIST ? strings.reservations :
                            props.displayTypeInReservations === DisplayTypesInReservationsConstant.ADD ? strings.create :
                                props.displayTypeInReservations === DisplayTypesInReservationsConstant.EDIT ? strings.edit : props.displayTypeInReservations === DisplayTypesInReservationsConstant.CANCEL ? strings.cancel : props.displayTypeInReservations === DisplayTypesInReservationsConstant.DETAILS ? strings.details : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>
                            {
                                props.displayTypeInReservations !== DisplayTypesInReservationsConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInReservations(DisplayTypesInReservationsConstant.LIST)}>
                                        {strings.reservations}
                                    </Button> : null}

                            {props.displayTypeInReservations !== DisplayTypesInReservationsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInReservations(DisplayTypesInReservationsConstant.ADD) }}>
                                    {strings.new}
                                </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInReservations === DisplayTypesInReservationsConstant.LIST && (<Reservation {...props} />)}
                            {props.displayTypeInReservations === DisplayTypesInReservationsConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInReservations === DisplayTypesInReservationsConstant.EDIT && (<Edit {...props} />)}
                            {props.displayTypeInReservations === DisplayTypesInReservationsConstant.CANCEL && (<Cancel {...props} />)}
                            {props.displayTypeInReservations === DisplayTypesInReservationsConstant.DETAILS && (<Details {...props} />)}

                        </>

                    </div>
                </div>
            </div>


        </>
    );
}
export default Reservations