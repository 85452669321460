import {
    UPDATE_RESERVATION_TIMES_IN_RESERVATIONS,
    UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS,
    UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateReservationTimesInReservationsActions';

export function updateReservationTimesInReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TIMES_IN_RESERVATIONS:
            return {
                ...state,
                isUpdatingReservationTimesInReservations: true,
                updateReservationTimesInReservationsError: null,
            };
        case UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingReservationTimesInReservations: false,
                reservationTimeInReservations: action.payload.selectedTime,
                updateReservationTimesInReservationsError: null

            };
        case UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingReservationTimesInReservations: false,
                updateReservationTimesInReservationsError: action.payload
            };
        default:
            return state;
    }
}