import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { DisplayTypesInCompanyReservationsConstant } from "../../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'
import moment from "moment";
import Select from "react-select";

function CompanyReservation(props) {

  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    [companyId, setCompanyId] = useState(null),
    [selectedDate, setSelectedDate] = useState(null),
    handleTableLength = (selected) => { props.getCompanyReservationsInCompanyReservations(null, 1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getCompaniesInCompanyReservations();
    props.getCompanyReservationsInCompanyReservations();
  }, []);

  useEffect(() => {
    if (
      props.deleteCompanyReservationResult !== null &&
      props.deleteCompanyReservationResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deletedSuccessfully}`, props.deleteCompanyReservationResult.message, "success").then(result => {
        props.getCompanyReservationsInCompanyReservations(companyId, props.companyReservationsPageNumberInCompanyReservations, props.companyReservationsPageSizeInCompanyReservations, search);
      });
    }
  }, [props.deleteCompanyReservationResult]);

  useEffect(() => {
    if (
      props.deleteCompanyReservationError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteCompanyReservationError, "error");
    }
  }, [props.deleteCompanyReservationError]);

  useEffect(() => {
    if (props.changeIsPaidInCompanyReservationsResult !== null && props.changeIsPaidInCompanyReservationsResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.changeIsPaidInCompanyReservationsResult.message,
        "success"
      ).then(res => {

      }
      );
    }
  }, [props.changeIsPaidInCompanyReservationsResult]);

  useEffect(() => {
    if (props.changeIsPaidInCompanyReservationsError !== null) {
      Swal.fire(`! ${strings.error}`, props.changeIsPaidInCompanyReservationsError, "error");
    }
  }, [props.changeIsPaidInCompanyReservationsError]);


  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.companyReservationsPageNumberInCompanyReservations) {
      props.getCompanyReservationsInCompanyReservations(companyId, pageNumber, props.companyReservationsPageSizeInCompanyReservations, search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getCompanyReservationsInCompanyReservations(companyId, 1, props.companyReservationsPageSizeInCompanyReservations, selected.target.value)
  }

  function handleDateChange(selected) {
    setSelectedDate(selected);
    if (selected !== null) {
      props.getCompanyReservationsInCompanyReservations(1, props.companyReservationsPageSizeInCompanyReservations, search, moment(selected).locale("en").format('lll'))
    }
    else {
      props.getCompanyReservationsInCompanyReservations(1, props.companyReservationsPageSizeInCompanyReservations, search)
    }
  }
  function handleCompanyChanges(selectedOption) {
    if (selectedOption != null) {
      setCompanyId(selectedOption.value)
      props.getCompanyReservationsInCompanyReservations(selectedOption.value)
    } else {
      setCompanyId(null)
      props.getCompanyReservationsInCompanyReservations()
    }
    props.updateCompaniesInCompanyReservations(selectedOption);
  }
  return (
    <>
      <div className="customer-data mt-5">
        {
          props.isGetCompanyReservationsInCompanyReservations
          && (<Loading />)
        }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              showDateInput={false}
              // selectedDate = {selectedDate}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length, { label: strings.all, value: props.companyReservationsTotalCountInCompanyReservations }]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.companyReservationsPageSizeInCompanyReservations)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.companyReservationsTotalCountInCompanyReservations}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            // handleDateChange={handleDateChange}
            />
            <div className="w-25 mb-5 mt-0">
              <label className="form-label">{strings.company}</label>
              <Select
                name="company"
                options={props.companiesInCompanyReservations}
                placeholder={strings.selectCompany}
                value={props.companyInCompanyReservations}
                onChange={handleCompanyChanges}
                isSearchable
                isClearable
                isLoading={props.isGetCompaniesInCompanyReservations}
              />
            </div>
          </div>

          <div className="table-responsive p-0">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.company}</th>
                  <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                  <th className="ps-4 min-w-125px px-0">{strings.details}</th>
                  <th className="min-w-125px px-0">{strings.mobile}</th>
                  <th className="min-w-125px px-0">{strings.reservationType}</th>
                  <th className="min-w-125px px-0">{strings.code}</th>
                  <th className="min-w-125px px-0">{strings.fromTime}</th>
                  <th className="min-w-125px px-0">{strings.toTime}</th>
                  <th className="min-w-125px px-0">{strings.day}</th>
                  <th className="min-w-125px px-0">{strings.reservationDate}</th>
                  <th className="min-w-125px px-0">{strings.count}</th>
                  <th className="min-w-125px px-0">{strings.totalAmount}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn2">{strings.cancel}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">

                {
                  props.companyReservationsInCompanyReservations !== []
                  && props.companyReservationsInCompanyReservations.length > 0
                  && props.companyReservationsInCompanyReservations.map((companyReservation, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.companyNameAr : companyReservation.companyNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.userNameAr : companyReservation.userNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <div className="action1">
                              <a
                                onClick={() => {
                                  props.getReservationDetails(companyReservation.reservationId);
                                  props.updateDisplayTypeInCompanyReservations(DisplayTypesInCompanyReservationsConstant.DETAILS);
                                }}
                              >
                                <i className={strings.getLanguage() === LanguagesConstant.AR ? "fas fa-caret-square-left edit" : "fas fa-caret-square-right edit"}></i>
                              </a>

                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.phoneNumber}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.reservationTypeNameAr : companyReservation.reservationTypeNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.reservationCode}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.fromTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.toTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {strings.getLanguage() === LanguagesConstant.EN ? companyReservation.dayNameEn : companyReservation.dayNameAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.reservationDateStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.count}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{companyReservation.totalAmount}</h4>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn2 px-0">
                        <div className="form-check form-switch flex-center">
                          <input
                            type="checkbox"
                            name="FlashesColors"
                            className="form-check-input"
                            id={`chkActive${index}`}
                            checked={companyReservation.isPaid}
                            onChange={e =>
                              props.changeIsPaidInCompanyReservations(companyReservation.id)}
                          />
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            {
                              companyReservation.isCanEdit
                              && (
                                <div className="action1">
                                  <a
                                    onClick={() => {
                                      props.getEditedCompanyReservation(companyReservation.id);
                                      props.updateDisplayTypeInCompanyReservations(DisplayTypesInCompanyReservationsConstant.EDIT);
                                    }}
                                  >
                                    <i className="fas fa-edit edit"></i>
                                  </a>
                                </div>)
                            }
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, companyReservation.id); }}
                              >
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer p-2">
            {
              props.companyReservationsTotalCountInCompanyReservations !== null
              && props.companyReservationsTotalCountInCompanyReservations > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.companyReservationsPageNumberInCompanyReservations - 1) * props.companyReservationsPageSizeInCompanyReservations) + 1} {strings.to} {(props.companyReservationsPageNumberInCompanyReservations * props.companyReservationsPageSizeInCompanyReservations) > props.companyReservationsTotalCountInCompanyReservations ? props.companyReservationsTotalCountInCompanyReservations : (props.companyReservationsPageNumberInCompanyReservations * props.companyReservationsPageSizeInCompanyReservations)} {strings.of} {props.companyReservationsTotalCountInCompanyReservations} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.companyReservationsPageNumberInCompanyReservations}
                    itemsCountPerPage={props.companyReservationsPageSizeInCompanyReservations}
                    totalItemsCount={props.companyReservationsTotalCountInCompanyReservations}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyReservation;