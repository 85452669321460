import strings from '../../localizations/strings';
import Swal from "sweetalert2";

export const deleteRow = (props,id) => {
    Swal.fire({
        title: `${strings.areYouSure}`,
        // text: `! ${strings.youWillNotBeAbleToRevertThis}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: ` ${strings.yes}`,
        cancelButtonText: `${strings.cancel}`,
        preConfirm: () => {props.deleteCompanyReservation(id)
          
        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}


