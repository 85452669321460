import {
    UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES,
    UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_FAILURE
} from '../../../src/actions/TicketTypes/updateDisplayTypeInTicketTypesActions';

export function updateDisplayTypeInTicketTypesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES:
            return {
                ...state,
                isUpdatingDisplayTypeInTicketTypes: true,
                updateDisplayTypeErrorInTicketTypes: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInTicketTypes: false,
                displayTypeInTicketTypes: action.payload.displayType,
                updateDisplayTypeErrorInTicketTypes: null,

                isEditingTicketType: false,
                editTicketTypeError: null,
                editTicketTypeResult:null,

                isDeletingTicketType: false,
                deleteTicketTypeError: null,
                deleteTicketTypeResult:null,

                isAddNewTicketType: false,
                addNewTicketTypeError: null,
                addNewTicketTypeResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInTicketTypes: false,
                updateDisplayTypeErrorInTicketTypes: action.payload
            };
        default:
            return state;
    }
}