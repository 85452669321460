import {
    GET_EDITED_RESERVATION_TYPE_SETTING,
    GET_EDITED_RESERVATION_TYPE_SETTING_SUCCESS,
    GET_EDITED_RESERVATION_TYPE_SETTING_FAILURE
} from '../../actions/ReservationTypeSettings/getEditedReservationTypeSettingActions';

export function getEditedReservationTypeSettingReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_RESERVATION_TYPE_SETTING:
            return {
                ...state,
                isGetEditedReservationTypeSetting: true,
                getEditedReservationTypeSettingError: null, 
            };
        case GET_EDITED_RESERVATION_TYPE_SETTING_SUCCESS:
            return {
                ...state,
                isGetEditedReservationTypeSetting: false,
                getEditedReservationTypeSettingError: null,
                editedReservationTypeSettingInReservationTypeSettings:state.reservationTypeSettingsInReservationTypeSettings.filter(x=>x.id == action.payload.reservationTypeSettingId )[0],

            };
        case GET_EDITED_RESERVATION_TYPE_SETTING_FAILURE:
            return {
                ...state,
                isGetEditedReservationTypeSetting: false,
                getEditedReservationTypeSettingError: action.payload.message,
            };
        default:
            return state;
}}