import React, { useEffect, forwardRef, useState, useRef } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInAttendedReservationsConstant } from "../../constants/Reservations/DisplayTypesInAttendedReservationsConstant";
import strings from "../../localizations/strings";
import moment from 'moment';
import "moment/locale/ar-sa";
import { Button } from "react-bootstrap";
import { ReservationTypesConstant } from "../../constants/ReservationTypesConstant";
import { useReactToPrint } from 'react-to-print';

import { Print } from './Print';

function Details(props) {
  const formikRef = React.useRef(null);

  let fromTimeStr = "";
  let toTimeStr = "";

  moment.locale(strings.getLanguage() === LanguagesConstant.AR ? "ar-sa" : "en");

  useEffect(() => {
    if (props.reservationDetailsInReservations !== null) {
      fromTimeStr = moment(props.reservationDetailsInReservations.fromTime).format("hh:mm A");
      toTimeStr = moment(props.reservationDetailsInReservations.toTime).format("hh:mm A");
      if (strings.getLanguage() === LanguagesConstant.AR) {
        fromTimeStr = fromTimeStr.replace("AM", "ص").replace("PM", "م");
        toTimeStr = toTimeStr.replace("AM", "ص").replace("PM", "م");
      }
      formikRef.current.setFieldValue("fromTime", fromTimeStr);
      formikRef.current.setFieldValue("toTime", toTimeStr);
    }
  }, [props.reservationDetailsInReservations]);

  useEffect(() => {
    if (props.updateAttendenceStatusReservationResult !== null && props.updateAttendenceStatusReservationResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.updateAttendenceStatusReservationResult.message,
        "success"
      ).then(result => {
        props.clearReservationDetails();
      }
      );
    }
  }, [props.updateAttendenceStatusReservationResult]);

  useEffect(() => {
    if (props.updateAttendenceStatusReservationError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateAttendenceStatusReservationError, "error");
    }
  }, [props.updateAttendenceStatusReservationError]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => props.clearReservationDetails()
  });

  return (
    <>
      <div className="d-none">
        <Print {...props} ref={componentRef} /></div>
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.details}
              </span>
            </h3>
            <div className="d-flex">
              <Button className="btn btn-success"
                onClick={() => {
                  props.updateAttendenceStatusReservation(props.reservationDetailsInReservations.id);
                }}>
                {strings.attend}</Button>
              <Button className="mx-3" onClick={handlePrint}>{strings.print}</Button>
              <Button className="btn btn-danger" onClick={() => {
                  props.clearReservationDetails();
                }}>{strings.reset}</Button>
            </div>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={{
                userName: props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? props.reservationDetailsInReservations.userNameAr : props.reservationDetailsInReservations.userNameEn) : "",
                mobile: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.phoneNumber : "",
                reservationCode: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.reservationCode : "",
                reservationType: props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? props.reservationDetailsInReservations.reservationTypeNameAr : props.reservationDetailsInReservations.reservationTypeNameEn) : "",
                reservationDate: props.reservationDetailsInReservations !== null ? moment(props.reservationDetailsInReservations.reservationDate).format("DD MMM yyyy") : "",
                fromTime: fromTimeStr,
                toTime: toTimeStr,
                count: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.count : "",
                amount: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.amount : "",
                totalAmount: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.totalAmount : "",
                rate: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.discountRate : "",
                status: props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? props.reservationDetailsInReservations.reservationStatusNameAr : props.reservationDetailsInReservations.reservationStatusNameEn) : "",
                insertedDate: props.reservationDetailsInReservations !== null ? props.reservationDetailsInReservations.insertedDate : "",
                ticketType: props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? props.reservationDetailsInReservations.ticketTypeListAr?.join('-') : props.reservationDetailsInReservations.ticketTypeListEn?.join('-')) : "",
                ticketOption: props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? props.reservationDetailsInReservations.ticketOptionListAr?.join('-') : props.reservationDetailsInReservations.ticketOptionListEn?.join('-')) : "",
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.userName}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="userName"
                          className={
                            "form-control" +
                            (errors.userName &&
                              touched.userName
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.mobile}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="mobile"
                          className={
                            "form-control" +
                            (errors.mobile &&
                              touched.mobile
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.code}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="reservationCode"
                          className={
                            "form-control" +
                            (errors.reservationCode &&
                              touched.reservationCode
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.reservationType}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="reservationType"
                          className={
                            "form-control" +
                            (errors.reservationType &&
                              touched.reservationType
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.reservationDate}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="reservationDate"
                          className={
                            "form-control" +
                            (errors.reservationDate &&
                              touched.reservationDate
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.fromTime}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="fromTime"
                          className={
                            "form-control" +
                            (errors.fromTime &&
                              touched.fromTime
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.toTime}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="toTime"
                          className={
                            "form-control" +
                            (errors.toTime &&
                              touched.toTime
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.count}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="count"
                          className={
                            "form-control" +
                            (errors.count &&
                              touched.count
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>

                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.amount}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="amount"
                          className={
                            "form-control" +
                            (errors.amount &&
                              touched.amount
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.totalAmount}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="totalAmount"
                          className={
                            "form-control" +
                            (errors.totalAmount &&
                              touched.totalAmount
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>

                    </div>
                    {
                      values.rate != null && values.rate != ""
                      && (
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.discounts}</label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="text"
                              name="rate"
                              className={
                                "form-control" +
                                (errors.rate &&
                                  touched.rate
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={true}
                            />
                          </div>
                        </div>
                      )
                    }
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.status}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="status"
                          className={
                            "form-control" +
                            (errors.status &&
                              touched.status
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>

                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.date}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="insertedDate"
                          className={
                            "form-control" +
                            (errors.insertedDate &&
                              touched.insertedDate
                              ? " is-invalid"
                              : "")
                          }
                          disabled={true}
                        />
                      </div>

                    </div>
                    {props.reservationDetailsInReservations !== null && props.reservationDetailsInReservations.reservationTypeId == ReservationTypesConstant.General ?
                      (<div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.ticketTypes}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="ticketType"
                            className={
                              "form-control" +
                              (errors.ticketType &&
                                touched.ticketType
                                ? " is-invalid"
                                : "")
                            }
                            disabled={true}
                          />
                        </div>

                      </div>)
                      :
                      (<div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.ticketOptions}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="ticketOption"
                            className={
                              "form-control" +
                              (errors.ticketOption &&
                                touched.ticketOption
                                ? " is-invalid"
                                : "")
                            }
                            disabled={true}
                          />
                        </div>

                      </div>)
                    }
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Details;
