import {
    UPDATE_DISPLAY_TYPE_IN_ROLE_USERS,
    UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_FAILURE
} from '../../../actions/Roles/RoleUsers/updateDisplayTypeInRoleUsersActions';

export function updateDisplayTypeInRoleUsersReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_ROLE_USERS:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleUsers: true,
                updateDisplayTypeErrorInRoleUsers: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleUsers: false,
                displayTypeInRoleUsers: action.payload.displayType,
                updateDisplayTypeErrorInRoleUsers: null,

                isDeletingRoleUser: false,
                deleteRoleUserError: null,
                deleteRoleUserResult: null,

                isCreateNewRoleUser: false,
                createNewRoleUserError: null,
                createNewRoleUserResult:null,

                isEditingRoleUser: false,
                editRoleUserError: null,
                editRoleUserResult:null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleUsers: false,
                updateDisplayTypeErrorInRoleUsers: action.payload
            };
        default:
            return state;
    }
}