export const GET_TICKET_TYPES_IN_TICKET_TYPES = 'GET_TICKET_TYPES_IN_TICKET_TYPES';
export const GET_TICKET_TYPES_IN_TICKET_TYPES_SUCCESS = 'GET_TICKET_TYPES_IN_TICKET_TYPES_SUCCESS';
export const GET_TICKET_TYPES_IN_TICKET_TYPES_FAILURE = 'GET_TICKET_TYPES_IN_TICKET_TYPES_FAILURE';

export const getTicketTypesInTicketTypes = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get ticketTypes action')
return({
    type: GET_TICKET_TYPES_IN_TICKET_TYPES,
    pageNumber,
    pageSize,
    search
}); 
}
export const getTicketTypesInTicketTypesSuccess = (ticketTypes,pageNumber,pageSize) => ({
    type: GET_TICKET_TYPES_IN_TICKET_TYPES_SUCCESS,
    payload: { ticketTypes ,pageNumber,pageSize}
});

export const getTicketTypesInTicketTypesFailure = (message) => ({
    type: GET_TICKET_TYPES_IN_TICKET_TYPES_FAILURE,
    payload: { message }
});