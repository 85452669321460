export const EDIT_PAUSED_TIME = 'EDIT_PAUSED_TIME';
export const EDIT_PAUSED_TIME_SUCCESS = 'EDIT_PAUSED_TIME_SUCCESS';
export const EDIT_PAUSED_TIME_FAILURE = 'EDIT_PAUSED_TIME_FAILURE';

export const editPausedTime = (pausedTimeData) => ({
    type: EDIT_PAUSED_TIME,
    pausedTimeData
});

export const editPausedTimeSuccess = (data) => ({
    type: EDIT_PAUSED_TIME_SUCCESS,
    payload: { data }
});

export const editPausedTimeFailure = (message) => ({
    type: EDIT_PAUSED_TIME_FAILURE,
    payload: { message }
});