import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInReservationTypesConstant } from "../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Loading from "../Shared/Loading/Loading";

function ReservationType(props) {
  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getReservationTypesInReservationTypes(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getReservationTypesInReservationTypes();
  }, []);

  useEffect(() => {
    if (
      props.deleteReservationTypeResult !== null &&
      props.deleteReservationTypeResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteReservationTypeResult.message, "success").then(result => {
        props.getReservationTypesInReservationTypes(props.reservationTypesPageNumberInReservationTypes, props.reservationTypesPageSizeInReservationTypes,search);
      });
    }
  }, [props.deleteReservationTypeResult]);

  useEffect(() => {
    if (props.updateStatusInReservationTypesResult !== null && props.updateStatusInReservationTypesResult.isSuccess) {
        Swal.fire(
            ` ${strings.changedSuccessfully}`,
            props.updateStatusInReservationTypesResult.message,
            "success"
        ).then(res => {
            
        }
        );
    }
}, [props.updateStatusInReservationTypesResult]);

useEffect(() => {
  if (props.updateStatusInReservationTypesError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusInReservationTypesError, "error");
  }
}, [props.updateStatusInReservationTypesError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.reservationTypesPageNumberInReservationTypes) {
        props.getReservationTypesInReservationTypes(pageNumber, props.reservationTypesPageSizeInReservationTypes, search);
    }
}

function handleinputSearch(selected) {
  console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getReservationTypesInReservationTypes(1, props.reservationTypesPageSizeInReservationTypes, selected.target.value)
}
  return (
    <>
      <div className="customer-data mt-5">
      {
                  props.isGetReservationTypesInReservationTypes
                  && (<Loading />)
                }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
          <TableHeader
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.reservationTypesTotalCountInReservationTypes}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.reservationTypesPageSizeInReservationTypes)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.reservationTypesTotalCountInReservationTypes}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
          </div>

          <div className="table-responsive p-0">
              <table className="table align-middle text-center table-bordered border-bottom m-0">
                <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                  <tr className="fw-bolder text-muted text-center align-middle bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.nameAr}</th>
                    <th className="min-w-125px px-0">{strings.nameEn}</th>
                    <th className="min-w-125px px-0">{strings.status}</th>
                    <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                      {strings.action}
                    </th>
                  </tr>
                </thead>
                <tbody className="tableBody">
               
                { props.reservationTypesInReservationTypes !== [] &&
                          props.reservationTypesInReservationTypes.length > 0 &&
                          props.reservationTypesInReservationTypes.map((reservationType, index) => {
                            return (
                              <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{reservationType.nameAr}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{reservationType.nameEn}</h4>
                        </div>
                      </div>
                    </td>
                    <td className="min-w-100px text-dark fw-bolder text-hover-primary fs-6 px-0">
                          <div className="form-check form-switch flex-center">
                              <input
                                  type="checkbox"
                                  name="FlashesColors"
                                  className="form-check-input"
                                  id={`chkActive${index}`}
                                  checked={reservationType.isActive}
                                  onChange={e =>
                                      props.updateStatusInReservationTypes(reservationType.id)}
                              />
                          </div>
                      </td>
                    <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                      <div className="d-flex justify-content-center">
                        <div
                          className="actions"
                          style={{ backgroundColor: "#e4e6ef" }}
                        >
                          <div className="action1">
                           <a  onClick={() => {
                              props.getEditedReservationType(reservationType.id); 
                             props.updateDisplayTypeInReservationTypes(
                              DisplayTypesInReservationTypesConstant.EDIT
                            )
                            }}>
                           
                              <i className="fas fa-edit edit"></i>
                            </a>
                          
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
                          })} 
                </tbody>
              </table>
          </div>

          <div className="card-footer">
        {
            props.reservationTypesTotalCountInReservationTypes !== null
            && props.reservationTypesTotalCountInReservationTypes > 0
            && (
                <div className="d-flex flex-stack flex-wrap ">
                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.reservationTypesPageNumberInReservationTypes - 1) * props.reservationTypesPageSizeInReservationTypes) + 1} {strings.to} {(props.reservationTypesPageNumberInReservationTypes * props.reservationTypesPageSizeInReservationTypes) > props.reservationTypesTotalCountInReservationTypes ? props.reservationTypesTotalCountInReservationTypes : (props.reservationTypesPageNumberInReservationTypes * props.reservationTypesPageSizeInReservationTypes)} {strings.of} {props.reservationTypesTotalCountInReservationTypes} {strings.entries}</div>
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activePage={props.reservationTypesPageNumberInReservationTypes}
                        itemsCountPerPage={props.reservationTypesPageSizeInReservationTypes}
                        totalItemsCount={props.reservationTypesTotalCountInReservationTypes}
                        onChange={handlePageButtonChange}
                    />
                </div>
            )}
            </div>
        </div>
      </div>
    </>
  );
}
export default ReservationType;
