import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS,
    updateCompanyReservationTimesInCompanyReservationsSuccess,
    updateCompanyReservationTimesInCompanyReservationsFailure
} from '../../actions/CompanyReservations/updateCompanyReservationTimesInCompanyReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function updateCompanyReservationTimesInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS),
        map(action => ({
            selectedTime: action.selectedTime
        })),
        map(response =>
            updateCompanyReservationTimesInCompanyReservationsSuccess(response.selectedTime)
        ),
        catchError(error => of(updateCompanyReservationTimesInCompanyReservationsFailure
            (strings.somethingWrongHappened)))
    )
}