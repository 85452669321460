export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = (changePasswordViewModel) => ({
    type: CHANGE_PASSWORD,
    changePasswordViewModel
});

export const changePasswordSuccess = (data) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { data }
});

export const changePasswordFailure = (message) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: { message }
});