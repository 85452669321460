import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_REQUESTS,
    updateDisplayTypeInRequestsSuccess,
    updateDisplayTypeInRequestsFailure
} from '../../actions/Reservations/updateDisplayTypeInRequestsActions';
import strings from '../../localizations/strings';

export function updateDisplayTypeInRequestsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_REQUESTS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInRequestsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInRequestsFailure(strings.somethingWrongHappened)))
    )
}

