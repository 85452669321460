import React, { useEffect, useState } from "react";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInNewsConstant } from "../../constants/News/DisplayTypesInNewsConstant";
import Loading from "../Shared/Loading/Loading";
import ImageUploader from 'react-image-upload';
import 'react-image-upload/dist/index.css'

function Add(props) {

  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (
      props.addNewNewResult !== null &&
      props.addNewNewResult.isSuccess
    ) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewNewResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInNews(
          DisplayTypesInNewsConstant.LIST
        );
      });
    }
  }, [props.addNewNewResult]);

  useEffect(() => {
    if (
      props.addNewNewError !== null
    ) {
      Swal.fire(
        `! ${strings.error}`,
        props.addNewNewError,
        'error'
      );
    }
  }, [props.addNewNewError]);

  function getImageFileObject(imageFile) {
    setImageFile(imageFile)
  }
  function runAfterImageDelete(file) {
    setImageFile(file)
  }

  return (
    <>
      {props.isAddNewNew && (<Loading />)}
      {!props.isAddNewNew &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.addNews}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                initialValues={
                  {
                    textAr: "",
                    textEn: "",
                  }
                }
                validationSchema={Yup.object().shape({
                  textAr: Yup.string().required(
                    strings.textArRequired
                  ),
                  textEn: Yup.string().required(
                    strings.textEnRequired
                  ),
                })}
                onSubmit={(fields) => {
                  var formData = new FormData();
                  formData.append("textEn", fields.textEn);
                  formData.append("textAr", fields.textAr);
                  formData.append("isActive", true);
                  if (imageFile != null && imageFile != "") {
                    formData.append("image", imageFile.file);
                  }

                  props.addNewNew(formData);

                }}
                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.textEn}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="textEn"
                            placeholder={`${strings.enterTextEn} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.textEn &&
                                touched.textEn
                                ? " is-invalid"
                                : "")
                            }
                            component="textarea"
                            rows={3}
                          />
                          <ErrorMessage name="textEn" component="div" className="invalid-feedback" />


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.textAr}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="textAr"
                            placeholder={`${strings.enterTextAr} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.textAr &&
                                touched.textAr
                                ? " is-invalid"
                                : "")
                            }
                            component="textarea"
                            rows={3}
                          />
                          <ErrorMessage name="textAr" component="div" className="invalid-feedback" />


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.image}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <ImageUploader
                            style={{ height: 150, width: 300 }}
                            deleteIcon={
                              <img
                                src='https://img.icons8.com/ios-glyphs/30/000000/delete-sign.png'
                                alt=''
                              />
                            }
                            onFileAdded={(img) => getImageFileObject(img)}
                            onFileRemoved={(img) => runAfterImageDelete(img)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-between p-3">
                      <div>
                        <button
                          type="submit"
                          className="btn fs-6"
                          style={{
                            backgroundColor: "#009ef7",
                            borderColor: "#009ef7",
                            color: "white",
                            borderRadius: "0.75rem",
                          }}
                          id="kt_account_profile_details_submit"
                        //	onClick={done}
                        >
                          {strings.save}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Add;
