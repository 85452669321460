import {
    GET_RESERVATIONS_IN_RESERVATIONS,
    GET_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    GET_RESERVATIONS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getReservationsInReservationsActions';

export function getReservationsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATIONS_IN_RESERVATIONS:
            return {
                ...state,
                isGetReservationsInReservations: true,
                getReservationsInReservationsError: null,
                reservationsInReservations: [],
            };
        case GET_RESERVATIONS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetReservationsInReservations: false,
                getReservationsInReservationsError: null,
                reservationsInReservations: action.payload.reservations.data,
                reservationsPageNumberInReservations: action.payload.pageNumber,
                reservationsPageSizeInReservations: action.payload.pageSize,
                reservationsTotalCountInReservations: action.payload.reservations.total,
            };
        case GET_RESERVATIONS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetReservationsInReservations: false,
                getReservationsInReservationsError: action.payload.message,
            };
        default:
            return state;
}}