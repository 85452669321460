import {
    UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../actions/NotificationSettings/updateNotificationRolesInNotificationSettingsActions';

export function updateNotificationRolesInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isUpdateNotificationRolesInNotificationSettings: true,
                updateNotificationRolesInNotificationSettingsError: null,
            };
        case UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                isUpdateNotificationRolesInNotificationSettings: false,
                updateNotificationRolesInNotificationSettingsError: null,
                notificationRoleInNotificationSettings: action.payload.notificationRole,
            };
        case UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdateNotificationRolesInNotificationSettings: false,
                updateNotificationRolesInNotificationSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}