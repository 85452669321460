export const UPDATE_STATUS_ROLE_SCREEN = 'UPDATE_STATUS_ROLE_SCREEN';
export const UPDATE_STATUS_ROLE_SCREEN_SUCCESS = 'UPDATE_STATUS_ROLE_SCREEN_SUCCESS';
export const UPDATE_STATUS_ROLE_SCREEN_FAILURE = 'UPDATE_STATUS_ROLE_SCREEN_FAILURE';

export const updateStatusRoleScreen = (roleScreenId,notes = null) => ({
    type: UPDATE_STATUS_ROLE_SCREEN,
    roleScreenId,
    notes
});

export const updateStatusRoleScreenSuccess = (data) => ({
    type: UPDATE_STATUS_ROLE_SCREEN_SUCCESS,
    payload: { data }
});

export const updateStatusRoleScreenFailure = (message) => ({
    type: UPDATE_STATUS_ROLE_SCREEN_FAILURE,
    payload: { message }
});