import {
    ADD_NEW_COMPANY,
    ADD_NEW_COMPANY_SUCCESS,
    ADD_NEW_COMPANY_FAILURE
} from '../../actions/Companies/addNewCompanyActions';

export function addNewCompanyReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_COMPANY:
            return {
                ...state,
                isAddNewCompany: true,
                addNewCompanyError: null,
                addNewCompanyResult:null,
            };
        case ADD_NEW_COMPANY_SUCCESS:
            return { 
                ...state,
                isAddNewCompany: false,
                addNewCompanyError: null,
                addNewCompanyResult: action.payload.data,
            };
        case ADD_NEW_COMPANY_FAILURE:
            return {
                ...state,
                isAddNewCompany: false,
                addNewCompanyError: action.payload.message,
            };
        default:
            return state;
}}