import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS,
    updateDisplayTypeInCompanyReservationsSuccess,
    updateDisplayTypeInCompanyReservationsFailure
} from '../../actions/CompanyReservations/updateDisplayTypeInCompanyReservationsActions';
import strings from '../../localizations/strings';

export function updateDisplayTypeInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInCompanyReservationsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInCompanyReservationsFailure(strings.somethingWrongHappened)))
    )
}

