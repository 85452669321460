import React,{useState,useEffect} from "react";
import { DisplayTypesInTicketOptionsConstant } from "../../../src/constants/TicketOptions/DisplayTypesInTicketOptionsConstant";
import TicketOption from './TicketOption';
import Edit from './Edit';
import Add from './Add';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function TicketOptions(props){

    return (
        <>
                  <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInTicketOptions === DisplayTypesInTicketOptionsConstant.LIST ? strings.ticketOptions:
                        props.displayTypeInTicketOptions === DisplayTypesInTicketOptionsConstant.ADD ? strings.create:
                        props.displayTypeInTicketOptions === DisplayTypesInTicketOptionsConstant.EDIT ? strings.edit:""

                    }
                    parentpage={strings.ticketOptions}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInTicketOptions  !== DisplayTypesInTicketOptionsConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInTicketOptions(DisplayTypesInTicketOptionsConstant.LIST)}>
                                    {strings.ticketOptions}
                                </Button> :null}

                            {props.displayTypeInTicketOptions  !== DisplayTypesInTicketOptionsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInTicketOptions(DisplayTypesInTicketOptionsConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                           
                            { props.displayTypeInTicketOptions  === DisplayTypesInTicketOptionsConstant.LIST &&
                            (<TicketOption {...props} />)}
                            {props.displayTypeInTicketOptions  === DisplayTypesInTicketOptionsConstant.ADD &&
                             (<Add {...props} />)}
                            {props.displayTypeInTicketOptions  === DisplayTypesInTicketOptionsConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
                        

        </>
    );
}
export default TicketOptions