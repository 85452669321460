import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_COMPANIES,
    updateDisplayTypeInCompaniesSuccess,
    updateDisplayTypeInCompaniesFailure
} from '../../../src/actions/Companies/updateDisplayTypeInCompaniesActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInCompaniesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_COMPANIES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response => updateDisplayTypeInCompaniesSuccess(response.displayType)
        ),
        catchError(error => Observable.of(updateDisplayTypeInCompaniesFailure(strings.somethingWrongHappened)))
    )
}

