export const DELETE_ROLE_SCREEN = 'DELETE_ROLE_SCREEN';
export const DELETE_ROLE_SCREEN_SUCCESS = 'DELETE_ROLE_SCREEN_SUCCESS';
export const DELETE_ROLE_SCREEN_FAILURE = 'DELETE_ROLE_SCREEN_FAILURE';

export const deleteRoleScreen = (roleScreenId) => ({
    type: DELETE_ROLE_SCREEN,
    roleScreenId
});

export const deleteRoleScreenSuccess = (data) => ({
    type: DELETE_ROLE_SCREEN_SUCCESS,
    payload: { data }
});

export const deleteRoleScreenFailure = (message) => ({
    type: DELETE_ROLE_SCREEN_FAILURE,
    payload: { message }
});