import React, { useState, useEffect, useRef ,forwardRef} from "react";
import Swal from "sweetalert2";
import strings from '../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DisplayTypesInCompaniesConstant } from "../../constants/Companies/DisplayTypesInCompaniesConstant";
import Loading from "../Shared/Loading/Loading";
import 'react-phone-number-input/style.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
function Add(props) {
  const [insertedDateError, setInsertedDateError] = useState(null);
  const formikRef = React.useRef(null);

  useEffect(() => {
    if (props.addNewCompanyResult !== null && props.addNewCompanyResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewCompanyResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInCompanies(DisplayTypesInCompaniesConstant.LIST)
      }
      )
    }
  }, [props.addNewCompanyResult])

  useEffect(() => {
    if (props.addNewCompanyError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.addNewCompanyError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.addNewCompanyError])
  // const StartDateInput = forwardRef(({ value, onClick }, ref) => (
  //   <input type="text"
  //     ref={ref}
  //     placeholder={`${strings.insertedDate} *`}
  //     name="insertedDate"
  //     value={value}
  //     onClick={onClick}
  //     className={"form-control form-control1 form-control-lg form-control-solid"} />
  // ));
  // function handleInsertedDateChange(value) {
  //   if (!moment(value).isValid()) {
  //     setInsertedDateError(strings.dateNotValid);
  //   } else {
  //     setInsertedDateError(null);
  //     formikRef.current.setFieldValue("insertedDate", value);
  //   }
  // }

  return (
    <>
      {props.isAddNewCompany && (<Loading />)}
      {!props.isAddNewCompany &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.addCompany}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  arabicName: '',
                  englishName: '',
                  // insertedDate: '',
                }}
                validationSchema={Yup.object().shape({
                  arabicName: Yup.string()
                    .required(strings.nameArRequired),
                  englishName: Yup.string()
                    .required(strings.nameEnRequired)
                })}
                onSubmit={fields => {
                    var company = {
                      nameEn: fields.englishName,
                      nameAr: fields.arabicName,
                      // insertedDate: moment(fields.insertedDate).format("YYYY-MM-DD HH:mm:ss"),
                      isActive: true,
                    }


                    props.addNewCompany(company);
                  
                }}
                render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.arabicName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="arabicName"
                            className={'form-control' + (errors.arabicName && touched.arabicName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameAr} *`}
                          />
                          <ErrorMessage name="arabicName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.englishName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="englishName"
                            className={'form-control' + (errors.englishName && touched.englishName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameEn} *`}
                          />
                          <ErrorMessage name="englishName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.insertedDate}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker 
                            placeholderText={strings.insertedDate}
                            customInput={<StartDateInput />}
                            locale={strings.getLanguage() === LanguagesConstant.AR ?
                              "ar" : "en"}
                            selected={values.insertedDate}
                            onChange={handleInsertedDateChange}
                            minDate={new Date(moment())}
                            dateFormat="dd/MM/yyyy"
                            // className={insertedDateError !== null ? 'red-border' : ''} 
                            />
                          {/* {
                            reservationDateError && (
                              <div className="invalid-feedback block">
                                {insertedDateDateError}
                              </div>
                            )
                          } 

                        </div>
                      </div> */}
                    </div>
                    <div className="card-footer d-flex justify-content-between p-3">
                      <div>
                        <button
                          type="submit"
                          className="btn fs-6"
                          style={{
                            backgroundColor: "#009ef7",
                            borderColor: "#009ef7",
                            color: "white",
                            borderRadius: "0.75rem",
                          }}
                          id="kt_account_profile_details_submit"
                        //	onClick={done}
                        >
                          {strings.save}
                        </button>
                      </div>
                    </div>
                  </Form>

                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Add;
