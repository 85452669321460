import {
    ADD_NEW_NEW,
    ADD_NEW_NEW_SUCCESS,
    ADD_NEW_NEW_FAILURE
} from '../../actions/News/addNewNewActions';

import { DisplayTypesInNewsConstant } from '../../constants/News/DisplayTypesInNewsConstant';

export function addNewNewReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_NEW:
            return {
                ...state,
                isAddNewNew: true,
                addNewNewError: null,
                addNewNewResult:null,
            };
        case ADD_NEW_NEW_SUCCESS:
            return { 
                ...state,
                isAddNewNew: false,
                addNewNewError: null,
                addNewNewResult: action.payload.data,
                // displayTypeInNews: DisplayTypesInNewsConstant.LIST,
            };
        case ADD_NEW_NEW_FAILURE:
            return {
                ...state,
                isAddNewNew: false,
                addNewNewError: action.payload.message,
            };
        default:
            return state;
}}