import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_NEW,
    getEditedNewSuccess,
    getEditedNewFailure
} from '../../actions/News/getEditedNewActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedNewEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_NEW),
        map(action =>({
            newId : action.newId 
        })),
        map(response => 
           getEditedNewSuccess(response.newId)
           
        ),
        catchError(error => of(getEditedNewFailure(strings.somethingWrongHappened)))
    )
}

