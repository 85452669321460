import React, { useEffect, useState } from "react";
import Button from "@restart/ui/esm/Button";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInSmsSettingsConstant } from "../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant";
import strings from "../../localizations/strings";
import Loading from "../Shared/Loading/Loading";

function Edit(props) {

  const [smsTypeError, setSmsTypeError] = useState(null);

  useEffect(() => {
    props.getSmsTypesInSmsSettings(props.editedSmsSettingInSmsSettings.id);
  }, []);

  function handleSmsTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setSmsTypeError(strings.pleaseSelectSmsType)
    } else {
      setSmsTypeError(null)
    }
    props.updateSmsTypesInSmsSettings(selectedOption);
  }

  useEffect(() => {
    if (props.editSmsSettingResult !== null && props.editSmsSettingResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editSmsSettingResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInSmsSettings(DisplayTypesInSmsSettingsConstant.LIST)
      }
      )
    }
  }, [props.editSmsSettingResult])

  useEffect(() => {
    if (props.editSmsSettingError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editSmsSettingError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editSmsSettingError])

  return (
    <>
      {props.isEditSmsSetting && (<Loading />)}
      {!props.isEditSmsSetting &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.editSmsSetting}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                initialValues={{
                  text: props.editedSmsSettingInSmsSettings !== null ? props.editedSmsSettingInSmsSettings.text : "",
                }}
                validationSchema={Yup.object().shape({
                  text: Yup.string().required(
                    strings.textRequired
                  ),

                })}
                onSubmit={(fields) => {
                  let isValid = true;
                  if (props.smsTypeInSmsSettings === null) {
                    setSmsTypeError(strings.pleaseSelectSmsType);
                    isValid = false;
                  }
                  if (isValid) {
                    const smsSetting = {
                      id:props.editedSmsSettingInSmsSettings.id,
                      smsTypeId: props.smsTypeInSmsSettings.value,
                      text: fields.text,
                      isActive: true,
                    };

                    props.editSmsSetting(smsSetting);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.smsTypeName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="smsType"
                            options={props.smsTypesInSmsSettings}
                            placeholder={strings.selectSmsType}
                            value={props.smsTypeInSmsSettings}
                            onChange={handleSmsTypeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetSmsTypesInSmsSettings}
                            className={smsTypeError != null ? "is-invalid" : ""}
                          />
                          {smsTypeError && (
                            <div className="invalid-feedback block">
                              {smsTypeError}
                            </div>
                          )}
                        </div>

                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.text}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="text"
                            placeholder={`${strings.enterText} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.text &&
                                touched.text
                                ? " is-invalid"
                                : "")
                            }
                            component="textarea"
                            rows={5}
                          />
                          <ErrorMessage name="text" component="div" className="invalid-feedback" />


                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-between p-3">
                        <div>
                          <button
                            type="submit"
                            className="btn fs-6"
                            style={{
                              backgroundColor: "#009ef7",
                              borderColor: "#009ef7",
                              color: "white",
                              borderRadius: "0.75rem",
                            }}
                            id="kt_account_profile_details_submit"
                          //	onClick={done}
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Edit;
