import {
    UPDATE_STATUS_PAUSED_TIME,
    UPDATE_STATUS_PAUSED_TIME_SUCCESS,
    UPDATE_STATUS_PAUSED_TIME_FAILURE
} from '../../actions/PausedTimes/updateStatusPausedTimeActions';

import { DisplayTypesInPausedTimesConstant } from '../../constants/PausedTimes/DisplayTypesInPausedTimesConstant';

export function updateStatusPausedTimeReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_PAUSED_TIME:
            return {
                ...state,
                isUpdatingStatusPausedTime: true,
                updateStatusPausedTimeError: null,
            };
        case UPDATE_STATUS_PAUSED_TIME_SUCCESS:
            return {
                ...state,
                isUpdatingStatusPausedTime: false,
                updateStatusPausedTimeError: null,
                updateStatusPausedTimeResult: action.payload.data,
                pausedTimesInPausedTimes: state.pausedTimesInPausedTimes.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
                //  displayTypeInPausedTimes: DisplayTypesInPausedTimesConstant.LIST,
            };
        case UPDATE_STATUS_PAUSED_TIME_FAILURE:
            return {
                ...state,
                isUpdatingStatusPausedTime: false,
                updateStatusPausedTimeError: action.payload.message,
            };
        default:
            return state;
    }
}