import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES,
    checkRoleNameEnAvailabilityInRolesSuccess,
    checkRoleNameEnAvailabilityInRolesFailure
} from '../../../actions/Roles/Roles/checkRoleNameEnAvailabilityInRolesActions';

import { url, token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function checkRoleNameEnAvailabilityInRolesEpic(action$) {
    return action$.pipe(
        ofType(CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES),
        switchMap(action => {

            const queryString = action.roleId !== null ?
                `?roleNameEn=${action.nameEn}&roleId=${action.roleId}` :
                `?roleNameEn=${action.nameEn}`;
            const ajaxConfigObj = {
                url: url + `Role/CheckRoleNameEnAvailability${queryString}`,
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        map(response => {
            
            if (response.isSuccess) {
                return checkRoleNameEnAvailabilityInRolesSuccess(response.data);

            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return checkRoleNameEnAvailabilityInRolesFailure(message);
            }
        }),
        catchError(error => of(checkRoleNameEnAvailabilityInRolesFailure
            (strings.somethingWrongHappened)))
    )
}

