import {
    UPDATE_SCREENS_IN_ROLE_SCREENS,
    UPDATE_SCREENS_IN_ROLE_SCREENS_SUCCESS,
    UPDATE_SCREENS_IN_ROLE_SCREENS_FAILURE
} from '../../../actions/Roles/RoleScreens/updateScreensInRoleScreensActions';

export function updateScreensInRoleScreensReducer(state, action) {
    switch (action.type) {
        case UPDATE_SCREENS_IN_ROLE_SCREENS:
            return {
                ...state,
                isUpdateScreensInRoleScreens: true,
                updateScreensInRoleScreensError: null,
            };
        case UPDATE_SCREENS_IN_ROLE_SCREENS_SUCCESS:
            return {
                ...state,
                isUpdateScreensInRoleScreens: false,
                updateScreensInRoleScreensError: null,
                screenInRoleScreens: action.payload.screen,
            };
        case UPDATE_SCREENS_IN_ROLE_SCREENS_FAILURE:
            return {
                ...state,
                isUpdateScreensInRoleScreens: false,
                updateScreensInRoleScreensError: action.payload.message,
            };
        default:
            return state;
}}