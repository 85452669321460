export const GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS = 'GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS';
export const GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE';

export const getReservationTypesInCompanyReservations = () => ({
    type: GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
}); 

export const getReservationTypesInCompanyReservationsSuccess = (reservationTypes) => ({
    type: GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { reservationTypes }
});

export const getReservationTypesInCompanyReservationsFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});