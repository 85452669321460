import {
    ADD_NEW_TICKET_TYPE,
    ADD_NEW_TICKET_TYPE_SUCCESS,
    ADD_NEW_TICKET_TYPE_FAILURE
} from '../../actions/TicketTypes/addNewTicketTypeActions';

import { DisplayTypesInTicketTypesConstant } from '../../constants/TicketTypes/DisplayTypesInTicketTypesConstant';

export function addNewTicketTypeReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_TICKET_TYPE:
            return {
                ...state,
                isAddNewTicketType: true,
                addNewTicketTypeError: null,
                addNewTicketTypeResult:null,
            };
        case ADD_NEW_TICKET_TYPE_SUCCESS:
            return { 
                ...state,
                isAddNewTicketType: false,
                addNewTicketTypeError: null,
                addNewTicketTypeResult: action.payload.data,
                // displayTypeInTicketTypes: DisplayTypesInTicketTypesConstant.LIST,
            };
        case ADD_NEW_TICKET_TYPE_FAILURE:
            return {
                ...state,
                isAddNewTicketType: false,
                addNewTicketTypeError: action.payload.message,
            };
        default:
            return state;
}}