export const EDIT_TICKET_TYPE = 'EDIT_TICKET_TYPE';
export const EDIT_TICKET_TYPE_SUCCESS = 'EDIT_TICKET_TYPE_SUCCESS';
export const EDIT_TICKET_TYPE_FAILURE = 'EDIT_TICKET_TYPE_FAILURE';

export const editTicketType = (ticketTypeData) =>{
    console.log(ticketTypeData) 
    return ({
    type: EDIT_TICKET_TYPE,
    ticketTypeData
});
}
export const editTicketTypeSuccess = (data) => ({
    type: EDIT_TICKET_TYPE_SUCCESS,
    payload: { data }
});

export const editTicketTypeFailure = (message) => ({
    type: EDIT_TICKET_TYPE_FAILURE,
    payload: { message }
});