import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
    updateReservationTypesInCompanyReservationsSuccess,
    updateReservationTypesInCompanyReservationsFailure
} from '../../actions/CompanyReservations/updateReservationTypesInCompanyReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateReservationTypesInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS),
        map(action => ({
            reservationType:action.reservationType
        })),
        mergeMap(response => {
            return of(updateReservationTypesInCompanyReservationsSuccess(response.reservationType))
        }),
        catchError(error =>  of(updateReservationTypesInCompanyReservationsFailure(strings.somethingWrongHappened)))
    )
}

