export const UPDATE_REQUEST_STATUS_RESERVATION = 'UPDATE_REQUEST_STATUS_RESERVATION';
export const UPDATE_REQUEST_STATUS_RESERVATION_SUCCESS = 'UPDATE_REQUEST_STATUS_RESERVATION_SUCCESS';
export const UPDATE_REQUEST_STATUS_RESERVATION_FAILURE = 'UPDATE_REQUEST_STATUS_RESERVATION_FAILURE';

export const updateRequestStatusReservation = (reservationId) => ({
    type: UPDATE_REQUEST_STATUS_RESERVATION,
    reservationId
});

export const updateRequestStatusReservationSuccess = (data) => ({
    type: UPDATE_REQUEST_STATUS_RESERVATION_SUCCESS,
    payload: { data }
});

export const updateRequestStatusReservationFailure = (message) => ({
    type: UPDATE_REQUEST_STATUS_RESERVATION_FAILURE,
    payload: { message }
});