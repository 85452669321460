import {
    PAY_IN_REQUEST,
    PAY_IN_REQUEST_SUCCESS,
    PAY_IN_REQUEST_FAILURE
} from '../../actions/Requests/payInRequestActions';

export function payInRequestReducer(state, action) {
    switch (action.type) {
        case PAY_IN_REQUEST:
            return {
                ...state,
                isPayingInRequest: true,
                payInRequestError: null,
            };
        case PAY_IN_REQUEST_SUCCESS:
            return {
                ...state,
                isPayingInRequest: false,
                payInRequestError: null,
                payInRequestResult: action.payload.data,
            };
        case PAY_IN_REQUEST_FAILURE:
            return {
                ...state,
                isPayingInRequest: false,
                payInRequestError: action.payload.message,
            };
        default:
            return state;
}}