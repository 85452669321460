import {
    UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
    UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateReservationTypesInCompanyReservationsActions';

export function updateReservationTypesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdateReservationTypesInCompanyReservations: true,
                updateReservationTypesInCompanyReservationsError: null,
            };
        case UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdateReservationTypesInCompanyReservations: false,
                updateReservationTypesInCompanyReservationsError: null,
                reservationTypeInCompanyReservations: action.payload.reservationType,
            };
        case UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdateReservationTypesInCompanyReservations: false,
                updateReservationTypesInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
}}