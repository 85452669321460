import {
    GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS,
    GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getTicketOptionsInCompanyReservationsActions';

export function getTicketOptionsInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGetTicketOptionsInCompanyReservations: true,
                getTicketOptionsInCompanyReservationsError: null,
            };
        case GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetTicketOptionsInCompanyReservations: false,
                getTicketOptionsInCompanyReservationsError: null,
                ticketOptionsInCompanyReservations: action.payload.ticketOptions,
                //reservationTypeInCompanyReservations: action.payload.ticketOptions[0],
            };
        case GET_TICKET_OPTIONS_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetTicketOptionsInCompanyReservations: false,
                getTicketOptionsInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}