import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS,
    updateCompaniesInCompanyReservationsSuccess,
    updateCompaniesInCompanyReservationsFailure
} from '../../actions/CompanyReservations/updateCompaniesInCompanyReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateCompaniesInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS),
        map(action => ({
            company:action.company
        })),
        map(response => 
            updateCompaniesInCompanyReservationsSuccess(response.company)
            ),
        catchError(error =>  of(updateCompaniesInCompanyReservationsFailure(strings.somethingWrongHappened)))
    )
}

