import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_FAILURE
} from '../../../src/actions/ReservationSchedules/updateDisplayTypeInReservationSchedulesActions';

export function updateDisplayTypeInReservationSchedulesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationSchedules: true,
                updateDisplayTypeErrorInReservationSchedules: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInReservationSchedules: false,
                displayTypeInReservationSchedules: action.payload.displayType,
                updateDisplayTypeErrorInReservationSchedules: null,

                isAddNewReservationSchedule: false,
                addNewReservationScheduleError: null,
                addNewReservationScheduleResult: null,

                isDeletingReservationSchedule: false,
                deleteReservationScheduleError: null,
                deleteReservationScheduleResult: null,

                isEditingReservationSchedule: false,
                editReservationScheduleError: null,
                editReservationScheduleResult: null,

                isUpdatingStatusReservationSchedule: false,
                updateStatusReservationScheduleError: null,
                updateStatusReservationScheduleResult: null,

                ischeckingReservationScheduleNameEnAvailabilityInReservationSchedules: false,
                isReservationScheduleNameEnAvailableInReservationSchedules: true,
                checkReservationScheduleNameEnAvailabilityErrorInReservationSchedules: null,

                ischeckingReservationScheduleNameArAvailabilityInReservationSchedules: false,
                isReservationScheduleNameArAvailableInReservationSchedules: true,
                checkReservationScheduleNameArAvailabilityErrorInReservationSchedules: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationSchedules: false,
                updateDisplayTypeErrorInReservationSchedules: action.payload
            };
        default:
            return state;
    }
}