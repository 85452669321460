export const GET_REQUEST_REPORT = 'GET_REQUEST_REPORT';
export const GET_REQUEST_REPORT_SUCCESS = 'GET_REQUEST_REPORT_SUCCESS';
export const GET_REQUEST_REPORT_FAILURE = 'GET_REQUEST_REPORT_FAILURE';

export const getRequestReport = (reportData) => ({
    type: GET_REQUEST_REPORT,
    reportData
});

export const getRequestReportSuccess = (requestReport, reportData) => ({
    type: GET_REQUEST_REPORT_SUCCESS,
    payload: { requestReport, reportData }
});

export const getRequestReportFailure = (message) => ({
    type: GET_REQUEST_REPORT_FAILURE,
    payload: { message }
});