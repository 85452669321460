import {
    EDIT_SMS_SETTING,
    EDIT_SMS_SETTING_SUCCESS,
    EDIT_SMS_SETTING_FAILURE
} from '../../actions/SmsSettings/editSmsSettingActions';

import { DisplayTypesInSmsSettingsConstant } from '../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant';

export function editSmsSettingReducer(state, action) {
    switch (action.type) {
        case EDIT_SMS_SETTING:
            return {
                ...state,
                isEditingSmsSetting: true,
                editSmsSettingError: null,
            };
        case EDIT_SMS_SETTING_SUCCESS:
            return {
                ...state,
                isEditingSmsSetting: false,
                editSmsSettingError: null,
                editSmsSettingResult: action.payload.data,
               // displayTypeInSmsSettings: DisplayTypesInSmsSettingsConstant.LIST,
            };
        case EDIT_SMS_SETTING_FAILURE:
            return {
                ...state,
                isEditingSmsSetting: false,
                editSmsSettingError: action.payload.message,
            };
        default:
            return state;
}}