import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_EDITED_REQUEST,
    getEditedRequestSuccess,
    getEditedRequestFailure
} from '../../actions/Requests/getEditedRequestActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedRequestEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_REQUEST),
        map(action => ({
            requestId: action.requestId
        })),
        map(response =>
            getEditedRequestSuccess(response.requestId)

        ),
        catchError(error => of(getEditedRequestFailure(strings.somethingWrongHappened)))
    )
}

