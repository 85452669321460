import {
    DELETE_ROLE_SCREEN,
    DELETE_ROLE_SCREEN_SUCCESS,
    DELETE_ROLE_SCREEN_FAILURE
} from '../../../actions/Roles/RoleScreens/deleteRoleScreenActions';

export function deleteRoleScreenReducer(state, action) {
    switch (action.type) {
        case DELETE_ROLE_SCREEN:
            return {
                ...state,
                isDeletingRoleScreen: true,
                deleteRoleScreenError: null,
            };
        case DELETE_ROLE_SCREEN_SUCCESS:
            return {
                ...state,
                isDeletingRoleScreen: false,
                deleteRoleScreenError: null,
                deleteRoleScreenResult: action.payload.data,
            };
        case DELETE_ROLE_SCREEN_FAILURE:
            return {
                ...state,
                isDeletingRoleScreen: false,
                deleteRoleScreenError: action.payload.message,
            };
        default:
            return state;
}}