import {
    GET_REQUESTS_IN_RESERVATIONS,
    GET_REQUESTS_IN_RESERVATIONS_SUCCESS,
    GET_REQUESTS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getRequestsInReservationsActions';

export function getRequestsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_REQUESTS_IN_RESERVATIONS:
            return {
                ...state,
                isGetRequestsInReservations: true,
                getRequestsInReservationsError: null,
                requestsInReservations: [],
            };
        case GET_REQUESTS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetRequestsInReservations: false,
                getRequestsInReservationsError: null,
                requestsInReservations: action.payload.requests.data,
                requestsPageNumberInReservations: action.payload.pageNumber,
                requestsPageSizeInReservations: action.payload.pageSize,
                requestsTotalCountInReservations: action.payload.requests.total,
            };
        case GET_REQUESTS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetRequestsInReservations: false,
                getRequestsInReservationsError: action.payload.message,
            };
        default:
            return state;
}}