import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_GALLERY,
    getEditedGallerySuccess,
    getEditedGalleryFailure
} from '../../actions/Galleries/getEditedGalleryActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedGalleryEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_GALLERY),
        map(action =>({
            galleryId : action.galleryId 
        })),
        map(response => 
           getEditedGallerySuccess(response.galleryId)
           
        ),
        catchError(error => of(getEditedGalleryFailure(strings.somethingWrongHappened)))
    )
}

