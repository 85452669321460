export const UPDATE_STATUS_IN_RESERVATION_TYPES = 'UPDATE_STATUS_IN_RESERVATION_TYPES';
export const UPDATE_STATUS_IN_RESERVATION_TYPES_SUCCESS = 'UPDATE_STATUS_IN_RESERVATION_TYPES_SUCCESS';
export const UPDATE_STATUS_IN_RESERVATION_TYPES_FAILURE = 'UPDATE_STATUS_IN_RESERVATION_TYPES_FAILURE';

export const updateStatusInReservationTypes = (ReservationTypeId) => ({
    type: UPDATE_STATUS_IN_RESERVATION_TYPES,
    ReservationTypeId
});

export const updateStatusInReservationTypesSuccess = (data) => ({
    type: UPDATE_STATUS_IN_RESERVATION_TYPES_SUCCESS,
    payload: { data }
});

export const updateStatusInReservationTypesFailure = (message) => ({
    type: UPDATE_STATUS_IN_RESERVATION_TYPES_FAILURE,
    payload: { message }
});