import {
    GET_EDITED_ROLE,
    GET_EDITED_ROLE_SUCCESS,
    GET_EDITED_ROLE_FAILURE
} from '../../../actions/Roles/Roles/getEditedRoleActions';

export function getEditedRoleReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_ROLE:
            return {
                ...state,
                isGetEditedRole: true,
                getEditedRoleError: null,
            };
        case GET_EDITED_ROLE_SUCCESS:
            return {
                ...state,
                isGetEditedRole: false,
                getEditedRoleError: null,
                editedRoleInRoles: state.rolesInRoles.filter(x=> x.id === action.payload.roleId)[0],
            };
        case GET_EDITED_ROLE_FAILURE:
            return {
                ...state,
                isGetEditedRole: false,
                getEditedRoleError: action.payload.message,
            };
        default:
            return state;
}}