import {
    EDIT_GALLERY,
    EDIT_GALLERY_SUCCESS,
    EDIT_GALLERY_FAILURE
} from '../../actions/Galleries/editGalleryActions';

import { DisplayTypesInGalleriesConstant } from '../../constants/Galleries/DisplayTypesInGalleriesConstant';

export function editGalleryReducer(state, action) {
    switch (action.type) {
        case EDIT_GALLERY:
            return {
                ...state,
                isEditingGallery: true,
                editGalleryError: null,
            };
        case EDIT_GALLERY_SUCCESS:
            return {
                ...state,
                isEditingGallery: false,
                editGalleryError: null,
                editGalleryResult: action.payload.data,
               // displayTypeInGalleries: DisplayTypesInGalleriesConstant.LIST,
            };
        case EDIT_GALLERY_FAILURE:
            return {
                ...state,
                isEditingGallery: false,
                editGalleryError: action.payload.message,
            };
        default:
            return state;
}}