export const UPDATE_DISPLAY_TYPE_IN_USERS = 'UPDATE_DISPLAY_TYPE_IN_USERS';
export const UPDATE_DISPLAY_TYPE_IN_USERS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_USERS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_USERS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_USERS_FAILURE';

export const updateDisplayTypeInUsers = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_USERS,
    displayType
});

export const updateDisplayTypeInUsersSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_USERS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInUsersFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_USERS_FAILURE,
    payload: message
});