import React, { useEffect } from "react";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInTicketTypesConstant } from "../../constants/TicketTypes/DisplayTypesInTicketTypesConstant";
import { StatusCodeConstant } from "../../constants/StatusCodeConstant";
import Loading from "../Shared/Loading/Loading";

function Add(props) {
  useEffect(() => {
    if (
      props.editTicketTypeResult !== null &&
      props.editTicketTypeResult.statusCode === StatusCodeConstant.Ok
    ) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editTicketTypeResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInTicketTypes(
          DisplayTypesInTicketTypesConstant.LIST
        );
      });
    }
  }, [props.editTicketTypeResult]);

  useEffect(() => {
    if (
      props.editTicketTypeResult !== null &&
      props.editTicketTypeResult.statusCode !== StatusCodeConstant.Ok
    ) {
      Swal.fire(
        //icon: 'error',
        "Oops...",
        //	text: `${strings.thisDataAlreadyExists}`,
        props.editTicketTypeResult.message,
        "error"
      );
    }
  });

  // function done(){

  // 	Swal.fire(`${strings.successfullyAdded}`,'','success').then(
  // 		()=>props.updateDisplayTypeInTicketTypes(DisplayTypesInTicketTypesConstant.LIST))
  // }


  return (
    <>
     { props.isEditingTicketType && ( <Loading />)}
     {  !props.isEditingTicketType &&
     (
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.editTicketTypeData}
              </span>
            </h3>
            {/* <Button
              className="NewB2 col-1"
              onClick={() =>
                props.updateDisplayTypeInTicketTypes(
                  DisplayTypesInTicketTypesConstant.LIST
                )
              }
            >
              {strings.ticketType}
            </Button> */}
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              initialValues={
                {
                 ticketTypeNameAr: props.editedTicketTypeInTicketTypes !== null ? props.editedTicketTypeInTicketTypes.nameAr: '',
                 ticketTypeNameEn: props.editedTicketTypeInTicketTypes !== null ? props.editedTicketTypeInTicketTypes.nameEn: '',
                 price: props.editedTicketTypeInTicketTypes !== null ? props.editedTicketTypeInTicketTypes.price: '',
                 status: props.editedTicketTypeInTicketTypes !== null ? props.editedTicketTypeInTicketTypes.isActive: '',
                }
              }
              validationSchema={Yup.object().shape({
                   ticketTypeNameAr: Yup.string().required(
                      strings.nameArRequired
                    ),
                    ticketTypeNameEn: Yup.string().required(
                      strings.nameEnRequired
                    ),
                    price: Yup.string().required(
                      strings.priceRequired
                    ),
              })}
              onSubmit={(fields) => {
                   const ticketType={
                           "nameAr" : fields.ticketTypeNameAr,
                           "nameEn" : fields.ticketTypeNameEn,
                           "price" : fields.price,
                           "status" : fields.isActive,
                           "id" : props.editedTicketTypeInTicketTypes.id
                   }
                   props.editTicketType(ticketType);
                    console.log(ticketType)
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                <div className="card-body row mx-0 border-top d-flex"style={{ padding: "1rem 1rem 0rem 1rem" }}>
                  <div className="col-lg-6 col-sm-12 mb-3">
                    <label className="col-lg-6 col-form-label required">
                      {strings.arabicName}
                    </label>
                    <div className="col-lg-10 col-sm-12 fv-row">
                      <Field
                        type="text"
                        name="ticketTypeNameAr"
                        className={
                          "form-control form-control1 form-control-lg form-control-solid" +
                          (errors.ticketTypeNameAr &&
                          touched.ticketTypeNameAr
                            ? " is-invalid"
                            : "")
                        }
                        placeholder={`${strings.enterNameAr} *`}
                      />
                      <ErrorMessage
                        name="ticketTypeNameAr"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-3">
                    <label className="col-lg-6 col-form-label required">
                      {strings.englishName}
                    </label>
                    <div className="col-lg-10 col-sm-12 fv-row">
                      <Field
                        type="text"
                        name="ticketTypeNameEn"
                        className={
                          "form-control form-control1 form-control-lg form-control-solid" +
                          (errors.ticketTypeNameEn &&
                          touched.ticketTypeNameEn
                            ? " is-invalid"
                            : "")
                        }
                        placeholder={`${strings.enterNameEn} *`}
                      />
                      <ErrorMessage
                        name="ticketTypeNameEn"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.price}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="price"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.price &&
                            touched.price
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterPrice} *`}
                        />
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between p-3">
                  <div>
                    <button
                      type="submit"
                      className="btn fs-6"
                      style={{
                        backgroundColor: "#009ef7",
                        borderColor: "#009ef7",
                        color: "white",
                        borderRadius: "0.75rem",
                      }}
                      id="kt_account_profile_details_submit"
                      //	onClick={done}
                    >
                      {strings.save}
                    </button>
                  </div>
                </div>
              </Form>
              )}
            />
          </div>
        </div>
      </div>
     )
      }
    </>
  );
}
export default Add;
