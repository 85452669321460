export const GET_COMPANY_RESERVATION_REPORT = 'GET_COMPANY_RESERVATION_REPORT';
export const GET_COMPANY_RESERVATION_REPORT_SUCCESS = 'GET_COMPANY_RESERVATION_REPORT_SUCCESS';
export const GET_COMPANY_RESERVATION_REPORT_FAILURE = 'GET_COMPANY_RESERVATION_REPORT_FAILURE';

export const getCompanyReservationReport = (reportData) => ({
    type: GET_COMPANY_RESERVATION_REPORT,
    reportData
});

export const getCompanyReservationReportSuccess = (companyReservationReport, reportData) => ({
    type: GET_COMPANY_RESERVATION_REPORT_SUCCESS,
    payload: { companyReservationReport, reportData }
});

export const getCompanyReservationReportFailure = (message) => ({
    type: GET_COMPANY_RESERVATION_REPORT_FAILURE,
    payload: { message }
});