export const GET_RESERVATION_TIMES_IN_RESERVATIONS = 'GET_RESERVATION_TIMES_IN_RESERVATIONS';
export const GET_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS = 'GET_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS';
export const GET_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE = 'GET_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE';

export const getReservationTimesInReservations = (reservationTypeSettingId,selectedDate) => ({
    type: GET_RESERVATION_TIMES_IN_RESERVATIONS,
    reservationTypeSettingId,
    selectedDate
});

export const getReservationTimesInReservationsSuccess = (timeResultList,selectedDate) => ({
    type: GET_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS,
    payload: { timeResultList,selectedDate }
});

export const getReservationTimesInReservationsFailure = (message) => ({
    type: GET_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE,
    payload: message
});