import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_RESERVATION_TYPES_IN_REQUESTS,
    updateReservationTypesInRequestsSuccess,
    updateReservationTypesInRequestsFailure
} from '../../actions/Requests/updateReservationTypesInRequestsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateReservationTypesInRequestsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_RESERVATION_TYPES_IN_REQUESTS),
        map(action => ({
            reservationType:action.reservationType,
            selectedDate:action.selectedDate
        })),
        mergeMap(response => {
            return of(updateReservationTypesInRequestsSuccess(response.reservationType))
        }),
        catchError(error =>  of(updateReservationTypesInRequestsFailure(strings.somethingWrongHappened)))
    )
}

