import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import strings from '../../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Modal, Button, Container, Row, Col, InputGroup, FormControl, Pagination } from "react-bootstrap";
import { DisplayTypesInRoleScreensConstant } from '../../../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant';

function Add(props) {

    const [ScreenError, setScreenError] = useState();

    useEffect(() => {
        props.getScreensInRoleScreens(props.roleInRoles.id);
    }, []);

    useEffect(() => {
        if (props.createNewRoleScreenResult !== null && props.createNewRoleScreenResult.isSuccess) {
            Swal.fire(
                `! ${strings.successfullyAdded}`,
                props.createNewRoleScreenResult.message,
                'success'
            ).then(result => {
                props.updateDisplayTypeInRoleScreens(DisplayTypesInRoleScreensConstant.LIST)
            }
            )
        }
    }, [props.createNewRoleScreenResult])

    useEffect(() => {
        if (props.createNewRoleScreenError !== null) {
            Swal.fire(
                `! ${strings.error}`,
                props.createNewRoleScreenError,
                'error'
            ).then(result => {

            }
            )
        }
    }, [props.createNewRoleScreenError])

    function handleScreenChanges(selectedOption) {
        if (selectedOption == null) {
            setScreenError(strings.pleaseSelectScreen);
        } else {
            setScreenError(null);
        }
        props.updateScreensInRoleScreens(selectedOption);
    }


    return (
        <div className="customer-data cus2">
        <div className="card rounded-0 animate__animated  animate__backInLeft">
        <div className='card-header border-0 d-flex align-items-center mh-47'>
              <h3 className='card-title col-9'>
              <span className="card-label fw-bolder text-dark">
                {strings.addScreen}</span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                    }}
                    validationSchema={Yup.object().shape({

                    })}
                    onSubmit={fields => {
                        if (props.screenInRoleScreens == null) {
                            setScreenError(strings.pleaseSelectScreen)
                        } else {
                            var roleScreen = {
                                roleId: props.roleInRoles.id,
                                screenId: props.screenInRoleScreens.value,
                            }

                            props.createNewRoleScreen(roleScreen);
                        }
                    }}
                    render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                        <Form id="kt_account_profile_details_form" className="form1">
                            <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label className="col-lg-6 col-form-label required">
                                        {strings.screen}
                                    </label>
                                    <div className="col-lg-10 col-sm-12">
                                        <Select
                                            name="screenName"
                                            options={props.screensInRoleScreens}
                                            placeholder={strings.selectScreen}
                                            value={props.screenInRoleScreens}
                                            onChange={handleScreenChanges}
                                            isSearchable
                                            isClearable
                                            isLoading={props.isGetScreensInRoleScreens}
                                            className={ScreenError != null ? "is-invalid" : ""}
                                        />
                                        {ScreenError && (
                                            <div className="invalid-feedback block">
                                                {ScreenError}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between">
                <Button type="submit" className="btn fs-6"
                    style={{backgroundColor: "#009ef7",borderColor: "#009ef7",color: "white",
                        borderRadius: "0.75rem",
                    }}
                    id="kt_account_profile_details_submit"
                //	onClick={done}
                >
                    {strings.save}
                </Button>
                <Button type="button"className="btn fs-6"style={{ borderRadius: "0.75rem" }}onClick={()=> props.updateDisplayTypeInRoleScreens(DisplayTypesInRoleScreensConstant.LIST)}>{strings.cancel}</Button>
            </div>
                        </Form>

                    )}
                />
            </div>
            </div>
        </div>


    );

}
export default Add
