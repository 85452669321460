import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationsConstant } from "../../constants/Reservations/DisplayTypesInReservationsConstant";
import { ReservationTypesConstant } from "../../constants/ReservationTypesConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import Loading from "../Shared/Loading/Loading";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);


function Add(props) {
  const formikRef = React.useRef(null);

  const [userError, setUserError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reservationTypeError, setReservationTypeError] = useState(null);
  const [reservationTimeError, setReservationTimeError] = useState(null);
  const [reservationDateError, setReservationDateError] = useState(null);
  const [maxCount, setMaxCount] = React.useState(0);
  const [amount, setAmount] = React.useState(0);

  useEffect(() => {
    props.getUsersInReservations();
  }, [])

  useEffect(() => {
    if (props.addNewReservationResult !== null && props.addNewReservationResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewReservationResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInReservations(DisplayTypesInReservationsConstant.LIST)
      }
      )
    }
  }, [props.addNewReservationResult])

  useEffect(() => {
    if (props.addNewReservationError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.addNewReservationError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.addNewReservationError])

  function handleUserChanges(selectedOption) {
    if (selectedOption == null) {
      setUserError(strings.pleaseSelectUser)
    } else {
      setUserError(null)
    }
    props.updateUsersInReservations(selectedOption);
  }

  function handleReservationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTypeError(strings.pleaseSelectReservationType)
    } else {
      setReservationTypeError(null);

    }
    props.updateReservationTypesInReservations(selectedOption, moment(props.selectedDateInReservations).format("YYYY-MM-DD HH:mm:ss"));
  }

  function handleReservationTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTimeError(strings.pleaseSelectTime)
    } else {
      setMaxCount(selectedOption.availablePlaces);
      setReservationTimeError(null);
      //if (props.reservationTypeInReservations.value == ReservationTypesConstant.General) {
      props.getTicketTypesInReservations();
      // } else {
      //   props.getTicketOptionsInReservations();
      // }
    }
    props.updateReservationTimesInReservations(selectedOption);
  }

  function handleReservationDateChange(value) {
    if (!moment(value).isValid()) {
      setReservationDateError(strings.dateNotValid);
    } else {
      setReservationDateError(null);
      formikRef.current.setFieldValue("reservationDate", value);
      props.updateSelectedDateInReservations(props.reservationTypeInReservations.value, moment(value).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  function changeCount(id, price, count) {
    var tickets = 0;
    var total = 0;
    let updatedType = selectedItems.length > 0 ? selectedItems.filter(x => x.id !== id) : selectedItems;
    if (count > 0) {
      updatedType = [...updatedType, { id: id, price: price, count: count }];
    }
    setSelectedItems(updatedType);
    updatedType.forEach(item => {
      tickets += item.count;
      total += item.count * item.price;
    })
    formikRef.current.setFieldValue("count", tickets);
    setAmount(total)
  }

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.reservationDate} *`}
      name="reservationDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  return (
    <>
      {props.isAddNewReservation && (<Loading />)}
      {!props.isAddNewReservation &&
        <div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.addReservation}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  count: "",
                  reservationDate: props.selectedDateInReservations,
                }}
                validationSchema={Yup.object().shape({
                  count: Yup.number()
                    .integer(strings.invalidInputs)
                    .max(maxCount, strings.countMustBeLessThanIt)
                    .required(strings.pleaseEnterCount)
                })}
                onSubmit={(fields) => {
                  let isValid = true;

                  if (props.userInReservations == null) {
                    setUserError(strings.pleaseSelectUser)
                    isValid = false;
                  }

                  if (props.reservationTypeInReservations == null) {
                    setReservationTypeError(strings.pleaseSelectReservationType)
                    isValid = false;
                  }

                  if (props.reservationTimeInReservations == null) {
                    setReservationTimeError(strings.pleaseSelectTime)
                    isValid = false;
                  }

                  if (props.reservationTypeInReservations !== null && props.reservationTypeInReservations.value == ReservationTypesConstant.General && selectedItems.length == 0) {
                    isValid = false;
                  }

                  if (isValid) {

                    let startDate = moment(props.selectedDateInReservations).toDate();
                    const splittedStartTimeArr = props.reservationTimeInReservations.fromTime.split(":");
                    startDate.setHours(splittedStartTimeArr[0]);
                    startDate.setMinutes(splittedStartTimeArr[1]);

                    const reservationViewModel = {
                      userId: props.userInReservations.value,
                      //reservationTypeSettingId: props.reservationTypeInReservations.value,
                      reservationScheduleId: props.reservationTimeInReservations.reservationScheduleId,
                      reservationDate: moment(fields.reservationDate).format("YYYY-MM-DD HH:mm:ss"),
                      fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                      count: fields.count,
                      amount: amount,
                      totalAmount: amount,
                      ticketTypesViewModel: selectedItems,
                    };

                    props.addNewReservation(reservationViewModel);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  setFieldError,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.userName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.usersInReservations}
                            placeholder={strings.selectUser}
                            value={props.userInReservations}
                            onChange={handleUserChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetUsersInReservations}
                            className={userError != null ? "is-invalid" : ""}
                          />
                          {userError && (
                            <div className="invalid-feedback block">
                              {userError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.reservationType}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.reservationTypesInReservations}
                            placeholder={strings.selectReservationType}
                            value={props.reservationTypeInReservations}
                            onChange={handleReservationTypeChanges}
                            isSearchable
                            isLoading={props.isGetReservationTypesInReservations}
                            className={reservationTypeError != null ? "is-invalid" : ""}
                          />
                          {reservationTypeError && (
                            <div className="invalid-feedback block">
                              {reservationTypeError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.reservationDate}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker placeholderText={strings.from}
                            customInput={<StartDateInput />}
                            locale={strings.getLanguage() === LanguagesConstant.AR ?
                              "ar" : "en"}
                            selected={values.reservationDate}
                            onChange={handleReservationDateChange}
                            minDate={new Date(moment())}
                            dateFormat="dd/MM/yyyy"
                            className={reservationDateError !== null ? 'red-border' : ''} />
                          {
                            reservationDateError && (
                              <div className="invalid-feedback block">
                                {reservationDateError}
                              </div>
                            )
                          }

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.selectTime}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.reservationTimesInReservations}
                            placeholder={strings.selectTime}
                            value={props.reservationTimeInReservations}
                            onChange={handleReservationTimeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGettingReservationTimesInReservations}
                            className={reservationTimeError != null ? "is-invalid" : ""}
                          />
                          {reservationTimeError && (
                            <div className="invalid-feedback block">
                              {reservationTimeError}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.count}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="count"
                            className={
                              "form-control" +
                              (errors.count &&
                                touched.count
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={`${strings.enterCount} *`}
                            disabled={true}
                          />
                          <ErrorMessage
                            name="count"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                      </div>

                      {
                        props.ticketTypesInReservations !== []
                        && props.ticketTypesInReservations.length > 0
                        && (
                          <div className="row border p-5 my-5">
                            <span className="card-label fw-bolder text-dark">
                              {strings.ticketTypes}
                            </span>
                            {
                              props.ticketTypesInReservations.map((item, index) => {
                                return <div key={index} className="col-lg-6 col-sm-12 my-5">
                                  <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">{item.label}</label>
                                    <div class="col-sm-8">
                                      <input maxLength={3} type="number" class="form-control" placeholder={`${strings.enterCount} *`} onBlur={(e) => {
                                        if (e.target.value != "") {
                                          changeCount(item.value, item.price, parseInt(e.target.value));
                                        } else {
                                          changeCount(item.value, item.price, 0);
                                        }
                                      }} />
                                    </div>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        )
                      }



                      <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                        <div className="col-11">
                          <button type="submit" className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                          >
                            {strings.save}
                          </button>
                        </div>

                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}
export default Add;
