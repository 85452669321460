export const UPDATE_RESERVATION_TIMES_IN_RESERVATIONS = 'UPDATE_RESERVATION_TIMES_IN_RESERVATIONS';
export const UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS = 'UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS';
export const UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE = 'UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE';

export const updateReservationTimesInReservations = (selectedTime) => ({
    type: UPDATE_RESERVATION_TIMES_IN_RESERVATIONS,
    selectedTime
});

export const updateReservationTimesInReservationsSuccess = (selectedTime) => ({
    type: UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS,
    payload: { selectedTime }
});

export const updateReservationTimesInReservationsFailure = (message) => ({
    type: UPDATE_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE,
    payload: message
});