export const UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE';

export const updateNotificationTypesInNotificationSettings = (notificationType) => ({
    type: UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS,
    notificationType
});

export const updateNotificationTypesInNotificationSettingsSuccess = (notificationType) => ({
    type: UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationType }
});

export const updateNotificationTypesInNotificationSettingsFailure = (message) => ({
    type: UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: { message }
});