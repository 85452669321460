import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES,
    updateDisplayTypeInReservationTypesSuccess,
    updateDisplayTypeInReservationTypesFailure
} from '../../../src/actions/ReservationTypes/updateDisplayTypeInReservationTypesActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInReservationTypesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInReservationTypesSuccess(response.displayType)
            }
        ),
        catchError(error => of(updateDisplayTypeInReservationTypesFailure(strings.somethingWrongHappened)))
    )
}

