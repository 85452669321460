import {
    UPDATE_DISPLAY_TYPE_IN_REQUESTS,
    UPDATE_DISPLAY_TYPE_IN_REQUESTS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_REQUESTS_FAILURE
} from '../../actions/Reservations/updateDisplayTypeInRequestsActions';
import { DisplayTypesInRequestsConstant } from '../../constants/Reservations/DisplayTypesInRequestsConstant';

export function updateDisplayTypeInRequestsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_REQUESTS:
            return {
                ...state,
                isUpdatingDisplayTypeInRequests: true,
                updateDisplayTypeErrorInRequests: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_REQUESTS_SUCCESS:
            if (action.payload.displayType == DisplayTypesInRequestsConstant.EDIT || action.payload.displayType == DisplayTypesInRequestsConstant.APPROVE) {
                return {
                    ...state,
                    isUpdatingDisplayTypeInRequests: false,
                    displayTypeInRequests: action.payload.displayType,
                    updateDisplayTypeErrorInRequests: null,

                    isApprovingRequest: false,
                    approveRequestResult: null,
                    approveRequestError: null,

                    isDeletingRequest: false,
                    deleteRequestError: null,
                    deleteRequestResult: null,

                    isAddNewRequest: false,
                    addNewRequestError: null,
                    addNewRequestResult: null,

                    isEditingRequest: false,
                    editRequestResult: null,
                    editRequestError: null,

                };
            } else {
                return {
                    ...state,
                    isUpdatingDisplayTypeInRequests: false,
                    displayTypeInRequests: action.payload.displayType,
                    updateDisplayTypeErrorInRequests: null,

                    isApprovingRequest: false,
                    approveRequestResult: null,
                    approveRequestError: null,

                    isDeletingRequest: false,
                    deleteRequestError: null,
                    deleteRequestResult: null,

                    isAddNewRequest: false,
                    addNewRequestError: null,
                    addNewRequestResult: null,

                    isEditingRequest: false,
                    editRequestResult: null,
                    editRequestError: null,

                    isGetEditedRequest: false,
                getEditedRequestError: null,
                editedRequestInRequests:null,

                    isGettingReservationTimesInEditRequest: false,
                    getReservationTimesInEditRequestError: null,
                    reservationTimesInEditRequest: [],

                    isGettingSchoolTourRangesInRequests: false,
                    schoolTourRangesInRequests: [],
                    getSchoolTourRangesInRequestsError: null,

                    isGetReservationTypesInRequests: false,
                    getReservationTypesInRequestsError: null,
                    reservationTypesInRequests: [],

                    isGetTicketOptionsInRequests: false,
                    getTicketOptionsInRequestsError: null,
                    ticketOptionsInRequests: [],

                    isGetUsersInRequests: false,
                    getUsersInRequestsError: null,
                    usersInRequests: [],

                    isUpdatingReservationTimesInEditRequest: false,
                    reservationTimeInEditRequest: null,
                    updateReservationTimesInEditRequestError: null,

                    isUpdatingReservationTimesInRequests: false,
                    reservationTimeInRequests: null,
                    updateReservationTimesInRequestsError: null,

                    isUpdateReservationTypesInRequests: false,
                    updateReservationTypesInRequestsError: null,
                    reservationTypeInRequests: null,

                    //isUpdatingSelectedDateInRequests: false,
                    //updateSelectedDateInRequestsError: null,

                    isUpdateUsersInRequests: false,
                    updateUsersInRequestsError: null,
                    userInRequests: null,

                };
            }
        case UPDATE_DISPLAY_TYPE_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInRequests: false,
                updateDisplayTypeErrorInRequests: action.payload
            };
        default:
            return state;
    }
}