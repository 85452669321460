import {
    GET_EDITED_NOTIFICATION_SETTING,
    GET_EDITED_NOTIFICATION_SETTING_SUCCESS,
    GET_EDITED_NOTIFICATION_SETTING_FAILURE
} from '../../actions/NotificationSettings/getEditedNotificationSettingActions';

export function getEditedNotificationSettingReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_NOTIFICATION_SETTING:
            return {
                ...state,
                isGetEditedNotificationSetting: true,
                getEditedNotificationSettingError: null, 
            };
        case GET_EDITED_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                isGetEditedNotificationSetting: false,
                getEditedNotificationSettingError: null,
                editedNotificationSettingInNotificationSettings:state.notificationSettingsInNotificationSettings.filter(x=> x.id == action.payload.notificationSettingId)[0],
            };
        case GET_EDITED_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                isGetEditedNotificationSetting: false,
                getEditedNotificationSettingError: action.payload.message,
            };
        default:
            return state;
}}