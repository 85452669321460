export const GET_RESERVATION_TYPES_IN_REQUESTS = 'GET_RESERVATION_TYPES_IN_REQUESTS';
export const GET_RESERVATION_TYPES_IN_REQUESTS_SUCCESS = 'GET_RESERVATION_TYPES_IN_REQUESTS_SUCCESS';
export const GET_RESERVATION_TYPES_IN_REQUESTS_FAILURE = 'GET_RESERVATION_TYPES_IN_REQUESTS_FAILURE';

export const getReservationTypesInRequests = () => ({
    type: GET_RESERVATION_TYPES_IN_REQUESTS,
}); 

export const getReservationTypesInRequestsSuccess = (reservationTypes) => ({
    type: GET_RESERVATION_TYPES_IN_REQUESTS_SUCCESS,
    payload: { reservationTypes }
});

export const getReservationTypesInRequestsFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_REQUESTS_FAILURE,
    payload: { message }
});