import LocalizedStrings from 'react-localization';
import englishStrings from './englishStrings';
import arabicStrings from './arabicStrings';

let strings = new LocalizedStrings({
    en: {
        ...englishStrings,
    },
    ar: {
        ...arabicStrings,
    }
});

export default strings; 