export const UPDATE_DISPLAY_TYPE_IN_GALLERIES = 'UPDATE_DISPLAY_TYPE_IN_GALLERIES';
export const UPDATE_DISPLAY_TYPE_IN_GALLERIES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_GALLERIES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_GALLERIES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_GALLERIES_FAILURE';

export const updateDisplayTypeInGalleries = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_GALLERIES,
    displayType
});

}
export const updateDisplayTypeInGalleriesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_GALLERIES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInGalleriesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_GALLERIES_FAILURE,
    payload: message
});