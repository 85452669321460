export const GET_EDITED_COMPANY = 'GET_EDITED_COMPANY';
export const GET_EDITED_COMPANY_SUCCESS = 'GET_EDITED_COMPANY_SUCCESS';
export const GET_EDITED_COMPANY_FAILURE = 'GET_EDITED_COMPANY_FAILURE';

export const getEditedCompany = (companyId) => ({
    type: GET_EDITED_COMPANY,
    companyId
});

export const getEditedCompanySuccess = (companyId) => ({
    type: GET_EDITED_COMPANY_SUCCESS,
    payload: { companyId }
});

export const getEditedCompanyFailure = (message) => ({
    type: GET_EDITED_COMPANY_FAILURE,
    payload: { message }
});
