export const DELETE_TICKET_TYPE = 'DELETE_TICKET_TYPE';
export const DELETE_TICKET_TYPE_SUCCESS = 'DELETE_TICKET_TYPE_SUCCESS';
export const DELETE_TICKET_TYPE_FAILURE = 'DELETE_TICKET_TYPE_FAILURE';

export const deleteTicketType = (ticketTypeId) => ({
    type: DELETE_TICKET_TYPE,
    ticketTypeId
});

export const deleteTicketTypeSuccess = (data) => ({
    type: DELETE_TICKET_TYPE_SUCCESS,
    payload: { data }
});

export const deleteTicketTypeFailure = (message) => ({
    type: DELETE_TICKET_TYPE_FAILURE,
    payload: { message }
});