import {
    GET_EDITED_RESERVATION_SCHEDULE,
    GET_EDITED_RESERVATION_SCHEDULE_SUCCESS,
    GET_EDITED_RESERVATION_SCHEDULE_FAILURE
} from '../../actions/ReservationSchedules/getEditedReservationScheduleActions';

export function getEditedReservationScheduleReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_RESERVATION_SCHEDULE:
            return {
                ...state,
                isGetEditedReservationSchedule: true,
                getEditedReservationScheduleError: null, 
            };
        case GET_EDITED_RESERVATION_SCHEDULE_SUCCESS:
            return {
                ...state,
                isGetEditedReservationSchedule: false,
                getEditedReservationScheduleError: null,
                editedReservationScheduleInReservationSchedules:state.reservationSchedulesInReservationSchedules.filter(x=> x.id == action.payload.reservationScheduleId)[0],
            };
        case GET_EDITED_RESERVATION_SCHEDULE_FAILURE:
            return {
                ...state,
                isGetEditedReservationSchedule: false,
                getEditedReservationScheduleError: action.payload.message,
            };
        default:
            return state;
}}