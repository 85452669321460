export const GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES = 'GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES';
export const GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_SUCCESS = 'GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_SUCCESS';
export const GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_FAILURE = 'GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_FAILURE';

export const getReservationSchedulesInReservationSchedules = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES,
    pageNumber,
    pageSize,
    search
});

export const getReservationSchedulesInReservationSchedulesSuccess = (reservationSchedules, pageNumber, pageSize) => ({
    type: GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_SUCCESS,
    payload: { reservationSchedules, pageNumber, pageSize }
});

export const getReservationSchedulesInReservationSchedulesFailure = (message) => ({
    type: GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_FAILURE,
    payload: { message }
});