
import {
    CHECK_IS_USER_LOGGED_IN,
    CHECK_IS_USER_LOGGED_IN_SUCCESS,
    CHECK_IS_USER_LOGGED_IN_FAILURE,
} from '../../actions/Account/checkIsUserLoggedInActions';

export function checkIsUserLoggedInReducer(state, action) {
    switch (action.type) {
        case CHECK_IS_USER_LOGGED_IN:
            return {
                ...state,
                isInCheckIsUserLoggedIn: true,
                checkIsUserLoggedInError: null
            };
        case CHECK_IS_USER_LOGGED_IN_SUCCESS:
                return {
                    ...state,
                    isInCheckIsUserLoggedIn: false,
                    checkIsUserLoggedInError: null,
                    isUserLoggedIn:action.payload.isUserLoggedIn,
                };
        case CHECK_IS_USER_LOGGED_IN_FAILURE:
            return {
                ...state,
                isInCheckIsUserLoggedIn: false,
                checkIsUserLoggedInError: action.payload.message
            };
        default:
                return state;
        }
}
