import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import { 
    GET_REQUEST_REPORT,
    getRequestReportSuccess,
    getRequestReportFailure
} from '../../actions/Reports/getRequestReportActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getRequestReportEpic(action$) {
    return action$.pipe(
        ofType(GET_REQUEST_REPORT),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `Request/GetRequestReportPage`,
                method: 'POST',
                headers: {
                	'Authorization':token,
                },
                body:action.reportData,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => ({
                    response:data.response,
                    reportData:action.reportData
                })))
        }),
        mergeMap(data => {
             
            console.log(data.response)
            if (data.response.isSuccess) {
                return of(getRequestReportSuccess(data.response.data,data.reportData));
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                 && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getRequestReportFailure(message));
            }
        }),
        catchError(error =>  of(getRequestReportFailure(strings.somethingWrongHappened)))
    )
}

