import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_DISPLAY_TYPE_IN_NEWS,
    updateDisplayTypeInNewsSuccess,
    updateDisplayTypeInNewsFailure
} from '../../../src/actions/News/updateDisplayTypeInNewsActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInNewsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_NEWS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInNewsSuccess(response.displayType)
            }
        ),
        catchError(error => of(updateDisplayTypeInNewsFailure(strings.somethingWrongHappened)))
    )
}

