import {
    UPDATE_REQUEST_STATUS_RESERVATION,
    UPDATE_REQUEST_STATUS_RESERVATION_SUCCESS,
    UPDATE_REQUEST_STATUS_RESERVATION_FAILURE
} from '../../actions/Reservations/updateRequestStatusReservationActions';

export function updateRequestStatusReservationReducer(state, action) {
    switch (action.type) {
        case UPDATE_REQUEST_STATUS_RESERVATION:
            return {
                ...state,
                isUpdatingRequestStatusReservation: true,
                updateRequestStatusReservationError: null,
            };
        case UPDATE_REQUEST_STATUS_RESERVATION_SUCCESS:
            return {
                ...state,
                isUpdatingRequestStatusReservation: false,
                updateRequestStatusReservationError: null,
                updateRequestStatusReservationResult: action.payload.data,
            };
        case UPDATE_REQUEST_STATUS_RESERVATION_FAILURE:
            return {
                ...state,
                isUpdatingRequestStatusReservation: false,
                updateRequestStatusReservationError: action.payload.message,
            };
        default:
            return state;
    }
}