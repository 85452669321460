import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInTicketOptionsConstant } from "../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Loading from "../Shared/Loading/Loading";

function TicketOption(props) {
  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getTicketOptionsInTicketOptions(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getTicketOptionsInTicketOptions();
  }, []);

  useEffect(() => {
    if (
      props.deleteTicketOptionResult !== null &&
      props.deleteTicketOptionResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteTicketOptionResult.message, "success").then(result => {
        props.getTicketOptionsInTicketOptions(props.ticketOptionsPageNumberInTicketOptions, props.ticketOptionsPageSizeInTicketOptions,search);
      });
    }
  }, [props.deleteTicketOptionResult]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.ticketOptionsPageNumberInTicketOptions) {
        props.getTicketOptionsInTicketOptions(pageNumber, props.ticketOptionsPageSizeInTicketOptions, search);
    }
}

function handleinputSearch(selected) {
  console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getTicketOptionsInTicketOptions(1, props.ticketOptionsPageSizeInTicketOptions, selected.target.value)
}
  return (
    <>
      <div className="customer-data mt-5">
      { props.isGetTicketOptionsInTicketOptions && ( <Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
          <TableHeader
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.ticketOptionsTotalCountInTicketOptions}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.ticketOptionsPageSizeInTicketOptions)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.ticketOptionsTotalCountInTicketOptions}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
          </div>

          <div className="table-responsive p-0">
              <table className="table align-middle text-center table-bordered border-bottom m-0">
                <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                  <tr className="fw-bolder text-muted text-center align-middle bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.arabicName}</th>
                    <th className="min-w-125px px-0">{strings.englishName}</th>
                    <th className="min-w-125px px-0">{strings.price}</th>
                    <th className="min-w-125px px-0">{strings.showInput}</th>
                    <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                      {strings.action}
                    </th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                { props.ticketOptionsInTicketOptions !== [] &&
                          props.ticketOptionsInTicketOptions.length > 0 &&
                          props.ticketOptionsInTicketOptions.map((ticketOption, index) => {
                            return (
                              <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketOption.nameAr}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketOption.nameEn}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketOption.price}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketOption.isInput ? strings.yes : strings.no}</h4>
                        </div>
                      </div>
                    </td>
                    <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                      <div className="d-flex justify-content-center">
                        <div
                          className="actions"
                          style={{ backgroundColor: "#e4e6ef" }}
                        >
                          <div className="action1">
                           <a  onClick={() => {
                              props.getEditedTicketOption(ticketOption.id); 
                             props.updateDisplayTypeInTicketOptions(
                              DisplayTypesInTicketOptionsConstant.EDIT
                            )
                            }}>
                           
                              <i className="fas fa-edit edit"></i>
                            </a>
                          
                          </div>
                          <div className="action2">
                            <a
                               onClick={() => {Delete(props,ticketOption.id)}}> 
                              <i className="fas fa-trash delete"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
                          })} 
                </tbody>
              </table>
          </div>

          <div className="card-footer">
        {
            props.ticketOptionsTotalCountInTicketOptions !== null
            && props.ticketOptionsTotalCountInTicketOptions > 0
            && (
                <div className="d-flex flex-stack flex-wrap ">
                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.ticketOptionsPageNumberInTicketOptions - 1) * props.ticketOptionsPageSizeInTicketOptions) + 1} {strings.to} {(props.ticketOptionsPageNumberInTicketOptions * props.ticketOptionsPageSizeInTicketOptions) > props.ticketOptionsTotalCountInTicketOptions ? props.ticketOptionsTotalCountInTicketOptions : (props.ticketOptionsPageNumberInTicketOptions * props.ticketOptionsPageSizeInTicketOptions)} {strings.of} {props.ticketOptionsTotalCountInTicketOptions} {strings.entries}</div>
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activePage={props.ticketOptionsPageNumberInTicketOptions}
                        itemsCountPerPage={props.ticketOptionsPageSizeInTicketOptions}
                        totalItemsCount={props.ticketOptionsTotalCountInTicketOptions}
                        onChange={handlePageButtonChange}
                    />
                </div>
            )}
            </div>
        </div>
      </div>
    </>
  );
}
export default TicketOption;
