import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_FAILURE
} from '../../../src/actions/ReservationTypeSettings/updateDisplayTypeInReservationTypeSettingsActions';

export function updateDisplayTypeInReservationTypeSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypeSettings: true,
                updateDisplayTypeErrorInReservationTypeSettings: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypeSettings: false,
                displayTypeInReservationTypeSettings: action.payload.displayType,
                updateDisplayTypeErrorInReservationTypeSettings: null,

                isEditingReservationTypeSetting: false,
                editReservationTypeSettingError: null,
                editReservationTypeSettingResult:null,

                isDeletingReservationTypeSetting: false,
                deleteReservationTypeSettingError: null,
                deleteReservationTypeSettingResult:null,

                isAddNewReservationTypeSetting: false,
                addNewReservationTypeSettingError: null,
                addNewReservationTypeSettingResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInReservationTypeSettings: false,
                updateDisplayTypeErrorInReservationTypeSettings: action.payload
            };
        default:
            return state;
    }
}