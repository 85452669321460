export const GET_EDITED_NEW = 'GET_EDITED_NEW';
export const GET_EDITED_NEW_SUCCESS = 'GET_EDITED_NEW_SUCCESS';
export const GET_EDITED_NEW_FAILURE = 'GET_EDITED_NEW_FAILURE';

export const getEditedNew = (newId) => {
    console.log('get edited new action')
    return ({
    type: GET_EDITED_NEW,
    newId
});
}
export const getEditedNewSuccess = (newId) => {
    console.log('get edited new success action')
    return ({
    type: GET_EDITED_NEW_SUCCESS,
    payload: { newId }
});
}
export const getEditedNewFailure = (message) => {
    console.log('get edited new fail action')
    return ({
    type: GET_EDITED_NEW_FAILURE,
    payload: { message }
});
}