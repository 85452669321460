import {
    GET_EDITED_COMPANY_RESERVATION,
    GET_EDITED_COMPANY_RESERVATION_SUCCESS,
    GET_EDITED_COMPANY_RESERVATION_FAILURE
} from '../../actions/CompanyReservations/getEditedCompanyReservationActions';

export function getEditedCompanyReservationReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_COMPANY_RESERVATION:
            return {
                ...state,
                isGetEditedCompanyReservation: true,
                getEditedCompanyReservationError: null, 
            };
        case GET_EDITED_COMPANY_RESERVATION_SUCCESS:
            return {
                ...state,
                isGetEditedCompanyReservation: false,
                getEditedCompanyReservationError: null,
                editedCompanyReservationInCompanyReservations:state.companyReservationsInCompanyReservations.filter(x=> x.id == action.payload.companyReservationId)[0],
            };
        case GET_EDITED_COMPANY_RESERVATION_FAILURE:
            return {
                ...state,
                isGetEditedCompanyReservation: false,
                getEditedCompanyReservationError: action.payload.message,
            };
        default:
            return state;
}}