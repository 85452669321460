import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import './App.css';
import './animate.min.css'
// import './Test.scss';
import './components/Dashboard/admin.css';
import strings from './localizations/strings';
import { LanguagesConstant } from './constants/LanguagesConstant';
import getNotificationsConfig from './notificationsConfig';
import setLanguage from './languageConfig';

//components
import Sidebar from "./components/Shared/SideBar/sidebar";
import Home from './components/startUp/Home';
import Login from './components/Account/Login';
import Dashboard from "./components/Dashboard/Dashboard";
import PausedTimes from "./components/PausedTime/PausedTimes";
import Roles from "./components/Role/Roles/Roles";
import SmsSettings from "./components/SmsSettings/SmsSettings";
import Discounts from "./components/Discount/Discounts";
import Users from "./components/User/Users";
import Reservations from "./components/Reservation/Reservations";
import AttendedReservations from "./components/AttendedReservation/AttendedReservations";
import UnpaidReservations from "./components/UnpaidReservation/UnpaidReservations";
import Requests from "./components/Requests/Requests";
import CancelledReservations from "./components/CancelledReservation/CancelledReservations";
import ChangePassword from "./components/Account/ChangePassword";
import Startup from "./components/startUp/Startup";
import Topnav from "./components/Shared/Topnav";
import ReservationTypes from "./components/ReservationTypes/ReservationTypes";
import TicketTypes from "./components/TicketTypes/TicketTypes";
import TicketOptions from "./components/TicketOptions/TicketOptions";
import ReservationTypeSettings from "./components/ReservationTypeSettings/ReservationTypeSettings";
import ReservationSchedules from "./components/ReservationSchedule/ReservationSchedules";
import NotificationSettings from "./components/NotificationSettings/NotificationSettings";
import Reports from "./components/Report/Reports";
import News from './components/News/News'
import Galleries from './components/Galleries/Galleries'
import BarcodeReaders from './components/BarcodeReaders/BarcodeReaders';
import LoadingApp from "./components/Shared/LoadingApp";


//login
import { loginUser } from './actions/Account/loginUserActions';
import { checkIsUserLoggedIn } from './actions/Account/checkIsUserLoggedInActions';
import { getUserSideBar } from './actions/Account/getUserSideBarActions';

//pausedTime
import { updateDisplayTypeInPausedTimes } from './actions/PausedTimes/updateDisplayTypeInPausedTimesActions';
import { getPausedTimesInPausedTimes } from './actions/PausedTimes/getPausedTimesInPausedTimesActions';
import { addNewPausedTime } from './actions/PausedTimes/addNewPausedTimeActions';
import { editPausedTime } from './actions/PausedTimes/editPausedTimeActions';
import { getEditedPausedTime } from './actions/PausedTimes/getEditedPausedTimeActions';
import { deletePausedTime } from './actions/PausedTimes/deletePausedTimeActions';
import { updateStatusPausedTime } from './actions/PausedTimes/updateStatusPausedTimeActions'

//smsSettings
import { updateDisplayTypeInSmsSettings } from './actions/SmsSettings/updateDisplayTypeInSmsSettingsActions';
import { getSmsSettingsInSmsSettings } from './actions/SmsSettings/getSmsSettingsInSmsSettingsActions';
import { addNewSmsSetting } from './actions/SmsSettings/addNewSmsSettingActions';
import { editSmsSetting } from './actions/SmsSettings/editSmsSettingActions';
import { getEditedSmsSetting } from './actions/SmsSettings/getEditedSmsSettingActions';
import { deleteSmsSetting } from './actions/SmsSettings/deleteSmsSettingActions';
import { updateStatusSmsSetting } from './actions/SmsSettings/updateStatusSmsSettingActions';
import { getSmsTypesInSmsSettings } from './actions/SmsSettings/getSmsTypesInSmsSettingsActions';
import { updateSmsTypesInSmsSettings } from './actions/SmsSettings/updateSmsTypesInSmsSettingsActions';

//notificationSettings
import { updateDisplayTypeInNotificationSettings } from './actions/NotificationSettings/updateDisplayTypeInNotificationSettingsActions';
import { getNotificationSettingsInNotificationSettings } from './actions/NotificationSettings/getNotificationSettingsInNotificationSettingsActions';
import { addNewNotificationSetting } from './actions/NotificationSettings/addNewNotificationSettingActions';
import { editNotificationSetting } from './actions/NotificationSettings/editNotificationSettingActions';
import { getEditedNotificationSetting } from './actions/NotificationSettings/getEditedNotificationSettingActions';
import { deleteNotificationSetting } from './actions/NotificationSettings/deleteNotificationSettingActions';
import { updateStatusNotificationSetting } from './actions/NotificationSettings/updateStatusNotificationSettingActions';
import { getNotificationTypesInNotificationSettings } from './actions/NotificationSettings/getNotificationTypesInNotificationSettingsActions';
import { updateNotificationTypesInNotificationSettings } from './actions/NotificationSettings/updateNotificationTypesInNotificationSettingsActions';
import { getNotificationRolesInNotificationSettings } from './actions/NotificationSettings/getNotificationRolesInNotificationSettingsActions';
import { updateNotificationRolesInNotificationSettings } from './actions/NotificationSettings/updateNotificationRolesInNotificationSettingsActions';

//discount
import { updateDisplayTypeInDiscounts } from './actions/Discounts/updateDisplayTypeInDiscountsActions';
import { getDiscountsInDiscounts } from './actions/Discounts/getDiscountsInDiscountsActions';
import { addNewDiscount } from './actions/Discounts/addNewDiscountActions';
import { editDiscount } from './actions/Discounts/editDiscountActions';
import { getEditedDiscount } from './actions/Discounts/getEditedDiscountActions';
import { deleteDiscount } from './actions/Discounts/deleteDiscountActions';
import { updateStatusDiscount } from './actions/Discounts/updateStatusDiscountActions';

//reservationSchedule
import { updateDisplayTypeInReservationSchedules } from './actions/ReservationSchedules/updateDisplayTypeInReservationSchedulesActions';
import { getReservationSchedulesInReservationSchedules } from './actions/ReservationSchedules/getReservationSchedulesInReservationSchedulesActions';
import { addNewReservationSchedule } from './actions/ReservationSchedules/addNewReservationScheduleActions';
import { editReservationSchedule } from './actions/ReservationSchedules/editReservationScheduleActions';
import { getEditedReservationSchedule } from './actions/ReservationSchedules/getEditedReservationScheduleActions';
import { deleteReservationSchedule } from './actions/ReservationSchedules/deleteReservationScheduleActions';
import { updateStatusReservationSchedule } from './actions/ReservationSchedules/updateStatusReservationScheduleActions';
import { getDaysInReservationSchedules } from './actions/ReservationSchedules/getDaysInReservationSchedulesActions';
import { updateDaysInReservationSchedules } from './actions/ReservationSchedules/updateDaysInReservationSchedulesActions';

//user
import { updateDisplayTypeInUsers } from './actions/Users/updateDisplayTypeInUsersActions';
import { getUsersInUsers } from './actions/Users/getUsersInUsersActions';
import { getEditedUser } from './actions/Users/getEditedUserActions';
import { deleteUser } from './actions/Users/deleteUserActions';
import { updateStatusUser } from './actions/Users/updateStatusUserActions';
import { mobileVerifiedUser } from './actions/Users/mobileVerifiedUserActions';
import { addNewUser } from './actions/Users/addNewUserActions';
import { editUser } from './actions/Users/editUserActions';
import { checkEmailAvailabilityInUsers } from './actions/Users/checkEmailAvailabilityInUsersActions';
import { checkMobileAvailabilityInUsers } from './actions/Users/checkMobileAvailabilityInUsersActions';

//reservation
import { updateDisplayTypeInReservations } from './actions/Reservations/updateDisplayTypeInReservationsActions';
import { getReservationsInReservations } from './actions/Reservations/getReservationsInReservationsActions';
import { getEditedReservation } from './actions/Reservations/getEditedReservationActions';
import { deleteReservation } from './actions/Reservations/deleteReservationActions';
import { updatePaymentStatusReservation } from './actions/Reservations/updatePaymentStatusReservationActions';
import { getReservationTypesInReservations } from './actions/Reservations/getReservationTypesInReservationsActions';
import { getUsersInReservations } from './actions/Reservations/getUsersInReservationsActions';
import { updateReservationTypesInReservations } from './actions/Reservations/updateReservationTypesInReservationsActions';
import { updateUsersInReservations } from './actions/Reservations/updateUsersInReservationsActions';
import { updateSelectedDateInReservations } from './actions/Reservations/updateSelectedDateInReservationsActions';
import { getReservationTimesInReservations } from './actions/Reservations/getReservationTimesInReservationsActions';
import { updateReservationTimesInReservations } from './actions/Reservations/updateReservationTimesInReservationsActions';
import { addNewReservation } from './actions/Reservations/addNewReservationActions';
import { getReservationTimesInEditReservation } from './actions/Reservations/getReservationTimesInEditReservationActions';
import { updateReservationTimesInEditReservation } from './actions/Reservations/updateReservationTimesInEditReservationActions';
import { editReservation } from './actions/Reservations/editReservationActions';
import { cancelReservation } from './actions/Reservations/cancelReservationActions';
import { getReservationDetails } from './actions/Reservations/getReservationDetailsActions';
import { updateAttendenceStatusReservation } from './actions/Reservations/updateAttendenceStatusReservationActions';
import { getAttendedReservationsInReservations } from "./actions/Reservations/getAttendedReservationsInReservationsActions";
import { getCancelledReservationsInReservations } from "./actions/Reservations/getCancelledReservationsInReservationsActions";
import { getUnpaidReservationsInReservations } from "./actions/Reservations/getUnpaidReservationsInReservationsActions";
import { updateDisplayTypeInAttendedReservations } from "./actions/Reservations/updateDisplayTypeInAttendedReservationsActions";
import { updateDisplayTypeInCancelledReservations } from "./actions/Reservations/updateDisplayTypeInCancelledReservationsActions";
import { updateDisplayTypeInUnpaidReservations } from "./actions/Reservations/updateDisplayTypeInUnpaidReservationsActions";
import { deleteCancelledReservation } from "./actions/Reservations/deleteCancelledReservationActions";
import { updateReturnMoneyStatusReservation } from "./actions/Reservations/updateReturnMoneyStatusReservationActions";
import { getRequestsInReservations } from "./actions/Reservations/getRequestsInReservationsActions";
import { updateDisplayTypeInRequests } from "./actions/Reservations/updateDisplayTypeInRequestsActions";
import { updateRequestStatusReservation } from "./actions/Reservations/updateRequestStatusReservationActions";
import { getTicketTypesInReservations } from "./actions/Reservations/getTicketTypesInReservationsActions";
import { getTicketOptionsInReservations } from "./actions/Reservations/getTicketOptionsInReservationsActions";
import { clearReservationDetails } from "./actions/Reservations/clearReservationDetailsActions";

//notifications
import { changeNotificationIsRead } from './actions/Notifications/changeNotificationIsReadActions';
import { getNotifications } from './actions/Notifications/getNotificationsActions';
import { markAllAsRead } from './actions/Notifications/markAllAsReadActions';
import { updateNotifications } from './actions/Notifications/updateNotificationsActions';

//dashboard
import { getChartsData } from './actions/Dashboard/getChartsDataActions';

//changePassword
import { changePassword } from './actions/Account/changePasswordActions';

//reservation types
import { updateDisplayTypeInReservationTypes } from "./actions/ReservationTypes/updateDisplayTypeInReservationTypesActions";
import { addNewReservationType } from "./actions/ReservationTypes/addNewReservationTypeActions";
import { getReservationTypesInReservationTypes } from "./actions/ReservationTypes/getReservationTypesInReservationTypesActions";
import { getEditedReservationType } from "./actions/ReservationTypes/getEditedReservationTypeActions";
import { editReservationType } from "./actions/ReservationTypes/editReservationTypeActions";
import { deleteReservationType } from "./actions/ReservationTypes/deleteReservationTypeActions";
import { updateStatusInReservationTypes } from "./actions/ReservationTypes/updateStatusInReservationTypesActions";

//ticket types
import { updateDisplayTypeInTicketTypes } from "./actions/TicketTypes/updateDisplayTypeInTicketTypesActions";
import { addNewTicketType } from "./actions/TicketTypes/addNewTicketTypeActions";
import { getTicketTypesInTicketTypes } from "./actions/TicketTypes/getTicketTypesInTicketTypesActions";
import { getEditedTicketType } from "./actions/TicketTypes/getEditedTicketTypeActions";
import { editTicketType } from "./actions/TicketTypes/editTicketTypeActions";
import { deleteTicketType } from "./actions/TicketTypes/deleteTicketTypeActions";

//ticket options
import { updateDisplayTypeInTicketOptions } from "./actions/TicketOptions/updateDisplayTypeInTicketOptionsActions";
import { addNewTicketOption } from "./actions/TicketOptions/addNewTicketOptionActions";
import { getTicketOptionsInTicketOptions } from "./actions/TicketOptions/getTicketOptionsInTicketOptionsActions";
import { getEditedTicketOption } from "./actions/TicketOptions/getEditedTicketOptionActions";
import { editTicketOption } from "./actions/TicketOptions/editTicketOptionActions";
import { deleteTicketOption } from "./actions/TicketOptions/deleteTicketOptionActions";
import { getReservationTypesInTicketOptions } from "./actions/TicketOptions/getReservationTypesInTicketOptionsActions";
import { updateReservationTypesInTicketOptions } from "./actions/TicketOptions/updateReservationTypesInTicketOptionsActions";

//reservation type settings
import { updateDisplayTypeInReservationTypeSettings } from "./actions/ReservationTypeSettings/updateDisplayTypeInReservationTypeSettingsActions";
import { addNewReservationTypeSetting } from "./actions/ReservationTypeSettings/addNewReservationTypeSettingActions";
import { getReservationTypeSettingsInReservationTypeSettings } from "./actions/ReservationTypeSettings/getReservationTypeSettingsInReservationTypeSettingsActions";
import { getEditedReservationTypeSetting } from "./actions/ReservationTypeSettings/getEditedReservationTypeSettingActions";
import { editReservationTypeSetting } from "./actions/ReservationTypeSettings/editReservationTypeSettingActions";
import { deleteReservationTypeSetting } from "./actions/ReservationTypeSettings/deleteReservationTypeSettingActions";
import { getReservationTypesInReservationTypeSettings } from "./actions/ReservationTypeSettings/getReservationTypesInReservationTypeSettingsActions";
import { updateReservationTypesInReservationTypeSettings } from "./actions/ReservationTypeSettings/updateReservationTypesInReservationTypeSettingsActions";

//roles
import { createNewRole } from './actions/Roles/Roles/createNewRoleActions';
import { deleteRole } from './actions/Roles/Roles/deleteRoleActions';
import { editRole } from './actions/Roles/Roles/editRoleActions';
import { getEditedRole } from './actions/Roles/Roles/getEditedRoleActions';
import { getRole } from './actions/Roles/Roles/getRoleActions';
import { getRoles } from './actions/Roles/Roles/getRolesActions';
import { updateDisplayTypeInRoles } from './actions/Roles/Roles/updateDisplayTypeInRolesActions';
import { updateStatusRole } from './actions/Roles/Roles/updateStatusRoleActions';
import { checkRoleNameArAvailabilityInRoles } from './actions/Roles/Roles/checkRoleNameArAvailabilityInRolesActions';
import { checkRoleNameEnAvailabilityInRoles } from './actions/Roles/Roles/checkRoleNameEnAvailabilityInRolesActions';

//roleUsers
import { createNewRoleUser } from './actions/Roles/RoleUsers/createNewRoleUserActions';
import { deleteRoleUser } from './actions/Roles/RoleUsers/deleteRoleUserActions';
import { editRoleUser } from './actions/Roles/RoleUsers/editRoleUserActions';
import { getEditedRoleUser } from './actions/Roles/RoleUsers/getEditedRoleUserActions';
import { getRoleUsers } from './actions/Roles/RoleUsers/getRoleUsersActions';
import { updateDisplayTypeInRoleUsers } from './actions/Roles/RoleUsers/updateDisplayTypeInRoleUsersActions';
import { updateStatusRoleUser } from './actions/Roles/RoleUsers/updateStatusRoleUserActions';
import { checkEmailAvailabilityInRoleUsers } from './actions/Roles/RoleUsers/checkEmailAvailabilityInRoleUsersActions';

//roleUsers
import { createNewRoleScreen } from './actions/Roles/RoleScreens/createNewRoleScreenActions';
import { deleteRoleScreen } from './actions/Roles/RoleScreens/deleteRoleScreenActions';
import { editRoleScreen } from './actions/Roles/RoleScreens/editRoleScreenActions';
import { getEditedRoleScreen } from './actions/Roles/RoleScreens/getEditedRoleScreenActions';
import { getRoleScreens } from './actions/Roles/RoleScreens/getRoleScreensActions';
import { getScreensInRoleScreens } from './actions/Roles/RoleScreens/getScreensInRoleScreensActions';
import { updateDisplayTypeInRoleScreens } from './actions/Roles/RoleScreens/updateDisplayTypeInRoleScreensActions';
import { updateScreensInRoleScreens } from './actions/Roles/RoleScreens/updateScreensInRoleScreensActions';
import { updateStatusRoleScreen } from './actions/Roles/RoleScreens/updateStatusRoleScreenActions';


//reports
import { updateDisplayTypeInReports } from './actions/Reports/updateDisplayTypeInReportsActions';
import { getReservationReport } from './actions/Reports/getReservationReportActions';
import { getRequestReport } from './actions/Reports/getRequestReportActions';
import { getCompanyReservationReport } from './actions/Reports/getCompanyReservationReportActions';

//news
import { updateDisplayTypeInNews } from './actions/News/updateDisplayTypeInNewsActions';
import { addNewNew } from './actions/News/addNewNewActions';
import { getNewsInNews } from './actions/News/getNewsInNewsActions';
import { getEditedNew } from './actions/News/getEditedNewActions';
import { editNew } from './actions/News/editNewActions';
import { deleteNew } from './actions/News/deleteNewActions';
import { updateStatusNew } from './actions/News/updateStatusNewActions';

//requests
import { getFromTimesInRequests } from './actions/Requests/getFromTimesInRequestsActions';
import { updateFromTimesInRequests } from './actions/Requests/updateFromTimesInRequestsActions';
import { getToTimesInRequests } from './actions/Requests/getToTimesInRequestsActions';
import { updateToTimesInRequests } from './actions/Requests/updateToTimesInRequestsActions';
import { approveRequest } from './actions/Requests/approveRequestActions';
import { getEditedRequest } from './actions/Requests/getEditedRequestActions';
import { getRequestDetails } from './actions/Requests/getRequestDetailsActions';
import { deleteRequest } from './actions/Requests/deleteRequestActions';
import { payInRequest } from './actions/Requests/payInRequestActions';
import { addNewRequest } from './actions/Requests/addNewRequestActions';
import { editRequest } from './actions/Requests/editRequestActions';
import { getSchoolTourRangesInRequests } from './actions/Requests/getSchoolTourRangesInRequestsActions';
import { getReservationTypesInRequests } from './actions/Requests/getReservationTypesInRequestsActions';
import { getTicketOptionsInRequests } from './actions/Requests/getTicketOptionsInRequestsActions';
import { getUsersInRequests } from './actions/Requests/getUsersInRequestsActions';
import { updateReservationTypesInRequests } from './actions/Requests/updateReservationTypesInRequestsActions';
//import { updateSelectedDateInRequests } from './actions/Requests/updateSelectedDateInRequestsActions';
import { updateUsersInRequests } from './actions/Requests/updateUsersInRequestsActions';

//galleries
import { updateDisplayTypeInGalleries } from './actions/Galleries/updateDisplayTypeInGalleriesActions';
import { addNewGallery } from './actions/Galleries/addNewGalleryActions';
import { getGalleriesInGalleries } from './actions/Galleries/getGalleriesInGalleriesActions';
import { getEditedGallery } from './actions/Galleries/getEditedGalleryActions';
import { editGallery } from './actions/Galleries/editGalleryActions';
import { deleteGallery } from './actions/Galleries/deleteGalleryActions';
import { updateStatusGallery } from './actions/Galleries/updateStatusGalleryActions';

//school tour range
import { addNewSchoolTourRange } from './actions/SchoolTourRanges/addNewSchoolTourRangeActions';
import { editSchoolTourRange } from './actions/SchoolTourRanges/editSchoolTourRangeActions';
import { getEditedSchoolTourRange } from './actions/SchoolTourRanges/getEditedSchoolTourRangeActions';
import { getSchoolTourRanges } from './actions/SchoolTourRanges/getSchoolTourRangesActions';
import { deleteSchoolTourRange } from './actions/SchoolTourRanges/deleteSchoolTourRangeActions';
import { updateDisplayTypeInSchoolTourRanges } from './actions/SchoolTourRanges/updateDisplayTypeInSchoolTourRangesActions';
import SchoolTourRanges from "./components/SchoolTourRanges/SchoolTourRanges";

//companies
import { addNewCompany } from './actions/Companies/addNewCompanyActions';
import { deleteCompany } from './actions/Companies/deleteCompanyActions';
import { editCompany } from './actions/Companies/editCompanyActions';
import { getCompaniesInCompanies } from './actions/Companies/getCompaniesInCompaniesActions';
import { getEditedCompany } from './actions/Companies/getEditedCompanyActions';
import { updateDisplayTypeInCompanies } from './actions/Companies/updateDisplayTypeInCompaniesActions';
import { updateStatusCompany } from './actions/Companies/updateStatusCompanyActions';
import Companies from "./components/Companies/Companies";

//company reservation
import { addNewCompanyReservation } from './actions/CompanyReservations/addNewCompanyReservationActions';
import { deleteCompanyReservation } from './actions/CompanyReservations/deleteCompanyReservationActions';
import { editCompanyReservation } from './actions/CompanyReservations/editCompanyReservationActions';
import { getCompaniesInCompanyReservations } from './actions/CompanyReservations/getCompaniesInCompanyReservationsActions';
import { getCompanyReservationDetails } from './actions/CompanyReservations/getCompanyReservationDetailsActions';
import { getCompanyReservationTimesInCompanyReservations } from './actions/CompanyReservations/getCompanyReservationTimesInCompanyReservationsActions';
import { getCompanyReservationsInCompanyReservations } from './actions/CompanyReservations/getCompanyReservationsInCompanyReservationsActions';
import { getEditedCompanyReservation } from './actions/CompanyReservations/getEditedCompanyReservationActions';
import { getReservationTypesInCompanyReservations } from './actions/CompanyReservations/getReservationTypesInCompanyReservationsActions';
import { getTicketOptionsInCompanyReservations } from './actions/CompanyReservations/getTicketOptionsInCompanyReservationsActions';
import { getTicketTypesInCompanyReservations } from './actions/CompanyReservations/getTicketTypesInCompanyReservationsActions';
import { updateCompaniesInCompanyReservations } from './actions/CompanyReservations/updateCompaniesInCompanyReservationsActions';
import { updateCompanyReservationTimesInCompanyReservations } from './actions/CompanyReservations/updateCompanyReservationTimesInCompanyReservationsActions';
import { updateDisplayTypeInCompanyReservations } from './actions/CompanyReservations/updateDisplayTypeInCompanyReservationsActions';
import { updateReservationTypesInCompanyReservations } from './actions/CompanyReservations/updateReservationTypesInCompanyReservationsActions';
import { updateSelectedDateInCompanyReservations } from './actions/CompanyReservations/updateSelectedDateInCompanyReservationsActions';
import { getToTimesInCompanyReservations } from './actions/CompanyReservations/getToTimesInCompanyReservationsActions';
import { updateToTimesInCompanyReservations } from './actions/CompanyReservations/updateToTimesInCompanyReservationsActions';
import { changeIsPaidInCompanyReservations } from './actions/CompanyReservations/changeIsPaidInCompanyReservationsActions';
import CompanyReservations from './components/CompanyReservation/CompanyReservations'

//reservation details with code
import { getReservationDetailsWithCode } from './actions/ReservationDetails/getReservationDetailsWithCodeActions'

import './style.bundle.css'
import PaymentSuccess from './components/Payment/PaymentSuccess';
import PaymentFailure from './components/Payment/PaymentFailure';
import ReservationDetails from "./components/ReservationDetails/ReservationDetails";
//import './Css/StyleEn.css'

function App(props) {
  const [toggle, settoggle] = useState(false);
  const [slidershow, setslidershow] = useState(false);
  const aquaContext = localStorage.getItem('AquaContext')
  React.useEffect(() => {
    //document.title="Fix"
    if (window.location.href.includes('/ar/') || window.location.href.endsWith('/ar')) {

      import('./style.bundle.rtl.css');
      //  import('./Css/StyleAr.css');
    }

    if (aquaContext !== null) {
      props.checkIsUserLoggedIn();
    }
  }, []);

  React.useEffect(() => {
    if (aquaContext !== null &&
      props.isInCheckIsUserLoggedIn === false &&
      props.isUserLoggedIn === true) {
      props.getNotifications();
      getNotificationsConfig(props);
    }

  }, [props.isUserLoggedIn]);

  const base = "/:locale(en|ar)?";
  const sideTwoKlasses = classNames({
    "app": true,
    "rtl": localStorage.getItem("language-code") === "ar" ? true : false,
    "toggled": false,
    "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed": false
  });
  return (
    <>
      {
        setLanguage(localStorage.getItem("language-code"))
      }
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`${base}/PaymentSuccess`}
            render={(routeProps) => (
              <PaymentSuccess {...routeProps} />
            )}
          />
          <Route
            exact
            path={`${base}/PaymentFailure`}
            render={(routeProps) => (
              <PaymentFailure {...routeProps} />
            )}
          />
          <Route
            exact
            path={`${base}/ReservationDetails`}
            render={(routeProps) => (
              <ReservationDetails {...props}{...routeProps} />
            )}
          />
          {
            localStorage.getItem("language-code") === null || (aquaContext !== null &&
              props.isInCheckIsUserLoggedIn === true)
              ? (
                <LoadingApp />
              ) : (
                <Route
                  render={({ location, history }) => (
                    <React.Fragment>
                      <div className={sideTwoKlasses}>
                        {
                          aquaContext !== null &&
                          props.isInCheckIsUserLoggedIn === false &&
                          props.isUserLoggedIn === true
                          && (
                            <Sidebar
                              handletoggle={settoggle}
                              toggle={toggle}
                              rtl={strings.getLanguage() === LanguagesConstant.AR ? true : false}
                              userSideBar={props.userSideBar}
                              location={location} history={history} />
                          )}
                        <div className={`${aquaContext !== null &&
                          props.isInCheckIsUserLoggedIn === false &&
                          props.isUserLoggedIn === true ? "section" : ""}`}>
                          <div className="sticky-top zIndex-1021">
                            <Topnav handletoggle={() => settoggle(true)} close={() => settoggle(false)} show={toggle}
                              {...props} location={location} history={history} />

                          </div>
                          <div style={{ display: "flex", width: "100%", justifyContent: "center", backgroundColor: "white" }}>
                            <Route
                              exact
                              path={`${base}/login`}
                              render={(routeProps) => (
                                <Login {...props} {...routeProps} />
                              )}
                            />

                            <Route
                              exact
                              path={`${base}/home`}
                              render={(routeProps) => (
                                <Home {...props} {...routeProps} />
                              )}
                            />
                            <Route
                              exact
                              path={`${base}`}
                              render={(routeProps) => (
                                <Home {...props} {...routeProps} />
                              )}
                            />

                            {
                              aquaContext !== null &&
                              props.isInCheckIsUserLoggedIn === false &&
                              props.isUserLoggedIn === true
                              && (
                                <>
                                  <Route
                                    exact
                                    path={`${base}/startup`}
                                    render={(routeProps) => (
                                      <Startup {...props} {...routeProps} />
                                    )}
                                  />

                                  <Route
                                    exact
                                    path={`${base}/ReservationTypes`}
                                    render={(routeProps) => (
                                      <ReservationTypes {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/TicketTypes`}
                                    render={(routeProps) => (
                                      <TicketTypes {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/TicketOptions`}
                                    render={(routeProps) => (
                                      <TicketOptions {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/ReservationTypeSettings`}
                                    render={(routeProps) => (
                                      <ReservationTypeSettings {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/ReservationSchedules`}
                                    render={(routeProps) => (
                                      <ReservationSchedules {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Discounts`}
                                    render={(routeProps) => (
                                      <Discounts {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/PausedTimes`}
                                    render={(routeProps) => (
                                      <PausedTimes {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Reservations`}
                                    render={(routeProps) => (
                                      <Reservations {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/AttendedReservations`}
                                    render={(routeProps) => (
                                      <AttendedReservations {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/UnpaidReservations`}
                                    render={(routeProps) => (
                                      <UnpaidReservations {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Requests`}
                                    render={(routeProps) => (
                                      <Requests {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/CancelledReservations`}
                                    render={(routeProps) => (
                                      <CancelledReservations {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Roles`}
                                    render={(routeProps) => (
                                      <Roles {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Users`}
                                    render={(routeProps) => (
                                      <Users {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/SmsSettings`}
                                    render={(routeProps) => (
                                      <SmsSettings {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Notifications`}
                                    render={(routeProps) => (
                                      <NotificationSettings {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Reports`}
                                    render={(routeProps) => (
                                      <Reports {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/News`}
                                    render={(routeProps) => (
                                      <News {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Galleries`}
                                    render={(routeProps) => (
                                      <Galleries {...props} {...routeProps} />)} />

                                  <Route
                                    exact
                                    path={`${base}/Dashboard`}
                                    render={(routeProps) => (
                                      <Dashboard {...props} {...routeProps} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={`${base}/ChangePassword`}
                                    render={(routeProps) => (
                                      <ChangePassword {...props} {...routeProps} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={`${base}/BarcodeReaders`}
                                    render={(routeProps) => (
                                      <BarcodeReaders {...props} {...routeProps} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={`${base}/SchoolTourRanges`}
                                    render={(routeProps) => (
                                      <SchoolTourRanges {...props} {...routeProps} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={`${base}/Companies`}
                                    render={(routeProps) => (
                                      <Companies {...props} {...routeProps} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={`${base}/CompanyReservations`}
                                    render={(routeProps) => (
                                      <CompanyReservations {...props} {...routeProps} />
                                    )}
                                  />
                                </>
                              )}


                            {/* {
                        localStorage.getItem("language-code") !== null
                        && props.isInCheckIsUserLoggedIn === true
                        && aquaContext == null
                        && (
                          <Redirect to={`/${localStorage.getItem("language-code") === LanguagesConstant.EN ? "en" : "ar"}/home`} />
                        )
                      } */}
                            {/* {
                        aquaContext === null
                        && localStorage.getItem("language-code") !== null
                        && props.isInCheckIsUserLoggedIn === true
                        && (
                          <Redirect to={`/${localStorage.getItem("language-code") === LanguagesConstant.EN ? "en" : "ar"}/home`} />
                        )
                      } */}
                            {/* {
                        aquaContext !== null &&
                        props.isInCheckIsUserLoggedIn === false &&
                        props.isUserLoggedIn === false
                        && (
                          <Redirect to={`/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/login`} />
                        )
                      } */}
                            {/* {
                        aquaContext !== null &&
                        props.isInCheckIsUserLoggedIn === true
                        && (
                          <LoadingApp />
                        )
                      } */}

                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                />
              )
          }

        </Switch>
      </BrowserRouter>
    </>
  );
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    //login
    loginUser,
    checkIsUserLoggedIn,
    getUserSideBar,

    //pausedTime
    updateDisplayTypeInPausedTimes,
    getPausedTimesInPausedTimes,
    updateStatusPausedTime,
    deletePausedTime,
    getEditedPausedTime,
    editPausedTime,
    addNewPausedTime,

    //smsSettings
    updateDisplayTypeInSmsSettings,
    getSmsSettingsInSmsSettings,
    updateStatusSmsSetting,
    deleteSmsSetting,
    getEditedSmsSetting,
    editSmsSetting,
    addNewSmsSetting,
    getSmsTypesInSmsSettings,
    updateSmsTypesInSmsSettings,

    //notificationSettings
    updateDisplayTypeInNotificationSettings,
    getNotificationSettingsInNotificationSettings,
    updateStatusNotificationSetting,
    deleteNotificationSetting,
    getEditedNotificationSetting,
    editNotificationSetting,
    addNewNotificationSetting,
    getNotificationTypesInNotificationSettings,
    updateNotificationTypesInNotificationSettings,
    getNotificationRolesInNotificationSettings,
    updateNotificationRolesInNotificationSettings,



    //discount
    updateDisplayTypeInDiscounts,
    getDiscountsInDiscounts,
    updateStatusDiscount,
    deleteDiscount,
    getEditedDiscount,
    editDiscount,
    addNewDiscount,

    //reservationSchedule
    updateDisplayTypeInReservationSchedules,
    getReservationSchedulesInReservationSchedules,
    updateStatusReservationSchedule,
    deleteReservationSchedule,
    getEditedReservationSchedule,
    editReservationSchedule,
    addNewReservationSchedule,
    getDaysInReservationSchedules,
    updateDaysInReservationSchedules,

    //user
    updateDisplayTypeInUsers,
    getUsersInUsers,
    updateStatusUser,
    mobileVerifiedUser,
    deleteUser,
    getEditedUser,
    addNewUser,
    editUser,
    checkEmailAvailabilityInUsers,
    checkMobileAvailabilityInUsers,

    //reservation
    updateDisplayTypeInReservations,
    getReservationsInReservations,
    updatePaymentStatusReservation,
    deleteReservation,
    getEditedReservation,
    getReservationTypesInReservations,
    getUsersInReservations,
    updateReservationTypesInReservations,
    updateUsersInReservations,
    updateSelectedDateInReservations,
    getReservationTimesInReservations,
    updateReservationTimesInReservations,
    addNewReservation,
    getReservationTimesInEditReservation,
    updateReservationTimesInEditReservation,
    editReservation,
    cancelReservation,
    getReservationDetails,
    updateAttendenceStatusReservation,
    getAttendedReservationsInReservations,
    getCancelledReservationsInReservations,
    getUnpaidReservationsInReservations,
    updateDisplayTypeInAttendedReservations,
    updateDisplayTypeInCancelledReservations,
    updateDisplayTypeInUnpaidReservations,
    deleteCancelledReservation,
    updateReturnMoneyStatusReservation,
    getRequestsInReservations,
    updateDisplayTypeInRequests,
    updateRequestStatusReservation,
    getTicketTypesInReservations,
    getTicketOptionsInReservations,
    clearReservationDetails,

    //notifications
    changeNotificationIsRead,
    getNotifications,
    markAllAsRead,
    updateNotifications,

    //dashboard
    getChartsData,

    //changePassword
    changePassword,

    //reservation types
    updateDisplayTypeInReservationTypes,
    addNewReservationType,
    getReservationTypesInReservationTypes,
    getEditedReservationType,
    editReservationType,
    deleteReservationType,
    updateStatusInReservationTypes,

    //ticket types
    updateDisplayTypeInTicketTypes,
    addNewTicketType,
    getTicketTypesInTicketTypes,
    getEditedTicketType,
    editTicketType,
    deleteTicketType,

    //ticket options
    updateDisplayTypeInTicketOptions,
    addNewTicketOption,
    getTicketOptionsInTicketOptions,
    getEditedTicketOption,
    editTicketOption,
    deleteTicketOption,
    getReservationTypesInTicketOptions,
    updateReservationTypesInTicketOptions,

    //reservation type settings
    updateDisplayTypeInReservationTypeSettings,
    addNewReservationTypeSetting,
    getReservationTypeSettingsInReservationTypeSettings,
    getEditedReservationTypeSetting,
    editReservationTypeSetting,
    deleteReservationTypeSetting,
    getReservationTypesInReservationTypeSettings,
    updateReservationTypesInReservationTypeSettings,

    //roles
    createNewRole,
    deleteRole,
    editRole,
    getEditedRole,
    getRole,
    getRoles,
    updateDisplayTypeInRoles,
    updateStatusRole,
    checkRoleNameArAvailabilityInRoles,
    checkRoleNameEnAvailabilityInRoles,

    //roleUsers
    createNewRoleUser,
    deleteRoleUser,
    editRoleUser,
    getEditedRoleUser,
    getRoleUsers,
    updateDisplayTypeInRoleUsers,
    updateStatusRoleUser,
    checkEmailAvailabilityInRoleUsers,

    //roleUsers
    createNewRoleScreen,
    deleteRoleScreen,
    editRoleScreen,
    getEditedRoleScreen,
    getRoleScreens,
    getScreensInRoleScreens,
    updateDisplayTypeInRoleScreens,
    updateScreensInRoleScreens,
    updateStatusRoleScreen,

    //reports
    updateDisplayTypeInReports,
    getReservationReport,
    getRequestReport,
    getCompanyReservationReport,

    //news
    updateDisplayTypeInNews,
    addNewNew,
    getNewsInNews,
    getEditedNew,
    editNew,
    deleteNew,
    updateStatusNew,

    //requests
    getFromTimesInRequests,
    updateFromTimesInRequests,
    getToTimesInRequests,
    updateToTimesInRequests,
    approveRequest,
    getEditedRequest,
    getRequestDetails,
    deleteRequest,
    payInRequest,
    addNewRequest,
    editRequest,
    getSchoolTourRangesInRequests,
    getReservationTypesInRequests,
    getTicketOptionsInRequests,
    getUsersInRequests,
    updateReservationTypesInRequests,
    //updateSelectedDateInRequests,
    updateUsersInRequests,

    //galleries
    updateDisplayTypeInGalleries,
    addNewGallery,
    getGalleriesInGalleries,
    getEditedGallery,
    editGallery,
    deleteGallery,
    updateStatusGallery,

    //school tour range
    addNewSchoolTourRange,
    editSchoolTourRange,
    getEditedSchoolTourRange,
    getSchoolTourRanges,
    deleteSchoolTourRange,
    updateDisplayTypeInSchoolTourRanges,

    //companies
    addNewCompany,
    deleteCompany,
    editCompany,
    getCompaniesInCompanies,
    getEditedCompany,
    updateDisplayTypeInCompanies,
    updateStatusCompany,

    //company reservation
    addNewCompanyReservation,
    deleteCompanyReservation,
    editCompanyReservation,
    getCompaniesInCompanyReservations,
    getCompanyReservationDetails,
    getCompanyReservationTimesInCompanyReservations,
    getCompanyReservationsInCompanyReservations,
    getEditedCompanyReservation,
    getReservationTypesInCompanyReservations,
    getTicketOptionsInCompanyReservations,
    getTicketTypesInCompanyReservations,
    updateCompaniesInCompanyReservations,
    updateCompanyReservationTimesInCompanyReservations,
    updateDisplayTypeInCompanyReservations,
    updateReservationTypesInCompanyReservations,
    updateSelectedDateInCompanyReservations,
    getToTimesInCompanyReservations,
    updateToTimesInCompanyReservations,
    changeIsPaidInCompanyReservations,

    //reservation details with code
    getReservationDetailsWithCode

  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(App);