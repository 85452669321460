import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_TICKET_TYPE,
    getEditedTicketTypeSuccess,
    getEditedTicketTypeFailure
} from '../../actions/TicketTypes/getEditedTicketTypeActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedTicketTypeEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_TICKET_TYPE),
        map(action =>({
            ticketTypeId : action.ticketTypeId 
        })),
        map(response => 
           getEditedTicketTypeSuccess(response.ticketTypeId)
           
        ),
        catchError(error => of(getEditedTicketTypeFailure(strings.somethingWrongHappened)))
    )
}

