export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const getNotifications = (pageNumber =1,pageSize=10) => ({
    type: GET_NOTIFICATIONS,
    pageNumber,
    pageSize
});

export const getNotificationsSuccess = (data,pageNumber,pageSize) => ({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: { data,pageNumber,pageSize }
});

export const getNotificationsFailure = (message) => ({
    type: GET_NOTIFICATIONS_FAILURE,
    payload: { message }
});