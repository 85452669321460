import {
    CREATE_NEW_ROLE,
    CREATE_NEW_ROLE_SUCCESS,
    CREATE_NEW_ROLE_FAILURE
} from '../../../actions/Roles/Roles/createNewRoleActions';

export function createNewRoleReducer(state, action) {
    switch (action.type) {
        case CREATE_NEW_ROLE:
            return {
                ...state,
                isCreateNewRole: true,
                createNewRoleError: null,
            };
        case CREATE_NEW_ROLE_SUCCESS:
            return {
                ...state,
                isCreateNewRole: false,
                createNewRoleError: null,
                createNewRoleResult: action.payload.data,
            };
        case CREATE_NEW_ROLE_FAILURE:
            return {
                ...state,
                isCreateNewRole: false,
                createNewRoleError: action.payload.message,
            };
        default:
            return state;
}}