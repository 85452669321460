import React from 'react';
import logo1 from "../../images/ic_logo.png";
import plurk from "../../images/plurk.svg";
import figma from "../../images/figma-1.svg";
import vimeo from "../../images/vimeo.svg";
import $ from "jquery";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import strings from '../../localizations/strings';
import { Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';

class Startup extends React.Component {
    state = {
        Notifications:[
            {id:1,title:"Project Alice",description:"Phase 1 development",time:"1 hr",avatarurl:"/images/notifi.svg"},
            {id:2,title:"HR Confidential",description:"Confidential staff documents",time:"2 hr",avatarurl:"/images/notifi2.svg"}
        ],
    ar:true,
        Searchresult:[],
        search:""
    };
    componentDidMount() {

        let url = window.location.href;
        let lan=localStorage.getItem('language-code')
        if (url.indexOf('/ar/') !== -1&&lan==='ar') {
            this.setState({ar:true})
        }
        else  if (url.indexOf('/en/') !== -1&&lan==='en') {
            this.setState({ar:false})
        }

        window.addEventListener('scroll', this.handleScroll);
    }
    handleScroll=()=>{
        $(".SearchCard").removeClass("active");
        $(".icon-search").removeClass("searchbtnActive");
        $("#Search-form").removeClass("active");
        this.setState({Searchresult:[],search:""})
    }
    handleGetSearch=(e)=>{
        console.log(e.target.value);
        //function search here
        this.setState({Searchresult:e.target.value,search:e.target.value})
    }

    handleSearch=()=>{
        this.setState({Searchresult:[],search:""})
        $(".icon-search").toggleClass("searchbtnActive");
        $(".SearchCard").toggleClass("active");
        $("#Search-form").toggleClass("active");


        }
    handlehidesearch=()=>{
        $(".SearchCard").removeClass("active");
        $(".icon-search").removeClass("searchbtnActive");
        this.setState({Searchresult:[],search:""})
    }
                        handleLanChange=(e)=>{

                         let url = window.location.href;
                        if (strings.getLanguage() === LanguagesConstant.EN) {
                            if (url.indexOf('/en/') !== -1) {
                                url = url.replace('/en/', '/ar/');
                            }


                            strings.setLanguage(LanguagesConstant.AR);

                            let arabicStyleLink = document.querySelector("#arabicStyleLink");
                            if (arabicStyleLink === null) {
                                arabicStyleLink = document.createElement('link');
                                arabicStyleLink.href = "/StyleAr.css";
                                arabicStyleLink.id = "arabicStyleLink";
                                arabicStyleLink.rel = "stylesheet";


                                document.querySelector("head").appendChild(arabicStyleLink);
                            }



                        } else {
                            if (url.indexOf('/ar/') !== -1) {
                                url = url.replace('/ar/', '/en/');
                            }
                            strings.setLanguage(LanguagesConstant.EN);

                            const arabicStyleLink = document.querySelector("#arabicStyleLink");
                            if (arabicStyleLink !== null) {
                                document.querySelector("head").removeChild(arabicStyleLink);
                            }

                        }
                        localStorage.setItem("language-code", strings.getLanguage());
                        window.location.href =
                            `/${strings.getLanguage()}${this.props.location.pathname
                                .replace('/en', '').replace('/ar', '') === '/' ? ''
                                : `${this.props.location.pathname.replace('/en', '').replace('/ar', '')}`}`;






                            this.setState({ar:e})
                        }
    render() {
        return (
            <div className="home-container">
            {/* {/* <Topnav {...this.props} /> */}
            <div className="container">
              <div className="row f-sec">
                <body id="kt_body" className="p-0 header-fixed header-tablet-and-mobile-fixed toolbar-enabled">
                <div className=' animate__animated  animate__backInLeft'>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                       
                      
                        <div id="kt_content_container" className="py-3 py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
                            <div className="content flex-row-fluid" id="kt_content">
                                
                                <div className="row g-5 g-xl-8">
                                    
                                    <div className="col-xl-4">
                                        
                                        <div className="card card-xl-stretch mb-xl-3">
                                            
                                            <div className="card-header border-0 py-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bolder fs-3 mb-1">Trends</span>
                                                    <span className="text-muted fw-bold fs-7">Latest trends</span>
                                                </h3>
                                                <div className="card-toolbar">
                                                    
                                                    <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span className="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"></rect>
                                                                    <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                                                    <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                                                    <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        
                                                    </button>
                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
                                                        
                                                        <div className="menu-item px-3">
                                                            <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                                                        </div>
                                                        
                                                        
                                                        <div className="menu-item px-3">
                                                            <a href="#" className="menu-link px-3">Create Invoice</a>
                                                        </div>
                                                        
                                                        
                                                        <div className="menu-item px-3">
                                                            <a href="#" className="menu-link flex-stack px-3">Create Payment 
                                                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a target name for future usage and reference" aria-label="Specify a target name for future usage and reference"></i></a>
                                                        </div>
                                                        
                                                        
                                                        <div className="menu-item px-3">
                                                            <a href="#" className="menu-link px-3">Generate Bill</a>
                                                        </div>
                                                        
                                                        
                                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                                                            <a href="#" className="menu-link px-3">
                                                                <span className="menu-title">Subscription</span>
                                                                <span className="menu-arrow"></span>
                                                            </a>
                                                            
                                                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                
                                                                <div className="menu-item px-3">
                                                                    <a href="#" className="menu-link px-3">Plans</a>
                                                                </div>
                                                                
                                                                
                                                                <div className="menu-item px-3">
                                                                    <a href="#" className="menu-link px-3">Billing</a>
                                                                </div>
                                                                
                                                                
                                                                <div className="menu-item px-3">
                                                                    <a href="#" className="menu-link px-3">Statements</a>
                                                                </div>
                                                                
                                                                
                                                                <div className="separator my-2"></div>
                                                                
                                                                
                                                                <div className="menu-item px-3">
                                                                    <div className="menu-content px-3">
                                                                        
                                                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                                                           
                         <input className="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications"/>
                                                                           
                                                                            
                                                                            <span className="form-check-label text-muted fs-6">Recuring</span>
                                                                            
                                                                        </label>
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                        <div className="menu-item px-3 my-1">
                                                            <a href="#" className="menu-link px-3">Settings</a>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="card-body d-flex flex-column">
                                                <div>
                                                    
                                                    <div className="d-flex flex-stack mb-5">
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            
                                                            <div className="symbol symbol-50px me-3">
                                                                <div className="symbol-label bg-light">
                                                                    <img src={plurk}
                                                                     className="h-50" alt=""/>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                            <div>
                                                                <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">Top Authors</a>
                                                                <div className="fs-7 text-muted fw-bold mt-1">Ricky Hunt, Sandra Trepp</div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                        <div className="badge badge-light fw-bold py-4 px-3">+82$</div>
                                                        
                                                    </div>
                                                    
                                                    
                                                    <div className="d-flex flex-stack mb-5">
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            
                                                            <div className="symbol symbol-50px me-3">
                                                                <div className="symbol-label bg-light">
                                                                    <img src={figma} className="h-50" alt=""/>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                            <div>
                                                                <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">Top Sales</a>
                                                                <div className="fs-7 text-muted fw-bold mt-1">PitStop Emails</div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                        <div className="badge badge-light fw-bold py-4 px-3">+82$</div>
                                                        
                                                    </div>
                                                    
                                                    
                                                    <div className="d-flex flex-stack">
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            
                                                            <div className="symbol symbol-50px me-3">
                                                                <div className="symbol-label bg-light">
                                                                    <img src={vimeo} className="h-50" alt=""/>
                                                                </div>
                                                            </div>
                                                           
                                                           
                                                            <div className="py-1">
                                                                <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">Top Engagement</a>
                                                                <div className="fs-7 text-muted fw-bold mt-1">KT.com</div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                        <div className="badge badge-light fw-bold py-4 px-3">+82$</div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                               
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="col-xl-8">
                                        
                                        <div className="row gx-5 gx-xl-8 mb-5 mb-xl-8">
                                            <div className="col-xl-3">
                                                
                                                <div className="card h-150px bgi-no-repeat bgi-size-cover card-xl-stretch bg-image-3">
                                                    
                                                    <div className="card-body p-6">
                                                       
                                                        <a href="#" className="text-black text-hover-primary fw-bolder fs-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Properties</a>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-xl-9">
                                                <div className="card h-150px card-xl-stretch">
                                                    
                                                    <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                                                        <div className="me-2">
                                                            <h2 className="fw-bolder text-gray-800 mb-3">Create CRM Reports</h2>
                                                            <div className="text-muted fw-bold fs-6">Generate the latest CRM report for company projects</div>
                                                        </div>
                                                        <a href="#" className="btn btn-primary fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Start Now</a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="row gx-5 gx-xl-8 mb-5 mb-xl-8">
                                            
                                            <div className="col-xl-6">
                                                <div className="card h-175px bgi-no-repeat bgi-size-contain card-xl-stretch mb-5 mb-xl-8 bg-image-4">
                                                    
                                                    <div className="card-body d-flex flex-column justify-content-between">
                                                       
                                                        <h2 className="text-white fw-bolder mb-5">Create SaaS
                                                        <br/>Based Reports</h2>
                                                        
                                                        
                                                        <div className="m-0">
                                                            <a href="#" className="btn btn-danger fw-bold px-6 py-3" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Create Campaign</a>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div className="row gx-5 gx-xl-8">
                                                    
                                                    <div className="col-xxl-6">
                                                        <a href="#" className="card card-xxl-stretch bg-primary">
                                                            
                                                            <div className="card-body d-flex flex-column justify-content-between">
                                                                
                                                                <span className="svg-icon svg-icon-white svg-icon-2hx ms-n1 flex-grow-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                    </svg>
                                                                </span>
                                                                
                                                                <div className="d-flex flex-column">
                                                                    <div className="text-white fw-bolder fs-1 mb-0 mt-5">790</div>
                                                                    <div className="text-white fw-bold fs-6">New Products</div>
                                                                </div>
                                                            </div>
                                                            
                                                        </a>
                                                    </div>
                                                    
                                                    
                                                    <div className="col-xxl-6">
                                                        <a href="#" className="card card-xxl-stretch bg-body">
                                                            
                                                            <div className="card-body d-flex flex-column justify-content-between">
                                                                <span className="svg-icon svg-icon-success svg-icon-2hx ms-n1 flex-grow-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"></path>
                                                                        <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                                
                                                                <div className="d-flex flex-column">
                                                                    <div className="text-dark fw-bolder fs-1 mb-0 mt-5">8,600</div>
                                                                    <div className="text-muted fw-bold fs-6">New Customers</div>
                                                                </div>
                                                            </div>
                                                            
                                                        </a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="col-xl-6">
                                                <div className="card h-100 bgi-no-repeat bgi-size-cover card-xl-stretch mb-5 mb-xl-8 bg-image-1">
                                                    
                                                    <div className="card-body d-flex flex-column justify-content-between">
                                                       
                                                        <div className="text-white fw-bolder fs-2">
                                                        <h2 className="fw-bolder text-white mb-2">Create Reports</h2>With App</div>
                                                        
                                                        <a href="#" className="text-warning fw-bold" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Create Report 
                                                        
                                                        <span className="svg-icon svg-icon-2 svg-icon-warning">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        </a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                              
                             
                            </div>
                        </div>
                        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                           
                            <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <div className="text-dark order-2 order-md-1">
                                    <span className="text-muted fw-bold me-1">2022©</span>
                                    <a href="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</a>
                                </div>                                
                                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                                    <li className="menu-item">
                                        <a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="https://1.envato.market/EA4JP" target="_blank" className="menu-link px-2">Purchase</a>
                                    </li>
                                </ul>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </body>  
              </div>
             
            </div>
          
          </div>
          
        );
    }
}

export default Startup;