import {
    UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS,
    UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateCompaniesInCompanyReservationsActions';

export function updateCompaniesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdateCompaniesInCompanyReservations: true,
                updateCompaniesInCompanyReservationsError: null,
            };
        case UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdateCompaniesInCompanyReservations: false,
                updateCompaniesInCompanyReservationsError: null,
                companyInCompanyReservations: action.payload.company,
            };
        case UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdateCompaniesInCompanyReservations: false,
                updateCompaniesInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
}}