export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAILURE = 'DELETE_REQUEST_FAILURE';

export const deleteRequest = (requestId) => ({
    type: DELETE_REQUEST,
    requestId
});

export const deleteRequestSuccess = (data) => ({
    type: DELETE_REQUEST_SUCCESS,
    payload: { data }
});

export const deleteRequestFailure = (message) => ({
    type: DELETE_REQUEST_FAILURE,
    payload: { message }
});