import {
    DELETE_NOTIFICATION_SETTING,
    DELETE_NOTIFICATION_SETTING_SUCCESS,
    DELETE_NOTIFICATION_SETTING_FAILURE
} from '../../actions/NotificationSettings/deleteNotificationSettingActions';

import { DisplayTypesInNotificationSettingsConstant } from '../../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant';

export function deleteNotificationSettingReducer(state, action) {
    switch (action.type) {
        case DELETE_NOTIFICATION_SETTING:
            return {
                ...state,
                isDeletingNotificationSetting: true,
                deleteNotificationSettingError: null,
            };
        case DELETE_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                isDeletingNotificationSetting: false,
                deleteNotificationSettingError: null,
                deleteNotificationSettingResult: action.payload.data,
                //displayTypeInNotificationSettings: DisplayTypesInNotificationSettingsConstant.LIST,
            };
        case DELETE_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                isDeletingNotificationSetting: false,
                deleteNotificationSettingError: action.payload.message,
            };
        default:
            return state;
}}