export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_FAILURE = 'CANCEL_RESERVATION_FAILURE';

export const cancelReservation = (cancelReservationViewModel) => ({
    type: CANCEL_RESERVATION,
    cancelReservationViewModel
});

export const cancelReservationSuccess = (data) => ({
    type: CANCEL_RESERVATION_SUCCESS,
    payload: { data }
});

export const cancelReservationFailure = (message) => ({
    type: CANCEL_RESERVATION_FAILURE,
    payload: message
});