import React,{useState,useEffect} from "react";
import { DisplayTypesInPausedTimesConstant } from "../../constants/PausedTimes/DisplayTypesInPausedTimesConstant";
import PausedTime from './PausedTime';
import Add from './Add';
import Edit from './Edit';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function PausedTimes(props){

    return (
        <>

<div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.LIST ? strings.pausedTime:
                        props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.ADD ? strings.create:
                        props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.EDIT ? strings.edit:""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInPausedTimes  !== DisplayTypesInPausedTimesConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInPausedTimes(DisplayTypesInPausedTimesConstant.LIST)}>
                                    {strings.pausedTime}
                                </Button> :null}

                            {props.displayTypeInPausedTimes  !== DisplayTypesInPausedTimesConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInPausedTimes(DisplayTypesInPausedTimesConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                           
                            { props.displayTypeInPausedTimes  === DisplayTypesInPausedTimesConstant.LIST &&
                            (<PausedTime {...props} />)}
                            {props.displayTypeInPausedTimes   === DisplayTypesInPausedTimesConstant.ADD &&
                             (<Add {...props} />)}
                            {props.displayTypeInPausedTimes   === DisplayTypesInPausedTimesConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
    
                            {/* {props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.LIST && (<div className="gridWidth">
                                <PausedTime {...props} /> </div>)}
                            {props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInPausedTimes === DisplayTypesInPausedTimesConstant.EDIT && (<Edit {...props}/>)} */}


        </>
    );
}
export default PausedTimes