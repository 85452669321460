export const ADD_NEW_TICKET_TYPE = 'ADD_NEW_TICKET_TYPE';
export const ADD_NEW_TICKET_TYPE_SUCCESS = 'ADD_NEW_TICKET_TYPE_SUCCESS';
export const ADD_NEW_TICKET_TYPE_FAILURE = 'ADD_NEW_TICKET_TYPE_FAILURE';

export const addNewTicketType = (ticketTypeData) => ({
    type: ADD_NEW_TICKET_TYPE,
    ticketTypeData
});

export const addNewTicketTypeSuccess = (data) => ({
    type: ADD_NEW_TICKET_TYPE_SUCCESS,
    payload: { data }
});

export const addNewTicketTypeFailure = (message) => ({
    type: ADD_NEW_TICKET_TYPE_FAILURE,
    payload: { message }
});