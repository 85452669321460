export const UPDATE_STATUS_GALLERY = 'UPDATE_STATUS_GALLERY';
export const UPDATE_STATUS_GALLERY_SUCCESS = 'UPDATE_STATUS_GALLERY_SUCCESS';
export const UPDATE_STATUS_GALLERY_FAILURE = 'UPDATE_STATUS_GALLERY_FAILURE';

export const updateStatusGallery = (galleryId) => ({
    type: UPDATE_STATUS_GALLERY,
    galleryId
});

export const updateStatusGallerySuccess = (data) => ({
    type: UPDATE_STATUS_GALLERY_SUCCESS,
    payload: { data }
});

export const updateStatusGalleryFailure = (message) => ({
    type: UPDATE_STATUS_GALLERY_FAILURE,
    payload: { message }
});