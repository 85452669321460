export const UPDATE_STATUS_COMPANY = 'UPDATE_STATUS_COMPANY';
export const UPDATE_STATUS_COMPANY_SUCCESS = 'UPDATE_STATUS_COMPANY_SUCCESS';
export const UPDATE_STATUS_COMPANY_FAILURE = 'UPDATE_STATUS_COMPANY_FAILURE';

export const updateStatusCompany = (companyId) => ({
    type: UPDATE_STATUS_COMPANY,
    companyId
});

export const updateStatusCompanySuccess = (data) => ({
    type: UPDATE_STATUS_COMPANY_SUCCESS,
    payload: { data }
});

export const updateStatusCompanyFailure = (message) => ({
    type: UPDATE_STATUS_COMPANY_FAILURE,
    payload: { message }
});