import {
    EDIT_PAUSED_TIME,
    EDIT_PAUSED_TIME_SUCCESS,
    EDIT_PAUSED_TIME_FAILURE
} from '../../actions/PausedTimes/editPausedTimeActions';

import { DisplayTypesInPausedTimesConstant } from '../../constants/PausedTimes/DisplayTypesInPausedTimesConstant';

export function editPausedTimeReducer(state, action) {
    switch (action.type) {
        case EDIT_PAUSED_TIME:
            return {
                ...state,
                isEditingPausedTime: true,
                editPausedTimeError: null,
            };
        case EDIT_PAUSED_TIME_SUCCESS:
            return {
                ...state,
                isEditingPausedTime: false,
                editPausedTimeError: null,
                editPausedTimeResult: action.payload.data,
               // displayTypeInPausedTimes: DisplayTypesInPausedTimesConstant.LIST,
            };
        case EDIT_PAUSED_TIME_FAILURE:
            return {
                ...state,
                isEditingPausedTime: false,
                editPausedTimeError: action.payload.message,
            };
        default:
            return state;
}}