import React, { useEffect } from "react";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInSchoolTourRangesConstant } from "../../constants/SchoolTourRanges/DisplayTypesInSchoolTourRangesConstant";
import { StatusCodeConstant } from "../../constants/StatusCodeConstant";
import Loading from "../Shared/Loading/Loading";

function Add(props) {

  const formikRef = React.useRef(null);

  useEffect(() => {
    if (
      props.editSchoolTourRangeResult !== null &&
      props.editSchoolTourRangeResult.statusCode === StatusCodeConstant.Ok
    ) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editSchoolTourRangeResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInSchoolTourRanges(
          DisplayTypesInSchoolTourRangesConstant.LIST
        );
      });
    }
  }, [props.editSchoolTourRangeResult]);

  useEffect(() => {
    if (
      props.editSchoolTourRangeResult !== null &&
      props.editSchoolTourRangeResult.statusCode !== StatusCodeConstant.Ok
    ) {
      Swal.fire(
        //icon: 'error',
        "Oops...",
        //	text: `${strings.thisDataAlreadyExists}`,
        props.editSchoolTourRangeResult.message,
        "error"
      );
    }
  });

  return (
    <>
      {props.isEditingSchoolTourRange && (<Loading />)}
      {!props.isEditingSchoolTourRange &&
        (
          <div className="customer-data cus2 mt-5">
            <div className="card animate__animated  animate__backInLeft">
              {/* <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.editSchoolTourRangeData}
              </span>
            </h3>
            {/* <Button
              className="NewB2 col-1"
              onClick={() =>
                props.updateDisplayTypeInSchoolTourRanges(
                  DisplayTypesInSchoolTourRangesConstant.LIST
                )
              }
            >
              {strings.schoolTourRange}
            </Button> 
          </div> */}
              <div id="kt_account_profile_details" className="collapse show">
                <Formik
                  innerRef={formikRef}
                  initialValues={
                    {
                      from: props.editedSchoolTourRange !== null ? props.editedSchoolTourRange.countFrom : '',
                      to: props.editedSchoolTourRange !== null ? props.editedSchoolTourRange.countTo : '',
                      price: props.editedSchoolTourRange !== null ? props.editedSchoolTourRange.price : '',
                    }
                  }
                  validationSchema={Yup.object().shape({
                    from: Yup.string().required(
                      strings.countFromRequired
                    ).min(1, strings.theNumberMustBeGreaterThan1),
                    to: Yup.number().required(
                      strings.countToRequired
                    ).test(
                      'is-MaxNumber',
                      strings.toMustBeGreaterThanFrom,
                      (value) => {
                        if (!value) {
                          return true;
                        }
                        return value >= formikRef.current.values.from;
                      }),
                    price: Yup.string().required(
                      strings.priceRequired
                    ),
                  })}
                  onSubmit={(fields) => {
                    const schoolTourRange = {
                      "countFrom": fields.from,
                      "countTo": fields.to,
                      "price": fields.price,
                      "id": props.editedSchoolTourRange.id
                    }
                    props.editSchoolTourRange(schoolTourRange);

                  }}
                  render={({
                    errors,
                    touched,
                    isValidating,
                    handleChange,
                    values,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError,
                  }) => (
                    <Form id="kt_account_profile_details_form" className="form1">
                      <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.countFrom}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="text"
                              name="from"
                              className={
                                "form-control form-control1 form-control-lg form-control-solid" +
                                (errors.from &&
                                  touched.from
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={`${strings.countFrom} *`}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("to", "");
                              }}
                            />
                            <ErrorMessage
                              name="from"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.countTo}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="number"
                              name="to"
                              className={
                                "form-control form-control1 form-control-lg form-control-solid" +
                                (errors.to &&
                                  touched.to
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={`${strings.countTo} *`}
                            />
                            <ErrorMessage
                              name="to"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.price}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="number"
                              name="price"
                              className={
                                "form-control form-control1 form-control-lg form-control-solid" +
                                (errors.price &&
                                  touched.price
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={`${strings.enterPrice} *`}
                            />
                            <ErrorMessage
                              name="price"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-between p-3">
                        <div>
                          <button
                            type="submit"
                            className="btn fs-6"
                            style={{
                              backgroundColor: "#009ef7",
                              borderColor: "#009ef7",
                              color: "white",
                              borderRadius: "0.75rem",
                            }}
                            id="kt_account_profile_details_submit"
                          //	onClick={done}
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
export default Add;
