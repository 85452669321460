import React from 'react';
 
import './Language.css';
import strings from '../../../localizations/strings';
import { LanguagesConstant } from '../../../constants/LanguagesConstant';
import { HideSectionclick } from '../Fun/HideAndShowSection';

class Language extends React.Component {
  handleredirect = (code) => {

    if (strings.getLanguage() !== code) {
    let url = window.location.href;
    if (strings.getLanguage() === LanguagesConstant.EN) {
      if (url.indexOf('/en/') !== -1) {
        url = url.replace('/en/', '/ar/');
      }
      else if (url.indexOf('/en') !== -1) {
        url = url.replace('/en', '/ar');
      }
    } else {
      if (url.indexOf('/ar/') !== -1) {
        url = url.replace('/ar/', '/en/');
      }
      else if(url.indexOf('/ar') !== -1) {
        url = url.replace('/ar', '/en');
      }
    }


    //strings.setLanguage(code);

   // localStorage.setItem("language-code", code);
    HideSectionclick('languageMenu', 'languageIcon');
    window.location.replace(url)
      }
  }
   
  render() {
    return (
      <>
        <div id="languageMenu" className="menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-150px">

          <div className="menu-item px-3">
            <span className={`menu-link d-flex px-5 ${strings.getLanguage()=== LanguagesConstant.EN ? "active" : ""}`} onClick={()=>this.handleredirect(LanguagesConstant.EN)} >
              <span className="symbol symbol-20px mx-1">
                <img
                  className="rounded-1"
                  src={"/united-states.svg"}
                  alt=""
                />
              </span>
              {strings.english}
            </span>
          </div>
          <div className="menu-item px-3">
            <span
              onClick={()=>this.handleredirect(LanguagesConstant.AR)}
              className={`menu-link d-flex px-5 ${strings.getLanguage()=== LanguagesConstant.AR ? "active" : ""}`}
            >
              <span className="symbol symbol-20px mx-2">
                <img
                  className="rounded-1"
                  src={"/Flag_of_Kuwait.svg.png"}
                  alt=""
                />
              </span>
              {strings.arabic}
            </span>
          </div>
          {/* <div className="p-0 text-center border-top">
                          <a
                            href="pages/profile/activity.html"
                            className="btn btn-color-gray-600 py-2 p-0 m-0 btn-active-color-primary"
                          >
                            View All
                            <span className="svg-icon svg-icon-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="18"
                                  y="13"
                                  width="13"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-180 18 13)"
                                  fill="black"
                                ></rect>
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                          </a>
                        </div> */}
        </div>

      </>
    );
  };
}


export default Language;