import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    UPDATE_STATUS_ROLE_SCREEN,
    updateStatusRoleScreenSuccess,
    updateStatusRoleScreenFailure
} from '../../../actions/Roles/RoleScreens/updateStatusRoleScreenActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function updateStatusRoleScreenEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_STATUS_ROLE_SCREEN),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `RoleScreen/ChangeIsActive?roleScreenId=${action.roleScreenId}`,
                method: 'Post',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                //console.log(response.data);
                return of(updateStatusRoleScreenSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(updateStatusRoleScreenFailure(message));
            }
        }),
        catchError(error =>  of(updateStatusRoleScreenFailure(strings.somethingWrongHappened)))
    )
}

