import {
    CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES,
    CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_SUCCESS,
    CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_FAILURE
} from '../../../actions/Roles/Roles/checkRoleNameEnAvailabilityInRolesActions';

export function checkRoleNameEnAvailabilityInRolesReducer(state, action) {
    switch (action.type) {
        case CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES:
            return {
                ...state,
                ischeckingRoleNameEnAvailabilityInRoles: true,
                checkRoleNameEnAvailabilityErrorInRoles: null,
            };
        case CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_SUCCESS:
            return {
                ...state,
                ischeckingRoleNameEnAvailabilityInRoles: false,
                isRoleNameEnAvailableInRoles: action.payload.isAvailable,
                checkRoleNameEnAvailabilityErrorInRoles: null

            };
        case CHECK_ROLE_NAME_EN_AVAILABILITY_IN_ROLES_FAILURE:
            return {
                ...state,
                ischeckingRoleNameEnAvailabilityInRoles: false,
                checkRoleNameEnAvailabilityErrorInRoles: action.payload
            };
        default:
            return state;
    }
}