import {
    DELETE_SMS_SETTING,
    DELETE_SMS_SETTING_SUCCESS,
    DELETE_SMS_SETTING_FAILURE
} from '../../actions/SmsSettings/deleteSmsSettingActions';

import { DisplayTypesInSmsSettingsConstant } from '../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant';

export function deleteSmsSettingReducer(state, action) {
    switch (action.type) {
        case DELETE_SMS_SETTING:
            return {
                ...state,
                isDeletingSmsSetting: true,
                deleteSmsSettingError: null,
            };
        case DELETE_SMS_SETTING_SUCCESS:
            return {
                ...state,
                isDeletingSmsSetting: false,
                deleteSmsSettingError: null,
                deleteSmsSettingResult: action.payload.data,
                //displayTypeInSmsSettings: DisplayTypesInSmsSettingsConstant.LIST,
            };
        case DELETE_SMS_SETTING_FAILURE:
            return {
                ...state,
                isDeletingSmsSetting: false,
                deleteSmsSettingError: action.payload.message,
            };
        default:
            return state;
}}