export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const getRoles = (pageNumber=1,pageSize=25,search=null) => ({
    type: GET_ROLES,
    pageNumber,
    pageSize,
    search
});

export const getRolesSuccess = (roles,pageNumber,pageSize) => ({
    type: GET_ROLES_SUCCESS,
    payload: { roles ,pageNumber,pageSize }
});

export const getRolesFailure = (message) => ({
    type: GET_ROLES_FAILURE,
    payload: { message }
});