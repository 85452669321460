import {
    GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES,
    GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_SUCCESS,
    GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_FAILURE
} from '../../actions/SchoolTourRanges/getSchoolTourRangesActions';

export function getSchoolTourRangesReducer(state, action) {
    switch (action.type) {
        case GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES:
            return {
                ...state,
                isGetSchoolTourRanges: true,
                getSchoolTourRangesError: null,
            };
        case GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_SUCCESS:
            console.log(action.payload.schoolTourRanges)
            return {
                ...state,
                isGetSchoolTourRanges: false,
                getSchoolTourRangesError: null,
                schoolTourRanges: action.payload.schoolTourRanges.data,
                schoolTourRangesPageNumber: action.payload.pageNumber,
                schoolTourRangesPageSize: action.payload.pageSize,
                schoolTourRangesTotalCount: action.payload.schoolTourRanges.total,
            };
        case GET_SCHOOL_TOUR_RANGES_IN_SCHOOL_TOUR_RANGES_FAILURE:
            return {
                ...state,
                isGetSchoolTourRanges: false,
                getSchoolTourRangesError: action.payload.message,
            };
        default:
            return state;
}}