import {
    UPDATE_ATTENDENCE_STATUS_RESERVATION,
    UPDATE_ATTENDENCE_STATUS_RESERVATION_SUCCESS,
    UPDATE_ATTENDENCE_STATUS_RESERVATION_FAILURE
} from '../../actions/Reservations/updateAttendenceStatusReservationActions';

export function updateAttendenceStatusReservationReducer(state, action) {
    switch (action.type) {
        case UPDATE_ATTENDENCE_STATUS_RESERVATION:
            return {
                ...state,
                isUpdatingAttendenceStatusReservation: true,
                updateAttendenceStatusReservationError: null,
            };
        case UPDATE_ATTENDENCE_STATUS_RESERVATION_SUCCESS:
            return {
                ...state,
                isUpdatingAttendenceStatusReservation: false,
                updateAttendenceStatusReservationError: null,
                updateAttendenceStatusReservationResult: action.payload.data,
            };
        case UPDATE_ATTENDENCE_STATUS_RESERVATION_FAILURE:
            return {
                ...state,
                isUpdatingAttendenceStatusReservation: false,
                updateAttendenceStatusReservationError: action.payload.message,
            };
        default:
            return state;
    }
}