import React, { useEffect, useState, forwardRef } from "react";
import Button from "@restart/ui/esm/Button";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationSchedulesConstant } from "../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import Loading from "../Shared/Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";
import { LanguagesConstant } from "../../constants/LanguagesConstant";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);

const moment = require('moment/min/moment.min');

function Edit(props) {

  const formikRef = React.useRef(null);

  const [vacationDateError, setVacationDateError] = useState(null);
  const [fromTimeError, setFromTimeError] = React.useState(null);
  const [toTimeError, setToTimeError] = React.useState(null);
  const [dayError, setDayError] = useState(null);

  useEffect(() => {
    props.getDaysInReservationSchedules(props.editedReservationScheduleInReservationSchedules.dayId);
  }, []);

  function handleDayChanges(selectedOption) {
    if (selectedOption == null) {
      setDayError(strings.pleaseSelectDay)
    } else {
      setDayError(null)
    }
    props.updateDaysInReservationSchedules(selectedOption);
  }

  function handleFromTimeChange(value) {
    if (!moment(value).isValid()) {
      setFromTimeError(strings.dateNotValid);
    } else {
      setFromTimeError(null);
      formikRef.current.setFieldValue("fromTime", value);
      formikRef.current.setFieldValue("toTime", null);
    }
  }

  function handleToTimeChange(value) {
    if (!moment(value).isValid()) {
      setToTimeError(strings.dateNotValid);
    } else {
      setToTimeError(null);
      formikRef.current.setFieldValue("toTime", value);
    }
  }

  useEffect(() => {
    if (props.editReservationScheduleResult !== null && props.editReservationScheduleResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editReservationScheduleResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInReservationSchedules(DisplayTypesInReservationSchedulesConstant.LIST)
      }
      )
    }
  }, [props.editReservationScheduleResult])

  useEffect(() => {
    if (props.editReservationScheduleError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editReservationScheduleError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editReservationScheduleError])

  function handleVacationDateChange(value) {
    if(value != null && value != ""){
    if (!moment(value).isValid()) {
      setVacationDateError(strings.dateNotValid);
    } else {
      setVacationDateError(null);
      formikRef.current.setFieldValue("vacationDate", value);
    }
  }
  else{
    formikRef.current.setFieldValue("vacationDate", "");
  }
  }

  const FromTimeInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.enterFromTime} *`}
      name="fromTime"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  const ToTimeInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.enterToTime} *`}
      name="toTime"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  const VacationDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.selectDate} *`}
      name="vacationDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  return (
    <>
      {props.isAddNewReservationSchedule && (<Loading />)}
      {!props.isAddNewReservationSchedule &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.editReservationSchedule}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                initialValues={{
                   vacationDate: props.editedReservationScheduleInReservationSchedules != null && props.editedReservationScheduleInReservationSchedules.officialVacationDate != null ? new Date(props.editedReservationScheduleInReservationSchedules.officialVacationDate) : "",

                  fromTime: props.editedReservationScheduleInReservationSchedules != null ? new Date(props.editedReservationScheduleInReservationSchedules.fromTime) : "",

                  toTime: props.editedReservationScheduleInReservationSchedules != null ? new Date(props.editedReservationScheduleInReservationSchedules.toTime) : "",
                  
                  officialVacation: props.editedReservationScheduleInReservationSchedules != null ? props.editedReservationScheduleInReservationSchedules.isOfficialVacation : false
                }}
                validationSchema={Yup.object().shape({

                })}
                onSubmit={(fields) => {
                  let isValid = true;
                  if (fields.vacationDate == null && props.dayInReservationSchedules === null) {
                    setDayError(strings.pleaseSelectDay);
                    isValid = false;
                  }

                  if (!fields.officialVacation && (fields.fromTime == null || fields.fromTime == "")) {
                    formikRef.current.setFieldError("fromTime", strings.timeIsRequired);
                    isValid = false;
                  }

                  if (!fields.officialVacation && (fields.toTime == null || fields.toTime == "")) {
                    formikRef.current.setFieldError("toTime", strings.timeIsRequired);
                    isValid = false;
                  }

                  if (fields.officialVacation && (fields.vacationDate == null || fields.vacationDate == "")) {
                    setVacationDateError(strings.dateIsRequired);
                    isValid = false;
                  }
                  if (isValid) {
                    // let fromTime = moment(fields.fromTime).toDate();
                    // const splittedFromTimeArr = fields.fromTime.split(":");
                    // fromTime.setHours(splittedFromTimeArr[0]);
                    // fromTime.setMinutes(splittedFromTimeArr[1]);

                    // let toTime = moment(fields.toTime).toDate();
                    // const splittedToTimeArr = fields.toTime.split(":");
                    // toTime.setHours(splittedToTimeArr[0]);
                    // toTime.setMinutes(splittedToTimeArr[1]);

                    const reservationSchedule = {
                      id:props.editedReservationScheduleInReservationSchedules.id,
                      fromTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                      toTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                      isOfficialVacation: fields.officialVacation,
                      isActive: true
                    };

                    if (fields.vacationDate != null && fields.vacationDate != "" ) {
                      reservationSchedule.vacationDate = moment(fields.vacationDate).format("YYYY-MM-DD HH:mm:ss")
                    }

                    if (props.dayInReservationSchedules != null) {
                      reservationSchedule.dayId = props.dayInReservationSchedules.value
                    }
                    if (!fields.officialVacation) {
                      reservationSchedule.fromTime = moment(fields.fromTime).format("YYYY-MM-DD HH:mm:ss");
                      reservationSchedule.toTime = moment(fields.toTime).format("YYYY-MM-DD HH:mm:ss");
                    }


                    props.editReservationSchedule(reservationSchedule);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">{strings.day}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="day"
                            options={props.daysInReservationSchedules}
                            placeholder={strings.selectDay}
                            value={props.dayInReservationSchedules}
                            onChange={handleDayChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetDaysInReservationSchedules}
                            className={dayError != null ? "is-invalid" : ""}
                          />
                          {dayError && (
                            <div className="invalid-feedback block">
                              {dayError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">

                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">{strings.fromTime}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker placeholderText={strings.fromTime}
                            customInput={<FromTimeInput />}
                            locale={strings.getLanguage() === LanguagesConstant.EN ?
                              "en" : "ar"}
                            selected={values.fromTime}
                            onChange={handleFromTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className={fromTimeError !== null ? 'red-border' : ''} />
                          {
                            fromTimeError && (
                              <div className="invalid-feedback d-block">
                                {fromTimeError}
                              </div>
                            )
                          }

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">{strings.toTime}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker placeholderText={strings.toTime}
                            customInput={<ToTimeInput />}
                            locale={strings.getLanguage() === LanguagesConstant.EN ?
                              "en" : "ar"}
                            selected={values.toTime}
                            onChange={handleToTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className={toTimeError !== null ? 'red-border' : ''} />
                          {
                            toTimeError && (
                              <div className="invalid-feedback d-block">
                                {toTimeError}
                              </div>
                            )
                          }
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                            <label className="col-lg-6 col-form-label required">{strings.selectDate}</label>
                            <div className="col-lg-10 col-sm-12 fv-row">
                              <DatePicker 
                               placeholderText={strings.selectDate}
                                customInput={<VacationDateInput />}
                                locale={strings.getLanguage() === LanguagesConstant.AR ?
                                  "ar" : "en"}
                                selected={values.vacationDate}
                                onChange={handleVacationDateChange}
                                minDate={new Date(moment())}
                                isClearable
                                dateFormat="dd/MM/yyyy"
                                className={vacationDateError !== null ? 'red-border' : ''} />
                              {
                                vacationDateError && (
                                  <div className="invalid-feedback d-block">
                                    {vacationDateError}
                                  </div>
                                )
                              }
                            </div>
                          </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required"></label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <div class="form-check">
                            <input class="form-check-input" checked={values.officialVacation} onChange={() => {
                              setFieldValue("officialVacation", !values.officialVacation);
                            }} type="checkbox" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                              {strings.officialVacation}
                            </label>
                          </div>
                        </div>
                      </div>
                     
                        
                       
                      <div className="card-footer d-flex justify-content-start" style={{ backgroundColor: "white" }}>
                        <div className="col-11">
                          <button type="submit" className="btn fs-5" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                          >
                            {strings.save}
                          </button>
                        </div>

                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Edit;
