export const GET_RESERVATION_TIMES_IN_EDIT_RESERVATION = 'GET_RESERVATION_TIMES_IN_EDIT_RESERVATION';
export const GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS = 'GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS';
export const GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE = 'GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE';

export const getReservationTimesInEditReservation = (reservationTypeSettingId,selectedDate,reservationId) => ({
    type: GET_RESERVATION_TIMES_IN_EDIT_RESERVATION,
    reservationTypeSettingId,
    selectedDate,
    reservationId
});

export const getReservationTimesInEditReservationSuccess = (timeResultList,selectedDate) => ({
    type: GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS,
    payload: { timeResultList,selectedDate }
});

export const getReservationTimesInEditReservationFailure = (message) => ({
    type: GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE,
    payload: message
});