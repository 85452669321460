import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    ADD_NEW_RESERVATION,
    addNewReservationSuccess,
    addNewReservationFailure
} from '../../actions/Reservations/addNewReservationActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function addNewReservationEpic(action$) {
    return action$.pipe(
        ofType(ADD_NEW_RESERVATION),
        switchMap(action => {
            
            const ajaxConfigObj = {
                url: url + `Reservation/AddPaidReservation`,
                method: 'Post',
                headers: {
                    'Authorization': token,
                    'content-Type': 'application/json'
                },
                body: action.reservationData,
                addXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };
            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
            if (response.isSuccess) {
                return of(addNewReservationSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(addNewReservationFailure(message));
            }
        }),
        catchError(error => of(addNewReservationFailure(strings.somethingWrongHappened)))
    )
}

