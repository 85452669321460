import { combineEpics } from 'redux-observable';
//login
import { loginUserEpic } from './Account/loginUserEpic';
import { checkIsUserLoggedInEpic } from './Account/checkIsUserLoggedInEpic';
import { getUserSideBarEpic } from './Account/getUserSideBarEpic';

//pausedTime
import { updateDisplayTypeInPausedTimesEpic } from './PausedTimes/updateDisplayTypeInPausedTimesEpic';
import { getPausedTimesInPausedTimesEpic } from './PausedTimes/getPausedTimesInPausedTimesEpic';
import { addNewPausedTimeEpic } from './PausedTimes/addNewPausedTimeEpic';
import { editPausedTimeEpic } from './PausedTimes/editPausedTimeEpic';
import { getEditedPausedTimeEpic } from './PausedTimes/getEditedPausedTimeEpic';
import { deletePausedTimeEpic } from './PausedTimes/deletePausedTimeEpic';
import { updateStatusPausedTimeEpic } from './PausedTimes/updateStatusPausedTimeEpic'

//smsSettings
import { updateDisplayTypeInSmsSettingsEpic } from './SmsSettings/updateDisplayTypeInSmsSettingsEpic';
import { getSmsSettingsInSmsSettingsEpic } from './SmsSettings/getSmsSettingsInSmsSettingsEpic';
import { addNewSmsSettingEpic } from './SmsSettings/addNewSmsSettingEpic';
import { editSmsSettingEpic } from './SmsSettings/editSmsSettingEpic';
import { getEditedSmsSettingEpic } from './SmsSettings/getEditedSmsSettingEpic';
import { deleteSmsSettingEpic } from './SmsSettings/deleteSmsSettingEpic';
import { updateStatusSmsSettingEpic } from './SmsSettings/updateStatusSmsSettingEpic';
import { getSmsTypesInSmsSettingsEpic } from './SmsSettings/getSmsTypesInSmsSettingsEpic';
import { updateSmsTypesInSmsSettingsEpic } from './SmsSettings/updateSmsTypesInSmsSettingsEpic';

//notificationSettings
import { updateDisplayTypeInNotificationSettingsEpic } from './NotificationSettings/updateDisplayTypeInNotificationSettingsEpic';
import { getNotificationSettingsInNotificationSettingsEpic } from './NotificationSettings/getNotificationSettingsInNotificationSettingsEpic';
import { addNewNotificationSettingEpic } from './NotificationSettings/addNewNotificationSettingEpic';
import { editNotificationSettingEpic } from './NotificationSettings/editNotificationSettingEpic';
import { getEditedNotificationSettingEpic } from './NotificationSettings/getEditedNotificationSettingEpic';
import { deleteNotificationSettingEpic } from './NotificationSettings/deleteNotificationSettingEpic';
import { updateStatusNotificationSettingEpic } from './NotificationSettings/updateStatusNotificationSettingEpic';
import { getNotificationTypesInNotificationSettingsEpic } from './NotificationSettings/getNotificationTypesInNotificationSettingsEpic';
import { getNotificationRolesInNotificationSettingsEpic } from './NotificationSettings/getNotificationRolesInNotificationSettingsEpic';
import { updateNotificationTypesInNotificationSettingsEpic } from './NotificationSettings/updateNotificationTypesInNotificationSettingsEpic';
import { updateNotificationRolesInNotificationSettingsEpic } from './NotificationSettings/updateNotificationRolesInNotificationSettingsEpic';



//discount
import { updateDisplayTypeInDiscountsEpic } from './Discounts/updateDisplayTypeInDiscountsEpic';
import { getDiscountsInDiscountsEpic } from './Discounts/getDiscountsInDiscountsEpic';
import { addNewDiscountEpic } from './Discounts/addNewDiscountEpic';
import { editDiscountEpic } from './Discounts/editDiscountEpic';
import { getEditedDiscountEpic } from './Discounts/getEditedDiscountEpic';
import { deleteDiscountEpic } from './Discounts/deleteDiscountEpic';
import { updateStatusDiscountEpic } from './Discounts/updateStatusDiscountEpic';

//reservationSchedule
import { updateDisplayTypeInReservationSchedulesEpic } from './ReservationSchedules/updateDisplayTypeInReservationSchedulesEpic';
import { getReservationSchedulesInReservationSchedulesEpic } from './ReservationSchedules/getReservationSchedulesInReservationSchedulesEpic';
import { addNewReservationScheduleEpic } from './ReservationSchedules/addNewReservationScheduleEpic';
import { editReservationScheduleEpic } from './ReservationSchedules/editReservationScheduleEpic';
import { getEditedReservationScheduleEpic } from './ReservationSchedules/getEditedReservationScheduleEpic';
import { deleteReservationScheduleEpic } from './ReservationSchedules/deleteReservationScheduleEpic';
import { updateStatusReservationScheduleEpic } from './ReservationSchedules/updateStatusReservationScheduleEpic';
import { getDaysInReservationSchedulesEpic } from './ReservationSchedules/getDaysInReservationSchedulesEpic';
import { updateDaysInReservationSchedulesEpic } from './ReservationSchedules/updateDaysInReservationSchedulesEpic';

//user
import { updateDisplayTypeInUsersEpic } from './Users/updateDisplayTypeInUsersEpic';
import { getUsersInUsersEpic } from './Users/getUsersInUsersEpic';
import { getEditedUserEpic } from './Users/getEditedUserEpic';
import { deleteUserEpic } from './Users/deleteUserEpic';
import { updateStatusUserEpic } from './Users/updateStatusUserEpic';
import { mobileVerifiedUserEpic } from './Users/mobileVerifiedUserEpic';
import { addNewUserEpic } from './Users/addNewUserEpic';
import { editUserEpic } from './Users/editUserEpic';
import { checkEmailAvailabilityInUsersEpic } from './Users/checkEmailAvailabilityInUsersEpic';
import { checkMobileAvailabilityInUsersEpic } from './Users/checkMobileAvailabilityInUsersEpic';

//reservation
import { updateDisplayTypeInReservationsEpic } from './Reservations/updateDisplayTypeInReservationsEpic';
import { getReservationsInReservationsEpic } from './Reservations/getReservationsInReservationsEpic';
import { getEditedReservationEpic } from './Reservations/getEditedReservationEpic';
import { deleteReservationEpic } from './Reservations/deleteReservationEpic';
import { updatePaymentStatusReservationEpic } from './Reservations/updatePaymentStatusReservationEpic';
import { getReservationTypesInReservationsEpic } from './Reservations/getReservationTypesInReservationsEpic';
import { getUsersInReservationsEpic } from './Reservations/getUsersInReservationsEpic';
import { updateReservationTypesInReservationsEpic } from './Reservations/updateReservationTypesInReservationsEpic';
import { updateUsersInReservationsEpic } from './Reservations/updateUsersInReservationsEpic';
import { updateSelectedDateInReservationsEpic } from './Reservations/updateSelectedDateInReservationsEpic';
import { getReservationTimesInReservationsEpic } from './Reservations/getReservationTimesInReservationsEpic';
import { updateReservationTimesInReservationsEpic } from './Reservations/updateReservationTimesInReservationsEpic';
import { addNewReservationEpic } from './Reservations/addNewReservationEpic';
import { getReservationTimesInEditReservationEpic } from './Reservations/getReservationTimesInEditReservationEpic';
import { updateReservationTimesInEditReservationEpic } from './Reservations/updateReservationTimesInEditReservationEpic';
import { editReservationEpic } from './Reservations/editReservationEpic';
import { cancelReservationEpic } from './Reservations/cancelReservationEpic';
import { getReservationDetailsEpic } from './Reservations/getReservationDetailsEpic';
import { updateAttendenceStatusReservationEpic } from './Reservations/updateAttendenceStatusReservationEpic';
import { getAttendedReservationsInReservationsEpic } from "./Reservations/getAttendedReservationsInReservationsEpic";
import { getCancelledReservationsInReservationsEpic } from "./Reservations/getCancelledReservationsInReservationsEpic";
import { getUnpaidReservationsInReservationsEpic } from "./Reservations/getUnpaidReservationsInReservationsEpic";
import { updateDisplayTypeInAttendedReservationsEpic } from "./Reservations/updateDisplayTypeInAttendedReservationsEpic";
import { updateDisplayTypeInCancelledReservationsEpic } from "./Reservations/updateDisplayTypeInCancelledReservationsEpic";
import { updateDisplayTypeInUnpaidReservationsEpic } from "./Reservations/updateDisplayTypeInUnpaidReservationsEpic";
import { deleteCancelledReservationEpic } from './Reservations/deleteCancelledReservationEpic';
import { updateReturnMoneyStatusReservationEpic } from './Reservations/updateReturnMoneyStatusReservationEpic';
import { getRequestsInReservationsEpic } from './Reservations/getRequestsInReservationsEpic';
import { updateDisplayTypeInRequestsEpic } from './Reservations/updateDisplayTypeInRequestsEpic';
import { updateRequestStatusReservationEpic } from './Reservations/updateRequestStatusReservationEpic';
import { getTicketTypesInReservationsEpic } from './Reservations/getTicketTypesInReservationsEpic';
import { getTicketOptionsInReservationsEpic } from './Reservations/getTicketOptionsInReservationsEpic';
import { clearReservationDetailsEpic } from './Reservations/clearReservationDetailsEpic';

//notifications
import { changeNotificationIsReadEpic } from './Notifications/changeNotificationIsReadEpic';
import { getNotificationsEpic } from './Notifications/getNotificationsEpic';
import { markAllAsReadEpic } from './Notifications/markAllAsReadEpic';
import { updateNotificationsEpic } from './Notifications/updateNotificationsEpic';

//dashboard
import { getChartsDataEpic } from './Dashboard/getChartsDataEpic';

//changePassword
import { changePasswordEpic } from './Account/changePasswordEpic';

//reservation types
import { updateDisplayTypeInReservationTypesEpic } from './ReservationTypes/updateDisplayTypeInReservationTypesEpic';
import { addNewReservationTypeEpic } from './ReservationTypes/addNewReservationTypeEpic';
import { getReservationTypesInReservationTypesEpic } from './ReservationTypes/getReservationTypesInReservationTypesEpic';
import { getEditedReservationTypeEpic } from './ReservationTypes/getEditedReservationTypeEpic';
import { editReservationTypeEpic } from './ReservationTypes/editReservationTypeEpic';
import { deleteReservationTypeEpic } from './ReservationTypes/deleteReservationTypeEpic';
import { updateStatusInReservationTypesEpic } from './ReservationTypes/updateStatusInReservationTypesEpic';

//reservation type settings
import { updateDisplayTypeInReservationTypeSettingsEpic } from './ReservationTypeSettings/updateDisplayTypeInReservationTypeSettingsEpic';
import { addNewReservationTypeSettingEpic } from './ReservationTypeSettings/addNewReservationTypeSettingEpic';
import { getReservationTypeSettingsInReservationTypeSettingsEpic } from './ReservationTypeSettings/getReservationTypeSettingsInReservationTypeSettingsEpic';
import { getEditedReservationTypeSettingEpic } from './ReservationTypeSettings/getEditedReservationTypeSettingEpic';
import { editReservationTypeSettingEpic } from './ReservationTypeSettings/editReservationTypeSettingEpic';
import { deleteReservationTypeSettingEpic } from './ReservationTypeSettings/deleteReservationTypeSettingEpic';
import { getReservationTypesInReservationTypeSettingsEpic } from './ReservationTypeSettings/getReservationTypesInReservationTypeSettingsEpic';
import { updateReservationTypesInReservationTypeSettingsEpic } from './ReservationTypeSettings/updateReservationTypesInReservationTypeSettingsEpic';

//roles
import { createNewRoleEpic } from './Roles/Roles/createNewRoleEpic';
import { deleteRoleEpic } from './Roles/Roles/deleteRoleEpic';
import { editRoleEpic } from './Roles/Roles/editRoleEpic';
import { getEditedRoleEpic } from './Roles/Roles/getEditedRoleEpic';
import { getRoleEpic } from './Roles/Roles/getRoleEpic';
import { getRolesEpic } from './Roles/Roles/getRolesEpic';
import { updateDisplayTypeInRolesEpic } from './Roles/Roles/updateDisplayTypeInRolesEpic';
import { updateStatusRoleEpic } from './Roles/Roles/updateStatusRoleEpic';
import { checkRoleNameArAvailabilityInRolesEpic } from './Roles/Roles/checkRoleNameArAvailabilityInRolesEpic';
import { checkRoleNameEnAvailabilityInRolesEpic } from './Roles/Roles/checkRoleNameEnAvailabilityInRolesEpic';

//roleUsers
import { createNewRoleUserEpic } from './Roles/RoleUsers/createNewRoleUserEpic';
import { deleteRoleUserEpic } from './Roles/RoleUsers/deleteRoleUserEpic';
import { editRoleUserEpic } from './Roles/RoleUsers/editRoleUserEpic';
import { getEditedRoleUserEpic } from './Roles/RoleUsers/getEditedRoleUserEpic';
import { getRoleUsersEpic } from './Roles/RoleUsers/getRoleUsersEpic';
import { updateDisplayTypeInRoleUsersEpic } from './Roles/RoleUsers/updateDisplayTypeInRoleUsersEpic';
import { updateStatusRoleUserEpic } from './Roles/RoleUsers/updateStatusRoleUserEpic';
import { checkEmailAvailabilityInRoleUsersEpic } from './Roles/RoleUsers/checkEmailAvailabilityInRoleUsersEpic';

//roleUsers
import { createNewRoleScreenEpic } from './Roles/RoleScreens/createNewRoleScreenEpic';
import { deleteRoleScreenEpic } from './Roles/RoleScreens/deleteRoleScreenEpic';
import { editRoleScreenEpic } from './Roles/RoleScreens/editRoleScreenEpic';
import { getEditedRoleScreenEpic } from './Roles/RoleScreens/getEditedRoleScreenEpic';
import { getRoleScreensEpic } from './Roles/RoleScreens/getRoleScreensEpic';
import { getScreensInRoleScreensEpic } from './Roles/RoleScreens/getScreensInRoleScreensEpic';
import { updateDisplayTypeInRoleScreensEpic } from './Roles/RoleScreens/updateDisplayTypeInRoleScreensEpic';
import { updateScreensInRoleScreensEpic } from './Roles/RoleScreens/updateScreensInRoleScreensEpic';
import { updateStatusRoleScreenEpic } from './Roles/RoleScreens/updateStatusRoleScreenEpic';

//reports
import { updateDisplayTypeInReportsEpic } from './Reports/updateDisplayTypeInReportsEpic';
import { getReservationReportEpic } from './Reports/getReservationReportEpic';
import { getRequestReportEpic } from './Reports/getRequestReportEpic';
import { getCompanyReservationReportEpic } from './Reports/geCompanytReservationReportEpic';

//news
import { updateDisplayTypeInNewsEpic } from './News/updateDisplayTypeInNewsEpic';
import { addNewNewEpic } from './News/addNewNewEpic';
import { getNewsInNewsEpic } from './News/getNewsInNewsEpic';
import { getEditedNewEpic } from './News/getEditedNewEpic';
import { editNewEpic } from './News/editNewEpic';
import { deleteNewEpic } from './News/deleteNewEpic';
import { updateStatusNewEpic } from './News/updateStatusNewEpic';

//ticket types
import { updateDisplayTypeInTicketTypesEpic } from './TicketTypes/updateDisplayTypeInTicketTypesEpic';
import { addNewTicketTypeEpic } from './TicketTypes/addNewTicketTypeEpic';
import { getTicketTypesInTicketTypesEpic } from './TicketTypes/getTicketTypesInTicketTypesEpic';
import { getEditedTicketTypeEpic } from './TicketTypes/getEditedTicketTypeEpic';
import { editTicketTypeEpic } from './TicketTypes/editTicketTypeEpic';
import { deleteTicketTypeEpic } from './TicketTypes/deleteTicketTypeEpic';

//ticket options
import { updateDisplayTypeInTicketOptionsEpic } from './TicketOptions/updateDisplayTypeInTicketOptionsEpic';
import { addNewTicketOptionEpic } from './TicketOptions/addNewTicketOptionEpic';
import { getTicketOptionsInTicketOptionsEpic } from './TicketOptions/getTicketOptionsInTicketOptionsEpic';
import { getEditedTicketOptionEpic } from './TicketOptions/getEditedTicketOptionEpic';
import { editTicketOptionEpic } from './TicketOptions/editTicketOptionEpic';
import { deleteTicketOptionEpic } from './TicketOptions/deleteTicketOptionEpic';
import { getReservationTypesInTicketOptionsEpic } from './TicketOptions/getReservationTypesInTicketOptionsEpic';
import { updateReservationTypesInTicketOptionsEpic } from './TicketOptions/updateReservationTypesInTicketOptionsEpic';

//requests
import { getFromTimesInRequestsEpic } from './Requests/getFromTimesInRequestsEpic';
import { updateFromTimesInRequestsEpic } from './Requests/updateFromTimesInRequestsEpic';
import { getToTimesInRequestsEpic } from './Requests/getToTimesInRequestsEpic';
import { updateToTimesInRequestsEpic } from './Requests/updateToTimesInRequestsEpic';
import { approveRequestEpic } from './Requests/approveRequestEpic';
import { getEditedRequestEpic } from './Requests/getEditedRequestEpic';
import { getRequestDetailsEpic } from './Requests/getRequestDetailsEpic';
import { deleteRequestEpic } from './Requests/deleteRequestEpic';
import { payInRequestEpic } from './Requests/payInRequestEpic';
import { addNewRequestEpic } from './Requests/addNewRequestEpic';
import { editRequestEpic } from './Requests/editRequestEpic';
import { getSchoolTourRangesInRequestsEpic } from './Requests/getSchoolTourRangesInRequestsEpic';
import { getReservationTypesInRequestsEpic } from './Requests/getReservationTypesInRequestsEpic';
import { getTicketOptionsInRequestsEpic } from './Requests/getTicketOptionsInRequestsEpic';
import { getUsersInRequestsEpic } from './Requests/getUsersInRequestsEpic';
import { updateReservationTypesInRequestsEpic } from './Requests/updateReservationTypesInRequestsEpic';
//import { updateSelectedDateInRequestsEpic } from './Requests/updateSelectedDateInRequestsEpic';
import { updateUsersInRequestsEpic } from './Requests/updateUsersInRequestsEpic';

//galleries
import { updateDisplayTypeInGalleriesEpic } from './Galleries/updateDisplayTypeInGalleriesEpic';
import { addNewGalleryEpic } from './Galleries/addNewGalleryEpic';
import { getGalleriesInGalleriesEpic } from './Galleries/getGalleriesInGalleriesEpic';
import { getEditedGalleryEpic } from './Galleries/getEditedGalleryEpic';
import { editGalleryEpic } from './Galleries/editGalleryEpic';
import { deleteGalleryEpic } from './Galleries/deleteGalleryEpic';
import { updateStatusGalleryEpic } from './Galleries/updateStatusGalleryEpic';

//school tour range
import { addNewSchoolTourRangeEpic } from './SchoolTourRanges/addNewSchoolTourRangeEpic';
import { deleteSchoolTourRangeEpic } from './SchoolTourRanges/deleteSchoolTourRangeEpic';
import { editSchoolTourRangeEpic } from './SchoolTourRanges/editSchoolTourRangeEpic';
import { getEditedSchoolTourRangeEpic } from './SchoolTourRanges/getEditedSchoolTourRangeEpic';
import { getSchoolTourRangesEpic } from './SchoolTourRanges/getSchoolTourRangesEpic';
import { updateDisplayTypeInSchoolTourRangesEpic } from './SchoolTourRanges/updateDisplayTypeInSchoolTourRangesEpic';

//companies
import { addNewCompanyEpic } from './Companies/addNewCompanyEpic';
import { deleteCompanyEpic } from './Companies/deleteCompanyEpic';
import { getEditedCompanyEpic } from './Companies/getEditedCompanyEpic';
import { editCompanyEpic } from './Companies/editCompanyEpic';
import { getCompaniesInCompaniesEpic } from './Companies/getCompaniesInCompaniesEpic';
import { updateDisplayTypeInCompaniesEpic } from './Companies/updateDisplayTypeInCompaniesEpic';
import { updateStatusCompanyEpic } from './Companies/updateStatusCompanyEpic';

//company reservation
import { addNewCompanyReservationEpic } from './CompanyReservations/addNewCompanyReservationEpic';
import { deleteCompanyReservationEpic } from './CompanyReservations/deleteCompanyReservationEpic';
import { editCompanyReservationEpic } from './CompanyReservations/editCompanyReservationEpic';
import { getCompaniesInCompanyReservationsEpic } from './CompanyReservations/getCompaniesInCompanyReservationsEpic';
import { getCompanyReservationDetailsEpic } from './CompanyReservations/getCompanyReservationDetailsEpic';
import { getCompanyReservationTimesInCompanyReservationsEpic } from './CompanyReservations/getCompanyReservationTimesInCompanyReservationsEpic';
import { getCompanyReservationsInCompanyReservationsEpic } from './CompanyReservations/getCompanyReservationsInCompanyReservationsEpic';
import { getEditedCompanyReservationEpic } from './CompanyReservations/getEditedCompanyReservationEpic';
import { getReservationTypesInCompanyReservationsEpic } from './CompanyReservations/getReservationTypesInCompanyReservationsEpic';
import { getTicketOptionsInCompanyReservationsEpic } from './CompanyReservations/getTicketOptionsInCompanyReservationsEpic';
import { getTicketTypesInCompanyReservationsEpic } from './CompanyReservations/getTicketTypesInCompanyReservationsEpic';
import { updateCompaniesInCompanyReservationsEpic } from './CompanyReservations/updateCompaniesInCompanyReservationsEpic';
import { updateCompanyReservationTimesInCompanyReservationsEpic } from './CompanyReservations/updateCompanyReservationTimesInCompanyReservationsEpic';
import { updateDisplayTypeInCompanyReservationsEpic } from './CompanyReservations/updateDisplayTypeInCompanyReservationsEpic';
import { updateReservationTypesInCompanyReservationsEpic } from './CompanyReservations/updateReservationTypesInCompanyReservationsEpic';
import { updateSelectedDateInCompanyReservationsEpic } from './CompanyReservations/updateSelectedDateInCompanyReservationsEpic';
import { getToTimesInCompanyReservationsEpic } from './CompanyReservations/getToTimesInCompanyReservationsEpic';
import { updateToTimesInCompanyReservationsEpic } from './CompanyReservations/updateToTimesInCompanyReservationsEpic';
import { changeIsPaidInCompanyReservationsEpic } from './CompanyReservations/changeIsPaidInCompanyReservationsEpic';

//reservation details with code
import { getReservationDetailsWithCodeEpic } from './ReservationDetails/getReservationDetailsWithCodeEpic'

export const rootEpic = combineEpics(
    //login
    loginUserEpic,
    checkIsUserLoggedInEpic,
    getUserSideBarEpic,

    //pausedTime
    updateDisplayTypeInPausedTimesEpic,
    getPausedTimesInPausedTimesEpic,
    updateStatusPausedTimeEpic,
    deletePausedTimeEpic,
    getEditedPausedTimeEpic,
    editPausedTimeEpic,
    addNewPausedTimeEpic,

    //smsSettings
    updateDisplayTypeInSmsSettingsEpic,
    getSmsSettingsInSmsSettingsEpic,
    updateStatusSmsSettingEpic,
    deleteSmsSettingEpic,
    getEditedSmsSettingEpic,
    editSmsSettingEpic,
    addNewSmsSettingEpic,
    getSmsTypesInSmsSettingsEpic,
    updateSmsTypesInSmsSettingsEpic,

    //notificationSettings
    updateDisplayTypeInNotificationSettingsEpic,
    getNotificationSettingsInNotificationSettingsEpic,
    updateStatusNotificationSettingEpic,
    deleteNotificationSettingEpic,
    getEditedNotificationSettingEpic,
    editNotificationSettingEpic,
    addNewNotificationSettingEpic,
    getNotificationTypesInNotificationSettingsEpic,
    getNotificationRolesInNotificationSettingsEpic,
    updateNotificationTypesInNotificationSettingsEpic,
    updateNotificationRolesInNotificationSettingsEpic,


    //discount
    updateDisplayTypeInDiscountsEpic,
    getDiscountsInDiscountsEpic,
    updateStatusDiscountEpic,
    deleteDiscountEpic,
    getEditedDiscountEpic,
    editDiscountEpic,
    addNewDiscountEpic,

    //reservationSchedule
    updateDisplayTypeInReservationSchedulesEpic,
    getReservationSchedulesInReservationSchedulesEpic,
    updateStatusReservationScheduleEpic,
    deleteReservationScheduleEpic,
    getEditedReservationScheduleEpic,
    editReservationScheduleEpic,
    addNewReservationScheduleEpic,
    getDaysInReservationSchedulesEpic,
    updateDaysInReservationSchedulesEpic,

    //user
    updateDisplayTypeInUsersEpic,
    getUsersInUsersEpic,
    updateStatusUserEpic,
    mobileVerifiedUserEpic,
    deleteUserEpic,
    getEditedUserEpic,
    addNewUserEpic,
    editUserEpic,
    checkEmailAvailabilityInUsersEpic,
    checkMobileAvailabilityInUsersEpic,

    //reservation
    updateDisplayTypeInReservationsEpic,
    getReservationsInReservationsEpic,
    updatePaymentStatusReservationEpic,
    deleteReservationEpic,
    getEditedReservationEpic,
    getReservationTypesInReservationsEpic,
    getUsersInReservationsEpic,
    updateReservationTypesInReservationsEpic,
    updateUsersInReservationsEpic,
    updateSelectedDateInReservationsEpic,
    getReservationTimesInReservationsEpic,
    updateReservationTimesInReservationsEpic,
    addNewReservationEpic,
    getReservationTimesInEditReservationEpic,
    updateReservationTimesInEditReservationEpic,
    editReservationEpic,
    cancelReservationEpic,
    getReservationDetailsEpic,
    updateAttendenceStatusReservationEpic,
    getAttendedReservationsInReservationsEpic,
    getCancelledReservationsInReservationsEpic,
    getUnpaidReservationsInReservationsEpic,
    updateDisplayTypeInAttendedReservationsEpic,
    updateDisplayTypeInCancelledReservationsEpic,
    updateDisplayTypeInUnpaidReservationsEpic,
    deleteCancelledReservationEpic,
    updateReturnMoneyStatusReservationEpic,
    getRequestsInReservationsEpic,
    updateDisplayTypeInRequestsEpic,
    updateRequestStatusReservationEpic,
    getTicketTypesInReservationsEpic,
    getTicketOptionsInReservationsEpic,
    clearReservationDetailsEpic,

    //notifications
    changeNotificationIsReadEpic,
    getNotificationsEpic,
    markAllAsReadEpic,
    updateNotificationsEpic,

    //dashboard
    getChartsDataEpic,

    //changePassword
    changePasswordEpic,

    //reservation types
    updateDisplayTypeInReservationTypesEpic,
    addNewReservationTypeEpic,
    getReservationTypesInReservationTypesEpic,
    getEditedReservationTypeEpic,
    editReservationTypeEpic,
    deleteReservationTypeEpic,
    updateStatusInReservationTypesEpic,

    //reservation type settings
    updateDisplayTypeInReservationTypeSettingsEpic,
    addNewReservationTypeSettingEpic,
    getReservationTypeSettingsInReservationTypeSettingsEpic,
    getEditedReservationTypeSettingEpic,
    editReservationTypeSettingEpic,
    deleteReservationTypeSettingEpic,
    getReservationTypesInReservationTypeSettingsEpic,
    updateReservationTypesInReservationTypeSettingsEpic,

    //roles
    createNewRoleEpic,
    deleteRoleEpic,
    editRoleEpic,
    getEditedRoleEpic,
    getRoleEpic,
    getRolesEpic,
    updateDisplayTypeInRolesEpic,
    updateStatusRoleEpic,
    checkRoleNameArAvailabilityInRolesEpic,
    checkRoleNameEnAvailabilityInRolesEpic,

    //roleUsers
    createNewRoleUserEpic,
    deleteRoleUserEpic,
    editRoleUserEpic,
    getEditedRoleUserEpic,
    getRoleUsersEpic,
    updateDisplayTypeInRoleUsersEpic,
    updateStatusRoleUserEpic,
    checkEmailAvailabilityInRoleUsersEpic,

    //roleUsers
    createNewRoleScreenEpic,
    deleteRoleScreenEpic,
    editRoleScreenEpic,
    getEditedRoleScreenEpic,
    getRoleScreensEpic,
    getScreensInRoleScreensEpic,
    updateDisplayTypeInRoleScreensEpic,
    updateScreensInRoleScreensEpic,
    updateStatusRoleScreenEpic,

    //reports
    updateDisplayTypeInReportsEpic,
    getReservationReportEpic,
    getRequestReportEpic,
    getCompanyReservationReportEpic,

    //news
    updateDisplayTypeInNewsEpic,
    addNewNewEpic,
    getNewsInNewsEpic,
    getEditedNewEpic,
    editNewEpic,
    deleteNewEpic,
    updateStatusNewEpic,

    //ticket types
    updateDisplayTypeInTicketTypesEpic,
    addNewTicketTypeEpic,
    getTicketTypesInTicketTypesEpic,
    getEditedTicketTypeEpic,
    editTicketTypeEpic,
    deleteTicketTypeEpic,

    //ticket options
    updateDisplayTypeInTicketOptionsEpic,
    addNewTicketOptionEpic,
    getTicketOptionsInTicketOptionsEpic,
    getEditedTicketOptionEpic,
    editTicketOptionEpic,
    deleteTicketOptionEpic,
    getReservationTypesInTicketOptionsEpic,
    updateReservationTypesInTicketOptionsEpic,

    //requests
    getFromTimesInRequestsEpic,
    updateFromTimesInRequestsEpic,
    getToTimesInRequestsEpic,
    updateToTimesInRequestsEpic,
    approveRequestEpic,
    getEditedRequestEpic,
    getRequestDetailsEpic,
    deleteRequestEpic,
    payInRequestEpic,
    addNewRequestEpic,
    editRequestEpic,
    getSchoolTourRangesInRequestsEpic,
    getReservationTypesInRequestsEpic,
    getTicketOptionsInRequestsEpic,
    getUsersInRequestsEpic,
    updateReservationTypesInRequestsEpic,
    //updateSelectedDateInRequestsEpic,
    updateUsersInRequestsEpic,

    //galleries
    updateDisplayTypeInGalleriesEpic,
    addNewGalleryEpic,
    getGalleriesInGalleriesEpic,
    getEditedGalleryEpic,
    editGalleryEpic,
    deleteGalleryEpic,
    updateStatusGalleryEpic,

    //school tour range
    addNewSchoolTourRangeEpic,
    deleteSchoolTourRangeEpic,
    editSchoolTourRangeEpic,
    getEditedSchoolTourRangeEpic,
    getSchoolTourRangesEpic,
    updateDisplayTypeInSchoolTourRangesEpic,

    //companies
    addNewCompanyEpic,
    deleteCompanyEpic,
    getEditedCompanyEpic,
    editCompanyEpic,
    getCompaniesInCompaniesEpic,
    updateDisplayTypeInCompaniesEpic,
    updateStatusCompanyEpic,

    //company reservation
    addNewCompanyReservationEpic,
    deleteCompanyReservationEpic,
    editCompanyReservationEpic,
    getCompaniesInCompanyReservationsEpic,
    getCompanyReservationDetailsEpic,
    getCompanyReservationTimesInCompanyReservationsEpic,
    getCompanyReservationsInCompanyReservationsEpic,
    getEditedCompanyReservationEpic,
    getReservationTypesInCompanyReservationsEpic,
    getTicketOptionsInCompanyReservationsEpic,
    getTicketTypesInCompanyReservationsEpic,
    updateCompaniesInCompanyReservationsEpic,
    updateCompanyReservationTimesInCompanyReservationsEpic,
    updateDisplayTypeInCompanyReservationsEpic,
    updateReservationTypesInCompanyReservationsEpic,
    updateSelectedDateInCompanyReservationsEpic,
    getToTimesInCompanyReservationsEpic,
    updateToTimesInCompanyReservationsEpic,
    changeIsPaidInCompanyReservationsEpic,

    //reservation details with code
    getReservationDetailsWithCodeEpic
);

