export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const getRole = (roleId) => ({
    type: GET_ROLE,
    roleId
});

export const getRoleSuccess = (roleId) => ({
    type: GET_ROLE_SUCCESS,
    payload: { roleId }
});

export const getRoleFailure = (message) => ({
    type: GET_ROLE_FAILURE,
    payload: { message }
});