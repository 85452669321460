import {
    GET_RESERVATION_TIMES_IN_EDIT_RESERVATION,
    GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS,
    GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE
} from '../../actions/Reservations/getReservationTimesInEditReservationActions';

import moment from 'moment';
import "moment/locale/ar-sa";

export function getReservationTimesInEditReservationReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TIMES_IN_EDIT_RESERVATION:
            return {
                ...state,
                isGettingReservationTimesInEditReservation: true,
                getReservationTimesInEditReservationError: null,
                reservationTimesInEditReservation:[],
                reservationTimeInEditReservation:null,
            };
        case GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS:
            let indexKey = 0;
            var arr = state.editedReservationInReservations !== null ? action.payload.timeResultList.map((item, index) => {
                const reservationDate = moment(state.editedReservationInReservations.reservationDate).format("YYYY-MM-DD");
                const selectedDate = moment(action.payload.selectedDate).format("YYYY-MM-DD");
                const isSameDate = moment(reservationDate).isSame(moment(selectedDate))
                const editedReservationTime = moment(state.editedReservationInReservations.fromTime).format("HH:mm").split(":");
                const splittedStartTimeArr = item.fromTime.split(":");
                if (isSameDate && splittedStartTimeArr[0] == editedReservationTime[0] && splittedStartTimeArr[1] == editedReservationTime[1]) {
                    indexKey = index;
                }
            }) : null;
            return {
                ...state,
                isGettingReservationTimesInEditReservation: false,
                reservationTimesInEditReservation: action.payload.timeResultList,
                reservationTimeInEditReservation: state.editedReservationInReservation !== null ? action.payload.timeResultList[indexKey] : null,
                getReservationTimesInEditReservationError: null,
            };
        case GET_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE:
            return {
                ...state,
                isGettingReservationTimesInEditReservation: false,
                getReservationTimesInEditReservationError: action.payload
            };
        default:
            return state;
    }
}