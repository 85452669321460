import {
    GET_USER_SIDE_BAR,
    GET_USER_SIDE_BAR_SUCCESS,
    GET_USER_SIDE_BAR_FAILURE
} from '../../actions/Account/getUserSideBarActions';

export function getUserSideBarReducer(state, action) {
    switch (action.type) {
        case GET_USER_SIDE_BAR:
            return {
                ...state,
                isGetUserSideBar: true,
                getUserSideBarError: null,
            };
        case GET_USER_SIDE_BAR_SUCCESS:
            return {
                ...state,
                isGetUserSideBar: false,
                getUserSideBarError: null,
                userSideBar: action.payload.data,
            };
        case GET_USER_SIDE_BAR_FAILURE:
            return {
                ...state,
                isGetUserSideBar: false,
                getUserSideBarError: action.payload.message,
            };
        default:
            return state;
}}