import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import { DisplayTypesInRequestsConstant } from "../../constants/Reservations/DisplayTypesInRequestsConstant";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loading from '../Shared/Loading/Loading';
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";
import { RequestStatusesConstant } from "../../constants/RequestStatusesConstant";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);
function Approve(props) {
  const formikRef = React.useRef(null);
  const [fromTimeError, setFromTimeError] = useState(null);
  const [toTimeError, setToTimeError] = useState(null);
  const [dateError, setDateError] = useState(null);

  useEffect(() => {
    props.getFromTimesInRequests(moment(props.editedRequestInRequests.reservationDate).format("YYYY-MM-DD HH:mm:ss"));
    if(props.editedRequestInRequests.requestStatusId == RequestStatusesConstant.APPROVED){
      props.getToTimesInRequests(moment(props.editedRequestInRequests.fromTime).format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [])

  useEffect(() => {
    if (props.approveRequestResult !== null && props.approveRequestResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.approveRequestResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.LIST)
      }
      )
    }
  }, [props.approveRequestResult])

  useEffect(() => {
    if (props.approveRequestError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.approveRequestError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.approveRequestError])

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.enterDate} *`}
      name="date"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  function handleDateChange(value) {
    if (!moment(value).isValid()) {
      setDateError(strings.dateNotValid);
    } else {
      setDateError(null);
      formikRef.current.setFieldValue("reservationDate", value);
      props.getFromTimesInRequests(moment(value).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  function handleFromTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setFromTimeError(strings.pleaseSelectFromTime)
    } else {
      setFromTimeError(null);
      let startDate = moment(formikRef.current.values.reservationDate).toDate();
      const splittedStartTimeArr = selectedOption.fromTime.split(":");
      startDate.setHours(splittedStartTimeArr[0]);
      startDate.setMinutes(splittedStartTimeArr[1]);
      props.getToTimesInRequests(moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    props.updateFromTimesInRequests(selectedOption);
  }

  function handleToTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setToTimeError(strings.pleaseSelectToTime)
    } else {
      setToTimeError(null);
    }
    props.updateToTimesInRequests(selectedOption);
  }

  return (

    <>
      {props.isApprovingRequest && (<Loading />)}

      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.edit}
              </span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              innerRef={formikRef}
              initialValues={{
                reservationDate: props.editedRequestInRequests != null ? new Date(props.editedRequestInRequests.reservationDate) : new Date(),
              }}
              validationSchema={Yup.object().shape({

              })}
              onSubmit={(fields) => {
                if (props.fromTimeInRequests == null) {
                  setFromTimeError(strings.pleaseSelectFromTime);
                } else if (props.toTimeInRequests == null) {
                  setToTimeError(strings.pleaseSelectToTime);
                } else {
                  let startDate = moment(fields.reservationDate).toDate();
                  const splittedStartTimeArr = props.fromTimeInRequests.fromTime.split(":");
                  startDate.setHours(splittedStartTimeArr[0]);
                  startDate.setMinutes(splittedStartTimeArr[1]);

                  let endDate = moment(fields.reservationDate).toDate();
                  const splittedEndTimeArr = props.toTimeInRequests.toTime.split(":");
                  endDate.setHours(splittedEndTimeArr[0]);
                  endDate.setMinutes(splittedEndTimeArr[1]);

                  var approveRequestViewModel = {
                    requestId: props.editedRequestInRequests.id,
                    reservationScheduleId:props.fromTimeInRequests.reservationScheduleId,
                    reservationDate: moment(fields.reservationDate).format("YYYY-MM-DD HH:mm:ss"),
                    fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                    toTime: moment(endDate).format("YYYY-MM-DD HH:mm:ss")
                  };

                  props.approveRequest(approveRequestViewModel);
                }
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                setFieldError,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="row">
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.date}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker placeholderText={strings.date}
                            customInput={<StartDateInput />}
                            locale={strings.getLanguage() === LanguagesConstant.AR ?
                              "ar" : "en"}
                            selected={values.reservationDate}
                            onChange={handleDateChange}
                            minDate={new Date(moment())}
                            dateFormat="dd/MM/yyyy"
                            className={dateError !== null ? 'red-border' : ''} />
                          {
                            dateError && (
                              <div className="invalid-feedback block">
                                {dateError}
                              </div>
                            )
                          }
                          {
                            dateError == null
                            && moment(values.reservationDate).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
                            && props.getFromTimesInRequestsError != null && (
                              <div className="invalid-feedback d-block">
                                {props.getFromTimesInRequestsError}
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">{strings.fromTime}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Select
                          name="fromTime"
                          options={props.fromTimesInRequests}
                          placeholder={strings.selectFromTime}
                          value={props.fromTimeInRequests}
                          onChange={handleFromTimeChanges}
                          isSearchable
                          isClearable
                          isLoading={props.isGettingFromTimesInRequests}
                          className={fromTimeError != null ? "is-invalid" : ""}
                        />
                        {
                          fromTimeError && (
                            <div className="invalid-feedback d-block">
                              {fromTimeError}
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">{strings.toTime}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Select
                          name="toTime"
                          options={props.toTimesInRequests}
                          placeholder={strings.selectToTime}
                          value={props.toTimeInRequests}
                          onChange={handleToTimeChanges}
                          isSearchable
                          isClearable
                          isLoading={props.isGettingToTimesInRequests}
                          className={toTimeError != null ? "is-invalid" : ""}
                        />
                        {
                          toTimeError && (
                            <div className="invalid-feedback d-block">
                              {toTimeError}
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                      <div className="col-11">
                        <button type="submit" className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                        >
                          {strings.save}
                        </button>
                      </div>

                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>

    </>
  );
}
export default Approve;