import {
    UPDATE_DISPLAY_TYPE_IN_REPORTS,
    UPDATE_DISPLAY_TYPE_IN_REPORTS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_REPORTS_FAILURE
} from '../../../src/actions/Reports/updateDisplayTypeInReportsActions';

import { DisplayTypesInReportsConstant } from '../../constants/Reports/DisplayTypesInReportsConstant';

export function updateDisplayTypeInReportsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_REPORTS:
            return {
                ...state,
                isUpdatingDisplayTypeInReports: true,
                updateDisplayTypeErrorInReports: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_REPORTS_SUCCESS:
            if (action.payload.displayType == DisplayTypesInReportsConstant.TABS) {
                return {
                    ...state,
                    isUpdatingDisplayTypeInReports: false,
                    displayTypeInReports: action.payload.displayType,
                    updateDisplayTypeErrorInReports: null,

                    isGetReservationReport: false,
                    getReservationReportError: null,
                    reservationReport: [],
                    reservationReportPageNumber: 1,
                    reservationReportPageSize: 10,
                    reservationReportTotalCount: null,
                    reportDataFilter: null,

                    isGetRequestReport: false,
                    getRequestReportError: null,
                    requestReport:[],
                    requestReportPageNumber: 1,
                    requestReportPageSize:10,
                    requestReportTotalCount:null,
                    reportDataFilter:null

                };
            } else {
                return {
                    ...state,
                    isUpdatingDisplayTypeInReports: false,
                    displayTypeInReports: action.payload.displayType,
                    updateDisplayTypeErrorInReports: null,

                };
            }
        case UPDATE_DISPLAY_TYPE_IN_REPORTS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInReports: false,
                updateDisplayTypeErrorInReports: action.payload
            };
        default:
            return state;
    }
}