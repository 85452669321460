export const UPDATE_DISPLAY_TYPE_IN_ROLES = 'UPDATE_DISPLAY_TYPE_IN_ROLES';
export const UPDATE_DISPLAY_TYPE_IN_ROLES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_ROLES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_ROLES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_ROLES_FAILURE';

export const updateDisplayTypeInRoles = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLES,
    displayType
});

export const updateDisplayTypeInRolesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInRolesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLES_FAILURE,
    payload: message
});