import React,{useState,useEffect} from "react";
import { DisplayTypesInDiscountsConstant } from "../../constants/Discounts/DisplayTypesInDiscountsConstant";
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
import Discount from './Discount';
import Add from './Add';
import Edit from './Edit';
function Discounts(props){

    return (
        <>

<div className="content d-flex flex-column flex-column-fluid pt-0">
    <Toolbar
        pagename={
            props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.LIST ? strings.discounts:
            props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.ADD ? strings.create:
            props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.EDIT ? strings.edit:""

        }
        parentpage={strings.Items}
        Listbtns={(
            <>

                {
                props.displayTypeInDiscounts  !== DisplayTypesInDiscountsConstant.LIST
               // && props.userRoles !== [] &&
                //props.userRoles.length > 0 && 
                //props.userRoles.includes("Show") 
                ?
                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInDiscounts(DisplayTypesInDiscountsConstant.LIST)}>
                        {strings.discounts}
                    </Button> :null}

                {props.displayTypeInDiscounts  !== DisplayTypesInDiscountsConstant.ADD 
                //&&
                // props.userRoles !== [] &&
                // props.userRoles.length > 0 && 
               //  props.userRoles.includes("Add")
               ?
                    <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInDiscounts(DisplayTypesInDiscountsConstant.ADD)}}>
                        {strings.new}
                    </Button> :null}

              
            </>
        )} />

    <div className="post d-flex flex-column-fluid" id="kt_post" >
        <div id="kt_content_container" className="kt_content_container container-xxl">
            <>
                {props.displayTypeInDiscounts  === DisplayTypesInDiscountsConstant.LIST && 
                // props.userRoles !== [] &&
               //  props.userRoles.length > 0 && 
               //  props.userRoles.includes("Show")&&
                 (<Discount {...props} />)}
                {props.displayTypeInDiscounts  === DisplayTypesInDiscountsConstant.ADD &&
                // props.userRoles !== [] &&
               //  props.userRoles.length > 0 && 
               //  props.userRoles.includes("Add")&&
                  (<Add {...props} />)}
                {props.displayTypeInDiscounts  === DisplayTypesInDiscountsConstant.EDIT &&
                // props.userRoles !== [] &&
                 //props.userRoles.length > 0 && 
                 //props.userRoles.includes("Edit")&&
                  (<Edit {...props} />)}
                
            </>

        </div>
    </div>
            </div>
                            {/* {props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.LIST && (<div className="gridWidth">
                               <Discount {...props} /> </div>)}
                            {props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInDiscounts === DisplayTypesInDiscountsConstant.EDIT && (<Edit {...props}/>)} */}
                     

        </>
    );
}
export default Discounts