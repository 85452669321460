export const GET_EDITED_USER = 'GET_EDITED_USER';
export const GET_EDITED_USER_SUCCESS = 'GET_EDITED_USER_SUCCESS';
export const GET_EDITED_USER_FAILURE = 'GET_EDITED_USER_FAILURE';

export const getEditedUser = (userId) => ({
    type: GET_EDITED_USER,
    userId
});

export const getEditedUserSuccess = (userId) => ({
    type: GET_EDITED_USER_SUCCESS,
    payload: { userId }
});

export const getEditedUserFailure = (message) => ({
    type: GET_EDITED_USER_FAILURE,
    payload: { message }
});
