export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAILURE = 'UPDATE_NOTIFICATIONS_FAILURE';

export const updateNotifications = (notificationData) => ({
    type: UPDATE_NOTIFICATIONS,
    notificationData
});

export const updateNotificationsSuccess = (data) => ({
    type: UPDATE_NOTIFICATIONS_SUCCESS,
    payload: { data }
});

export const updateNotificationsFailure = (message) => ({
    type: UPDATE_NOTIFICATIONS_FAILURE,
    payload: { message }
});