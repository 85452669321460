import {
    EDIT_RESERVATION_TYPE_SETTING,
    EDIT_RESERVATION_TYPE_SETTING_SUCCESS,
    EDIT_RESERVATION_TYPE_SETTING_FAILURE
} from '../../actions/ReservationTypeSettings/editReservationTypeSettingActions';

import { DisplayTypesInReservationTypeSettingsConstant } from '../../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant';

export function editReservationTypeSettingReducer(state, action) {
    switch (action.type) {
        case EDIT_RESERVATION_TYPE_SETTING:
            return {
                ...state,
                isEditingReservationTypeSetting: true,
                editReservationTypeSettingError: null,
            };
        case EDIT_RESERVATION_TYPE_SETTING_SUCCESS:
            return {
                ...state,
                isEditingReservationTypeSetting: false,
                editReservationTypeSettingError: null,
                editReservationTypeSettingResult: action.payload.data,
               // displayTypeInReservationTypeSettings: DisplayTypesInReservationTypeSettingsConstant.LIST,
            };
        case EDIT_RESERVATION_TYPE_SETTING_FAILURE:
            return {
                ...state,
                isEditingReservationTypeSetting: false,
                editReservationTypeSettingError: action.payload.message,
            };
        default:
            return state;
}}