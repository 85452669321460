import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_SELECTED_DATE_IN_RESERVATIONS,
    updateSelectedDateInReservationsSuccess,
    updateSelectedDateInReservationsFailure
} from '../../actions/Reservations/updateSelectedDateInReservationsActions';

import {
    getReservationTimesInReservations
} from '../../actions/Reservations/getReservationTimesInReservationsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function updateSelectedDateInReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_SELECTED_DATE_IN_RESERVATIONS),
        map(action => ({
            reservationTypeSettingId: action.reservationTypeSettingId,
            selectedDate: action.selectedDate
        })),
        mergeMap(response => {
            return of(updateSelectedDateInReservationsSuccess(response.selectedDate), getReservationTimesInReservations(response.reservationTypeSettingId, response.selectedDate))
        }),
        catchError(error => of(updateSelectedDateInReservationsFailure
            (strings.somethingWrongHappened)))
    )
}