import {
    GET_RESERVATION_TIMES_IN_RESERVATIONS,
    GET_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS,
    GET_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getReservationTimesInReservationsActions';

export function getReservationTimesInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TIMES_IN_RESERVATIONS:
            return {
                ...state,
                isGettingReservationTimesInReservations: true,
                getReservationTimesInReservationsError: null,
                reservationTimesInReservations:[],
                reservationTimeInReservations:null
            };
        case GET_RESERVATION_TIMES_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGettingReservationTimesInReservations: false,
                reservationTimesInReservations: action.payload.timeResultList,
                getReservationTimesInReservationsError: null,
                selectedDateInReservations: action.payload.selectedDate,
            };
        case GET_RESERVATION_TIMES_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGettingReservationTimesInReservations: false,
                getReservationTimesInReservationsError: action.payload
            };
        default:
            return state;
    }
}