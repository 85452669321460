import {
    GET_REQUEST_REPORT,
    GET_REQUEST_REPORT_SUCCESS,
    GET_REQUEST_REPORT_FAILURE
} from '../../actions/Reports/getRequestReportActions';

export function getRequestReportReducer(state, action) {
    switch (action.type) {
        case GET_REQUEST_REPORT:
            return {
                ...state,
                isGetRequestReport: true,
                getRequestReportError: null,
                requestReport: [],
            };
        case GET_REQUEST_REPORT_SUCCESS:
            return {
                ...state,
                isGetRequestReport: false,
                getRequestReportError: null,
                requestReport: action.payload.requestReport.data,
                requestReportPageNumber: action.payload.reportData.pageNumber,
                requestReportPageSize: action.payload.reportData.pageSize,
                requestReportTotalCount: action.payload.requestReport.total,
                reportDataFilter:action.payload.reportData
            };
        case GET_REQUEST_REPORT_FAILURE:
            return {
                ...state,
                isGetRequestReport: false,
                getRequestReportError: action.payload.message,
            };
        default:
            return state;
}}