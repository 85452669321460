export const MOBILE_VERIFIED_USER = 'MOBILE_VERIFIED_USER';
export const MOBILE_VERIFIED_USER_SUCCESS = 'MOBILE_VERIFIED_USER_SUCCESS';
export const MOBILE_VERIFIED_USER_FAILURE = 'MOBILE_VERIFIED_USER_FAILURE';

export const mobileVerifiedUser = (userId) => ({
    type: MOBILE_VERIFIED_USER,
    userId
});

export const mobileVerifiedUserSuccess = (data) => ({
    type: MOBILE_VERIFIED_USER_SUCCESS,
    payload: { data }
});

export const mobileVerifiedUserFailure = (message) => ({
    type: MOBILE_VERIFIED_USER_FAILURE,
    payload: { message }
});