import {
    GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS,
    GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getCompanyReservationsInCompanyReservationsActions';

export function getCompanyReservationsInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGetCompanyReservationsInCompanyReservations: true,
                getCompanyReservationsInCompanyReservationsError: null,
                companyReservationsInCompanyReservations: [],
            };
        case GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetCompanyReservationsInCompanyReservations: false,
                getCompanyReservationsInCompanyReservationsError: null,
                companyReservationsInCompanyReservations: action.payload.companyReservations.data,
                companyReservationsPageNumberInCompanyReservations: action.payload.pageNumber,
                companyReservationsPageSizeInCompanyReservations: action.payload.pageSize,
                companyReservationsTotalCountInCompanyReservations: action.payload.companyReservations.total,
            };
        case GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetCompanyReservationsInCompanyReservations: false,
                getCompanyReservationsInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
}}