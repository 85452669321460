import {
    UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS,
    UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_FAILURE
} from '../../../src/actions/TicketOptions/updateDisplayTypeInTicketOptionsActions';

export function updateDisplayTypeInTicketOptionsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInTicketOptions: true,
                updateDisplayTypeErrorInTicketOptions: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInTicketOptions: false,
                displayTypeInTicketOptions: action.payload.displayType,
                updateDisplayTypeErrorInTicketOptions: null,

                isEditingTicketOption: false,
                editTicketOptionError: null,
                editTicketOptionResult:null,

                isDeletingTicketOption: false,
                deleteTicketOptionError: null,
                deleteTicketOptionResult:null,

                isAddNewTicketOption: false,
                addNewTicketOptionError: null,
                addNewTicketOptionResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInTicketOptions: false,
                updateDisplayTypeErrorInTicketOptions: action.payload
            };
        default:
            return state;
    }
}