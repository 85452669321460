import {
    GET_EDITED_ROLE_SCREEN,
    GET_EDITED_ROLE_SCREEN_SUCCESS,
    GET_EDITED_ROLE_SCREEN_FAILURE
} from '../../../actions/Roles/RoleScreens/getEditedRoleScreenActions';

export function getEditedRoleScreenReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_ROLE_SCREEN:
            return {
                ...state,
                isGetEditedRoleScreen: true,
                getEditedRoleScreenError: null,
            };
        case GET_EDITED_ROLE_SCREEN_SUCCESS:
            return {
                ...state,
                isGetEditedRoleScreen: false,
                getEditedRoleScreenError: null,
                editedRoleScreenInRoleScreens: state.roleScreensInRoleScreens.filter(x=> x.id === action.payload.roleScreenId)[0],
            };
        case GET_EDITED_ROLE_SCREEN_FAILURE:
            return {
                ...state,
                isGetEditedRoleScreen: false,
                getEditedRoleScreenError: action.payload.message,
            };
        default:
            return state;
}}