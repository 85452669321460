export const GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS = 'GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS';
export const GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_FAILURE';

export const getCompanyReservationsInCompanyReservations = (companyId=null,pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS,
    companyId,
    pageNumber,
    pageSize,
    search
});

export const getCompanyReservationsInCompanyReservationsSuccess = (companyReservations, pageNumber, pageSize) => ({
    type: GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { companyReservations, pageNumber, pageSize }
});

export const getCompanyReservationsInCompanyReservationsFailure = (message) => ({
    type: GET_COMPANY_RESERVATIONS_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});