export const GET_RESERVATION_TYPES_IN_RESERVATIONS = 'GET_RESERVATION_TYPES_IN_RESERVATIONS';
export const GET_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS = 'GET_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS';
export const GET_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE = 'GET_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE';

export const getReservationTypesInReservations = () => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATIONS,
}); 

export const getReservationTypesInReservationsSuccess = (reservationTypes) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS,
    payload: { reservationTypes }
});

export const getReservationTypesInReservationsFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE,
    payload: { message }
});