export const EDIT_SMS_SETTING = 'EDIT_SMS_SETTING';
export const EDIT_SMS_SETTING_SUCCESS = 'EDIT_SMS_SETTING_SUCCESS';
export const EDIT_SMS_SETTING_FAILURE = 'EDIT_SMS_SETTING_FAILURE';

export const editSmsSetting = (smsSettingData) => ({
    type: EDIT_SMS_SETTING,
    smsSettingData
});

export const editSmsSettingSuccess = (data) => ({
    type: EDIT_SMS_SETTING_SUCCESS,
    payload: { data }
});

export const editSmsSettingFailure = (message) => ({
    type: EDIT_SMS_SETTING_FAILURE,
    payload: { message }
});