const TableStat = {
    Length:[
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 25, label: '25' },
    ],
    Export:[
        { value: 'Print', label: ' Print' },
        { value: 'Excel', label: 'Excel' },
        { value: 'Pdf', label: 'Pdf' },
    ]
};

export default TableStat;