import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATIONS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateDisplayTypeInReservationsActions';

export function updateDisplayTypeInReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_RESERVATIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInReservations: true,
                updateDisplayTypeErrorInReservations: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInReservations: false,
                displayTypeInReservations: action.payload.displayType,
                updateDisplayTypeErrorInReservations: null,

                isAddNewReservation: false,
                addNewReservationError: null,
                addNewReservationResult: null,

                isCancellingReservation: false,
                cancelReservationResult: null,
                cancelReservationError: null,

                isDeletingReservation: false,
                deleteReservationError: null,
                deleteReservationResult: null,

                isEditingReservation: false,
                editReservationResult: null,
                editReservationError: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInReservations: false,
                updateDisplayTypeErrorInReservations: action.payload
            };
        default:
            return state;
    }
}