export const UPDATE_TO_TIMES_IN_REQUESTS = 'UPDATE_TO_TIMES_IN_REQUESTS';
export const UPDATE_TO_TIMES_IN_REQUESTS_SUCCESS = 'UPDATE_TO_TIMES_IN_REQUESTS_SUCCESS';
export const UPDATE_TO_TIMES_IN_REQUESTS_FAILURE = 'UPDATE_TO_TIMES_IN_REQUESTS_FAILURE';

export const updateToTimesInRequests = (selectedTime) => ({
    type: UPDATE_TO_TIMES_IN_REQUESTS,
    selectedTime
});

export const updateToTimesInRequestsSuccess = (selectedTime) => ({
    type: UPDATE_TO_TIMES_IN_REQUESTS_SUCCESS,
    payload: { selectedTime }
});

export const updateToTimesInRequestsFailure = (message) => ({
    type: UPDATE_TO_TIMES_IN_REQUESTS_FAILURE,
    payload: message
});