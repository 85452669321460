export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_FAILURE';

export const updateDisplayTypeInReservationTypes = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES,
    displayType
});

}
export const updateDisplayTypeInReservationTypesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInReservationTypesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPES_FAILURE,
    payload: message
});