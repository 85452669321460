import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES,
    checkRoleNameArAvailabilityInRolesSuccess,
    checkRoleNameArAvailabilityInRolesFailure
} from '../../../actions/Roles/Roles/checkRoleNameArAvailabilityInRolesActions';

import { url, token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";


export function checkRoleNameArAvailabilityInRolesEpic(action$) {
    return action$.pipe(
        ofType(CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES),
        switchMap(action => {
           
            const queryString = action.roleId !== null ?
                `?roleNameAr=${action.nameAr}&roleId=${action.roleId}` :
                `?roleNameAr=${action.nameAr}`;
            const ajaxConfigObj = {
                url: url + `Role/CheckRoleNameArAvailability${queryString}`,
                method: 'POST',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        map(response => {
           
            if (response.isSuccess) {
                return checkRoleNameArAvailabilityInRolesSuccess(response.data);

            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return checkRoleNameArAvailabilityInRolesFailure(message);
            }
        }),
        catchError(error => of(checkRoleNameArAvailabilityInRolesFailure
            (strings.somethingWrongHappened)))
    )
}

