import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_RESERVATIONS,
    updateDisplayTypeInReservationsSuccess,
    updateDisplayTypeInReservationsFailure
} from '../../actions/Reservations/updateDisplayTypeInReservationsActions';
import strings from '../../localizations/strings';

export function updateDisplayTypeInReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_RESERVATIONS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInReservationsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInReservationsFailure(strings.somethingWrongHappened)))
    )
}

