import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS,
    updateDisplayTypeInRoleScreensSuccess,
    updateDisplayTypeInRoleScreensFailure
} from '../../../actions/Roles/RoleScreens/updateDisplayTypeInRoleScreensActions';

import strings from '../../../localizations/strings';

export function updateDisplayTypeInRoleScreensEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>
            updateDisplayTypeInRoleScreensSuccess(response.displayType)
        ),
        catchError(error => of(updateDisplayTypeInRoleScreensFailure(strings.somethingWrongHappened)))
    )
}

