export const DELETE_NEW = 'DELETE_NEW';
export const DELETE_NEW_SUCCESS = 'DELETE_NEW_SUCCESS';
export const DELETE_NEW_FAILURE = 'DELETE_NEW_FAILURE';

export const deleteNew = (newId) => ({
    type: DELETE_NEW,
    newId
});

export const deleteNewSuccess = (data) => ({
    type: DELETE_NEW_SUCCESS,
    payload: { data }
});

export const deleteNewFailure = (message) => ({
    type: DELETE_NEW_FAILURE,
    payload: { message }
});