import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import strings from '../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DisplayTypesInUsersConstant } from "../../constants/Users/DisplayTypesInUsersConstant";
import Loading from "../Shared/Loading/Loading";
import PhoneInput, {
  parsePhoneNumber,
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import 'react-phone-number-input/style.css'

function Add(props) {
  const formikRef = useRef(null);

  useEffect(() => {
    if (props.addNewUserResult !== null && props.addNewUserResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewUserResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInUsers(DisplayTypesInUsersConstant.LIST)
      }
      )
    }
  }, [props.addNewUserResult])

  useEffect(() => {
    if (props.addNewUserError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.addNewUserError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.addNewUserError])

  function checkEmailAvailabilityInUsers(value) {
    if (value !== undefined && value !== "") {
      props.checkEmailAvailabilityInUsers(value);
    }
  }

  function checkMobileAvailabilityInUsers(value) {
    if (value !== undefined && value !== "") {
      props.checkMobileAvailabilityInUsers(value);
    }
  }

  const handlePhoneNumberChanges = (value) => {
    formikRef.current.setFieldValue("phoneNumber", value);
  };

  return (
    <>
      {props.isAddNewUser && (<Loading />)}
      {!props.isAddNewUser &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.addUser}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  arabicName: '',
                  englishName: '',
                  phoneNumber: '',
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  arabicName: Yup.string()
                    .required(strings.nameArRequired),
                  englishName: Yup.string()
                    .required(strings.nameEnRequired),
                  phoneNumber: Yup.string()
                    .required(strings.mobileRequierd)
                    .test(
                      'is-phoneNumber',
                      strings.inValidMobileNumber,
                      (value) => {
                        if (!value) {
                          return true;
                        }
                        return isPossiblePhoneNumber(value, "KW");
                      }),
                  email: Yup.string()
                    .email(strings.emailIsInvalid),
                  password: Yup.string()
                    .required(strings.passwordRequired)
                })}
                onSubmit={fields => {
                  if (props.isEmailAvailableInUsers && props.isMobileAvailableInUsers) {
                    var user = {
                      userNameEn: fields.englishName,
                      userNameAr: fields.arabicName,
                      phoneNumber:fields.phoneNumber,
                      password: fields.password,
                      isActive: true,
                    }

                    if(fields.email != null && fields.email != ''){
                      user.email=fields.email;
                    }

                    props.addNewUser(user);
                  }
                }}
                render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.arabicName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="arabicName"
                            className={'form-control' + (errors.arabicName && touched.arabicName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameAr} *`}
                          />
                          <ErrorMessage name="arabicName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.englishName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="englishName"
                            className={'form-control' + (errors.englishName && touched.englishName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameEn} *`}
                          />
                          <ErrorMessage name="englishName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.mobile}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <PhoneInput
                            className={
                              "form-control border-0" +
                              (errors.phoneNumber && touched.phoneNumber
                                ? " is-invalid"
                                : "")
                            }
                            international={false}
                            defaultCountry="KW"
                            countries={["KW"]}
                            addInternationalOption={false}
                            countryCallingCodeEditable={false}
                            value={values.phoneNumber}
                            limitMaxLength
                            onChange={handlePhoneNumberChanges}
                            placeholder={`${strings.enterMobile} *`}
                            onFocus={(e) => setFieldTouched("phoneNumber", false)}
                            onBlur={(e) => {
                              setFieldTouched("phoneNumber", true);
                              checkMobileAvailabilityInUsers(values.phoneNumber);
                            }}
                          //}}
                          />
                          {
                            errors.phoneNumber && touched.phoneNumber
                            && <ErrorMessage name="phoneNumber" component="div"
                              className="invalid-feedback" />
                          }
                          {
                            !errors.phoneNumber
                            && !props.isMobileAvailableInUsers
                            && (
                              <div className="invalid-feedback d-inline-block">
                                {strings.mobileEnteredBefore}
                              </div>
                            )
                          }


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.email}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="email"
                            className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                            placeholder={`${strings.enterEmail} *`}
                            onChange={e => {
                              handleChange(e);
                              setFieldTouched('email');
                            }}
                            onBlur={e => {
                              handleBlur(e);
                              checkEmailAvailabilityInUsers(e.target.value);
                            }}
                          />
                          {
                            errors.email && touched.email
                            && <ErrorMessage name="email" component="div"
                              className="invalid-feedback" />
                          }
                          {
                            !errors.email
                            && !props.isEmailAvailableInUsers
                            && (
                              <div className="invalid-feedback d-inline-block">
                                {strings.emailEnteredBefore}
                              </div>
                            )
                          }


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.password}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="password"
                            name="password"
                            className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                            placeholder={`${strings.enterPassword} *`}
                          />
                          <ErrorMessage name="password" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-between p-3">
                      <div>
                        <button
                          type="submit"
                          className="btn fs-6"
                          style={{
                            backgroundColor: "#009ef7",
                            borderColor: "#009ef7",
                            color: "white",
                            borderRadius: "0.75rem",
                          }}
                          id="kt_account_profile_details_submit"
                        //	onClick={done}
                        >
                          {strings.save}
                        </button>
                      </div>
                    </div>
                  </Form>

                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Add;
