import {
    GET_EDITED_PAUSED_TIME,
    GET_EDITED_PAUSED_TIME_SUCCESS,
    GET_EDITED_PAUSED_TIME_FAILURE
} from '../../actions/PausedTimes/getEditedPausedTimeActions';

export function getEditedPausedTimeReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_PAUSED_TIME:
            return {
                ...state,
                isGetEditedPausedTime: true,
                getEditedPausedTimeError: null, 
            };
        case GET_EDITED_PAUSED_TIME_SUCCESS:
            return {
                ...state,
                isGetEditedPausedTime: false,
                getEditedPausedTimeError: null,
                editedPausedTimeInPausedTimes:state.pausedTimesInPausedTimes.filter(x=> x.id == action.payload.pausedTimeId)[0],
            };
        case GET_EDITED_PAUSED_TIME_FAILURE:
            return {
                ...state,
                isGetEditedPausedTime: false,
                getEditedPausedTimeError: action.payload.message,
            };
        default:
            return state;
}}