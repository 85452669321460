import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_RESERVATION_TIMES_IN_RESERVATIONS,
    updateReservationTimesInReservationsSuccess,
    updateReservationTimesInReservationsFailure
} from '../../actions/Reservations/updateReservationTimesInReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function updateReservationTimesInReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_RESERVATION_TIMES_IN_RESERVATIONS),
        map(action => ({
            selectedTime: action.selectedTime
        })),
        map(response =>
            updateReservationTimesInReservationsSuccess(response.selectedTime)
        ),
        catchError(error => of(updateReservationTimesInReservationsFailure
            (strings.somethingWrongHappened)))
    )
}