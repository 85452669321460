import {
    EDIT_NEW,
    EDIT_NEW_SUCCESS,
    EDIT_NEW_FAILURE
} from '../../actions/News/editNewActions';

import { DisplayTypesInNewsConstant } from '../../constants/News/DisplayTypesInNewsConstant';

export function editNewReducer(state, action) {
    switch (action.type) {
        case EDIT_NEW:
            return {
                ...state,
                isEditingNew: true,
                editNewError: null,
            };
        case EDIT_NEW_SUCCESS:
            return {
                ...state,
                isEditingNew: false,
                editNewError: null,
                editNewResult: action.payload.data,
               // displayTypeInNews: DisplayTypesInNewsConstant.LIST,
            };
        case EDIT_NEW_FAILURE:
            return {
                ...state,
                isEditingNew: false,
                editNewError: action.payload.message,
            };
        default:
            return state;
}}