export const UPDATE_RESERVATION_TYPES_IN_REQUESTS = 'UPDATE_RESERVATION_TYPES_IN_REQUESTS';
export const UPDATE_RESERVATION_TYPES_IN_REQUESTS_SUCCESS = 'UPDATE_RESERVATION_TYPES_IN_REQUESTS_SUCCESS';
export const UPDATE_RESERVATION_TYPES_IN_REQUESTS_FAILURE = 'UPDATE_RESERVATION_TYPES_IN_REQUESTS_FAILURE';

export const updateReservationTypesInRequests = (reservationType,selectedDate) => ({
    type: UPDATE_RESERVATION_TYPES_IN_REQUESTS,
    reservationType,
    selectedDate
}); 

export const updateReservationTypesInRequestsSuccess = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_REQUESTS_SUCCESS,
    payload: { reservationType }
});

export const updateReservationTypesInRequestsFailure = (message) => ({
    type: UPDATE_RESERVATION_TYPES_IN_REQUESTS_FAILURE,
    payload: { message }
});