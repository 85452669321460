import React from 'react';

import { Line } from 'react-chartjs-2';
import strings from '../../../localizations/strings';

class LineChart extends React.Component {
 
    
    render() {
        
        return (
            <>
            <Line ref="chart" data={
              {
                labels: this.props.labels,
                datasets: [
                  {
                    
                    label: strings.reservations,
                    fill: true,
                    
                    backgroundColor: 'rgba(232,255,243,0.5)',
                    borderColor: 'rgb(80,205,137)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgb(80,205,137)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(80,205,137)',
                    pointHoverBorderColor: 'rgb(232,255,243)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.props.value
                  }
                ]
              }
            } />
            </>
        );
    };
}

export default LineChart;