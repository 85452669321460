export const UPDATE_STATUS_DISCOUNT = 'UPDATE_STATUS_DISCOUNT';
export const UPDATE_STATUS_DISCOUNT_SUCCESS = 'UPDATE_STATUS_DISCOUNT_SUCCESS';
export const UPDATE_STATUS_DISCOUNT_FAILURE = 'UPDATE_STATUS_DISCOUNT_FAILURE';

export const updateStatusDiscount = (discountId) => {
    console.log('update status discount action')
return({
    type: UPDATE_STATUS_DISCOUNT,
    discountId
});
}
export const updateStatusDiscountSuccess = (data) => ({
    type: UPDATE_STATUS_DISCOUNT_SUCCESS,
    payload: { data }
});

export const updateStatusDiscountFailure = (message) => ({
    type: UPDATE_STATUS_DISCOUNT_FAILURE,
    payload: { message }
});