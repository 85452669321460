import {
    GET_COMPANY_RESERVATION_REPORT,
    GET_COMPANY_RESERVATION_REPORT_SUCCESS,
    GET_COMPANY_RESERVATION_REPORT_FAILURE
} from '../../actions/Reports/getCompanyReservationReportActions';

export function getCompanyReservationReportReducer(state, action) {
    switch (action.type) {
        case GET_COMPANY_RESERVATION_REPORT:
            return {
                ...state,
                isGetCompanyReservationReport: true,
                getCompanyReservationReportError: null,
                companyReservationReport: [],
            };
        case GET_COMPANY_RESERVATION_REPORT_SUCCESS:
            return {
                ...state,
                isGetCompanyReservationReport: false,
                getCompanyReservationReportError: null,
                companyReservationReport: action.payload.companyReservationReport.result,
                companyReservationReportPageNumber: action.payload.reportData.pageNumber,
                companyReservationReportPageSize: action.payload.reportData.pageSize,
                companyReservationReportTotalAmount: action.payload.companyReservationReport.totalAmount,
                companyReservationReportTotalUnpaid: action.payload.companyReservationReport.totalUnpaid,
                companyReservationReportTotalPaid: action.payload.companyReservationReport.totalPaid,
                reportDataFilter:action.payload.reportData
            };
        case GET_COMPANY_RESERVATION_REPORT_FAILURE:
            return {
                ...state,
                isGetCompanyReservationReport: false,
                getCompanyReservationReportError: action.payload.message,
            };
        default:
            return state;
}}