import {
    GET_EDITED_COMPANY,
    GET_EDITED_COMPANY_SUCCESS,
    GET_EDITED_COMPANY_FAILURE
} from '../../actions/Companies/getEditedCompanyActions';

export function getEditedCompanyReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_COMPANY:
            return {
                ...state,
                isGetEditedCompany: true,
                getEditedCompanyError: null, 
            };
        case GET_EDITED_COMPANY_SUCCESS:
            return {
                ...state,
                isGetEditedCompany: false,
                getEditedCompanyError: null,
                editedCompanyInCompanies:state.companiesInCompanies.filter(x=> x.id == action.payload.companyId)[0],
            };
        case GET_EDITED_COMPANY_FAILURE:
            return {
                ...state,
                isGetEditedCompany: false,
                getEditedCompanyError: action.payload.message,
            };
        default:
            return state;
}}