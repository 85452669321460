export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';

export const editRole = (roleData) => ({
    type: EDIT_ROLE,
    roleData
});

export const editRoleSuccess = (data) => ({
    type: EDIT_ROLE_SUCCESS,
    payload: { data }
});

export const editRoleFailure = (message) => ({
    type: EDIT_ROLE_FAILURE,
    payload: { message }
});