import {
    GET_DISCOUNTS_IN_DISCOUNTS,
    GET_DISCOUNTS_IN_DISCOUNTS_SUCCESS,
    GET_DISCOUNTS_IN_DISCOUNTS_FAILURE
} from '../../actions/Discounts/getDiscountsInDiscountsActions';

export function getDiscountsInDiscountsReducer(state, action) {
    switch (action.type) {
        case GET_DISCOUNTS_IN_DISCOUNTS:
            return {
                ...state,
                isGetDiscountsInDiscounts: true,
                getDiscountsInDiscountsError: null,
            };
        case GET_DISCOUNTS_IN_DISCOUNTS_SUCCESS:
            console.log(action.payload.discounts)
            return {
                ...state,
                isGetDiscountsInDiscounts: false,
                getDiscountsInDiscountsError: null,
                discountsInDiscounts: action.payload.discounts.data,
                discountsPageNumberInDiscounts: action.payload.pageNumber,
                discountsPageSizeInDiscounts: action.payload.pageSize,
                discountsTotalCountInDiscounts: action.payload.discounts.total,
            };
        case GET_DISCOUNTS_IN_DISCOUNTS_FAILURE:
            return {
                ...state,
                isGetDiscountsInDiscounts: false,
                getDiscountsInDiscountsError: action.payload.message,
            };
        default:
            return state;
}}