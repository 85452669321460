export const GET_EDITED_RESERVATION_TYPE_SETTING = 'GET_EDITED_RESERVATION_TYPE_SETTING';
export const GET_EDITED_RESERVATION_TYPE_SETTING_SUCCESS = 'GET_EDITED_RESERVATION_TYPE_SETTING_SUCCESS';
export const GET_EDITED_RESERVATION_TYPE_SETTING_FAILURE = 'GET_EDITED_RESERVATION_TYPE_SETTING_FAILURE';

export const getEditedReservationTypeSetting = (reservationTypeSettingId) => {
    console.log('get edited reservationTypeSetting action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE_SETTING,
    reservationTypeSettingId
});
}
export const getEditedReservationTypeSettingSuccess = (reservationTypeSettingId) => {
    console.log('get edited reservationTypeSetting success action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE_SETTING_SUCCESS,
    payload: { reservationTypeSettingId }
});
}
export const getEditedReservationTypeSettingFailure = (message) => {
    console.log('get edited reservationTypeSetting fail action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE_SETTING_FAILURE,
    payload: { message }
});
}