export const GET_DAYS_IN_RESERVATION_SCHEDULES = 'GET_DAYS_IN_RESERVATION_SCHEDULES';
export const GET_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS = 'GET_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS';
export const GET_DAYS_IN_RESERVATION_SCHEDULES_FAILURE = 'GET_DAYS_IN_RESERVATION_SCHEDULES_FAILURE';

export const getDaysInReservationSchedules = (dayId = null) => ({
    type: GET_DAYS_IN_RESERVATION_SCHEDULES,
    dayId
});

export const getDaysInReservationSchedulesSuccess = (days) => ({
    type: GET_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS,
    payload: { days }
});

export const getDaysInReservationSchedulesFailure = (message) => ({
    type: GET_DAYS_IN_RESERVATION_SCHEDULES_FAILURE,
    payload: { message }
});