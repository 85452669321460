export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_REQUEST_SUCCESS = 'EDIT_REQUEST_SUCCESS';
export const EDIT_REQUEST_FAILURE = 'EDIT_REQUEST_FAILURE';

export const editRequest = (requestViewModel) => ({
    type: EDIT_REQUEST,
    requestViewModel
});

export const editRequestSuccess = (requestViewModel) => ({
    type: EDIT_REQUEST_SUCCESS,
    payload: { requestViewModel }
});

export const editRequestFailure = (message) => ({
    type: EDIT_REQUEST_FAILURE,
    payload: message
});