import {
    EDIT_ROLE_USER,
    EDIT_ROLE_USER_SUCCESS,
    EDIT_ROLE_USER_FAILURE
} from '../../../actions/Roles/RoleUsers/editRoleUserActions';

export function editRoleUserReducer(state, action) {
    switch (action.type) {
        case EDIT_ROLE_USER:
            return {
                ...state,
                isEditingRoleUser: true,
                editRoleUserError: null,
            };
        case EDIT_ROLE_USER_SUCCESS:
            return {
                ...state,
                isEditingRoleUser: false,
                editRoleUserError: null,
                editRoleUserResult: action.payload.data,
            };
        case EDIT_ROLE_USER_FAILURE:
            return {
                ...state,
                isEditingRoleUser: false,
                editRoleUserError: action.payload.message,
            };
        default:
            return state;
}}