export const UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS = 'UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS';
export const UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateCompaniesInCompanyReservations = (company) => ({
    type: UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS,
    company
}); 

export const updateCompaniesInCompanyReservationsSuccess = (company) => ({
    type: UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { company }
});

export const updateCompaniesInCompanyReservationsFailure = (message) => ({
    type: UPDATE_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});