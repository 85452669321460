import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_SCREENS_IN_ROLE_SCREENS,
    getScreensInRoleScreensSuccess,
    getScreensInRoleScreensFailure
} from '../../../actions/Roles/RoleScreens/getScreensInRoleScreensActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function getScreensInRoleScreensEpic(action$) {
    return action$.pipe(
        ofType(GET_SCREENS_IN_ROLE_SCREENS),
        switchMap(action => {
            
            const queryString= action.roleScreenId !== null ? `?roleId=${action.roleId}&roleScreenId=${action.roleScreenId}` : `?roleId=${action.roleId}`;

            const ajaxConfigObj = {
                url:  url + `RoleScreen/GetScreens${queryString}`,
                method: 'POST',
                headers: {
                	'Authorization':token
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
            map(data => ({
                response: data.response
            })))
        }),
        mergeMap(data => {
             console.log(data.response)
            if (data.response.isSuccess) {
                const screens=data.response.data.map(item => ({
                    label:strings.getLanguage() === LanguagesConstant.AR ? item.nameAr : item.nameEn,
                    value:item.id
                }));
                return of(getScreensInRoleScreensSuccess(screens));
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                 && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getScreensInRoleScreensFailure(message));
            }
        }),
        catchError(error =>  of(getScreensInRoleScreensFailure(strings.somethingWrongHappened)))
    )
}

