import {
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS,
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE
} from '../../actions/ReservationTypeSettings/getReservationTypesInReservationTypeSettingsActions';

export function getReservationTypesInReservationTypeSettingsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS:
            return {
                ...state,
                isGetReservationTypesInReservationTypeSettings: true,
                getReservationTypesInReservationTypeSettingsError: null,
            };
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS:
            return {
                ...state,
                isGetReservationTypesInReservationTypeSettings: false,
                getReservationTypesInReservationTypeSettingsError: null,
                reservationTypesInReservationTypeSettings: action.payload.reservationTypes,
                reservationTypeInReservationTypeSettings: state.editedReservationTypeSettingInReservationTypeSettings !== null ? action.payload.reservationTypes.filter(x => x.value === state.editedReservationTypeSettingInReservationTypeSettings.reservationTypeId)[0] : null,
            };
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE:
            return {
                ...state,
                isGetReservationTypesInReservationTypeSettings: false,
                getReservationTypesInReservationTypeSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}