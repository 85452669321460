import {
    APPROVE_REQUEST,
    APPROVE_REQUEST_SUCCESS,
    APPROVE_REQUEST_FAILURE
} from '../../actions/Requests/approveRequestActions';

export function approveRequestReducer(state, action) {
    switch (action.type) {
        case APPROVE_REQUEST:
            return {
                ...state,
                isApprovingRequest: true,
                approveRequestError: null,
            };
        case APPROVE_REQUEST_SUCCESS:
            return {
                ...state,
                isApprovingRequest: false,
                approveRequestResult: action.payload.requestViewModel,
                approveRequestError: null
            };
        case APPROVE_REQUEST_FAILURE:
            return {
                ...state,
                isApprovingRequest: false,
                approveRequestError: action.payload
            };
        default:
            return state;
    }
}