export const GET_EDITED_TICKET_TYPE = 'GET_EDITED_TICKET_TYPE';
export const GET_EDITED_TICKET_TYPE_SUCCESS = 'GET_EDITED_TICKET_TYPE_SUCCESS';
export const GET_EDITED_TICKET_TYPE_FAILURE = 'GET_EDITED_TICKET_TYPE_FAILURE';

export const getEditedTicketType = (ticketTypeId) => {
    console.log('get edited ticketType action')
    return ({
    type: GET_EDITED_TICKET_TYPE,
    ticketTypeId
});
}
export const getEditedTicketTypeSuccess = (ticketTypeId) => {
    console.log('get edited ticketType success action')
    return ({
    type: GET_EDITED_TICKET_TYPE_SUCCESS,
    payload: { ticketTypeId }
});
}
export const getEditedTicketTypeFailure = (message) => {
    console.log('get edited ticketType fail action')
    return ({
    type: GET_EDITED_TICKET_TYPE_FAILURE,
    payload: { message }
});
}