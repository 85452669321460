export const UPDATE_STATUS_PAUSED_TIME = 'UPDATE_STATUS_PAUSED_TIME';
export const UPDATE_STATUS_PAUSED_TIME_SUCCESS = 'UPDATE_STATUS_PAUSED_TIME_SUCCESS';
export const UPDATE_STATUS_PAUSED_TIME_FAILURE = 'UPDATE_STATUS_PAUSED_TIME_FAILURE';

export const updateStatusPausedTime = (pausedTimeId) => {
    console.log('update status pausedTime action')
return({
    type: UPDATE_STATUS_PAUSED_TIME,
    pausedTimeId
});
}
export const updateStatusPausedTimeSuccess = (data) => ({
    type: UPDATE_STATUS_PAUSED_TIME_SUCCESS,
    payload: { data }
});

export const updateStatusPausedTimeFailure = (message) => ({
    type: UPDATE_STATUS_PAUSED_TIME_FAILURE,
    payload: { message }
});