import {
    GET_ROLE_SCREENS,
    GET_ROLE_SCREENS_SUCCESS,
    GET_ROLE_SCREENS_FAILURE
} from '../../../actions/Roles/RoleScreens/getRoleScreensActions';

export function getRoleScreensReducer(state, action) {
    switch (action.type) {
        case GET_ROLE_SCREENS:
            return {
                ...state,
                isGetRoleScreens: true,
                getRoleScreensError: null,
            };
        case GET_ROLE_SCREENS_SUCCESS:
            return {
                ...state,
                isGetRoleScreens: false,
                getRoleScreensError: null,
                roleScreensInRoleScreens: action.payload.roleScreens.data,
                roleScreensPageNumberInRoleScreens: action.payload.pageNumber,
                roleScreensPageSizeInRoleScreens: action.payload.pageSize,
                roleScreensTotalCountInRoleScreens: action.payload.roleScreens.total,
            };
        case GET_ROLE_SCREENS_FAILURE:
            return {
                ...state,
                isGetRoleScreens: false,
                getRoleScreensError: action.payload.message,
            };
        default:
            return state;
}}