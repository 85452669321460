import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationsConstant } from "../../constants/Reservations/DisplayTypesInReservationsConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);


function Edit(props) {
  const formikRef = React.useRef(null);

   const [reservationTimeError, setReservationTimeError] = useState(null);
  const [reservationDateError, setReservationDateError] = useState(null);

  useEffect(() => {
    props.getReservationTimesInEditReservation(props.editedReservationInReservations.reservationTypeSettingId, moment(props.editedReservationInReservations.fromTime).format("YYYY-MM-DD HH:mm:ss"), props.editedReservationInReservations.id);
  }, [])

  useEffect(() => {
    if (props.editReservationResult !== null) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.editReservationResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInReservations(DisplayTypesInReservationsConstant.LIST)
      }
      )
    }
  }, [props.editReservationResult])

  useEffect(() => {
    if (props.editReservationError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editReservationError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editReservationError])

  function handleReservationTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTimeError(strings.pleaseSelectTime)
    } else {
      setReservationTimeError(null)
    }
    props.updateReservationTimesInEditReservation(selectedOption);
  }

  function handleReservationDateChange(value) {
    if (!moment(value).isValid()) {
      setReservationDateError(strings.dateNotValid);
    } else {
      setReservationDateError(null);
      formikRef.current.setFieldValue("reservationDate", value);
      props.getReservationTimesInEditReservation(props.editedReservationInReservations.reservationTypeSettingId, moment(value).format("YYYY-MM-DD HH:mm:ss"), props.editedReservationInReservations.id);
    }
  }

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.enterReservationDate} *`}
      name="reservationDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  return (
    <>
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.editReservation}
              </span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              innerRef={formikRef}
              initialValues={{
                reservationDate: new Date(props.editedReservationInReservations.reservationDate),
              }}
              onSubmit={(fields) => {
                let isValid = true;

                 if (props.reservationTimeInEditReservation == null) {
                  setReservationTimeError(strings.pleaseSelectTime)
                  isValid = false;
                }

                 if (isValid) {

                  let startDate = moment(fields.reservationDate).toDate();
                  const splittedStartTimeArr = props.reservationTimeInEditReservation.fromTime.split(":");
                  startDate.setHours(splittedStartTimeArr[0]);
                  startDate.setMinutes(splittedStartTimeArr[1]);

                  const reservationViewModel = {
                    id: props.editedReservationInReservations.id,
                    reservationScheduleId: props.reservationTimeInEditReservation.reservationScheduleId,
                    reservationDate: moment(fields.reservationDate).format("YYYY-MM-DD HH:mm:ss"),
                    fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss")
                  };

                  props.editReservation(reservationViewModel);
                }
              }}

              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.reservationDate}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <DatePicker placeholderText={strings.from}
                          customInput={<StartDateInput />}
                          locale={strings.getLanguage() === LanguagesConstant.AR ?
                            "ar" : "en"}
                          selected={values.reservationDate}
                          onChange={handleReservationDateChange}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          className={reservationDateError !== null ? 'red-border' : ''} />
                        {
                          reservationDateError && (
                            <div className="invalid-feedback block">
                              {reservationDateError}
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.selectTime}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Select
                          name="reservationType"
                          options={props.reservationTimesInEditReservation}
                          placeholder={strings.selectTime}
                          value={props.reservationTimeInEditReservation}
                          onChange={handleReservationTimeChanges}
                          isSearchable
                          isLoading={props.isGettingReservationTimesInEditReservation}
                          className={reservationTimeError != null ? "is-invalid" : ""}
                        />
                        {reservationTimeError && (
                          <div className="invalid-feedback block">
                            {reservationTimeError}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                      <div className="col-11">
                        <button type="submit" className="btn fs-5" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                        >
                          {strings.save}
                        </button>
                      </div>

                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Edit;
