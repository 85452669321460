export const DELETE_TICKET_OPTION = 'DELETE_TICKET_OPTION';
export const DELETE_TICKET_OPTION_SUCCESS = 'DELETE_TICKET_OPTION_SUCCESS';
export const DELETE_TICKET_OPTION_FAILURE = 'DELETE_TICKET_OPTION_FAILURE';

export const deleteTicketOption = (ticketOptionId) => ({
    type: DELETE_TICKET_OPTION,
    ticketOptionId
});

export const deleteTicketOptionSuccess = (data) => ({
    type: DELETE_TICKET_OPTION_SUCCESS,
    payload: { data }
});

export const deleteTicketOptionFailure = (message) => ({
    type: DELETE_TICKET_OPTION_FAILURE,
    payload: { message }
});