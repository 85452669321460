import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_RESERVATION_TYPE,
    getEditedReservationTypeSuccess,
    getEditedReservationTypeFailure
} from '../../actions/ReservationTypes/getEditedReservationTypeActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedReservationTypeEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_RESERVATION_TYPE),
        map(action =>({
            reservationTypeId : action.reservationTypeId 
        })),
        map(response => 
           getEditedReservationTypeSuccess(response.reservationTypeId)
           
        ),
        catchError(error => of(getEditedReservationTypeFailure(strings.somethingWrongHappened)))
    )
}

