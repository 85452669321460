import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_USERS,
    updateDisplayTypeInUsersSuccess,
    updateDisplayTypeInUsersFailure
} from '../../../src/actions/Users/updateDisplayTypeInUsersActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInUsersEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_USERS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response => updateDisplayTypeInUsersSuccess(response.displayType)
        ),
        catchError(error => Observable.of(updateDisplayTypeInUsersFailure(strings.somethingWrongHappened)))
    )
}

