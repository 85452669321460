import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationsConstant } from "../../constants/Reservations/DisplayTypesInReservationsConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);


function Cancel(props) {
  const formikRef = React.useRef(null);

  useEffect(() => {
    if (props.cancelReservationResult !== null) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.cancelReservationResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInReservations(DisplayTypesInReservationsConstant.LIST)
      }
      )
    }
  }, [props.cancelReservationResult])

  useEffect(() => {
    if (props.cancelReservationError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.cancelReservationError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.cancelReservationError])

  return (
    <>
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.cancelReservation}
              </span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              innerRef={formikRef}
              initialValues={{
                count: props.editedReservationInReservations.count,
                paymentLink: ""
              }}
              validationSchema={Yup.object().shape({
                count: Yup.number()
                  .integer(strings.invalidInputs)
                  .max(props.editedReservationInReservations.count, strings.countMustBeLessThanIt)
                  .required(strings.pleaseEnterCount),
                paymentLink: Yup.string()
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    strings.invalidInputs)
              })}
              onSubmit={(values) => {
                const cancelReservationViewModel = {
                  reservationId: props.editedReservationInReservations.id,
                  cancelCount: values.count
                };

                if(values.paymentLink != "" && values.paymentLink != null){
                  cancelReservationViewModel.paymentlink=values.paymentLink;
                }

                props.cancelReservation(cancelReservationViewModel);
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>

                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.count}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="count"
                          className={
                            "form-control" +
                            (errors.count &&
                              touched.count
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterCount} *`}
                        />
                        <ErrorMessage
                          name="count"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.paymentLink}</label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                      <Field
                          type="text"
                          name="paymentLink"
                          component="textArea"
                          rows={3}
                          className={
                            "form-control" +
                            (errors.paymentLink &&
                              touched.paymentLink
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterPaymentLink} *`}
                        />
                        <ErrorMessage
                          name="paymentLink"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                      <div className="col-11">
                        <button type="submit" className="btn fs-5" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                        >
                          {strings.save}
                        </button>
                      </div>

                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Cancel;
