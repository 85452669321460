import {
    GET_COMPANIES_IN_COMPANY_RESERVATIONS,
    GET_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getCompaniesInCompanyReservationsActions';

export function getCompaniesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_COMPANIES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGetCompaniesInCompanyReservations: true,
                getCompaniesInCompanyReservationsError: null,
            };
        case GET_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetCompaniesInCompanyReservations: false,
                getCompaniesInCompanyReservationsError: null,
                companiesInCompanyReservations: action.payload.companies,
                companyInCompanyReservations: state.editedCompanyReservationInCompanyReservations != null ? action.payload.companies.filter(x=> x.value == state.editedCompanyReservationInCompanyReservations.companyId)[0] : null,
            };
        case GET_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetCompaniesInCompanyReservations: false,
                getCompaniesInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}