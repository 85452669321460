import React, { useEffect, useState, useRef } from "react";
import strings from '../../localizations/strings';
import { Button } from "react-bootstrap";
import { DisplayTypesInReportsConstant } from "../../constants/Reports/DisplayTypesInReportsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Loading from "../Shared/Loading/Loading";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { useDownloadExcel } from 'react-export-table-to-excel';
import "../../webfonts/Amiri/Amiri";
function RequestReport(props) {
  const tableRef = useRef(null);
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => {
      const reportData = {
        ...props.reportDataFilter,
        pageNumber: 1,
        pageSize: selected.value,
        search: search
      }
      props.getRequestReport(reportData);
    },
    handleTableExport = (selected) => { setExport(selected) };

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    const reportData = {
      ...props.reportDataFilter,
      pageNumber: 1,
      pageSize: props.requestReportPageSize,
      search: selected.target.value
    }
    props.getRequestReport(reportData);
  }
  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.requestReportPageNumber) {
      const reportData = {
        ...props.reportDataFilter,
        pageNumber: pageNumber,
        pageSize: props.requestReportPageSize,
        search: search
      }
      props.getRequestReport(reportData);
    }
  }

  function downloadPdf() {
    const doc = new jsPDF('l', 'pt', "a4");
    doc.setFont("Amiri-Regular")
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text((pageWidth / 2) - 25, 25, strings.requestReport);
    autoTable(doc, {
      headStyles: { font: "Amiri-Regular" }, // For Arabic text in the table head
      bodyStyles: { font: "Amiri-Regular" }, // For Arabic text in the table body
      html: '#reportTable',
      pageBreak: "avoid"
    })
    doc.save('RequestReport.pdf')
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'RequestReport',
    sheet: 'Requests'
  })

  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetRequestReport && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length, { label: strings.all, value: props.requestReportTotalCount }]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.requestReportPageSize)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.requestReportTotalCount}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>
          <div className="col-md-12">
            <Button variant="sm btn-primary m-1  animate__animated  animate__fadeInDown" onClick={downloadPdf}>
              {strings.downloadPdf}
            </Button>
            <Button variant="sm btn-primary m-1  animate__animated  animate__fadeInDown" onClick={onDownload}> Download as XLS </Button>
            <div className='table-responsive p-0'>
              <table id="reportTable" ref={tableRef} className='table align-middle text-center table-bordered border-bottom m-0'>
                <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                  <tr className="fw-bolder text-muted text-center align-middle bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                    <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                    <th className="min-w-125px px-0">{strings.mobile}</th>
                    <th className="min-w-125px px-0">{strings.reservationType}</th>
                    <th className="min-w-125px px-0">{strings.requestStatus}</th>
                    <th className="min-w-125px px-0">{strings.amount}</th>
                    <th className="min-w-125px px-0">{strings.totalAmount}</th>
                    <th className="min-w-125px px-0">{strings.fromTime}</th>
                    <th className="min-w-125px px-0">{strings.toTime}</th>
                    <th className="min-w-125px px-0">{strings.day}</th>
                    <th className="min-w-125px px-0">{strings.ticketOptions}</th>
                    <th className="min-w-125px px-0">{strings.reservationDate}</th>
                    <th className="min-w-125px px-0">{strings.insertedDate}</th>
                    <th className="min-w-125px px-0">{strings.amount}</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {!props.isGetRequestReport
                    && props.requestReport !== []
                    && props.requestReport.length > 0
                    && props.requestReport.map((request, index) => {
                      return <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{index + 1}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? request.userNameAr : request.userNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.phoneNumber}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? request.reservationTypeNameAr : request.reservationTypeNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? request.requestStatusNameAr : request.requestStatusNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.amount}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.totalAmount}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.fromTimeStr !== null ? request.fromTimeStr : strings.notSpecified}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.toTimeStr !== null ? request.toTimeStr : strings.notSpecified}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>
                                {strings.getLanguage() === LanguagesConstant.EN ? request.dayNameEn : request.dayNameAr}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>
                                {strings.getLanguage() === LanguagesConstant.EN ? request.ticketOptionListEn?.join('-') : request.ticketOptionListAr?.join('-')}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.reservationDateStr}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.insertedDate}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{request.totalAmount}</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })
                  }

                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            {
              props.requestReportTotalCount !== null
              && props.requestReportTotalCount > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.requestReportPageNumber - 1) * props.requestReportPageSize) + 1} {strings.to} {(props.requestReportPageNumber * props.requestReportPageSize) > props.requestReportTotalCount ? props.requestReportTotalCount : (props.requestReportPageNumber * props.requestReportPageSize)} {strings.of} {props.requestReportTotalCount} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.requestReportPageNumber}
                    itemsCountPerPage={props.requestReportPageSize}
                    totalItemsCount={props.requestReportTotalCount}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>

        </div>
      </div>

    </>
  );
}

export default RequestReport;