import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_EDITED_SMS_SETTING,
    getEditedSmsSettingSuccess,
    getEditedSmsSettingFailure
} from '../../actions/SmsSettings/getEditedSmsSettingActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedSmsSettingEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_SMS_SETTING),
        map(action => ({
            smsSettingId: action.smsSettingId
        })),
        map(response =>
            getEditedSmsSettingSuccess(response.smsSettingId)

        ),
        catchError(error => of(getEditedSmsSettingFailure(strings.somethingWrongHappened)))
    )
}

