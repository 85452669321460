import {
    ADD_NEW_PAUSED_TIME,
    ADD_NEW_PAUSED_TIME_SUCCESS,
    ADD_NEW_PAUSED_TIME_FAILURE
} from '../../actions/PausedTimes/addNewPausedTimeActions';

import { DisplayTypesInPausedTimesConstant } from '../../constants/PausedTimes/DisplayTypesInPausedTimesConstant';

export function addNewPausedTimeReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_PAUSED_TIME:
            return {
                ...state,
                isAddNewPausedTime: true,
                addNewPausedTimeError: null, 
            };
        case ADD_NEW_PAUSED_TIME_SUCCESS:
            return {
                ...state,
                isAddNewPausedTime: false,
                addNewPausedTimeError: null,
                addNewPausedTimeResult: action.payload.data,
              //  displayTypeInPausedTimes: DisplayTypesInPausedTimesConstant.LIST,
            };
        case ADD_NEW_PAUSED_TIME_FAILURE:
            return {
                ...state,
                isAddNewPausedTime: false,
                addNewPausedTimeError: action.payload.message,
            };
        default:
            return state;
}}