export const UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS = 'UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS';
export const UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateReservationTypesInCompanyReservations = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
    reservationType
}); 

export const updateReservationTypesInCompanyReservationsSuccess = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { reservationType }
});

export const updateReservationTypesInCompanyReservationsFailure = (message) => ({
    type: UPDATE_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});