import {
    GET_SCHOOL_TOUR_RANGES_IN_REQUESTS,
    GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_SUCCESS,
    GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/getSchoolTourRangesInRequestsActions';

export function getSchoolTourRangesInRequestsReducer(state, action) {
    switch (action.type) {
        case GET_SCHOOL_TOUR_RANGES_IN_REQUESTS:
            return {
                ...state,
                isGettingSchoolTourRangesInRequests: true,
                getSchoolTourRangesInRequestsError: null,
            };
        case GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isGettingSchoolTourRangesInRequests: false,
                schoolTourRangesInRequests: action.payload.ranges,
                getSchoolTourRangesInRequestsError: null,
            };
        case GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isGettingSchoolTourRangesInRequests: false,
                getSchoolTourRangesInRequestsError: action.payload
            };
        default:
            return state;
    }
}