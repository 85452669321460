export const GET_TO_TIMES_IN_COMPANY_RESERVATIONS = 'GET_TO_TIMES_IN_COMPANY_RESERVATIONS';
export const GET_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE';

export const getToTimesInCompanyReservations = (selectedDate) => ({
    type: GET_TO_TIMES_IN_COMPANY_RESERVATIONS,
    selectedDate
});

export const getToTimesInCompanyReservationsSuccess = (timeResultList,selectedDate) => ({
    type: GET_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { timeResultList,selectedDate }
});

export const getToTimesInCompanyReservationsFailure = (message) => ({
    type: GET_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});