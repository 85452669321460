import React,{useState,useEffect} from "react";
import { DisplayTypesInSchoolTourRangesConstant } from "../../../src/constants/SchoolTourRanges/DisplayTypesInSchoolTourRangesConstant";
import SchoolTourRange from './SchoolTourRange';
import Edit from './Edit';
import Add from './Add';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function SchoolTourRanges(props){

    return (
        <>
                  <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInSchoolTourRanges === DisplayTypesInSchoolTourRangesConstant.LIST ? strings.schoolTourRanges:
                        props.displayTypeInSchoolTourRanges === DisplayTypesInSchoolTourRangesConstant.ADD ? strings.create:
                        props.displayTypeInSchoolTourRanges === DisplayTypesInSchoolTourRangesConstant.EDIT ? strings.edit:""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInSchoolTourRanges  !== DisplayTypesInSchoolTourRangesConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInSchoolTourRanges(DisplayTypesInSchoolTourRangesConstant.LIST)}>
                                    {strings.schoolTourRanges}
                                </Button> :null}

                            {props.displayTypeInSchoolTourRanges  !== DisplayTypesInSchoolTourRangesConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInSchoolTourRanges(DisplayTypesInSchoolTourRangesConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                           
                            { props.displayTypeInSchoolTourRanges  === DisplayTypesInSchoolTourRangesConstant.LIST &&
                            (<SchoolTourRange {...props} />)}
                            {props.displayTypeInSchoolTourRanges  === DisplayTypesInSchoolTourRangesConstant.ADD &&
                             (<Add {...props} />)}
                            {props.displayTypeInSchoolTourRanges  === DisplayTypesInSchoolTourRangesConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
                        

        </>
    );
}
export default SchoolTourRanges