export const GET_PAUSED_TIMES_IN_PAUSED_TIMES = 'GET_PAUSED_TIMES_IN_PAUSED_TIMES';
export const GET_PAUSED_TIMES_IN_PAUSED_TIMES_SUCCESS = 'GET_PAUSED_TIMES_IN_PAUSED_TIMES_SUCCESS';
export const GET_PAUSED_TIMES_IN_PAUSED_TIMES_FAILURE = 'GET_PAUSED_TIMES_IN_PAUSED_TIMES_FAILURE';

export const getPausedTimesInPausedTimes = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_PAUSED_TIMES_IN_PAUSED_TIMES,
    pageNumber,
    pageSize,
    search
});

export const getPausedTimesInPausedTimesSuccess = (pausedTimes, pageNumber, pageSize) => ({
    type: GET_PAUSED_TIMES_IN_PAUSED_TIMES_SUCCESS,
    payload: { pausedTimes, pageNumber, pageSize }
});

export const getPausedTimesInPausedTimesFailure = (message) => ({
    type: GET_PAUSED_TIMES_IN_PAUSED_TIMES_FAILURE,
    payload: { message }
});