import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { DisplayTypesInCancelledReservationsConstant } from "../../constants/Reservations/DisplayTypesInCancelledReservationsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'
import moment from "moment";
function Reservation(props) {

  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    [selectedDate,setSelectedDate] = useState(null),
    handleTableLength = (selected) => { props.getCancelledReservationsInReservations(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getCancelledReservationsInReservations();
  }, []);

  useEffect(() => {
    props.getCancelledReservationsInReservations();
  }, []);

  useEffect(() => {
    if (
      props.deleteCancelledReservationResult !== null &&
      props.deleteCancelledReservationResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deleted}`, props.deleteCancelledReservationResult.message, "success").then(result => {
        props.getCancelledReservationsInReservations(props.cancelledReservationsPageNumberInReservations, props.cancelledReservationsPageSizeInReservations,search);
      });
    }
  }, [props.deleteCancelledReservationResult]);

  useEffect(() => {
    if (
      props.deleteCancelledReservationError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteCancelledReservationError, "error");
    }
  }, [props.deleteCancelledReservationError]);

  useEffect(() => {
    if (props.updateReturnMoneyStatusReservationResult !== null && props.updateReturnMoneyStatusReservationResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.updateReturnMoneyStatusReservationResult.message,
        "success"
      ).then(result => {
       // props.getCancelledReservationsInReservations(props.cancelledReservationsPageNumberInReservations, props.cancelledReservationsPageSizeInReservations);
      }
      );
    }
  }, [props.updateReturnMoneyStatusReservationResult]);

  useEffect(() => {
    if (props.updateReturnMoneyStatusReservationError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateReturnMoneyStatusReservationError, "error");
    }
  }, [props.updateReturnMoneyStatusReservationError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.cancelledReservationsPageNumberInReservations) {
      props.getCancelledReservationsInReservations(pageNumber, props.cancelledReservationsPageSizeInReservations,search);
    }
  }

  function handleinputSearch(selected) {
    console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getCancelledReservationsInReservations(1, props.cancelledReservationsPageSizeInReservations, selected.target.value)
  }
  function handleDateChange(selected) {
    setSelectedDate(selected);
    if(selected !== null){
    props.getCancelledReservationsInReservations(1, props.cancelledReservationsPageSizeInReservations, search,moment(selected).locale("en").format('lll'))
    }
    else{
      props.getCancelledReservationsInReservations(1, props.cancelledReservationsPageSizeInReservations, search)
    }
  }
  return (
    <>
      <div className="customer-data mt-5">
      {
                  props.isGetCancelledReservationsInReservations
                  && (<Loading />)
                }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              showDateInput = {true}
              selectedDate = {selectedDate}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.cancelledReservationsTotalCountInReservations}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.cancelledReservationsPageSizeInReservations)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.cancelledReservationsTotalCountInReservations}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
              handleDateChange={handleDateChange}
            />
          </div>

          <div className="table-responsive">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                  <th className="min-w-125px px-0">{strings.mobile}</th>
                  <th className="min-w-125px px-0">{strings.reservationType}</th>
                  <th className="min-w-125px px-0">{strings.code}</th>
                  <th className="min-w-125px px-0">{strings.fromTime}</th>
                  <th className="min-w-125px px-0">{strings.toTime}</th>
                  <th className="min-w-125px px-0">{strings.day}</th>
                  <th className="min-w-125px px-0">{strings.reservationDate}</th>
                  <th className="min-w-125px px-0">{strings.count}</th>
                  <th className="min-w-125px px-0">{strings.amount}</th>
                  <th className="min-w-125px px-0">{strings.paymentLink}</th>
                  <th className="min-w-125px px-0">{strings.returnMoney}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
               
                {
                  props.cancelledReservationsInReservations !== []
                  && props.cancelledReservationsInReservations.length > 0
                  && props.cancelledReservationsInReservations.map((reservation, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? reservation.userNameAr : reservation.userNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.phoneNumber}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? reservation.reservationTypeNameAr : reservation.reservationTypeNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.reservationCode}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.fromTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.toTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {strings.getLanguage() === LanguagesConstant.EN ? reservation.dayNameEn : reservation.dayNameAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.reservationDateStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.count}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.amount}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {
                            !reservation.isReturnMoney ?
                            (reservation.paymentLink != null && reservation.paymentLink != "" ?
                              (
                                <div
                                  className="actions"
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  <div className="action1">
                                    <a
                                      href={reservation.paymentLink}
                                      target="_blank"
                                    >
                                      <i className="fas fa-link edit"></i>
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <h4>{strings.noPaymentLink}</h4>
                              )
                            ) : (
                              <h4>{strings.returnedMoney}</h4>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            {
                              !reservation.isReturnMoney ?
                                (
                                  <div className="form-check form-switch">
                                    <input className="form-check-input"
                                      style={{ float: "unset", width: "50px", height: "27px" }}
                                      type="checkbox"
                                      id={`flexSwitchCheckChecked${index}`}
                                      onChange={e =>
                                        props.updateReturnMoneyStatusReservation(reservation.id)}
                                    />
                                  </div>
                                ) : (
                                  <h4>{strings.returnedMoney}</h4>
                                )
                            }
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, reservation.id); }}
                              >
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer p-2">
            {
              props.cancelledReservationsTotalCountInReservations !== null
              && props.cancelledReservationsTotalCountInReservations > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.cancelledReservationsPageNumberInReservations - 1) * props.cancelledReservationsPageSizeInReservations) + 1} {strings.to} {(props.cancelledReservationsPageNumberInReservations * props.cancelledReservationsPageSizeInReservations) > props.cancelledReservationsTotalCountInReservations ? props.cancelledReservationsTotalCountInReservations : (props.cancelledReservationsPageNumberInReservations * props.cancelledReservationsPageSizeInReservations)} {strings.of} {props.cancelledReservationsTotalCountInReservations} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.cancelledReservationsPageNumberInReservations}
                    itemsCountPerPage={props.cancelledReservationsPageSizeInReservations}
                    totalItemsCount={props.cancelledReservationsTotalCountInReservations}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reservation;