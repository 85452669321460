export const EDIT_RESERVATION_TYPE_SETTING = 'EDIT_RESERVATION_TYPE_SETTING';
export const EDIT_RESERVATION_TYPE_SETTING_SUCCESS = 'EDIT_RESERVATION_TYPE_SETTING_SUCCESS';
export const EDIT_RESERVATION_TYPE_SETTING_FAILURE = 'EDIT_RESERVATION_TYPE_SETTING_FAILURE';

export const editReservationTypeSetting = (reservationTypeSettingData) =>{
    console.log(reservationTypeSettingData) 
    return ({
    type: EDIT_RESERVATION_TYPE_SETTING,
    reservationTypeSettingData
});
}
export const editReservationTypeSettingSuccess = (data) => ({
    type: EDIT_RESERVATION_TYPE_SETTING_SUCCESS,
    payload: { data }
});

export const editReservationTypeSettingFailure = (message) => ({
    type: EDIT_RESERVATION_TYPE_SETTING_FAILURE,
    payload: { message }
});