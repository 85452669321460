import {
    UPDATE_STATUS_NEW,
    UPDATE_STATUS_NEW_SUCCESS,
    UPDATE_STATUS_NEW_FAILURE
} from '../../actions/News/updateStatusNewActions';

export function updateStatusNewReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_NEW:
            return {
                ...state,
                isUpdatingStatusNew: true,
                updateStatusNewError: null,
            };
        case UPDATE_STATUS_NEW_SUCCESS:
            return {
                ...state,
                isUpdatingStatusNew: false,
                updateStatusNewError: null,
                updateStatusNewResult: action.payload.data,
                newsInNews: state.newsInNews.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_NEW_FAILURE:
            return {
                ...state,
                isUpdatingStatusNew: false,
                updateStatusNewError: action.payload.message,
            };
        default:
            return state;
    }
}