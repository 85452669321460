import {
    EDIT_RESERVATION,
    EDIT_RESERVATION_SUCCESS,
    EDIT_RESERVATION_FAILURE
} from '../../actions/Reservations/editReservationActions';

export function editReservationReducer(state, action) {
    switch (action.type) {
        case EDIT_RESERVATION:
            return {
                ...state,
                isEditingReservation: true,
                editReservationError: null,
            };
        case EDIT_RESERVATION_SUCCESS:
            return {
                ...state,
                isEditingReservation: false,
                editReservationResult: action.payload.reservationViewModel,
                reservationsInReservations: state.reservationsInReservations.map((item, index) => {
                    if (item.id === action.payload.reservationViewModel.id) {
                        return action.payload.reservationViewModel
                    }
                    return item
                }),
                editReservationError: null
            };
        case EDIT_RESERVATION_FAILURE:
            return {
                ...state,
                isEditingReservation: false,
                editReservationError: action.payload
            };
        default:
            return state;
    }
}