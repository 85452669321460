import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE
} from '../../../actions/Roles/Roles/getRolesActions';

export function getRolesReducer(state, action) {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                isGetRoles: true,
                getRolesError: null,
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                isGetRoles: false,
                getRolesError: null,
                rolesInRoles: action.payload.roles.data,
                rolesPageNumberInRoles: action.payload.pageNumber,
                rolesPageSizeInRoles: action.payload.pageSize,
                rolesTotalCountInRoles: action.payload.roles.total,
            };
        case GET_ROLES_FAILURE:
            return {
                ...state,
                isGetRoles: false,
                getRolesError: action.payload.message,
            };
        default:
            return state;
}}