export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS';
export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS';
export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE';

export const getReservationTypesInReservationTypeSettings = (reservationTypeId = null) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS,
    reservationTypeId
}); 

export const getReservationTypesInReservationTypeSettingsSuccess = (reservationTypes) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    payload: { reservationTypes }
});

export const getReservationTypesInReservationTypeSettingsFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE,
    payload: { message }
});