export const UPDATE_ATTENDENCE_STATUS_RESERVATION = 'UPDATE_ATTENDENCE_STATUS_RESERVATION';
export const UPDATE_ATTENDENCE_STATUS_RESERVATION_SUCCESS = 'UPDATE_ATTENDENCE_STATUS_RESERVATION_SUCCESS';
export const UPDATE_ATTENDENCE_STATUS_RESERVATION_FAILURE = 'UPDATE_ATTENDENCE_STATUS_RESERVATION_FAILURE';

export const updateAttendenceStatusReservation = (reservationId) => ({
    type: UPDATE_ATTENDENCE_STATUS_RESERVATION,
    reservationId
});

export const updateAttendenceStatusReservationSuccess = (data) => ({
    type: UPDATE_ATTENDENCE_STATUS_RESERVATION_SUCCESS,
    payload: { data }
});

export const updateAttendenceStatusReservationFailure = (message) => ({
    type: UPDATE_ATTENDENCE_STATUS_RESERVATION_FAILURE,
    payload: { message }
});