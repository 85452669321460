import {
    DELETE_RESERVATION,
    DELETE_RESERVATION_SUCCESS,
    DELETE_RESERVATION_FAILURE
} from '../../actions/Reservations/deleteReservationActions';

import { DisplayTypesInReservationsConstant } from '../../constants/Reservations/DisplayTypesInReservationsConstant';

export function deleteReservationReducer(state, action) {
    switch (action.type) {
        case DELETE_RESERVATION:
            return {
                ...state,
                isDeletingReservation: true,
                deleteReservationError: null,
            };
        case DELETE_RESERVATION_SUCCESS:
            return {
                ...state,
                isDeletingReservation: false,
                deleteReservationError: null,
                deleteReservationResult: action.payload.data,
                //displayTypeInReservations: DisplayTypesInReservationsConstant.LIST,
            };
        case DELETE_RESERVATION_FAILURE:
            return {
                ...state,
                isDeletingReservation: false,
                deleteReservationError: action.payload.message,
            };
        default:
            return state;
}}