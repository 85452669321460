import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE
} from '../../actions/Notifications/getNotificationsActions';

export function getNotificationsReducer(state, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                isGetNotifications: true,
                getNotificationsError: null,
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isGetNotifications: false,
                getNotificationsError: null,
                notificationsUser: action.payload.pageNumber == 1 ? action.payload.data.data.data : state.notificationsUser.concat(action.payload.data.data.data),
                notificationsUserPageNumber: action.payload.pageNumber,
                notificationsUserPageSize: action.payload.pageSize,
                notificationsUserTotalCount: Math.ceil(action.payload.data.data.total / action.payload.pageSize),
                unReadNotificationsUserCount: action.payload.data.unReadNotificationUsers,
            };
        case GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isGetNotifications: false,
                getNotificationsError: action.payload.message,
            };
        default:
            return state;
}}