export const DELETE_RESERVATION_SCHEDULE = 'DELETE_RESERVATION_SCHEDULE';
export const DELETE_RESERVATION_SCHEDULE_SUCCESS = 'DELETE_RESERVATION_SCHEDULE_SUCCESS';
export const DELETE_RESERVATION_SCHEDULE_FAILURE = 'DELETE_RESERVATION_SCHEDULE_FAILURE';

export const deleteReservationSchedule = (reservationScheduleId) => ({
    type: DELETE_RESERVATION_SCHEDULE,
    reservationScheduleId
});

export const deleteReservationScheduleSuccess = (data) => ({
    type: DELETE_RESERVATION_SCHEDULE_SUCCESS,
    payload: { data }
});

export const deleteReservationScheduleFailure = (message) => ({
    type: DELETE_RESERVATION_SCHEDULE_FAILURE,
    payload: { message }
});