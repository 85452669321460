export const UPDATE_RESERVATION_TYPES_IN_RESERVATIONS = 'UPDATE_RESERVATION_TYPES_IN_RESERVATIONS';
export const UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS = 'UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS';
export const UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE = 'UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE';

export const updateReservationTypesInReservations = (reservationType,selectedDate) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATIONS,
    reservationType,
    selectedDate
}); 

export const updateReservationTypesInReservationsSuccess = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS,
    payload: { reservationType }
});

export const updateReservationTypesInReservationsFailure = (message) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE,
    payload: { message }
});