import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInTicketTypesConstant } from "../../constants/TicketTypes/DisplayTypesInTicketTypesConstant";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Loading from "../Shared/Loading/Loading";

function TicketType(props) {
  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getTicketTypesInTicketTypes(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getTicketTypesInTicketTypes();
  }, []);

  useEffect(() => {
    if (
      props.deleteTicketTypeResult !== null &&
      props.deleteTicketTypeResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteTicketTypeResult.message, "success").then(result => {
        props.getTicketTypesInTicketTypes(props.ticketTypesPageNumberInTicketTypes, props.ticketTypesPageSizeInTicketTypes,search);
      });
    }
  }, [props.deleteTicketTypeResult]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.ticketTypesPageNumberInTicketTypes) {
        props.getTicketTypesInTicketTypes(pageNumber, props.ticketTypesPageSizeInTicketTypes, search);
    }
}

function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getTicketTypesInTicketTypes(1, props.ticketTypesPageSizeInTicketTypes, selected.target.value)
}
  return (
    <>
      <div className="customer-data mt-5">
      { props.isGetTicketTypesInTicketTypes && ( <Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
          <TableHeader
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.ticketTypesTotalCountInTicketTypes}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.ticketTypesPageSizeInTicketTypes)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.ticketTypesTotalCountInTicketTypes}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
          </div>

          <div className="table-responsive p-0">
              <table className="table align-middle text-center table-bordered border-bottom m-0">
                <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                  <tr className="fw-bolder text-muted text-center align-middle bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.arabicName}</th>
                    <th className="min-w-125px px-0">{strings.englishName}</th>
                    <th className="min-w-125px px-0">{strings.price}</th>
                    <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                      {strings.action}
                    </th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {console.log( props.ticketTypesInTicketTypes)}
                { props.ticketTypesInTicketTypes !== [] &&
                          props.ticketTypesInTicketTypes.length > 0 &&
                          props.ticketTypesInTicketTypes.map((ticketType, index) => {
                            return (
                              <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketType.nameAr}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketType.nameEn}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{ticketType.price}</h4>
                        </div>
                      </div>
                    </td>
                    <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                      <div className="d-flex justify-content-center">
                        <div
                          className="actions"
                          style={{ backgroundColor: "#e4e6ef" }}
                        >
                          <div className="action1">
                           <a  onClick={() => {
                              props.getEditedTicketType(ticketType.id); 
                             props.updateDisplayTypeInTicketTypes(
                              DisplayTypesInTicketTypesConstant.EDIT
                            )
                            }}>
                           
                              <i className="fas fa-edit edit"></i>
                            </a>
                          
                          </div>
                          <div className="action2">
                            <a
                               onClick={() => {Delete(props,ticketType.id)}}> 
                              <i className="fas fa-trash delete"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
                          })} 
                </tbody>
              </table>
          </div>

          <div className="card-footer">
        {
            props.ticketTypesTotalCountInTicketTypes !== null
            && props.ticketTypesTotalCountInTicketTypes > 0
            && (
                <div className="d-flex flex-stack flex-wrap ">
                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.ticketTypesPageNumberInTicketTypes - 1) * props.ticketTypesPageSizeInTicketTypes) + 1} {strings.to} {(props.ticketTypesPageNumberInTicketTypes * props.ticketTypesPageSizeInTicketTypes) > props.ticketTypesTotalCountInTicketTypes ? props.ticketTypesTotalCountInTicketTypes : (props.ticketTypesPageNumberInTicketTypes * props.ticketTypesPageSizeInTicketTypes)} {strings.of} {props.ticketTypesTotalCountInTicketTypes} {strings.entries}</div>
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activePage={props.ticketTypesPageNumberInTicketTypes}
                        itemsCountPerPage={props.ticketTypesPageSizeInTicketTypes}
                        totalItemsCount={props.ticketTypesTotalCountInTicketTypes}
                        onChange={handlePageButtonChange}
                    />
                </div>
            )}
            </div>
        </div>
      </div>
    </>
  );
}
export default TicketType;
