import {
    ADD_NEW_RESERVATION_TYPE_SETTING,
    ADD_NEW_RESERVATION_TYPE_SETTING_SUCCESS,
    ADD_NEW_RESERVATION_TYPE_SETTING_FAILURE
} from '../../actions/ReservationTypeSettings/addNewReservationTypeSettingActions';

import { DisplayTypesInReservationTypeSettingsConstant } from '../../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant';

export function addNewReservationTypeSettingReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_RESERVATION_TYPE_SETTING:
            return {
                ...state,
                isAddNewReservationTypeSetting: true,
                addNewReservationTypeSettingError: null,
                addNewReservationTypeSettingResult:null,
            };
        case ADD_NEW_RESERVATION_TYPE_SETTING_SUCCESS:
            console.log('new reservationTypeSetting reducer')
            return { 
                ...state,
                isAddNewReservationTypeSetting: false,
                addNewReservationTypeSettingError: null,
                addNewReservationTypeSettingResult: action.payload.data,
                // displayTypeInReservationTypeSettings: DisplayTypesInReservationTypeSettingsConstant.LIST,
            };
        case ADD_NEW_RESERVATION_TYPE_SETTING_FAILURE:
            return {
                ...state,
                isAddNewReservationTypeSetting: false,
                addNewReservationTypeSettingError: action.payload.message,
            };
        default:
            return state;
}}