import {
    UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION,
    UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS,
    UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE
} from '../../actions/Reservations/updateReservationTimesInEditReservationActions';

export function updateReservationTimesInEditReservationReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION:
            return {
                ...state,
                isUpdatingReservationTimesInEditReservation: true,
                updateReservationTimesInEditReservationError: null,
            };
        case UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS:
            return {
                ...state,
                isUpdatingReservationTimesInEditReservation: false,
                reservationTimeInEditReservation: action.payload.selectedTime,
                updateReservationTimesInEditReservationError: null
            };
        case UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE:
            return {
                ...state,
                isUpdatingReservationTimesInEditReservation: false,
                updateReservationTimesInEditReservationError: action.payload
            };
        default:
            return state;
    }
}