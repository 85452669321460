import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import TableHeader from "../../Shared/tableHeader";
import strings from '../../../localizations/strings';
import TableStat from '../../Shared/tablestat';
import { deleteRow, deleteItems } from './Delete';
import { DisplayTypesInRolesConstant } from "../../../constants/Roles/Roles/DisplayTypesInRolesConstant";
import moment from 'moment';
import "moment/locale/ar-sa";
import { urlFile } from "../../../config";
import Pagination from "react-js-pagination";
import { LanguagesConstant } from "../../../constants/LanguagesConstant";
import Loading from "../../Shared/Loading/Loading";

function Role(props) {

    const [Export, setExport] = useState(null),
        [search, setSearch] = useState(null),
        handleTableLength = (selected) => { props.getRoles(1, selected.value, search); },
        handleTableExport = (selected) => { setExport(selected) };


    useEffect(() => {
        props.getRoles();
    }, []);

    useEffect(() => {
        if (
            props.deleteRoleResult !== null &&
            props.deleteRoleResult.isSuccess
        ) {
            Swal.fire(` ${strings.deleted}`, props.deleteRoleResult.message, "success").then(res => {
                props.getRoles(props.rolesPageNumberInRoles, props.rolesPageSizeInRoles, search);
            }
            );
        }
    }, [props.deleteRoleResult]);

    useEffect(() => {
        if (props.updateStatusRoleResult !== null && props.updateStatusRoleResult.isSuccess) {
            Swal.fire(
                ` ${strings.changed}`,
                props.updateStatusRoleResult.message,
                "success"
            ).then(res => {
                props.getRoles(props.rolesPageNumberInRoles, props.rolesPageSizeInRoles, search);
            }
            );
        }
    }, [props.updateStatusRoleResult]);

    useEffect(() => {
        if (
            props.deleteRoleError !== null
        ) {
            Swal.fire(`! ${strings.error}`, props.deleteRoleError, "error");
        }
    }, [props.deleteRoleError]);

    useEffect(() => {
        if (props.updateStatusRoleError !== null) {
            Swal.fire(`! ${strings.error}`, props.updateStatusRoleError, "error");
        }
    }, [props.updateStatusRoleError]);

    function handlePageButtonChange(pageNumber) {
        if (pageNumber != props.rolesPageNumberInRoles) {
            props.getRoles(props.rolesPageNumberInRoles, props.rolesPageSizeInRoles, search);
        }
    }

    function handleinputSearch(selected) {
        setSearch(selected.target.value);
        props.getRoles(1, props.rolesPageSizeInRoles, selected.target.value)
    }

    return (
        <>
            <div className="customer-data mt-5">
            {props.isGetRoles && (<Loading />)}
                <div className="card animate__animated  animate__backInLeft">
                    <div className="card-header d-flex flex-column flex-column-fluid">
                        <TableHeader
                            searchPlaceholder={strings.search}
                            ExportPlaceholder={strings.Export}
                            Length={[...TableStat.Length,{label:strings.all,value:props.rolesTotalCountInRoles}]}
                            Export={TableStat.Export}
                            selectedLength={TableStat.Length.filter(x => x.value == props.rolesPageSizeInRoles)[0]}
                            selectedExport={Export}
                            cardname={strings.show}
                            rowsnumber={props.rolesTotalCountInRoles}
                            handleTableLength={handleTableLength}
                            handleTableExport={handleTableExport}
                            handleinputSearch={handleinputSearch}
                        />
                    </div>

                    <div className="table-responsive p-0">
                        <table className="table align-middle text-center table-bordered border-bottom m-0">
                            <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                                <tr className="fw-bolder text-muted text-center bg-light">
                                    <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                                    <th className="ps-4 min-w-125px px-0">{strings.details}</th>
                                    <th className="min-w-125px px-0">{strings.arabicName}</th>
                                    <th className="min-w-125px px-0">{strings.englishName}</th>
                                    <th className="min-w-150px px-0">{strings.createdDate}</th>
                                    <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                                        {strings.action}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                           
                                {props.rolesInRoles !== [] &&
                                    props.rolesInRoles.length > 0 &&
                                    props.rolesInRoles.map((role, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="ps-4 min-w-125px text-dark fw-bolder text-hover-primary fs-6 px-0">{index + 1}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center">
                                                        <div
                                                            className="actions"
                                                            style={{ backgroundColor: "#fff" }}
                                                        >
                                                            <div className="action1">
                                                                <a
                                                                    onClick={() => {
                                                                        props.getRole(role.id);
                                                                        props.updateDisplayTypeInRoles(DisplayTypesInRolesConstant.Details);
                                                                    }}
                                                                >
                                                                    <i className={strings.getLanguage() === LanguagesConstant.AR ? "fas fa-caret-square-left edit" : "fas fa-caret-square-right edit"}></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="min-w-150px text-dark fw-bolder text-hover-primary fs-6 px-0">{role.nameAr}</td>
                                                <td className="min-w-150px text-dark fw-bolder text-hover-primary fs-6 px-0">{role.nameEn}</td>
                                                <td className="min-w-150px text-dark fw-bolder text-hover-primary fs-6 px-0">{role.insertedDate}</td>
                                                <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                                                    <div className="d-flex justify-content-center">
                                                        <div
                                                            className="actions"
                                                            style={{ backgroundColor: "#e4e6ef" }}
                                                        >
                                                            <div className="action1">
                                                                <a onClick={() => {
                                                                    props.updateDisplayTypeInRoles(DisplayTypesInRolesConstant.Edit)
                                                                    props.getEditedRole(role.id)
                                                                }}>

                                                                    <i className="fas fa-edit edit"></i>
                                                                </a>

                                                            </div>
                                                            <div className="action2">
                                                                <a
                                                                    onClick={() => { deleteRow(props, role.id) }}>
                                                                    <i className="fas fa-trash delete"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>

                        </table>
                    </div>

                    <div className="card-footer">
                        {
                            props.rolesTotalCountInRoles !== null
                            && props.rolesTotalCountInRoles > 0
                            && (
                                <div className="d-flex flex-stack flex-wrap ">
                                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.rolesPageNumberInRoles - 1) * props.rolesPageSizeInRoles) + 1} {strings.to} {(props.rolesPageNumberInRoles * props.rolesPageSizeInRoles) > props.rolesTotalCountInRoles ? props.rolesTotalCountInRoles : (props.rolesPageNumberInRoles * props.rolesPageSizeInRoles)} {strings.of} {props.rolesTotalCountInRoles} {strings.entries}</div>
                                    <Pagination
                                        innerClass="pagination"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activeClass="active"
                                        activePage={props.rolesPageNumberInRoles}
                                        itemsCountPerPage={props.rolesPageSizeInRoles}
                                        totalItemsCount={props.rolesTotalCountInRoles}
                                        onChange={handlePageButtonChange}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>

        </>

    );
}
export default Role
