import {
    EDIT_NOTIFICATION_SETTING,
    EDIT_NOTIFICATION_SETTING_SUCCESS,
    EDIT_NOTIFICATION_SETTING_FAILURE
} from '../../actions/NotificationSettings/editNotificationSettingActions';

import { DisplayTypesInNotificationSettingsConstant } from '../../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant';

export function editNotificationSettingReducer(state, action) {
    switch (action.type) {
        case EDIT_NOTIFICATION_SETTING:
            return {
                ...state,
                isEditingNotificationSetting: true,
                editNotificationSettingError: null,
            };
        case EDIT_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                isEditingNotificationSetting: false,
                editNotificationSettingError: null,
                editNotificationSettingResult: action.payload.data,
               // displayTypeInNotificationSettings: DisplayTypesInNotificationSettingsConstant.LIST,
            };
        case EDIT_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                isEditingNotificationSetting: false,
                editNotificationSettingError: action.payload.message,
            };
        default:
            return state;
}}