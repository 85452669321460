import React, { useEffect, useState } from "react";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import Select from 'react-select';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInTicketOptionsConstant } from "../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant";
import { StatusCodeConstant } from "../../constants/StatusCodeConstant";
import Loading from "../Shared/Loading/Loading";

function Add(props) {

  const [reservationTypeError, setReservationTypeError] = useState(null);

  useEffect(() => {
    props.getReservationTypesInTicketOptions();
  }, [])

  useEffect(() => {
    if (
      props.addNewTicketOptionResult !== null &&
      props.addNewTicketOptionResult.isSuccess
    ) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewTicketOptionResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInTicketOptions(
          DisplayTypesInTicketOptionsConstant.LIST
        );
      });
    }
  }, [props.addNewTicketOptionResult]);

  useEffect(() => {
    if (
      props.addNewTicketOptionError !== null
    ) {
      Swal.fire(
        //icon: 'error',
        "Oops...",
        props.addNewTicketOptionError,
        "error"
      );
    }
  }, [props.addNewTicketOptionError]);

  function handleReservationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTypeError(strings.pleaseSelectReservationType)
    } else {
      setReservationTypeError(null)
    }
    props.updateReservationTypesInTicketOptions(selectedOption);
  }

  return (
    <>
      {props.isAddNewTicketOption && (<Loading />)}
      {!props.isAddNewTicketOption &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.addTicketOption}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                initialValues={
                  {
                    ticketOptionNameAr: "",
                    ticketOptionNameEn: "",
                    price: "",
                    status: true,
                    isInput: false
                  }
                }
                validationSchema={Yup.object().shape({
                  ticketOptionNameAr: Yup.string().required(
                    strings.nameArRequired
                  ),
                  ticketOptionNameEn: Yup.string().required(
                    strings.nameEnRequired
                  ),
                  price: Yup.string().required(
                    strings.priceRequired
                  ),
                })}
                onSubmit={(fields) => {
                  if (props.reservationTypeInTicketOptions === null) {
                    setReservationTypeError(strings.pleaseSelectReservationType);
                  } else {
                  const ticketOption = {
                    "reservationTypeId": props.reservationTypeInTicketOptions.value,
                    "nameAr": fields.ticketOptionNameAr,
                    "nameEn": fields.ticketOptionNameEn,
                    "price": fields.price,
                    "isActive": fields.status,
                    "isInput": fields.isInput,
                  }
                  props.addNewTicketOption(ticketOption);
                }
                }}
                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.reservationType}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.reservationTypesInTicketOptions}
                            placeholder={strings.selectReservationType}
                            value={props.reservationTypeInTicketOptions}
                            onChange={handleReservationTypeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetReservationTypesInTicketOptions}
                            className={reservationTypeError != null ? "is-invalid" : ""}
                          />
                          {reservationTypeError && (
                            <div className="invalid-feedback block">
                              {reservationTypeError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.arabicName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="ticketOptionNameAr"
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.ticketOptionNameAr &&
                                touched.ticketOptionNameAr
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={`${strings.enterNameAr} *`}
                          />
                          <ErrorMessage
                            name="ticketOptionNameAr"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.englishName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="ticketOptionNameEn"
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.ticketOptionNameEn &&
                                touched.ticketOptionNameEn
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={`${strings.enterNameEn} *`}
                          />
                          <ErrorMessage
                            name="ticketOptionNameEn"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.price}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="price"
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.price &&
                                touched.price
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={`${strings.enterPrice} *`}
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12" style={{ marginTop: "4rem" }}>
                        <div className="d-flex align-items-center">
                          <div className="form-check">
                            <Field type="checkbox" id="isInput" className="form-check-input" name="isInput" />
                            <label title="" htmlFor="isInput" className="form-check-label">{strings.showInput}</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="card-footer d-flex justify-content-between p-3">
                      <div>
                        <button
                          type="submit"
                          className="btn fs-6"
                          style={{
                            backgroundColor: "#009ef7",
                            borderColor: "#009ef7",
                            color: "white",
                            borderRadius: "0.75rem",
                          }}
                          id="kt_account_profile_details_submit"
                        //	onClick={done}
                        >
                          {strings.save}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Add;
