export const GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE';

export const getNotificationTypesInNotificationSettings = (notificationSettingId = null) => ({
    type: GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS,
    notificationSettingId
});

export const getNotificationTypesInNotificationSettingsSuccess = (notificationTypes) => ({
    type: GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationTypes }
});

export const getNotificationTypesInNotificationSettingsFailure = (message) => ({
    type: GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: { message }
});