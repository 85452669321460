import {
    UPDATE_STATUS_ROLE_USER,
    UPDATE_STATUS_ROLE_USER_SUCCESS,
    UPDATE_STATUS_ROLE_USER_FAILURE
} from '../../../actions/Roles/RoleUsers/updateStatusRoleUserActions';

export function updateStatusRoleUserReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_ROLE_USER:
            return {
                ...state,
                isUpdatingStatusRoleUser: true,
                updateStatusRoleUserError: null,
            };
        case UPDATE_STATUS_ROLE_USER_SUCCESS:
            return {
                ...state,
                isUpdatingStatusRoleUser: false,
                updateStatusRoleUserError: null,
                updateStatusRoleUserResult: action.payload.data,
                roleUsersInRoleUsers: state.roleUsersInRoleUsers.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_ROLE_USER_FAILURE:
            return {
                ...state,
                isUpdatingStatusRoleUser: false,
                updateStatusRoleUserError: action.payload.message,
            };
        default:
            return state;
}}