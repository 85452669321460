export const GET_USERS_IN_REQUESTS = 'GET_USERS_IN_REQUESTS';
export const GET_USERS_IN_REQUESTS_SUCCESS = 'GET_USERS_IN_REQUESTS_SUCCESS';
export const GET_USERS_IN_REQUESTS_FAILURE = 'GET_USERS_IN_REQUESTS_FAILURE';

export const getUsersInRequests = () => ({
    type: GET_USERS_IN_REQUESTS,
}); 

export const getUsersInRequestsSuccess = (users) => ({
    type: GET_USERS_IN_REQUESTS_SUCCESS,
    payload: { users }
});

export const getUsersInRequestsFailure = (message) => ({
    type: GET_USERS_IN_REQUESTS_FAILURE,
    payload: { message }
});