export const GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_FAILURE';

export const getNotificationSettingsInNotificationSettings = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS,
    pageNumber,
    pageSize,
    search
});

export const getNotificationSettingsInNotificationSettingsSuccess = (notificationSettings, pageNumber, pageSize) => ({
    type: GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationSettings, pageNumber, pageSize }
});

export const getNotificationSettingsInNotificationSettingsFailure = (message) => ({
    type: GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: { message }
});