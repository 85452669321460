import reduceReducers from 'reduce-reducers';
import moment from 'moment';
import "moment/locale/ar-sa";

//login
import { loginUserReducer } from './Account/loginUserReducer';
import { checkIsUserLoggedInReducer } from './Account/checkIsUserLoggedInReducer';
import { getUserSideBarReducer } from './Account/getUserSideBarReducer';

//pausedTime
import { updateDisplayTypeInPausedTimesReducer } from './PausedTimes/updateDisplayTypeInPausedTimesReducer';
import { DisplayTypesInPausedTimesConstant } from '../constants/PausedTimes/DisplayTypesInPausedTimesConstant';
import { getPausedTimesInPausedTimesReducer } from './PausedTimes/getPausedTimesInPausedTimesReducer';
import { addNewPausedTimeReducer } from './PausedTimes/addNewPausedTimeReducer';
import { editPausedTimeReducer } from './PausedTimes/editPausedTimeReducer';
import { getEditedPausedTimeReducer } from './PausedTimes/getEditedPausedTimeReducer';
import { deletePausedTimeReducer } from './PausedTimes/deletePausedTimeReducer';
import { updateStatusPausedTimeReducer } from './PausedTimes/updateStatusPausedTimeReducer';

//smsSettings
import { updateDisplayTypeInSmsSettingsReducer } from './SmsSettings/updateDisplayTypeInSmsSettingsReducer';
import { DisplayTypesInSmsSettingsConstant } from '../constants/SmsSettings/DisplayTypesInSmsSettingsConstant';
import { getSmsSettingsInSmsSettingsReducer } from './SmsSettings/getSmsSettingsInSmsSettingsReducer';
import { addNewSmsSettingReducer } from './SmsSettings/addNewSmsSettingReducer';
import { editSmsSettingReducer } from './SmsSettings/editSmsSettingReducer';
import { getEditedSmsSettingReducer } from './SmsSettings/getEditedSmsSettingReducer';
import { deleteSmsSettingReducer } from './SmsSettings/deleteSmsSettingReducer';
import { updateStatusSmsSettingReducer } from './SmsSettings/updateStatusSmsSettingReducer';
import { getSmsTypesInSmsSettingsReducer } from './SmsSettings/getSmsTypesInSmsSettingsReducer';
import { updateSmsTypesInSmsSettingsReducer } from './SmsSettings/updateSmsTypesInSmsSettingsReducer';


//notificationSettings
import { updateDisplayTypeInNotificationSettingsReducer } from './NotificationSettings/updateDisplayTypeInNotificationSettingsReducer';
import { DisplayTypesInNotificationSettingsConstant } from '../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant';
import { getNotificationSettingsInNotificationSettingsReducer } from './NotificationSettings/getNotificationSettingsInNotificationSettingsReducer';
import { addNewNotificationSettingReducer } from './NotificationSettings/addNewNotificationSettingReducer';
import { editNotificationSettingReducer } from './NotificationSettings/editNotificationSettingReducer';
import { getEditedNotificationSettingReducer } from './NotificationSettings/getEditedNotificationSettingReducer';
import { deleteNotificationSettingReducer } from './NotificationSettings/deleteNotificationSettingReducer';
import { updateStatusNotificationSettingReducer } from './NotificationSettings/updateStatusNotificationSettingReducer';
import { getNotificationTypesInNotificationSettingsReducer } from './NotificationSettings/getNotificationTypesInNotificationSettingsReducer';
import { getNotificationRolesInNotificationSettingsReducer } from './NotificationSettings/getNotificationRolesInNotificationSettingsReducer';
import { updateNotificationTypesInNotificationSettingsReducer } from './NotificationSettings/updateNotificationTypesInNotificationSettingsReducer';
import { updateNotificationRolesInNotificationSettingsReducer } from './NotificationSettings/updateNotificationRolesInNotificationSettingsReducer';

//discount
import { updateDisplayTypeInDiscountsReducer } from './Discounts/updateDisplayTypeInDiscountsReducer';
import { DisplayTypesInDiscountsConstant } from '../constants/Discounts/DisplayTypesInDiscountsConstant';
import { getDiscountsInDiscountsReducer } from './Discounts/getDiscountsInDiscountsReducer';
import { addNewDiscountReducer } from './Discounts/addNewDiscountReducer';
import { editDiscountReducer } from './Discounts/editDiscountReducer';
import { getEditedDiscountReducer } from './Discounts/getEditedDiscountReducer';
import { deleteDiscountReducer } from './Discounts/deleteDiscountReducer';
import { updateStatusDiscountReducer } from './Discounts/updateStatusDiscountReducer';

//reservationSchedule
import { updateDisplayTypeInReservationSchedulesReducer } from './ReservationSchedules/updateDisplayTypeInReservationSchedulesReducer';
import { DisplayTypesInReservationSchedulesConstant } from '../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant';
import { getReservationSchedulesInReservationSchedulesReducer } from './ReservationSchedules/getReservationSchedulesInReservationSchedulesReducer';
import { addNewReservationScheduleReducer } from './ReservationSchedules/addNewReservationScheduleReducer';
import { editReservationScheduleReducer } from './ReservationSchedules/editReservationScheduleReducer';
import { getEditedReservationScheduleReducer } from './ReservationSchedules/getEditedReservationScheduleReducer';
import { deleteReservationScheduleReducer } from './ReservationSchedules/deleteReservationScheduleReducer';
import { updateStatusReservationScheduleReducer } from './ReservationSchedules/updateStatusReservationScheduleReducer';
import { getDaysInReservationSchedulesReducer } from './ReservationSchedules/getDaysInReservationSchedulesReducer';
import { updateDaysInReservationSchedulesReducer } from './ReservationSchedules/updateDaysInReservationSchedulesReducer';

//user
import { updateDisplayTypeInUsersReducer } from './Users/updateDisplayTypeInUsersReducer';
import { DisplayTypesInUsersConstant } from '../constants/Users/DisplayTypesInUsersConstant';
import { getUsersInUsersReducer } from './Users/getUsersInUsersReducer';
import { getEditedUserReducer } from './Users/getEditedUserReducer';
import { deleteUserReducer } from './Users/deleteUserReducer';
import { updateStatusUserReducer } from './Users/updateStatusUserReducer';
import { mobileVerifiedUserReducer } from './Users/mobileVerifiedUserReducer';
import { addNewUserReducer } from './Users/addNewUserReducer';
import { editUserReducer } from './Users/editUserReducer';
import { checkEmailAvailabilityInUsersReducer } from './Users/checkEmailAvailabilityInUsersReducer';
import { checkMobileAvailabilityInUsersReducer } from './Users/checkMobileAvailabilityInUsersReducer';

//reservation
import { updateDisplayTypeInReservationsReducer } from './Reservations/updateDisplayTypeInReservationsReducer';
import { DisplayTypesInReservationsConstant } from '../constants/Reservations/DisplayTypesInReservationsConstant';
import { getReservationsInReservationsReducer } from './Reservations/getReservationsInReservationsReducer';
import { getEditedReservationReducer } from './Reservations/getEditedReservationReducer';
import { deleteReservationReducer } from './Reservations/deleteReservationReducer';
import { updatePaymentStatusReservationReducer } from './Reservations/updatePaymentStatusReservationReducer';
import { getReservationTypesInReservationsReducer } from './Reservations/getReservationTypesInReservationsReducer';
import { getUsersInReservationsReducer } from './Reservations/getUsersInReservationsReducer';
import { updateReservationTypesInReservationsReducer } from './Reservations/updateReservationTypesInReservationsReducer';
import { updateUsersInReservationsReducer } from './Reservations/updateUsersInReservationsReducer';
import { updateSelectedDateInReservationsReducer } from './Reservations/updateSelectedDateInReservationsReducer';
import { getReservationTimesInReservationsReducer } from './Reservations/getReservationTimesInReservationsReducer';
import { updateReservationTimesInReservationsReducer } from './Reservations/updateReservationTimesInReservationsReducers';
import { addNewReservationReducer } from './Reservations/addNewReservationReducer';
import { getReservationTimesInEditReservationReducer } from './Reservations/getReservationTimesInEditReservationReducer';
import { updateReservationTimesInEditReservationReducer } from './Reservations/updateReservationTimesInEditReservationReducers';
import { editReservationReducer } from './Reservations/editReservationReducer';
import { cancelReservationReducer } from './Reservations/cancelReservationReducer';
import { getReservationDetailsReducer } from './Reservations/getReservationDetailsReducer';
import { updateAttendenceStatusReservationReducer } from './Reservations/updateAttendenceStatusReservationReducer';
import { getAttendedReservationsInReservationsReducer } from "./Reservations/getAttendedReservationsInReservationsReducer";
import { getCancelledReservationsInReservationsReducer } from "./Reservations/getCancelledReservationsInReservationsReducer";
import { getUnpaidReservationsInReservationsReducer } from "./Reservations/getUnpaidReservationsInReservationsReducer";
import { updateDisplayTypeInAttendedReservationsReducer } from "./Reservations/updateDisplayTypeInAttendedReservationsReducer";
import { updateDisplayTypeInCancelledReservationsReducer } from "./Reservations/updateDisplayTypeInCancelledReservationsReducer";
import { updateDisplayTypeInUnpaidReservationsReducer } from "./Reservations/updateDisplayTypeInUnpaidReservationsReducer";
import { DisplayTypesInAttendedReservationsConstant } from '../constants/Reservations/DisplayTypesInAttendedReservationsConstant';
import { DisplayTypesInCancelledReservationsConstant } from '../constants/Reservations/DisplayTypesInCancelledReservationsConstant';
import { DisplayTypesInUnpaidReservationsConstant } from '../constants/Reservations/DisplayTypesInUnpaidReservationsConstant';
import { DisplayTypesInRequestsConstant } from '../constants/Reservations/DisplayTypesInRequestsConstant';
import { deleteCancelledReservationReducer } from './Reservations/deleteCancelledReservationReducer';
import { updateReturnMoneyStatusReservationReducer } from './Reservations/updateReturnMoneyStatusReservationReducer';
import { getRequestsInReservationsReducer } from './Reservations/getRequestsInReservationsReducer';
import { updateDisplayTypeInRequestsReducer } from './Reservations/updateDisplayTypeInRequestsReducer';
import { updateRequestStatusReservationReducer } from './Reservations/updateRequestStatusReservationReducer';
import { getTicketTypesInReservationsReducer } from './Reservations/getTicketTypesInReservationsReducer';
import { getTicketOptionsInReservationsReducer } from './Reservations/getTicketOptionsInReservationsReducer';
import { clearReservationDetailsReducer } from './Reservations/clearReservationDetailsReducer';

//notifications
import { changeNotificationIsReadReducer } from './Notifications/changeNotificationIsReadReducer';
import { getNotificationsReducer } from './Notifications/getNotificationsReducer';
import { markAllAsReadReducer } from './Notifications/markAllAsReadReducer';
import { updateNotificationsReducer } from './Notifications/updateNotificationsReducer';

//dashboard
import { getChartsDataReducer } from './Dashboard/getChartsDataReducer';

//changePassword
import { changePasswordReducer } from './Account/changePasswordReducer';

//reservation types
import { updateDisplayTypeInReservationTypesReducer } from './ReservationTypes/updateDisplayTypeInReservationTypesReducer';
import { addNewReservationTypeReducer } from './ReservationTypes/addNewReservationTypeReducer';
import { getReservationTypesInReservationTypesReducer } from './ReservationTypes/getReservationTypesInReservationTypesReducer';
import { getEditedReservationTypeReducer } from './ReservationTypes/getEditedReservationTypeReducer';
import { editReservationTypeReducer } from './ReservationTypes/editReservationTypeReducer';
import { deleteReservationTypeReducer } from './ReservationTypes/deleteReservationTypeReducer';
import { updateStatusInReservationTypesReducer } from './ReservationTypes/updateStatusInReservationTypesReducer';
import { DisplayTypesInReservationTypesConstant } from '../constants/ReservationTypes/DisplayTypesInReservationTypesConstant';

//ticket types
import { updateDisplayTypeInTicketTypesReducer } from './TicketTypes/updateDisplayTypeInTicketTypesReducer';
import { addNewTicketTypeReducer } from './TicketTypes/addNewTicketTypeReducer';
import { getTicketTypesInTicketTypesReducer } from './TicketTypes/getTicketTypesInTicketTypesReducer';
import { getEditedTicketTypeReducer } from './TicketTypes/getEditedTicketTypeReducer';
import { editTicketTypeReducer } from './TicketTypes/editTicketTypeReducer';
import { deleteTicketTypeReducer } from './TicketTypes/deleteTicketTypeReducer';
import { DisplayTypesInTicketTypesConstant } from '../constants/TicketTypes/DisplayTypesInTicketTypesConstant';

//ticket options
import { updateDisplayTypeInTicketOptionsReducer } from './TicketOptions/updateDisplayTypeInTicketOptionsReducer';
import { addNewTicketOptionReducer } from './TicketOptions/addNewTicketOptionReducer';
import { getTicketOptionsInTicketOptionsReducer } from './TicketOptions/getTicketOptionsInTicketOptionsReducer';
import { getEditedTicketOptionReducer } from './TicketOptions/getEditedTicketOptionReducer';
import { editTicketOptionReducer } from './TicketOptions/editTicketOptionReducer';
import { deleteTicketOptionReducer } from './TicketOptions/deleteTicketOptionReducer';
import { getReservationTypesInTicketOptionsReducer } from './TicketOptions/getReservationTypesInTicketOptionsReducer';
import { updateReservationTypesInTicketOptionsReducer } from './TicketOptions/updateReservationTypesInTicketOptionsReducer';
import { DisplayTypesInTicketOptionsConstant } from '../constants/TicketOptions/DisplayTypesInTicketOptionsConstant';


//reservation type settings
import { updateDisplayTypeInReservationTypeSettingsReducer } from './ReservationTypeSettings/updateDisplayTypeInReservationTypeSettingsReducer';
import { addNewReservationTypeSettingReducer } from './ReservationTypeSettings/addNewReservationTypeSettingReducer';
import { getReservationTypeSettingsInReservationTypeSettingsReducer } from './ReservationTypeSettings/getReservationTypeSettingsInReservationTypeSettingsReducer';
import { getEditedReservationTypeSettingReducer } from './ReservationTypeSettings/getEditedReservationTypeSettingReducer';
import { editReservationTypeSettingReducer } from './ReservationTypeSettings/editReservationTypeSettingReducer';
import { deleteReservationTypeSettingReducer } from './ReservationTypeSettings/deleteReservationTypeSettingReducer';
import { getReservationTypesInReservationTypeSettingsReducer } from './ReservationTypeSettings/getReservationTypesInReservationTypeSettingsReducer';
import { updateReservationTypesInReservationTypeSettingsReducer } from './ReservationTypeSettings/updateReservationTypesInReservationTypeSettingsReducer';
import { DisplayTypesInReservationTypeSettingsConstant } from '../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant';

//roles
import { createNewRoleReducer } from './Roles/Roles/createNewRoleReducer';
import { deleteRoleReducer } from './Roles/Roles/deleteRoleReducer';
import { editRoleReducer } from './Roles/Roles/editRoleReducer';
import { getEditedRoleReducer } from './Roles/Roles/getEditedRoleReducer';
import { getRoleReducer } from './Roles/Roles/getRoleReducer';
import { getRolesReducer } from './Roles/Roles/getRolesReducer';
import { updateDisplayTypeInRolesReducer } from './Roles/Roles/updateDisplayTypeInRolesReducer';
import { updateStatusRoleReducer } from './Roles/Roles/updateStatusRoleReducer';
import { checkRoleNameArAvailabilityInRolesReducer } from './Roles/Roles/checkRoleNameArAvailabilityInRolesReducer';
import { checkRoleNameEnAvailabilityInRolesReducer } from './Roles/Roles/checkRoleNameEnAvailabilityInRolesReducer';
import { DisplayTypesInRolesConstant } from '../constants/Roles/Roles/DisplayTypesInRolesConstant';

//roleUsers
import { createNewRoleUserReducer } from './Roles/RoleUsers/createNewRoleUserReducer';
import { deleteRoleUserReducer } from './Roles/RoleUsers/deleteRoleUserReducer';
import { editRoleUserReducer } from './Roles/RoleUsers/editRoleUserReducer';
import { getEditedRoleUserReducer } from './Roles/RoleUsers/getEditedRoleUserReducer';
import { getRoleUsersReducer } from './Roles/RoleUsers/getRoleUsersReducer';
import { updateDisplayTypeInRoleUsersReducer } from './Roles/RoleUsers/updateDisplayTypeInRoleUsersReducer';
import { updateStatusRoleUserReducer } from './Roles/RoleUsers/updateStatusRoleUserReducer';
import { checkEmailAvailabilityInRoleUsersReducer } from './Roles/RoleUsers/checkEmailAvailabilityInRoleUsersReducer';
import { DisplayTypesInRoleUsersConstant } from '../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant';

//roleUsers
import { createNewRoleScreenReducer } from './Roles/RoleScreens/createNewRoleScreenReducer';
import { deleteRoleScreenReducer } from './Roles/RoleScreens/deleteRoleScreenReducer';
import { editRoleScreenReducer } from './Roles/RoleScreens/editRoleScreenReducer';
import { getEditedRoleScreenReducer } from './Roles/RoleScreens/getEditedRoleScreenReducer';
import { getRoleScreensReducer } from './Roles/RoleScreens/getRoleScreensReducer';
import { getScreensInRoleScreensReducer } from './Roles/RoleScreens/getScreensInRoleScreensReducer';
import { updateDisplayTypeInRoleScreensReducer } from './Roles/RoleScreens/updateDisplayTypeInRoleScreensReducer';
import { updateScreensInRoleScreensReducer } from './Roles/RoleScreens/updateScreensInRoleScreensReducer';
import { updateStatusRoleScreenReducer } from './Roles/RoleScreens/updateStatusRoleScreenReducer';
import { DisplayTypesInRoleScreensConstant } from '../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant';


//reports
import { updateDisplayTypeInReportsReducer } from './Reports/updateDisplayTypeInReportsReducer';
import { getReservationReportReducer } from './Reports/getReservationReportReducer';
import { getRequestReportReducer } from './Reports/getRequestReportReducer';
import { getCompanyReservationReportReducer } from './Reports/getCompanyReservationReportReducer';
import { DisplayTypesInReportsConstant } from '../constants/Reports/DisplayTypesInReportsConstant';

//news
import { updateDisplayTypeInNewsReducer } from './News/updateDisplayTypeInNewsReducer';
import { addNewNewReducer } from './News/addNewNewReducer';
import { getNewsInNewsReducer } from './News/getNewsInNewsReducer';
import { getEditedNewReducer } from './News/getEditedNewReducer';
import { editNewReducer } from './News/editNewReducer';
import { deleteNewReducer } from './News/deleteNewReducer';
import { updateStatusNewReducer } from './News/updateStatusNewReducer';
import { DisplayTypesInNewsConstant } from '../constants/News/DisplayTypesInNewsConstant';

//requests
import { getFromTimesInRequestsReducer } from './Requests/getFromTimesInRequestsReducer';
import { updateFromTimesInRequestsReducer } from './Requests/updateFromTimesInRequestsReducer';
import { getToTimesInRequestsReducer } from './Requests/getToTimesInRequestsReducer';
import { updateToTimesInRequestsReducer } from './Requests/updateToTimesInRequestsReducer';
import { approveRequestReducer } from './Requests/approveRequestReducer';
import { getEditedRequestReducer } from './Requests/getEditedRequestReducer';
import { getRequestDetailsReducer } from './Requests/getRequestDetailsReducer';
import { deleteRequestReducer } from './Requests/deleteRequestReducer';
import { payInRequestReducer } from './Requests/payInRequestReducer';
import { addNewRequestReducer } from './Requests/addNewRequestReducer';
import { editRequestReducer } from './Requests/editRequestReducer';
import { getSchoolTourRangesInRequestsReducer } from './Requests/getSchoolTourRangesInRequestsReducer';
import { getReservationTypesInRequestsReducer } from './Requests/getReservationTypesInRequestsReducer';
import { getTicketOptionsInRequestsReducer } from './Requests/getTicketOptionsInRequestsReducer';
import { getUsersInRequestsReducer } from './Requests/getUsersInRequestsReducer';
import { updateReservationTypesInRequestsReducer } from './Requests/updateReservationTypesInRequestsReducer';
//import { updateSelectedDateInRequestsReducer } from './Requests/updateSelectedDateInRequestsReducer';
import { updateUsersInRequestsReducer } from './Requests/updateUsersInRequestsReducer';

//galleries
import { updateDisplayTypeInGalleriesReducer } from './Galleries/updateDisplayTypeInGalleriesReducer';
import { addNewGalleryReducer } from './Galleries/addNewGalleryReducer';
import { getGalleriesInGalleriesReducer } from './Galleries/getGalleriesInGalleriesReducer';
import { getEditedGalleryReducer } from './Galleries/getEditedGalleryReducer';
import { editGalleryReducer } from './Galleries/editGalleryReducer';
import { deleteGalleryReducer } from './Galleries/deleteGalleryReducer';
import { updateStatusGalleryReducer } from './Galleries/updateStatusGalleryReducer';
import { DisplayTypesInGalleriesConstant } from '../constants/Galleries/DisplayTypesInGalleriesConstant';

//school tour range
import { addNewSchoolTourRangeReducer } from './SchoolTourRanges/addNewSchoolTourRangeReducer';
import { editSchoolTourRangeReducer } from './SchoolTourRanges/editSchoolTourRangeReducer';
import { getEditedSchoolTourRangeReducer } from './SchoolTourRanges/getEditedSchoolTourRangeReducer';
import { getSchoolTourRangesReducer } from './SchoolTourRanges/getSchoolTourRangesReducer';
import { deleteSchoolTourRangeReducer } from './SchoolTourRanges/deleteSchoolTourRangeReducer';
import { updateDisplayTypeInSchoolTourRangesReducer } from './SchoolTourRanges/updateDisplayTypeInSchoolTourRangesReducer';
import { DisplayTypesInSchoolTourRangesConstant } from '../constants/SchoolTourRanges/DisplayTypesInSchoolTourRangesConstant';

//companies
import { addNewCompanyReducer } from './Companies/addNewCompanyReducer';
import { deleteCompanyReducer } from './Companies/deleteCompanyReducer';
import { getEditedCompanyReducer } from './Companies/getEditedCompanyReducer';
import { editCompanyReducer } from './Companies/editCompanyReducer';
import { getCompaniesInCompaniesReducer } from './Companies/getCompaniesInCompaniesReducer';
import { updateDisplayTypeInCompaniesReducer } from './Companies/updateDisplayTypeInCompaniesReducer';
import { updateStatusCompanyReducer } from './Companies/updateStatusCompanyReducer';
import { DisplayTypesInCompaniesConstant } from '../constants/Companies/DisplayTypesInCompaniesConstant';

//company reservation
import { addNewCompanyReservationReducer } from './CompanyReservations/addNewCompanyReservationReducer';
import { deleteCompanyReservationReducer } from './CompanyReservations/deleteCompanyReservationReducer';
import { editCompanyReservationReducer } from './CompanyReservations/editCompanyReservationReducer';
import { getCompaniesInCompanyReservationsReducer } from './CompanyReservations/getCompaniesInCompanyReservationsReducer';
import { getCompanyReservationDetailsReducer } from './CompanyReservations/getCompanyReservationDetailsReducer';
import { getCompanyReservationsInCompanyReservationsReducer } from './CompanyReservations/getCompanyReservationsInCompanyReservationsReducer';
import { getCompanyReservationTimesInCompanyReservationsReducer } from './CompanyReservations/getCompanyReservationTimesInCompanyReservationsReducer';
import { getEditedCompanyReservationReducer } from './CompanyReservations/getEditedCompanyReservationReducer';
import { getReservationTypesInCompanyReservationsReducer } from './CompanyReservations/getReservationTypesInCompanyReservationsReducer';
import { getTicketOptionsInCompanyReservationsReducer } from './CompanyReservations/getTicketOptionsInCompanyReservationsReducer';
import { getTicketTypesInCompanyReservationsReducer } from './CompanyReservations/getTicketTypesInCompanyReservationsReducer';
import { updateCompaniesInCompanyReservationsReducer } from './CompanyReservations/updateCompaniesInCompanyReservationsReducer';
import { updateCompanyReservationTimesInCompanyReservationsReducer } from './CompanyReservations/updateCompanyReservationTimesInCompanyReservationsReducers';
import { updateReservationTypesInCompanyReservationsReducer } from './CompanyReservations/updateReservationTypesInCompanyReservationsReducer';
import { updateSelectedDateInCompanyReservationsReducer } from './CompanyReservations/updateSelectedDateInCompanyReservationsReducer';
import { updateDisplayTypeInCompanyReservationsReducer } from './CompanyReservations/updateDisplayTypeInCompanyReservationsReducer';
import { getToTimesInCompanyReservationsReducer } from './CompanyReservations/getToTimesInCompanyReservationsReducer';
import { updateToTimesInCompanyReservationsReducer } from './CompanyReservations/updateToTimesInCompanyReservationsReducer';
import { changeIsPaidInCompanyReservationsReducer } from './CompanyReservations/changeIsPaidInCompanyReservationsReducer';
import { DisplayTypesInCompanyReservationsConstant } from '../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant';

//reservation details with code
import { getReservationDetailsWithCodeReducer } from './ReservationDetails/getReservationDetailsWithCodeReducer'


const initialState = {
    //login
    isInCheckIsUserLoggedIn: true,
    checkIsUserLoggedInError: null,

    isLoggedUser: false,
    getLoggedUserError: null,
    isUserLoggedIn: false,
    loggedUserResult: null,

    isGetUserSideBar: false,
    getUserSideBarError: null,
    userSideBar: [],

    //pausedTime
    isUpdatingDisplayTypeInPausedTimes: false,
    displayTypeInPausedTimes: DisplayTypesInPausedTimesConstant.LIST,
    updateDisplayTypeErrorInPausedTimes: null,

    isAddNewPausedTime: false,
    addNewPausedTimeError: null,
    addNewPausedTimeResult: null,

    isDeletingPausedTime: false,
    deletePausedTimeError: null,
    deletePausedTimeResult: null,

    isEditingPausedTime: false,
    editPausedTimeError: null,
    editPausedTimeResult: null,

    isGetPausedTimesInPausedTimes: false,
    getPausedTimesInPausedTimesError: null,
    pausedTimesInPausedTimes: [],
    pausedTimesPageNumberInPausedTimes: 1,
    pausedTimesPageSizeInPausedTimes: 10,
    pausedTimesTotalCountInPausedTimes: 0,

    isGetEditedPausedTime: false,
    getEditedPausedTimeError: null,
    editedPausedTimeInPausedTimes: null,

    isUpdatingStatusPausedTime: false,
    updateStatusPausedTimeError: null,
    updateStatusPausedTimeResult: null,

    //smsSetting
    isUpdatingDisplayTypeInSmsSettings: false,
    displayTypeInSmsSettings: DisplayTypesInSmsSettingsConstant.LIST,
    updateDisplayTypeErrorInSmsSettings: null,

    isAddNewSmsSetting: false,
    addNewSmsSettingError: null,
    addNewSmsSettingResult: null,

    isDeletingSmsSetting: false,
    deleteSmsSettingError: null,
    deleteSmsSettingResult: null,

    isEditingSmsSetting: false,
    editSmsSettingError: null,
    editSmsSettingResult: null,

    isGetSmsSettingsInSmsSettings: false,
    getSmsSettingsInSmsSettingsError: null,
    smsSettingsInSmsSettings: [],
    smsSettingsPageNumberInSmsSettings: 1,
    smsSettingsPageSizeInSmsSettings: 10,
    smsSettingsTotalCountInSmsSettings: 0,

    isGetEditedSmsSetting: false,
    getEditedSmsSettingError: null,
    editedSmsSettingInSmsSettings: null,

    isUpdatingStatusSmsSetting: false,
    updateStatusSmsSettingError: null,
    updateStatusSmsSettingResult: null,

    isGetSmsTypesInSmsSettings: false,
    getSmsTypesInSmsSettingsError: null,
    smsTypesInSmsSettings: [],

    isUpdateSmsTypesInSmsSettings: false,
    updateSmsTypesInSmsSettingsError: null,
    smsTypeInSmsSettings: null,



    //notificationSetting
    isUpdatingDisplayTypeInNotificationSettings: false,
    displayTypeInNotificationSettings: DisplayTypesInNotificationSettingsConstant.LIST,
    updateDisplayTypeErrorInNotificationSettings: null,

    isAddNewNotificationSetting: false,
    addNewNotificationSettingError: null,
    addNewNotificationSettingResult: null,

    isDeletingNotificationSetting: false,
    deleteNotificationSettingError: null,
    deleteNotificationSettingResult: null,

    isEditingNotificationSetting: false,
    editNotificationSettingError: null,
    editNotificationSettingResult: null,

    isGetNotificationSettingsInNotificationSettings: false,
    getNotificationSettingsInNotificationSettingsError: null,
    notificationSettingsInNotificationSettings: [],
    notificationSettingsPageNumberInNotificationSettings: 1,
    notificationSettingsPageSizeInNotificationSettings: 10,
    notificationSettingsTotalCountInNotificationSettings: 0,

    isGetEditedNotificationSetting: false,
    getEditedNotificationSettingError: null,
    editedNotificationSettingInNotificationSettings: null,

    isUpdatingStatusNotificationSetting: false,
    updateStatusNotificationSettingError: null,
    updateStatusNotificationSettingResult: null,

    isGetNotificationTypesInNotificationSettings: false,
    getNotificationTypesInNotificationSettingsError: null,
    notificationTypesInNotificationSettings: [],

    isUpdateNotificationTypesInNotificationSettings: false,
    updateNotificationTypesInNotificationSettingsError: null,
    notificationTypeInNotificationSettings: null,

    isGetNotificationRolesInNotificationSettings: false,
    getNotificationRolesInNotificationSettingsError: null,
    notificationRolesInNotificationSettings: [],

    isUpdateNotificationRolesInNotificationSettings: false,
    updateNotificationRolesInNotificationSettingsError: null,
    notificationRoleInNotificationSettings: [],

    //discount
    isUpdatingDisplayTypeInDiscounts: false,
    displayTypeInDiscounts: DisplayTypesInDiscountsConstant.LIST,
    updateDisplayTypeErrorInDiscounts: null,

    isAddNewDiscount: false,
    addNewDiscountError: null,
    addNewDiscountResult: null,

    isDeletingDiscount: false,
    deleteDiscountError: null,
    deleteDiscountResult: null,

    isEditingDiscount: false,
    editDiscountError: null,
    editDiscountResult: null,

    isGetDiscountsInDiscounts: false,
    getDiscountsInDiscountsError: null,
    discountsInDiscounts: [],
    discountsPageNumberInDiscounts: 1,
    discountsPageSizeInDiscounts: 10,
    discountsTotalCountInDiscounts: 0,

    isGetEditedDiscount: false,
    getEditedDiscountError: null,
    editedDiscountInDiscounts: null,

    isUpdatingStatusDiscount: false,
    updateStatusDiscountError: null,
    updateStatusDiscountResult: null,

    //reservationSchedule
    isUpdatingDisplayTypeInReservationSchedules: false,
    displayTypeInReservationSchedules: DisplayTypesInReservationSchedulesConstant.LIST,
    updateDisplayTypeErrorInReservationSchedules: null,

    isAddNewReservationSchedule: false,
    addNewReservationScheduleError: null,
    addNewReservationScheduleResult: null,

    isDeletingReservationSchedule: false,
    deleteReservationScheduleError: null,
    deleteReservationScheduleResult: null,

    isEditingReservationSchedule: false,
    editReservationScheduleError: null,
    editReservationScheduleResult: null,

    isGetReservationSchedulesInReservationSchedules: false,
    getReservationSchedulesInReservationSchedulesError: null,
    reservationSchedulesInReservationSchedules: [],
    reservationSchedulesPageNumberInReservationSchedules: 1,
    reservationSchedulesPageSizeInReservationSchedules: 10,
    reservationSchedulesTotalCountInReservationSchedules: 0,

    isGetEditedReservationSchedule: false,
    getEditedReservationScheduleError: null,
    editedReservationScheduleInReservationSchedules: null,

    isUpdatingStatusReservationSchedule: false,
    updateStatusReservationScheduleError: null,
    updateStatusReservationScheduleResult: null,

    isGetDaysInReservationSchedules: false,
    getDaysInReservationSchedulesError: null,
    daysInReservationSchedules: [],

    isUpdateDaysInReservationSchedules: false,
    updateDaysInReservationSchedulesError: null,
    dayInReservationSchedules: null,

    //user
    isUpdatingDisplayTypeInUsers: false,
    displayTypeInUsers: DisplayTypesInUsersConstant.LIST,
    updateDisplayTypeErrorInUsers: null,

    isDeletingUser: false,
    deleteUserError: null,
    deleteUserResult: null,

    isGetUsersInUsers: false,
    getUsersInUsersError: null,
    usersInUsers: [],
    usersPageNumberInUsers: 1,
    usersPageSizeInUsers: 10,
    usersTotalCountInUsers: 0,

    isGetEditedUser: false,
    getEditedUserError: null,
    editedUserInUsers: null,

    isUpdatingStatusUser: false,
    updateStatusUserError: null,
    updateStatusUserResult: null,

    isUpdatingStatusUser: false,
    mobileVerifiedUserError: null,
    mobileVerifiedUserResult: null,

    isEditingUser: false,
    editUserError: null,
    editUserResult: null,

    isAddNewUser: false,
    addNewUserError: null,
    addNewUserResult: null,

    isCheckingEmailAvailabilityInUsers: false,
    isEmailAvailableInUsers: true,
    checkEmailAvailabilityInUsersError: null,

    isCheckingMobileAvailabilityInUsers: false,
    isMobileAvailableInUsers: true,
    checkMobileAvailabilityInUsersError: null,

    //reservation
    isUpdatingDisplayTypeInReservations: false,
    displayTypeInReservations: DisplayTypesInReservationsConstant.LIST,
    updateDisplayTypeErrorInReservations: null,

    isDeletingReservation: false,
    deleteReservationError: null,
    deleteReservationResult: null,

    isGetReservationsInReservations: false,
    getReservationsInReservationsError: null,
    reservationsInReservations: [],
    reservationsPageNumberInReservations: 1,
    reservationsPageSizeInReservations: 10,
    reservationsTotalCountInReservations: 0,

    isGetEditedReservation: false,
    getEditedReservationError: null,
    editedReservationInReservations: null,

    isUpdatingPaymentStatusReservation: false,
    updatePaymentStatusReservationError: null,
    updatePaymentStatusReservationResult: null,

    isGetReservationTypesInReservations: false,
    getReservationTypesInReservationsError: null,
    reservationTypesInReservations: [],

    isGetUsersInReservations: false,
    getUsersInReservationsError: null,
    usersInReservations: [],

    isUpdateReservationTypesInReservations: false,
    updateReservationTypesInReservationsError: null,
    reservationTypeInReservations: null,

    isUpdateUsersInReservations: false,
    updateUsersInReservationsError: null,
    userInReservations: null,

    isUpdatingSelectedDateInReservations: false,
    selectedDateInReservations: new Date(moment()),
    updateSelectedDateInReservationsError: null,

    isUpdatingReservationTimesInReservations: false,
    reservationTimeInReservations: null,
    updateReservationTimesInReservationsError: null,

    isGettingReservationTimesInReservations: false,
    reservationTimesInReservations: [],
    getReservationTimesInReservationsError: null,

    isAddNewReservation: false,
    addNewReservationError: null,
    addNewReservationResult: null,

    isUpdatingReservationTimesInEditReservation: false,
    reservationTimeInEditReservation: null,
    updateReservationTimesInEditReservationError: null,

    isGettingReservationTimesInEditReservation: false,
    reservationTimesInEditReservation: [],
    getReservationTimesInEditReservationError: null,

    isEditingReservation: false,
    editReservationResult: null,
    editReservationError: null,

    isUpdatingAttendenceStatusReservation: false,
    updateAttendenceStatusReservationError: null,
    updateAttendenceStatusReservationResult: null,

    isGetReservationDetails: false,
    reservationDetailsInReservations: null,
    getReservationDetailsError: null,

    isCancellingReservation: false,
    cancelReservationResult: null,
    cancelReservationError: null,

    isGetAttendedReservationsInReservations: false,
    getAttendedReservationsInReservationsError: null,
    attendedReservationsInReservations: [],
    attendedReservationsPageNumberInReservations: 1,
    attendedReservationsPageSizeInReservations: 10,
    attendedReservationsTotalCountInReservations: null,

    isGetCancelledReservationsInReservations: false,
    getCancelledReservationsInReservationsError: null,
    cancelledReservationsInReservations: [],
    cancelledReservationsPageNumberInReservations: 1,
    cancelledReservationsPageSizeInReservations: 10,
    cancelledReservationsTotalCountInReservations: null,

    isGetUnpaidReservationsInReservations: false,
    getUnpaidReservationsInReservationsError: null,
    unpaidReservationsInReservations: [],
    unpaidReservationsPageNumberInReservations: 1,
    unpaidReservationsPageSizeInReservations: 10,
    unpaidReservationsTotalCountInReservations: null,

    isUpdatingDisplayTypeInAttendedReservations: false,
    displayTypeInAttendedReservations: DisplayTypesInAttendedReservationsConstant.LIST,
    updateDisplayTypeErrorInAttendedReservations: null,

    isUpdatingDisplayTypeInCancelledReservations: false,
    displayTypeInCancelledReservations: DisplayTypesInCancelledReservationsConstant.LIST,
    updateDisplayTypeErrorInCancelledReservations: null,

    isUpdatingDisplayTypeInUnpaidReservations: false,
    displayTypeInUnpaidReservations: DisplayTypesInUnpaidReservationsConstant.LIST,
    updateDisplayTypeErrorInUnpaidReservations: null,

    isDeletingCancelledReservation: false,
    deleteCancelledReservationError: null,
    deleteCancelledReservationResult: null,

    isUpdatingReturnMoneyStatusReservation: false,
    updateReturnMoneyStatusReservationError: null,
    updateReturnMoneyStatusReservationResult: null,

    isGetRequestsInReservations: false,
    getRequestsInReservationsError: null,
    requestsInReservations: [],
    requestsPageNumberInReservations: 1,
    requestsPageSizeInReservations: 10,
    requestsTotalCountInReservations: null,

    isUpdatingDisplayTypeInRequests: false,
    displayTypeInRequests: DisplayTypesInRequestsConstant.LIST,
    updateDisplayTypeErrorInRequests: null,

    isUpdatingRequestStatusReservation: false,
    updateRequestStatusReservationError: null,
    updateRequestStatusReservationResult: null,

    isGetTicketTypesInReservations: false,
    getTicketTypesInReservationsError: null,
    ticketTypesInReservations: [],

    isGetTicketOptionsInReservations: false,
    getTicketOptionsInReservationsError: null,
    ticketOptionsInReservations: [],

    isClearReservationDetails: true,
    clearReservationDetailsError: null,

    //notifications
    isChangeNotificationIsRead: false,
    changeNotificationIsReadError: null,
    changeNotificationIsReadResult: null,

    isGetNotifications: false,
    getNotificationsError: null,
    notificationsUser: [],
    notificationsUserPageNumber: 1,
    notificationsUserPageSize: 10,
    notificationsUserTotalCount: null,
    unReadNotificationsUserCount: 0,

    isMarkAllAsRead: false,
    markAllAsReadError: null,
    markAllAsReadResult: null,

    isUpdateNotifications: false,
    updateNotificationsError: null,

    //dashboard
    isGetChartsData: false,
    getChartsDataError: null,
    chartsDataInAdmin: [],
    chartsDataLabelEnInAdmin: [],
    chartsDataLabelArInAdmin: [],
    chartsDataValueInAdmin: [],

    //changePassword
    isChangedPassword: false,
    getChangedPasswordError: null,
    changedPasswordResult: null,

    //reservation types
    isUpdatingStatusInReservationTypes: false,
    updateStatusInReservationTypesError: null,
    updateStatusInReservationTypesResult: null,

    isUpdatingDisplayTypeInReservationTypes: false,
    displayTypeInReservationTypes: DisplayTypesInReservationTypesConstant.LIST,
    updateDisplayTypeErrorInReservationTypes: null,

    isAddNewReservationType: false,
    addNewReservationTypeError: null,
    addNewReservationTypeResult: null,

    isGetReservationTypesInReservationTypes: false,
    getReservationTypesInReservationTypesError: null,
    reservationTypesInReservationTypes: [],
    reservationTypesPageNumberInReservationTypes: 1,
    reservationTypesPageSizeInReservationTypes: 10,
    reservationTypesTotalCountInReservationTypes: null,

    isGetEditedReservationType: false,
    getEditedReservationTypeError: null,
    editedReservationTypeInReservationTypes: null,

    isEditingReservationType: false,
    editReservationTypeError: null,
    editReservationTypeResult: null,

    isDeletingReservationType: false,
    deleteReservationTypeError: null,
    deleteReservationTypeResult: null,

    //ticket types
    isUpdatingDisplayTypeInTicketTypes: false,
    displayTypeInTicketTypes: DisplayTypesInTicketTypesConstant.LIST,
    updateDisplayTypeErrorInTicketTypes: null,

    isAddNewTicketType: false,
    addNewTicketTypeError: null,
    addNewTicketTypeResult: null,

    isGetTicketTypesInTicketTypes: false,
    getTicketTypesInTicketTypesError: null,
    ticketTypesInTicketTypes: [],
    ticketTypesPageNumberInTicketTypes: 1,
    ticketTypesPageSizeInTicketTypes: 10,
    ticketTypesTotalCountInTicketTypes: null,

    isGetEditedTicketType: false,
    getEditedTicketTypeError: null,
    editedTicketTypeInTicketTypes: null,

    isEditingTicketType: false,
    editTicketTypeError: null,
    editTicketTypeResult: null,

    isDeletingTicketType: false,
    deleteTicketTypeError: null,
    deleteTicketTypeResult: null,

    //ticket options
    isUpdatingDisplayTypeInTicketOptions: false,
    displayTypeInTicketOptions: DisplayTypesInTicketOptionsConstant.LIST,
    updateDisplayTypeErrorInTicketOptions: null,

    isAddNewTicketOption: false,
    addNewTicketOptionError: null,
    addNewTicketOptionResult: null,

    isGetTicketOptionsInTicketOptions: false,
    getTicketOptionsInTicketOptionsError: null,
    ticketOptionsInTicketOptions: [],
    ticketOptionsPageNumberInTicketOptions: 1,
    ticketOptionsPageSizeInTicketOptions: 10,
    ticketOptionsTotalCountInTicketOptions: null,

    isGetEditedTicketOption: false,
    getEditedTicketOptionError: null,
    editedTicketOptionInTicketOptions: null,

    isEditingTicketOption: false,
    editTicketOptionError: null,
    editTicketOptionResult: null,

    isDeletingTicketOption: false,
    deleteTicketOptionError: null,
    deleteTicketOptionResult: null,

    isGetReservationTypesInTicketOptions: false,
    getReservationTypesInTicketOptionsError: null,
    reservationTypesInTicketOptions: [],

    isUpdateReservationTypesInTicketOptions: false,
    updateReservationTypesInTicketOptionsError: null,
    reservationTypeInTicketOptions: null,

    //reservation type settings
    isUpdatingDisplayTypeInReservationTypeSettings: false,
    displayTypeInReservationTypeSettings: DisplayTypesInReservationTypeSettingsConstant.LIST,
    updateDisplayTypeErrorInReservationTypeSettings: null,

    isAddNewReservationTypeSetting: false,
    addNewReservationTypeSettingError: null,
    addNewReservationTypeSettingResult: null,

    isGetReservationTypeSettingsInReservationTypeSettings: false,
    getReservationTypeSettingsInReservationTypeSettingsError: null,
    reservationTypeSettingsInReservationTypeSettings: [],
    reservationTypeSettingsPageNumberInReservationTypeSettings: 1,
    reservationTypeSettingsPageSizeInReservationTypeSettings: 10,
    reservationTypeSettingsTotalCountInReservationTypeSettings: null,

    isGetEditedReservationTypeSetting: false,
    getEditedReservationTypeSettingError: null,
    editedReservationTypeSettingInReservationTypeSettings: null,

    isEditingReservationTypeSetting: false,
    editReservationTypeSettingError: null,
    editReservationTypeSettingResult: null,

    isDeletingReservationTypeSetting: false,
    deleteReservationTypeSettingError: null,
    deleteReservationTypeSettingResult: null,

    isGetReservationTypesInReservationTypeSettings: false,
    getReservationTypesInReservationTypeSettingsError: null,
    reservationTypesInReservationTypeSettings: [],

    isUpdateReservationTypesInReservationTypeSettings: false,
    updateReservationTypesInReservationTypeSettingsError: null,
    reservationTypeInReservationTypeSettings: null,

    //roles
    isCreateNewRole: false,
    createNewRoleError: null,
    createNewRoleResult: null,

    isDeletingRole: false,
    deleteRoleError: null,
    deleteRoleResult: null,

    isEditingRole: false,
    editRoleError: null,
    editRoleResult: null,

    isGetEditedRole: false,
    getEditedRoleError: null,
    editedRoleInRoles: null,

    isGetRole: false,
    getRoleError: null,
    roleInRoles: null,

    isGetRoles: false,
    getRolesError: null,
    rolesInRoles: [],
    rolesPageNumberInRoles: 1,
    rolesPageSizeInRoles: 10,
    rolesTotalCountInRoles: null,

    isUpdatingDisplayTypeInRoles: false,
    displayTypeInRoles: DisplayTypesInRolesConstant.LIST,
    updateDisplayTypeErrorInRoles: null,

    isUpdatingStatusRole: false,
    updateStatusRoleError: null,
    updateStatusRoleResult: null,

    ischeckingRoleNameEnAvailabilityInRoles: false,
    isRoleNameEnAvailableInRoles: true,
    checkRoleNameEnAvailabilityErrorInRoles: null,

    ischeckingRoleNameArAvailabilityInRoles: false,
    isRoleNameArAvailableInRoles: true,
    checkRoleNameArAvailabilityErrorInRoles: null,

    //roleUsers
    isCreateNewRoleUser: false,
    createNewRoleUserError: null,
    createNewRoleUserResult: null,

    isDeletingRoleUser: false,
    deleteRoleUserError: null,
    deleteRoleUserResult: null,

    isEditingRoleUser: false,
    editRoleUserError: null,
    editRoleUserResult: null,

    isGetEditedRoleUser: false,
    getEditedRoleUserError: null,
    editedRoleUserInRoleUsers: null,

    isGetRoleUsers: false,
    getRoleUsersError: null,
    roleUsersInRoleUsers: [],
    usersPageNumberInRoleUsers: 1,
    usersPageSizeInRoleUsers: 10,
    usersTotalCountInRoleUsers: null,

    isUpdatingDisplayTypeInRoleUsers: false,
    displayTypeInRoleUsers: DisplayTypesInRoleUsersConstant.LIST,
    updateDisplayTypeErrorInRoleUsers: null,

    isUpdatingStatusRoleUser: false,
    updateStatusRoleUserError: null,
    updateStatusRoleUserResult: null,

    isCheckingEmailAvailabilityInRoleUsers: false,
    isEmailAvailableInRoleUsers: true,
    checkEmailAvailabilityInRoleUsersError: null,

    //roleScreens
    isCreateNewRoleScreen: false,
    createNewRoleScreenError: null,
    createNewRoleScreenResult: null,

    isDeletingRoleScreen: false,
    deleteRoleScreenError: null,
    deleteRoleScreenResult: null,

    isEditingRoleScreen: false,
    editRoleScreenError: null,
    editRoleScreenResult: null,

    isGetEditedRoleScreen: false,
    getEditedRoleScreenError: null,
    editedRoleScreenInRoleScreens: null,

    isGetRoleScreens: false,
    getRoleScreensError: null,
    roleScreensInRoleScreens: [],
    roleScreensPageNumberInRoleScreens: 1,
    roleScreensPageSizeInRoleScreens: 10,
    roleScreensTotalCountInRoleScreens: null,

    isGetScreensInRoleScreens: false,
    getScreensInRoleScreensError: null,
    screensInRoleScreens: [],

    isUpdatingDisplayTypeInRoleScreens: false,
    displayTypeInRoleScreens: DisplayTypesInRoleScreensConstant.LIST,
    updateDisplayTypeErrorInRoleScreens: null,

    isUpdateScreensInRoleScreens: false,
    updateScreensInRoleScreensError: null,
    screenInRoleScreens: null,

    isUpdatingStatusRoleScreen: false,
    updateStatusRoleScreenError: null,
    updateStatusRoleScreenResult: null,

    //reports
    isUpdatingDisplayTypeInReports: false,
    displayTypeInReports: DisplayTypesInReportsConstant.TABS,
    updateDisplayTypeErrorInReports: null,

    isGetReservationReport: false,
    getReservationReportError: null,
    reservationReport: [],
    reservationReportPageNumber: 1,
    reservationReportPageSize: 10,
    reservationReportTotalCount: null,
    reportDataFilter: null,

    isGetRequestReport: false,
    getRequestReportError: null,
    requestReport: [],
    requestReportPageNumber: 1,
    requestReportPageSize: 10,
    requestReportTotalCount: null,

    isGetCompanyReservationReport: false,
    getCompanyReservationReportError: null,
    companyReservationReport: [],
    companyReservationReportPageNumber: 1,
    companyReservationReportPageSize: 10,
    companyReservationReportTotalAmount: 0,
    companyReservationReportTotalUnpaid: 0,
    companyReservationReportTotalPaid: 0,

    //news
    isUpdatingDisplayTypeInNews: false,
    displayTypeInNews: DisplayTypesInNewsConstant.LIST,
    updateDisplayTypeErrorInNews: null,

    isAddNewNew: false,
    addNewNewError: null,
    addNewNewResult: null,

    isGetNewsInNews: false,
    getNewsInNewsError: null,
    newsInNews: [],
    newsPageNumberInNews: 1,
    newsPageSizeInNews: 10,
    newsTotalCountInNews: null,

    isGetEditedNew: false,
    getEditedNewError: null,
    editedNewInNews: null,

    isEditingNew: false,
    editNewError: null,
    editNewResult: null,

    isDeletingNew: false,
    deleteNewError: null,
    deleteNewResult: null,

    isUpdatingStatusNew: false,
    updateStatusNewError: null,
    updateStatusNewResult: null,

    //requests
    isGettingFromTimesInRequests: false,
    fromTimesInRequests: [],
    selectedDateInRequests: new Date(moment()),
    getFromTimesInRequestsError: null,

    isUpdatingFromTimesInRequests: false,
    fromTimeInRequests: null,
    updateFromTimesInRequestsError: null,

    isGettingToTimesInRequests: false,
    toTimesInRequests: [],
    getToTimesInRequestsError: null,

    sUpdatingToTimesInRequests: false,
    toTimeInRequests: null,
    updateToTimesInRequestsError: null,

    isApprovingRequest: false,
    approveRequestResult: null,
    approveRequestError: null,

    isGetEditedRequest: false,
    getEditedRequestError: null,
    editedRequestInRequests: null,

    isGetRequestDetails: false,
    getRequestDetailsError: null,
    requestDetailsInRequests: null,

    isDeletingRequest: false,
    deleteRequestError: null,
    deleteRequestResult: null,

    isPayingInRequest: false,
    payInRequestError: null,
    payInRequestResult:null,

    isAddNewRequest: false,
    addNewRequestError: null,
    addNewRequestResult: null,

    isEditingRequest: false,
    editRequestResult: null,
    editRequestError: null,

    isGettingSchoolTourRangesInRequests: false,
    schoolTourRangesInRequests: [],
    getSchoolTourRangesInRequestsError: null,

    isGetReservationTypesInRequests: false,
    getReservationTypesInRequestsError: null,
    reservationTypesInRequests: [],

    isGetTicketOptionsInRequests: false,
    getTicketOptionsInRequestsError: null,
    ticketOptionsInRequests: [],

    isGetUsersInRequests: false,
    getUsersInRequestsError: null,
    usersInRequests: [],

    isUpdateReservationTypesInRequests: false,
    updateReservationTypesInRequestsError: null,
    reservationTypeInRequests: null,

    isUpdateUsersInRequests: false,
    updateUsersInRequestsError: null,
    userInRequests: null,

    //galleries
    isUpdatingDisplayTypeInGalleries: false,
    displayTypeInGalleries: DisplayTypesInGalleriesConstant.LIST,
    updateDisplayTypeErrorInGalleries: null,

    isAddGalleryGallery: false,
    addGalleryGalleryError: null,
    addGalleryGalleryResult: null,

    isGetGalleriesInGalleries: false,
    getGalleriesInGalleriesError: null,
    galleriesInGalleries: [],
    galleriesPageNumberInGalleries: 1,
    galleriesPageSizeInGalleries: 10,
    galleriesTotalCountInGalleries: null,

    isGetEditedGallery: false,
    getEditedGalleryError: null,
    editedGalleryInGalleries: null,

    isEditingGallery: false,
    editGalleryError: null,
    editGalleryResult: null,

    isDeletingGallery: false,
    deleteGalleryError: null,
    deleteGalleryResult: null,

    isUpdatingStatusGallery: false,
    updateStatusGalleryError: null,
    updateStatusGalleryResult: null,

    //school tour range
    isAddNewSchoolTourRange: false,
    addNewSchoolTourRangeError: null,
    addNewSchoolTourRangeResult: null,

    isDeletingSchoolTourRange: false,
    deleteSchoolTourRangeError: null,
    deleteSchoolTourRangeResult: null,

    isEditingSchoolTourRange: false,
    editSchoolTourRangeError: null,
    editSchoolTourRangeResult: null,

    isGetEditedSchoolTourRange: false,
    getEditedSchoolTourRangeError: null,
    editedSchoolTourRange: null,

    isGetSchoolTourRanges: false,
    getSchoolTourRangesError: null,
    schoolTourRanges: [],
    schoolTourRangesPageNumber: 1,
    schoolTourRangesPageSize: 25,
    schoolTourRangesTotalCount: null,

    isUpdatingDisplayTypeInSchoolTourRanges: false,
    displayTypeInSchoolTourRanges: DisplayTypesInSchoolTourRangesConstant.LIST,
    updateDisplayTypeErrorInSchoolTourRanges: null,

    //companies
    isAddNewCompany: false,
    addNewCompanyError: null,
    addNewCompanyResult: null,

    isDeletingCompany: false,
    deleteCompanyError: null,
    deleteCompanyResult: null,

    isEditingCompany: false,
    editCompanyError: null,
    editCompanyResult: null,

    isGetCompaniesInCompanies: false,
    getCompaniesInCompaniesError: null,
    companiesInCompanies: [],
    companiesPageNumberInCompanies: 1,
    companiesPageSizeInCompanies: 25,
    companiesTotalCountInCompanies: null,

    isGetEditedCompany: false,
    getEditedCompanyError: null,
    editedCompanyInCompanies: null,

    isUpdatingDisplayTypeInCompanies: false,
    displayTypeInCompanies: DisplayTypesInCompaniesConstant.LIST,
    updateDisplayTypeErrorInCompanies: null,

    isUpdatingStatusCompany: false,
    updateStatusCompanyError: null,
    updateStatusCompanyResult: null,

    //company reservation
    isAddNewCompanyReservation: false,
    addNewCompanyReservationError: null,
    addNewCompanyReservationResult: null,

    isDeletingCompanyReservation: false,
    deleteCompanyReservationError: null,
    deleteCompanyReservationResult: null,

    isEditingCompanyReservation: false,
    editCompanyReservationResult: null,
    companyReservationsInCompanyReservations: null,

    isGetCompaniesInCompanyReservations: false,
    getCompaniesInCompanyReservationsError: null,
    companiesInCompanyReservations: [],

    isGetCompanyReservationDetails: false,
    companyReservationDetailsInCompanyReservations: null,
    getCompanyReservationDetailsError: null,

    isGetCompanyReservationsInCompanyReservations: false,
    getCompanyReservationsInCompanyReservationsError: null,
    companyReservationsInCompanyReservations: [],
    companyReservationsPageNumberInCompanyReservations: 1,
    companyReservationsPageSizeInCompanyReservations: 25,
    companyReservationsTotalCountInCompanyReservations: null,

    isGettingCompanyReservationTimesInCompanyReservations: false,
    companyReservationTimesInCompanyReservations: [],
    getCompanyReservationTimesInCompanyReservationsError: null,

    isGetEditedCompanyReservation: false,
    getEditedCompanyReservationError: null,
    editedCompanyReservationInCompanyReservations: null,

    isGetReservationTypesInCompanyReservations: false,
    getReservationTypesInCompanyReservationsError: null,
    reservationTypesInCompanyReservations: [],

    isGetTicketOptionsInCompanyReservations: false,
    getTicketOptionsInCompanyReservationsError: null,
    ticketOptionsInCompanyReservations: [],

    isGetTicketTypesInCompanyReservations: false,
    getTicketTypesInCompanyReservationsError: null,
    ticketTypesInCompanyReservations: [],

    isUpdateCompaniesInCompanyReservations: false,
    updateCompaniesInCompanyReservationsError: null,
    companyInCompanyReservations: null,

    isUpdatingCompanyReservationTimesInCompanyReservations: false,
    companyReservationTimeInCompanyReservations: null,
    updateCompanyReservationTimesInCompanyReservationsError: null,

    isUpdatingDisplayTypeInCompanyReservations: false,
    displayTypeInCompanyReservations: DisplayTypesInCompanyReservationsConstant.LIST,
    updateDisplayTypeErrorInCompanyReservations: null,

    isUpdateReservationTypesInCompanyReservations: false,
    updateReservationTypesInCompanyReservationsError: null,
    reservationTypeInCompanyReservations: null,

    isUpdatingSelectedDateInCompanyReservations: false,
    selectedDateInCompanyReservations: null,
    updateSelectedDateInCompanyReservationsError: null,

    isGettingToTimesInCompanyReservations: false,
    toTimesInCompanyReservations: [],
    getToTimesInCompanyReservationsError: null,

    isUpdatingToTimesInCompanyReservations: false,
    toTimeInCompanyReservations: null,
    updateToTimesInCompanyReservationsError: null,

    isChangingIsPaidInCompanyReservations: false,
    changeIsPaidInCompanyReservationsError: null,
    changeIsPaidInCompanyReservationsResult: null,

    //reservation details with code
    isGetReservationDetailsWithCode: false,
    reservationDetailsWithCode:null,
    getReservationDetailsWithCodeError: null

};

const rootReducer = reduceReducers(initialState,
    //login
    loginUserReducer,
    checkIsUserLoggedInReducer,
    getUserSideBarReducer,

    //pausedTime
    updateDisplayTypeInPausedTimesReducer,
    getPausedTimesInPausedTimesReducer,
    updateStatusPausedTimeReducer,
    deletePausedTimeReducer,
    getEditedPausedTimeReducer,
    editPausedTimeReducer,
    addNewPausedTimeReducer,

    //smsSettings
    updateDisplayTypeInSmsSettingsReducer,
    getSmsSettingsInSmsSettingsReducer,
    updateStatusSmsSettingReducer,
    deleteSmsSettingReducer,
    getEditedSmsSettingReducer,
    editSmsSettingReducer,
    addNewSmsSettingReducer,
    getSmsTypesInSmsSettingsReducer,
    updateSmsTypesInSmsSettingsReducer,

    //notificationSettings
    updateDisplayTypeInNotificationSettingsReducer,
    getNotificationSettingsInNotificationSettingsReducer,
    updateStatusNotificationSettingReducer,
    deleteNotificationSettingReducer,
    getEditedNotificationSettingReducer,
    editNotificationSettingReducer,
    addNewNotificationSettingReducer,
    getNotificationTypesInNotificationSettingsReducer,
    getNotificationRolesInNotificationSettingsReducer,
    updateNotificationTypesInNotificationSettingsReducer,
    updateNotificationRolesInNotificationSettingsReducer,



    //discount
    updateDisplayTypeInDiscountsReducer,
    getDiscountsInDiscountsReducer,
    updateStatusDiscountReducer,
    deleteDiscountReducer,
    getEditedDiscountReducer,
    editDiscountReducer,
    addNewDiscountReducer,

    //reservationSchedule
    updateDisplayTypeInReservationSchedulesReducer,
    getReservationSchedulesInReservationSchedulesReducer,
    updateStatusReservationScheduleReducer,
    deleteReservationScheduleReducer,
    getEditedReservationScheduleReducer,
    editReservationScheduleReducer,
    addNewReservationScheduleReducer,
    getDaysInReservationSchedulesReducer,
    updateDaysInReservationSchedulesReducer,

    //user
    updateDisplayTypeInUsersReducer,
    getUsersInUsersReducer,
    updateStatusUserReducer,
    mobileVerifiedUserReducer,
    deleteUserReducer,
    getEditedUserReducer,
    addNewUserReducer,
    editUserReducer,
    checkEmailAvailabilityInUsersReducer,
    checkMobileAvailabilityInUsersReducer,

    //reservation
    updateDisplayTypeInReservationsReducer,
    getReservationsInReservationsReducer,
    updatePaymentStatusReservationReducer,
    deleteReservationReducer,
    getEditedReservationReducer,
    getReservationTypesInReservationsReducer,
    getUsersInReservationsReducer,
    updateReservationTypesInReservationsReducer,
    updateUsersInReservationsReducer,
    updateSelectedDateInReservationsReducer,
    getReservationTimesInReservationsReducer,
    updateReservationTimesInReservationsReducer,
    addNewReservationReducer,
    getReservationTimesInEditReservationReducer,
    updateReservationTimesInEditReservationReducer,
    editReservationReducer,
    cancelReservationReducer,
    getReservationDetailsReducer,
    updateAttendenceStatusReservationReducer,
    getAttendedReservationsInReservationsReducer,
    getCancelledReservationsInReservationsReducer,
    getUnpaidReservationsInReservationsReducer,
    updateDisplayTypeInAttendedReservationsReducer,
    updateDisplayTypeInCancelledReservationsReducer,
    updateDisplayTypeInUnpaidReservationsReducer,
    deleteCancelledReservationReducer,
    updateReturnMoneyStatusReservationReducer,
    getRequestsInReservationsReducer,
    updateDisplayTypeInRequestsReducer,
    updateRequestStatusReservationReducer,
    getTicketTypesInReservationsReducer,
    getTicketOptionsInReservationsReducer,
    clearReservationDetailsReducer,

    //notifications
    changeNotificationIsReadReducer,
    getNotificationsReducer,
    markAllAsReadReducer,
    updateNotificationsReducer,

    //dashboard
    getChartsDataReducer,

    //changePassword
    changePasswordReducer,

    //reservation types
    updateDisplayTypeInReservationTypesReducer,
    addNewReservationTypeReducer,
    getReservationTypesInReservationTypesReducer,
    getEditedReservationTypeReducer,
    editReservationTypeReducer,
    deleteReservationTypeReducer,
    updateStatusInReservationTypesReducer,

    //reservation type settings
    updateDisplayTypeInReservationTypeSettingsReducer,
    addNewReservationTypeSettingReducer,
    getReservationTypeSettingsInReservationTypeSettingsReducer,
    getEditedReservationTypeSettingReducer,
    editReservationTypeSettingReducer,
    getReservationTypesInReservationTypeSettingsReducer,
    updateReservationTypesInReservationTypeSettingsReducer,
    deleteReservationTypeSettingReducer,

    //roles
    createNewRoleReducer,
    deleteRoleReducer,
    editRoleReducer,
    getEditedRoleReducer,
    getRoleReducer,
    getRolesReducer,
    updateDisplayTypeInRolesReducer,
    updateStatusRoleReducer,
    checkRoleNameArAvailabilityInRolesReducer,
    checkRoleNameEnAvailabilityInRolesReducer,

    //roleUsers
    createNewRoleUserReducer,
    deleteRoleUserReducer,
    editRoleUserReducer,
    getEditedRoleUserReducer,
    getRoleUsersReducer,
    updateDisplayTypeInRoleUsersReducer,
    updateStatusRoleUserReducer,
    checkEmailAvailabilityInRoleUsersReducer,

    //roleUsers
    createNewRoleScreenReducer,
    deleteRoleScreenReducer,
    editRoleScreenReducer,
    getEditedRoleScreenReducer,
    getRoleScreensReducer,
    getScreensInRoleScreensReducer,
    updateDisplayTypeInRoleScreensReducer,
    updateScreensInRoleScreensReducer,
    updateStatusRoleScreenReducer,

    //reports
    updateDisplayTypeInReportsReducer,
    getReservationReportReducer,
    getRequestReportReducer,
    getCompanyReservationReportReducer,

    //news
    updateDisplayTypeInNewsReducer,
    addNewNewReducer,
    getNewsInNewsReducer,
    getEditedNewReducer,
    editNewReducer,
    deleteNewReducer,
    updateStatusNewReducer,

    //ticket types
    updateDisplayTypeInTicketTypesReducer,
    addNewTicketTypeReducer,
    getTicketTypesInTicketTypesReducer,
    getEditedTicketTypeReducer,
    editTicketTypeReducer,
    deleteTicketTypeReducer,

    //ticket options
    updateDisplayTypeInTicketOptionsReducer,
    addNewTicketOptionReducer,
    getTicketOptionsInTicketOptionsReducer,
    getEditedTicketOptionReducer,
    editTicketOptionReducer,
    deleteTicketOptionReducer,
    getReservationTypesInTicketOptionsReducer,
    updateReservationTypesInTicketOptionsReducer,

    //requests
    getFromTimesInRequestsReducer,
    updateFromTimesInRequestsReducer,
    getToTimesInRequestsReducer,
    updateToTimesInRequestsReducer,
    approveRequestReducer,
    getEditedRequestReducer,
    getRequestDetailsReducer,
    deleteRequestReducer,
    payInRequestReducer,
    addNewRequestReducer,
    editRequestReducer,
    getSchoolTourRangesInRequestsReducer,
    getReservationTypesInRequestsReducer,
    getTicketOptionsInRequestsReducer,
    getUsersInRequestsReducer,
    updateReservationTypesInRequestsReducer,
    //updateSelectedDateInRequestsReducer,
    updateUsersInRequestsReducer,

    //galleries
    updateDisplayTypeInGalleriesReducer,
    addNewGalleryReducer,
    getGalleriesInGalleriesReducer,
    getEditedGalleryReducer,
    editGalleryReducer,
    deleteGalleryReducer,
    updateStatusGalleryReducer,

    //school tour range
    addNewSchoolTourRangeReducer,
    editSchoolTourRangeReducer,
    getEditedSchoolTourRangeReducer,
    getSchoolTourRangesReducer,
    deleteSchoolTourRangeReducer,
    updateDisplayTypeInSchoolTourRangesReducer,

    //companies
    addNewCompanyReducer,
    deleteCompanyReducer,
    getEditedCompanyReducer,
    editCompanyReducer,
    getCompaniesInCompaniesReducer,
    updateDisplayTypeInCompaniesReducer,
    updateStatusCompanyReducer,

    //company reservation
    addNewCompanyReservationReducer,
    deleteCompanyReservationReducer,
    editCompanyReservationReducer,
    getCompaniesInCompanyReservationsReducer,
    getCompanyReservationDetailsReducer,
    getCompanyReservationsInCompanyReservationsReducer,
    getCompanyReservationTimesInCompanyReservationsReducer,
    getEditedCompanyReservationReducer,
    getReservationTypesInCompanyReservationsReducer,
    getTicketOptionsInCompanyReservationsReducer,
    getTicketTypesInCompanyReservationsReducer,
    updateCompaniesInCompanyReservationsReducer,
    updateCompanyReservationTimesInCompanyReservationsReducer,
    updateReservationTypesInCompanyReservationsReducer,
    updateSelectedDateInCompanyReservationsReducer,
    updateDisplayTypeInCompanyReservationsReducer,
    getToTimesInCompanyReservationsReducer,
    updateToTimesInCompanyReservationsReducer,
    changeIsPaidInCompanyReservationsReducer,

    //reservation details with code
     getReservationDetailsWithCodeReducer
);

export default rootReducer;