export const UPDATE_SCREENS_IN_ROLE_SCREENS = 'UPDATE_SCREENS_IN_ROLE_SCREENS';
export const UPDATE_SCREENS_IN_ROLE_SCREENS_SUCCESS = 'UPDATE_SCREENS_IN_ROLE_SCREENS_SUCCESS';
export const UPDATE_SCREENS_IN_ROLE_SCREENS_FAILURE = 'UPDATE_SCREENS_IN_ROLE_SCREENS_FAILURE';

export const updateScreensInRoleScreens = (screen) => ({
    type: UPDATE_SCREENS_IN_ROLE_SCREENS,
    screen
});

export const updateScreensInRoleScreensSuccess = (screen) => ({
    type: UPDATE_SCREENS_IN_ROLE_SCREENS_SUCCESS,
    payload: { screen }
});

export const updateScreensInRoleScreensFailure = (message) => ({
    type: UPDATE_SCREENS_IN_ROLE_SCREENS_FAILURE,
    payload: { message }
});