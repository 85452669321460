import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';


import {
    GET_SCHOOL_TOUR_RANGES_IN_REQUESTS,
    getSchoolTourRangesInRequestsSuccess,
    getSchoolTourRangesInRequestsFailure
} from '../../actions/Requests/getSchoolTourRangesInRequestsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function getSchoolTourRangesInRequestsEpic(action$) {
    return action$.pipe(
        ofType(GET_SCHOOL_TOUR_RANGES_IN_REQUESTS),
        switchMap(action => {

            const ajaxConfigObj = {
                url: url + `SchoolTourRange/GetSchoolTourRanges`,
                method: 'POST',
                headers: {
                    'Authorization':token,
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => ({
                    response: data.response,
                    selectedDate: action.selectedDate
                })), retry())
        }),
        map(data => {
            if (data.response.isSuccess) {
                return getSchoolTourRangesInRequestsSuccess(data.response.data, data.selectedDate);
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                    && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return getSchoolTourRangesInRequestsFailure(message);
            }
        }),
        catchError(error => of(getSchoolTourRangesInRequestsFailure
            (strings.somethingWrongHappened)))
    )
}