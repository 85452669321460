import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE
} from '../../actions/Account/changePasswordActions';

export function changePasswordReducer(state, action) {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return {
                ...state,
                isChangedPassword: true,
                getChangedPasswordError: null,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isChangedPassword: false,
                getChangedPasswordError: null,
                changedPasswordResult: action.payload.data,
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isChangedPassword: false,
                getChangedPasswordError: action.payload.message,
            };
        default:
            return state;
}}