export const UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS = 'UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS';
export const UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateCompanyReservationTimesInCompanyReservations = (selectedTime) => ({
    type: UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS,
    selectedTime
});

export const updateCompanyReservationTimesInCompanyReservationsSuccess = (selectedTime) => ({
    type: UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { selectedTime }
});

export const updateCompanyReservationTimesInCompanyReservationsFailure = (message) => ({
    type: UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});