import {
    UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS,
    UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_FAILURE
} from '../../../actions/Roles/RoleScreens/updateDisplayTypeInRoleScreensActions';

export function updateDisplayTypeInRoleScreensReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleScreens: true,
                updateDisplayTypeErrorInRoleScreens: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleScreens: false,
                displayTypeInRoleScreens: action.payload.displayType,
                updateDisplayTypeErrorInRoleScreens: null,

                isCreateNewRoleScreen: false,
                createNewRoleScreenError: null,
                createNewRoleScreenResult: null,

                isDeletingRoleScreen: false,
                deleteRoleScreenError: null,
                deleteRoleScreenResult: null,

                isEditingRoleScreen: false,
                editRoleScreenError: null,
                editRoleScreenResult: null,

                isUpdatingStatusRoleScreen: false,
                updateStatusRoleScreenError: null,
                updateStatusRoleScreenResult: null,

                isGetScreensInRoleScreens: false,
                getScreensInRoleScreensError: null,
                screensInRoleScreens: [],

                isUpdateScreensInRoleScreens: false,
                updateScreensInRoleScreensError: null,
                screenInRoleScreens: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInRoleScreens: false,
                updateDisplayTypeErrorInRoleScreens: action.payload
            };
        default:
            return state;
    }
}