import {
    DELETE_NEW,
    DELETE_NEW_SUCCESS,
    DELETE_NEW_FAILURE
} from '../../actions/News/deleteNewActions';

import { DisplayTypesInNewsConstant } from '../../constants/News/DisplayTypesInNewsConstant';

export function deleteNewReducer(state, action) {
    switch (action.type) {
        case DELETE_NEW:
            return {
                ...state,
                isDeletingNew: true,
                deleteNewError: null,
            };
        case DELETE_NEW_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingNew: false,
                deleteNewError: null,
                deleteNewResult: action.payload.data,
                //displayTypeInNews: DisplayTypesInNewsConstant.LIST,
            };
        case DELETE_NEW_FAILURE:
            return {
                ...state,
                isDeletingNew: false,
                deleteNewError: action.payload.message,
            };
        default:
            return state;
}}