import {
    EDIT_RESERVATION_SCHEDULE,
    EDIT_RESERVATION_SCHEDULE_SUCCESS,
    EDIT_RESERVATION_SCHEDULE_FAILURE
} from '../../actions/ReservationSchedules/editReservationScheduleActions';

import { DisplayTypesInReservationSchedulesConstant } from '../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant';

export function editReservationScheduleReducer(state, action) {
    switch (action.type) {
        case EDIT_RESERVATION_SCHEDULE:
            return {
                ...state,
                isEditingReservationSchedule: true,
                editReservationScheduleError: null,
            };
        case EDIT_RESERVATION_SCHEDULE_SUCCESS:
            return {
                ...state,
                isEditingReservationSchedule: false,
                editReservationScheduleError: null,
                editReservationScheduleResult: action.payload.data,
               // displayTypeInReservationSchedules: DisplayTypesInReservationSchedulesConstant.LIST,
            };
        case EDIT_RESERVATION_SCHEDULE_FAILURE:
            return {
                ...state,
                isEditingReservationSchedule: false,
                editReservationScheduleError: action.payload.message,
            };
        default:
            return state;
}}