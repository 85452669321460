export const GET_EDITED_SMS_SETTING = 'GET_EDITED_SMS_SETTING';
export const GET_EDITED_SMS_SETTING_SUCCESS = 'GET_EDITED_SMS_SETTING_SUCCESS';
export const GET_EDITED_SMS_SETTING_FAILURE = 'GET_EDITED_SMS_SETTING_FAILURE';

export const getEditedSmsSetting = (smsSettingId) => ({
    type: GET_EDITED_SMS_SETTING,
    smsSettingId
});

export const getEditedSmsSettingSuccess = (smsSettingId) => ({
    type: GET_EDITED_SMS_SETTING_SUCCESS,
    payload: { smsSettingId }
});

export const getEditedSmsSettingFailure = (message) => ({
    type: GET_EDITED_SMS_SETTING_FAILURE,
    payload: { message }
});
