import {
    EDIT_ROLE_SCREEN,
    EDIT_ROLE_SCREEN_SUCCESS,
    EDIT_ROLE_SCREEN_FAILURE
} from '../../../actions/Roles/RoleScreens/editRoleScreenActions';

export function editRoleScreenReducer(state, action) {
    switch (action.type) {
        case EDIT_ROLE_SCREEN:
            return {
                ...state,
                isEditingRoleScreen: true,
                editRoleScreenError: null,
            };
        case EDIT_ROLE_SCREEN_SUCCESS:
            return {
                ...state,
                isEditingRoleScreen: false,
                editRoleScreenError: null,
                editRoleScreenResult: action.payload.data,
            };
        case EDIT_ROLE_SCREEN_FAILURE:
            return {
                ...state,
                isEditingRoleScreen: false,
                editRoleScreenError: action.payload.message,
            };
        default:
            return state;
}}