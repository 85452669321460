import {
    UPDATE_STATUS_GALLERY,
    UPDATE_STATUS_GALLERY_SUCCESS,
    UPDATE_STATUS_GALLERY_FAILURE
} from '../../actions/Galleries/updateStatusGalleryActions';

export function updateStatusGalleryReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_GALLERY:
            return {
                ...state,
                isUpdatingStatusGallery: true,
                updateStatusGalleryError: null,
            };
        case UPDATE_STATUS_GALLERY_SUCCESS:
            return {
                ...state,
                isUpdatingStatusGallery: false,
                updateStatusGalleryError: null,
                updateStatusGalleryResult: action.payload.data,
                galleriesInGalleries: state.galleriesInGalleries.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_GALLERY_FAILURE:
            return {
                ...state,
                isUpdatingStatusGallery: false,
                updateStatusGalleryError: action.payload.message,
            };
        default:
            return state;
    }
}