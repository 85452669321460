import {
    GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS,
    GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getCancelledReservationsInReservationsActions';

export function getCancelledReservationsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS:
            return {
                ...state,
                isGetCancelledReservationsInReservations: true,
                getCancelledReservationsInReservationsError: null,
                cancelledReservationsInReservations: [],
            };
        case GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetCancelledReservationsInReservations: false,
                getCancelledReservationsInReservationsError: null,
                cancelledReservationsInReservations: action.payload.cancelledReservations.data,
                cancelledReservationsPageNumberInReservations: action.payload.pageNumber,
                cancelledReservationsPageSizeInReservations: action.payload.pageSize,
                cancelledReservationsTotalCountInReservations: action.payload.cancelledReservations.total,
            };
        case GET_CANCELLED_RESERVATIONS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetCancelledReservationsInReservations: false,
                getCancelledReservationsInReservationsError: action.payload.message,
            };
        default:
            return state;
}}