import strings from '../../localizations/strings';
import Swal from "sweetalert2";

export const deleteRow = (props,id) => {
    Swal.fire({
        title: `${strings.areYouSure}`,
        text: ``,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: ` !${strings.yes}`,
        cancelButtonText: `${strings.cancel}`,
        preConfirm: () => {props.deleteNotificationSetting(id)
            // return fetch(`//api.github.com/users/`+id)
            //     .then(response => {
            //         if (!response.ok) {
            //             throw new Error(response.statusText)
            //         }
            //         return response.json()
            //     })
            //     .catch(error => {
            //         Swal.showValidationMessage(
            //             `Request failed: ${error}`
            //         )
            //     })
        },
        allowOutsideClick: () => !Swal.isLoading()
    })
}


