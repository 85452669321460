import React, { useEffect } from 'react';
import BarcodeReader from 'react-barcode-reader';
import strings from "../../localizations/strings";
import Loading from '../Shared/Loading/Loading'
import Details from './Details';

function BarcodeReaders(props) {

    function handleScan(data) {
        props.getReservationDetails(data)
    }
    function handleError(err) {
        alert(err)
    }
// useEffect(() =>{
//        props.getReservationDetails(20139)
// },[])


    return (
        <div className="content d-flex flex-column flex-column-fluid pt-0">
            <div className="post d-flex flex-column-fluid" id="kt_post" >
                <div id="kt_content_container" className="kt_content_container container-xxl">
                  
                    {props.isGetReservationDetails && (<Loading />)}
                    {
                        props.reservationDetailsInReservations == null
                            ? (
                                <div className='text-center' style={{ marginTop: "10rem" }}>
                                    <BarcodeReader
                                        onScan={handleScan}
                                        onError={handleScan}
                                    />
                                    <i className="fas fa-barcode" style={{ fontSize: "15rem" }}></i>
                                    <h5 className='text-muted'>{strings.scanForDetails}</h5>
                                </div>
                            ) : <Details {...props} />
                    }

                </div>
            </div>
        </div>
    );
};
export default BarcodeReaders;