export const DELETE_GALLERY = 'DELETE_GALLERY';
export const DELETE_GALLERY_SUCCESS = 'DELETE_GALLERY_SUCCESS';
export const DELETE_GALLERY_FAILURE = 'DELETE_GALLERY_FAILURE';

export const deleteGallery = (galleryId) => ({
    type: DELETE_GALLERY,
    galleryId
});

export const deleteGallerySuccess = (data) => ({
    type: DELETE_GALLERY_SUCCESS,
    payload: { data }
});

export const deleteGalleryFailure = (message) => ({
    type: DELETE_GALLERY_FAILURE,
    payload: { message }
});