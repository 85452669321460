import React,{useState,useEffect} from "react";
import { DisplayTypesInNewsConstant } from "../../../src/constants/News/DisplayTypesInNewsConstant";
import New from './New';
import Edit from './Edit';
import Add from './Add';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function News(props){

    return (
        <>
                  <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInNews === DisplayTypesInNewsConstant.LIST ? strings.news:
                        props.displayTypeInNews === DisplayTypesInNewsConstant.ADD ? strings.create:
                        props.displayTypeInNews === DisplayTypesInNewsConstant.EDIT ? strings.edit:""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInNews  !== DisplayTypesInNewsConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInNews(DisplayTypesInNewsConstant.LIST)}>
                                    {strings.news}
                                </Button> :null}

                            {props.displayTypeInNews  !== DisplayTypesInNewsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInNews(DisplayTypesInNewsConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                           
                            { props.displayTypeInNews  === DisplayTypesInNewsConstant.LIST &&
                            (<New {...props} />)}
                            {props.displayTypeInNews  === DisplayTypesInNewsConstant.ADD &&
                             (<Add {...props} />)}
                            {props.displayTypeInNews  === DisplayTypesInNewsConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
                        

        </>
    );
}
export default News