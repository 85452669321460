import React from "react";
import strings from "./localizations/strings";
import { LanguagesConstant } from "./constants/LanguagesConstant";

export default function setLanguage(languageCodeCookie){
    if (languageCodeCookie === null) {
        if (window.location.href.indexOf("/en/") !== -1 || window.location.href.endsWith("/en")) {
          languageCodeCookie = LanguagesConstant.EN;
          strings.setLanguage(LanguagesConstant.EN);
          // props.changeLanguage();
          localStorage.setItem("language-code", LanguagesConstant.EN);
  
          // let arabicStyleLink = document.querySelector("#arabicStyleLink");
          // if (arabicStyleLink === null) {
          //     arabicStyleLink = document.createElement('link');
          //     arabicStyleLink.href = "/arStyle.css";
          //     arabicStyleLink.id = "arabicStyleLink";
          //     arabicStyleLink.rel = "stylesheet";
  
  
          //     document.querySelector("head").appendChild(arabicStyleLink);
          // }
  
        } else {
          languageCodeCookie = LanguagesConstant.AR;
          strings.setLanguage(LanguagesConstant.AR);
          // props.changeLanguage();
          localStorage.setItem("language-code", LanguagesConstant.AR);
  
          // const arabicStyleLink = document.querySelector("#arabicStyleLink");
          // if (arabicStyleLink !== null) {
          //   document.querySelector("head").removeChild(arabicStyleLink);
          // }
  
          if (window.location.href.indexOf("/ar") === -1) {
            window.location.href = window.location.href.endsWith('/') ?
              `${window.location.href}ar` : `${window.location.href}/ar`;
          }
  
        }
  
      } else {
  
        if (languageCodeCookie === LanguagesConstant.AR) {
          if (window.location.href.indexOf("/ar") !== -1
            || (window.location.href.indexOf("/ar") === -1
              && window.location.href.indexOf("/en") === -1)) {
  
            // let arabicStyleLink = document.querySelector("#arabicStyleLink");
            // if (arabicStyleLink === null) {
            //     arabicStyleLink = document.createElement('link');
            //     arabicStyleLink.href = "/arStyle.css";
            //     arabicStyleLink.id = "arabicStyleLink";
            //     arabicStyleLink.rel = "stylesheet";
  
  
            //     document.querySelector("head").appendChild(arabicStyleLink);
            // }
            strings.setLanguage(LanguagesConstant.AR);
            
            // props.changeLanguage();
  
            if (window.location.href.indexOf("/ar") === -1) {
              window.location.href = window.location.href.endsWith('/') ?
                `${window.location.href}ar` : `${window.location.href}/ar`;
            }
            
  
          } else if (window.location.href.indexOf("/en") !== -1) {
            // const arabicStyleLink = document.querySelector("#arabicStyleLink");
            // if (arabicStyleLink !== null) {
            //     document.querySelector("head").removeChild(arabicStyleLink);
            // }
  
            strings.setLanguage(LanguagesConstant.EN);
            //props.changeLanguage();
            localStorage.setItem("language-code", LanguagesConstant.EN);
          }
  
  
  
        } else {
  
          if (window.location.href.indexOf("/en") !== -1
            || (window.location.href.indexOf("/en") === -1
              && window.location.href.indexOf("/ar") === -1)) {
  
            // const arabicStyleLink = document.querySelector("#arabicStyleLink");
            // if (arabicStyleLink !== null) {
            //     document.querySelector("head").removeChild(arabicStyleLink);
            // }
  
            strings.setLanguage(languageCodeCookie);
            //props.changeLanguage();
  
            if (window.location.href.indexOf("/en") === -1) {
              window.location.href = window.location.href.endsWith('/') ?
                `${window.location.href}en` : `${window.location.href}/en`;
            }
          } else if (window.location.href.indexOf("/ar") !== -1) {
            // let arabicStyleLink = document.querySelector("#arabicStyleLink");
            // if (arabicStyleLink === null) {
            //     arabicStyleLink = document.createElement('link');
            //     arabicStyleLink.href = "/arStyle.css";
            //     arabicStyleLink.id = "arabicStyleLink";
            //     arabicStyleLink.rel = "stylesheet";
  
  
            //     document.querySelector("head").appendChild(arabicStyleLink);
            // }
  
            strings.setLanguage(LanguagesConstant.AR);
            //props.changeLanguage();
            localStorage.setItem("language-code", LanguagesConstant.AR);
          }
        }
  
      }
}