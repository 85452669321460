import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInCompanyReservationsConstant } from "../../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant";
import { ReservationTypesConstant } from "../../constants/ReservationTypesConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import Loading from "../Shared/Loading/Loading";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";
import PhoneInput, {
  parsePhoneNumber,
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);


function Edit(props) {
  const formikRef = React.useRef(null);

  const [companyError, setCompanyError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reservationTypeError, setReservationTypeError] = useState(null);
  const [companyReservationTimeError, setCompanyReservationTimeError] = useState(null);
  const [companyReservationDateError, setCompanyReservationDateError] = useState(null);
  const [selectedTicketTypeId, setSelectedTicketTypeId] = useState([]);
  const [selectedTicketOptionId, setSelectedTicketOptionId] = useState([]);
  const [maxCount, setMaxCount] = React.useState(0);
  const [toTimeError, setToTimeError] = useState(null);
  const [countPrice, setCountPrice] = useState(0);

  useEffect(() => {
    props.getCompaniesInCompanyReservations();
  }, [])

  useEffect(() => {
    if (props.editedCompanyReservationInCompanyReservations != null) {
      props.getCompanyReservationTimesInCompanyReservations(props.editedCompanyReservationInCompanyReservations.reservationTypeSettingId, moment(props.editedCompanyReservationInCompanyReservations.reservationDate).format("YYYY-MM-DD HH:mm:ss"));

    }
  }, [props.editedCompanyReservationInCompanyReservations])

  useEffect(() => {
    if (props.companyReservationTimeInCompanyReservations != null) {

      if (props.editedCompanyReservationInCompanyReservations.reservationTypeId == ReservationTypesConstant.General) {
        props.getTicketTypesInCompanyReservations();
        setSelectedItems([...props.editedCompanyReservationInCompanyReservations.ticketTypesViewModel]);
        setSelectedTicketOptionId(props.editedCompanyReservationInCompanyReservations.ticketTypesViewModel.map(x => x.id))
      } else {
        props.getTicketOptionsInCompanyReservations(props.editedCompanyReservationInCompanyReservations.reservationTypeId);
        let startDate = moment(props.editedCompanyReservationInCompanyReservations.reservationDate).toDate();
        const splittedStartTimeArr = props.companyReservationTimeInCompanyReservations.fromTime.split(":");
        startDate.setHours(splittedStartTimeArr[0]);
        startDate.setMinutes(splittedStartTimeArr[1]);
        props.getToTimesInCompanyReservations(moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
        setSelectedItems([...props.editedCompanyReservationInCompanyReservations.ticketOptionsViewModel]);
        setSelectedTicketOptionId(props.editedCompanyReservationInCompanyReservations.ticketOptionsViewModel.map(x => x.id))
      }

    }
  }, [props.companyReservationTimeInCompanyReservations])

  useEffect(() => {
    if (props.ticketTypesInCompanyReservations.length > 0) {
      const updatedTypes = props.editedCompanyReservationInCompanyReservations.ticketTypesViewModel.map(item => {
        const ticketType = props.ticketTypesInCompanyReservations.filter(x => x.value == item.id)[0];
        return ({
          ...item,
          label: ticketType.label
        })
      });
      setSelectedItems(updatedTypes);
      setSelectedTicketTypeId(props.editedCompanyReservationInCompanyReservations.ticketTypesViewModel.map(x => x.id))
    }
  }, [props.ticketTypesInCompanyReservations])

  useEffect(() => {
    if (props.ticketOptionsInCompanyReservations.length > 0) {
      let total = 0;
      const updatedOptions = props.editedCompanyReservationInCompanyReservations.ticketOptionsViewModel.map(item => {
        const ticketOption = props.ticketOptionsInCompanyReservations.filter(x => x.value == item.id)[0];
        total += item.count * item.price;
        return ({
          ...item,
          label: ticketOption.label
        })
      });
      setSelectedItems(updatedOptions);
      setSelectedTicketOptionId(props.editedCompanyReservationInCompanyReservations.ticketOptionsViewModel.map(x => x.id));
      if (props.editedCompanyReservationInCompanyReservations.reservationTypeId == ReservationTypesConstant.SchoolTrip) {
        total = props.editedCompanyReservationInCompanyReservations.totalAmount - total;
        setCountPrice(total);
        formikRef.current.setFieldValue("price", total / props.editedCompanyReservationInCompanyReservations.count);
      }
    }
  }, [props.ticketOptionsInCompanyReservations])

  useEffect(() => {
    if (props.editCompanyReservationResult !== null && props.editCompanyReservationResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editCompanyReservationResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInCompanyReservations(DisplayTypesInCompanyReservationsConstant.LIST)
      }
      )
    }
  }, [props.editCompanyReservationResult])

  useEffect(() => {
    if (props.editCompanyReservationError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editCompanyReservationError,
        'error'
      ).then(result => {

        setSelectedItems([]);
        setSelectedTicketTypeId([]);
        setSelectedTicketOptionId([]);
        setMaxCount(0);
        setCountPrice(0);
      }
      )
    }
  }, [props.editCompanyReservationError])

  function handleCompanyChanges(selectedOption) {
    if (selectedOption == null) {
      setCompanyError(strings.pleaseSelectCompany)
    } else {
      setCompanyError(null)
    }
    props.updateCompaniesInCompanyReservations(selectedOption);
  }

  function handleReservationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTypeError(strings.pleaseSelectReservationType)
    } else {
      setReservationTypeError(null);
      if (selectedOption.reservationTypeId == ReservationTypesConstant.Birthday) {
        formikRef.current.setFieldValue("totalAmount", selectedOption.price);
        formikRef.current.setFieldValue("count", 1);
      } else {
        formikRef.current.setFieldValue("totalAmount", "");
        formikRef.current.setFieldValue("count", "");
      }
    }
    props.updateReservationTypesInCompanyReservations(selectedOption);
  }

  function handleCompanyReservationTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setCompanyReservationTimeError(strings.pleaseSelectTime)
    } else {
      setMaxCount(selectedOption.availablePlaces);
      setCompanyReservationTimeError(null);
      if (props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.General) {
        props.getTicketTypesInCompanyReservations();
      } else {
        props.getTicketOptionsInCompanyReservations(props.reservationTypeInCompanyReservations.reservationTypeId);
        let startDate = moment(formikRef.current.values.companyReservationDate).toDate();
        const splittedStartTimeArr = selectedOption.fromTime.split(":");
        startDate.setHours(splittedStartTimeArr[0]);
        startDate.setMinutes(splittedStartTimeArr[1]);
        props.getToTimesInCompanyReservations(moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
      }
    }
    props.updateCompanyReservationTimesInCompanyReservations(selectedOption);
  }

  function handleCompanyReservationDateChange(value) {
    if (!moment(value).isValid()) {
      setCompanyReservationDateError(strings.dateNotValid);
    } else {
      setCompanyReservationDateError(null);
      formikRef.current.setFieldValue("companyReservationDate", value);
      props.getCompanyReservationTimesInCompanyReservations(props.reservationTypeInCompanyReservations.value, moment(value).format("YYYY-MM-DD HH:mm:ss"));
      //props.updateSelectedDateInCompanyReservations(props.reservationTypeInCompanyReservations.value, moment(value).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  function handleToTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setToTimeError(strings.pleaseSelectToTime)
    } else {
      setToTimeError(null);
    }
    props.updateToTimesInCompanyReservations(selectedOption);
  }

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.reservationDate} *`}
      name="reservationDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));
  function checkMobileAvailabilityInUsers(value) {
    if (value !== undefined && value !== "") {
      props.checkMobileAvailabilityInUsers(value);
    }
  }

  const handlePhoneNumberChanges = (value) => {
    formikRef.current.setFieldValue("phoneNumber", value);
  };

  function removeItem(id) {
    var tickets = 0;
    var total = 0;
    let updatedType = selectedItems.filter(x => x.id != id);
    updatedType.forEach(item => {
      tickets += item.count;
      total += item.count * item.price;
    });
    setSelectedItems(updatedType);
    setSelectedTicketTypeId(updatedType.map(x => x.id))
    formikRef.current.setFieldValue("count", tickets);
    formikRef.current.setFieldValue("totalAmount", total);
  }

  function removeItemOption(id) {
    var total = props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.Birthday ? props.reservationTypeInCompanyReservations.price : countPrice;
    let updatedType = selectedItems.filter(x => x.id != id);
    updatedType.forEach(item => {
      total += item.count * item.price;
    });
    setSelectedItems(updatedType);
    setSelectedTicketOptionId(updatedType.map(x => x.id))
    //formikRef.current.setFieldValue("count", tickets);
    formikRef.current.setFieldValue("totalAmount", total);
  }

  function handleCountPriceChange(count, price) {
    if (count != '' && count != null && price != '' && price != null) {
      var total = count * price;
      selectedItems.forEach(item => {
        total += item.count * item.price;
      });
      setCountPrice(total);
      formikRef.current.setFieldValue("totalAmount", total);
    } else {
      var total = 0;
      selectedItems.forEach(item => {
        total += item.count * item.price;
      });
      setCountPrice(total);
      formikRef.current.setFieldValue("totalAmount", total);
    }
  }

  return (
    <>
      {props.isEditingCompanyReservation ? (<Loading />)
        : (
          <div className="customer-data cus2 mt-5">
            <div className="card animate__animated  animate__backInLeft">
              <div className="card-header border-0 d-flex align-items-center">
                <h3 className="card-title col-9">
                  <span className="card-label fw-bolder text-dark">
                    {strings.editCompanyReservation}
                  </span>
                </h3>
              </div>
              <div id="kt_account_profile_details" className="collapse show">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={{
                    userName: props.editedCompanyReservationInCompanyReservations != null ? props.editedCompanyReservationInCompanyReservations.userNameEn : "",
                    totalAmount: props.editedCompanyReservationInCompanyReservations != null ? props.editedCompanyReservationInCompanyReservations.totalAmount : "",
                    count: props.editedCompanyReservationInCompanyReservations != null ? props.editedCompanyReservationInCompanyReservations.count : "",
                    phoneNumber: props.editedCompanyReservationInCompanyReservations != null ? props.editedCompanyReservationInCompanyReservations.phoneNumber : "",
                    price: '',
                    companyReservationDate: props.editedCompanyReservationInCompanyReservations != null ? new Date(props.editedCompanyReservationInCompanyReservations.reservationDate) : null,
                  }}
                  validationSchema={Yup.object().shape({
                    userName: Yup.string()
                      .required(strings.pleaseEnterUserName),
                    count: Yup.number()
                      .integer(strings.invalidInputs)
                      .required(strings.pleaseEnterCount),
                    price: Yup.number()
                      .integer(strings.invalidInputs),
                    phoneNumber: Yup.string()
                      .required(strings.mobileRequierd)
                      .test(
                        'is-phoneNumber',
                        strings.inValidMobileNumber,
                        (value) => {
                          if (!value) {
                            return true;
                          }
                          return isPossiblePhoneNumber(value, "KW");
                        }),
                  })}
                  onSubmit={(fields) => {
                    let isValid = true;

                    if (props.companyInCompanyReservations == null) {
                      setCompanyError(strings.pleaseSelectCompany)
                      isValid = false;
                    }

                    if (props.reservationTypeInCompanyReservations == null) {
                      setReservationTypeError(strings.pleaseSelectReservationType)
                      isValid = false;
                    }

                    if (props.companyReservationTimeInCompanyReservations == null) {
                      setCompanyReservationTimeError(strings.pleaseSelectTime)
                      isValid = false;
                    }

                    if (props.reservationTypeInCompanyReservations !== null && props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.General && selectedItems.length == 0) {
                      isValid = false;
                    }

                    if (props.reservationTypeInCompanyReservations !== null && props.reservationTypeInCompanyReservations.reservationTypeId != ReservationTypesConstant.General && props.toTimeInCompanyReservations == null) {
                      setToTimeError(strings.pleaseSelectToTime);
                      isValid = false;
                    }

                    if (props.reservationTypeInCompanyReservations !== null && props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.General && fields.count > maxCount) {
                      isValid = false;
                      formikRef.current.setFieldError("count", strings.countMustBeLessThanIt)
                    }

                    if (props.reservationTypeInCompanyReservations !== null && props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.SchoolTrip && fields.price == '' || fields.price == null) {
                      isValid = false;
                      formikRef.current.setFieldError("price", strings.priceRequired)
                    }

                    if (isValid) {

                      let startDate = moment(fields.companyReservationDate).toDate();
                      const splittedStartTimeArr = props.companyReservationTimeInCompanyReservations.fromTime.split(":");
                      startDate.setHours(splittedStartTimeArr[0]);
                      startDate.setMinutes(splittedStartTimeArr[1]);

                      const companyReservationViewModel = {
                        id: props.editedCompanyReservationInCompanyReservations.id,
                        companyId: props.companyInCompanyReservations.value,
                        reservationTypeSettingId: props.reservationTypeInCompanyReservations.value,
                        reservationScheduleId: props.companyReservationTimeInCompanyReservations.reservationScheduleId,
                        reservationDate: moment(fields.companyReservationDate).format("YYYY-MM-DD HH:mm:ss"),
                        fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                        count: fields.count,
                        amount: fields.totalAmount,
                        totalAmount: fields.totalAmount,
                        phoneNumber: fields.phoneNumber,
                        userName: fields.userName
                      };

                      if (props.reservationTypeInCompanyReservations !== null && props.reservationTypeInCompanyReservations.reservationTypeId != ReservationTypesConstant.General) {
                        let endDate = moment(fields.reservationDate).toDate();
                        const splittedEndTimeArr = props.toTimeInCompanyReservations.toTime.split(":");
                        endDate.setHours(splittedEndTimeArr[0]);
                        endDate.setMinutes(splittedEndTimeArr[1]);

                        companyReservationViewModel.toTime = moment(endDate).format("YYYY-MM-DD HH:mm:ss");
                        companyReservationViewModel.ticketOptionsViewModel = selectedItems;
                      } else {
                        companyReservationViewModel.ticketTypesViewModel = selectedItems;
                      }

                      props.editCompanyReservation(companyReservationViewModel);
                    }
                  }}
                  render={({
                    errors,
                    touched,
                    isValidating,
                    values,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError,
                    handleBlur,
                    handleChange
                  }) => (
                    <Form className="form1">
                      <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.company}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Select
                              name="company"
                              options={props.companiesInCompanyReservations}
                              placeholder={strings.selectCompany}
                              value={props.companyInCompanyReservations}
                              onChange={handleCompanyChanges}
                              isSearchable
                              isClearable
                              isLoading={props.isGetCompaniesInCompanyReservations}
                              className={companyError != null ? "is-invalid" : ""}
                            />
                            {companyError && (
                              <div className="invalid-feedback block">
                                {companyError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.reservationType}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Select
                              name="reservationType"
                              options={props.reservationTypesInCompanyReservations}
                              placeholder={strings.selectReservationType}
                              value={props.reservationTypeInCompanyReservations}
                              onChange={handleReservationTypeChanges}
                              isSearchable
                              isLoading={props.isGetReservationTypesInCompanyReservations}
                              className={reservationTypeError != null ? "is-invalid" : ""}
                            />
                            {reservationTypeError && (
                              <div className="invalid-feedback block">
                                {reservationTypeError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.reservationDate}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <DatePicker placeholderText={strings.from}
                              customInput={<StartDateInput />}
                              locale={strings.getLanguage() === LanguagesConstant.AR ?
                                "ar" : "en"}
                              selected={values.companyReservationDate}
                              onChange={handleCompanyReservationDateChange}
                              minDate={new Date(moment())}
                              dateFormat="dd/MM/yyyy"
                              className={companyReservationDateError !== null ? 'red-border' : ''} />
                            {
                              companyReservationDateError && (
                                <div className="invalid-feedback block">
                                  {companyReservationDateError}
                                </div>
                              )
                            }

                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.selectTime}</label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Select
                              name="time"
                              options={props.companyReservationTimesInCompanyReservations}
                              placeholder={strings.selectTime}
                              value={props.companyReservationTimeInCompanyReservations}
                              onChange={handleCompanyReservationTimeChanges}
                              isSearchable
                              isClearable
                              isLoading={props.isGettingCompanyReservationTimesInCompanyReservations}
                              className={companyReservationTimeError != null ? "is-invalid" : ""}
                            />
                            {companyReservationTimeError && (
                              <div className="invalid-feedback block">
                                {companyReservationTimeError}
                              </div>
                            )}
                          </div>
                        </div>
                        {
                          props.reservationTypeInCompanyReservations != null
                          && props.reservationTypeInCompanyReservations.reservationTypeId != ReservationTypesConstant.General
                          && (
                            <div className="col-lg-6 col-sm-12 mb-3">
                              <label className="col-lg-6 col-form-label required">{strings.toTime}</label>
                              <div className="col-lg-10 col-sm-12 fv-row">
                                <Select
                                  name="toTime"
                                  options={props.toTimesInCompanyReservations}
                                  placeholder={strings.selectToTime}
                                  value={props.toTimeInCompanyReservations}
                                  onChange={handleToTimeChanges}
                                  isSearchable
                                  isClearable
                                  isLoading={props.isGettingToTimesInCompanyReservations}
                                  className={toTimeError != null ? "is-invalid" : ""}
                                />
                                {
                                  toTimeError && (
                                    <div className="invalid-feedback d-block">
                                      {toTimeError}
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.mobile}
                          </label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <PhoneInput
                              className={
                                "form-control px-3" +
                                (errors.phoneNumber && touched.phoneNumber
                                  ? " is-invalid"
                                  : "")
                              }
                              international={false}
                              defaultCountry="KW"
                              countries={["KW"]}
                              addInternationalOption={false}
                              countryCallingCodeEditable={false}
                              value={values.phoneNumber}
                              limitMaxLength
                              onChange={handlePhoneNumberChanges}
                              placeholder={strings.enterMobile}
                              onFocus={(e) => setFieldTouched("phoneNumber", false)}
                              onBlur={(e) => {
                                setFieldTouched("phoneNumber", true);
                                checkMobileAvailabilityInUsers(values.phoneNumber);
                              }}
                            //}}
                            />
                            {
                              errors.phoneNumber && touched.phoneNumber
                              && <ErrorMessage name="phoneNumber" component="div"
                                className="invalid-feedback" />
                            }
                            {
                              !errors.phoneNumber
                              && !props.isMobileAvailableInUsers
                              && (
                                <div className="invalid-feedback d-inline-block">
                                  {strings.mobileEnteredBefore}
                                </div>
                              )
                            }


                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.userName}</label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="text"
                              name="userName"
                              className={
                                "form-control" +
                                (errors.userName &&
                                  touched.userName
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={`${strings.userName} *`}
                            />
                            <ErrorMessage
                              name="userName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                        </div>
                        {
                          props.ticketTypesInCompanyReservations !== []
                          && props.ticketTypesInCompanyReservations.length > 0
                          && (
                            <Formik
                              initialValues={{
                                ticketType: null,
                                ticketTypeCount: 0,
                                ticketTypePrice: 0,
                              }}
                              validationSchema={Yup.object().shape({
                                ticketTypeCount: Yup.number()
                                  .integer(strings.invalidInputs)
                                  .required(strings.pleaseEnterCount),
                                ticketTypePrice: Yup.number()
                                  .required(strings.pleaseEnterPrice),
                                ticketType: Yup.object()
                                  .required(strings.pleaseSelectTicketType),
                              })}
                              onSubmit={(fields, { resetForm }) => {
                                var tickets = 0;
                                var total = 0;
                                let updatedType = [...selectedItems, { id: fields.ticketType.value, price: fields.ticketTypePrice, count: fields.ticketTypeCount, label: fields.ticketType.label }];
                                updatedType.forEach(item => {
                                  tickets += item.count;
                                  total += item.count * item.price;
                                });
                                resetForm();
                                setSelectedItems(updatedType);
                                setSelectedTicketTypeId(updatedType.map(x => x.id))
                                setFieldValue("count", tickets);
                                setFieldValue("totalAmount", total);
                              }}
                              render={form => (
                                <Form className="form1">
                                  <div className="row border p-5 my-5">
                                    <span className="card-label fw-bolder text-dark">
                                      {strings.ticketTypes}
                                    </span>
                                    <div className="row my-5">
                                      <div className="col-lg-4 col-sm-12 mb-3">
                                        <label className="col-lg-6 col-form-label required">
                                          {strings.ticketTypes}</label>
                                        <div className="col-lg-10 col-sm-12 fv-row">
                                          <Select
                                            options={props.ticketTypesInCompanyReservations.filter(x => !selectedTicketTypeId.includes(x.value))}
                                            placeholder={strings.selectTicketType}
                                            value={form.values.ticketType}
                                            onChange={(value) => {
                                              form.setFieldError("ticketType", null);
                                              form.setFieldTouched("ticketType", true);
                                              form.setFieldValue("ticketType", value);
                                            }}
                                            isClearable
                                            className={form.errors.ticketType != null ? "is-invalid" : ""}
                                          />
                                          <ErrorMessage
                                            name="ticketType"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-sm-12 mb-3">
                                        <label className="col-lg-6 col-form-label required">
                                          {strings.count}</label>
                                        <div className="col-lg-10 col-sm-12 fv-row">
                                          <Field
                                            type="number"
                                            name="ticketTypeCount"
                                            className={
                                              "form-control" +
                                              (form.errors.ticketTypeCount &&
                                                form.touched.ticketTypeCount
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder={`${strings.enterCount} *`}
                                          />
                                          <ErrorMessage
                                            name="ticketTypeCount"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                      </div>
                                      <div className="col-lg-3 col-sm-12 mb-3">
                                        <label className="col-lg-6 col-form-label required">
                                          {strings.price}</label>
                                        <div className="col-lg-10 col-sm-12 fv-row">
                                          <Field
                                            type="number"
                                            name="ticketTypePrice"
                                            className={
                                              "form-control" +
                                              (form.errors.ticketTypePrice &&
                                                form.touched.ticketTypePrice
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder={`${strings.enterPrice} *`}
                                          />
                                          <ErrorMessage
                                            name="ticketTypePrice"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                      </div>
                                      <div className="col-lg-2 col-sm-12 mb-3">
                                        <button type="button" onClick={form.handleSubmit} className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem", marginTop: "2.5rem" }}
                                        >
                                          {strings.create}
                                        </button>
                                      </div>

                                    </div>
                                    {
                                      selectedItems.length > 0 && selectedItems != [] && (
                                        <div className="table-responsive p-0">
                                          <table className="table align-middle text-center table-bordered border-bottom m-0">
                                            <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                                              <tr className="fw-bolder text-muted text-center align-middle bg-light">
                                                <th className="ps-4 min-w-125px px-0">{strings.ticketType}</th>
                                                <th className="ps-4 min-w-125px px-0">{strings.count}</th>
                                                <th className="min-w-125px px-0">{strings.price}</th>
                                                <th className="min-w-125px px-0">{strings.delete}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                              {
                                                selectedItems.map((item, index) => {
                                                  return <tr key={index}>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.label}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.count}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.price}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="d-flex justify-content-center">
                                                        <div
                                                          className="actions"
                                                          style={{ backgroundColor: "#ffffff" }}
                                                        >

                                                          <div className="action2">
                                                            <a
                                                              onClick={() => removeItem(item.id)}
                                                            >
                                                              <i className="fas fa-trash delete"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                })
                                              }

                                            </tbody>
                                          </table>

                                        </div>
                                      )}
                                  </div>
                                </Form>
                              )}
                            />
                          )
                        }
                        {
                          props.ticketOptionsInCompanyReservations !== []
                          && props.ticketOptionsInCompanyReservations.length > 0
                          && (
                            <Formik
                              initialValues={{
                                ticketOption: null,
                                ticketOptionCount: 1,
                                ticketOptionPrice: null,
                              }}
                              validationSchema={Yup.object().shape({
                                ticketOptionCount: Yup.number()
                                  .integer(strings.invalidInputs)
                                  .min(1, strings.pleaseEnterNumberGreaterThanIt),
                                ticketOptionPrice: Yup.number()
                                  .required(strings.pleaseEnterPrice)
                                  .min(1, strings.pleaseEnterNumberGreaterThanIt),
                                ticketOption: Yup.object()
                                  .required(strings.pleaseSelectTicketOption),
                              })}
                              onSubmit={(fields, { resetForm }) => {
                                var total = props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.Birthday ? props.reservationTypeInCompanyReservations.price : countPrice;
                                let updatedOption = [...selectedItems, { id: fields.ticketOption.value, price: fields.ticketOptionPrice, count: fields.ticketOptionCount, label: fields.ticketOption.label }];
                                updatedOption.forEach(item => {
                                  total += item.count * item.price;
                                });
                                resetForm();
                                setSelectedItems(updatedOption);
                                setSelectedTicketOptionId(updatedOption.map(x => x.id))
                                //setFieldValue("count", tickets);
                                setFieldValue("totalAmount", total);
                              }}
                              render={form => (
                                <Form className="form1">
                                  <div className="row border p-5 my-5">
                                    <span className="card-label fw-bolder text-dark">
                                      {strings.ticketOptions}
                                    </span>
                                    <div className="row my-5">
                                      <div className="col-lg-4 col-sm-12 mb-3">
                                        <label className="col-lg-6 col-form-label required">
                                          {strings.ticketOptions}</label>
                                        <div className="col-lg-10 col-sm-12 fv-row">
                                          <Select
                                            options={props.ticketOptionsInCompanyReservations.filter(x => !selectedTicketOptionId.includes(x.value))}
                                            placeholder={strings.selectTicketOption}
                                            value={form.values.ticketOption}
                                            onChange={(value) => {
                                              form.setFieldError("ticketOption", null);
                                              form.setFieldTouched("ticketOption", true);
                                              form.setFieldValue("ticketOption", value);
                                            }}
                                            isClearable
                                            className={form.errors.ticketOption != null ? "is-invalid" : ""}
                                          />
                                          <ErrorMessage
                                            name="ticketOption"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                      {
                                        form.values.ticketOption != null
                                        && form.values.ticketOption.isInput
                                        && (
                                          <div className="col-lg-3 col-sm-12 mb-3">
                                            <label className="col-lg-6 col-form-label required">
                                              {strings.count}</label>
                                            <div className="col-lg-10 col-sm-12 fv-row">
                                              <Field
                                                type="number"
                                                name="ticketOptionCount"
                                                className={
                                                  "form-control" +
                                                  (form.errors.ticketOptionCount &&
                                                    form.touched.ticketOptionCount
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder={`${strings.enterCount} *`}
                                              />
                                              <ErrorMessage
                                                name="ticketOptionCount"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>

                                          </div>
                                        )
                                      }

                                      <div className="col-lg-3 col-sm-12 mb-3">
                                        <label className="col-lg-6 col-form-label required">
                                          {strings.price}</label>
                                        <div className="col-lg-10 col-sm-12 fv-row">
                                          <Field
                                            type="number"
                                            name="ticketOptionPrice"
                                            className={
                                              "form-control" +
                                              (form.errors.ticketOptionPrice &&
                                                form.touched.ticketOptionPrice
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder={`${strings.enterPrice} *`}
                                          />
                                          <ErrorMessage
                                            name="ticketOptionPrice"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                      </div>
                                      <div className="col-lg-2 col-sm-12 mb-3">
                                        <button Option="button" onClick={form.handleSubmit} className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem", marginTop: "2.5rem" }}
                                        >
                                          {strings.create}
                                        </button>
                                      </div>

                                    </div>
                                    {
                                      selectedItems.length > 0 && selectedItems != [] && (
                                        <div className="table-responsive p-0">
                                          <table className="table align-middle text-center table-bordered border-bottom m-0">
                                            <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                                              <tr className="fw-bolder text-muted text-center align-middle bg-light">
                                                <th className="ps-4 min-w-125px px-0">{strings.ticketOption}</th>
                                                <th className="ps-4 min-w-125px px-0">{strings.count}</th>
                                                <th className="min-w-125px px-0">{strings.price}</th>
                                                <th className="min-w-125px px-0">{strings.delete}</th>
                                              </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                              {
                                                selectedItems.map((item, index) => {
                                                  return <tr key={index}>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.label}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.count}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start m-auto flex-column'>
                                                          <h4>{item.price}</h4>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="d-flex justify-content-center">
                                                        <div
                                                          className="actions"
                                                          style={{ backgroundColor: "#ffffff" }}
                                                        >

                                                          <div className="action2">
                                                            <a
                                                              onClick={() => removeItemOption(item.id)}
                                                            >
                                                              <i className="fas fa-trash delete"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                })
                                              }

                                            </tbody>
                                          </table>

                                        </div>
                                      )}
                                  </div>
                                </Form>
                              )}
                            />
                          )
                        }
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.count}</label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="text"
                              name="count"
                              className={
                                "form-control" +
                                (errors.count &&
                                  touched.count
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(e) => {
                                handleChange(e);
                                handleCountPriceChange(e.target.value, values.price);
                              }}
                              placeholder={`${strings.enterCount} *`}
                              disabled={props.reservationTypeInCompanyReservations == null || (props.reservationTypeInCompanyReservations != null && props.reservationTypeInCompanyReservations.reservationTypeId != ReservationTypesConstant.SchoolTrip)}
                            />
                            <ErrorMessage
                              name="count"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                        </div>
                        {
                          props.reservationTypeInCompanyReservations != null && props.reservationTypeInCompanyReservations.reservationTypeId == ReservationTypesConstant.SchoolTrip
                          && (
                            <div className="col-lg-6 col-sm-12 mb-3">
                              <label className="col-lg-6 col-form-label required">
                                {strings.price}</label>
                              <div className="col-lg-10 col-sm-12 fv-row">
                                <Field
                                  type="number"
                                  name="price"
                                  className={
                                    "form-control" +
                                    (errors.price &&
                                      touched.price
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder={`${strings.enterPrice} *`}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleCountPriceChange(values.count, e.target.value);
                                  }}
                                />
                                <ErrorMessage
                                  name="price"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          )
                        }
                        <div className="col-lg-6 col-sm-12 mb-3">
                          <label className="col-lg-6 col-form-label required">
                            {strings.totalAmount}</label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <Field
                              type="text"
                              name="totalAmount"
                              className={
                                "form-control" +
                                (errors.totalAmount &&
                                  touched.totalAmount
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder={`${strings.enterAmount} *`}
                              disabled={true}
                            />
                            <ErrorMessage
                              name="totalAmount"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                        </div>

                        <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                          <div className="col-11">
                            <button type="submit" className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                            >
                              {strings.save}
                            </button>
                          </div>

                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
}
export default Edit;
