import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';   
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { Provider } from 'react-redux';
import  rootReducer  from './reducers/rootReducer';
import { rootEpic } from './epics/rootEpic';
import './fontawesome/css/all.min.css';
import './fontawesome/css/fontawesome.min.css';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
const epicMiddleware = createEpicMiddleware();

const store = createStore(rootReducer, applyMiddleware(epicMiddleware));

epicMiddleware.run(rootEpic);


const appWithProvider = (
    <Provider store={store}>
        <App />
    </Provider>
);



ReactDOM.render(appWithProvider, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
