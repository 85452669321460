import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import moment from 'moment';
import "moment/locale/ar-sa";

import {
    GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
    getReservationTypesInCompanyReservationsSuccess,
    getReservationTypesInCompanyReservationsFailure
} from '../../actions/CompanyReservations/getReservationTypesInCompanyReservationsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getReservationTypesInCompanyReservationsEpic(action$) {
    return action$.pipe(
        ofType(GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS),
        switchMap(action => {

            const ajaxConfigObj = {
                url: url + `Reservation/GetReservationTypes`,
                method: 'Post',
                headers: {
                    'Authorization': token,
                    //'content-type': 'application/json'
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => ({
                    response: data.response,
                })))
        }),
        mergeMap(data => {
            if (data.response.isSuccess) {
                const reservationTypes = data.response.data.map(item => ({
                    label: strings.getLanguage() === LanguagesConstant.AR ? item.nameAr : item.nameEn,
                    value: item.id,
                    reservationTypeId: item.reservationTypeId,
                    price: item.price
                }))
                return of(getReservationTypesInCompanyReservationsSuccess(reservationTypes));
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                    && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getReservationTypesInCompanyReservationsFailure(message));
            }
        }),
        catchError(error => of(getReservationTypesInCompanyReservationsFailure(strings.somethingWrongHappened)))
    )
}

