import React, { useEffect, useState } from "react";
import strings from "../../localizations/strings";
import Select from 'react-select';
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationTypeSettingsConstant } from "../../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant";
import Loading from "../Shared/Loading/Loading";
function Edit(props) {

  const [reservationTypeError, setReservationTypeError] = useState(null);

  useEffect(() => {
    props.getReservationTypesInReservationTypeSettings(props.editedReservationTypeSettingInReservationTypeSettings.reservationTypeId);
  }, [])

  useEffect(() => {
    if (
      props.editReservationTypeSettingResult !== null &&
      props.editReservationTypeSettingResult.isSuccess
    ) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editReservationTypeSettingResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInReservationTypeSettings(
          DisplayTypesInReservationTypeSettingsConstant.LIST
        );
      });
    }
  }, [props.editReservationTypeSettingResult]);

  useEffect(() => {
    if (props.editReservationTypeSettingError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editReservationTypeSettingError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editReservationTypeSettingError])

  function handleReservationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTypeError(strings.pleaseSelectReservationType)
    } else {
      setReservationTypeError(null)
    }
    props.updateReservationTypesInReservationTypeSettings(selectedOption);
  }

  return (
    <>
     { props.isEditingReservationTypeSetting && ( <Loading />)}
     {  !props.isEditingReservationTypeSetting &&
     (
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.editReservationSetting}
              </span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              initialValues={
                {
                  count:props.editedReservationTypeSettingInReservationTypeSettings !== null ? props.editedReservationTypeSettingInReservationTypeSettings.count : "",
                  time: props.editedReservationTypeSettingInReservationTypeSettings !== null ? props.editedReservationTypeSettingInReservationTypeSettings.time : "",
                  price: props.editedReservationTypeSettingInReservationTypeSettings !== null ? props.editedReservationTypeSettingInReservationTypeSettings.price : "",
                }
              }
              validationSchema={Yup.object().shape({
                count: Yup.number()
                  .integer(strings.pleaseEnterIntegerNumber)
                  .min(1, strings.pleaseEnterNumberGreaterThanIt)
                  .required(
                    strings.pleaseEnterCount
                  ),
                time: Yup.number()
                  .integer(strings.pleaseEnterIntegerNumber)
                  .min(1, strings.pleaseEnterNumberGreaterThanIt)
                  .required(
                    strings.pleaseEnterTime
                  ),
                price: Yup.number()
                  .integer(strings.pleaseEnterIntegerNumber)
                  .min(1, strings.pleaseEnterNumberGreaterThanIt)
                  .required(
                    strings.pleaseEnterPrice
                  ),
              })}
              onSubmit={(fields) => {
                if (props.reservationTypeInReservationTypeSettings === null) {
                  setReservationTypeError(strings.pleaseSelectReservationType);
                } else {
                  const reservationTypeSetting = {
                    id:props.editedReservationTypeSettingInReservationTypeSettings.id,
                    reservationTypeId: props.reservationTypeInReservationTypeSettings.value,
                    count: fields.count,
                    time: fields.time,
                    price: fields.price,
                    isActive: props.editedReservationTypeSettingInReservationTypeSettings.isActive
                  }
                  props.editReservationTypeSetting(reservationTypeSetting);
                }
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                 <div className="card-body row mx-0 border-top d-flex"style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.reservationType}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Select
                          name="reservationType"
                          options={props.reservationTypesInReservationTypeSettings}
                          placeholder={strings.selectReservationType}
                          value={props.reservationTypeInReservationTypeSettings}
                          onChange={handleReservationTypeChanges}
                          isSearchable
                          isClearable
                          isLoading={props.isGetReservationTypesInReservationTypeSettings}
                          className={reservationTypeError != null ? "is-invalid" : ""}
                        />
                        {reservationTypeError && (
                          <div className="invalid-feedback block">
                            {reservationTypeError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.count}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="count"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.count &&
                              touched.count
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterCount} *`}
                        />
                        <ErrorMessage
                          name="count"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.time}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="time"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.time &&
                              touched.time
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterTime} *`}
                        />
                        <ErrorMessage
                          name="time"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.price}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="price"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.price &&
                              touched.price
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterPrice} *`}
                        />
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between p-3">
                    <div>
                      <button
                        type="submit"
                        className="btn fs-6"
                        style={{
                          backgroundColor: "#009ef7",
                          borderColor: "#009ef7",
                          color: "white",
                          borderRadius: "0.75rem",
                        }}
                        id="kt_account_profile_details_submit"
                      //	onClick={done}
                      >
                        {strings.save}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
     )
    }
    </>
  );
}
export default Edit;
