import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_SCHOOL_TOUR_RANGE,
    getEditedSchoolTourRangeSuccess,
    getEditedSchoolTourRangeFailure
} from '../../actions/SchoolTourRanges/getEditedSchoolTourRangeActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedSchoolTourRangeEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_SCHOOL_TOUR_RANGE),
        map(action =>({
            schoolTourRangeId : action.schoolTourRangeId 
        })),
        map(response => 
           getEditedSchoolTourRangeSuccess(response.schoolTourRangeId)
           
        ),
        catchError(error => of(getEditedSchoolTourRangeFailure(strings.somethingWrongHappened)))
    )
}

