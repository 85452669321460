import {
    ADD_NEW_USER,
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAILURE
} from '../../actions/Users/addNewUserActions';

export function addNewUserReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_USER:
            return {
                ...state,
                isAddNewUser: true,
                addNewUserError: null,
                addNewUserResult:null,
            };
        case ADD_NEW_USER_SUCCESS:
            return { 
                ...state,
                isAddNewUser: false,
                addNewUserError: null,
                addNewUserResult: action.payload.data,
            };
        case ADD_NEW_USER_FAILURE:
            return {
                ...state,
                isAddNewUser: false,
                addNewUserError: action.payload.message,
            };
        default:
            return state;
}}