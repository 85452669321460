import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_DISPLAY_TYPE_IN_GALLERIES,
    updateDisplayTypeInGalleriesSuccess,
    updateDisplayTypeInGalleriesFailure
} from '../../../src/actions/Galleries/updateDisplayTypeInGalleriesActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInGalleriesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_GALLERIES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInGalleriesSuccess(response.displayType)
            }
        ),
        catchError(error => of(updateDisplayTypeInGalleriesFailure(strings.somethingWrongHappened)))
    )
}

