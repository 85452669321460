import {
    CHECK_MOBILE_AVAILABILITY_IN_USERS,
    CHECK_MOBILE_AVAILABILITY_IN_USERS_SUCCESS,
    CHECK_MOBILE_AVAILABILITY_IN_USERS_FAILURE
} from '../../actions/Users/checkMobileAvailabilityInUsersActions';

export function checkMobileAvailabilityInUsersReducer(state, action) {
    switch (action.type) {
        case CHECK_MOBILE_AVAILABILITY_IN_USERS:
            return {
                ...state,
                isCheckingMobileAvailabilityInUsers: true,
                checkMobileAvailabilityInUsersError: null,
            };
        case CHECK_MOBILE_AVAILABILITY_IN_USERS_SUCCESS:
            return {
                ...state,
                isCheckingMobileAvailabilityInUsers: false,
                isMobileAvailableInUsers: action.payload.isAvailable,
                checkMobileAvailabilityInUsersError: null

            };
        case CHECK_MOBILE_AVAILABILITY_IN_USERS_FAILURE:
            return {
                ...state,
                isCheckingMobileAvailabilityInUsers: false,
                checkMobileAvailabilityInUsersError: action.payload
            };
        default:
            return state;
    }
}