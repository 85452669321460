import {
    EDIT_TICKET_OPTION,
    EDIT_TICKET_OPTION_SUCCESS,
    EDIT_TICKET_OPTION_FAILURE
} from '../../actions/TicketOptions/editTicketOptionActions';

import { DisplayTypesInTicketOptionsConstant } from '../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant';

export function editTicketOptionReducer(state, action) {
    switch (action.type) {
        case EDIT_TICKET_OPTION:
            return {
                ...state,
                isEditingTicketOption: true,
                editTicketOptionError: null,
            };
        case EDIT_TICKET_OPTION_SUCCESS:
            return {
                ...state,
                isEditingTicketOption: false,
                editTicketOptionError: null,
                editTicketOptionResult: action.payload.data,
               // displayTypeInTicketOptions: DisplayTypesInTicketOptionsConstant.LIST,
            };
        case EDIT_TICKET_OPTION_FAILURE:
            return {
                ...state,
                isEditingTicketOption: false,
                editTicketOptionError: action.payload.message,
            };
        default:
            return state;
}}