export const EDIT_ROLE_SCREEN = 'EDIT_ROLE_SCREEN';
export const EDIT_ROLE_SCREEN_SUCCESS = 'EDIT_ROLE_SCREEN_SUCCESS';
export const EDIT_ROLE_SCREEN_FAILURE = 'EDIT_ROLE_SCREEN_FAILURE';

export const editRoleScreen = (roleScreenData) => ({
    type: EDIT_ROLE_SCREEN,
    roleScreenData
});

export const editRoleScreenSuccess = (data) => ({
    type: EDIT_ROLE_SCREEN_SUCCESS,
    payload: { data }
});

export const editRoleScreenFailure = (message) => ({
    type: EDIT_ROLE_SCREEN_FAILURE,
    payload: { message }
});