export const GET_EDITED_RESERVATION = 'GET_EDITED_RESERVATION';
export const GET_EDITED_RESERVATION_SUCCESS = 'GET_EDITED_RESERVATION_SUCCESS';
export const GET_EDITED_RESERVATION_FAILURE = 'GET_EDITED_RESERVATION_FAILURE';

export const getEditedReservation = (reservationId) => ({
    type: GET_EDITED_RESERVATION,
    reservationId
});

export const getEditedReservationSuccess = (reservationId) => ({
    type: GET_EDITED_RESERVATION_SUCCESS,
    payload: { reservationId }
});

export const getEditedReservationFailure = (message) => ({
    type: GET_EDITED_RESERVATION_FAILURE,
    payload: { message }
});
