export const GET_TICKET_OPTIONS_IN_REQUESTS = 'GET_TICKET_OPTIONS_IN_REQUESTS';
export const GET_TICKET_OPTIONS_IN_REQUESTS_SUCCESS = 'GET_TICKET_OPTIONS_IN_REQUESTS_SUCCESS';
export const GET_TICKET_OPTIONS_IN_REQUESTS_FAILURE = 'GET_TICKET_OPTIONS_IN_REQUESTS_FAILURE';

export const getTicketOptionsInRequests = (reservationTypeId) => ({
    type: GET_TICKET_OPTIONS_IN_REQUESTS,
    reservationTypeId
}); 

export const getTicketOptionsInRequestsSuccess = (ticketOptions) => ({
    type: GET_TICKET_OPTIONS_IN_REQUESTS_SUCCESS,
    payload: { ticketOptions }
});

export const getTicketOptionsInRequestsFailure = (message) => ({
    type: GET_TICKET_OPTIONS_IN_REQUESTS_FAILURE,
    payload: { message }
});