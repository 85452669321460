import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import { getParameterByName } from '../../helpers/urlHelper';
import { PaymentResultConstant } from '../../constants/PaymentResultConstant';

function PaymentSuccess(props) {

  let transactionId = getParameterByName("tranid");
  let trackId = getParameterByName("trackid");
  let referenceId = getParameterByName("ref");
  let result = getParameterByName("result");
  let paymentId = getParameterByName("paymentid");
  let amount = getParameterByName("amt");
  let postDate = getParameterByName("postdate");
  let isSuccess = true;
  // let transactionId = "tranid";
  // let trackId = "trackid";
  // let referenceId = "ref";
  // let result = "result";
  // let paymentId = "paymentid";
  // let amount = "amt";
  // let postDate = "postdate";
  useEffect(() => {
    var resultData = {
      trackId,
      isSuccess
    };

    if (window.ReactNativeWebView != null) {
      window.ReactNativeWebView.postMessage(JSON.stringify(resultData));
    }
  }, [])

  return (
        <div className="w-100 card animate__animated  animate__backInLeft">
          <div className="d-flex justify-content-center align-items-center p-3">
            <h3 className="text-success">{strings.transactionSuccess}</h3>
          </div>
          <ol class="row list-group list-group-numbered">
            {
              paymentId != null
              && (<li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">{strings.paymentId}</div>
                </div>
                <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{paymentId}</span>
              </li>)
            }
            {
              result != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.result}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{result}</span>
                </li>)
            }
            {
              referenceId != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.referenceId}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{referenceId}</span>
                </li>)
            }
            {
              trackId != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.reservationCode}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{trackId}</span>
                </li>)
            }
            {
              transactionId != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.transactionId}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{transactionId}</span>
                </li>)
            }
            {
              postDate != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.insertedDate}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{postDate}</span>
                </li>)
            }
            {
              amount != null
              && (
                <li class="list-group-item d-flex justify-content-between align-items-start px-5 py-3">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">{strings.amount}</div>
                  </div>
                  <span class="badge rounded-pill  text-white" style={{ backgroundColor: "#78882D" }}>{amount}</span>
                </li>)
            }
          </ol>
        </div>
  );

}
export default PaymentSuccess;