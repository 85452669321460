export const ADD_NEW_RESERVATION = 'ADD_NEW_RESERVATION';
export const ADD_NEW_RESERVATION_SUCCESS = 'ADD_NEW_RESERVATION_SUCCESS';
export const ADD_NEW_RESERVATION_FAILURE = 'ADD_NEW_RESERVATION_FAILURE';

export const addNewReservation = (reservationData) => ({
    type: ADD_NEW_RESERVATION,
    reservationData
});

export const addNewReservationSuccess = (data) => ({
    type: ADD_NEW_RESERVATION_SUCCESS,
    payload: { data }
});

export const addNewReservationFailure = (message) => ({
    type: ADD_NEW_RESERVATION_FAILURE,
    payload: { message }
});