import React, { useEffect , useState } from "react";
import strings from '../../localizations/strings';
import EmptyImg from '../../images/emptyImg.jpg';
import { DisplayTypesInPausedTimesConstant } from "../../constants/PausedTimes/DisplayTypesInPausedTimesConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Delete from "./Delete";
function PausedTime(props) {

  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getPausedTimesInPausedTimes(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getPausedTimesInPausedTimes();
  }, []);

  useEffect(() => {
    if (
      props.deletePausedTimeResult !== null &&
      props.deletePausedTimeResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deletePausedTimeResult.message, "success").then(result => {
        props.getPausedTimesInPausedTimes(props.pausedTimesPageNumberInPausedTimes, props.pausedTimesPageSizeInPausedTimes,search);
      });
    }
  }, [props.deletePausedTimeResult]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.pausedTimesPageNumberInPausedTimes) {
        props.getPausedTimesInPausedTimes(pageNumber, props.pausedTimesPageSizeInPausedTimes, search);
    }
}

function handleinputSearch(selected) {
  console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getPausedTimesInPausedTimes(1, props.pausedTimesPageSizeInPausedTimes, selected.target.value)
}
  return (
  
    <div className="customer-data mt-5">
         {
                   props.isGetPausedTimesInPausedTimes
                     && (<Loading />)
                         }
        <div className="card animate__animated  animate__backInLeft">
        <div className="card-header d-flex flex-column flex-column-fluid">
        <TableHeader
                 hideSearchInput={true}
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.pausedTimesTotalCountInPausedTimes}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.pausedTimesPageSizeInPausedTimes)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.pausedTimesTotalCountInPausedTimes}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
        </div>
         <div className='table-responsive p-0'>
             <table className="table align-middle text-center table-bordered border-bottom m-0">
               <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
               <tr className="fw-bolder text-muted text-center bg-light">
                   <th className="min-w-125px px-0">{strings.pausedDate}</th>
                   <th className="min-w-125px px-0">{strings.fromTime}</th>
                   <th className="min-w-125px px-0">{strings.toTime}</th>
                   <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                     {strings.action}
                   </th>
               </tr>
               </thead>
        <tbody className="tableBody">
       
          { !props.isGetPausedTimesInPausedTimes
           &&  props.pausedTimesInPausedTimes !== []
            && props.pausedTimesInPausedTimes.length > 0
            && props.pausedTimesInPausedTimes.map((pausedTime, index) => {
              return <tr key={index}>
                   <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start m-auto flex-column'>
                      <h4>{pausedTime.pausedDateStr}</h4>
                    </div>
                  </div>
                </td>  
                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{pausedTime.fromTimeStr}</h4>
                                    </div>
                                  </div>
                </td>
                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{pausedTime.toTimeStr}</h4>
                                    </div>
                                  </div>
                </td>
                                 
                <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                  <div className="d-flex justify-content-center">
                    <div className="actions" style={{ backgroundColor: "#e4e6ef" }}>
                      <div className="action1">
                       <a onClick={() => {
                          props.getEditedPausedTime(pausedTime.id); 
                          props.updateDisplayTypeInPausedTimes(
                          DisplayTypesInPausedTimesConstant.EDIT
                        )
                        }}>
                          <i className="fas fa-edit edit"></i>
                        </a>
                      
                      </div>
                      <div className="action2">
                        <a
                           onClick={() => {Delete(props,pausedTime.id)}}> 
                          <i className="fas fa-trash delete"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            })
          }

        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
        {
            props.pausedTimesTotalCountInPausedTimes !== null
            && props.pausedTimesTotalCountInPausedTimes > 0
            && (
                <div className="d-flex flex-stack flex-wrap ">
                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.pausedTimesPageNumberInPausedTimes - 1) * props.pausedTimesPageSizeInPausedTimes) + 1} {strings.to} {(props.pausedTimesPageNumberInPausedTimes * props.pausedTimesPageSizeInPausedTimes) > props.pausedTimesTotalCountInPausedTimes ? props.pausedTimesTotalCountInPausedTimes : (props.pausedTimesPageNumberInPausedTimes * props.pausedTimesPageSizeInPausedTimes)} {strings.of} {props.pausedTimesTotalCountInPausedTimes} {strings.entries}</div>
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activePage={props.pausedTimesPageNumberInPausedTimes}
                        itemsCountPerPage={props.pausedTimesPageSizeInPausedTimes}
                        totalItemsCount={props.pausedTimesTotalCountInPausedTimes}
                        onChange={handlePageButtonChange}
                    />
                </div>
            )}
            </div>
        </div>
      </div>
  
  );
}

export default PausedTime;