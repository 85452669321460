import {
    GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS,
    GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getCompanyReservationTimesInCompanyReservationsActions';

import moment from 'moment';
import "moment/locale/ar-sa";

export function getCompanyReservationTimesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGettingCompanyReservationTimesInCompanyReservations: true,
                getCompanyReservationTimesInCompanyReservationsError: null,
                companyReservationTimesInCompanyReservations:[],
                companyReservationTimeInCompanyReservations:null
            };
        case GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS:
            let indexKey = 0;
            var arr = state.editedCompanyReservationInCompanyReservations !== null ? action.payload.timeResultList.map((item, index) => {
                const reservationDate = moment(state.editedCompanyReservationInCompanyReservations.reservationDate).format("YYYY-MM-DD");
                const selectedDate = moment(action.payload.selectedDate).format("YYYY-MM-DD");
                const isSameDate = moment(reservationDate).isSame(moment(selectedDate))
                const editedReservationTime = moment(state.editedCompanyReservationInCompanyReservations.fromTime).format("HH:mm").split(":");
                const splittedStartTimeArr = item.fromTime.split(":");
                if (isSameDate && splittedStartTimeArr[0] == editedReservationTime[0] && splittedStartTimeArr[1] == editedReservationTime[1]) {
                    indexKey = index;
                }
            }) : null;
            return {
                ...state,
                isGettingCompanyReservationTimesInCompanyReservations: false,
                companyReservationTimesInCompanyReservations: action.payload.timeResultList,
                companyReservationTimeInCompanyReservations: state.editedCompanyReservationInCompanyReservations !== null ? action.payload.timeResultList[indexKey] : null,
                getCompanyReservationTimesInCompanyReservationsError: null,
            };
        case GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGettingCompanyReservationTimesInCompanyReservations: false,
                getCompanyReservationTimesInCompanyReservationsError: action.payload
            };
        default:
            return state;
    }
}