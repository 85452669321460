export const GET_EDITED_REQUEST = 'GET_EDITED_REQUEST';
export const GET_EDITED_REQUEST_SUCCESS = 'GET_EDITED_REQUEST_SUCCESS';
export const GET_EDITED_REQUEST_FAILURE = 'GET_EDITED_REQUEST_FAILURE';

export const getEditedRequest = (requestId) => ({
    type: GET_EDITED_REQUEST,
    requestId
});

export const getEditedRequestSuccess = (requestId) => ({
    type: GET_EDITED_REQUEST_SUCCESS,
    payload: { requestId }
});

export const getEditedRequestFailure = (message) => ({
    type: GET_EDITED_REQUEST_FAILURE,
    payload: { message }
});
