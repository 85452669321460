export const GET_TICKET_OPTIONS_IN_TICKET_OPTIONS = 'GET_TICKET_OPTIONS_IN_TICKET_OPTIONS';
export const GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_SUCCESS = 'GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_SUCCESS';
export const GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_FAILURE = 'GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_FAILURE';

export const getTicketOptionsInTicketOptions = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get ticketOptions action')
return({
    type: GET_TICKET_OPTIONS_IN_TICKET_OPTIONS,
    pageNumber,
    pageSize,
    search
}); 
}
export const getTicketOptionsInTicketOptionsSuccess = (ticketOptions,pageNumber,pageSize) => ({
    type: GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_SUCCESS,
    payload: { ticketOptions ,pageNumber,pageSize}
});

export const getTicketOptionsInTicketOptionsFailure = (message) => ({
    type: GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_FAILURE,
    payload: { message }
});