export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_FAILURE';

export const updateDisplayTypeInReservationTypeSettings = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS,
    displayType
});

}
export const updateDisplayTypeInReservationTypeSettingsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInReservationTypeSettingsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_TYPE_SETTINGS_FAILURE,
    payload: message
});