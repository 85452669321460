import {
    GET_RESERVATION_TYPES_IN_REQUESTS,
    GET_RESERVATION_TYPES_IN_REQUESTS_SUCCESS,
    GET_RESERVATION_TYPES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/getReservationTypesInRequestsActions';

export function getReservationTypesInRequestsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_REQUESTS:
            return {
                ...state,
                isGetReservationTypesInRequests: true,
                getReservationTypesInRequestsError: null,
            };
        case GET_RESERVATION_TYPES_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isGetReservationTypesInRequests: false,
                getReservationTypesInRequestsError: null,
                reservationTypesInRequests: action.payload.reservationTypes,
                reservationTypeInRequests: state.editedRequestInRequests !== null ? action.payload.reservationTypes.filter(x => x.value === state.editedRequestInRequests.reservationTypeId)[0] : null,
            };
        case GET_RESERVATION_TYPES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isGetReservationTypesInRequests: false,
                getReservationTypesInRequestsError: action.payload.message,
            };
        default:
            return state;
    }
}