import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { DisplayTypesInUnpaidReservationsConstant } from "../../constants/Reservations/DisplayTypesInUnpaidReservationsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'
import moment from "moment";
function Reservation(props) {

  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    [selectedDate,setSelectedDate] = useState(null),
    handleTableLength = (selected) => { props.getUnpaidReservationsInReservations(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getUnpaidReservationsInReservations();
  }, []);

  useEffect(() => {
    props.getUnpaidReservationsInReservations();
  }, []);

  useEffect(() => {
    if (
      props.deleteReservationResult !== null &&
      props.deleteReservationResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deletedSuccessfully}`, props.deleteReservationResult.message, "success").then(result => {
        props.getUnpaidReservationsInReservations(props.unpaidReservationsPageNumberInReservations, props.unpaidReservationsPageSizeInReservations,search);
      });
    }
  }, [props.deleteReservationResult]);

  useEffect(() => {
    if (
      props.deleteReservationError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteReservationError, "error");
    }
  }, [props.deleteReservationError]);

  useEffect(() => {
    if (props.updatePaymentStatusReservationResult !== null && props.updatePaymentStatusReservationResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.updatePaymentStatusReservationResult.message,
        "success"
      ).then(result => {
        props.getUnpaidReservationsInReservations(props.unpaidReservationsPageNumberInReservations, props.unpaidReservationsPageSizeInReservations,search);
      }
      );
    }
  }, [props.updatePaymentStatusReservationResult]);

  useEffect(() => {
    if (props.updatePaymentStatusReservationError !== null) {
      Swal.fire(`! ${strings.error}`, props.updatePaymentStatusReservationError, "error");
    }
  }, [props.updatePaymentStatusReservationError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.unpaidReservationsPageNumberInReservations) {
      props.getUnpaidReservationsInReservations(pageNumber, props.unpaidReservationsPageSizeInReservations,search);
    }
  }

  function handleinputSearch(selected) {
    console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getUnpaidReservationsInReservations(1, props.unpaidReservationsPageSizeInReservations, selected.target.value)
  }

  function handleDateChange(selected) {
    setSelectedDate(selected);
    if(selected !== null){
    props.getUnpaidReservationsInReservations(1, props.unpaidReservationsPageSizeInReservations, search,moment(selected).locale("en").format('lll'))
    }
    else{
      props.getUnpaidReservationsInReservations(1, props.unpaidReservationsPageSizeInReservations, search)
    }
  }
  return (
    <>
      <div className="customer-data mt-5">
      {
                  props.isGetUnpaidReservationsInReservations
                  && (<Loading />)
                }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              showDateInput = {true}
              selectedDate = {selectedDate}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.unpaidReservationsTotalCountInReservations}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.unpaidReservationsPageSizeInReservations)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.unpaidReservationsTotalCountInReservations}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
              handleDateChange={handleDateChange}
            />
          </div>

          <div className="table-responsive">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                  <th className="ps-4 min-w-125px px-0">{strings.details}</th>
                  <th className="min-w-125px px-0">{strings.mobile}</th>
                  <th className="min-w-125px px-0">{strings.reservationType}</th>
                  <th className="min-w-125px px-0">{strings.code}</th>
                  <th className="min-w-125px px-0">{strings.fromTime}</th>
                  <th className="min-w-125px px-0">{strings.toTime}</th>
                  <th className="min-w-125px px-0">{strings.day}</th>
                  <th className="min-w-125px px-0">{strings.reservationDate}</th>
                  <th className="min-w-125px px-0">{strings.count}</th>
                  <th className="min-w-125px px-0 action bg-secondary fixedleftcolumn3">{strings.payment}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
               
                {
                  props.unpaidReservationsInReservations !== []
                  && props.unpaidReservationsInReservations.length > 0
                  && props.unpaidReservationsInReservations.map((reservation, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? reservation.userNameAr : reservation.userNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <div className="action1">
                              <a
                                onClick={() => {
                                  props.getReservationDetails(reservation.id);
                                  props.updateDisplayTypeInUnpaidReservations(DisplayTypesInUnpaidReservationsConstant.DETAILS);
                                }}
                              >
                                <i className={strings.getLanguage() === LanguagesConstant.AR ? "fas fa-caret-square-left edit" : "fas fa-caret-square-right edit"}></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.phoneNumber}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? reservation.reservationTypeNameAr : reservation.reservationTypeNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.reservationCode}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.fromTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.toTimeStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {strings.getLanguage() === LanguagesConstant.EN ? reservation.dayNameEn : reservation.dayNameAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.reservationDateStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{reservation.count}</h4>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn3 px-0">
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            {
                              !reservation.isPaid ?
                                (
                                  <button className="btn btn-sm btn-primary" onClick={() => props.updatePaymentStatusReservation(reservation.id)}>{strings.pay}</button>
                                ) : (
                                  <h4>{strings.paid}</h4>
                                )
                            }
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, reservation.id); }}
                              >
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer p-2">
            {
              props.unpaidReservationsTotalCountInReservations !== null
              && props.unpaidReservationsTotalCountInReservations > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.unpaidReservationsPageNumberInReservations - 1) * props.unpaidReservationsPageSizeInReservations) + 1} {strings.to} {(props.unpaidReservationsPageNumberInReservations * props.unpaidReservationsPageSizeInReservations) > props.unpaidReservationsTotalCountInReservations ? props.unpaidReservationsTotalCountInReservations : (props.unpaidReservationsPageNumberInReservations * props.unpaidReservationsPageSizeInReservations)} {strings.of} {props.unpaidReservationsTotalCountInReservations} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.unpaidReservationsPageNumberInReservations}
                    itemsCountPerPage={props.unpaidReservationsPageSizeInReservations}
                    totalItemsCount={props.unpaidReservationsTotalCountInReservations}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reservation;