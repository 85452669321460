export const UPDATE_DISPLAY_TYPE_IN_ROLE_USERS = 'UPDATE_DISPLAY_TYPE_IN_ROLE_USERS';
export const UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_FAILURE';

export const updateDisplayTypeInRoleUsers = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_USERS,
    displayType
});

export const updateDisplayTypeInRoleUsersSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInRoleUsersFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_USERS_FAILURE,
    payload: message
});