export const GET_TICKET_TYPES_IN_RESERVATIONS = 'GET_TICKET_TYPES_IN_RESERVATIONS';
export const GET_TICKET_TYPES_IN_RESERVATIONS_SUCCESS = 'GET_TICKET_TYPES_IN_RESERVATIONS_SUCCESS';
export const GET_TICKET_TYPES_IN_RESERVATIONS_FAILURE = 'GET_TICKET_TYPES_IN_RESERVATIONS_FAILURE';

export const getTicketTypesInReservations = () => ({
    type: GET_TICKET_TYPES_IN_RESERVATIONS,
}); 

export const getTicketTypesInReservationsSuccess = (ticketTypes) => ({
    type: GET_TICKET_TYPES_IN_RESERVATIONS_SUCCESS,
    payload: { ticketTypes }
});

export const getTicketTypesInReservationsFailure = (message) => ({
    type: GET_TICKET_TYPES_IN_RESERVATIONS_FAILURE,
    payload: { message }
});