import {
    CREATE_NEW_ROLE_SCREEN,
    CREATE_NEW_ROLE_SCREEN_SUCCESS,
    CREATE_NEW_ROLE_SCREEN_FAILURE
} from '../../../actions/Roles/RoleScreens/createNewRoleScreenActions';

export function createNewRoleScreenReducer(state, action) {
    switch (action.type) {
        case CREATE_NEW_ROLE_SCREEN:
            return {
                ...state,
                isCreateNewRoleScreen: true,
                createNewRoleScreenError: null,
            };
        case CREATE_NEW_ROLE_SCREEN_SUCCESS:
            return {
                ...state,
                isCreateNewRoleScreen: false,
                createNewRoleScreenError: null,
                createNewRoleScreenResult: action.payload.data,
            };
        case CREATE_NEW_ROLE_SCREEN_FAILURE:
            return {
                ...state,
                isCreateNewRoleScreen: false,
                createNewRoleScreenError: action.payload.message,
            };
        default:
            return state;
}}