import React, { useState, useEffect } from "react";
import { DisplayTypesInCompanyReservationsConstant } from "../../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant";
import CompanyReservation from './CompanyReservation';
import Add from './Add';
import Edit from './Edit';
import Details from './Details';
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function CompanyReservations(props) {


    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.LIST ? strings.companyReservations :
                            props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.ADD ? strings.create :
                                props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.EDIT ? strings.edit :  props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.DETAILS ? strings.details : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>
                            {
                                props.displayTypeInCompanyReservations !== DisplayTypesInCompanyReservationsConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInCompanyReservations(DisplayTypesInCompanyReservationsConstant.LIST)}>
                                        {strings.companyReservations}
                                    </Button> : null}

                            {props.displayTypeInCompanyReservations !== DisplayTypesInCompanyReservationsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInCompanyReservations(DisplayTypesInCompanyReservationsConstant.ADD) }}>
                                    {strings.new}
                                </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.LIST && (<CompanyReservation {...props} />)}
                            {props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.EDIT && (<Edit {...props} />)}
                            {props.displayTypeInCompanyReservations === DisplayTypesInCompanyReservationsConstant.DETAILS && (<Details {...props} />)}

                        </>

                    </div>
                </div>
            </div>


        </>
    );
}
export default CompanyReservations