export const GET_EDITED_ROLE_USER = 'GET_EDITED_ROLE_USER';
export const GET_EDITED_ROLE_USER_SUCCESS = 'GET_EDITED_ROLE_USER_SUCCESS';
export const GET_EDITED_ROLE_USER_FAILURE = 'GET_EDITED_ROLE_USER_FAILURE';

export const getEditedRoleUser = (roleUserId) => ({
    type: GET_EDITED_ROLE_USER,
    roleUserId
});

export const getEditedRoleUserSuccess = (roleUserId) => ({
    type: GET_EDITED_ROLE_USER_SUCCESS,
    payload: { roleUserId }
});

export const getEditedRoleUserFailure = (message) => ({
    type: GET_EDITED_ROLE_USER_FAILURE,
    payload: { message }
});