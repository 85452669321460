export const GET_EDITED_PAUSED_TIME = 'GET_EDITED_PAUSED_TIME';
export const GET_EDITED_PAUSED_TIME_SUCCESS = 'GET_EDITED_PAUSED_TIME_SUCCESS';
export const GET_EDITED_PAUSED_TIME_FAILURE = 'GET_EDITED_PAUSED_TIME_FAILURE';

export const getEditedPausedTime = (pausedTimeId) => ({
    type: GET_EDITED_PAUSED_TIME,
    pausedTimeId
});

export const getEditedPausedTimeSuccess = (pausedTimeId) => ({
    type: GET_EDITED_PAUSED_TIME_SUCCESS,
    payload: { pausedTimeId }
});

export const getEditedPausedTimeFailure = (message) => ({
    type: GET_EDITED_PAUSED_TIME_FAILURE,
    payload: { message }
});
