import {
    UPDATE_DISPLAY_TYPE_IN_COMPANIES,
    UPDATE_DISPLAY_TYPE_IN_COMPANIES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_COMPANIES_FAILURE
} from '../../../src/actions/Companies/updateDisplayTypeInCompaniesActions';

export function updateDisplayTypeInCompaniesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_COMPANIES:
            return {
                ...state,
                isUpdatingDisplayTypeInCompanies: true,
                updateDisplayTypeErrorInCompanies: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_COMPANIES_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInCompanies: false,
                displayTypeInCompanies: action.payload.displayType,
                updateDisplayTypeErrorInCompanies: null,

                isAddNewCompany: false,
                addNewCompanyError: null,
                addNewCompanyResult: null,

                isDeletingCompany: false,
                deleteCompanyError: null,
                deleteCompanyResult: null,

                isEditingCompany: false,
                editCompanyError: null,
                editCompanyResult: null,

                isUpdatingStatusCompany: false,
                updateStatusCompanyError: null,
                updateStatusCompanyResult: null,

                ischeckingCompanyNameEnAvailabilityInCompanies: false,
                isCompanyNameEnAvailableInCompanies: true,
                checkCompanyNameEnAvailabilityErrorInCompanies: null,

                ischeckingCompanyNameArAvailabilityInCompanies: false,
                isCompanyNameArAvailableInCompanies: true,
                checkCompanyNameArAvailabilityErrorInCompanies: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_COMPANIES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInCompanies: false,
                updateDisplayTypeErrorInCompanies: action.payload
            };
        default:
            return state;
    }
}