export const GET_NEWS_IN_NEWS = 'GET_NEWS_IN_NEWS';
export const GET_NEWS_IN_NEWS_SUCCESS = 'GET_NEWS_IN_NEWS_SUCCESS';
export const GET_NEWS_IN_NEWS_FAILURE = 'GET_NEWS_IN_NEWS_FAILURE';

export const getNewsInNews = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get news action')
return({
    type: GET_NEWS_IN_NEWS,
    pageNumber,
    pageSize,
    search
}); 
}
export const getNewsInNewsSuccess = (news,pageNumber,pageSize) => ({
    type: GET_NEWS_IN_NEWS_SUCCESS,
    payload: { news ,pageNumber,pageSize}
});

export const getNewsInNewsFailure = (message) => ({
    type: GET_NEWS_IN_NEWS_FAILURE,
    payload: { message }
});