import {
    EDIT_COMPANY,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_FAILURE
} from '../../actions/Companies/editCompanyActions';

export function editCompanyReducer(state, action) {
    switch (action.type) {
        case EDIT_COMPANY:
            return {
                ...state,
                isEditingCompany: true,
                editCompanyError: null,
            };
        case EDIT_COMPANY_SUCCESS:
            return {
                ...state,
                isEditingCompany: false,
                editCompanyError: null,
                editCompanyResult: action.payload.data,
            };
        case EDIT_COMPANY_FAILURE:
            return {
                ...state,
                isEditingCompany: false,
                editCompanyError: action.payload.message,
            };
        default:
            return state;
}}