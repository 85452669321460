export const UPDATE_SMS_TYPES_IN_SMS_SETTINGS = 'UPDATE_SMS_TYPES_IN_SMS_SETTINGS';
export const UPDATE_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS = 'UPDATE_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS';
export const UPDATE_SMS_TYPES_IN_SMS_SETTINGS_FAILURE = 'UPDATE_SMS_TYPES_IN_SMS_SETTINGS_FAILURE';

export const updateSmsTypesInSmsSettings = (smsType) => ({
    type: UPDATE_SMS_TYPES_IN_SMS_SETTINGS,
    smsType
});

export const updateSmsTypesInSmsSettingsSuccess = (smsType) => ({
    type: UPDATE_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS,
    payload: { smsType }
});

export const updateSmsTypesInSmsSettingsFailure = (message) => ({
    type: UPDATE_SMS_TYPES_IN_SMS_SETTINGS_FAILURE,
    payload: { message }
});