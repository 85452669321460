import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { DisplayTypesInRequestsConstant } from "../../constants/Reservations/DisplayTypesInRequestsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { RequestStatusesConstant } from "../../constants/RequestStatusesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'
import { string } from "yup";
import moment from "moment";
function Reservation(props) {

  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    [selectedDate, setSelectedDate] = useState(null),
    handleTableLength = (selected) => { props.getRequestsInReservations(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };

  useEffect(() => {
    props.getRequestsInReservations();
  }, []);

  useEffect(() => {
    if (
      props.deleteRequestResult !== null &&
      props.deleteRequestResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deletedSuccessfully}`, props.deleteRequestResult.message, "success").then(result => {
        props.getRequestsInReservations(props.requestsPageNumberInReservations, props.requestsPageSizeInReservations);
      });
    }
  }, [props.deleteRequestResult]);

  useEffect(() => {
    if (
      props.deleteRequestError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteRequestError, "error");
    }
  }, [props.deleteRequestError]);

  useEffect(() => {
    if (props.updateRequestStatusReservationResult !== null && props.updateRequestStatusReservationResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.updateRequestStatusReservationResult.message,
        "success"
      ).then(result => {
        props.getRequestsInReservations(props.requestsPageNumberInReservations, props.requestsPageSizeInReservations, search);
      }
      );
    }
  }, [props.updateRequestStatusReservationResult]);

  useEffect(() => {
    if (props.updateRequestStatusReservationError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateRequestStatusReservationError, "error");
    }
  }, [props.updateRequestStatusReservationError]);

  useEffect(() => {
    if (props.payInRequestResult !== null && props.payInRequestResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.payInRequestResult.message,
        "success"
      ).then(result => {
        props.getRequestsInReservations(props.requestsPageNumberInReservations, props.requestsPageSizeInReservations, search);
      }
      );
    }
  }, [props.payInRequestResult]);

  useEffect(() => {
    if (props.payInRequestError !== null) {
      Swal.fire(`! ${strings.error}`, props.payInRequestError, "error");
    }
  }, [props.payInRequestError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.requestsPageNumberInReservations) {
      props.getRequestsInReservations(pageNumber, props.requestsPageSizeInReservations, search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getRequestsInReservations(1, props.requestsPageSizeInReservations, selected.target.value)
  }

  function handleDateChange(selected) {
    setSelectedDate(selected);
    if (selected !== null) {
      props.getRequestsInReservations(1, props.requestsPageSizeInReservations, search, moment(selected).locale("en").format('lll'))
    }
    else {
      props.getRequestsInReservations(1, props.requestsPageSizeInReservations, search)
    }
  }

  return (
    <>
      <div className="customer-data mt-5">
        {
          props.isGetRequestsInReservations
          && (<Loading />)
        }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              showDateInput={true}
              selectedDate={selectedDate}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length, { label: strings.all, value: props.requestsTotalCountInReservations }]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.requestsPageSizeInReservations)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.requestsTotalCountInReservations}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
              handleDateChange={handleDateChange}
            />
          </div>

          <div className="table-responsive">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                  <th className="ps-4 min-w-125px px-0">{strings.details}</th>
                  <th className="min-w-125px px-0">{strings.mobile}</th>
                  <th className="min-w-125px px-0">{strings.reservationType}</th>
                  <th className="min-w-125px px-0">{strings.amount}</th>
                  <th className="min-w-125px px-0">{strings.fromTime}</th>
                  <th className="min-w-125px px-0">{strings.toTime}</th>
                  <th className="min-w-125px px-0">{strings.day}</th>
                  <th className="min-w-125px px-0">{strings.count}</th>
                  <th className="min-w-125px px-0">{strings.reservationDate}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn1">{strings.pay}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn2">{strings.approve}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">

                {
                  props.requestsInReservations !== []
                  && props.requestsInReservations.length > 0
                  && props.requestsInReservations.map((request, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? request.userNameAr : request.userNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <div className="action1">
                              <a
                                onClick={() => {
                                  props.getRequestDetails(request.id);
                                  props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.DETAILS);
                                }}
                              >
                                <i className={strings.getLanguage() === LanguagesConstant.AR ? "fas fa-caret-square-left edit" : "fas fa-caret-square-right edit"}></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{request.phoneNumber}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? request.reservationTypeNameAr : request.reservationTypeNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{request.totalAmount}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{request.fromTimeStr !== null ? request.fromTimeStr : strings.notSpecified}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{request.toTimeStr !== null ? request.toTimeStr : strings.notSpecified}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {strings.getLanguage() === LanguagesConstant.EN ? request.dayNameEn : request.dayNameAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {request.count}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{request.reservationDateStr}</h4>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn1 px-0">
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                          <button className="btn btn-sm btn-success"
                                onClick={() => {
                                  props.payInRequest(request.id);
                                }}
                              >{strings.pay}</button>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn2 px-0">
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            {
                              request.requestStatusId == RequestStatusesConstant.ADD
                              && (<button className="btn btn-sm btn-success"
                                onClick={() => {
                                  props.getEditedRequest(request.id);
                                  props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.APPROVE);
                                }}
                              >{strings.approve}</button>)
                            }
                            {
                              request.requestStatusId == RequestStatusesConstant.APPROVED
                              && (<button className="btn btn-sm btn-primary"
                                onClick={() => {
                                  props.getEditedRequest(request.id);
                                  props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.APPROVE);
                                }}
                              >{strings.reApprove}</button>)
                            }
                            {
                              request.requestStatusId == RequestStatusesConstant.CANCELLED
                              && (strings.cancelled)
                            }
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            {
                              (request.requestStatusId == RequestStatusesConstant.ADD || request.requestStatusId == RequestStatusesConstant.APPROVED)
                              && (
                                <div className="action1">
                                  <a onClick={() => {
                                    props.getEditedRequest(request.id);
                                    props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.EDIT);
                                  }}>
                                    <i className="fas fa-edit edit"></i>
                                  </a>

                                </div>
                              )}
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, request.id); }}
                              >
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>


                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer p-2">
            {
              props.requestsTotalCountInReservations !== null
              && props.requestsTotalCountInReservations > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.requestsPageNumberInReservations - 1) * props.requestsPageSizeInReservations) + 1} {strings.to} {(props.requestsPageNumberInReservations * props.requestsPageSizeInReservations) > props.requestsTotalCountInReservations ? props.requestsTotalCountInReservations : (props.requestsPageNumberInReservations * props.requestsPageSizeInReservations)} {strings.of} {props.requestsTotalCountInReservations} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.requestsPageNumberInReservations}
                    itemsCountPerPage={props.requestsPageSizeInReservations}
                    totalItemsCount={props.requestsTotalCountInReservations}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reservation;