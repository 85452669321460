export const ADD_NEW_NOTIFICATION_SETTING = 'ADD_NEW_NOTIFICATION_SETTING';
export const ADD_NEW_NOTIFICATION_SETTING_SUCCESS = 'ADD_NEW_NOTIFICATION_SETTING_SUCCESS';
export const ADD_NEW_NOTIFICATION_SETTING_FAILURE = 'ADD_NEW_NOTIFICATION_SETTING_FAILURE';

export const addNewNotificationSetting = (notificationSettingData) =>{
    console.log("action")
    return ({
    type: ADD_NEW_NOTIFICATION_SETTING,
    notificationSettingData
});
}
export const addNewNotificationSettingSuccess = (data) => ({
    type: ADD_NEW_NOTIFICATION_SETTING_SUCCESS,
    payload: { data }
});

export const addNewNotificationSettingFailure = (message) => ({
    type: ADD_NEW_NOTIFICATION_SETTING_FAILURE,
    payload: { message }
});