export const CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS = 'CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS';
export const CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_SUCCESS = 'CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_SUCCESS';
export const CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_FAILURE = 'CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_FAILURE';

export const changeIsPaidInCompanyReservations = (companyReservationId) => ({
    type: CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS,
    companyReservationId
});

export const changeIsPaidInCompanyReservationsSuccess = (data) => ({
    type: CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { data }
});

export const changeIsPaidInCompanyReservationsFailure = (message) => ({
    type: CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});