export const GET_EDITED_TICKET_OPTION = 'GET_EDITED_TICKET_OPTION';
export const GET_EDITED_TICKET_OPTION_SUCCESS = 'GET_EDITED_TICKET_OPTION_SUCCESS';
export const GET_EDITED_TICKET_OPTION_FAILURE = 'GET_EDITED_TICKET_OPTION_FAILURE';

export const getEditedTicketOption = (ticketOptionId) => {
    console.log('get edited ticketOption action')
    return ({
    type: GET_EDITED_TICKET_OPTION,
    ticketOptionId
});
}
export const getEditedTicketOptionSuccess = (ticketOptionId) => {
    console.log('get edited ticketOption success action')
    return ({
    type: GET_EDITED_TICKET_OPTION_SUCCESS,
    payload: { ticketOptionId }
});
}
export const getEditedTicketOptionFailure = (message) => {
    console.log('get edited ticketOption fail action')
    return ({
    type: GET_EDITED_TICKET_OPTION_FAILURE,
    payload: { message }
});
}