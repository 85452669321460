import {
    GET_TICKET_OPTIONS_IN_TICKET_OPTIONS,
    GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_SUCCESS,
    GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_FAILURE
} from '../../actions/TicketOptions/getTicketOptionsInTicketOptionsActions';

export function getTicketOptionsInTicketOptionsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_OPTIONS_IN_TICKET_OPTIONS:
            return {
                ...state,
                isGetTicketOptionsInTicketOptions: true,
                getTicketOptionsInTicketOptionsError: null,
            };
        case GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_SUCCESS:
            console.log(action.payload.ticketOptions)
            return {
                ...state,
                isGetTicketOptionsInTicketOptions: false,
                getTicketOptionsInTicketOptionsError: null,
                ticketOptionsInTicketOptions: action.payload.ticketOptions.data,
                 ticketOptionsPageNumberInTicketOptions: action.payload.pageNumber,
                ticketOptionsPageSizeInTicketOptions: action.payload.pageSize,
                ticketOptionsTotalCountInTicketOptions: action.payload.ticketOptions.total,
            };
        case GET_TICKET_OPTIONS_IN_TICKET_OPTIONS_FAILURE:
            return {
                ...state,
                isGetTicketOptionsInTicketOptions: false,
                getTicketOptionsInTicketOptionsError: action.payload.message,
            };
        default:
            return state;
}}