import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    updateNotificationRolesInNotificationSettingsSuccess,
    updateNotificationRolesInNotificationSettingsFailure
} from '../../actions/NotificationSettings/updateNotificationRolesInNotificationSettingsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateNotificationRolesInNotificationSettingsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS),
        map(action => ({
            notificationRole: action.notificationRole
        })),
        map(response =>

            updateNotificationRolesInNotificationSettingsSuccess(response.notificationRole)

        ),
        catchError(error => of(updateNotificationRolesInNotificationSettingsFailure(strings.somethingWrongHappened)))
    )
}

