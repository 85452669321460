import {
    DELETE_RESERVATION_TYPE,
    DELETE_RESERVATION_TYPE_SUCCESS,
    DELETE_RESERVATION_TYPE_FAILURE
} from '../../actions/ReservationTypes/deleteReservationTypeActions';

import { DisplayTypesInReservationTypesConstant } from '../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant';

export function deleteReservationTypeReducer(state, action) {
    switch (action.type) {
        case DELETE_RESERVATION_TYPE:
            return {
                ...state,
                isDeletingReservationType: true,
                deleteReservationTypeError: null,
            };
        case DELETE_RESERVATION_TYPE_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingReservationType: false,
                deleteReservationTypeError: null,
                deleteReservationTypeResult: action.payload.data,
                //displayTypeInReservationTypes: DisplayTypesInReservationTypesConstant.LIST,
            };
        case DELETE_RESERVATION_TYPE_FAILURE:
            return {
                ...state,
                isDeletingReservationType: false,
                deleteReservationTypeError: action.payload.message,
            };
        default:
            return state;
}}