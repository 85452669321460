import {
    GET_ROLE,
    GET_ROLE_SUCCESS,
    GET_ROLE_FAILURE
} from '../../../actions/Roles/Roles/getRoleActions';

export function getRoleReducer(state, action) {
    switch (action.type) {
        case GET_ROLE:
            return {
                ...state,
                isGetRole: true,
                getRoleError: null,
            };
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                isGetRole: false,
                getRoleError: null,
                roleInRoles: state.rolesInRoles.filter(x=> x.id === action.payload.roleId)[0],
            };
        case GET_ROLE_FAILURE:
            return {
                ...state,
                isGetRole: false,
                getRoleError: action.payload.message,
            };
        default:
            return state;
}}