import {
    DELETE_ROLE_USER,
    DELETE_ROLE_USER_SUCCESS,
    DELETE_ROLE_USER_FAILURE
} from '../../../actions/Roles/RoleUsers/deleteRoleUserActions';

export function deleteRoleUserReducer(state, action) {
    switch (action.type) {
        case DELETE_ROLE_USER:
            return {
                ...state,
                isDeletingRoleUser: true,
                deleteRoleUserError: null,
            };
        case DELETE_ROLE_USER_SUCCESS:
            return {
                ...state,
                isDeletingRoleUser: false,
                deleteRoleUserError: null,
                deleteRoleUserResult: action.payload.data,
            };
        case DELETE_ROLE_USER_FAILURE:
            return {
                ...state,
                isDeletingRoleUser: false,
                deleteRoleUserError: action.payload.message,
            };
        default:
            return state;
}}