import {
    UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS,
    UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../../src/actions/NotificationSettings/updateDisplayTypeInNotificationSettingsActions';

export function updateDisplayTypeInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isUpdatingDisplayTypeInNotificationSettings: true,
                updateDisplayTypeErrorInNotificationSettings: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInNotificationSettings: false,
                displayTypeInNotificationSettings: action.payload.displayType,
                updateDisplayTypeErrorInNotificationSettings: null,

                isAddNewNotificationSetting: false,
                addNewNotificationSettingError: null,
                addNewNotificationSettingResult: null,

                isDeletingNotificationSetting: false,
                deleteNotificationSettingError: null,
                deleteNotificationSettingResult: null,

                isEditingNotificationSetting: false,
                editNotificationSettingError: null,
                editNotificationSettingResult: null,

                isUpdatingStatusNotificationSetting: false,
                updateStatusNotificationSettingError: null,
                updateStatusNotificationSettingResult: null,

                ischeckingNotificationSettingNameEnAvailabilityInNotificationSettings: false,
                isNotificationSettingNameEnAvailableInNotificationSettings: true,
                checkNotificationSettingNameEnAvailabilityErrorInNotificationSettings: null,

                ischeckingNotificationSettingNameArAvailabilityInNotificationSettings: false,
                isNotificationSettingNameArAvailableInNotificationSettings: true,
                checkNotificationSettingNameArAvailabilityErrorInNotificationSettings: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInNotificationSettings: false,
                updateDisplayTypeErrorInNotificationSettings: action.payload
            };
        default:
            return state;
    }
}