import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    EDIT_REQUEST,
    editRequestSuccess,
    editRequestFailure
} from '../../actions/Requests/editRequestActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function editRequestEpic(action$) {
    return action$.pipe(
        ofType(EDIT_REQUEST),
        switchMap(action => {
           
            const ajaxConfigObj = {
                url: url + `Request/EditAdminRequest`,
                method: 'POST',
                headers: {
                     'Authorization': token,
                    'content-type': 'application/json',
                },
                body: action.requestViewModel,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
				map(data => data.response),retry())
        }),
        map(response =>{
            if (response.isSuccess) {
                return editRequestSuccess(response);
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return editRequestFailure(message);
            }
        }),
        catchError(error => of(editRequestFailure(strings.somethingWrongHappened)))
    )
}