export const UPDATE_STATUS_NOTIFICATION_SETTING = 'UPDATE_STATUS_NOTIFICATION_SETTING';
export const UPDATE_STATUS_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_STATUS_NOTIFICATION_SETTING_SUCCESS';
export const UPDATE_STATUS_NOTIFICATION_SETTING_FAILURE = 'UPDATE_STATUS_NOTIFICATION_SETTING_FAILURE';

export const updateStatusNotificationSetting = (notificationSettingId) => {
    console.log('update status notificationSetting action')
return({
    type: UPDATE_STATUS_NOTIFICATION_SETTING,
    notificationSettingId
});
}
export const updateStatusNotificationSettingSuccess = (data) => ({
    type: UPDATE_STATUS_NOTIFICATION_SETTING_SUCCESS,
    payload: { data }
});

export const updateStatusNotificationSettingFailure = (message) => ({
    type: UPDATE_STATUS_NOTIFICATION_SETTING_FAILURE,
    payload: { message }
});