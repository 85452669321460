import {
    UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS,
    UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateDisplayTypeInCompanyReservationsActions';

import {
    DisplayTypesInCompanyReservationsConstant
} from '../../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant';

export function updateDisplayTypeInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInCompanyReservations: true,
                updateDisplayTypeErrorInCompanyReservations: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_SUCCESS:
            if (action.payload.displayType == DisplayTypesInCompanyReservationsConstant.EDIT) {
                return {
                    ...state,
                    isUpdatingDisplayTypeInCompanyReservations: false,
                    displayTypeInCompanyReservations: action.payload.displayType,
                    updateDisplayTypeErrorInCompanyReservations: null,

                    isAddNewCompanyReservation: false,
                    addNewCompanyReservationError: null,
                    addNewCompanyReservationResult: null,

                    isDeletingCompanyReservation: false,
                    deleteCompanyReservationError: null,
                    deleteCompanyReservationResult: null,

                    isEditingCompanyReservation: false,
                    editCompanyReservationResult: null,
                    editCompanyReservationError: null,

                };
            } else {
                return {
                    ...state,
                    isUpdatingDisplayTypeInCompanyReservations: false,
                    displayTypeInCompanyReservations: action.payload.displayType,
                    updateDisplayTypeErrorInCompanyReservations: null,

                    isAddNewCompanyReservation: false,
                    addNewCompanyReservationError: null,
                    addNewCompanyReservationResult: null,

                    isDeletingCompanyReservation: false,
                    deleteCompanyReservationError: null,
                    deleteCompanyReservationResult: null,

                    isEditingCompanyReservation: false,
                    editCompanyReservationResult: null,
                    editCompanyReservationError: null,

                    isGetCompaniesInCompanyReservations: false,
                    getCompaniesInCompanyReservationsError: null,
                    companiesInCompanyReservations: [],

                    isGettingCompanyReservationTimesInCompanyReservations: false,
                    companyReservationTimesInCompanyReservations: [],
                    getCompanyReservationTimesInCompanyReservationsError: null,

                    isGetReservationTypesInCompanyReservations: false,
                    getReservationTypesInCompanyReservationsError: null,
                    reservationTypesInCompanyReservations: [],

                    isGetTicketOptionsInCompanyReservations: false,
                    getTicketOptionsInCompanyReservationsError: null,
                    ticketOptionsInCompanyReservations: [],

                    isGetTicketTypesInCompanyReservations: false,
                    getTicketTypesInCompanyReservationsError: null,
                    ticketTypesInCompanyReservations: [],

                    isGettingToTimesInCompanyReservations: false,
                    toTimesInCompanyReservations: [],
                    getToTimesInCompanyReservationsError: null,

                    isUpdateCompaniesInCompanyReservations: false,
                    updateCompaniesInCompanyReservationsError: null,
                    companyInCompanyReservations: null,

                    isUpdatingCompanyReservationTimesInCompanyReservations: false,
                    companyReservationTimeInCompanyReservations: null,
                    updateCompanyReservationTimesInCompanyReservationsError: null,

                    isUpdateReservationTypesInCompanyReservations: false,
                    updateReservationTypesInCompanyReservationsError: null,
                    reservationTypeInCompanyReservations: null,

                    isUpdatingToTimesInCompanyReservations: false,
                    toTimeInCompanyReservations: null,
                    updateToTimesInCompanyReservationsError: null,

                    isGetEditedCompanyReservation: false,
                    getEditedCompanyReservationError: null,
                    editedCompanyReservationInCompanyReservations: null

                };
            }
        case UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInCompanyReservations: false,
                updateDisplayTypeErrorInCompanyReservations: action.payload
            };
        default:
            return state;
    }
}