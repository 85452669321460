export const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
export const CREATE_NEW_ROLE_SUCCESS = 'CREATE_NEW_ROLE_SUCCESS';
export const CREATE_NEW_ROLE_FAILURE = 'CREATE_NEW_ROLE_FAILURE';

export const createNewRole = (roleData) => ({
    type: CREATE_NEW_ROLE,
    roleData
});

export const createNewRoleSuccess = (data) => ({
    type: CREATE_NEW_ROLE_SUCCESS,
    payload: { data }
});

export const createNewRoleFailure = (message) => ({
    type: CREATE_NEW_ROLE_FAILURE,
    payload: { message }
});