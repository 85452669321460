import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import { 
    GET_DISCOUNTS_IN_DISCOUNTS,
    getDiscountsInDiscountsSuccess,
    getDiscountsInDiscountsFailure
} from '../../actions/Discounts/getDiscountsInDiscountsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getDiscountsInDiscountsEpic(action$) {
    return action$.pipe(
        ofType(GET_DISCOUNTS_IN_DISCOUNTS),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `Discount/LoadData`,
                method: 'POST',
                headers: {
                	'Authorization':token,
                    'content-type': 'application/json'
                },
                body: {
                    pageNumber: action.pageNumber,
                    pageSize: action.pageSize,
                    search: action.search
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => ({
                    response:data.response,
                    pageNumber:action.pageNumber,
                    pageSize:action.pageSize
                })))
        }),
        mergeMap(data => {
             
            if (data.response.isSuccess) {
                return of(getDiscountsInDiscountsSuccess(data.response.data,data.pageNumber,data.pageSize));
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                 && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getDiscountsInDiscountsFailure(message));
            }
        }),
        catchError(error =>  of(getDiscountsInDiscountsFailure(strings.somethingWrongHappened)))
    )
}

