import {
    UPDATE_STATUS_IN_RESERVATION_TYPES,
    UPDATE_STATUS_IN_RESERVATION_TYPES_SUCCESS,
    UPDATE_STATUS_IN_RESERVATION_TYPES_FAILURE
} from '../../actions/ReservationTypes/updateStatusInReservationTypesActions';

export function updateStatusInReservationTypesReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_IN_RESERVATION_TYPES:
            return {
                ...state,
                isUpdatingStatusInReservationTypes: true,
                updateStatusInReservationTypesError: null,
            };
        case UPDATE_STATUS_IN_RESERVATION_TYPES_SUCCESS:
            return {
                ...state,
                isUpdatingStatusInReservationTypes: false,
                updateStatusInReservationTypesError: null,
                updateStatusInReservationTypesResult: action.payload.data,
                reservationTypesInReservationTypes:state.reservationTypesInReservationTypes.map((item, index) => {
                    if (item.id === action.payload.data.data) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        };
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_IN_RESERVATION_TYPES_FAILURE:
            return {
                ...state,
                isUpdatingStatusInReservationTypes: false,
                updateStatusInReservationTypesError: action.payload.message,
            };
        default:
            return state;
}}