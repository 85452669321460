export const CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES =
    'CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES';
export const CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_SUCCESS =
    'CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_SUCCESS';
export const CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_FAILURE =
    'CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_FAILURE';

export const checkRoleNameArAvailabilityInRoles = (nameAr, roleId = null) => ({
    type: CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES,
    nameAr,
    roleId
});

export const checkRoleNameArAvailabilityInRolesSuccess = (isAvailable) => ({
    type: CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_SUCCESS,
    payload: { isAvailable }
});

export const checkRoleNameArAvailabilityInRolesFailure = (message) => ({
    type: CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_FAILURE,
    payload: message
});