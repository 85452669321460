import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_RESERVATION_TYPES_IN_RESERVATIONS,
    updateReservationTypesInReservationsSuccess,
    updateReservationTypesInReservationsFailure
} from '../../actions/Reservations/updateReservationTypesInReservationsActions';

import { 
    getReservationTimesInReservations
} from '../../actions/Reservations/getReservationTimesInReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateReservationTypesInReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_RESERVATION_TYPES_IN_RESERVATIONS),
        map(action => ({
            reservationType:action.reservationType,
            selectedDate:action.selectedDate
        })),
        mergeMap(response => {
            return of(updateReservationTypesInReservationsSuccess(response.reservationType),getReservationTimesInReservations(response.reservationType.value,response.selectedDate))
        }),
        catchError(error =>  of(updateReservationTypesInReservationsFailure(strings.somethingWrongHappened)))
    )
}

