import {
    UPDATE_STATUS_COMPANY,
    UPDATE_STATUS_COMPANY_SUCCESS,
    UPDATE_STATUS_COMPANY_FAILURE
} from '../../actions/Companies/updateStatusCompanyActions';

export function updateStatusCompanyReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_COMPANY:
            return {
                ...state,
                isUpdatingStatusCompany: true,
                updateStatusCompanyError: null,
            };
        case UPDATE_STATUS_COMPANY_SUCCESS:
            return {
                ...state,
                isUpdatingStatusCompany: false,
                updateStatusCompanyError: null,
                updateStatusCompanyResult: action.payload.data,
                companiesInCompanies: state.companiesInCompanies.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_COMPANY_FAILURE:
            return {
                ...state,
                isUpdatingStatusCompany: false,
                updateStatusCompanyError: action.payload.message,
            };
        default:
            return state;
    }
}