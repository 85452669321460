import React, { useEffect, forwardRef } from "react";
import Button from "@restart/ui/esm/Button";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInDiscountsConstant } from "../../constants/Discounts/DisplayTypesInDiscountsConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";
import Loading from '../Shared/Loading/Loading'
registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);

const moment = require('moment/min/moment.min');

function Edit(props) {
  const formikRef = React.useRef(null);

  const [fromDateError, setFromDateError] = React.useState(null);
  const [toDateError, setToDateError] = React.useState(null);

  useEffect(() => {
    if (props.editDiscountResult !== null && props.editDiscountResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editDiscountResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInDiscounts(DisplayTypesInDiscountsConstant.LIST)
      }
      )
    }
  }, [props.editDiscountResult])

  useEffect(() => {
    if (props.editDiscountError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editDiscountError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editDiscountError])

  function handleFromDateChange(value) {
    if (!moment(value).isValid()) {
      setFromDateError(strings.dateNotValid);
    } else {
      setFromDateError(null);
      formikRef.current.setFieldValue("fromDate", value);
      formikRef.current.setFieldValue("toDate", null);
    }
  }

  function handleToDateChange(value) {
    if (!moment(value).isValid()) {
      setToDateError(strings.dateNotValid);
    } else {
      setToDateError(null);
      formikRef.current.setFieldValue("toDate", value);
    }
  }

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.from} *`}
      name="fromDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  const EndDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.to} *`}
      name="toDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  return (
    <>
      { props.isEditingDiscount && ( <Loading />)}
     {  !props.isEditingDiscount &&
     (
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
            <div className='card-header border-0 d-flex align-items-center'>
              <h3 className='card-title col-9'>
              <span className="card-label fw-bolder text-dark">
                {strings.editDiscount}</span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  rate: props.editedDiscountInDiscounts !== null ? props.editedDiscountInDiscounts.rate : "",
                  code: props.editedDiscountInDiscounts !== null ? props.editedDiscountInDiscounts.code : "",
                  numberOfUse: props.editedDiscountInDiscounts !== null ? props.editedDiscountInDiscounts.numberOfUsing : "",
                  fromDate: props.editedDiscountInDiscounts !== null ? new Date(props.editedDiscountInDiscounts.fromDate) : null,
                  toDate: props.editedDiscountInDiscounts !== null ? new Date(props.editedDiscountInDiscounts.toDate) : null,
                }}
                validationSchema={Yup.object().shape({
                  rate: Yup.number().required(
                    strings.rateDiscountRequired
                  ).min(1, strings.discountRateShouldBeGreaterThan)
                    .max(100, strings.discountRateShouldBeLessThan),
                    code: Yup.string().required(
                      strings.codeIsRequired
                    ),
                  numberOfUse: Yup.number().required(
                      strings.numberOfUseIsRequired
                    ).min(1, strings.numberOfUseShouldBeGreaterThan)
                })}

                onSubmit={(fields) => {
                  let isValid = true;
                  if (fromDateError != null || toDateError != null) {
                    isValid = false;
                  }

                  if (fields.fromDate == null || fields.fromDate == "") {
                    setFromDateError(strings.dateIsRequired);
                    isValid = false;
                  }

                  if (fields.toDate == null || fields.toDate == "") {
                    setToDateError(strings.dateIsRequired);
                    isValid = false;
                  }

                  if (isValid) {
                    const discount = {
                      id: props.editedDiscountInDiscounts.id,
                      rate: fields.rate,
                      code: fields.code,
                      numberOfUsing:fields.numberOfUse,
                      fromDate: new Date(fields.fromDate).toLocaleDateString(),
                      toDate: new Date(fields.toDate).toLocaleDateString()
                    };
                    props.editDiscount(discount);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex"style={{padding:"1rem 1rem 0rem 1rem"}}>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.rateDiscount}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        <Field
                          type="text"
                          name="rate"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid"+
                            (errors.rate &&
                              touched.rate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.rateDiscount} *`}
                        />
                        <ErrorMessage
                          name="rate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.code}
                        </label>
                        <div className="col-lg-10 col-sm-12">
                          <Field
                            type="text"
                            name="code"
                            className={
                              "form-control form-control1 form-control-lg form-control-solid"+
                              (errors.code &&
                                touched.code
                                ? " is-invalid"
                                : "")
                            }
                            placeholder={`${strings.code} *`}
                          />
                          <ErrorMessage
                            name="code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                      </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.numberOfUse}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        <Field
                          type="text"
                          name="numberOfUse"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid"+
                            (errors.numberOfUse &&
                              touched.numberOfUse
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.numberOfUse} *`}
                        />
                        <ErrorMessage
                          name="numberOfUse"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.from}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        {/* <input type="text"
                          name="discountNameAr"
                          className={"form-control form-control1 form-control-lg form-control-solid"} /> */}
                        <DatePicker placeholderText={strings.from}
                          customInput={<StartDateInput />}
                          locale={strings.getLanguage() === LanguagesConstant.EN ?
                            "en" : "ar"}
                          selected={values.fromDate}
                          onChange={handleFromDateChange}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          className={fromDateError !== null ? 'red-border' : ''} />
                        {
                          fromDateError && (
                            <div className="invalid-feedback d-block">
                              {fromDateError}
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.to}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        <DatePicker 
                          placeholderText={strings.to}
                          customInput={<EndDateInput />}
                          locale={strings.getLanguage() === LanguagesConstant.EN ?
                            "en" : "ar"}
                          selected={values.toDate}
                          onChange={handleToDateChange}
                          minDate={values.fromDate == null ? new Date() : values.fromDate}
                          dateFormat="dd/MM/yyyy"
                          className={toDateError !== null ? 'red-border' : ''} />
                        {
                          toDateError && (
                            <div className="invalid-feedback d-block">
                              {toDateError}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-start" style={{ backgroundColor: "white" }}>
                  <div>
                    <button
                      type="submit"
                      className="btn fs-6"
                      style={{
                        backgroundColor: "#009ef7",
                        borderColor: "#009ef7",
                        color: "white",
                        borderRadius: "0.75rem",
                      }}
                      id="kt_account_profile_details_submit"
                      //	onClick={done}
                    >
                      {strings.save}
                    </button>
                  </div>
                </div>
                </Form>
                )}
              />
            </div>
        </div>
      </div>
     )
                          }
    </>
  );
}
export default Edit;
