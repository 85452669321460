import {
    ADD_NEW_RESERVATION_TYPE,
    ADD_NEW_RESERVATION_TYPE_SUCCESS,
    ADD_NEW_RESERVATION_TYPE_FAILURE
} from '../../actions/ReservationTypes/addNewReservationTypeActions';

import { DisplayTypesInReservationTypesConstant } from '../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant';

export function addNewReservationTypeReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_RESERVATION_TYPE:
            return {
                ...state,
                isAddNewReservationType: true,
                addNewReservationTypeError: null,
                addNewReservationTypeResult:null,
            };
        case ADD_NEW_RESERVATION_TYPE_SUCCESS:
            return { 
                ...state,
                isAddNewReservationType: false,
                addNewReservationTypeError: null,
                addNewReservationTypeResult: action.payload.data,
                // displayTypeInReservationTypes: DisplayTypesInReservationTypesConstant.LIST,
            };
        case ADD_NEW_RESERVATION_TYPE_FAILURE:
            return {
                ...state,
                isAddNewReservationType: false,
                addNewReservationTypeError: action.payload.message,
            };
        default:
            return state;
}}