export const GET_ROLE_USERS = 'GET_ROLE_USERS';
export const GET_ROLE_USERS_SUCCESS = 'GET_ROLE_USERS_SUCCESS';
export const GET_ROLE_USERS_FAILURE = 'GET_ROLE_USERS_FAILURE';

export const getRoleUsers = (roleId,pageNumber=1,pageSize=25,search=null) => ({
    type: GET_ROLE_USERS,
    roleId,
    pageNumber,
    pageSize,
    search
});

export const getRoleUsersSuccess = (users,pageNumber,pageSize) => ({
    type: GET_ROLE_USERS_SUCCESS,
    payload: { users ,pageNumber,pageSize }
});

export const getRoleUsersFailure = (message) => ({
    type: GET_ROLE_USERS_FAILURE,
    payload: { message }
});