import {
    GET_TICKET_TYPES_IN_TICKET_TYPES,
    GET_TICKET_TYPES_IN_TICKET_TYPES_SUCCESS,
    GET_TICKET_TYPES_IN_TICKET_TYPES_FAILURE
} from '../../actions/TicketTypes/getTicketTypesInTicketTypesActions';

export function getTicketTypesInTicketTypesReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_TYPES_IN_TICKET_TYPES:
            return {
                ...state,
                isGetTicketTypesInTicketTypes: true,
                getTicketTypesInTicketTypesError: null,
            };
        case GET_TICKET_TYPES_IN_TICKET_TYPES_SUCCESS:
            console.log(action.payload.ticketTypes)
            return {
                ...state,
                isGetTicketTypesInTicketTypes: false,
                getTicketTypesInTicketTypesError: null,
                ticketTypesInTicketTypes: action.payload.ticketTypes.data,
                 ticketTypesPageNumberInTicketTypes: action.payload.pageNumber,
                ticketTypesPageSizeInTicketTypes: action.payload.pageSize,
                ticketTypesTotalCountInTicketTypes: action.payload.ticketTypes.total,
            };
        case GET_TICKET_TYPES_IN_TICKET_TYPES_FAILURE:
            return {
                ...state,
                isGetTicketTypesInTicketTypes: false,
                getTicketTypesInTicketTypesError: action.payload.message,
            };
        default:
            return state;
}}