export const UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS = 'UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS';
export const UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_FAILURE';

export const updateDisplayTypeInUnpaidReservations = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS,
    displayType
});

export const updateDisplayTypeInUnpaidReservationsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInUnpaidReservationsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_FAILURE,
    payload: message
});