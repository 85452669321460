import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_REQUEST_DETAILS,
    getRequestDetailsSuccess,
    getRequestDetailsFailure
} from '../../actions/Requests/getRequestDetailsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getRequestDetailsEpic(action$) {
    return action$.pipe(
        ofType(GET_REQUEST_DETAILS),
        map(action => ({
            requestId: action.requestId
        })),
        map(response =>
            getRequestDetailsSuccess(response.requestId)

        ),
        catchError(error => of(getRequestDetailsFailure(strings.somethingWrongHappened)))
    )
}

