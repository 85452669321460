import {
    UPDATE_RETURN_MONEY_STATUS_RESERVATION,
    UPDATE_RETURN_MONEY_STATUS_RESERVATION_SUCCESS,
    UPDATE_RETURN_MONEY_STATUS_RESERVATION_FAILURE
} from '../../actions/Reservations/updateReturnMoneyStatusReservationActions';

export function updateReturnMoneyStatusReservationReducer(state, action) {
    switch (action.type) {
        case UPDATE_RETURN_MONEY_STATUS_RESERVATION:
            return {
                ...state,
                isUpdatingReturnMoneyStatusReservation: true,
                updateReturnMoneyStatusReservationError: null,
            };
        case UPDATE_RETURN_MONEY_STATUS_RESERVATION_SUCCESS:
            return {
                ...state,
                isUpdatingReturnMoneyStatusReservation: false,
                updateReturnMoneyStatusReservationError: null,
                updateReturnMoneyStatusReservationResult: action.payload.data,
                cancelledReservationsInReservations: state.cancelledReservationsInReservations.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isReturnMoney: !item.isReturnMoney
                        }
                    }
                    return item;
                })
            };
        case UPDATE_RETURN_MONEY_STATUS_RESERVATION_FAILURE:
            return {
                ...state,
                isUpdatingReturnMoneyStatusReservation: false,
                updateReturnMoneyStatusReservationError: action.payload.message,
            };
        default:
            return state;
    }
}