export const PAY_IN_REQUEST = 'PAY_IN_REQUEST';
export const PAY_IN_REQUEST_SUCCESS = 'PAY_IN_REQUEST_SUCCESS';
export const PAY_IN_REQUEST_FAILURE = 'PAY_IN_REQUEST_FAILURE';

export const payInRequest = (requestId) => ({
    type: PAY_IN_REQUEST,
    requestId
});

export const payInRequestSuccess = (data) => ({
    type: PAY_IN_REQUEST_SUCCESS,
    payload: { data }
});

export const payInRequestFailure = (message) => ({
    type: PAY_IN_REQUEST_FAILURE,
    payload: { message }
});