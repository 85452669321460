export const UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES = 'UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES';
export const UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_FAILURE';

export const updateDisplayTypeInPausedTimes = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES,
    displayType
});

}
export const updateDisplayTypeInPausedTimesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInPausedTimesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_FAILURE,
    payload: message
});