import {
    GET_NEWS_IN_NEWS,
    GET_NEWS_IN_NEWS_SUCCESS,
    GET_NEWS_IN_NEWS_FAILURE
} from '../../actions/News/getNewsInNewsActions';

export function getNewsInNewsReducer(state, action) {
    switch (action.type) {
        case GET_NEWS_IN_NEWS:
            return {
                ...state,
                isGetNewsInNews: true,
                getNewsInNewsError: null,
            };
        case GET_NEWS_IN_NEWS_SUCCESS:
            console.log(action.payload.news)
            return {
                ...state,
                isGetNewsInNews: false,
                getNewsInNewsError: null,
                newsInNews: action.payload.news.data,
                 newsPageNumberInNews: action.payload.pageNumber,
                newsPageSizeInNews: action.payload.pageSize,
                newsTotalCountInNews: action.payload.news.total,
            };
        case GET_NEWS_IN_NEWS_FAILURE:
            return {
                ...state,
                isGetNewsInNews: false,
                getNewsInNewsError: action.payload.message,
            };
        default:
            return state;
}}