import React, { useState, useEffect, useRef,forwardRef } from "react";
import Swal from "sweetalert2";
import strings from '../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DisplayTypesInCompaniesConstant } from "../../constants/Companies/DisplayTypesInCompaniesConstant";
import Loading from "../Shared/Loading/Loading";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import 'react-phone-number-input/style.css'

function Edit(props) {
  const [insertedDateError, setInsertedDateError] = useState(null);
  const formikRef = React.useRef(null);
  useEffect(() => {
    if (props.editCompanyResult !== null && props.editCompanyResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editCompanyResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInCompanies(DisplayTypesInCompaniesConstant.LIST)
      }
      )
    }
  }, [props.editCompanyResult])

  useEffect(() => {
    if (props.editCompanyError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editCompanyError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editCompanyError])

  
  return (
    <>
      {props.isEditNewCompany && (<Loading />)}
      {!props.isEditNewCompany &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.editCompany}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  arabicName:props.editedCompanyInCompanies !== null ? props.editedCompanyInCompanies.nameAr : '',
                  englishName: props.editedCompanyInCompanies !== null ? props.editedCompanyInCompanies.nameEn : '',
                }}
                validationSchema={Yup.object().shape({
                  arabicName: Yup.string()
                    .required(strings.nameArRequired),
                  englishName: Yup.string()
                    .required(strings.nameEnRequired)
                })}
                onSubmit={fields => {
                  var company = {
                    id:props.editedCompanyInCompanies.id,
                    nameEn: fields.englishName,
                    nameAr: fields.arabicName,
                    isActive: true,
                  }
                 
                    props.editCompany(company);
                  
                }}
                render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.arabicName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="arabicName"
                            className={'form-control' + (errors.arabicName && touched.arabicName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameAr} *`}
                          />
                          <ErrorMessage name="arabicName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.englishName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="englishName"
                            className={'form-control' + (errors.englishName && touched.englishName ? ' is-invalid' : '')}
                            placeholder={`${strings.enterNameEn} *`}
                          />
                          <ErrorMessage name="englishName" component="div"
                            className="invalid-feedback" />

                        </div>
                      </div>
                    
                    </div>
                    <div className="card-footer d-flex justify-content-between p-3">
                      <div>
                        <button
                          type="submit"
                          className="btn fs-6"
                          style={{
                            backgroundColor: "#009ef7",
                            borderColor: "#009ef7",
                            color: "white",
                            borderRadius: "0.75rem",
                          }}
                          id="kt_account_profile_details_submit"
                        //	onClick={done}
                        >
                          {strings.save}
                        </button>
                      </div>
                    </div>
                  </Form>

                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Edit;
