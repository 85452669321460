export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const loginUser = (loginData) => ({
    type: LOGIN_USER,
    loginData
});

export const loginUserSuccess = (data) => ({
    type: LOGIN_USER_SUCCESS,
    payload: { data }
});

export const loginUserFailure = (message) => ({
    type: LOGIN_USER_FAILURE,
    payload: { message }
});