import {
    GET_TICKET_OPTIONS_IN_RESERVATIONS,
    GET_TICKET_OPTIONS_IN_RESERVATIONS_SUCCESS,
    GET_TICKET_OPTIONS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getTicketOptionsInReservationsActions';

export function getTicketOptionsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_OPTIONS_IN_RESERVATIONS:
            return {
                ...state,
                isGetTicketOptionsInReservations: true,
                getTicketOptionsInReservationsError: null,
            };
        case GET_TICKET_OPTIONS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetTicketOptionsInReservations: false,
                getTicketOptionsInReservationsError: null,
                ticketOptionsInReservations: action.payload.ticketOptions,
                //reservationTypeInReservations: action.payload.ticketOptions[0],
            };
        case GET_TICKET_OPTIONS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetTicketOptionsInReservations: false,
                getTicketOptionsInReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}