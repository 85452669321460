import React, { useState, useEffect } from "react";
import { DisplayTypesInUsersConstant } from "../../constants/Users/DisplayTypesInUsersConstant";
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
import User from './User';
import Add from './Add';
import Edit from './Edit';
function Users(props) {

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInUsers === DisplayTypesInUsersConstant.LIST ? strings.user :
                            props.displayTypeInUsers === DisplayTypesInUsersConstant.ADD ? strings.create :
                                props.displayTypeInUsers === DisplayTypesInUsersConstant.EDIT ? strings.edit : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                                props.displayTypeInUsers !== DisplayTypesInUsersConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInUsers(DisplayTypesInUsersConstant.LIST)}>
                                        {strings.user}
                                    </Button> : null}

                            {props.displayTypeInUsers !== DisplayTypesInUsersConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInUsers(DisplayTypesInUsersConstant.ADD) }}>
                                    {strings.new}
                                </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>

                            {props.displayTypeInUsers === DisplayTypesInUsersConstant.LIST && (<User {...props} />)}
                            {props.displayTypeInUsers === DisplayTypesInUsersConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInUsers === DisplayTypesInUsersConstant.EDIT && (<Edit {...props} />)}

                        </>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Users