import * as signalR from '@microsoft/signalr';
import { urlHub } from './config';

const connection = new signalR.HubConnectionBuilder()
.withUrl(`${urlHub}notifications`, { accessTokenFactory: () => localStorage.getItem("AquaContext") }).configureLogging(signalR.LogLevel.Information).build();

export default function getNotificationsConfig(props){

connection.on("sendToApp", data => {
    props.updateNotifications(data);
});

start();
}

async function start() {
    try {
        await connection.start();
    } catch (err) {
        setTimeout(start, 5000);
    }
};