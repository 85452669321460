import {
    GET_REQUEST_DETAILS,
    GET_REQUEST_DETAILS_SUCCESS,
    GET_REQUEST_DETAILS_FAILURE
} from '../../actions/Requests/getRequestDetailsActions';

export function getRequestDetailsReducer(state, action) {
    switch (action.type) {
        case GET_REQUEST_DETAILS:
            return {
                ...state,
                isGetRequestDetails: true,
                getRequestDetailsError: null, 
            };
        case GET_REQUEST_DETAILS_SUCCESS:
            return {
                ...state,
                isGetRequestDetails: false,
                getRequestDetailsError: null,
                requestDetailsInRequests:state.requestsInReservations.filter(x=> x.id == action.payload.requestId)[0],
            };
        case GET_REQUEST_DETAILS_FAILURE:
            return {
                ...state,
                isGetRequestDetails: false,
                getRequestDetailsError: action.payload.message,
            };
        default:
            return state;
}}