import {
    GET_CHARTS_DATA,
    GET_CHARTS_DATA_SUCCESS,
    GET_CHARTS_DATA_FAILURE
} from '../../actions/Dashboard/getChartsDataActions';

export function getChartsDataReducer(state, action) {
    switch (action.type) {
        case GET_CHARTS_DATA:
            return {
                ...state,
                isGetChartsData: true,
                getChartsDataError: null,
            };
        case GET_CHARTS_DATA_SUCCESS:
            return {
                ...state,
                isGetChartsData: false,
                getChartsDataError: null,
                chartsDataInAdmin: action.payload.data,
                chartsDataLabelEnInAdmin: action.payload.data.map(item => item.labelEn),
                chartsDataLabelArInAdmin: action.payload.data.map(item => item.labelAr),
                chartsDataValueInAdmin: action.payload.data.map(item => item.value),
            };
        case GET_CHARTS_DATA_FAILURE:
            return {
                ...state,
                isGetChartsData: false,
                getChartsDataError: action.payload.message,
            };
        default:
            return state;
}}