import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    CLEAR_RESERVATION_DETAILS,
    clearReservationDetailsSuccess,
    clearReservationDetailsFailure
} from '../../actions/Reservations/clearReservationDetailsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function clearReservationDetailsEpic(action$) {
    return action$.pipe(
        ofType(CLEAR_RESERVATION_DETAILS),
        mapTo(clearReservationDetailsSuccess()),
        catchError(error => of(clearReservationDetailsFailure(strings.somethingWrongHappened)))
    )
}