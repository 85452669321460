import {
    GET_EDITED_DISCOUNT,
    GET_EDITED_DISCOUNT_SUCCESS,
    GET_EDITED_DISCOUNT_FAILURE
} from '../../actions/Discounts/getEditedDiscountActions';

export function getEditedDiscountReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_DISCOUNT:
            return {
                ...state,
                isGetEditedDiscount: true,
                getEditedDiscountError: null, 
            };
        case GET_EDITED_DISCOUNT_SUCCESS:
            return {
                ...state,
                isGetEditedDiscount: false,
                getEditedDiscountError: null,
                editedDiscountInDiscounts:state.discountsInDiscounts.filter(x=> x.id == action.payload.discountId)[0],
            };
        case GET_EDITED_DISCOUNT_FAILURE:
            return {
                ...state,
                isGetEditedDiscount: false,
                getEditedDiscountError: action.payload.message,
            };
        default:
            return state;
}}