export const GET_COMPANIES_IN_COMPANY_RESERVATIONS = 'GET_COMPANIES_IN_COMPANY_RESERVATIONS';
export const GET_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE';

export const getCompaniesInCompanyReservations = () => ({
    type: GET_COMPANIES_IN_COMPANY_RESERVATIONS,
}); 

export const getCompaniesInCompanyReservationsSuccess = (companies) => ({
    type: GET_COMPANIES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { companies }
});

export const getCompaniesInCompanyReservationsFailure = (message) => ({
    type: GET_COMPANIES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});