export const GET_FROM_TIMES_IN_REQUESTS = 'GET_FROM_TIMES_IN_REQUESTS';
export const GET_FROM_TIMES_IN_REQUESTS_SUCCESS = 'GET_FROM_TIMES_IN_REQUESTS_SUCCESS';
export const GET_FROM_TIMES_IN_REQUESTS_FAILURE = 'GET_FROM_TIMES_IN_REQUESTS_FAILURE';

export const getFromTimesInRequests = (selectedDate) => ({
    type: GET_FROM_TIMES_IN_REQUESTS,
    selectedDate
});

export const getFromTimesInRequestsSuccess = (timeResultList,selectedDate) => ({
    type: GET_FROM_TIMES_IN_REQUESTS_SUCCESS,
    payload: { timeResultList,selectedDate }
});

export const getFromTimesInRequestsFailure = (message) => ({
    type: GET_FROM_TIMES_IN_REQUESTS_FAILURE,
    payload: message
});