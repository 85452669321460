import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_DISCOUNTS,
    updateDisplayTypeInDiscountsSuccess,
    updateDisplayTypeInDiscountsFailure
} from '../../../src/actions/Discounts/updateDisplayTypeInDiscountsActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInDiscountsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_DISCOUNTS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInDiscountsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInDiscountsFailure(strings.somethingWrongHappened)))
    )
}

