import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS,
    getReservationTypesInReservationTypeSettingsSuccess,
    getReservationTypesInReservationTypeSettingsFailure
} from '../../actions/ReservationTypeSettings/getReservationTypesInReservationTypeSettingsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getReservationTypesInReservationTypeSettingsEpic(action$) {
    return action$.pipe(
        ofType(GET_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS),
        switchMap(action => {
            const queryString= action.reservationTypeId !== null ? `?reservationTypeId=${action.reservationTypeId}` : '';
            const ajaxConfigObj = {
                url:  url + `ReservationTypeSetting/GetReservationTypes${queryString}`,
                method: 'Post',
                headers: {
                	//'Authorization':token,
                    //'content-type': 'application/json'
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
            map(data => ({
                response: data.response,
            })))
        }),
        mergeMap(data => {
             console.log(data.response)
            if (data.response.isSuccess) {
                const reservationTypes=data.response.data.map(item=>({
                    label:strings.getLanguage() === LanguagesConstant.AR ? item.nameAr : item.nameEn,
                    value:item.id
                }))
                return of(getReservationTypesInReservationTypeSettingsSuccess(reservationTypes));
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                 && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getReservationTypesInReservationTypeSettingsFailure(message));
            }
        }),
        catchError(error =>  of(getReservationTypesInReservationTypeSettingsFailure(strings.somethingWrongHappened)))
    )
}

