import {
    UPDATE_DISPLAY_TYPE_IN_DISCOUNTS,
    UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_FAILURE
} from '../../../src/actions/Discounts/updateDisplayTypeInDiscountsActions';

export function updateDisplayTypeInDiscountsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_DISCOUNTS:
            return {
                ...state,
                isUpdatingDisplayTypeInDiscounts: true,
                updateDisplayTypeErrorInDiscounts: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInDiscounts: false,
                displayTypeInDiscounts: action.payload.displayType,
                updateDisplayTypeErrorInDiscounts: null,

                isAddNewDiscount: false,
                addNewDiscountError: null,
                addNewDiscountResult: null,

                isDeletingDiscount: false,
                deleteDiscountError: null,
                deleteDiscountResult: null,

                isEditingDiscount: false,
                editDiscountError: null,
                editDiscountResult: null,

                isUpdatingStatusDiscount: false,
                updateStatusDiscountError: null,
                updateStatusDiscountResult: null,

                ischeckingDiscountNameEnAvailabilityInDiscounts: false,
                isDiscountNameEnAvailableInDiscounts: true,
                checkDiscountNameEnAvailabilityErrorInDiscounts: null,

                ischeckingDiscountNameArAvailabilityInDiscounts: false,
                isDiscountNameArAvailableInDiscounts: true,
                checkDiscountNameArAvailabilityErrorInDiscounts: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInDiscounts: false,
                updateDisplayTypeErrorInDiscounts: action.payload
            };
        default:
            return state;
    }
}