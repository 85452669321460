export const UPDATE_STATUS_SMS_SETTING = 'UPDATE_STATUS_SMS_SETTING';
export const UPDATE_STATUS_SMS_SETTING_SUCCESS = 'UPDATE_STATUS_SMS_SETTING_SUCCESS';
export const UPDATE_STATUS_SMS_SETTING_FAILURE = 'UPDATE_STATUS_SMS_SETTING_FAILURE';

export const updateStatusSmsSetting = (smsSettingId) => {
    console.log('update status smsSetting action')
return({
    type: UPDATE_STATUS_SMS_SETTING,
    smsSettingId
});
}
export const updateStatusSmsSettingSuccess = (data) => ({
    type: UPDATE_STATUS_SMS_SETTING_SUCCESS,
    payload: { data }
});

export const updateStatusSmsSettingFailure = (message) => ({
    type: UPDATE_STATUS_SMS_SETTING_FAILURE,
    payload: { message }
});