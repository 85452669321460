export const UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS = 'UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS';
export const UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateToTimesInCompanyReservations = (selectedTime) => ({
    type: UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS,
    selectedTime
});

export const updateToTimesInCompanyReservationsSuccess = (selectedTime) => ({
    type: UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { selectedTime }
});

export const updateToTimesInCompanyReservationsFailure = (message) => ({
    type: UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});