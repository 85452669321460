import React, { useState, useEffect } from "react";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../../Shared/toolbar";
import strings from '../../../localizations/strings';
import RolesList from './Role'
import Add from './Add';
import Edit from './Edit';
import Details from './Details';
import { DisplayTypesInRolesConstant } from "../../../constants/Roles/Roles/DisplayTypesInRolesConstant";
import '../roles.css'
function Roles(props) {

    return (
        <div className="content d-flex flex-column flex-column-fluid pt-0">
            <Toolbar
                pagename={
                    props.displayTypeInRoles === DisplayTypesInRolesConstant.ADD
                        ? strings.create :
                        props.displayTypeInRoles === DisplayTypesInRolesConstant.LIST
                            ? strings.roles :
                            props.displayTypeInRoles === DisplayTypesInRolesConstant.Details
                                ? strings.details :
                                props.displayTypeInRoles === DisplayTypesInRolesConstant.Edit
                                    ? strings.edit : ""
                }
                parentpage={strings.Items}
                Listbtns={(
                    <>

                        {props.displayTypeInRoles !== DisplayTypesInRolesConstant.LIST ?
                            <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInRoles(DisplayTypesInRolesConstant.LIST)}>
                                {strings.roles}
                            </Button> : null}

                        {props.displayTypeInRoles === DisplayTypesInRolesConstant.LIST ?
                            <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInRoles(DisplayTypesInRolesConstant.ADD) }}>
                                {strings.new}
                            </Button> : null}
                    </>
                )} />

            <div className="post d-flex flex-column-fluid" id="kt_post" >
                <div id="kt_content_container" className="kt_content_container container-xxl">
                    <>
                        {props.displayTypeInRoles === DisplayTypesInRolesConstant.LIST &&
                            (<RolesList {...props} />)}
                        {props.displayTypeInRoles === DisplayTypesInRolesConstant.ADD && 
                        (<Add {...props} />)}
                       {props.displayTypeInRoles === DisplayTypesInRolesConstant.Edit && 
                        (<Edit {...props} />)}
                        {props.displayTypeInRoles === DisplayTypesInRolesConstant.Details && 
                        (<Details {...props} />)}
                    </>

                </div>
            </div>
        </div>

    );
}
export default Roles
