export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS';
export const ADD_NEW_COMPANY_FAILURE = 'ADD_NEW_COMPANY_FAILURE';

export const addNewCompany = (companyData) => ({
    type: ADD_NEW_COMPANY,
    companyData
});

export const addNewCompanySuccess = (data) => ({
    type: ADD_NEW_COMPANY_SUCCESS,
    payload: { data }
});

export const addNewCompanyFailure = (message) => ({
    type: ADD_NEW_COMPANY_FAILURE,
    payload: { message }
});