import React, { useEffect,useState } from "react";
import strings from '../../localizations/strings';
import { DisplayTypesInSmsSettingsConstant } from "../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'

function SmsSetting(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getSmsSettingsInSmsSettings(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getSmsSettingsInSmsSettings();
  }, [])

  useEffect(() => {
    if (
      props.deleteSmsSettingResult !== null &&
      props.deleteSmsSettingResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deleted}`, props.deleteSmsSettingResult.message, "success").then(result => {
        props.getSmsSettingsInSmsSettings(props.smsSettingsPageNumberInSmsSettings, props.smsSettingsPageSizeInSmsSettings,search);
      });
    }
  }, [props.deleteSmsSettingResult]);

  useEffect(() => {
    if (
      props.deleteSmsSettingError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteSmsSettingError, "error");
    }
  }, [props.deleteSmsSettingError]);

  useEffect(() => {
    if (props.updateStatusSmsSettingResult !== null && props.updateStatusSmsSettingResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusSmsSettingResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusSmsSettingResult]);

  useEffect(() => {
    if (props.updateStatusSmsSettingError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusSmsSettingError, "error");
    }
  }, [props.updateStatusSmsSettingError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.smsSettingsPageNumberInSmsSettings) {
      props.getSmsSettingsInSmsSettings(pageNumber, props.smsSettingsPageSizeInSmsSettings,search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getSmsSettingsInSmsSettings(1, props.smsSettingsPageSizeInSmsSettings, selected.target.value)
  }

  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetSmsSettingsInSmsSettings && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.smsSettingsTotalCountInSmsSettings}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.smsSettingsPageSizeInSmsSettings)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.smsSettingsTotalCountInSmsSettings}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-3">
            <table className="table align-middle text-center table-bordered border-bottom">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                  <th className="min-w-125px px-0">{strings.smsTypeName}</th>
                  <th className="min-w-125px px-0">{strings.text}</th>
                  <th className='text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn'>{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {
                  props.smsSettingsInSmsSettings !== []
                  && props.smsSettingsInSmsSettings.length > 0
                  && props.smsSettingsInSmsSettings.map((smsSetting, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{index + 1}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() == LanguagesConstant.EN ? smsSetting.smsTypeNameEn : smsSetting.smsTypeNameAr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {smsSetting.text}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action1">
                              <a onClick={() => {
                                props.getEditedSmsSetting(smsSetting.id);
                                props.updateDisplayTypeInSmsSettings(
                                  DisplayTypesInSmsSettingsConstant.EDIT
                                )
                              }}>

                                <i className="fas fa-edit edit"></i>
                              </a>

                            </div>
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, smsSetting.id) }}>
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {
              props.smsSettingsTotalCountInSmsSettings !== null
              && props.smsSettingsTotalCountInSmsSettings > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.smsSettingsPageNumberInSmsSettings - 1) * props.smsSettingsPageSizeInSmsSettings) + 1} {strings.to} {(props.smsSettingsPageNumberInSmsSettings * props.smsSettingsPageSizeInSmsSettings) > props.smsSettingsTotalCountInSmsSettings ? props.smsSettingsTotalCountInSmsSettings : (props.smsSettingsPageNumberInSmsSettings * props.smsSettingsPageSizeInSmsSettings)} {strings.of} {props.smsSettingsTotalCountInSmsSettings} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.smsSettingsPageNumberInSmsSettings}
                    itemsCountPerPage={props.smsSettingsPageSizeInSmsSettings}
                    totalItemsCount={props.smsSettingsTotalCountInSmsSettings}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SmsSetting;