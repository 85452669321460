export const DELETE_NOTIFICATION_SETTING = 'DELETE_NOTIFICATION_SETTING';
export const DELETE_NOTIFICATION_SETTING_SUCCESS = 'DELETE_NOTIFICATION_SETTING_SUCCESS';
export const DELETE_NOTIFICATION_SETTING_FAILURE = 'DELETE_NOTIFICATION_SETTING_FAILURE';

export const deleteNotificationSetting = (notificationSettingId) => ({
    type: DELETE_NOTIFICATION_SETTING,
    notificationSettingId
});

export const deleteNotificationSettingSuccess = (data) => ({
    type: DELETE_NOTIFICATION_SETTING_SUCCESS,
    payload: { data }
});

export const deleteNotificationSettingFailure = (message) => ({
    type: DELETE_NOTIFICATION_SETTING_FAILURE,
    payload: { message }
});