import {
    GET_SMS_TYPES_IN_SMS_SETTINGS,
    GET_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS,
    GET_SMS_TYPES_IN_SMS_SETTINGS_FAILURE
} from '../../actions/SmsSettings/getSmsTypesInSmsSettingsActions';

export function getSmsTypesInSmsSettingsReducer(state, action) {
    switch (action.type) {
        case GET_SMS_TYPES_IN_SMS_SETTINGS:
            return {
                ...state,
                isGetSmsTypesInSmsSettings: true,
                getSmsTypesInSmsSettingsError: null,
            };
        case GET_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS:
            return {
                ...state,
                isGetSmsTypesInSmsSettings: false,
                getSmsTypesInSmsSettingsError: null,
                smsTypesInSmsSettings: action.payload.smsTypes,
                smsTypeInSmsSettings:
                    state.editedSmsSettingInSmsSettings !== null
                        ? action.payload.smsTypes.filter(
                            x => x.value == state.editedSmsSettingInSmsSettings.smsTypeId
                        )[0]
                        : null,
            };
        case GET_SMS_TYPES_IN_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                isGetSmsTypesInSmsSettings: false,
                getSmsTypesInSmsSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}