import {
    GET_DAYS_IN_RESERVATION_SCHEDULES,
    GET_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS,
    GET_DAYS_IN_RESERVATION_SCHEDULES_FAILURE
} from '../../actions/ReservationSchedules/getDaysInReservationSchedulesActions';

export function getDaysInReservationSchedulesReducer(state, action) {
    switch (action.type) {
        case GET_DAYS_IN_RESERVATION_SCHEDULES:
            return {
                ...state,
                isGetDaysInReservationSchedules: true,
                getDaysInReservationSchedulesError: null,
            };
        case GET_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS:
            return {
                ...state,
                isGetDaysInReservationSchedules: false,
                getDaysInReservationSchedulesError: null,
                daysInReservationSchedules: action.payload.days,
                dayInReservationSchedules:
                    state.editedReservationScheduleInReservationSchedules !== null && !state.editedReservationScheduleInReservationSchedules.isOfficialVacation
                        ? action.payload.days.filter(
                            x => x.value == state.editedReservationScheduleInReservationSchedules.dayId
                        )[0]
                        : null,
            };
        case GET_DAYS_IN_RESERVATION_SCHEDULES_FAILURE:
            return {
                ...state,
                isGetDaysInReservationSchedules: false,
                getDaysInReservationSchedulesError: action.payload.message,
            };
        default:
            return state;
    }
}