export const EDIT_NEW = 'EDIT_NEW';
export const EDIT_NEW_SUCCESS = 'EDIT_NEW_SUCCESS';
export const EDIT_NEW_FAILURE = 'EDIT_NEW_FAILURE';

export const editNew = (newData) =>{
    console.log(newData) 
    return ({
    type: EDIT_NEW,
    newData
});
}
export const editNewSuccess = (data) => ({
    type: EDIT_NEW_SUCCESS,
    payload: { data }
});

export const editNewFailure = (message) => ({
    type: EDIT_NEW_FAILURE,
    payload: { message }
});