import {
    UPDATE_DAYS_IN_RESERVATION_SCHEDULES,
    UPDATE_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS,
    UPDATE_DAYS_IN_RESERVATION_SCHEDULES_FAILURE
} from '../../actions/ReservationSchedules/updateDaysInReservationSchedulesActions';

export function updateDaysInReservationSchedulesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DAYS_IN_RESERVATION_SCHEDULES:
            return {
                ...state,
                isUpdateDaysInReservationSchedules: true,
                updateDaysInReservationSchedulesError: null,
            };
        case UPDATE_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS:
            return {
                ...state,
                isUpdateDaysInReservationSchedules: false,
                updateDaysInReservationSchedulesError: null,
                dayInReservationSchedules: action.payload.day,
            };
        case UPDATE_DAYS_IN_RESERVATION_SCHEDULES_FAILURE:
            return {
                ...state,
                isUpdateDaysInReservationSchedules: false,
                updateDaysInReservationSchedulesError: action.payload.message,
            };
        default:
            return state;
    }
}