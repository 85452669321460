export const UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS = 'UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS';
export const UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_FAILURE';

export const updateDisplayTypeInRoleScreens = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS,
    displayType
});

export const updateDisplayTypeInRoleScreensSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInRoleScreensFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ROLE_SCREENS_FAILURE,
    payload: message
});