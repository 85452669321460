import {
    UPDATE_DISPLAY_TYPE_IN_ROLES,
    UPDATE_DISPLAY_TYPE_IN_ROLES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_ROLES_FAILURE
} from '../../../actions/Roles/Roles/updateDisplayTypeInRolesActions';

import { DisplayTypesInRoleScreensConstant } from '../../../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant';

import { DisplayTypesInRoleUsersConstant } from '../../../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant';

export function updateDisplayTypeInRolesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_ROLES:
            return {
                ...state,
                isUpdatingDisplayTypeInRoles: true,
                updateDisplayTypeErrorInRoles: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLES_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInRoles: false,
                displayTypeInRoles: action.payload.displayType,
                updateDisplayTypeErrorInRoles: null,

                isUpdatingDisplayTypeInRoleScreens: false,
                displayTypeInRoleScreens: DisplayTypesInRoleScreensConstant.LIST,
                updateDisplayTypeErrorInRoleScreens: null,

                isUpdatingDisplayTypeInRoleUsers: false,
                displayTypeInRoleUsers: DisplayTypesInRoleUsersConstant.LIST,
                updateDisplayTypeErrorInRoleUsers: null,

                ischeckingRoleNameArAvailabilityInRoles: false,
                isRoleNameArAvailableInRoles: true,
                checkRoleNameArAvailabilityErrorInRoles: null,

                ischeckingRoleNameEnAvailabilityInRoles: false,
                isRoleNameEnAvailableInRoles: true,
                checkRoleNameEnAvailabilityErrorInRoles: null,

                isDeletingRole: false,
                deleteRoleError: null,
                deleteRoleResult: null,

                isCreateNewRole: false,
                createNewRoleError: null,
                createNewRoleResult:null,

                isEditingRole: false,
                editRoleError: null,
                editRoleResult:null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ROLES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInRoles: false,
                updateDisplayTypeErrorInRoles: action.payload
            };
        default:
            return state;
    }
}