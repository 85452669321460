import {
    ADD_NEW_RESERVATION_SCHEDULE,
    ADD_NEW_RESERVATION_SCHEDULE_SUCCESS,
    ADD_NEW_RESERVATION_SCHEDULE_FAILURE
} from '../../actions/ReservationSchedules/addNewReservationScheduleActions';

import { DisplayTypesInReservationSchedulesConstant } from '../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant';

export function addNewReservationScheduleReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_RESERVATION_SCHEDULE:
            return {
                ...state,
                isAddNewReservationSchedule: true,
                addNewReservationScheduleError: null, 
            };
        case ADD_NEW_RESERVATION_SCHEDULE_SUCCESS:
            return {
                ...state,
                isAddNewReservationSchedule: false,
                addNewReservationScheduleError: null,
                addNewReservationScheduleResult: action.payload.data,
              //  displayTypeInReservationSchedules: DisplayTypesInReservationSchedulesConstant.LIST,
            };
        case ADD_NEW_RESERVATION_SCHEDULE_FAILURE:
            return {
                ...state,
                isAddNewReservationSchedule: false,
                addNewReservationScheduleError: action.payload.message,
            };
        default:
            return state;
}}