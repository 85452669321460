export const EDIT_ROLE_USER = 'EDIT_ROLE_USER';
export const EDIT_ROLE_USER_SUCCESS = 'EDIT_ROLE_USER_SUCCESS';
export const EDIT_ROLE_USER_FAILURE = 'EDIT_ROLE_USER_FAILURE';

export const editRoleUser = (userData) => ({
    type: EDIT_ROLE_USER,
    userData
});

export const editRoleUserSuccess = (data) => ({
    type: EDIT_ROLE_USER_SUCCESS,
    payload: { data }
});

export const editRoleUserFailure = (message) => ({
    type: EDIT_ROLE_USER_FAILURE,
    payload: { message }
});