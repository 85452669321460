export const GET_EDITED_RESERVATION_SCHEDULE = 'GET_EDITED_RESERVATION_SCHEDULE';
export const GET_EDITED_RESERVATION_SCHEDULE_SUCCESS = 'GET_EDITED_RESERVATION_SCHEDULE_SUCCESS';
export const GET_EDITED_RESERVATION_SCHEDULE_FAILURE = 'GET_EDITED_RESERVATION_SCHEDULE_FAILURE';

export const getEditedReservationSchedule = (reservationScheduleId) => ({
    type: GET_EDITED_RESERVATION_SCHEDULE,
    reservationScheduleId
});

export const getEditedReservationScheduleSuccess = (reservationScheduleId) => ({
    type: GET_EDITED_RESERVATION_SCHEDULE_SUCCESS,
    payload: { reservationScheduleId }
});

export const getEditedReservationScheduleFailure = (message) => ({
    type: GET_EDITED_RESERVATION_SCHEDULE_FAILURE,
    payload: { message }
});
