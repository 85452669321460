export const ADD_NEW_PAUSED_TIME = 'ADD_NEW_PAUSED_TIME';
export const ADD_NEW_PAUSED_TIME_SUCCESS = 'ADD_NEW_PAUSED_TIME_SUCCESS';
export const ADD_NEW_PAUSED_TIME_FAILURE = 'ADD_NEW_PAUSED_TIME_FAILURE';

export const addNewPausedTime = (pausedTimeData) =>{
    console.log("action")
    return ({
    type: ADD_NEW_PAUSED_TIME,
    pausedTimeData
});
}
export const addNewPausedTimeSuccess = (data) => ({
    type: ADD_NEW_PAUSED_TIME_SUCCESS,
    payload: { data }
});

export const addNewPausedTimeFailure = (message) => ({
    type: ADD_NEW_PAUSED_TIME_FAILURE,
    payload: { message }
});