import {
    UPDATE_PAYMENT_STATUS_RESERVATION,
    UPDATE_PAYMENT_STATUS_RESERVATION_SUCCESS,
    UPDATE_PAYMENT_STATUS_RESERVATION_FAILURE
} from '../../actions/Reservations/updatePaymentStatusReservationActions';

export function updatePaymentStatusReservationReducer(state, action) {
    switch (action.type) {
        case UPDATE_PAYMENT_STATUS_RESERVATION:
            return {
                ...state,
                isUpdatingPaymentStatusReservation: true,
                updatePaymentStatusReservationError: null,
            };
        case UPDATE_PAYMENT_STATUS_RESERVATION_SUCCESS:
            return {
                ...state,
                isUpdatingPaymentStatusReservation: false,
                updatePaymentStatusReservationError: null,
                updatePaymentStatusReservationResult: action.payload.data,
            };
        case UPDATE_PAYMENT_STATUS_RESERVATION_FAILURE:
            return {
                ...state,
                isUpdatingPaymentStatusReservation: false,
                updatePaymentStatusReservationError: action.payload.message,
            };
        default:
            return state;
    }
}