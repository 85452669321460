import {
    DELETE_RESERVATION_TYPE_SETTING,
    DELETE_RESERVATION_TYPE_SETTING_SUCCESS,
    DELETE_RESERVATION_TYPE_SETTING_FAILURE
} from '../../actions/ReservationTypeSettings/deleteReservationTypeSettingActions';

import { DisplayTypesInReservationTypeSettingsConstant } from '../../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant';

export function deleteReservationTypeSettingReducer(state, action) {
    switch (action.type) {
        case DELETE_RESERVATION_TYPE_SETTING:
            return {
                ...state,
                isDeletingReservationTypeSetting: true,
                deleteReservationTypeSettingError: null,
            };
        case DELETE_RESERVATION_TYPE_SETTING_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingReservationTypeSetting: false,
                deleteReservationTypeSettingError: null,
                deleteReservationTypeSettingResult: action.payload.data,
                //displayTypeInReservationTypeSettings: DisplayTypesInReservationTypeSettingsConstant.LIST,
            };
        case DELETE_RESERVATION_TYPE_SETTING_FAILURE:
            return {
                ...state,
                isDeletingReservationTypeSetting: false,
                deleteReservationTypeSettingError: action.payload.message,
            };
        default:
            return state;
}}