export const ADD_NEW_RESERVATION_SCHEDULE = 'ADD_NEW_RESERVATION_SCHEDULE';
export const ADD_NEW_RESERVATION_SCHEDULE_SUCCESS = 'ADD_NEW_RESERVATION_SCHEDULE_SUCCESS';
export const ADD_NEW_RESERVATION_SCHEDULE_FAILURE = 'ADD_NEW_RESERVATION_SCHEDULE_FAILURE';

export const addNewReservationSchedule = (reservationScheduleData) =>{
    console.log("action")
    return ({
    type: ADD_NEW_RESERVATION_SCHEDULE,
    reservationScheduleData
});
}
export const addNewReservationScheduleSuccess = (data) => ({
    type: ADD_NEW_RESERVATION_SCHEDULE_SUCCESS,
    payload: { data }
});

export const addNewReservationScheduleFailure = (message) => ({
    type: ADD_NEW_RESERVATION_SCHEDULE_FAILURE,
    payload: { message }
});