export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const APPROVE_REQUEST_SUCCESS = 'APPROVE_REQUEST_SUCCESS';
export const APPROVE_REQUEST_FAILURE = 'APPROVE_REQUEST_FAILURE';

export const approveRequest = (requestViewModel) => ({
    type: APPROVE_REQUEST,
    requestViewModel
});

export const approveRequestSuccess = (requestViewModel) => ({
    type: APPROVE_REQUEST_SUCCESS,
    payload: { requestViewModel }
});

export const approveRequestFailure = (message) => ({
    type: APPROVE_REQUEST_FAILURE,
    payload: message
});