export const GET_EDITED_NOTIFICATION_SETTING = 'GET_EDITED_NOTIFICATION_SETTING';
export const GET_EDITED_NOTIFICATION_SETTING_SUCCESS = 'GET_EDITED_NOTIFICATION_SETTING_SUCCESS';
export const GET_EDITED_NOTIFICATION_SETTING_FAILURE = 'GET_EDITED_NOTIFICATION_SETTING_FAILURE';

export const getEditedNotificationSetting = (notificationSettingId) => ({
    type: GET_EDITED_NOTIFICATION_SETTING,
    notificationSettingId
});

export const getEditedNotificationSettingSuccess = (notificationSettingId) => ({
    type: GET_EDITED_NOTIFICATION_SETTING_SUCCESS,
    payload: { notificationSettingId }
});

export const getEditedNotificationSettingFailure = (message) => ({
    type: GET_EDITED_NOTIFICATION_SETTING_FAILURE,
    payload: { message }
});
