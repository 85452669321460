import {
    GET_PAUSED_TIMES_IN_PAUSED_TIMES,
    GET_PAUSED_TIMES_IN_PAUSED_TIMES_SUCCESS,
    GET_PAUSED_TIMES_IN_PAUSED_TIMES_FAILURE
} from '../../actions/PausedTimes/getPausedTimesInPausedTimesActions';

export function getPausedTimesInPausedTimesReducer(state, action) {
    switch (action.type) {
        case GET_PAUSED_TIMES_IN_PAUSED_TIMES:
            return {
                ...state,
                isGetPausedTimesInPausedTimes: true,
                getPausedTimesInPausedTimesError: null,
            };
        case GET_PAUSED_TIMES_IN_PAUSED_TIMES_SUCCESS:
            console.log(action.payload.pausedTimes)
            return {
                ...state,
                isGetPausedTimesInPausedTimes: false,
                getPausedTimesInPausedTimesError: null,
                pausedTimesInPausedTimes: action.payload.pausedTimes.data,
                pausedTimesPageNumberInPausedTimes: action.payload.pageNumber,
                pausedTimesPageSizeInPausedTimes: action.payload.pageSize,
                pausedTimesTotalCountInPausedTimes: action.payload.pausedTimes.total,
            };
        case GET_PAUSED_TIMES_IN_PAUSED_TIMES_FAILURE:
            return {
                ...state,
                isGetPausedTimesInPausedTimes: false,
                getPausedTimesInPausedTimesError: action.payload.message,
            };
        default:
            return state;
}}