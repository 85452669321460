export const EDIT_GALLERY = 'EDIT_GALLERY';
export const EDIT_GALLERY_SUCCESS = 'EDIT_GALLERY_SUCCESS';
export const EDIT_GALLERY_FAILURE = 'EDIT_GALLERY_FAILURE';

export const editGallery = (galleryData) =>{
    console.log(galleryData) 
    return ({
    type: EDIT_GALLERY,
    galleryData
});
}
export const editGallerySuccess = (data) => ({
    type: EDIT_GALLERY_SUCCESS,
    payload: { data }
});

export const editGalleryFailure = (message) => ({
    type: EDIT_GALLERY_FAILURE,
    payload: { message }
});