export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const deleteCompany = (companyId) => ({
    type: DELETE_COMPANY,
    companyId
});

export const deleteCompanySuccess = (data) => ({
    type: DELETE_COMPANY_SUCCESS,
    payload: { data }
});

export const deleteCompanyFailure = (message) => ({
    type: DELETE_COMPANY_FAILURE,
    payload: { message }
});