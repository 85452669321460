import {
    CHANGE_NOTIFICATION_IS_READ,
    CHANGE_NOTIFICATION_IS_READ_SUCCESS,
    CHANGE_NOTIFICATION_IS_READ_FAILURE
} from '../../actions/Notifications/changeNotificationIsReadActions';


export function changeNotificationIsReadReducer(state, action) {
    switch (action.type) {
        case CHANGE_NOTIFICATION_IS_READ:
            return {
                ...state,
                isChangeNotificationIsRead: true,
                changeNotificationIsReadError: null,
            };
        case CHANGE_NOTIFICATION_IS_READ_SUCCESS:
            return {
                ...state,
                isChangeNotificationIsRead: false,
                changeNotificationIsReadError: null,
                changeNotificationIsReadResult: action.payload.data,
                notificationsUser:state.notificationsUser.map(item => {
                    if(item.id == action.payload.data){
                        return {
                            ...item,
                            isRead:true
                        };
                    }
                    return item;
                }),
                unReadNotificationsUserCount:state.unReadNotificationsUserCount - 1
               
            };
        case CHANGE_NOTIFICATION_IS_READ_FAILURE:
            return {
                ...state,
                isChangeNotificationIsRead: false,
                changeNotificationIsReadError: action.payload.message,
            };
        default:
            return state;
}}