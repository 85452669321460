import {
    UPDATE_USERS_IN_RESERVATIONS,
    UPDATE_USERS_IN_RESERVATIONS_SUCCESS,
    UPDATE_USERS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateUsersInReservationsActions';

export function updateUsersInReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_USERS_IN_RESERVATIONS:
            return {
                ...state,
                isUpdateUsersInReservations: true,
                updateUsersInReservationsError: null,
            };
        case UPDATE_USERS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdateUsersInReservations: false,
                updateUsersInReservationsError: null,
                userInReservations: action.payload.user,
            };
        case UPDATE_USERS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdateUsersInReservations: false,
                updateUsersInReservationsError: action.payload.message,
            };
        default:
            return state;
}}