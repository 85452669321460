export const GET_SMS_TYPES_IN_SMS_SETTINGS = 'GET_SMS_TYPES_IN_SMS_SETTINGS';
export const GET_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS = 'GET_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS';
export const GET_SMS_TYPES_IN_SMS_SETTINGS_FAILURE = 'GET_SMS_TYPES_IN_SMS_SETTINGS_FAILURE';

export const getSmsTypesInSmsSettings = (smsSettingId = null) => ({
    type: GET_SMS_TYPES_IN_SMS_SETTINGS,
    smsSettingId
});

export const getSmsTypesInSmsSettingsSuccess = (smsTypes) => ({
    type: GET_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS,
    payload: { smsTypes }
});

export const getSmsTypesInSmsSettingsFailure = (message) => ({
    type: GET_SMS_TYPES_IN_SMS_SETTINGS_FAILURE,
    payload: { message }
});