import {
    EDIT_TICKET_TYPE,
    EDIT_TICKET_TYPE_SUCCESS,
    EDIT_TICKET_TYPE_FAILURE
} from '../../actions/TicketTypes/editTicketTypeActions';

import { DisplayTypesInTicketTypesConstant } from '../../constants/TicketTypes/DisplayTypesInTicketTypesConstant';

export function editTicketTypeReducer(state, action) {
    switch (action.type) {
        case EDIT_TICKET_TYPE:
            return {
                ...state,
                isEditingTicketType: true,
                editTicketTypeError: null,
            };
        case EDIT_TICKET_TYPE_SUCCESS:
            return {
                ...state,
                isEditingTicketType: false,
                editTicketTypeError: null,
                editTicketTypeResult: action.payload.data,
               // displayTypeInTicketTypes: DisplayTypesInTicketTypesConstant.LIST,
            };
        case EDIT_TICKET_TYPE_FAILURE:
            return {
                ...state,
                isEditingTicketType: false,
                editTicketTypeError: action.payload.message,
            };
        default:
            return state;
}}