export const GET_COMPANIES_IN_COMPANIES = 'GET_COMPANIES_IN_COMPANIES';
export const GET_COMPANIES_IN_COMPANIES_SUCCESS = 'GET_COMPANIES_IN_COMPANIES_SUCCESS';
export const GET_COMPANIES_IN_COMPANIES_FAILURE = 'GET_COMPANIES_IN_COMPANIES_FAILURE';

export const getCompaniesInCompanies = (companyId=null,pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_COMPANIES_IN_COMPANIES,
    companyId,
    pageNumber,
    pageSize,
    search
});

export const getCompaniesInCompaniesSuccess = (companies, pageNumber, pageSize) => ({
    type: GET_COMPANIES_IN_COMPANIES_SUCCESS,
    payload: { companies, pageNumber, pageSize }
});

export const getCompaniesInCompaniesFailure = (message) => ({
    type: GET_COMPANIES_IN_COMPANIES_FAILURE,
    payload: { message }
});