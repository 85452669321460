import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_DAYS_IN_RESERVATION_SCHEDULES,
    updateDaysInReservationSchedulesSuccess,
    updateDaysInReservationSchedulesFailure
} from '../../actions/ReservationSchedules/updateDaysInReservationSchedulesActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateDaysInReservationSchedulesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DAYS_IN_RESERVATION_SCHEDULES),
        map(action => ({
            day: action.day
        })),
        map(response =>

            updateDaysInReservationSchedulesSuccess(response.day)

        ),
        catchError(error => of(updateDaysInReservationSchedulesFailure(strings.somethingWrongHappened)))
    )
}

