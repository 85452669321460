import {
    DELETE_DISCOUNT,
    DELETE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_FAILURE
} from '../../actions/Discounts/deleteDiscountActions';

import { DisplayTypesInDiscountsConstant } from '../../constants/Discounts/DisplayTypesInDiscountsConstant';

export function deleteDiscountReducer(state, action) {
    switch (action.type) {
        case DELETE_DISCOUNT:
            return {
                ...state,
                isDeletingDiscount: true,
                deleteDiscountError: null,
            };
        case DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                isDeletingDiscount: false,
                deleteDiscountError: null,
                deleteDiscountResult: action.payload.data,
                //displayTypeInDiscounts: DisplayTypesInDiscountsConstant.LIST,
            };
        case DELETE_DISCOUNT_FAILURE:
            return {
                ...state,
                isDeletingDiscount: false,
                deleteDiscountError: action.payload.message,
            };
        default:
            return state;
}}