import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap,map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_STATUS_SMS_SETTING,
    updateStatusSmsSettingSuccess,
    updateStatusSmsSettingFailure
} from '../../actions/SmsSettings/updateStatusSmsSettingActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateStatusSmsSettingEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_STATUS_SMS_SETTING),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `SmsSetting/ChangeIsActive?smsSettingId=${action.smsSettingId}`,
                method: 'POST',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                return of(updateStatusSmsSettingSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(updateStatusSmsSettingFailure(message));
            }
        }),
        catchError(error =>  of(updateStatusSmsSettingFailure(strings.somethingWrongHappened)))
    )
}

