import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_DISPLAY_TYPE_IN_ROLE_USERS,
    updateDisplayTypeInRoleUsersSuccess,
    updateDisplayTypeInRoleUsersFailure
} from '../../../actions/Roles/RoleUsers/updateDisplayTypeInRoleUsersActions';

import strings from '../../../localizations/strings';

export function updateDisplayTypeInRoleUsersEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_ROLE_USERS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>
            updateDisplayTypeInRoleUsersSuccess(response.displayType)
        ),
        catchError(error => of(updateDisplayTypeInRoleUsersFailure(strings.somethingWrongHappened)))
    )
}

