import {
    GET_TICKET_TYPES_IN_RESERVATIONS,
    GET_TICKET_TYPES_IN_RESERVATIONS_SUCCESS,
    GET_TICKET_TYPES_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getTicketTypesInReservationsActions';

export function getTicketTypesInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_TYPES_IN_RESERVATIONS:
            return {
                ...state,
                isGetTicketTypesInReservations: true,
                getTicketTypesInReservationsError: null,
            };
        case GET_TICKET_TYPES_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetTicketTypesInReservations: false,
                getTicketTypesInReservationsError: null,
                ticketTypesInReservations: action.payload.ticketTypes,
                //reservationTypeInReservations: action.payload.ticketTypes[0],
            };
        case GET_TICKET_TYPES_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetTicketTypesInReservations: false,
                getTicketTypesInReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}