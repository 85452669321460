export const UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS = 'UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS';
export const UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_FAILURE';

export const updateDisplayTypeInAttendedReservations = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS,
    displayType
});

export const updateDisplayTypeInAttendedReservationsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInAttendedReservationsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_FAILURE,
    payload: message
});