export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';

export const editCompany = (companyData) =>({
    type: EDIT_COMPANY,
    companyData
});

export const editCompanySuccess = (data) => ({
    type: EDIT_COMPANY_SUCCESS,
    payload: { data }
});

export const editCompanyFailure = (message) => ({
    type: EDIT_COMPANY_FAILURE,
    payload: { message }
});