import {
    GET_RESERVATION_DETAILS,
    GET_RESERVATION_DETAILS_SUCCESS,
    GET_RESERVATION_DETAILS_FAILURE
} from '../../actions/Reservations/getReservationDetailsActions';

export function getReservationDetailsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_DETAILS:
            return {
                ...state,
                isGetReservationDetails: true,
                getReservationDetailsError: null,
            };
        case GET_RESERVATION_DETAILS_SUCCESS:
            return {
                ...state,
                isGetReservationDetails: false,
                reservationDetailsInReservations: action.payload.reservationViewModel,
                getReservationDetailsError: null
            };
        case GET_RESERVATION_DETAILS_FAILURE:
            return {
                ...state,
                isGetReservationDetails: false,
                isAvailableReservationInReservation:false,
                getReservationDetailsError: action.payload
            };
        default:
            return state;
    }
}