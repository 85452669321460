import React, { useEffect, useState } from "react";
import Button from "@restart/ui/esm/Button";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInNotificationSettingsConstant } from "../../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant";
import strings from "../../localizations/strings";
import Loading from "../Shared/Loading/Loading";

function Edit(props) {

  const [notificationTypeError, setNotificationTypeError] = useState(null);
  const [notificationRoleError, setNotificationRoleError] = useState(null);

  useEffect(() => {
    props.getNotificationTypesInNotificationSettings(props.editedNotificationSettingInNotificationSettings.id);
  }, []);

  function handleNotificationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setNotificationTypeError(strings.pleaseSelectNotificationType)
    } else {
      setNotificationTypeError(null)
    }
    props.updateNotificationTypesInNotificationSettings(selectedOption);
  }

  function handleNotificationRoleChanges(selectedOption) {
    if (selectedOption == null) {
      setNotificationRoleError(strings.pleaseSelectRole)
    } else {
      setNotificationRoleError(null)
    }
    props.updateNotificationRolesInNotificationSettings(selectedOption);
  }

  useEffect(() => {
    if (props.editNotificationSettingResult !== null && props.editNotificationSettingResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.editNotificationSettingResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInNotificationSettings(DisplayTypesInNotificationSettingsConstant.LIST)
      }
      )
    }
  }, [props.editNotificationSettingResult])

  useEffect(() => {
    if (props.editNotificationSettingError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editNotificationSettingError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.editNotificationSettingError])

  return (
    <>
      {props.isEditNotificationSetting && (<Loading />)}
      {!props.isEditNotificationSetting &&
        (<div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.editNotificationSetting}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                initialValues={{
                  textEn: props.editedNotificationSettingInNotificationSettings !== null ? props.editedNotificationSettingInNotificationSettings.textEn : "",
                  textAr: props.editedNotificationSettingInNotificationSettings !== null ? props.editedNotificationSettingInNotificationSettings.textAr : "",
                  titleEn: props.editedNotificationSettingInNotificationSettings !== null ? props.editedNotificationSettingInNotificationSettings.titleEn : "",
                  titleAr: props.editedNotificationSettingInNotificationSettings !== null ? props.editedNotificationSettingInNotificationSettings.titleAr : "",
                }}
                validationSchema={Yup.object().shape({
                  textEn: Yup.string().required(
                    strings.textEnRequired
                  ),
                  textAr: Yup.string().required(
                    strings.textArRequired
                  ),
                  titleEn: Yup.string().required(
                    strings.titleEnRequired
                  ),
                  titleAr: Yup.string().required(
                    strings.titleArRequired
                  ),
                })}
                onSubmit={(fields) => {
                  let isValid = true;

                  if (props.notificationTypeInNotificationSettings === null) {
                    setNotificationTypeError(strings.pleaseSelectNotificationType);
                    isValid = false;
                  }

                  if (props.notificationRoleInNotificationSettings === []) {
                    setNotificationRoleError(strings.pleaseSelectRole);
                    isValid = false;
                  }

                  if (isValid) {
                    const notificationSetting = {
                      id:props.editedNotificationSettingInNotificationSettings.id,
                      notificationTypeId: props.notificationTypeInNotificationSettings.value,
                      roleIds: props.notificationRoleInNotificationSettings.map(x=> x.value),
                      textEn: fields.textEn,
                      textAr:fields.textAr,
                      titleEn:fields.titleEn,
                      titleAr:fields.titleAr,
                    };

                    props.editNotificationSetting(notificationSetting);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.notificationTypeName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="notificationType"
                            options={props.notificationTypesInNotificationSettings}
                            placeholder={strings.selectNotificationType}
                            value={props.notificationTypeInNotificationSettings}
                            onChange={handleNotificationTypeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetNotificationTypesInNotificationSettings}
                            className={notificationTypeError != null ? "is-invalid" : ""}
                          />
                          {notificationTypeError && (
                            <div className="invalid-feedback block">
                              {notificationTypeError}
                            </div>
                          )}
                        </div>

                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.roleName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="notificationRole"
                            options={props.notificationRolesInNotificationSettings}
                            placeholder={strings.selectRole}
                            value={props.notificationRoleInNotificationSettings}
                            onChange={handleNotificationRoleChanges}
                            isMulti
                            isSearchable
                            isClearable
                            isLoading={props.isGetNotificationRolesInNotificationSettings}
                            className={notificationRoleError != null ? "is-invalid" : ""}
                          />
                          {notificationRoleError && (
                            <div className="invalid-feedback block">
                              {notificationRoleError}
                            </div>
                          )}
                        </div>

                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.titleEn}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="titleEn"
                            placeholder={`${strings.enterTitleEn} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.titleEn &&
                                touched.titleEn
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage name="titleEn" component="div" className="invalid-feedback" />


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.titleAr}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="titleAr"
                            placeholder={`${strings.enterTitleAr} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.titleAr &&
                                touched.titleAr
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage name="titleAr" component="div" className="invalid-feedback" />


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.textEn}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="textEn"
                            placeholder={`${strings.enterTextEn} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.textEn &&
                                touched.textEn
                                ? " is-invalid"
                                : "")
                            }
                            component="textarea"
                            rows={3}
                          />
                          <ErrorMessage name="textEn" component="div" className="invalid-feedback" />


                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.textAr}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="textAr"
                            placeholder={`${strings.enterTextAr} *`}
                            className={
                              "form-control form-control1 form-control-lg form-control-solid" +
                              (errors.textAr &&
                                touched.textAr
                                ? " is-invalid"
                                : "")
                            }
                            component="textarea"
                            rows={3}
                          />
                          <ErrorMessage name="textAr" component="div" className="invalid-feedback" />


                        </div>
                      </div>

                      <div className="card-footer d-flex justify-content-between p-3">
                        <div>
                          <button
                            type="submit"
                            className="btn fs-6"
                            style={{
                              backgroundColor: "#009ef7",
                              borderColor: "#009ef7",
                              color: "white",
                              borderRadius: "0.75rem",
                            }}
                            id="kt_account_profile_details_submit"
                          //	onClick={done}
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        )
      }
    </>
  );
}
export default Edit;
