export const UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS = 'UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS';
export const UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS = 'UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS';
export const UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE = 'UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE';

export const updateReservationTypesInReservationTypeSettings = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS,
    reservationType
}); 

export const updateReservationTypesInReservationTypeSettingsSuccess = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    payload: { reservationType }
});

export const updateReservationTypesInReservationTypeSettingsFailure = (message) => ({
    type: UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE,
    payload: { message }
});