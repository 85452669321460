export const GET_USER_SIDE_BAR = 'GET_USER_SIDE_BAR';
export const GET_USER_SIDE_BAR_SUCCESS = 'GET_USER_SIDE_BAR_SUCCESS';
export const GET_USER_SIDE_BAR_FAILURE = 'GET_USER_SIDE_BAR_FAILURE';

export const getUserSideBar = () => ({
    type: GET_USER_SIDE_BAR,
});

export const getUserSideBarSuccess = (data) => ({
    type: GET_USER_SIDE_BAR_SUCCESS,
    payload: { data }
});

export const getUserSideBarFailure = (message) => ({
    type: GET_USER_SIDE_BAR_FAILURE,
    payload: { message }
});