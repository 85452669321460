import {
    GET_COMPANIES_IN_COMPANIES,
    GET_COMPANIES_IN_COMPANIES_SUCCESS,
    GET_COMPANIES_IN_COMPANIES_FAILURE
} from '../../actions/Companies/getCompaniesInCompaniesActions';

export function getCompaniesInCompaniesReducer(state, action) {
    switch (action.type) {
        case GET_COMPANIES_IN_COMPANIES:
            return {
                ...state,
                isGetCompaniesInCompanies: true,
                getCompaniesInCompaniesError: null,
            };
        case GET_COMPANIES_IN_COMPANIES_SUCCESS:
            console.log(action.payload.companies)
            return {
                ...state,
                isGetCompaniesInCompanies: false,
                getCompaniesInCompaniesError: null,
                companiesInCompanies: action.payload.companies.data,
                companiesPageNumberInCompanies: action.payload.pageNumber,
                companiesPageSizeInCompanies: action.payload.pageSize,
                companiesTotalCountInCompanies: action.payload.companies.total,
            };
        case GET_COMPANIES_IN_COMPANIES_FAILURE:
            return {
                ...state,
                isGetCompaniesInCompanies: false,
                getCompaniesInCompaniesError: action.payload.message,
            };
        default:
            return state;
}}