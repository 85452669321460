import {
    UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS,
    UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateSelectedDateInCompanyReservationsActions';

export function updateSelectedDateInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdatingSelectedDateInCompanyReservations: true,
                updateSelectedDateInCompanyReservationsError: null,
            };
        case UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingSelectedDateInCompanyReservations: false,
                selectedDateInCompanyReservations: action.payload.selectedDate,
                updateSelectedDateInCompanyReservationsError: null

            };
        case UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingSelectedDateInCompanyReservations: false,
                updateSelectedDateInCompanyReservationsError: action.payload
            };
        default:
            return state;
    }
}