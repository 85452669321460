export const UPDATE_DISPLAY_TYPE_IN_REQUESTS = 'UPDATE_DISPLAY_TYPE_IN_REQUESTS';
export const UPDATE_DISPLAY_TYPE_IN_REQUESTS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_REQUESTS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_REQUESTS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_REQUESTS_FAILURE';

export const updateDisplayTypeInRequests = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_REQUESTS,
    displayType 
});

export const updateDisplayTypeInRequestsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_REQUESTS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInRequestsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_REQUESTS_FAILURE,
    payload: message
});