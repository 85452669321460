export const GET_ROLE_SCREENS = 'GET_ROLE_SCREENS';
export const GET_ROLE_SCREENS_SUCCESS = 'GET_ROLE_SCREENS_SUCCESS';
export const GET_ROLE_SCREENS_FAILURE = 'GET_ROLE_SCREENS_FAILURE';

export const getRoleScreens = (roleId,pageNumber=1,pageSize=25,search=null) => ({
    type: GET_ROLE_SCREENS,
    roleId,
    pageNumber,
    pageSize,
    search
});

export const getRoleScreensSuccess = (roleScreens,pageNumber,pageSize) => ({
    type: GET_ROLE_SCREENS_SUCCESS,
    payload: { roleScreens ,pageNumber,pageSize }
});

export const getRoleScreensFailure = (message) => ({
    type: GET_ROLE_SCREENS_FAILURE,
    payload: { message }
});