import {
    GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS,
    GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_FAILURE
} from '../../actions/ReservationTypeSettings/getReservationTypeSettingsInReservationTypeSettingsActions';

export function getReservationTypeSettingsInReservationTypeSettingsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS:
            return {
                ...state,
                isGetReservationTypeSettingsInReservationTypeSettings: true,
                getReservationTypeSettingsInReservationTypeSettingsError: null,
            };
        case GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_SUCCESS:
            console.log(action.payload.reservationTypeSettings)
            return {
                ...state,
                isGetReservationTypeSettingsInReservationTypeSettings: false,
                getReservationTypeSettingsInReservationTypeSettingsError: null,
                reservationTypeSettingsInReservationTypeSettings: action.payload.reservationTypeSettings.data,
                 reservationTypeSettingsPageNumberInReservationTypeSettings: action.payload.pageNumber,
                reservationTypeSettingsPageSizeInReservationTypeSettings: action.payload.pageSize,
                reservationTypeSettingsTotalCountInReservationTypeSettings: action.payload.reservationTypeSettings.total,
            };
        case GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_FAILURE:
            return {
                ...state,
                isGetReservationTypeSettingsInReservationTypeSettings: false,
                getReservationTypeSettingsInReservationTypeSettingsError: action.payload.message,
            };
        default:
            return state;
}}