import React, { useState, useEffect } from "react";
import strings from '../../localizations/strings';
import { DisplayTypesInUsersConstant } from "../../constants/Users/DisplayTypesInUsersConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading';
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';

function User(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getUsersInUsers(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getUsersInUsers();
  }, [])

  useEffect(() => {
    if (
      props.deleteUserResult !== null &&
      props.deleteUserResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deleted}`, props.deleteUserResult.message, "success").then(result => {
        props.getUsersInUsers(props.usersPageNumberInUsers, props.usersPageSizeInUsers,search);
      });
    }
  }, [props.deleteUserResult]);

  useEffect(() => {
    if (
      props.deleteUserError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteUserError, "error");
    }
  }, [props.deleteUserError]);

  useEffect(() => {
    if (props.updateStatusUserResult !== null && props.updateStatusUserResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusUserResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusUserResult]);

  useEffect(() => {
    if (props.updateStatusUserError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusUserError, "error");
    }
  }, [props.updateStatusUserError]);

  useEffect(() => {
    if (props.mobileVerifiedUserResult !== null && props.mobileVerifiedUserResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.mobileVerifiedUserResult.message,
        "success"
      ).then(
      );
    }
  }, [props.mobileVerifiedUserResult]);

  useEffect(() => {
    if (props.mobileVerifiedUserError !== null) {
      Swal.fire(`! ${strings.error}`, props.mobileVerifiedUserError, "error");
    }
  }, [props.mobileVerifiedUserError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.usersPageNumberInUsers) {
      props.getUsersInUsers(pageNumber, props.usersPageSizeInUsers,search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getUsersInUsers(1, props.usersPageSizeInUsers, selected.target.value)
  }

  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetUsersInUsers && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.usersTotalCountInUsers}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.usersPageSizeInUsers)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.usersTotalCountInUsers}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.userName}</th>
                  <th className="min-w-125px px-0">{strings.mobile}</th>
                  <th className="min-w-125px px-0">{strings.email}</th>
                  <th className="min-w-125px px-0">{strings.createdDate}</th>
                  <th className="min-w-125px px-0">{strings.mobileVerified}</th>
                  <th className="min-w-125px px-0">{strings.status}</th>
                  <th className='text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn'>{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {
                  props.usersInUsers !== []
                  && props.usersInUsers.length > 0
                  && props.usersInUsers.map((user, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.AR ? user.userNameAr : user.userNameEn}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{user.phoneNumber}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{user.email}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{user.insertedDate}</h4>
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>
                        <div className="form-check form-switch">
                          <input className="form-check-input"
                            style={{ float: "unset", width: "50px", height: "27px" }}
                            type="checkbox"
                            id={`flexSwitchCheckChecked${index}`}
                            onChange={e =>
                              props.mobileVerifiedUser(user.id)}
                            checked={user.isMobileVerified}
                          />
                        </div>
                      </td>
                      <td className='text-center'>
                        <div className="form-check form-switch">
                          <input className="form-check-input"
                            style={{ float: "unset", width: "50px", height: "27px" }}
                            type="checkbox"
                            id={`flexSwitchCheckChecked${index}`}
                            onChange={e =>
                              props.updateStatusUser(user.id)}
                            checked={user.isActive}
                          />
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action1">
                              <a onClick={() => {
                                props.getEditedUser(user.id);
                                props.updateDisplayTypeInUsers(
                                  DisplayTypesInUsersConstant.EDIT
                                )
                              }}>

                                <i className="fas fa-edit edit"></i>
                              </a>

                            </div>
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, user.id) }}>
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {
              props.usersTotalCountInUsers !== null
              && props.usersTotalCountInUsers > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.usersPageNumberInUsers - 1) * props.usersPageSizeInUsers) + 1} {strings.to} {(props.usersPageNumberInUsers * props.usersPageSizeInUsers) > props.usersTotalCountInUsers ? props.usersTotalCountInUsers : (props.usersPageNumberInUsers * props.usersPageSizeInUsers)} {strings.of} {props.usersTotalCountInUsers} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.usersPageNumberInUsers}
                    itemsCountPerPage={props.usersPageSizeInUsers}
                    totalItemsCount={props.usersTotalCountInUsers}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default User;