export const UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS = 'UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS';
export const UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_FAILURE';

export const updateDisplayTypeInNotificationSettings = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS,
    displayType
});

}
export const updateDisplayTypeInNotificationSettingsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInNotificationSettingsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: message
});