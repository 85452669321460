export const CHANGE_NOTIFICATION_IS_READ = 'CHANGE_NOTIFICATION_IS_READ';
export const CHANGE_NOTIFICATION_IS_READ_SUCCESS = 'CHANGE_NOTIFICATION_IS_READ_SUCCESS';
export const CHANGE_NOTIFICATION_IS_READ_FAILURE = 'CHANGE_NOTIFICATION_IS_READ_FAILURE';

export const changeNotificationIsRead = (id) => ({
    type: CHANGE_NOTIFICATION_IS_READ,
    id
});

export const changeNotificationIsReadSuccess = (data) => ({
    type: CHANGE_NOTIFICATION_IS_READ_SUCCESS,
    payload: { data }
});

export const changeNotificationIsReadFailure = (message) => ({
    type: CHANGE_NOTIFICATION_IS_READ_FAILURE,
    payload: { message }
});