export const DELETE_SCHOOL_TOUR_RANGE = 'DELETE_SCHOOL_TOUR_RANGE';
export const DELETE_SCHOOL_TOUR_RANGE_SUCCESS = 'DELETE_SCHOOL_TOUR_RANGE_SUCCESS';
export const DELETE_SCHOOL_TOUR_RANGE_FAILURE = 'DELETE_SCHOOL_TOUR_RANGE_FAILURE';

export const deleteSchoolTourRange = (schoolTourRangeId) => ({
    type: DELETE_SCHOOL_TOUR_RANGE,
    schoolTourRangeId
});

export const deleteSchoolTourRangeSuccess = (data) => ({
    type: DELETE_SCHOOL_TOUR_RANGE_SUCCESS,
    payload: { data }
});

export const deleteSchoolTourRangeFailure = (message) => ({
    type: DELETE_SCHOOL_TOUR_RANGE_FAILURE,
    payload: { message }
});