import {
    CHECK_EMAIL_AVAILABILITY_IN_USERS,
    CHECK_EMAIL_AVAILABILITY_IN_USERS_SUCCESS,
    CHECK_EMAIL_AVAILABILITY_IN_USERS_FAILURE
} from '../../actions/Users/checkEmailAvailabilityInUsersActions';

export function checkEmailAvailabilityInUsersReducer(state, action) {
    switch (action.type) {
        case CHECK_EMAIL_AVAILABILITY_IN_USERS:
            return {
                ...state,
                isCheckingEmailAvailabilityInUsers: true,
                checkEmailAvailabilityInUsersError: null,
            };
        case CHECK_EMAIL_AVAILABILITY_IN_USERS_SUCCESS:
            return {
                ...state,
                isCheckingEmailAvailabilityInUsers: false,
                isEmailAvailableInUsers: action.payload.isAvailable,
                checkEmailAvailabilityInUsersError: null

            };
        case CHECK_EMAIL_AVAILABILITY_IN_USERS_FAILURE:
            return {
                ...state,
                isCheckingEmailAvailabilityInUsers: false,
                checkEmailAvailabilityInUsersError: action.payload
            };
        default:
            return state;
    }
}