export const DELETE_PAUSED_TIME = 'DELETE_PAUSED_TIME';
export const DELETE_PAUSED_TIME_SUCCESS = 'DELETE_PAUSED_TIME_SUCCESS';
export const DELETE_PAUSED_TIME_FAILURE = 'DELETE_PAUSED_TIME_FAILURE';

export const deletePausedTime = (pausedTimeId) => ({
    type: DELETE_PAUSED_TIME,
    pausedTimeId
});

export const deletePausedTimeSuccess = (data) => ({
    type: DELETE_PAUSED_TIME_SUCCESS,
    payload: { data }
});

export const deletePausedTimeFailure = (message) => ({
    type: DELETE_PAUSED_TIME_FAILURE,
    payload: { message }
});