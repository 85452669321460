import {
    ADD_NEW_DISCOUNT,
    ADD_NEW_DISCOUNT_SUCCESS,
    ADD_NEW_DISCOUNT_FAILURE
} from '../../actions/Discounts/addNewDiscountActions';

import { DisplayTypesInDiscountsConstant } from '../../constants/Discounts/DisplayTypesInDiscountsConstant';

export function addNewDiscountReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_DISCOUNT:
            return {
                ...state,
                isAddNewDiscount: true,
                addNewDiscountError: null, 
            };
        case ADD_NEW_DISCOUNT_SUCCESS:
            return {
                ...state,
                isAddNewDiscount: false,
                addNewDiscountError: null,
                addNewDiscountResult: action.payload.data,
              //  displayTypeInDiscounts: DisplayTypesInDiscountsConstant.LIST,
            };
        case ADD_NEW_DISCOUNT_FAILURE:
            return {
                ...state,
                isAddNewDiscount: false,
                addNewDiscountError: action.payload.message,
            };
        default:
            return state;
}}