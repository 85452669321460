import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import strings from '../../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Modal, Button, Container, Row, Col, InputGroup, FormControl, Pagination } from "react-bootstrap";
import * as Yup from 'yup';
import { DisplayTypesInRoleUsersConstant } from '../../../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant';

function Add(props) {

    useEffect(() => {
        if (props.createNewRoleUserResult !== null && props.createNewRoleUserResult.isSuccess) {
            Swal.fire(
                `! ${strings.successfullyAdded}`,
                props.createNewRoleUserResult.message,
                'success'
            ).then(result => {
                props.updateDisplayTypeInRoleUsers(DisplayTypesInRoleUsersConstant.LIST)
            }
            )
        }
    }, [props.createNewRoleUserResult])

    useEffect(() => {
        if (props.createNewRoleUserError !== null) {
            Swal.fire(
                `! ${strings.error}`,
                props.createNewRoleUserError,
                'error'
            ).then(result => {

            }
            )
        }
    }, [props.createNewRoleUserError])

    function checkEmailAvailabilityInRoleUsers(value) {
        if (value !== undefined && value !== "") {
            props.checkEmailAvailabilityInRoleUsers(value);
        }
    }

    return (
        <div className="customer-data cus2">
        <div className="card rounded-0 animate__animated  animate__backInLeft">
        <div className='card-header border-0 d-flex align-items-center mh-47'>
              <h3 className='card-title col-9'>
              <span className="card-label fw-bolder text-dark">
                {strings.addUser}</span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        arabicName: '',
                        englishName: '',
                        email: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        arabicName: Yup.string()
                            .required(strings.nameArRequired),
                        englishName: Yup.string()
                            .required(strings.nameEnRequired),
                        email: Yup.string()
                            .required(strings.emailRequired)
                            .email(strings.emailIsInvalid),
                        password: Yup.string()
                            .required(strings.passwordRequired)
                    })}
                    onSubmit={fields => {
                        if (props.isEmailAvailableInRoleUsers) {
                            var roleUser = {
                                roleId: props.roleInRoles.id,
                                nameEn: fields.englishName,
                                nameAr: fields.arabicName,
                                email: fields.email,
                                password: fields.password,
                                isActive: true,
                            }

                            props.createNewRoleUser(roleUser);
                        }
                    }}
                    render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue, setFieldTouched }) => (
                        <Form id="kt_account_profile_details_form" className="form1">
                            <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label className="col-lg-6 col-form-label required">
                                        {strings.arabicName}
                                    </label>
                                    <div className="col-lg-10 col-sm-12 fv-row">
                                        <Field
                                            type="text"
                                            name="arabicName"
                                            className={"form-control form-control1 form-control-lg form-control-solid" + (errors.arabicName && touched.arabicName ? ' is-invalid' : '')}
                                            placeholder={`${strings.enterNameAr} *`}
                                        />
                                        <ErrorMessage name="arabicName" component="div"
                                            className="invalid-feedback" />

                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label className="col-lg-6 col-form-label required">
                                        {strings.englishName}
                                    </label>
                                    <div className="col-lg-10 col-sm-12 fv-row">
                                        <Field
                                            type="text"
                                            name="englishName"
                                            className={"form-control form-control1 form-control-lg form-control-solid" + (errors.englishName && touched.englishName ? ' is-invalid' : '')}
                                            placeholder={`${strings.enterNameEn} *`}
                                        />
                                        <ErrorMessage name="englishName" component="div"
                                            className="invalid-feedback" />

                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label className="col-lg-6 col-form-label required">
                                        {strings.email}
                                    </label>
                                    <div className="col-lg-10 col-sm-12 fv-row">
                                        <Field
                                            type="text"
                                            name="email"
                                            className={"form-control form-control1 form-control-lg form-control-solid" + (errors.email && touched.email ? ' is-invalid' : '')}
                                            placeholder={`${strings.enterEmail} *`}
                                            onChange={e => {
                                                handleChange(e);
                                                setFieldTouched('email');
                                            }}
                                            onBlur={e => {
                                                handleBlur(e);
                                                checkEmailAvailabilityInRoleUsers(e.target.value);
                                            }}
                                        />
                                        {
                                            errors.email && touched.email
                                            && <ErrorMessage name="email" component="div"
                                                className="invalid-feedback" />
                                        }
                                        {
                                            !errors.email
                                            && !props.isEmailAvailableInRoleUsers
                                            && (
                                                <div className="invalid-feedback d-inline-block">
                                                    {strings.emailEnteredBefore}
                                                </div>
                                            )
                                        }


                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label className="col-lg-6 col-form-label required">
                                        {strings.password}
                                    </label>
                                    <div className="col-lg-10 col-sm-12 fv-row">
                                        <Field
                                            type="password"
                                            name="password"
                                            className={"form-control form-control1 form-control-lg form-control-solid" + (errors.password && touched.password ? ' is-invalid' : '')}
                                            placeholder={`${strings.enterPassword} *`}
                                        />
                                        <ErrorMessage name="password" component="div"
                                            className="invalid-feedback" />

                                    </div>
                                </div>
                            </div>
            <div className="card-footer d-flex justify-content-between p-3">
                <Button type="submit" className="btn fs-6"
                    style={{backgroundColor: "#009ef7",borderColor: "#009ef7",color: "white",
                        borderRadius: "0.75rem",
                    }}
                    id="kt_account_profile_details_submit"
                //	onClick={done}
                >
                    {strings.save}
                </Button>
                <Button type="button"className="btn fs-6"style={{ borderRadius: "0.75rem" }}onClick={()=> props.updateDisplayTypeInRoleUsers(DisplayTypesInRoleUsersConstant.LIST)}>{strings.cancel}</Button>
            </div>
                        </Form>

                    )}
                />
              </div>
            </div>
        </div>


    );

}
export default Add
