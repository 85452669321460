export const GET_USERS_IN_RESERVATIONS = 'GET_USERS_IN_RESERVATIONS';
export const GET_USERS_IN_RESERVATIONS_SUCCESS = 'GET_USERS_IN_RESERVATIONS_SUCCESS';
export const GET_USERS_IN_RESERVATIONS_FAILURE = 'GET_USERS_IN_RESERVATIONS_FAILURE';

export const getUsersInReservations = () => ({
    type: GET_USERS_IN_RESERVATIONS,
}); 

export const getUsersInReservationsSuccess = (users) => ({
    type: GET_USERS_IN_RESERVATIONS_SUCCESS,
    payload: { users }
});

export const getUsersInReservationsFailure = (message) => ({
    type: GET_USERS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});