export const UPDATE_SELECTED_DATE_IN_RESERVATIONS = 'UPDATE_SELECTED_DATE_IN_RESERVATIONS';
export const UPDATE_SELECTED_DATE_IN_RESERVATIONS_SUCCESS = 'UPDATE_SELECTED_DATE_IN_RESERVATIONS_SUCCESS';
export const UPDATE_SELECTED_DATE_IN_RESERVATIONS_FAILURE = 'UPDATE_SELECTED_DATE_IN_RESERVATIONS_FAILURE';

export const updateSelectedDateInReservations = (reservationTypeSettingId,selectedDate) => ({
    type: UPDATE_SELECTED_DATE_IN_RESERVATIONS,
    reservationTypeSettingId,
    selectedDate
});

export const updateSelectedDateInReservationsSuccess = (selectedDate) => ({
    type: UPDATE_SELECTED_DATE_IN_RESERVATIONS_SUCCESS,
    payload: { selectedDate }
});

export const updateSelectedDateInReservationsFailure = (message) => ({
    type: UPDATE_SELECTED_DATE_IN_RESERVATIONS_FAILURE,
    payload: message
});