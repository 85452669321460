import {
    EDIT_DISCOUNT,
    EDIT_DISCOUNT_SUCCESS,
    EDIT_DISCOUNT_FAILURE
} from '../../actions/Discounts/editDiscountActions';

import { DisplayTypesInDiscountsConstant } from '../../constants/Discounts/DisplayTypesInDiscountsConstant';

export function editDiscountReducer(state, action) {
    switch (action.type) {
        case EDIT_DISCOUNT:
            return {
                ...state,
                isEditingDiscount: true,
                editDiscountError: null,
            };
        case EDIT_DISCOUNT_SUCCESS:
            return {
                ...state,
                isEditingDiscount: false,
                editDiscountError: null,
                editDiscountResult: action.payload.data,
               // displayTypeInDiscounts: DisplayTypesInDiscountsConstant.LIST,
            };
        case EDIT_DISCOUNT_FAILURE:
            return {
                ...state,
                isEditingDiscount: false,
                editDiscountError: action.payload.message,
            };
        default:
            return state;
}}