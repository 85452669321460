import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap,map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    DELETE_CANCELLED_RESERVATION,
    deleteCancelledReservationSuccess,
    deleteCancelledReservationFailure
} from '../../actions/Reservations/deleteCancelledReservationActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function deleteCancelledReservationEpic(action$) {
    return action$.pipe(
        ofType(DELETE_CANCELLED_RESERVATION),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `Reservation/DeleteCancelledReservation?cancelledReservationId=${action.cancelledReservationId}`,
                method: 'POST',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                return of(deleteCancelledReservationSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(deleteCancelledReservationFailure(message));
            }
        }),
        catchError(error =>  of(deleteCancelledReservationFailure(strings.somethingWrongHappened)))
    )
}

