export const GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS = 'GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS';
export const GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_FAILURE';

export const getTicketTypesInCompanyReservations = () => ({
    type: GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS,
}); 

export const getTicketTypesInCompanyReservationsSuccess = (ticketTypes) => ({
    type: GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { ticketTypes }
});

export const getTicketTypesInCompanyReservationsFailure = (message) => ({
    type: GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: { message }
});