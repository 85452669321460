export const GET_SMS_SETTINGS_IN_SMS_SETTINGS = 'GET_SMS_SETTINGS_IN_SMS_SETTINGS';
export const GET_SMS_SETTINGS_IN_SMS_SETTINGS_SUCCESS = 'GET_SMS_SETTINGS_IN_SMS_SETTINGS_SUCCESS';
export const GET_SMS_SETTINGS_IN_SMS_SETTINGS_FAILURE = 'GET_SMS_SETTINGS_IN_SMS_SETTINGS_FAILURE';

export const getSmsSettingsInSmsSettings = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_SMS_SETTINGS_IN_SMS_SETTINGS,
    pageNumber,
    pageSize,
    search
});

export const getSmsSettingsInSmsSettingsSuccess = (smsSettings, pageNumber, pageSize) => ({
    type: GET_SMS_SETTINGS_IN_SMS_SETTINGS_SUCCESS,
    payload: { smsSettings, pageNumber, pageSize }
});

export const getSmsSettingsInSmsSettingsFailure = (message) => ({
    type: GET_SMS_SETTINGS_IN_SMS_SETTINGS_FAILURE,
    payload: { message }
});