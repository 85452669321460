export const CLEAR_RESERVATION_DETAILS = 'CLEAR_RESERVATION_DETAILS';
export const CLEAR_RESERVATION_DETAILS_SUCCESS = 'CLEAR_RESERVATION_DETAILS_SUCCESS';
export const CLEAR_RESERVATION_DETAILS_FAILURE = 'CLEAR_RESERVATION_DETAILS_FAILURE';

export const clearReservationDetails = () => ({
    type: CLEAR_RESERVATION_DETAILS,
});

export const clearReservationDetailsSuccess = () => ({
    type: CLEAR_RESERVATION_DETAILS_SUCCESS,
});

export const clearReservationDetailsFailure = (message) => ({
    type: CLEAR_RESERVATION_DETAILS_FAILURE,
    payload: message
});