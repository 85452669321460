import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    ADD_NEW_TICKET_TYPE,
    addNewTicketTypeSuccess,
    addNewTicketTypeFailure
} from '../../actions/TicketTypes/addNewTicketTypeActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function addNewTicketTypeEpic(action$) {
    return action$.pipe(
        ofType(ADD_NEW_TICKET_TYPE),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `TicketType/Add`,
                method: 'Post',
                headers: {
                   'Authorization':token ,
                'content-Type': 'application/json'
                },
                body:action.ticketTypeData,
                addXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };
            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
            console.log(response)
            if (response.isSuccess) {
                return of(addNewTicketTypeSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(addNewTicketTypeFailure(message));
            }
        }),
        catchError(error =>  of(addNewTicketTypeFailure(strings.somethingWrongHappened)))
    )
}

