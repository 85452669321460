import {
    UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES,
    UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_FAILURE
} from '../../../src/actions/SchoolTourRanges/updateDisplayTypeInSchoolTourRangesActions';

export function updateDisplayTypeInSchoolTourRangesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES:
            return {
                ...state,
                isUpdatingDisplayTypeInSchoolTourRanges: true,
                updateDisplayTypeErrorInSchoolTourRanges: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInSchoolTourRanges: false,
                displayTypeInSchoolTourRanges: action.payload.displayType,
                updateDisplayTypeErrorInSchoolTourRanges: null,

                isEditingSchoolTourRange: false,
                editSchoolTourRangeError: null,
                editSchoolTourRangeResult:null,

                isDeletingSchoolTourRange: false,
                deleteSchoolTourRangeError: null,
                deleteSchoolTourRangeResult:null,

                isAddNewSchoolTourRange: false,
                addNewSchoolTourRangeError: null,
                addNewSchoolTourRangeResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInSchoolTourRanges: false,
                updateDisplayTypeErrorInSchoolTourRanges: action.payload
            };
        default:
            return state;
    }
}