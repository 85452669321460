import {
    GET_RESERVATION_TYPES_IN_RESERVATIONS,
    GET_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS,
    GET_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getReservationTypesInReservationsActions';

export function getReservationTypesInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_RESERVATIONS:
            return {
                ...state,
                isGetReservationTypesInReservations: true,
                getReservationTypesInReservationsError: null,
            };
        case GET_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetReservationTypesInReservations: false,
                getReservationTypesInReservationsError: null,
                reservationTypesInReservations: action.payload.reservationTypes,
                //reservationTypeInReservations: action.payload.reservationTypes[0],
            };
        case GET_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetReservationTypesInReservations: false,
                getReservationTypesInReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}