import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import strings from "../../localizations/strings";
import { LanguagesConstant } from "../../constants/LanguagesConstant";

function ChangePassword(props) {

  useEffect(() => {
    if (props.changedPasswordResult !== null && props.changedPasswordResult.isSuccess) {
      Swal.fire(
        `! ${strings.changed}`,
        props.changedPasswordResult.message,
        'success'
      ).then(result => {
        //window.location.href = `/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/login`;
      }
      )
    }
  }, [props.changedPasswordResult])

  useEffect(() => {
    if (props.getChangedPasswordError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.getChangedPasswordError,
        'error'
      ).then(result => {

      }
      )
    }
  }, [props.getChangedPasswordError])

  return (
    <>
      <div className="customer-data grid2">
        {
          console.log(props.changedPasswordResult)
        }
        <div className="card animate__animated  animate__backInLeft">
          <div className='card-header border-0'>
            <h3 className='card-title d-flex'>
              <span className='card-label fw-bolder fs-3 text-dark col-10'>{strings.changePassword}</span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string().required(
                  strings.currentPasswordIsRequired
                ),
                newPassword: Yup.string().required(
                  strings.newPasswordRequired
                ),
                confirmPassword: Yup.string().required(
                  strings.confirmPasswordRequired
                ).when("newPassword", {
                  is: val => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("newPassword")], strings.passwordNotMatch)
                }),

              })}
              onSubmit={(fields) => {
                const passwordViewModel = {
                  oldPassword: fields.currentPassword,
                  newPassword: fields.newPassword,
                };
                props.changePassword(passwordViewModel);
              }}

              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body border-top" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="row mb-3">
                      <label className="col-lg-4 col-form-label font required">{strings.currentPassword}</label>
                      <div className="col-lg-8 fv-row">
                        <Field
                          type="password"
                          name="currentPassword"
                          placeholder={`${strings.enterNewPassword} *`}
                          className={'form-control form-control1 form-control-lg form-control-solid'
                            + (errors.currentPassword && touched.currentPassword
                              ?
                              ' is-invalid' : '')}
                        />

                        <ErrorMessage name="currentPassword" component="div" className="invalid-feedback" />

                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-lg-4 col-form-label font required">{strings.newPassword}</label>
                      <div className="col-lg-8 fv-row">
                        <Field
                          type="password"
                          name="newPassword"
                          placeholder={`${strings.enterNewPassword} *`}
                          className={'form-control form-control1 form-control-lg form-control-solid'
                            + (errors.newPassword && touched.newPassword
                              ?
                              ' is-invalid' : '')}
                        />

                        <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />

                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-lg-4 col-form-label font required">{strings.confirmPassword}</label>
                      <div className="col-lg-8 fv-row">
                        <Field
                          type="password"
                          name="confirmPassword"
                          placeholder={`${strings.enterConfirmPassword} *`}
                          className={'form-control form-control1 form-control-lg form-control-solid'
                            + (errors.confirmPassword && touched.confirmPassword ?
                              ' is-invalid' : '')}
                        />
                        <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />

                      </div>
                    </div>

                    <div className="card-footer d-flex justify-content-start" style={{ backgroundColor: "white" }}>
                      <div className="col-11">
                        <button type="submit" className="btn fs-5" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                        >
                          {strings.save}
                        </button>
                      </div>

                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>

      </div>
    </>
  );
}
export default ChangePassword;
