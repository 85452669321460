import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
	CHECK_IS_USER_LOGGED_IN,
	checkIsUserLoggedInSuccess,
    checkIsUserLoggedInFailure,
} from '../../actions/Account/checkIsUserLoggedInActions';

import {
	getUserSideBar
} from '../../actions/Account/getUserSideBarActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function checkIsUserLoggedInEpic(action$) {
	return action$.pipe(
        ofType(CHECK_IS_USER_LOGGED_IN),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `AccountApi/CheckAdminUserLoggedIn`,
                method: 'POST',
        		headers: {
                	'Authorization':token 
                },
        		createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
        	};

            return ajax(ajaxConfigObj).pipe(
				map(data => data.response));
				
        }),
        mergeMap(response => {
            console.log(response)
            if (response.isSuccess) {
                return of(checkIsUserLoggedInSuccess(response.data),getUserSideBar());
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(checkIsUserLoggedInFailure(message));
            }
        }),
        catchError(error => of(checkIsUserLoggedInFailure(strings.somethingWrongHappened))))
}

