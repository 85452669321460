export const GET_EDITED_DISCOUNT = 'GET_EDITED_DISCOUNT';
export const GET_EDITED_DISCOUNT_SUCCESS = 'GET_EDITED_DISCOUNT_SUCCESS';
export const GET_EDITED_DISCOUNT_FAILURE = 'GET_EDITED_DISCOUNT_FAILURE';

export const getEditedDiscount = (discountId) => ({
    type: GET_EDITED_DISCOUNT,
    discountId
});

export const getEditedDiscountSuccess = (discountId) => ({
    type: GET_EDITED_DISCOUNT_SUCCESS,
    payload: { discountId }
});

export const getEditedDiscountFailure = (message) => ({
    type: GET_EDITED_DISCOUNT_FAILURE,
    payload: { message }
});
