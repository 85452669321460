export const GET_GALLERIES_IN_GALLERIES = 'GET_GALLERIES_IN_GALLERIES';
export const GET_GALLERIES_IN_GALLERIES_SUCCESS = 'GET_GALLERIES_IN_GALLERIES_SUCCESS';
export const GET_GALLERIES_IN_GALLERIES_FAILURE = 'GET_GALLERIES_IN_GALLERIES_FAILURE';

export const getGalleriesInGalleries = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get galleries action')
return({
    type: GET_GALLERIES_IN_GALLERIES,
    pageNumber,
    pageSize,
    search
}); 
}
export const getGalleriesInGalleriesSuccess = (galleries,pageNumber,pageSize) => ({
    type: GET_GALLERIES_IN_GALLERIES_SUCCESS,
    payload: { galleries ,pageNumber,pageSize}
});

export const getGalleriesInGalleriesFailure = (message) => ({
    type: GET_GALLERIES_IN_GALLERIES_FAILURE,
    payload: { message }
});