import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_EDITED_COMPANY_RESERVATION,
    getEditedCompanyReservationSuccess,
    getEditedCompanyReservationFailure
} from '../../actions/CompanyReservations/getEditedCompanyReservationActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedCompanyReservationEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_COMPANY_RESERVATION),
        map(action => ({
            companyReservationId: action.companyReservationId
        })),
        map(response =>
            getEditedCompanyReservationSuccess(response.companyReservationId)

        ),
        catchError(error => of(getEditedCompanyReservationFailure(strings.somethingWrongHappened)))
    )
}

