import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS,
    updateDisplayTypeInAttendedReservationsSuccess,
    updateDisplayTypeInAttendedReservationsFailure
} from '../../actions/Reservations/updateDisplayTypeInAttendedReservationsActions';
import strings from '../../localizations/strings';

export function updateDisplayTypeInAttendedReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInAttendedReservationsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInAttendedReservationsFailure(strings.somethingWrongHappened)))
    )
}

