import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    ADD_NEW_COMPANY_RESERVATION,
    addNewCompanyReservationSuccess,
    addNewCompanyReservationFailure
} from '../../actions/CompanyReservations/addNewCompanyReservationActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function addNewCompanyReservationEpic(action$) {
    return action$.pipe(
        ofType(ADD_NEW_COMPANY_RESERVATION),
        switchMap(action => {
           
            const ajaxConfigObj = {
                url: url + `CompanyReservation/Add`,
                method: 'Post',
                headers: {
                    'Authorization': token,
                    'content-Type': 'application/json'
                },
                body: action.companyReservationData,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };
            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        map(response => {
            if (response.isSuccess) {
                return addNewCompanyReservationSuccess(response);
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return addNewCompanyReservationFailure(message);
            }
        }),
        catchError(error => of(addNewCompanyReservationFailure(strings.somethingWrongHappened)))
    )
}

