import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_RESERVATION_DETAILS,
    getReservationDetailsSuccess,
    getReservationDetailsFailure
} from '../../actions/Reservations/getReservationDetailsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function getReservationDetailsEpic(action$) {
    return action$.pipe(
        ofType(GET_RESERVATION_DETAILS),
        switchMap(action => {

            const ajaxConfigObj = {
                url: url + `Reservation/GetReservation?reservationId=${action.reservationId}`,
                method: 'POST',
                headers: {
                    'Authorization': token,
                    // 'content-type': 'application/json',
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response), retry())
        }),
        map(response => {
            console.log(response)
            if (response.isSuccess) {
                return getReservationDetailsSuccess(response.data);
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return getReservationDetailsFailure(message);
            }
        }),
        catchError(error => of(getReservationDetailsFailure(strings.somethingWrongHappened)))
    )
}