import {
    UPDATE_STATUS_RESERVATION_SCHEDULE,
    UPDATE_STATUS_RESERVATION_SCHEDULE_SUCCESS,
    UPDATE_STATUS_RESERVATION_SCHEDULE_FAILURE
} from '../../actions/ReservationSchedules/updateStatusReservationScheduleActions';

import { DisplayTypesInReservationSchedulesConstant } from '../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant';

export function updateStatusReservationScheduleReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_RESERVATION_SCHEDULE:
            return {
                ...state,
                isUpdatingStatusReservationSchedule: true,
                updateStatusReservationScheduleError: null,
            };
        case UPDATE_STATUS_RESERVATION_SCHEDULE_SUCCESS:
            return {
                ...state,
                isUpdatingStatusReservationSchedule: false,
                updateStatusReservationScheduleError: null,
                updateStatusReservationScheduleResult: action.payload.data,
                reservationSchedulesInReservationSchedules: state.reservationSchedulesInReservationSchedules.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_RESERVATION_SCHEDULE_FAILURE:
            return {
                ...state,
                isUpdatingStatusReservationSchedule: false,
                updateStatusReservationScheduleError: action.payload.message,
            };
        default:
            return state;
    }
}