import {
    GET_SMS_SETTINGS_IN_SMS_SETTINGS,
    GET_SMS_SETTINGS_IN_SMS_SETTINGS_SUCCESS,
    GET_SMS_SETTINGS_IN_SMS_SETTINGS_FAILURE
} from '../../actions/SmsSettings/getSmsSettingsInSmsSettingsActions';

export function getSmsSettingsInSmsSettingsReducer(state, action) {
    switch (action.type) {
        case GET_SMS_SETTINGS_IN_SMS_SETTINGS:
            return {
                ...state,
                isGetSmsSettingsInSmsSettings: true,
                getSmsSettingsInSmsSettingsError: null,
            };
        case GET_SMS_SETTINGS_IN_SMS_SETTINGS_SUCCESS:
            console.log(action.payload.smsSettings)
            return {
                ...state,
                isGetSmsSettingsInSmsSettings: false,
                getSmsSettingsInSmsSettingsError: null,
                smsSettingsInSmsSettings: action.payload.smsSettings.data,
                smsSettingsPageNumberInSmsSettings: action.payload.pageNumber,
                smsSettingsPageSizeInSmsSettings: action.payload.pageSize,
                smsSettingsTotalCountInSmsSettings: action.payload.smsSettings.total,
            };
        case GET_SMS_SETTINGS_IN_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                isGetSmsSettingsInSmsSettings: false,
                getSmsSettingsInSmsSettingsError: action.payload.message,
            };
        default:
            return state;
}}