import {
    UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS,
    UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateDisplayTypeInUnpaidReservationsActions';

export function updateDisplayTypeInUnpaidReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInUnpaidReservations: true,
                updateDisplayTypeErrorInUnpaidReservations: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInUnpaidReservations: false,
                displayTypeInUnpaidReservations: action.payload.displayType,
                updateDisplayTypeErrorInUnpaidReservations: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_UN_PAID_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInUnpaidReservations: false,
                updateDisplayTypeErrorInUnpaidReservations: action.payload
            };
        default:
            return state;
    }
}