export const ADD_NEW_TICKET_OPTION = 'ADD_NEW_TICKET_OPTION';
export const ADD_NEW_TICKET_OPTION_SUCCESS = 'ADD_NEW_TICKET_OPTION_SUCCESS';
export const ADD_NEW_TICKET_OPTION_FAILURE = 'ADD_NEW_TICKET_OPTION_FAILURE';

export const addNewTicketOption = (ticketOptionData) => ({
    type: ADD_NEW_TICKET_OPTION,
    ticketOptionData
});

export const addNewTicketOptionSuccess = (data) => ({
    type: ADD_NEW_TICKET_OPTION_SUCCESS,
    payload: { data }
});

export const addNewTicketOptionFailure = (message) => ({
    type: ADD_NEW_TICKET_OPTION_FAILURE,
    payload: { message }
});