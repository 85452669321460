import {
    CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS,
    CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_SUCCESS,
    CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_FAILURE
} from '../../../actions/Roles/RoleUsers/checkEmailAvailabilityInRoleUsersActions';

export function checkEmailAvailabilityInRoleUsersReducer(state, action) {
    switch (action.type) {
        case CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS:
            return {
                ...state,
                isCheckingEmailAvailabilityInRoleUsers: true,
                checkEmailAvailabilityInRoleUsersError: null,
            };
        case CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_SUCCESS:
            return {
                ...state,
                isCheckingEmailAvailabilityInRoleUsers: false,
                isEmailAvailableInRoleUsers: action.payload.isAvailable,
                checkEmailAvailabilityInRoleUsersError: null

            };
        case CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_FAILURE:
            return {
                ...state,
                isCheckingEmailAvailabilityInRoleUsers: false,
                checkEmailAvailabilityInRoleUsersError: action.payload
            };
        default:
            return state;
    }
}