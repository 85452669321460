import React, { useState, useEffect } from "react";
import { DisplayTypesInRequestsConstant } from "../../constants/Reservations/DisplayTypesInRequestsConstant";
import Request from './Request';
import Details from './Details';
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
import Approve from "./Approve";
import Add from "./Add";
import Edit from "./Edit";
function Requests(props) {


    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInRequests === DisplayTypesInRequestsConstant.LIST ? strings.request :
                        props.displayTypeInRequests === DisplayTypesInRequestsConstant.APPROVE ? strings.confirm :
                        props.displayTypeInRequests === DisplayTypesInRequestsConstant.EDIT ? strings.edit :
                        props.displayTypeInRequests === DisplayTypesInRequestsConstant.ADD ? strings.create :
                            props.displayTypeInRequests === DisplayTypesInRequestsConstant.DETAILS ? strings.details : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>
                            {
                                props.displayTypeInRequests !== DisplayTypesInRequestsConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.LIST)}>
                                        {strings.request}
                                    </Button> : null}
                                    {props.displayTypeInRequests !== DisplayTypesInRequestsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.ADD) }}>
                                    {strings.new}
                                </Button> : null}

                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInRequests === DisplayTypesInRequestsConstant.LIST && (<Request {...props} />)}
                            {props.displayTypeInRequests === DisplayTypesInRequestsConstant.DETAILS && (<Details {...props} />)}
                            {props.displayTypeInRequests === DisplayTypesInRequestsConstant.APPROVE && (<Approve {...props} />)}
                            {props.displayTypeInRequests === DisplayTypesInRequestsConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInRequests === DisplayTypesInRequestsConstant.EDIT && (<Edit {...props} />)}
                        </>

                    </div>
                </div>
            </div>


        </>
    );
}
export default Requests