export const ADD_NEW_COMPANY_RESERVATION = 'ADD_NEW_COMPANY_RESERVATION';
export const ADD_NEW_COMPANY_RESERVATION_SUCCESS = 'ADD_NEW_COMPANY_RESERVATION_SUCCESS';
export const ADD_NEW_COMPANY_RESERVATION_FAILURE = 'ADD_NEW_COMPANY_RESERVATION_FAILURE';

export const addNewCompanyReservation = (companyReservationData) => ({
    type: ADD_NEW_COMPANY_RESERVATION,
    companyReservationData
});

export const addNewCompanyReservationSuccess = (data) => ({
    type: ADD_NEW_COMPANY_RESERVATION_SUCCESS,
    payload: { data }
});

export const addNewCompanyReservationFailure = (message) => ({
    type: ADD_NEW_COMPANY_RESERVATION_FAILURE,
    payload: { message }
});