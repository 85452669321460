import {
    CLEAR_RESERVATION_DETAILS,
    CLEAR_RESERVATION_DETAILS_SUCCESS,
    CLEAR_RESERVATION_DETAILS_FAILURE
} from '../../actions/Reservations/clearReservationDetailsActions';

export function clearReservationDetailsReducer(state, action) {
    switch (action.type) {
        case CLEAR_RESERVATION_DETAILS:
            return {
                ...state,
                isClearReservationDetails: true,
                clearReservationDetailsError: null,
            };
        case CLEAR_RESERVATION_DETAILS_SUCCESS:
            return {
                ...state,
                isClearReservationDetails: false,
                reservationDetailsInReservations: null,
                clearReservationDetailsError: null,

                isUpdatingAttendenceStatusReservation: false,
                updateAttendenceStatusReservationError: null,
                updateAttendenceStatusReservationResult:null
            };
        case CLEAR_RESERVATION_DETAILS_FAILURE:
            return {
                ...state,
                isClearReservationDetails: false,
                clearReservationDetailsError: action.payload
            };
        default:
            return state;
    }
}