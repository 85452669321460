export const GET_SCHOOL_TOUR_RANGES_IN_REQUESTS = 'GET_SCHOOL_TOUR_RANGES_IN_REQUESTS';
export const GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_SUCCESS = 'GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_SUCCESS';
export const GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_FAILURE = 'GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_FAILURE';

export const getSchoolTourRangesInRequests = () => ({
    type: GET_SCHOOL_TOUR_RANGES_IN_REQUESTS,
});

export const getSchoolTourRangesInRequestsSuccess = (ranges) => ({
    type: GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_SUCCESS,
    payload: { ranges }
});

export const getSchoolTourRangesInRequestsFailure = (message) => ({
    type: GET_SCHOOL_TOUR_RANGES_IN_REQUESTS_FAILURE,
    payload: message
});