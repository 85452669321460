export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_FAILURE = 'DELETE_DISCOUNT_FAILURE';

export const deleteDiscount = (discountId) => ({
    type: DELETE_DISCOUNT,
    discountId
});

export const deleteDiscountSuccess = (data) => ({
    type: DELETE_DISCOUNT_SUCCESS,
    payload: { data }
});

export const deleteDiscountFailure = (message) => ({
    type: DELETE_DISCOUNT_FAILURE,
    payload: { message }
});