import {
    ADD_NEW_SCHOOL_TOUR_RANGE,
    ADD_NEW_SCHOOL_TOUR_RANGE_SUCCESS,
    ADD_NEW_SCHOOL_TOUR_RANGE_FAILURE
} from '../../actions/SchoolTourRanges/addNewSchoolTourRangeActions';

export function addNewSchoolTourRangeReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_SCHOOL_TOUR_RANGE:
            return {
                ...state,
                isAddNewSchoolTourRange: true,
                addNewSchoolTourRangeError: null,
                addNewSchoolTourRangeResult:null,
            };
        case ADD_NEW_SCHOOL_TOUR_RANGE_SUCCESS:
            return { 
                ...state,
                isAddNewSchoolTourRange: false,
                addNewSchoolTourRangeError: null,
                addNewSchoolTourRangeResult: action.payload.data,
            };
        case ADD_NEW_SCHOOL_TOUR_RANGE_FAILURE:
            return {
                ...state,
                isAddNewSchoolTourRange: false,
                addNewSchoolTourRangeError: action.payload.message,
            };
        default:
            return state;
}}