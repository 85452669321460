import {
    GET_RESERVATION_TYPES_IN_TICKET_OPTIONS,
    GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS,
    GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE
} from '../../actions/TicketOptions/getReservationTypesInTicketOptionsActions';

export function getReservationTypesInTicketOptionsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_TICKET_OPTIONS:
            return {
                ...state,
                isGetReservationTypesInTicketOptions: true,
                getReservationTypesInTicketOptionsError: null,
            };
        case GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS:
            return {
                ...state,
                isGetReservationTypesInTicketOptions: false,
                getReservationTypesInTicketOptionsError: null,
                reservationTypesInTicketOptions: action.payload.reservationTypes,
                reservationTypeInTicketOptions: state.editedTicketOptionInTicketOptions !== null ? action.payload.reservationTypes.filter(x => x.value === state.editedTicketOptionInTicketOptions.reservationTypeId)[0] : null,
            };
        case GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE:
            return {
                ...state,
                isGetReservationTypesInTicketOptions: false,
                getReservationTypesInTicketOptionsError: action.payload.message,
            };
        default:
            return state;
    }
}