export const EDIT_RESERVATION_TYPE = 'EDIT_RESERVATION_TYPE';
export const EDIT_RESERVATION_TYPE_SUCCESS = 'EDIT_RESERVATION_TYPE_SUCCESS';
export const EDIT_RESERVATION_TYPE_FAILURE = 'EDIT_RESERVATION_TYPE_FAILURE';

export const editReservationType = (reservationTypeData) =>{
    console.log(reservationTypeData) 
    return ({
    type: EDIT_RESERVATION_TYPE,
    reservationTypeData
});
}
export const editReservationTypeSuccess = (data) => ({
    type: EDIT_RESERVATION_TYPE_SUCCESS,
    payload: { data }
});

export const editReservationTypeFailure = (message) => ({
    type: EDIT_RESERVATION_TYPE_FAILURE,
    payload: { message }
});