import {
    EDIT_COMPANY_RESERVATION,
    EDIT_COMPANY_RESERVATION_SUCCESS,
    EDIT_COMPANY_RESERVATION_FAILURE
} from '../../actions/CompanyReservations/editCompanyReservationActions';

export function editCompanyReservationReducer(state, action) {
    switch (action.type) {
        case EDIT_COMPANY_RESERVATION:
            return {
                ...state,
                isEditingCompanyReservation: true,
                editCompanyReservationError: null,
            };
        case EDIT_COMPANY_RESERVATION_SUCCESS:
            return {
                ...state,
                isEditingCompanyReservation: false,
                editCompanyReservationResult: action.payload.companyReservationViewModel,
                editCompanyReservationError: null
            };
        case EDIT_COMPANY_RESERVATION_FAILURE:
            return {
                ...state,
                isEditingCompanyReservation: false,
                editCompanyReservationError: action.payload
            };
        default:
            return state;
    }
}