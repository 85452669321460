import {
    CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES,
    CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_SUCCESS,
    CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_FAILURE
} from '../../../actions/Roles/Roles/checkRoleNameArAvailabilityInRolesActions';

export function checkRoleNameArAvailabilityInRolesReducer(state, action) {
    switch (action.type) {
        case CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES:
            return {
                ...state,
                ischeckingRoleNameArAvailabilityInRoles: true,
                checkRoleNameArAvailabilityErrorInRoles: null,
            };
        case CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_SUCCESS:
            return {
                ...state,
                ischeckingRoleNameArAvailabilityInRoles: false,
                isRoleNameArAvailableInRoles: action.payload.isAvailable,
                checkRoleNameArAvailabilityErrorInRoles: null

            };
        case CHECK_ROLE_NAME_AR_AVAILABILITY_IN_ROLES_FAILURE:
            return {
                ...state,
                ischeckingRoleNameArAvailabilityInRoles: false,
                checkRoleNameArAvailabilityErrorInRoles: action.payload
            };
        default:
            return state;
    }
}