import {
    GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS,
    GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getReservationTypesInCompanyReservationsActions';

export function getReservationTypesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGetReservationTypesInCompanyReservations: true,
                getReservationTypesInCompanyReservationsError: null,
            };
        case GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetReservationTypesInCompanyReservations: false,
                getReservationTypesInCompanyReservationsError: null,
                reservationTypesInCompanyReservations: action.payload.reservationTypes,
                reservationTypeInCompanyReservations: state.editedCompanyReservationInCompanyReservations != null ? action.payload.reservationTypes.filter(x=> x.value == state.editedCompanyReservationInCompanyReservations.reservationTypeSettingId)[0] : null,
            };
        case GET_RESERVATION_TYPES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetReservationTypesInCompanyReservations: false,
                getReservationTypesInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}