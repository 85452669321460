export const GET_USERS_IN_USERS = 'GET_USERS_IN_USERS';
export const GET_USERS_IN_USERS_SUCCESS = 'GET_USERS_IN_USERS_SUCCESS';
export const GET_USERS_IN_USERS_FAILURE = 'GET_USERS_IN_USERS_FAILURE';

export const getUsersInUsers = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_USERS_IN_USERS,
    pageNumber,
    pageSize,
    search
});

export const getUsersInUsersSuccess = (users, pageNumber, pageSize) => ({
    type: GET_USERS_IN_USERS_SUCCESS,
    payload: { users, pageNumber, pageSize }
});

export const getUsersInUsersFailure = (message) => ({
    type: GET_USERS_IN_USERS_FAILURE,
    payload: { message }
});