import React, { useEffect } from "react";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInReservationTypesConstant } from "../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant";
import { StatusCodeConstant } from "../../constants/StatusCodeConstant";
import Loading from "../Shared/Loading/Loading";

function Add(props) {
  useEffect(() => {
    if (
      props.addNewReservationTypeResult !== null &&
      props.addNewReservationTypeResult.isSuccess
    ) {
      Swal.fire(
        `! ${strings.successfullyAdded}`,
        props.addNewReservationTypeResult.message,
        "success"
      ).then((result) => {
        props.updateDisplayTypeInReservationTypes(
          DisplayTypesInReservationTypesConstant.LIST
        );
      });
    }
  }, [props.addNewReservationTypeResult]);

  useEffect(() => {
    if (
      props.addNewReservationTypeError !== null 
    ) {
      Swal.fire(
        //icon: 'error',
        "Oops...",
        props.addNewReservationTypeError,
        "error"
      );
    }
  },[props.addNewReservationTypeError]);

  return (
    <>
     { props.isAddNewReservationType && ( <Loading />)}
     {  !props.isAddNewReservationType &&
     ( <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header border-0 d-flex align-items-center">
            <h3 className="card-title col-9">
              <span className="card-label fw-bolder text-dark">
                {strings.addReservationType}
              </span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              initialValues={
                {
                  reservationTypeNameAr: "",
                 reservationTypeNameEn: "",
                }
              }
              validationSchema={Yup.object().shape({
                   reservationTypeNameAr: Yup.string().required(
                      strings.nameArRequired
                    ),
                    reservationTypeNameEn: Yup.string().required(
                      strings.nameEnRequired
                    ),
              })}
              onSubmit={(fields) => {
                   const reservationType={
                           "nameAr" : fields.reservationTypeNameAr,
                           "nameEn" : fields.reservationTypeNameEn,
                   }
                   props.addNewReservationType(reservationType);
                    console.log(reservationType)
              }}
              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex"style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.arabicName}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="reservationTypeNameAr"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.reservationTypeNameAr &&
                            touched.reservationTypeNameAr
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterNameAr} *`}
                        />
                        <ErrorMessage
                          name="reservationTypeNameAr"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.englishName}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Field
                          type="text"
                          name="reservationTypeNameEn"
                          className={
                            "form-control form-control1 form-control-lg form-control-solid" +
                            (errors.reservationTypeNameEn &&
                            touched.reservationTypeNameEn
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={`${strings.enterNameEn} *`}
                        />
                        <ErrorMessage
                          name="reservationTypeNameEn"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between p-3">
                    <div>
                      <button
                        type="submit"
                        className="btn fs-6"
                        style={{
                          backgroundColor: "#009ef7",
                          borderColor: "#009ef7",
                          color: "white",
                          borderRadius: "0.75rem",
                        }}
                        id="kt_account_profile_details_submit"
                        //	onClick={done}
                      >
                        {strings.save}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
       )
      }
    </>
  );
}
export default Add;
