const englishStrings = {
  home: "Home",
  ourServices: "Our Services",
  youhealth: "YOUR HEALTH",
  notifications: "NOTOFICATIONS",
  logout: "LOGOUT",
  newAccount: "New Account",
  county: "Country",
  doctors: "Doctors",
  doctor: "Doctor",
  clinics: "Clinics",
  medicalCenter: "Medical Center",
  medicalCenters: "Medical Centers",
  hospitals: "Hospitals",
  intensiveCare: "Intensive Care",
  incubators: "Incubators",
  laboratories: "Laboratories",
  radiologyCenters: "Radiology Centers",
  pharmacies: " Pharmacies",
  all: "all",
  seachWithDrName: "Search with Dr Name",
  searchWithClinicName: "Search With Clinic Name",
  searchWithMedicalCenterName: "Search With Medical Center Name",
  searchWithHospitalName: "Search With Hospital Name",
  searchWithIntensiveCareName: "Search With Intensive Care Name",
  SearchWithIncubatorName: "Search With Incubator Name",
  searchWithLaboratoryName: "Search with Laboratory name",
  searchWithRadiologyCenterName: "search With Radiology Center Name",
  searchWithPharamcyName: "search With Pharamcy Name",
  hideFilters: "Hide Filters -",
  showFilters: "Show Filters +",
  fields: "Fields",
  field: "Field",
  specialities: "specialities",
  insuranceCompany: "Insurance Company ",
  title: "Title",
  governorate: "Governorate",
  city: "City",
  area: "Area",
  fees: "Fees",
  institutionsName: "Institutions Name",
  intensiveCareType: "Intensive Care Type",
  dailyCost: "Daily Cost",
  testCost: "Test Cost ",
  medicalTestType: "Medical Test Type",
  medicalTestName: "Medical Test Name ",
  radiationCost: "Radiation Cost ",
  medicalRadiationType: "Medical Radiation Type",
  medicalRadiationName: "Medical Radiation Name ",
  iSearchFor: "i search for Doctor ",
  all: "All",
  male: "Male",
  female: "Female",
  loremSM:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  loremL:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  loremXl:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  whyMedicalOnHand: "WHY MEDICAL ON HAND ?",
  institutions: "Institutions",
  aboutUs: "About Us",
  chooseFromPopular: "CHOOSE FROM POPULAR",
  categories: "Categories",
  medicalOnHand: "Medical On Hand",
  HowUse: "How Use",
  search: "SEARCH",
  compare: "COMPARE",
  reserve: "RESERVE",
  contactUs: "Contact Us",
  termsAndConditions: "terms & Conditions",
  topSpeciality: " top Speciality",
  topHospitals: " top Hospitals",
  topGovernorates: " top Governorates",
  appointments: "Appointments",
  services: "Services",
  noAvailableAppointmentsInSelectedDate:
    "No available appointments in selected date",
  opsThisTimeNotAvailable: "Ops... this time not available",
  select: "select",
  nextAvailableTime: "next available time ",
  next: "Next",
  previous: "Previous",
  yourAppointmentWillBeAt: "Your Appointment Will be at",
  from: "From",
  to: "To",
  nameRequierd: "Name is requierd",
  mobileRequierd: "Mobile is requierd",
  emailIsInvalid: "Email is invalid",
  emailRequired: "Email is requierd",
  passwordRequired: "Password is requierd",
  confirmPasswordRequired: "Confirm Password is requierd",
  enterpatientname: "Enter patient name",
  enterYourEmail: "Enter your email",
  enterYourMobile: "Enter your mobile",
  cancel: "Cancel",
  reserveNow: "Reserve Now",
  you: "You",
  yourRelevent: "Your Relevent",
  reservationTypes: "Reservation Types",
  paymentType: "Payment Type",
  patientCard: "Patient Card",
  selectInsurancCompany: "Select insuranc company",
  enterPatientCardNumber: "Enter patient card number",
  back: "Back",
  insuranceCompanyisRequired: "insurance company is required",
  relevantTypeIsRequired: "Relevant type is required",
  enterReleventName: "Enter relevent name",
  releventType: "Releven type",
  patientRelevantIsRequierd: "Patient relevant is requierd",
  selectReleventFirst: "Select Relevent first",
  PaymentTypeIsRequired: "PaymentType is Required",
  PaymentTypeIsRequiredFirst: "PaymentType is Required First",
  patientCardIsRequierd: "Patient card is Requierd",
  patientCardNumberIsRequired: "patient card number is required",
  patientRelevantNameIsRequired: "patient relevant name is required",
  reservationTypeIsRequierd: "Reservation type is Requierd",
  reservationTypeIsRequierdFirst: "Reservation type is Requierd First",
  yourRservationDoneSuccessfullyAt: "Your Reservation Done Successfully at",
  yourRservationDoneSuccessfully: "Your Reservation Done Successfully ",
  showMap: "Show map",
  drImg: "Doctor image",
  noOptions: "No options",
  addNew: "ADD NEW",
  nameEn: "Name En",
  nameAr: "Name Ar",
  enterNameAr: "Enter Arabic Name",
  enterNameEn: "Enter English Name",
  nameArRequired: "Arabic Name Required",
  nameEnRequired: "English Name Required",
  englishName: "English Name",
  arabicName: "Arabic Name",
  thereIsNoDataToDisplay: "There is no data to display....",
  opsThereIsSomethingHappenedInRetrievingData:
    "Ops! There is something happened in retrieving data.",
  showing: "Showing",
  of: "of",
  pleaseEnterEnglishName: "Please Enter English Name",
  maxLengthIs: "Max length is",
  englishNameEnteredBefore: "English Name entered before",
  pleaseEnterArabicName: "Please Enter Arabic Name",
  arabicNameEnteredBefore: "Arabic Name entered before",
  addInstitutionType: "Add Institution Type",
  addFail: "Add Fail",
  save: "Save",
  editFail: "Edit Fail",
  close: "Close",
  areYouSureYouWantToDelete: "Are you sure you want to delete",
  institutionType: "Institution Type",
  delete: "Delete",
  somethingWrongHappened: "Something wrong happened",
  invalidInputs: "Invalid inputs",
  egypt: "Egypt",
  country: "Country",
  code: "Code",
  active: "Active",
  editCountry: "Edit Country",
  pleaseEnterCode: "Please Enter Code",
  codeEnteredBefore: "Code entered before",
  institutionIsRequired: "Institution is required",
  branchIsRequired: "Branch is required",
  fieldIsRequired: "Field is required",
  selectInstitution: "Select institution",
  selectBranch: "Select branch",
  selectField: "Select field",
  about: "About",
  educationes: "Educationes",
  contacts: "Contacts",
  aboutDoctor: "About Doctor",
  hospitalsAndClinics: "Hospitals and Clinics",
  doctorGallery: "Doctor's Gallery",
  thereIsNoImg: "There's no Image",
  gallery: "Gallery",
  doctorsArticals: "Doctor's Articals",
  thereIsNoArticals: "There is no any Articals",
  articalImg: "Artical image",
  readMore: "Read more",
  commentImg: "comment image",
  ourClientsComments: "Our clients' comments",
  thereIsNoAnyComments: "There is no any comments",
  selectCountry: "Select Country",
  pleaseSelectCountry: "Please select country",
  branches: "Branches",
  insuranceCompanies: "Insurance companies",
  available: "Available",
  instensiveCares: "Instensive cares",
  institutionsBranchIsRequierd: "Institutions branch is requierd",
  doctorNameIsRequired: "Doctor name is required",
  dateIsRequired: "Date is required",
  error: "Error",
  timeIsRequired: "Time is required",
  selectDoctorName: "Select doctor name",
  selectDate: "Select date",
  selectTime: "Select time",
  theDeductionCost: "The deduction cost",
  showBranchContact: "Show branch contact",
  hideBranchContact: "Hide branch contact",
  incubatorIsRequierd: "Incubator is requierd",
  selectIncubator: "Select incubator",
  intensiveCareIsRequierd: "Intensive care is requierd",
  selectIntensiveCare: "Select intensive care",
  done: "Done",
  enterBabyName: "Enter baby name",
  babyNameRequired: "Baby name is required",
  addNewRelevant: "Add New Relevant",
  thisIntesiveCareNotAvailableNow: "This Intensive Care Is Not Available Now",
  thisIncubatorIsNotAvailableNow: "This Incubator Is Not Available Now",
  intensiveCareImg: "intensiveCare-img",
  intensiveCareWillBeHiddenSoon: "Intensive care will be hidden soon...",
  intensiveCareAlreadyReserved: "Intensive care already reserved",
  reservation: "Reservation",
  reservations: "Reservations",
  branch: "branch",
  thisIntensiveCareIsNotAvailableNow:
    "This Intensive Care Is Not Available Now",
  testCost: "Test Cost",
  IncubatorAlreadyReserved: "This Incubator already reserved",
  incubatorWillBehiddenSoon: "Incubation will be hidden soon...",
  thisIncubationsNotAvailableNow: "This incubation is not available now",
  specialisteAt: "SPECIALIST AT",
  institutionGallery: "Clinic Gallery",
  ourBranches: "Our Branches",
  thereIsNpBranchMapData: "There is no branch map data",
  map: "Map",
  ourFields: "Our Fields",
  phone: "Phone",
  mobile: "Mobile",
  hotline: "Hotline",
  links: "Links",
  link: "Link",
  doctorDoesNotSupportThisReservationType:
    "Doctor does not support this reservation type",
  youHaveNotAnyDetectionReservationBeforeOnThisDoctor:
    "you have not any detection reservation before on this doctor",
  thereIsNoAvailableReservationsAsLimitOfReservationsReached:
    "There is no available reservations as limit of reservations reached",
  noCasesSpecifiedForSelectedReservationType:
    "No cases specified for selected reservation type",
  maxCasesForSelectedReservationTypeIsReached:
    "Max cases for selected reservation type is reached.",
  followedReservationAvailabilityIsExpiredCanNotUseThisReservationType:
    "Followed reservation availability is expired , can not use this reservation type",
  selectGovernorate: "Select Governorate",
  pleaseSelectGovernorate: "Please select governorate",
  selectCity: "Select City",
  pleaseSelectCity: "Please select city",
  laboratoryBranchIsRequired: "Laboratory branch is required",
  medicalTestTypeIsRequired: "medical Test Type is required",
  medicalTestIsRequired: "medical Test  is required",
  selectTestType: "Select test type",
  selectTest: "Select test",
  TestCost: "Test cost",
  hideTestInstructions: "Hide test instructions",
  showTestInstructions: "Show test instructions",
  yourReservationFor: "Your Reservation for",
  doneSuccessfullyOn: "Done successfully on",
  testInstructions: "Test instructions",
  surgeryRooms: "Surgery Rooms",
  radiations: "Radiations",
  tests: "Tests",
  address: "Address",
  countries: "Countries",
  governorates: "Governorates",
  cities: "Cities",
  areas: "Areas",
  ourTeam: "Our Team",
  enterYourEmailOrPhoneOrMOHCode: "Enter Email or Mobile or MOH code",
  emailOrPhoneOrMohRequierd: "Email Or Phone Or MOH code is Required",
  invalidPassword: "Password not match.....",
  enterYourPassword: "Enter your Password",
  thisAccountYouEnteredWasInvalidEnterADifferentAccount:
    "This account you entered was invalid enter a diffrent account",
  emailEnteredBefore: "Email entered before",
  mobileEnteredBefore: "Mobile entered before",
  withFacebook: "with facebook",
  enterPassword: "Enter password",
  enterConfirmPassword: "Enter Confirm password",
  iDontHaveAnAccount: "I don't have an account",
  registerNow: "Register now ",
  WithGoogle: "With Google",
  youHaveMoreThanRole: "You have more than role",
  selectRole: "select role",
  ThereAreMoreThanOrganization: "There are more than Organization",
  selectOrgainzation: "select organization",
  ThereAreMoreThanInstitution: "ThereAreMoreThanInstitution",
  ThereAreMoreThanBranch: "ThereAreMoreThanBranch",
  selectAccountType: "Select account type",
  enterYourName: "Enter your name ",
  loginForm: "Login Form",
  registrationForm: "Registration Form",
  registrationFinishNeedToConfirmYourEmail:
    "Registration finish need to confirm your email",
  verificationMailMassage: " verification email massage",
  resend: "resend",
  patientForm: "Patient Form",
  doctorForm: "Doctor Form",
  selectYourNationality: "Select your nationality",
  dateOfBirth: "Date of birth",
  gender: "Gender",
  or: "or",
  institutionForm: "Institution form",
  enterInstitutionName: "Enter institution name ",
  enterNumberOfBranch: "Enter number of branch *",
  enterNumberOfUsers: "Enter number of users *",
  enterNumberOfFields: "Enter number of fields *",
  enterNumberOfDoctors: "Enter number of doctors *",
  weContactWithYouSoon: "We contact with you soon",
  accountTypeIsRequired: "Account Type is required",
  patientNationalityIsRequired: "Nationality Is Required",
  makeAReservation: "Make A Reservation",
  radiologyCenterBranchIsRequired: "Radiology Center branch is required",
  medicalReadiationTypeIsRequired: "Medical Radiation Type is required",
  medicalRadiationIsRequired: "Medical Radiation is required",
  radiationCenterBranchIsRequired: "Radiology Center branch is required",
  selectRadiationType: "Select radiation type",
  selectRadiationName: "Select radiation name",
  hideMedicalRadiationInstructions: "Hide medical radiation instructions",
  showMedicalRadiationInstructions: "Show medical radiation instructions",
  medicalRadiationInstructions: "Medical radiation instructions",
  backToHome: "back to home",
  thereIsNo: "There is no",
  result: "result",
  numberOfOurBranches: "Number of our branches",
  myProfile: "My Profile",
  MyReservations: "My Reservations",
  myReleventsReservations: "My relevents reservations",
  settings: "Settings",
  changeMyPassword: "Change my password",
  currentPasswordIsRequired: "Current password is required",
  enterNewPassword: "Enter new password",
  changePassword: "Change Password",
  enterCurrentPassword: "Enter current password",
  search: "Search...",
  latestArticals: "Latest Articals",
  tags: "Tags",
  recommended: "Recommended",
  comments: "Comments",
  like: "like",
  leaveComment: "leave Comment",
  enterYourComment: "Enter your comment",
  addComment: "Add comment",
  replay: "replay",
  userName: "User Name",
  RadiationCost: "Radiation cost",
  showRadiationInstructions: "Show radiation instructions",
  hideRadiationInstructions: "Hide radiation instructions",
  radiationInstructions: "Radiation instructions",
  laboratoryForm: "Laboratory form",
  enterLaboratoryName: "Enter laboratory name",
  enterNumberOfTestTyps: "Enter number of test typs",
  enterNumberOfTests: "Enter number of tests",
  RadiologyCenterForm: "Radiology Center form",
  enterRadiologyCenterName: "Enter Radiology Center name",
  enterNumberOfRadiationTyps: "Enter number of Radiation typs",
  enterNumberOfRadiations: "Enter number of Radiations",
  pharamcyForm: "Pharamcy form",
  enterPharamcyName: "Enter Pharamcy name",
  enterNumberMedicineItem: "Enter number of Medicine item",
  mohCode: "MOH Code",
  logo: "Logo",
  selectInstitutionType: "Select institution type",
  selectLicenseStatus: "Select license status",
  loading: "Loading",
  displayedInSearch: "Displayed in search",
  forgetPassword: "I'm forget my password",
  enterYourEmailToSendConfirmationMail:
    "Enter your e-mail to send confirmation mail",
  send: "send",
  addCountry: "Add Country",
  addGovernorate: "Add Governorate",
  editGovernorate: "Edit Governorate",
  addCity: "Add City",
  editCity: "Edit City",
  addArea: "Add Area",
  editArea: "Edit Area",
  addInstitution: "Add Institution",
  editInstitution: "Edit Institution",
  institutionTypes: "Institution Types",
  insertedSuccessfully: "Inserted Successfully",
  howDoYouWantToReceiveTheCodeToResetYourPassword:
    "How do you want to receive the code to reset your password?",
  sendCodeViaEmail: "Send code via Email",
  sendCodeViaMobile: "Send code via Mobile",
  enterCode: "Enter Code",
  dateOfBirthIsRequired: "Date Of Birth Is Required",
  dateOfBirthNotValid: "Date Not Valid",
  skip: "Skip",
  mobileMustStartsWith: "Mobile Must Start with ",
  mobileCountNumbers: "Mobile Number Must be ",
  mobileEnterBefore: "Mobile Entered Before",
  institution: "Institution",
  details: "Details",
  emailOrMobileRequired: "Mobile or Email Required",
  adminAccount: "Admin Account",
  license: "License",
  institutionTypesRequired: "Institution Type Required",
  minimumNumberIs: "Minimum number is",
  requiredMinNumber: "Minimum Number is 1",
  requiredMaxNumber: "Maximum Number is 100 ",
  requiredIntNumber: "Must be integer Number ",
  numberCannotBeDecimal: "Number cannot be decimal",
  requiredBranchNumber: "Branch Count is Required",
  requiredInstitutionFieldNumber: "Field Count is Required",
  requiredDoctorNumber: "Doctor Count is Required",
  requiredUserNumber: "User Count is Required",
  users: "users",
  user: "user",
  requiredLaboratoryMedicalTestNumber: "Medical Test Count is Required",
  medicalTests: "Medical Tests",
  medicalTest: "Medical Test",
  requiredRadiologyCenterMedicalRadiationNumber:
    "Medical Radiation Count is Required",
  medicalRadiations: "Medical Radiations",
  medicalRadiation: "Medical Radiation",
  countryCallingCode: "Country calling code",
  mobileAlreadyExist: "Mobile already exist",
  emailAlreadyExist: "Email already exist",
  flag: "Flag",
  emailNotExist: "Email Not Exist",
  accountIsDeactivated: "Account Is Deactivated",
  emailNotActive: "Email Not Active",
  noRole: "No Role Added",
  loginSuccessfully: "Login Successfully",
  noLicense: "Organization Not have License",
  licenseExpired: "License Expired",
  noActiveOrganization: "Organization Not Active",
  noActiveOrganizationBranch: "Organization Branch Not Active",
  selectOrganizationBranch: "Select Organization Branch",
  selectOrganization: "Select Organization",
  roleCheck: "Select Account Type",
  mobileNotExist: "Mobile Not Exist",
  mobileNotActive: "Mobile Not Active",
  contactWithAdmin: "some thing wrong, contact with admin",
  haveNotAccount: "not have account",
  roleIsRequired: "Role Is Required",
  organizationIsRequired: "organization Is Required",
  organizationBranchIsRequired: "Organization Branch Is Required",
  descriptionEn: "english Description",
  descriptionAr: "arabic Description",
  contactType: "contact Type",
  pleaseSelectContactType: "please Select Contact Type",
  contactValue: "contact Value",
  contactValueIsRequired: "contact Value Is Required",
  addPharmaceuticalCompanyContact: "Add Pharmaceutical Company Contact",
  contactValueEnteredBefore: "Contact Value Entered Before",
  editPharmaceuticalCompanyContact: "Edit Pharmaceutical Company Contact",
  pharmaceuticalCompanyContact: "Pharmaceutical Company Contact",
  addInstitutionAdmin: "Add Institution Admin",
  licenseStatus: "Status",
  fromDate: "From Date",
  toDate: "To Date",
  isCurrent: "Is Current",
  allowedAddScreen: "Allowed Add Screen",
  allowedAddScreenPermission: "Allowed Add Screen Permission",
  pleaseSelectLicenseStatus: "Please Select License Status",
  addInstitutionLicense: "Add Institution License",
  editInstitutionLicense: "Edit Institution License",
  institutionLicense: "Institution License",
  screenDiplayEn: "Screen Name English",
  screenDiplayAr: "Screen Name Arabic",
  parentScreenDiplayEn: "Parent Screen Name English",
  parentScreenDiplayAr: "Parent Screen Name Arabic",
  pleaseSelectScreen: "Please Select Screen",
  addLicenseScreen: "Add New License Screen",
  editLicenseScreen: "Edit License Screen",
  selectScreen: "Select Screen",
  screen: "Screen",
  permissionDiplayEn: "Permission Name English",
  permissionDiplayAr: "permission Name Arabic",
  pleaseSelectPermission: "Please select Permission",
  addLicenseScreenPermission: "Add New License Permission",
  editLicenseScreenPermission: "Edit License Permission",
  selectPermission: "Select Permission",
  permission: "Permission",
  parentScreen: "Parent Screen",
  screens: "Screens",
  permissions: "Permissions",
  institutionLicenses: "Institution Licenses",
  addLicense: "Add License",
  editLicense: "Edit License",
  numOf: "numOf",
  isClinicModuleNeeded: "is Clinic Module",
  isIncubationModuleNeeded: "is Incubation Module",
  isIntensiveCareModuleNeeded: "is IntensiveCare Module",
  isSurgeryRoomModuleNeeded: "is SurgeryRoom Module",
  genericName: "generic Name",
  commonName: "common Name",
  pharmaceuticalCompany: "pharmaceutical Company",
  selectPharmaceuticalCompany: "select Pharmaceutical Company",
  addMedicine: "Add Medicine",
  editMedicine: "Edit Medicine",
  medicine: "medicine",
  pleaseSelectPharmaceuticalCompany: "please Select Pharmaceutical Company",
  genericNameEn: "generic Name English",
  commonNameEn: "common Name English",
  noteEn: "note English",
  genericNameAr: "generic Name Arabic",
  commonNameAr: "common Name Arabic",
  noteAr: "note Arabic",
  pleaseEnterBasicNameEn: "please Enter Basic Name English",
  pleaseEnterBasicNameAr: "please Enter Basic Name Arabic",
  pleaseEnterCommonNameEn: "please Enter Common Name English",
  pleaseEnterCommonNameAr: "please Enter Common Name Arabic",
  genericNameArEnteredBefore: "generic Name Arabic Entered Before",
  genericNameEnEnteredBefore: "generic Name English Entered Before",
  commonNameArEnteredBefore: "common Name Arabic Entered Before",
  commonNameEnEnteredBefore: "common Name English Entered Before",
  addPharmaceuticalCompany: "Add Pharmaceutical Company",
  editPharmaceuticalCompany: "Edit Pharmaceutical Company",
  pleaseSelectCountryFirst: "please Select Country First",
  pleaseSelectContactTypeFirst: "please Select Contact Type First",
  addMedicalTestType: "Add Medical Test Type",
  editMedicalTestType: "Edit Medical Test Type",
  pleaseSelectMedicalTestType: "please Select Medical Test Type",
  selectMedicalTestType: "select Medical Test Type",
  medicalTestInstructions: "medical Test Instructions",
  instructions: "Instructions",
  addMedicalTestInstructions: "Add Medical Test Instructions",
  editMedicalTestInstructions: "Edit Medical Test Instructions",
  instructionBodyEn: "Instruction English",
  instructionBodyAr: "Instruction Arabic",
  pleaseEnterInstructionBodyEn: "please Enter Instruction English",
  pleaseEnterInstructionBodyAr: "please Enter Instruction Arabic",
  addMedicalRadiationType: "Add Medical Radiation Type",
  editMedicalRadiationType: "Edit Medical Radiation Type",
  pleaseSelectMedicalRadiationType: "please Select Medical Radiation Type",
  selectMedicalRadiationType: "select Medical Radiation Type",
  medicalRadiationInstructions: "Medical Radiation Instructions",
  addMedicalRadiationInstructions: "Add Medical Radiation Instructions",
  editMedicalRadiationInstructions: "Edit Medical Radiation Instructions",
  addMedicalTest: "Add Medical Test",
  editMedicalTest: "Edit Medical Test",
  addMedicalRadiation: "Add Medical Radiation",
  editMedicalRadiation: "Edit Medical Radiation",
  addSurgery: "Add Surgery",
  editSurgery: "Edit Surgery",
  surgery: "Surgery",
  addLaboratory: "Add Laboratory",
  editLaboratory: "Edit Laboratory",
  laboratory: "Laboratory",
  addLaboratoryAdmin: "Add Laboratory Admin",
  laboratoryLicense: "laboratory License",
  laboratoryLicenses: "Laboratory Licenses",
  addRadiologyCenter: "Add RadiologyCenter",
  editRadiologyCenter: "Edit RadiologyCenter",
  radiologyCenter: "RadiologyCenter",
  addRadiologyCenterAdmin: "Add RadiologyCenter Admin",
  radiologyCenterLicense: "RadiologyCenter License",
  radiologyCenterLicenses: "RadiologyCenter Licenses",
  addPharmacy: "Add Pharmacy",
  editPharmacy: "Edit Pharmacy",
  pharmacy: "Pharmacy",
  addPharmacyAdmin: "Add Pharmacy Admin",
  pharmacyLicense: "pharmacy License",
  pharmacyLicenses: "Pharmacy Licenses",
  editInfo: "Edit Info",
  aboutEn: "About English",
  aboutAr: "About Arabic",
  roleName: "Role Name",
  roleBase: "Role Base Name",
  allowedAddNotifications: "Allowed Add Notifications",
  pleaseSelectInstitutionRoleBase: "please Select Institution Role Base",
  selectInstitutionRoleBase: "select Institution Role Base",
  addRole: "Add Role",
  editRole: "Edit Role",
  role: "Role",
  addScreen: "Add Screen",
  editScreen: "Edit Screen",
  addScreenPermission: "Add Screen Permission",
  editScreenPermission: "Edit Screen Permission",
  addBranch: "Add Branch",
  editBranch: "Edit Branch",
  addExistingUser: "Add Existing User",
  changeWithNewUser: "changeWithNewUser",
  changeWithExistingUser: "changeWithExistingUser",
  addUser: "Add User",
  pleaseSelectUser: "please Select User",
  isPrimaryField: "Primary Field",
  addField: "Add Field",
  editField: "Edit Field",
  pleaseSelectField: "Please Select Field",
  fieldName: "Field Name",
  doctorName: "Doctor Name",
  doctorTitleName: "Doctor Title Name",
  addDoctor: "Add Doctor",
  editDoctor: "Edit Doctor",
  selectDoctor: "Select Doctor",
  pleaseSelectDoctor: "Please Select Doctor",
  selectDoctorTitle: "Select Doctor Title",
  pleaseSelectDoctorTitle: "Please Select Doctor Title",
  specialityName: "Speciality Name",
  addSpeciality: "Add Speciality",
  editSpeciality: "Edit Speciality",
  selectSpeciality: "Select Speciality",
  pleaseSelectSpeciality: "Please Select Speciality",
  speciality: "Speciality",
  editableData: "editable Data",
  account: "Account",
  addExistingDoctor: "addExistingDoctor",
  titleDescriptionEn: "Title Description English",
  titleDescriptionAr: "Title Description Arabic",
  selectGender: "Select Gender",
  codeEnteredBefore: "Code Entered Before",
  PasswordNotMatch: "Password Not Match",
  detectionPrice: "Detection Price",
  detectionPriceIsRequired: "Detection Price Is Required",
  specificTime: "Specific Time",
  patientTimeAverage: "Patient Time Average",
  minute: "Minute",
  calendarNumberOfDays: "Calendar Number Of Days",
  days: "Days",
  cannotDeleteItemAsChildDataFound: "Cannot Delete Item As Child Data Found",
  hasAcoount: "has Account",
  branchName: "Branch Name",
  mainBranch: "Main Branch",
  addressEn: "Address With English",
  addressAr: "Address With Arabic",
  pleaseSelectGovernorateFirst: "please Select Governorate First",
  pleaseSelectCityFirst: "please Select City First",
  selectArea: "Select Area",
  pleaseSelectArea: "Please Select Area",
  addContact: "Add Contact",
  editContact: "Edit Contact",
  contact: "Contact",
  searchDate: "Search Date",
  day: "Day",
  reservationType: "Reservation Type",
  patientName: "Patient Name",
  reservationStatus: "Reservation Status",
  date: "Date",
  history: "History",
  addReservation: "Add Reservation",
  gridReservations: "Grid Reservations",
  existingPatient: "Existing Patient",
  patientsCreatedAccountBefore: "Patients Created Account Before",
  yourPatients: "Your Patients",
  newPatient: "new Patient",
  selectPatient: "Select Patient",
  pleaseSelectPatient: "Please Select Patient",
  selectPatientFirst: "Select Patient First",
  surgeryName: "Surgery Name",
  cost: "Cost",
  institutionSurgery: "Institution Surgery",
  pleaseSelectSurgery: "Please select surgery",
  pleaseEnterCost: "Please enter cost",
  addInstitutionSurgery: "Add Institution Surgery",
  englishDescription: "English Description",
  arabicDescription: "Arabic Description",
  incubationName: "Incubation Name",
  status: "Status",
  addIncubation: "Add Incubation",
  editIncubation: "Edit Incubation",
  incubation: "Incubation",
  intensiveCareName: "Intensive Care Name",
  addIntensiveCare: "Add Intensive Care",
  editIntensiveCare: "Edit Intensive Care",
  intensiveCareTypeIsRequired: "Intensive Care Type Is Required",
  showIsCurrentOnly: "Show Is Current Only",
  selectIncubation: "Select Incubation",
  pleaseSelectIncubation: "Please Select Incubation",
  schedule: "Schedule",
  pleaseSelectBranch: "Please Select Branch",
  followedReservationAvailabilityWithinDays:
    "Followed Reservation Availability Within Days",
  diagnosisPrice: "Diagnosis Price",
  surgeryRoomName: "Surgery Room Name",
  addSurgeryRoom: "Add Surgery Room",
  editSurgeryRoom: "Edit Surgery Room",
  surgeryRoom: "Surgery Room",
  addReservationType: "Add Reservation Type",
  schedules: "Schedules",
  addSchedule: "Add Schedule",
  editSchedule: "Edit Schedule",
  selectDay: "Select Day",
  pleaseSelectDay: "Please Select Day",
  fromTime: "From Time",
  toTime: "To Time",
  selectSurgeryRoom: "Select Surgery Room",
  pleaseSelectSurgeryRoom: "Please Select Surgery Room",
  pleaseSelectReservationType: "Please Select Reservation Type",
  pleaseEnterDiagnosisPrice: "Please Enter Diagnosis Price",
  selectReservationType: "Select Reservation Type",
  editReservationType: "Edit Reservation Type",
  maxCases: "Max Cases",
  isCasesSpecified: "Is Cases Specified",
  selectSurgery: "Select Surgery",
  selectSchedule: "Select Schedule",
  pleaseSelectSchedule: "Please Select Schedule",
  divideCasesBySupportedReservationTypes:
    "Divide Cases By Supported Reservation Types",
  maxCasesDivisions: "Max Cases Divisions",
  maxCasesCount: "Max Cases Count",
  modify: "Modify",
  item: "Item",
  addInsuranceCompany: "Add Insurance Company",
  editInsuranceCompany: "Edit Insurance Company",
  selectCateory: "Select Cateory",
  pleaseSelectCategory: "Please Select Category",
  addCategory: "Add Category",
  editCategory: "Edit Category",
  category: "Category",
  scheduleMaxCases: "Schedule Max Cases",
  addPatient: "Add Patient",
  editPatient: "Edit Patient",
  addExistingPatient: "Add Existing Patient",
  modifyReservationTypeMaxCases: "Modify Reservation Types Max Cases",
  serviceName: "Service Name",
  addService: "Add Service",
  editService: "Edit Service",
  service: "Service",
  patient: "Patient",
  institutionProfile: "Institution Profile",
  institutionRoles: "Institution Roles",
  systemAdmin: "System Admin",
  institutionFieldSettings: "Institution Field Settings",
  patients: "Patients",
  branchPatients: "Branch Patients",
  branchDoctors: "Branch Doctors",
  systemAdministration: "System Administration",
  doctorReservations: "Doctor Reservations",
  incubations: "Incubations",
  incubationReservations: "Incubation Reservations",
  intensiveCares: "Intensive Cares",
  intensiveCareReservations: "Intensive Care Reservations",
  surgeryRoomReservations: "Surgery Room Reservations",
  institutionSurgeries: "Institution Surgeries",
  hideMap: "Hide Map",
  institutionBranchFieldSettings: "Institution Branch Field Settings",
  pictureName: "Picture Name",
  pictureDescription: "Picture Description",
  picture: "Picture",
  browse: "Browse",
  selectPicture: "Select Picture",
  addPicture: "Add Picture",
  editPicture: "Edit Picture",
  institutionContacts: "Institution Contacts",
  institutionBranchContacts: "Institution Branch Contacts",
  relevantType: "Relevant Type",
  maritalStatus: "Marital Status",
  yourAreSuccessfullyLoggedInPleasePickAPageFromMenu:
    "تم تسجيل الدخول بنجاح، من فضلك قم باختيار احدى الصفح من القائمة",

  emailNotConfirmed: "Email Not Confirmed",
  mobileNotConfirmed: "Mobile Not Confirmed",
  contactWithAdmin: "Contact with Admin",

  pleaseWait: "Please Wait...",
  youAreNotAuthorizedToViewThisPage: "You are not authorized to view this page",
  addRelevant: "Add Relevant",
  editRelevant: "Edit Relevant",
  selectRelevantType: "Select Relevant Type",
  pleaseSelectRelevantType: "Please Select Relevant Type",
  pleaseSelectRelevantTypeFirst: "Please Select Relevant Type First",
  selectMaritalStatus: "Select Marital Status",
  relevantName: "Relevant Name",
  cardNumber: "Card Number",
  photoFaceOne: "Photo Face One",
  photoFaceTwo: "Photo Face Two",
  faceOne: "Face One",
  faceTwo: "Face Two",
  addPatientCard: "Add Patient Card",
  editPatientCard: "Edit Patient Card",
  pleaseSelectInsuranceCompanyFirst: "Please Select Insurance Company First",
  cardNumberEnteredBefore: "Card Number Entered Before",
  reset: "Reset",
  no: "No",
  hasNoAccount: "Has no Account",
  pictures: "Pictures",
  beneficialRelevant: "Beneficial Relevant",
  selectRelevant: "Select Relevant",
  pleaseSelectRelevant: "Please Select Relevant",
  relevant: "Relevant",
  enterAValidDate: "Enter a valid date",
  updateSuccessful: "Update Successful",
  withRegisterationYouAgree: "With registeration you agree",
  termsAndCond: "Terms & Conditions",
  language: "Language",
  favorites: "Favorites",
  more: "More",
  onlyForPatient: "Only For Patient",
  signUp: "Sign up",
  alreadyHaveAccount: "Already have account ",
  loginNow: "login now",
  year: "year",
  month: "month",

  // Ahmed's translation

  // login

  enterEmail: "Enter your email",
  enterPass: "Enter password",
  notHaveAccount: "I don't have account. create",
  new: "new",
  signUpWithFace: "Sign up with facebook",
  signUpWithGoogle: "Sign up with google",
  selectLanguage: "Select Language",
  accountType: "Account Type",

  // notification

  notifications: "Notifications",
  turnOff: "Turn Off",
  deleteThisNot: "Delete this notification.",
  stopReceiveNot: "Stop receiving notifications like this.",

  // home

  welcome: "WELCOME",

  // drawer

  yourReservation: "Your Reservation",
  releventsReservation: "Relevent Reservation",
  relevents: "Relevents",
  intitutions: "Intitutions",
  logout: "Logout",

  // search

  search: "Search",
  searchByDrName: "SEARCH BY DOCOTR NAME",
  searchByInstitutionName: "SEARCH BY INSTITUTION NAME",
  searchByRaiologyCenterName: "SEARCH BY RADIOLOGY CENTER NAME",
  searchByPharmacyName: "SEARCH BY PHARMACY NAME",
  searchByIncubatorName: "SEARCH BY INCUBATOR NAME",
  searchByIntensiveCareName: "SEARCH BY INTESIVE CARE NAME",
  searchByDrNameOrUseFilter: "Search By doctor name or use filters",
  searchByInstitutionNameOrUseFilter:
    "Search by institution name or use filter",
  searchByIntensiveCareNameOrUseFilter:
    "Search by intensive care name or use filter",
  searchByRadiologyCenterNameOrUseFilter:
    "Search by radiology center name or use filter",
  searchByLaboratoryNameOrUseFilter: "Search by laboratory name or use filter",
  searchByIncubatorNameOrUseFilter: "Search by incubator name or use filter",
  searchByPharmacyNameOrUseFilter: "Search by pharmacy name or use filter",
  ourPharmacyHave: "Our Pharmacy Have ",

  // filter

  selectSpeciality: "Select speciality",
  selectGovernorate: "Select governorate",
  selectCity: "Select city",
  selectArea: "Select area",
  selectInsuranceCompany: "Select insurance company",
  selectDrGender: "Select dr gender",
  feesRange: "Fees Range",
  showResults: "SHOW RESULTS",
  institutionName: "Institution name",
  intensiveCareType: "Intensive care type",
  radiationName: "radiation name",
  radiationType: "radiation type",

  // profile

  ssnCard: "ssn card",
  medicalCard: "medical card",
  setting: "Setting",
  changePass: "Change Password",
  // reservation

  selectDataToShow: "Select data to show you available appointments",
  reservationFor: "Reservation For",
  reserve: "Reserve",
  now: "Now",
  selectFromAvailableTime: "Select from available time",
  selectCardId: "Select Card Id",
  selectMedicalCompany: "Select medical company",
  attachMedicalCard: "Attach your medical card here",
  front: "Front",
  goBack: "Back",
  saveAndBack: "Save and back",
  addNew: "Add new",
  enterRelevantName: "Enter your relevant name",
  selectRelevantRelationship: "Select relevant relatioship",
  fees: "FEES",
  succesMess:
    "You resevation done successfuly and you will receive confirmation message soon",
  selectInstitution: "Select Institution",
  selectBranch: "Select Branch",
  makeAppointment: "Make appointment",
  galary: "Galary",
  reviews: "Reviews",
  education: "Education",
  reserveAppointment: "RESERVE APPONTMENT",
  availableToday: "Available Today",
  doctorInfo: "Doctor Info",
  institutionInfo: "Institution Info",
  incubatorInfo: "Incubator Info",
  radiologyCenterInfo: "Radiology Center Info",
  branchContact: "Branch Contact",
  dailyFees: "daily fees",
  radiationInstruction: "Radiation Instructions",
  dateNotValid: "Date isn't valid",
  timeNotValid: "Time isn't valid",

  // footer

  reservations: "Reservation",

  // reset password

  resetMess: "How do you want to receivethe code to reset the password?",
  sendViaMobile: "Send code via mobile",
  sendViaEmail: "Send code via email",
  send: "SEND",
  enterCode: "Enter code you received",
  enterCodeHere: "Enter code here*",
  ok: "OK",
  enterNewPass: "Enter new password*",
  confirmNewPass: "Confirm new password*",
  change: "Change",

  // more Tab

  aboutUs: "About us",
  contactUs: "Contact us",
  typeMessHere: "Type your message here",
  hotline: "Hot line",

  // register

  enterFullName: "Enter your full name*",
  enterMobile: "Enter your mobile*",
  confirmPass: "Confirm password*",
  selectGender: "Select your gender",
  birthOfDate: "Birth of date",
  day: "Day",
  someThingWrongHappened: "Some Thing Wrong Happened",
  enterCodeYouRecieved: "Enter code you recieved",
  enterCodeHere: "Enter code here",
  confirm: "Confirm",
  codeIsRequired: "Code Is Required",
  sendCodeAgain: "Send Code Again",
  cannotVerifyUserEmailBecauseOfInValidCode:
    "Can not Verify User Email Because Of InValid Code",
  cannotVerifyUserEmailAsCodeHasBeenExpired:
    "Can not Verify User Email As Code Has Been Expired",
  cannotResetPasswordBecauseOfInValidCode:
    "Can not Reset Password Because Of InValid Code",
  cannotResetPasswordAsCodeHasBeenExpired:
    "Can not Reset Password As Code Has Been Expired",
  willRedirectInFiveSecondsToConfirmedIt:
    "will Redirect In Five Seconds To Confirmed It",
  exitFromApp: "Exit From App !",
  yes: "Yes",
  areYouSureYouWantToExistApp: "Are You Sure You Want To Exist App ?",
  emailSent: "Email Sent",
  howDoYouWantToRecieveTheCode: "How do you want to recieve the code",
  toResetYourPassword: "to reset your password?",
  resetPassword: "Reset Password",
  resetPasswordSuccessfully: "Reset Password Successfully",
  emailConfirmedSuccessfully: "Email Confirmed Successfully",
  registeredSuccessfully: "Registered Successfully",
  codeConfirmed: "Code Confirmed",
  logoutSuccessfully: "Logout Successfully",
  facebookLoginError: "Facebook Login Error",
  loginError: "Login Error",
  noResult: "No Result",
  eg: "EG",
  notAvialable: "Not Avialable",
  loadingMore: "Loading More",
  medical: "Medical",
  onName: "On",
  hand: "Hand",
  camera: "Camera",
  attachYourMedicalCardHere: "Attach your medical card here",
  showProfile: "Show Profile",
  thereAreNotSupportedInsuranceCompanyForThisInstitution:
    "There Are Not Supported Insurance Company For This Institution",
  info: "Info",
  thereIsNoInformation: "There Is No Information",
  update: "Update",
  noDoctorFavorite: "No Doctor Favorite",
  noInstitutionFavorite: "No Institution Favorite",
  noIncubationFavorite: "No Incubation Favorite",
  noIntensiveCareFavorite: "No Intensive Care Favorite",
  noLaboratoryFavorite: "No Laboratory Favorite",
  noRadiologyCenterFavorite: "No Radiology Center Favorite",
  noPharmacyFavorite: "No Pharmacy Favorite",

  prescription: "Prescription",
  edit: "Edit",
  relevants: "Relevants",
  relevantReservations: "Relevant Reservations",
  patientCards: "Patient Cards",
  deletedSuccessfully: "Deleted Successfully",
  deleted: "Deleted Successfully",
  redirectIn: "Redirect In",
  noInternet: "No Internet Connection",
  autoUpdate: "Auto-update",
  off: "Off",
  on: "On",
  wifiOnly: "Wifi Only",
  english: "English",
  arabic: "Arabic",
  checkYourConnection: "Check your network connection",
  reloadPage: "Reload Page",
  backOnline: "Back Online",
  selectLanguage: "select Language",

  //weekDays
  sat: "Sat",
  sun: "Sun",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",

  //months
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",

  //app
  inValidMobileNumber: "inValid Mobile Number",
  thisIsNotKuwaitNumber: "this isn't Kuwait Number",
  youDoNothaveAccount: "You don't have account?",
  clickHereToRegister: "Click here to Register",
  verificationCode: "Verification Code",
  verificationCodeSent: "The verification code has been sent to your mobile",
  pleaseEnterCodeBelow: "Please enter code below",
  didNotReceiveCode: "I didn't Receive Code",
  sendAgain: "Send Again",
  sendCode: "send Code",
  messageSent: "message Sent",
  enterYourArabicName: "Enter Your ArabicN ame",
  enterYourEnglishName: "Enter Your English Name",
  enterCount: "Enter Count",
  enterPaymentLink: "Enter Payment Link",
  enterAmount: "Enter Amount",
  howManyPersons: "How many persons ?",
  showCurrency: "K.D/PERSON",
  hasCoupon: "Has a Coupon ?",
  pleaseEnterCount: "please Enter Count",
  countMustBeLessThanIt: "count must be less than it",
  check: "Check",
  pleaseSelectTime: "please Select Time",
  acceptAndCheckout: "Accept And Checkout",
  places: "places",
  currency: "K.D",
  instruction1: "Child Age apove 3 years only allow to enter,and not refund amount for these reservation.",
  instruction2: "Reservation will be cancelled and non refund if not arrive at the time.",
  instruction3: "You can change time or cancel reservation with refund only before 24 hours.",
  youCanEditTimeBefore: "you Can Edit Time Before",
  bookTicket: "Book Ticket",
  tickets: "Tickets",
  ticket: "Ticket",
  news: "News",
  myTickets: "My Tickets",
  expired: "Expired",
  close: "close",
  cancelReservation: "cancel Reservation",
  pleaseEnterPaymentLink: "please Enter Payment Link",
  shareApplication: "Share Application",
  bookATicket: "book A Ticket",
  bookedTickets: "Booked Tickets",
  noTicketAvailable: "NO TICKET AVAIALBLE",
  bookNowAndEnjoyWithFamily: "Book Now and Enjoy with Family",
  makeSureWhatsAppInstalledOnYourDevice: "Make sure WhatsApp Installed on your Device",
  makeSureFacebookInstalledOnYourDevice: "Make sure Facebook Installed on your Device",
  makeSureInstgramInstalledOnYourDevice: "Make sure Instgram Installed on your Device",
  makeSureYoutubeInstalledOnYourDevice: "Make sure Youtube Installed on your Device",
  makeSureTwitterInstalledOnYourDevice: "Make sure Twitter Installed on your Device",
  today: "Today",
  at: "at",
  count: "Count",
  amount: "Amount",
  totalAmount: "Total Amount",
  editReservation: "Edit Reservation",
  //reservation
  reservationType: "Reservation Type",
  editReservationTypeData: "Edit Reservation Type Data",
  addReservationType: "Add Reservation Type",

  //discount
  discounts: "Discounts",
  addDiscount: "Add Discount",
  editDiscount: "Edit Discount",
  reservationSettings: "Reservation Settings",
  reservationTypeName: "Reservation Type Name",
  count: "Count",
  time: "Time",
  pleaseSelectReservationType: "Please Select Reservation Type",
  addReservationSetting: "Add Reservation Setting",
  pleaseEnterIntegerNumber: "Please Enter Integer Number",
  pleaseEnterNumberGreaterThanIt: "Please Enter Number greater than it",
  pleaseEnterCount: "Please Enter Count",
  pleaseEnterTime: "Please Enter Time",
  pleaseEnterPrice: "Please Enter Price",
  selectReservationType: "select Reservation Type",
  enterCount: "Enter Count",
  enterTime: "Enter Time",
  enterPrice: "Enter Price",
  editReservationSetting: "Edit Reservation Setting",
  rateDiscount: "Discount Rate",
  numberOfUse: "Number Of Use",
  rateDiscountRequired: "Discount Rate Required",
  discountRateShouldBeGreaterThan: "Discount Rate should be greater than 0",
  discountRateShouldBeLessThan: "Discount Rate should be less than or equal to 100",
  numberOfUseShouldBeGreaterThan: "Number of use should be greater than 0",
  numberOfUseIsRequired: "Number Of Use Required",
  //home
  social: "Social",
  socialMedia: "Social Media",
  facebook: "Facebook",
  instagram: "Instagram",
  twitter: "Twitter",
  visitOurPageOnFacebook: "Visit Our Page On Facebook",
  visitOurPageOnInstagram: "Visit Our Page On Instagram",
  visitOurPageOnTwitter: "Visit Our Page On Twitter",
  aboutUs: "About Us",
  show: "Show",
  reservationDate: "Reservation Date",
  action: "Action",
  payment: "Payment",
  attendence: "Attendence",
  cancel: "Cancel",
  pay: "Pay",
  paid: "Paid",
  attend: "Attend",
  attended: "Attended",
  canNotCancel: "Can ُt Cancel",
  create: "Create",
  successfullyAdded: "Added Successfully",
  successfullyEdited: "Edited Successfully",
  areYouSure: "Are you sure ?",
  Items: "Items",
  price: "Price",
  paymentLink: "Payment Link",
  returnMoney: "Return Money",
  noPaymentLink: "No Payment Link",
  returnedMoney: "Returned Money",
  reservationSchedule: "Reservation Schedule",
  vacationDate: "Vacation Date",
  officialVacation: "Official Vacation",
  addReservationSchedule: "Add Reservation Schedule",
  enterFromTime: "enter From Time",
  enterToTime: "enter To Time",
  enterVacationDate: "enter Vacation Date",
  roles: "Roles",
  createdDate: "Created Date",
  number: "Number",
  entries: "Entries",
  cannotChangeYourStatus: "can not Change Your Status",
  enterFromTime: "Enter From Time",
  enterToTime: "Enter To Time",
  enterVacationDate: "Enter Vacation Date",
  changedSuccessfully: "Changed Successfully",
  changed: "Changed Successfully",


  //paused time
  pausedTime: "Paused Time",
  pausedDate: "Paused Date",
  addPausedTime: "Add Paused Time",
  editPausedTime: "Edit Paused Time",
  enterPausedDate: "Enter Paused Date",
  smsSettings: "Sms Settings",
  smsTypeName: "Sms Type Name",
  text: "Text",
  selectSmsType: "select Sms Type",
  enterText: "enter Text",
  pleaseSelectSmsType: "please Select Sms Type",
  textRequired: "text Required",
  addSmsSetting: "add Sms Setting",

  //attended
  attendedReservation: "Attended Reservations",

  //cancelled
  cancelledReservation: "Cancelled Reservations",

  //unpaid
  unpaidReservation: "Unpaid Reservations",

  //reports
  filter: "Filter",
  filterReports: "Filter Reports",
  reports: "Reports",
  report: "Report",
  cancelled: "Cancelled",
  unpaid: "UnPaid",
  Export: "Export",
  export: "Export",
  editUser: "Edit User Data",
  notificationTypeName: "Notification Type Name",
  textEn: "english Text",
  textAr: "arabic Text",
  titleEn: "english Title",
  titleAr: "arabic Title",
  addNotificationSetting: "add Notification Setting",
  editNotificationSetting: "edit Notification Setting",
  textEnRequired: "english Text Required",
  textArRequired: "arabic Text Required",
  titleEnRequired: "english Title Required",
  titleArRequired: "arabic Title Required",
  enterTextEn: "enter English Text",
  enterTextAr: "enter Arabic Text",
  enterTitleEn: "enter English Title",
  enterTitleAr: "enter Arabic Title",
  selectNotificationType: "select Notification Type",
  pleaseSelectRole: "please Select Role",
  pleaseSelectNotificationType: "please Select Notification Type",
  unattended: "UnAttended",
  loadedSuccessfully: "loaded Successfully",
  reservationReport: "Reservation Report",
  downloadPdf: "download Pdf",
  image: "Image",
  complaints: "Complaints",
  thereIsNoNotifications: "There is no notifications",
  addNews: "add News",
  editNews: "edit News",
  ViewAll: "View All",
  Markallasread: "Mark All As Read",

  //aqua kids
  aquaKids: "Aqua Kids",
  signInToAquaKids: "Sign In To Aqua Kids",
  email: "Email",
  password: "Password",
  forgetPass: "Forget Password ?",
  login: "Login",
  signIn: "Sign In",
  ticketTypes: "Ticket Types",
  priceRequired: "Price Required",
  ticketOptions: "Ticket Options",
  showInput: "Show Input",
  requests: "Requests",
  request: "Request",
  approve: "Approve",
  selectUser: "select User",
  notSpecified: "Not Specified",
  paymentId: "Payment Id",
  result: "Result",
  referenceId: "Reference Id",
  reservationCode: "Reservation Code",
  transactionId: "Transaction Id",
  insertedDate: "Inserted Date",
  amount: "Amount",
  errorText: "Error Text",
  error: "Error",
  errorCode: "Error Code",
  transactionSuccess: "Transaction Success",
  transactionFailed: "Transaction Failed",
  selectToTime: "select To Time",
  selectFromTime: "select From Time",
  pleaseSelectToTime: "please Select To Time",
  pleaseSelectFromTime: "please Select From Time",
  requestStatus: "Request Status",
  ticketOptionList: "Ticket Option List",
  reApprove: "Re-Approve",
  paying: "Paying",
  aquaInfo1: "Aqua Kids Safari Adventure is an enteractive educational experience which is suitable for all family members (adults and children).",
  aquaInfo2: "It is a guided tour in four unique areas (Dessert, Rain Forest, Amazon and Aqua Area).",
  aquaInfo3: "Come and learn about some of our exotic animals and enjoy your time with our knowledgeable friendly team.",
  aquaInfo4: "please create an account or login and enjoy our adventures.",
  whatsapp: "WhatsApp",
  howToBookATicket: "How To Book A Ticket",
  downloadTheApp: "Download The App",
  selectTime: "Select Time",
  bookYourTicket: "Book Your Ticket",
  scanForDetails: "Scan For Details",
  galleries: "Galleries",
  gallery: "Gallery",
  addImage: "Add Image",
  editImage: "Edit Image",
  print: "Print",
  completed: "Completed",
  thereAreReservationsInThisDate: "There are reservations in this date",
  mobileVerified: "Mobile Verified",
  schoolTourRanges: "School Tour Ranges",
  schoolTourRange: "School Tour Range",
  toMustBeGreaterThanFrom: "To must be greater than from",
  theNumberMustBeGreaterThan1: "The number must be greater than 1",
  countFromRequired: "count From Required",
  countToRequired: "count To Required",
  countFrom: "count From",
  countTo: "count To",
  company: "Company",
  companies: "Companies",
  addRequest: "Add Request",
  enterReservationDate: "Enter Reservation Date",
  companyReservation: "Company Reservation",
  companyReservations: "Company Reservations",
  addCompanyReservation: "Add Company Reservation",
  editCompanyReservation: "Edit Company Reservation",
  pleaseEnterCorrectCount: "please Enter Correct Count",
  totalPrice: "Total Price",
  editRequest: "Edit Request",
  selectCompany: "Select Company",
  pleaseSelectCompany: "please Select Company",
  pleaseSelectTicketType: "please Select Ticket Type",
  selectTicketType: "select Ticket Type",
  ticketType: "Ticket Type",
  ReservationDetails: "Reservation Details",
  pleaseEnterUserName: "please Enter User Name",
  pleaseSelectTicketOption: "please Select Ticket Option",
  selectTicketOption: "select Ticket Option",
  timeNotAvailable:"Time Not Available",
  ticketOption:"ticket Option",
  companyReservationReport:"Company Reservation Report",
};
export default englishStrings;
