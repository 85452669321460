import React from 'react';
import { Pie } from "react-chartjs-2";

import './chart.css';

var options = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
  
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = Math.round((value / sum) * 100) + "%";
            return percentage;
          } 
        },
        color: "red"
      }
    },
    maintainAspectRatio: false,
  };
class PieChart extends React.Component {
  state={data: {
    labels: this.props.labels,
    datasets: [
      {
        label: "# of Votes",
        data: this.props.value,
        backgroundColor: [
          "rgba(80,205,200, 0.5)",
          "rgba(180,205,137, 0.5)",
          "rgba(255,199,0, 0.5)",
          "rgba(9,113,241, 0.5)",
          "rgba(241,65,108, 0.5)",
          "rgba(21,88,152, 0.5)",
          "rgba(33,100,255, 0.5)",
          "rgba(18,5,37, 0.5)",
          "rgba(55,99,100, 0.5)",
          "rgba(91,13,41, 0.5)",
          "rgba(25,65,160, 0.5)",
          "rgba(210,77,10, 0.5)",
        ],
        borderColor: [
          "rgba(80,205,200, 1)",
          "rgba(180,205,137, 1)",
          "rgba(255,199,0, 1)",
          "rgba(9,113,241, 1)",
          "rgba(241,65,108, 1)",
          "rgba(21,88,152, 1)",
          "rgba(33,100,255, 1)",
          "rgba(18,5,37, 1)",
          "rgba(55,99,100, 1)",
          "rgba(91,13,41, 1)",
          "rgba(25,65,160, 1)",
          "rgba(210,77,10, 1)",
        ],
        borderWidth: 2
      }
    ]
  }}
    render() {
        const {
            someProp
        } = this.props;

        return (
            <>
            <div className="cardPie">
                
            <Pie data={
              {
                labels: this.props.labels,
                datasets: [
                  {
                    label: "# of Votes",
                    data: this.props.value,
                    backgroundColor: [
                      "rgba(80,205,200, 0.5)",
                      "rgba(180,205,137, 0.5)",
                      "rgba(255,199,0, 0.5)",
                      "rgba(9,113,241, 0.5)",
                      "rgba(241,65,108, 0.5)",
                      "rgba(21,88,152, 0.5)",
                      "rgba(33,100,255, 0.5)",
                      "rgba(18,5,37, 0.5)",
                      "rgba(55,99,100, 0.5)",
                      "rgba(91,13,41, 0.5)",
                      "rgba(25,65,160, 0.5)",
                      "rgba(210,77,10, 0.5)",
                    ],
                    borderColor: [
                      "rgba(80,205,200, 1)",
                      "rgba(180,205,137, 1)",
                      "rgba(255,199,0, 1)",
                      "rgba(9,113,241, 1)",
                      "rgba(241,65,108, 1)",
                      "rgba(21,88,152, 1)",
                      "rgba(33,100,255, 1)",
                      "rgba(18,5,37, 1)",
                      "rgba(55,99,100, 1)",
                      "rgba(91,13,41, 1)",
                      "rgba(25,65,160, 1)",
                      "rgba(210,77,10, 1)",
                    ],
                    borderWidth: 2
                  }
                ]
              }
            } width={100} height={100} options={options} id={this.props.id} />
                </div> 
                  

            </>

        );
    };
}



export default PieChart;