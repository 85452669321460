export const UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS = 'UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS';
export const UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_FAILURE';

export const updateDisplayTypeInCancelledReservations = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS,
    displayType
});

export const updateDisplayTypeInCancelledReservationsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInCancelledReservationsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_CANCELLED_RESERVATIONS_FAILURE,
    payload: message
});