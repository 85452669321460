import React, { useEffect, useState, useRef } from "react";
import strings from '../../localizations/strings';
import { Button } from "react-bootstrap";
import { DisplayTypesInReportsConstant } from "../../constants/Reports/DisplayTypesInReportsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Loading from "../Shared/Loading/Loading";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { useDownloadExcel } from 'react-export-table-to-excel';
import "../../webfonts/Amiri/Amiri";
function CompanyReservationReport(props) {
  const tableRef = useRef(null);
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => {
      const reportData = {
        ...props.reportDataFilter,
        pageNumber: 1,
        pageSize: selected.value,
        search: search
      }
      props.getCompanyReservationReport(reportData);
    },
    handleTableExport = (selected) => { setExport(selected) };

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    const reportData = {
      ...props.reportDataFilter,
      pageNumber: 1,
      pageSize: props.companyReservationReportPageSize,
      search: selected.target.value,
    }
    props.getCompanyReservationReport(reportData);
  }
  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.companyReservationReportPageNumber) {
      const reportData = {
        ...props.reportDataFilter,
        pageNumber: pageNumber,
        pageSize: props.companyReservationReportPageSize,
        search: search
      }
      props.getCompanyReservationReport(reportData);
    }
  }

  function downloadPdf() {
    const doc = new jsPDF('l', 'pt', "a4");
    doc.setFont("Amiri-Regular")
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text((pageWidth / 2) - 50, 25, strings.companyReservationReport);
    autoTable(doc, {
      headStyles: { font: "Amiri-Regular" }, // For Arabic text in the table head
      bodyStyles: { font: "Amiri-Regular" }, // For Arabic text in the table body
      footStyles: { font: "Amiri-Regular" }, // For Arabic text in the table body
      html: '#reportTable',
      pageBreak: "avoid"
    })
    doc.save('CompanyReservationReport.pdf')
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'CompanyReservationReport',
    sheet: 'CompanyReservations'
  })

  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetCompanyReservationReport && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          {/* <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length, { label: strings.all, value: props.companyReservationReportTotalCount }]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.companyReservationReportPageSize)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.companyReservationReportTotalCount}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div> */}
          <div className="col-md-12">
            <Button variant="sm btn-primary m-1  animate__animated  animate__fadeInDown" onClick={downloadPdf}>
              {strings.downloadPdf}
            </Button>
            <Button variant="sm btn-primary m-1  animate__animated  animate__fadeInDown" onClick={onDownload}> Download as XLS </Button>
            <div className='table-responsive p-0'>
              <table id="reportTable" ref={tableRef} className='table align-middle text-center table-bordered border-bottom m-0'>
                <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                  <tr className="fw-bolder text-muted text-center align-middle bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                    <th className="min-w-125px px-0">{strings.company}</th>
                    <th className="min-w-125px px-0">{strings.userName}</th>
                    <th className="min-w-125px px-0">{strings.mobile}</th>
                    <th className="min-w-125px px-0">{strings.reservationType}</th>
                    <th className="min-w-125px px-0">{strings.code}</th>
                    <th className="min-w-125px px-0">{strings.fromTime}</th>
                    <th className="min-w-125px px-0">{strings.toTime}</th>
                    <th className="min-w-125px px-0">{strings.day}</th>
                    <th className="min-w-125px px-0">{strings.reservationDate}</th>
                    <th className="min-w-125px px-0">{strings.count}</th>
                    <th className="min-w-125px px-0">{strings.amount}</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {!props.isGetCompanyReservationReport
                    && props.companyReservationReport !== []
                    && props.companyReservationReport.length > 0
                    && props.companyReservationReport.map((companyReservation, index) => {
                      return <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{index + 1}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.companyNameAr : companyReservation.companyNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.userNameAr : companyReservation.userNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.phoneNumber}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{strings.getLanguage() === LanguagesConstant.AR ? companyReservation.reservationTypeNameAr : companyReservation.reservationTypeNameEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.reservationCode}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.fromTimeStr}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.toTimeStr}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>
                                {strings.getLanguage() === LanguagesConstant.EN ? companyReservation.dayNameEn : companyReservation.dayNameAr}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.reservationDateStr}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.count}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start m-auto flex-column'>
                              <h4>{companyReservation.totalAmount}</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })
                  }

                </tbody>
                <tfoot className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-start bg-light">
                    <th colspan="2" className="ps-4 min-w-125px px-0 text-start">{strings.unpaid}</th>
                    <th colspan="9" className="ps-4 min-w-125px px-0 bg-white text-start">{props.companyReservationReportTotalUnpaid}</th>
                  </tr>
                  <tr className="fw-bolder text-muted text-start bg-light border-top">
                    <th colspan="2" className="ps-4 min-w-125px px-0 text-start">{strings.paid}</th>
                    <th colspan="9" className="ps-4 min-w-125px px-0 bg-white text-start">{props.companyReservationReportTotalPaid}</th>
                  </tr>
                  <tr className="fw-bolder text-muted text-start bg-light">
                    <th colspan="2" className="ps-4 min-w-125px px-0 text-start">{strings.totalAmount}</th>
                    <th colspan="9" className="ps-4 min-w-125px px-0 bg-white text-start">{props.companyReservationReportTotalAmount}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {/* <div className="card-footer">
            {
              props.companyReservationReportTotalCount !== null
              && props.companyReservationReportTotalCount > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.companyReservationReportPageNumber - 1) * props.companyReservationReportPageSize) + 1} {strings.to} {(props.companyReservationReportPageNumber * props.companyReservationReportPageSize) > props.companyReservationReportTotalCount ? props.companyReservationReportTotalCount : (props.companyReservationReportPageNumber * props.companyReservationReportPageSize)} {strings.of} {props.companyReservationReportTotalCount} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.companyReservationReportPageNumber}
                    itemsCountPerPage={props.companyReservationReportPageSize}
                    totalItemsCount={props.companyReservationReportTotalCount}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div> */}

        </div>
      </div>

    </>
  );
}

export default CompanyReservationReport;