import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    EDIT_RESERVATION,
    editReservationSuccess,
    editReservationFailure
} from '../../actions/Reservations/editReservationActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function editReservationEpic(action$) {
    return action$.pipe(
        ofType(EDIT_RESERVATION),
        switchMap(action => {
           
            const ajaxConfigObj = {
                url: url + `Reservation/Edit`,
                method: 'POST',
                headers: {
                     'Authorization': token,
                    'content-type': 'application/json',
                },
                body: action.reservationViewModel,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
				map(data => data.response),retry())
        }),
        map(response =>{
            if (response.isSuccess) {
                return editReservationSuccess(response.data);
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                    && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return editReservationFailure(message);
            }
        }),
        catchError(error => of(editReservationFailure(strings.somethingWrongHappened)))
    )
}