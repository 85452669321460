import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_SMS_TYPES_IN_SMS_SETTINGS,
    updateSmsTypesInSmsSettingsSuccess,
    updateSmsTypesInSmsSettingsFailure
} from '../../actions/SmsSettings/updateSmsTypesInSmsSettingsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateSmsTypesInSmsSettingsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_SMS_TYPES_IN_SMS_SETTINGS),
        map(action => ({
            smsType: action.smsType
        })),
        map(response =>

            updateSmsTypesInSmsSettingsSuccess(response.smsType)

        ),
        catchError(error => of(updateSmsTypesInSmsSettingsFailure(strings.somethingWrongHappened)))
    )
}

