import {
    UPDATE_SMS_TYPES_IN_SMS_SETTINGS,
    UPDATE_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS,
    UPDATE_SMS_TYPES_IN_SMS_SETTINGS_FAILURE
} from '../../actions/SmsSettings/updateSmsTypesInSmsSettingsActions';

export function updateSmsTypesInSmsSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_SMS_TYPES_IN_SMS_SETTINGS:
            return {
                ...state,
                isUpdateSmsTypesInSmsSettings: true,
                updateSmsTypesInSmsSettingsError: null,
            };
        case UPDATE_SMS_TYPES_IN_SMS_SETTINGS_SUCCESS:
            return {
                ...state,
                isUpdateSmsTypesInSmsSettings: false,
                updateSmsTypesInSmsSettingsError: null,
                smsTypeInSmsSettings: action.payload.smsType,
            };
        case UPDATE_SMS_TYPES_IN_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdateSmsTypesInSmsSettings: false,
                updateSmsTypesInSmsSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}