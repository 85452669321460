import {
    GET_EDITED_SCHOOL_TOUR_RANGE,
    GET_EDITED_SCHOOL_TOUR_RANGE_SUCCESS,
    GET_EDITED_SCHOOL_TOUR_RANGE_FAILURE
} from '../../actions/SchoolTourRanges/getEditedSchoolTourRangeActions';

export function getEditedSchoolTourRangeReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_SCHOOL_TOUR_RANGE:
            return {
                ...state,
                isGetEditedSchoolTourRange: true,
                getEditedSchoolTourRangeError: null, 
            };
        case GET_EDITED_SCHOOL_TOUR_RANGE_SUCCESS:
            return {
                ...state,
                isGetEditedSchoolTourRange: false,
                getEditedSchoolTourRangeError: null,
                editedSchoolTourRange:state.schoolTourRanges.filter(x=>x.id == action.payload.schoolTourRangeId )[0],

            };
        case GET_EDITED_SCHOOL_TOUR_RANGE_FAILURE:
            return {
                ...state,
                isGetEditedSchoolTourRange: false,
                getEditedSchoolTourRangeError: action.payload.message,
            };
        default:
            return state;
}}