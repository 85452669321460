export const CREATE_NEW_ROLE_SCREEN = 'CREATE_NEW_ROLE_SCREEN';
export const CREATE_NEW_ROLE_SCREEN_SUCCESS = 'CREATE_NEW_ROLE_SCREEN_SUCCESS';
export const CREATE_NEW_ROLE_SCREEN_FAILURE = 'CREATE_NEW_ROLE_SCREEN_FAILURE';

export const createNewRoleScreen = (roleScreenData) => ({
    type: CREATE_NEW_ROLE_SCREEN,
    roleScreenData
});

export const createNewRoleScreenSuccess = (data) => ({
    type: CREATE_NEW_ROLE_SCREEN_SUCCESS,
    payload: { data }
});

export const createNewRoleScreenFailure = (message) => ({
    type: CREATE_NEW_ROLE_SCREEN_FAILURE,
    payload: { message }
});