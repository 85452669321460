export const GET_RESERVATION_TYPES_IN_TICKET_OPTIONS = 'GET_RESERVATION_TYPES_IN_TICKET_OPTIONS';
export const GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS = 'GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS';
export const GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE = 'GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE';

export const getReservationTypesInTicketOptions = (reservationTypeId = null) => ({
    type: GET_RESERVATION_TYPES_IN_TICKET_OPTIONS,
    reservationTypeId
}); 

export const getReservationTypesInTicketOptionsSuccess = (reservationTypes) => ({
    type: GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS,
    payload: { reservationTypes }
});

export const getReservationTypesInTicketOptionsFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE,
    payload: { message }
});