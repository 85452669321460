import {
    GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES,
    GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_SUCCESS,
    GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_FAILURE
} from '../../actions/ReservationSchedules/getReservationSchedulesInReservationSchedulesActions';

export function getReservationSchedulesInReservationSchedulesReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES:
            return {
                ...state,
                isGetReservationSchedulesInReservationSchedules: true,
                getReservationSchedulesInReservationSchedulesError: null,
            };
        case GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_SUCCESS:
            console.log(action.payload.reservationSchedules)
            return {
                ...state,
                isGetReservationSchedulesInReservationSchedules: false,
                getReservationSchedulesInReservationSchedulesError: null,
                reservationSchedulesInReservationSchedules: action.payload.reservationSchedules.data,
                reservationSchedulesPageNumberInReservationSchedules: action.payload.pageNumber,
                reservationSchedulesPageSizeInReservationSchedules: action.payload.pageSize,
                reservationSchedulesTotalCountInReservationSchedules: action.payload.reservationSchedules.total,
            };
        case GET_RESERVATION_SCHEDULES_IN_RESERVATION_SCHEDULES_FAILURE:
            return {
                ...state,
                isGetReservationSchedulesInReservationSchedules: false,
                getReservationSchedulesInReservationSchedulesError: action.payload.message,
            };
        default:
            return state;
}}