export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_FAILURE';

export const updateDisplayTypeInReservationSchedules = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES,
    displayType
});

}
export const updateDisplayTypeInReservationSchedulesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInReservationSchedulesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATION_SCHEDULES_FAILURE,
    payload: message
});