export const GET_RESERVATION_REPORT = 'GET_RESERVATION_REPORT';
export const GET_RESERVATION_REPORT_SUCCESS = 'GET_RESERVATION_REPORT_SUCCESS';
export const GET_RESERVATION_REPORT_FAILURE = 'GET_RESERVATION_REPORT_FAILURE';

export const getReservationReport = (reportData) => ({
    type: GET_RESERVATION_REPORT,
    reportData
});

export const getReservationReportSuccess = (reservationReport, reportData) => ({
    type: GET_RESERVATION_REPORT_SUCCESS,
    payload: { reservationReport, reportData }
});

export const getReservationReportFailure = (message) => ({
    type: GET_RESERVATION_REPORT_FAILURE,
    payload: { message }
});