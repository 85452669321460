import {
    UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS,
    UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_FAILURE
} from '../../../src/actions/SmsSettings/updateDisplayTypeInSmsSettingsActions';

export function updateDisplayTypeInSmsSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS:
            return {
                ...state,
                isUpdatingDisplayTypeInSmsSettings: true,
                updateDisplayTypeErrorInSmsSettings: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInSmsSettings: false,
                displayTypeInSmsSettings: action.payload.displayType,
                updateDisplayTypeErrorInSmsSettings: null,

                isAddNewSmsSetting: false,
                addNewSmsSettingError: null,
                addNewSmsSettingResult: null,

                isDeletingSmsSetting: false,
                deleteSmsSettingError: null,
                deleteSmsSettingResult: null,

                isEditingSmsSetting: false,
                editSmsSettingError: null,
                editSmsSettingResult: null,

                isUpdatingStatusSmsSetting: false,
                updateStatusSmsSettingError: null,
                updateStatusSmsSettingResult: null,

                ischeckingSmsSettingNameEnAvailabilityInSmsSettings: false,
                isSmsSettingNameEnAvailableInSmsSettings: true,
                checkSmsSettingNameEnAvailabilityErrorInSmsSettings: null,

                ischeckingSmsSettingNameArAvailabilityInSmsSettings: false,
                isSmsSettingNameArAvailableInSmsSettings: true,
                checkSmsSettingNameArAvailabilityErrorInSmsSettings: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInSmsSettings: false,
                updateDisplayTypeErrorInSmsSettings: action.payload
            };
        default:
            return state;
    }
}