export const GET_EDITED_COMPANY_RESERVATION = 'GET_EDITED_COMPANY_RESERVATION';
export const GET_EDITED_COMPANY_RESERVATION_SUCCESS = 'GET_EDITED_COMPANY_RESERVATION_SUCCESS';
export const GET_EDITED_COMPANY_RESERVATION_FAILURE = 'GET_EDITED_COMPANY_RESERVATION_FAILURE';

export const getEditedCompanyReservation = (companyReservationId) => ({
    type: GET_EDITED_COMPANY_RESERVATION,
    companyReservationId
});

export const getEditedCompanyReservationSuccess = (companyReservationId) => ({
    type: GET_EDITED_COMPANY_RESERVATION_SUCCESS,
    payload: { companyReservationId }
});

export const getEditedCompanyReservationFailure = (message) => ({
    type: GET_EDITED_COMPANY_RESERVATION_FAILURE,
    payload: { message }
});
