import {
    CREATE_NEW_ROLE_USER,
    CREATE_NEW_ROLE_USER_SUCCESS,
    CREATE_NEW_ROLE_USER_FAILURE
} from '../../../actions/Roles/RoleUsers/createNewRoleUserActions';

export function createNewRoleUserReducer(state, action) {
    switch (action.type) {
        case CREATE_NEW_ROLE_USER:
            return {
                ...state,
                isCreateNewRoleUser: true,
                createNewRoleUserError: null,
            };
        case CREATE_NEW_ROLE_USER_SUCCESS:
            return {
                ...state,
                isCreateNewRoleUser: false,
                createNewRoleUserError: null,
                createNewRoleUserResult: action.payload.data,
            };
        case CREATE_NEW_ROLE_USER_FAILURE:
            return {
                ...state,
                isCreateNewRoleUser: false,
                createNewRoleUserError: action.payload.message,
            };
        default:
            return state;
}}