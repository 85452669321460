import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInReservationTypeSettingsConstant } from "../../constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant";
import strings from "../../localizations/strings";
import Button from "@restart/ui/esm/Button";
import "../../Css/StyleEn.css";
import Loading from "../Shared/Loading/Loading";
function ReservationTypeSetting(props) {
  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getReservationTypeSettingsInReservationTypeSettings(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getReservationTypeSettingsInReservationTypeSettings();
  }, []);

  useEffect(() => {
    if (
      props.deleteReservationTypeSettingResult !== null &&
      props.deleteReservationTypeSettingResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteReservationTypeSettingResult.message, "success").then(result => {
        props.getReservationTypeSettingsInReservationTypeSettings(props.reservationTypeSettingsPageNumberInReservationTypeSettings, props.reservationTypeSettingsPageSizeInReservationTypeSettings,search);
      });
    }
  }, [props.deleteReservationTypeSettingResult]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.reservationTypeSettingsPageNumberInReservationTypeSettings) {
        props.getReservationTypeSettingsInReservationTypeSettings(pageNumber, props.reservationTypeSettingsPageSizeInReservationTypeSettings, search);
    }
}

function handleinputSearch(selected) {
  console.log(selected.target.value);
    setSearch(selected.target.value);
    props.getReservationTypeSettingsInReservationTypeSettings(1, props.reservationTypeSettingsPageSizeInReservationTypeSettings, selected.target.value)
}
  return (
    <>
      <div className="customer-data mt-5">
      { props.isGetReservationTypeSettingsInReservationTypeSettings && ( <Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
          <TableHeader
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.reservationTypeSettingsTotalCountInReservationTypeSettings}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.reservationTypeSettingsPageSizeInReservationTypeSettings)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.reservationTypeSettingsTotalCountInReservationTypeSettings}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
            {/* <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">
                {strings.reservationTypeSetting}
              </span>
            </h3>
            <div className="pt-4">
              <Button
                className="NewB2 col-1"
                onClick={() =>
                  props.updateDisplayTypeInReservationTypeSettings(
                    DisplayTypesInReservationTypeSettingsConstant.ADD
                  )
                }
              >
                {strings.new}
              </Button>
            </div> */}
          </div>

          <div className="table-responsive p-0">
            {/* <div className="container1 scroll"> */}
              <table className="table align-middle text-center table-bordered border-bottom m-0">
                <thead
                  className="tableHeader"
                  style={{ position: "sticky", width: "100%" }}
                >
                  <tr className="fw-bolder text-muted text-center bg-light">
                    <th className="ps-4 min-w-125px px-0">{strings.reservationTypeName}</th>
                    <th className="min-w-125px px-0">{strings.count}</th>
                    <th className="min-w-125px px-0">{strings.time}</th>
                    <th className="min-w-125px px-0">{strings.price}</th>
                    <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                      {strings.action}
                    </th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                {
                  props.isGetReservationTypeSettingsInReservationTypeSettings
                  && (<Loading />)
                }
                  {
                  // !props.isGetReservationTypeSettingsInReservationTypeSettings &&
                  props.reservationTypeSettingsInReservationTypeSettings !== [] &&
                          props.reservationTypeSettingsInReservationTypeSettings.length > 0 &&
                          props.reservationTypeSettingsInReservationTypeSettings.map((reservationTypeSetting, index) => {
                            return (
                              <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{strings.getLanguage() === LanguagesConstant.AR ? reservationTypeSetting.reservationTypeNameAr : reservationTypeSetting.reservationTypeNameEn}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{reservationTypeSetting.count}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{reservationTypeSetting.time}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start m-auto flex-column">
                          <h4>{reservationTypeSetting.price}</h4>
                        </div>
                      </div>
                    </td>
                    <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                      <div className="d-flex justify-content-center">
                        <div
                          className="actions"
                          style={{ backgroundColor: "#e4e6ef" }}
                        >
                          <div className="action1">
                           <a  onClick={() => {
                              props.getEditedReservationTypeSetting(reservationTypeSetting.id); 
                             props.updateDisplayTypeInReservationTypeSettings(
                              DisplayTypesInReservationTypeSettingsConstant.EDIT
                            )
                            }}>
                           
                              <i className="fas fa-edit edit"></i>
                            </a>
                          
                          </div>
                          <div className="action2">
                            <a
                               onClick={() => {Delete(props,reservationTypeSetting.id)}}> 
                              <i className="fas fa-trash delete"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
                          })} 
                </tbody>
              </table>
            {/* </div> */}
          </div>

          <div className="card-footer">
        {
            props.reservationTypeSettingsTotalCountInReservationTypeSettings !== null
            && props.reservationTypeSettingsTotalCountInReservationTypeSettings > 0
            && (
                <div className="d-flex flex-stack flex-wrap ">
                    <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.reservationTypeSettingsPageNumberInReservationTypeSettings - 1) * props.reservationTypeSettingsPageSizeInReservationTypeSettings) + 1} {strings.to} {(props.reservationTypeSettingsPageNumberInReservationTypeSettings * props.reservationTypeSettingsPageSizeInReservationTypeSettings) > props.reservationTypeSettingsTotalCountInReservationTypeSettings ? props.reservationTypeSettingsTotalCountInReservationTypeSettings : (props.reservationTypeSettingsPageNumberInReservationTypeSettings * props.reservationTypeSettingsPageSizeInReservationTypeSettings)} {strings.of} {props.reservationTypeSettingsTotalCountInReservationTypeSettings} {strings.entries}</div>
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activePage={props.reservationTypeSettingsPageNumberInReservationTypeSettings}
                        itemsCountPerPage={props.reservationTypeSettingsPageSizeInReservationTypeSettings}
                        totalItemsCount={props.reservationTypeSettingsTotalCountInReservationTypeSettings}
                        onChange={handlePageButtonChange}
                    />
                </div>
            )}
            </div>
        </div>
      </div>
    </>
  );
}
export default ReservationTypeSetting;
