import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    GET_USERS_IN_RESERVATIONS,
    getUsersInReservationsSuccess,
    getUsersInReservationsFailure
} from '../../actions/Reservations/getUsersInReservationsActions';

import { 
    getReservationTypesInReservations
} from '../../actions/Reservations/getReservationTypesInReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getUsersInReservationsEpic(action$) {
    return action$.pipe(
        ofType(GET_USERS_IN_RESERVATIONS),
        switchMap(action => {
            
            const ajaxConfigObj = {
                url:  url + `Reservation/GetUsers`,
                method: 'Post',
                headers: {
                	'Authorization':token,
                    //'content-type': 'application/json'
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
            map(data => ({
                response: data.response,
            })))
        }),
        mergeMap(data => {
            if (data.response.isSuccess) {
                const users=data.response.data.map(item=>({
                    label:strings.getLanguage() === LanguagesConstant.AR ? item.nameAr : item.nameEn,
                    value:item.id
                }))
                return of(getUsersInReservationsSuccess(users),getReservationTypesInReservations());
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                 && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return of(getUsersInReservationsFailure(message));
            }
        }),
        catchError(error =>  of(getUsersInReservationsFailure(strings.somethingWrongHappened)))
    )
}

