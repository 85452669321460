import {
    DELETE_TICKET_TYPE,
    DELETE_TICKET_TYPE_SUCCESS,
    DELETE_TICKET_TYPE_FAILURE
} from '../../actions/TicketTypes/deleteTicketTypeActions';

import { DisplayTypesInTicketTypesConstant } from '../../constants/TicketTypes/DisplayTypesInTicketTypesConstant';

export function deleteTicketTypeReducer(state, action) {
    switch (action.type) {
        case DELETE_TICKET_TYPE:
            return {
                ...state,
                isDeletingTicketType: true,
                deleteTicketTypeError: null,
            };
        case DELETE_TICKET_TYPE_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingTicketType: false,
                deleteTicketTypeError: null,
                deleteTicketTypeResult: action.payload.data,
                //displayTypeInTicketTypes: DisplayTypesInTicketTypesConstant.LIST,
            };
        case DELETE_TICKET_TYPE_FAILURE:
            return {
                ...state,
                isDeletingTicketType: false,
                deleteTicketTypeError: action.payload.message,
            };
        default:
            return state;
}}