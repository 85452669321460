import React, { useEffect, useState, forwardRef } from "react";
import strings from '../../localizations/strings';
import EmptyImg from '../../images/emptyImg.jpg';
import { DisplayTypesInReportsConstant } from "../../constants/Reports/DisplayTypesInReportsConstant";
import { ReportFiltersConstant } from "../../constants/Reports/ReportFiltersConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select';
import Loading from "../Shared/Loading/Loading";
const moment = require('moment/min/moment.min');

function CompanyReservationFilter(props) {

  const formikRef = React.useRef(null);
  const [companyError, setCompanyError] = useState(null);
  const [fromDateError, setFromDateError] = React.useState(null);
  const [toDateError, setToDateError] = React.useState(null);
  const [attendenceReportFilter, setAttendenceReportFilter] = React.useState(ReportFiltersConstant.ALL);
  const [paymentReportFilter, setPaymentReportFilter] = React.useState(ReportFiltersConstant.ALL);
  const [cancelledReportFilter, setCancelledReportFilter] = React.useState(ReportFiltersConstant.ALL);
  useEffect(() => {
    props.getCompaniesInCompanyReservations();
  }, [])
  useEffect(() => {
    if ((props.companyReservationReport == null || props.companyReservationReport == [] || props.companyReservationReport.length == 0)
      && props.reportDataFilter != null
    ) {
      Swal.fire(
        strings.loadedSuccessfully,
        strings.noResult,
        'success'
      ).then(result => {

      }
      )
    }

    if (props.companyReservationReport !== null && props.companyReservationReport !== [] && props.companyReservationReport.length > 0 && props.reportDataFilter != null) {
      props.updateDisplayTypeInReports(DisplayTypesInReportsConstant.RES_COM_LIST)
    }
  }, [props.companyReservationReport]);

  function handleFromDateChange(value) {
    if (!moment(value).isValid()) {
      setFromDateError(strings.dateNotValid);
    } else {
      setFromDateError(null);
      formikRef.current.setFieldValue("fromDate", value);
      formikRef.current.setFieldValue("toDate", null);
    }
  }
  function handleToDateChange(value) {
    if (!moment(value).isValid()) {
      setToDateError(strings.dateNotValid);
    } else {
      setToDateError(null);
      formikRef.current.setFieldValue("toDate", value);
    }
  }
  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.from} *`}
      name="fromDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));
  const EndDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.to} *`}
      name="toDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));
  function handleCompanyChanges(selectedOption) {
    if (selectedOption == null) {
      setCompanyError(strings.pleaseSelectCompany)
    } else {
      setCompanyError(null)
    }
    props.updateCompaniesInCompanyReservations(selectedOption);
  }

  return (
    <>
      {props.isGetCompanyReservationReport && (<Loading />)}
      <div className="customer-data cus2 mt-5">
        <div className="card animate__animated  animate__backInLeft">
          <div className='card-header border-0 d-flex align-items-center'>
            <h3 className='card-title col-9'>
              <span className="card-label fw-bolder text-dark">
                {strings.companyReservation}</span>
            </h3>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <Formik
              innerRef={formikRef}
              initialValues={{
                fromDate: new Date(),
                toDate: new Date()
              }}
              validationSchema={Yup.object().shape({

              })}
              onSubmit={(fields) => {
                // if (props.companyInCompanyReservations == null) {
                //   setCompanyError(strings.pleaseSelectCompany)
                // }
                // else {
                const report = {
                  //   reportFilters:reportFilter,
                  fromDate: new Date(fields.fromDate).toLocaleDateString(),
                  toDate: new Date(fields.toDate).toLocaleDateString(),
                  pageNumber: 1,
                  pageSize: 10,
                  attendenceReportFilter: attendenceReportFilter,
                  paymentReportFilter: paymentReportFilter,
                  cancelledReportFilter: cancelledReportFilter

                };

                if (props.companyInCompanyReservations != null) {
                  report.companyId = props.companyInCompanyReservations.value;
                }

                props.getCompanyReservationReport(report);
                //  }

              }}

              render={({
                errors,
                touched,
                isValidating,
                values,
                setFieldTouched,
                setFieldValue,
                handleBlur,
                handleChange
              }) => (
                <Form id="kt_account_profile_details_form" className="form1">
                  <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.company}
                      </label>
                      <div className="col-lg-10 col-sm-12 fv-row">
                        <Select
                          name="company"
                          options={props.companiesInCompanyReservations}
                          placeholder={strings.selectCompany}
                          value={props.companyInCompanyReservations}
                          onChange={handleCompanyChanges}
                          isSearchable
                          isClearable
                          isLoading={props.isGetCompaniesInCompanyReservations}
                        // className={companyError != null ? "is-invalid" : ""}
                        />
                        {/* {companyError && (
                            <div className="invalid-feedback block">
                              {companyError}
                            </div>
                          )} */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.from}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        {/* <input type="text"
                            name="reportNameAr"
                            className={"form-control form-control1 form-control-lg form-control-solid"} /> */}
                        <DatePicker placeholderText={strings.from}
                          customInput={<StartDateInput />}
                          locale={strings.getLanguage() === LanguagesConstant.EN ?
                            "en" : "ar"}
                          selected={values.fromDate}
                          onChange={handleFromDateChange}
                          dateFormat="dd/MM/yyyy"
                          className={fromDateError !== null ? 'red-border' : ''} />
                        {
                          fromDateError && (
                            <div className="invalid-feedback d-block">
                              {fromDateError}
                            </div>
                          )
                        }

                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label className="col-lg-6 col-form-label required">
                        {strings.to}
                      </label>
                      <div className="col-lg-10 col-sm-12">
                        <DatePicker
                          placeholderText={strings.to}
                          customInput={<EndDateInput />}
                          locale={strings.getLanguage() === LanguagesConstant.EN ?
                            "en" : "ar"}
                          selected={values.toDate}
                          onChange={handleToDateChange}
                          minDate={values.fromDate == null ? new Date() : values.fromDate}
                          dateFormat="dd/MM/yyyy"
                          className={toDateError !== null ? 'red-border' : ''} />
                        {
                          toDateError && (
                            <div className="invalid-feedback d-block">
                              {toDateError}
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <Form>
                      <label className="col-lg-6 col-form-label">
                        {strings.attendence}
                      </label>
                      <div className="row d-flex mx-0">
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="all"
                                checked={attendenceReportFilter == ReportFiltersConstant.ALL} onChange={(e) => setAttendenceReportFilter(ReportFiltersConstant.ALL)}
                              />
                              <label class="form-check-label" for="all">
                                {strings.all}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="yes"
                                checked={attendenceReportFilter == ReportFiltersConstant.YES} onChange={(e) => setAttendenceReportFilter(ReportFiltersConstant.YES)}
                              />
                              <label class="form-check-label" for="yes">
                                {strings.yes}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="no"
                                checked={attendenceReportFilter == ReportFiltersConstant.NO} onChange={(e) => setAttendenceReportFilter(ReportFiltersConstant.NO)}
                              />
                              <label class="form-check-label" for="no">
                                {strings.no}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form className="my-4">
                      <label className="col-lg-6 col-form-label">
                        {strings.payment}
                      </label>
                      <div className="row d-flex mx-0">
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="all"
                                checked={paymentReportFilter == ReportFiltersConstant.ALL} onChange={(e) => setPaymentReportFilter(ReportFiltersConstant.ALL)}
                              />
                              <label class="form-check-label" for="all">
                                {strings.all}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="yes"
                                checked={paymentReportFilter == ReportFiltersConstant.YES} onChange={(e) => setPaymentReportFilter(ReportFiltersConstant.YES)}
                              />
                              <label class="form-check-label" for="yes">
                                {strings.yes}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="no"
                                checked={paymentReportFilter == ReportFiltersConstant.NO} onChange={(e) => setPaymentReportFilter(ReportFiltersConstant.NO)}
                              />
                              <label class="form-check-label" for="no">
                                {strings.no}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form>
                      <label className="col-lg-6 col-form-label">
                        {strings.cancelled}
                      </label>
                      <div className="row d-flex mx-0">
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="all"
                                checked={cancelledReportFilter == ReportFiltersConstant.ALL} onChange={(e) => setCancelledReportFilter(ReportFiltersConstant.ALL)}
                              />
                              <label class="form-check-label" for="all">
                                {strings.all}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="yes"
                                checked={cancelledReportFilter == ReportFiltersConstant.YES} onChange={(e) => setCancelledReportFilter(ReportFiltersConstant.YES)}
                              />
                              <label class="form-check-label" for="yes">
                                {strings.yes}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 mb-3">
                          <label className="col-lg-4 col-form-label"></label>
                          <div className="col-lg-10 col-sm-12 fv-row">
                            <div class="form-check">
                              <input name="filters" class="form-check-input" type="radio" id="no"
                                checked={cancelledReportFilter == ReportFiltersConstant.NO} onChange={(e) => setCancelledReportFilter(ReportFiltersConstant.NO)}
                              />
                              <label class="form-check-label" for="no">
                                {strings.no}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="card-footer d-flex justify-content-start" style={{ backgroundColor: "white" }}>
                    <div>
                      <button
                        type="submit"
                        className="btn fs-6"
                        style={{
                          backgroundColor: "#009ef7",
                          borderColor: "#009ef7",
                          color: "white",
                          borderRadius: "0.75rem",
                        }}
                        id="kt_account_profile_details_submit"
                      //	onClick={done}
                      >
                        {strings.filter}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default CompanyReservationFilter;