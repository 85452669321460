import $ from "jquery";
export function HideSection(contain,btn){
    $(document).mouseup(function (e) {
        var container = $(`#${contain}`);
        var containerbtn= $(`#${btn}`);
       
        if(!container.is(e.target) && container.has(e.target).length === 0 &&!containerbtn.is(e.target) && containerbtn.has(e.target).length === 0 ) {
            container.fadeOut();
            containerbtn.removeClass('active')
        }
      });
}
export function ShowSection(contain,btn){
    $(`#${btn}`).click(
        function(){
         $(`#${btn}`).toggleClass('active')
          $(`#${contain}`).fadeToggle();
        }
   )
}
export function HideSectionclick(contain,btn){
        $(`#${contain}`).fadeOut();
            $(`#${btn}`).removeClass('active')  
}