import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS,
    updateDisplayTypeInSmsSettingsSuccess,
    updateDisplayTypeInSmsSettingsFailure
} from '../../../src/actions/SmsSettings/updateDisplayTypeInSmsSettingsActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInSmsSettingsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInSmsSettingsSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInSmsSettingsFailure(strings.somethingWrongHappened)))
    )
}

