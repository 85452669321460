export const EDIT_RESERVATION = 'EDIT_RESERVATION';
export const EDIT_RESERVATION_SUCCESS = 'EDIT_RESERVATION_SUCCESS';
export const EDIT_RESERVATION_FAILURE = 'EDIT_RESERVATION_FAILURE';

export const editReservation = (reservationViewModel) => ({
    type: EDIT_RESERVATION,
    reservationViewModel
});

export const editReservationSuccess = (reservationViewModel) => ({
    type: EDIT_RESERVATION_SUCCESS,
    payload: { reservationViewModel }
});

export const editReservationFailure = (message) => ({
    type: EDIT_RESERVATION_FAILURE,
    payload: message
});