import {
    GET_EDITED_SMS_SETTING,
    GET_EDITED_SMS_SETTING_SUCCESS,
    GET_EDITED_SMS_SETTING_FAILURE
} from '../../actions/SmsSettings/getEditedSmsSettingActions';

export function getEditedSmsSettingReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_SMS_SETTING:
            return {
                ...state,
                isGetEditedSmsSetting: true,
                getEditedSmsSettingError: null, 
            };
        case GET_EDITED_SMS_SETTING_SUCCESS:
            return {
                ...state,
                isGetEditedSmsSetting: false,
                getEditedSmsSettingError: null,
                editedSmsSettingInSmsSettings:state.smsSettingsInSmsSettings.filter(x=> x.id == action.payload.smsSettingId)[0],
            };
        case GET_EDITED_SMS_SETTING_FAILURE:
            return {
                ...state,
                isGetEditedSmsSetting: false,
                getEditedSmsSettingError: action.payload.message,
            };
        default:
            return state;
}}