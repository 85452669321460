export const UPDATE_DISPLAY_TYPE_IN_REPORTS = 'UPDATE_DISPLAY_TYPE_IN_REPORTS';
export const UPDATE_DISPLAY_TYPE_IN_REPORTS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_REPORTS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_REPORTS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_REPORTS_FAILURE';

export const updateDisplayTypeInReports = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_REPORTS,
    displayType
});

}
export const updateDisplayTypeInReportsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_REPORTS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInReportsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_REPORTS_FAILURE,
    payload: message
});