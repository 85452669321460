export const CHECK_MOBILE_AVAILABILITY_IN_USERS = 'CHECK_MOBILE_AVAILABILITY_IN_USERS';
export const CHECK_MOBILE_AVAILABILITY_IN_USERS_SUCCESS = 'CHECK_MOBILE_AVAILABILITY_IN_USERS_SUCCESS';
export const CHECK_MOBILE_AVAILABILITY_IN_USERS_FAILURE = 'CHECK_MOBILE_AVAILABILITY_IN_USERS_FAILURE';

export const checkMobileAvailabilityInUsers = (mobile,userId=null) => ({
    type: CHECK_MOBILE_AVAILABILITY_IN_USERS,
    mobile,
    userId
});

export const checkMobileAvailabilityInUsersSuccess = (isAvailable) => ({
    type: CHECK_MOBILE_AVAILABILITY_IN_USERS_SUCCESS,
    payload: { isAvailable }
});

export const checkMobileAvailabilityInUsersFailure = (message) => ({
    type: CHECK_MOBILE_AVAILABILITY_IN_USERS_FAILURE,
    payload: message
});