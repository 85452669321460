export const DELETE_SMS_SETTING = 'DELETE_SMS_SETTING';
export const DELETE_SMS_SETTING_SUCCESS = 'DELETE_SMS_SETTING_SUCCESS';
export const DELETE_SMS_SETTING_FAILURE = 'DELETE_SMS_SETTING_FAILURE';

export const deleteSmsSetting = (smsSettingId) => ({
    type: DELETE_SMS_SETTING,
    smsSettingId
});

export const deleteSmsSettingSuccess = (data) => ({
    type: DELETE_SMS_SETTING_SUCCESS,
    payload: { data }
});

export const deleteSmsSettingFailure = (message) => ({
    type: DELETE_SMS_SETTING_FAILURE,
    payload: { message }
});