export const GET_EDITED_ROLE = 'GET_EDITED_ROLE';
export const GET_EDITED_ROLE_SUCCESS = 'GET_EDITED_ROLE_SUCCESS';
export const GET_EDITED_ROLE_FAILURE = 'GET_EDITED_ROLE_FAILURE';

export const getEditedRole = (roleId) => ({
    type: GET_EDITED_ROLE,
    roleId
});

export const getEditedRoleSuccess = (roleId) => ({
    type: GET_EDITED_ROLE_SUCCESS,
    payload: { roleId }
});

export const getEditedRoleFailure = (message) => ({
    type: GET_EDITED_ROLE_FAILURE,
    payload: { message }
});