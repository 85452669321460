import React,{useState,useEffect} from "react";
import { DisplayTypesInReservationTypeSettingsConstant } from "../../../src/constants/ReservationTypeSettings/DisplayTypesInReservationTypeSettingsConstant";
import ReservationTypeSetting from './ReservationTypeSetting';
import Edit from './Edit';
import Add from './Add';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function ReservationTypeSettings(props){

    return (
        <>
                  <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInReservationTypeSettings === DisplayTypesInReservationTypeSettingsConstant.LIST ? strings.reservationSettings:
                        props.displayTypeInReservationTypeSettings === DisplayTypesInReservationTypeSettingsConstant.ADD ? strings.create:
                        props.displayTypeInReservationTypeSettings === DisplayTypesInReservationTypeSettingsConstant.EDIT ? strings.edit:""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInReservationTypeSettings  !== DisplayTypesInReservationTypeSettingsConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInReservationTypeSettings(DisplayTypesInReservationTypeSettingsConstant.LIST)}>
                                    {strings.reservationSettings}
                                </Button> :null}

                            {props.displayTypeInReservationTypeSettings  !== DisplayTypesInReservationTypeSettingsConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInReservationTypeSettings(DisplayTypesInReservationTypeSettingsConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInReservationTypeSettings  === DisplayTypesInReservationTypeSettingsConstant.LIST && 
                             (<ReservationTypeSetting {...props} />)}
                            {props.displayTypeInReservationTypeSettings  === DisplayTypesInReservationTypeSettingsConstant.ADD &&
                              (<Add {...props} />)}
                            {props.displayTypeInReservationTypeSettings  === DisplayTypesInReservationTypeSettingsConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
        </>
    );
}
export default ReservationTypeSettings