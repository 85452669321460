export const CHECK_EMAIL_AVAILABILITY_IN_USERS = 'CHECK_EMAIL_AVAILABILITY_IN_USERS';
export const CHECK_EMAIL_AVAILABILITY_IN_USERS_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_IN_USERS_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_IN_USERS_FAILURE = 'CHECK_EMAIL_AVAILABILITY_IN_USERS_FAILURE';

export const checkEmailAvailabilityInUsers = (email,userId=null) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_USERS,
    email,
    userId
});

export const checkEmailAvailabilityInUsersSuccess = (isAvailable) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_USERS_SUCCESS,
    payload: { isAvailable }
});

export const checkEmailAvailabilityInUsersFailure = (message) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_USERS_FAILURE,
    payload: message
});