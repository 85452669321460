import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    EDIT_GALLERY,
    editGallerySuccess,
    editGalleryFailure
} from '../../actions/Galleries/editGalleryActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function editGalleryEpic(action$) {
    return action$.pipe(
        ofType(EDIT_GALLERY),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `Gallery/Edit`,
                method: 'Post',
                 headers: {
                     'Authorization':token ,
                   //'content-type': 'application/json'
                 },
                body:action.galleryData,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                return of(editGallerySuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(editGalleryFailure(message));
            }
        }),
        catchError(error =>  of(editGalleryFailure(strings.somethingWrongHappened)))
    )
}

