export const CREATE_NEW_ROLE_USER = 'CREATE_NEW_ROLE_USER';
export const CREATE_NEW_ROLE_USER_SUCCESS = 'CREATE_NEW_ROLE_USER_SUCCESS';
export const CREATE_NEW_ROLE_USER_FAILURE = 'CREATE_NEW_ROLE_USER_FAILURE';

export const createNewRoleUser = (userData) => ({
    type: CREATE_NEW_ROLE_USER,
    userData
});

export const createNewRoleUserSuccess = (data) => ({
    type: CREATE_NEW_ROLE_USER_SUCCESS,
    payload: { data }
});

export const createNewRoleUserFailure = (message) => ({
    type: CREATE_NEW_ROLE_USER_FAILURE,
    payload: { message }
});