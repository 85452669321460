import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS,
    updateReservationTypesInTicketOptionsSuccess,
    updateReservationTypesInTicketOptionsFailure
} from '../../actions/TicketOptions/updateReservationTypesInTicketOptionsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateReservationTypesInTicketOptionsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS),
        map(action => ({
            reservationType:action.reservationType
        })),
        map(response => 
            updateReservationTypesInTicketOptionsSuccess(response.reservationType)
            ),
        catchError(error =>  of(updateReservationTypesInTicketOptionsFailure(strings.somethingWrongHappened)))
    )
}

