import {
    GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS,
    GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getTicketTypesInCompanyReservationsActions';

export function getTicketTypesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGetTicketTypesInCompanyReservations: true,
                getTicketTypesInCompanyReservationsError: null,
            };
        case GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetTicketTypesInCompanyReservations: false,
                getTicketTypesInCompanyReservationsError: null,
                ticketTypesInCompanyReservations: action.payload.ticketTypes,
                //reservationTypeInCompanyReservations: action.payload.ticketTypes[0],
            };
        case GET_TICKET_TYPES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetTicketTypesInCompanyReservations: false,
                getTicketTypesInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}