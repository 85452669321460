import {
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPES,
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_SUCCESS,
    GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_FAILURE
} from '../../actions/ReservationTypes/getReservationTypesInReservationTypesActions';

export function getReservationTypesInReservationTypesReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPES:
            return {
                ...state,
                isGetReservationTypesInReservationTypes: true,
                getReservationTypesInReservationTypesError: null,
            };
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_SUCCESS:
            console.log(action.payload.reservationTypes)
            return {
                ...state,
                isGetReservationTypesInReservationTypes: false,
                getReservationTypesInReservationTypesError: null,
                reservationTypesInReservationTypes: action.payload.reservationTypes.data,
                 reservationTypesPageNumberInReservationTypes: action.payload.pageNumber,
                reservationTypesPageSizeInReservationTypes: action.payload.pageSize,
                reservationTypesTotalCountInReservationTypes: action.payload.reservationTypes.total,
            };
        case GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_FAILURE:
            return {
                ...state,
                isGetReservationTypesInReservationTypes: false,
                getReservationTypesInReservationTypesError: action.payload.message,
            };
        default:
            return state;
}}