import {
    DELETE_SCHOOL_TOUR_RANGE,
    DELETE_SCHOOL_TOUR_RANGE_SUCCESS,
    DELETE_SCHOOL_TOUR_RANGE_FAILURE
} from '../../actions/SchoolTourRanges/deleteSchoolTourRangeActions';

export function deleteSchoolTourRangeReducer(state, action) {
    switch (action.type) {
        case DELETE_SCHOOL_TOUR_RANGE:
            return {
                ...state,
                isDeletingSchoolTourRange: true,
                deleteSchoolTourRangeError: null,
            };
        case DELETE_SCHOOL_TOUR_RANGE_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingSchoolTourRange: false,
                deleteSchoolTourRangeError: null,
                deleteSchoolTourRangeResult: action.payload.data,
            };
        case DELETE_SCHOOL_TOUR_RANGE_FAILURE:
            return {
                ...state,
                isDeletingSchoolTourRange: false,
                deleteSchoolTourRangeError: action.payload.message,
            };
        default:
            return state;
}}