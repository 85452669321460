import React from 'react';
import './Notification.css'
import strings from '../../../localizations/strings';
import { Link } from 'react-router-dom';
import { HideSectionclick } from '../Fun/HideAndShowSection';
import { LanguagesConstant } from '../../../constants/LanguagesConstant'
import notification from '../../../954549.png';
class Notification extends React.Component {
  //state={allNotifications:this.props.Notifications}
  handleredirect = (id, isRead) => {
    HideSectionclick('NotificatioContent', 'NotificatioIcon');
    if (!isRead) {
      this.props.changeNotificationIsRead(id)
    }

  }
  render() {
    return (
      <>
        <div id="NotificatioContent" className="menu menu-sub  w-250px  w-md-350px w-lg-375px">
          <div className="d-flex flex-column bgi-no-repeat rounded-top bg-image-notification">

            <div className="d-flex justify-content-between align-items-center px-8 mt-3 mb-3">
              <h3 className="text-white fw-bold p-0 m-0">
                {strings.notifications}
              </h3>
              {
                this.props.notificationsUser !== []
                && this.props.notificationsUser.length > 0
                && (
                  <a className="allread" onClick={() => this.props.markAllAsRead()}>{strings.Markallasread}</a>
                )}
            </div>
          </div>
          <div>
            <div className="scroll-y mh-325px my-1 px-1">
              {
                this.props.notificationsUser !== []
                && this.props.notificationsUser.map(ele => {
                  return (
                    <>
                      <a key={ele.id} onClick={() => this.handleredirect(ele.id, ele.isRead)} className={`d-flex flex-column noti ${!ele.isRead ? 'unred ' : ''}my-1 py-1 px-0 mx-0`} style={{ cursor: "pointer" }}>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-35px me-2 d-md-block ">
                            <span className="symbol-label bg-transparent">
                              <span className="svg-icon svg-icon-2 svg-icon-primary">
                                <img src={notification} alt="" width={40} height={40} />
                              </span>
                            </span>
                          </div>

                          <div className="mb-0 me-1">
                            <a className="fs-6 text-gray-800 text-hover-primary fw-bolder" style={{ cursor: "pointer" }}>
                              {strings.getLanguage() === LanguagesConstant.AR ? ele.titleAr : ele.titleEn}
                            </a>
                            <div className="text-gray-400 fs-7 text-break">
                              {strings.getLanguage() === LanguagesConstant.AR ? ele.textAr : ele.textEn}
                            </div>
                           <span className="badge badge-light fs-8">{ele.insertedDateStr}</span>
                          </div>
                        </div>
                      </a>
                    </>
                  )
                })}
              {
                (this.props.notificationsUser === []
                  || this.props.notificationsUser === null
                  || this.props.notificationsUser.length === 0)
                && (
                  <div className="d-flex align-items-center">

                    <div className="mb-0 me-2">
                      <div className="text-gray-400 fs-4">
                        {strings.thereIsNoNotifications}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            {
              this.props.notificationsUser !== []
              && this.props.notificationsUser.length > 0
              && (
                <div className="py-1 text-center border-top">
                  <Link
                    to={`/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/Reservations`}
                    className="btn btn-color-gray-600 menu-hover-title-primary"
                  >
                    {strings.ViewAll}
                  </Link>
                </div>
              )}
          </div>
        </div>
      </>
    );
  };
}
export default Notification;