export const UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION = 'UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION';
export const UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE = 'UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE';

export const updateReservationTimesInEditReservation = (selectedTime) => ({
    type: UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION,
    selectedTime
});

export const updateReservationTimesInEditReservationSuccess = (selectedTime) => ({
    type: UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_SUCCESS,
    payload: { selectedTime }
});

export const updateReservationTimesInEditReservationFailure = (message) => ({
    type: UPDATE_RESERVATION_TIMES_IN_EDIT_RESERVATION_FAILURE,
    payload: message
});