import {
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE
} from '../../../actions/Roles/Roles/deleteRoleActions';

export function deleteRoleReducer(state, action) {
    switch (action.type) {
        case DELETE_ROLE:
            return {
                ...state,
                isDeletingRole: true,
                deleteRoleError: null,
            };
        case DELETE_ROLE_SUCCESS:
            return {
                ...state,
                isDeletingRole: false,
                deleteRoleError: null,
                deleteRoleResult: action.payload.data,
            };
        case DELETE_ROLE_FAILURE:
            return {
                ...state,
                isDeletingRole: false,
                deleteRoleError: action.payload.message,
            };
        default:
            return state;
}}