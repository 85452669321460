export const CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS = 'CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS';
export const CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_SUCCESS = 'CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_SUCCESS';
export const CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_FAILURE = 'CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_FAILURE';

export const checkEmailAvailabilityInRoleUsers = (email,userId=null) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS,
    email,
    userId
});

export const checkEmailAvailabilityInRoleUsersSuccess = (isAvailable) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_SUCCESS,
    payload: { isAvailable }
});

export const checkEmailAvailabilityInRoleUsersFailure = (message) => ({
    type: CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS_FAILURE,
    payload: message
});