export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPES = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPES';
export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_SUCCESS = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_SUCCESS';
export const GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_FAILURE = 'GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_FAILURE';

export const getReservationTypesInReservationTypes = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get reservationTypes action')
return({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPES,
    pageNumber,
    pageSize,
    search
}); 
}
export const getReservationTypesInReservationTypesSuccess = (reservationTypes,pageNumber,pageSize) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_SUCCESS,
    payload: { reservationTypes ,pageNumber,pageSize}
});

export const getReservationTypesInReservationTypesFailure = (message) => ({
    type: GET_RESERVATION_TYPES_IN_RESERVATION_TYPES_FAILURE,
    payload: { message }
});