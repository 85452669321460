export const UPDATE_DISPLAY_TYPE_IN_RESERVATIONS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATIONS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_FAILURE';

export const updateDisplayTypeInReservations = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATIONS,
    displayType
});

export const updateDisplayTypeInReservationsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInReservationsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_RESERVATIONS_FAILURE,
    payload: message
});