export const DELETE_RESERVATION_TYPE_SETTING = 'DELETE_RESERVATION_TYPE_SETTING';
export const DELETE_RESERVATION_TYPE_SETTING_SUCCESS = 'DELETE_RESERVATION_TYPE_SETTING_SUCCESS';
export const DELETE_RESERVATION_TYPE_SETTING_FAILURE = 'DELETE_RESERVATION_TYPE_SETTING_FAILURE';

export const deleteReservationTypeSetting = (reservationTypeSettingId) => ({
    type: DELETE_RESERVATION_TYPE_SETTING,
    reservationTypeSettingId
});

export const deleteReservationTypeSettingSuccess = (data) => ({
    type: DELETE_RESERVATION_TYPE_SETTING_SUCCESS,
    payload: { data }
});

export const deleteReservationTypeSettingFailure = (message) => ({
    type: DELETE_RESERVATION_TYPE_SETTING_FAILURE,
    payload: { message }
});