export const DELETE_ROLE_USER = 'DELETE_ROLE_USER';
export const DELETE_ROLE_USER_SUCCESS = 'DELETE_ROLE_USER_SUCCESS';
export const DELETE_ROLE_USER_FAILURE = 'DELETE_ROLE_USER_FAILURE';

export const deleteRoleUser = (roleUserId) => ({
    type: DELETE_ROLE_USER,
    roleUserId
});

export const deleteRoleUserSuccess = (data) => ({
    type: DELETE_ROLE_USER_SUCCESS,
    payload: { data }
});

export const deleteRoleUserFailure = (message) => ({
    type: DELETE_ROLE_USER_FAILURE,
    payload: { message }
});