export const GET_RESERVATION_DETAILS = 'GET_RESERVATION_DETAILS';
export const GET_RESERVATION_DETAILS_SUCCESS = 'GET_RESERVATION_DETAILS_SUCCESS';
export const GET_RESERVATION_DETAILS_FAILURE = 'GET_RESERVATION_DETAILS_FAILURE';

export const getReservationDetails = (reservationId) => ({
    type: GET_RESERVATION_DETAILS,
    reservationId
});

export const getReservationDetailsSuccess = (reservationViewModel) => ({
    type: GET_RESERVATION_DETAILS_SUCCESS,
    payload: { reservationViewModel }
});

export const getReservationDetailsFailure = (message) => ({
    type: GET_RESERVATION_DETAILS_FAILURE,
    payload: message
});