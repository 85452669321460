import React, { useEffect,useState } from "react";
import strings from '../../localizations/strings';
import EmptyImg from '../../images/emptyImg.jpg';
import { DisplayTypesInDiscountsConstant } from "../../constants/Discounts/DisplayTypesInDiscountsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Loading from "../Shared/Loading/Loading";
function Discount(props) {
  const [Export, setExport] = useState(null),
  [search, setSearch] = useState(null),
  handleTableLength = (selected) => { props.getDiscountsInDiscounts(1, selected.value, search); },
  handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getDiscountsInDiscounts();
  }, [])

  useEffect(() => {
    if (
      props.deleteDiscountResult !== null &&
      props.deleteDiscountResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deletedSuccessfully}`, props.deleteDiscountResult.message, "success").then(result => {
        props.getDiscountsInDiscounts(props.discountsPageNumberInDiscounts, props.discountsPageSizeInDiscounts,search);
      });
    }
  }, [props.deleteDiscountResult]);

  useEffect(() => {
    if (
      props.deleteDiscountError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteDiscountError, "error");
    }
  }, [props.deleteDiscountError]);

  useEffect(() => {
    if (props.updateStatusDiscountResult !== null && props.updateStatusDiscountResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusDiscountResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusDiscountResult]);

  useEffect(() => {
    if (props.updateStatusDiscountError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusDiscountError, "error");
    }
  }, [props.updateStatusDiscountError]);
 
function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getDiscountsInDiscounts(1, props.discountsPageSizeInDiscounts, selected.target.value)
}
  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.discountsPageNumberInDiscounts) {
      props.getDiscountsInDiscounts(pageNumber, props.discountsPageSizeInDiscounts,search);
    }
  }

  return (
    <>
      <div className="customer-data mt-5">
      {
                             props.isGetDiscountsInDiscounts
                             && (<Loading />)
                         }
        <div className="card animate__animated  animate__backInLeft">
        <div className="card-header d-flex flex-column flex-column-fluid">
          <TableHeader
                 searchPlaceholder={strings.search}
                 ExportPlaceholder={strings.export}
                 Length={[...TableStat.Length,{label:strings.all,value:props.discountsTotalCountInDiscounts}]}
                 Export={TableStat.Export}
                 selectedLength={TableStat.Length.filter(x => x.value == props.discountsPageSizeInDiscounts)[0]}
                 selectedExport={Export}
                 cardname={strings.show}
                 rowsnumber={props.discountsTotalCountInDiscounts}
                 handleTableLength={handleTableLength}
                 handleTableExport={handleTableExport}
                 handleinputSearch={handleinputSearch}
             />
          </div>
                  <div className='table-responsive p-0'>
                      <table className='table align-middle text-center table-bordered border-bottom m-0'>
                        <thead className="tableHeader"style={{ position: "sticky", width: "100%" }}>
                          <tr className='fw-bolder text-muted text-center bg-light'>
                            <th className="min-w-125px px-0">{strings.code}</th>
                            <th className="min-w-125px px-0">{strings.rateDiscount}</th>
                            <th className="min-w-125px px-0">{strings.from}</th>
                            <th className="min-w-125px px-0">{strings.to}</th>
                            <th className="min-w-125px px-0">{strings.numberOfUse}</th>
                            {/* <th className="status2 text-center">{strings.details}</th> */}
                            <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                              {strings.action}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                         
                          {  props.discountsInDiscounts !== []
                            && props.discountsInDiscounts.length > 0
                            && props.discountsInDiscounts.map((discount, index) => {
                              return <tr key={index}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{discount.code}</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{discount.rate}</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{discount.fromDateStr}</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>
                                        {discount.toDateStr}
                                      </h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start m-auto flex-column'>
                                      <h4>{discount.numberOfUsing == null ? 0 : discount.numberOfUsing}</h4>
                                    </div>
                                  </div>
                                </td>
                                
                                <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                                  <div className='d-flex justify-content-center'>
                                    <div className='actions'  style={{ backgroundColor: "#e4e6ef" }}>
                                      <div className="action1">
                                        <a
                                          onClick={() => {
                                            props.getEditedDiscount(discount.id);
                                            props.updateDisplayTypeInDiscounts(DisplayTypesInDiscountsConstant.EDIT);
                                          }}
                                        >
                                          <i className="fas fa-edit edit"></i>
                                        </a>
                                      </div>
                                      <div className="action2">
                                        <a
                                          onClick={() => { deleteRow(props, discount.id); }}
                                        >
                                          <i className="fas fa-trash delete"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            })
                          }

                        </tbody>
                      </table>
                  </div>
                    <div className="card-footer">
                      {
                        props.discountsTotalCountInDiscounts !== null
                        && props.discountsTotalCountInDiscounts > 0
                        && (
                          <div className="d-flex flex-stack flex-wrap justify-content-between">
                            <div className="fs-6 fw-bold text-gray-700"style={{padding:"6px 20px 6px 6px"}}>{strings.showing} {((props.discountsPageNumberInDiscounts - 1) * props.discountsPageSizeInDiscounts) + 1} {strings.to} {(props.discountsPageNumberInDiscounts * props.discountsPageSizeInDiscounts) > props.discountsTotalCountInDiscounts ? props.discountsTotalCountInDiscounts : (props.discountsPageNumberInDiscounts * props.discountsPageSizeInDiscounts)} {strings.of} {props.discountsTotalCountInDiscounts} {strings.entries}</div>
                            <Pagination
                              innerClass="pagination1"
                              itemClass="page-item"
                              linkClass="page-link"
                              activeClass="active"
                              activePage={props.discountsPageNumberInDiscounts}
                              itemsCountPerPage={props.discountsPageSizeInDiscounts}
                              totalItemsCount={props.discountsTotalCountInDiscounts}
                              onChange={handlePageButtonChange}
                            />
                          </div>
                        )}
                    </div>
                  
              </div>
            </div>
         
    </>
  );
}

export default Discount;