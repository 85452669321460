export const UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES = 'UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES';
export const UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_FAILURE';

export const updateDisplayTypeInTicketTypes = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES,
    displayType
});

}
export const updateDisplayTypeInTicketTypesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInTicketTypesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_TYPES_FAILURE,
    payload: message
});