import {
    GET_EDITED_RESERVATION_TYPE,
    GET_EDITED_RESERVATION_TYPE_SUCCESS,
    GET_EDITED_RESERVATION_TYPE_FAILURE
} from '../../actions/ReservationTypes/getEditedReservationTypeActions';

import { DisplayTypesInReservationTypesConstant } from '../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant';

export function getEditedReservationTypeReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_RESERVATION_TYPE:
            return {
                ...state,
                isGetEditedReservationType: true,
                getEditedReservationTypeError: null, 
            };
        case GET_EDITED_RESERVATION_TYPE_SUCCESS:
            console.log('get edited reservationType reducer');
            return {
                ...state,
                isGetEditedReservationType: false,
                getEditedReservationTypeError: null,
                editedReservationTypeInReservationTypes:state.reservationTypesInReservationTypes.filter(x=>x.id == action.payload.reservationTypeId )[0],
                // displayTypeInReservationTypes: DisplayTypesInReservationTypesConstant.EDIT,

                // isDeletingReservationType: false,
                // deleteReservationTypeError: null,
                // deleteReservationTypeResult:null,

            };
        case GET_EDITED_RESERVATION_TYPE_FAILURE:
            console.log('get edited reservationType fail reducer');
            return {
                ...state,
                isGetEditedReservationType: false,
                getEditedReservationTypeError: action.payload.message,
            };
        default:
            return state;
}}