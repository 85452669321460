import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE
} from '../../actions/Account/loginUserActions';

export function loginUserReducer(state, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                isLoggedUser: true,
                getLoggedUserError: null,
            };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoggedUser: false,
                getLoggedUserError: null,
                isUserLoggedIn:true,
                isInCheckIsUserLoggedIn: false,
                loggedUserResult: action.payload.data,
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoggedUser: false,
                getLoggedUserError: action.payload.message,
            };
        default:
            return state;
}}