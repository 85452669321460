export const UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES = 'UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES';
export const UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_FAILURE';

export const updateDisplayTypeInSchoolTourRanges = (displayType) =>
{
    return({
    type: UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES,
    displayType
});

}
export const updateDisplayTypeInSchoolTourRangesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInSchoolTourRangesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_SCHOOL_TOUR_RANGES_FAILURE,
    payload: message
});