import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    EDIT_ROLE_USER,
    editRoleUserSuccess,
    editRoleUserFailure
} from '../../../actions/Roles/RoleUsers/editRoleUserActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function editRoleUserEpic(action$) {
    return action$.pipe(
        ofType(EDIT_ROLE_USER),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `RoleUser/Edit`,
                method: 'Post',
                body:action.userData,
                headers: {
                    'Authorization':token ,
                    'content-type': 'application/json'
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                //console.log(response.data);
                return of(editRoleUserSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(editRoleUserFailure(message));
            }
        }),
        catchError(error =>  of(editRoleUserFailure(strings.somethingWrongHappened)))
    )
}

