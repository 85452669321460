import Swal from "sweetalert2";
import strings from '../../localizations/strings';

function Delete (props,id) {
    return(
    Swal.fire({
        title: `${strings.areYouSure}`,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${strings.yes}`,
        cancelButtonText: `${strings.cancel}`,
        preConfirm: () => { props.deletePausedTime(id)},
      })
    );
}
export default Delete

 