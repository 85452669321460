import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';


import {
    GET_RESERVATION_TIMES_IN_RESERVATIONS,
    getReservationTimesInReservationsSuccess,
    getReservationTimesInReservationsFailure
} from '../../actions/Reservations/getReservationTimesInReservationsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function getReservationTimesInReservationsEpic(action$) {
    return action$.pipe(
        ofType(GET_RESERVATION_TIMES_IN_RESERVATIONS),
        switchMap(action => {

            const ajaxConfigObj = {
                url: url + `Reservation/GetReservationTimes?reservationTypeSettingId=${action.reservationTypeSettingId}&selectedDate=${action.selectedDate}`,
                method: 'POST',
                headers: {
                    'Authorization':token,
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => ({
                    response: data.response,
                    selectedDate: action.selectedDate
                })), retry())
        }),
        map(data => {
            if (data.response.isSuccess) {
                const times=data.response.data.map((item,index)=>({
                    label:strings.getLanguage() === LanguagesConstant.AR ? item.displayTextAr : item.displayTextEn,
                    value:index,
                    reservationScheduleId:item.reservationScheduleId,
                    fromTime:item.fromTime,
                    availablePlaces:item.availablePlaces
                }))
                return getReservationTimesInReservationsSuccess(times, data.selectedDate);
            } else {
                let message = data.response.message;
                if (data.response.localizedMessageKey !== ""
                    && strings[data.response.localizedMessageKey] !== undefined) {
                    message = strings[data.response.localizedMessageKey];
                }
                return getReservationTimesInReservationsFailure(message);
            }
        }),
        catchError(error => of(getReservationTimesInReservationsFailure
            (strings.somethingWrongHappened)))
    )
}