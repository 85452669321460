export const GET_COMPANY_RESERVATION_DETAILS = 'GET_COMPANY_RESERVATION_DETAILS';
export const GET_COMPANY_RESERVATION_DETAILS_SUCCESS = 'GET_COMPANY_RESERVATION_DETAILS_SUCCESS';
export const GET_COMPANY_RESERVATION_DETAILS_FAILURE = 'GET_COMPANY_RESERVATION_DETAILS_FAILURE';

export const getCompanyReservationDetails = (companyReservationId) => ({
    type: GET_COMPANY_RESERVATION_DETAILS,
    companyReservationId
});

export const getCompanyReservationDetailsSuccess = (companyReservationViewModel) => ({
    type: GET_COMPANY_RESERVATION_DETAILS_SUCCESS,
    payload: { companyReservationViewModel }
});

export const getCompanyReservationDetailsFailure = (message) => ({
    type: GET_COMPANY_RESERVATION_DETAILS_FAILURE,
    payload: message
});