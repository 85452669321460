import {
    UPDATE_RESERVATION_TYPES_IN_RESERVATIONS,
    UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS,
    UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateReservationTypesInReservationsActions';

export function updateReservationTypesInReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TYPES_IN_RESERVATIONS:
            return {
                ...state,
                isUpdateReservationTypesInReservations: true,
                updateReservationTypesInReservationsError: null,
            };
        case UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdateReservationTypesInReservations: false,
                updateReservationTypesInReservationsError: null,
                reservationTypeInReservations: action.payload.reservationType,
            };
        case UPDATE_RESERVATION_TYPES_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdateReservationTypesInReservations: false,
                updateReservationTypesInReservationsError: action.payload.message,
            };
        default:
            return state;
}}