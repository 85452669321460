import React, { useState, useEffect } from "react";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../../Shared/toolbar";
import strings from '../../../localizations/strings';
import RoleScreensList from './RoleScreen'
import Add from './Add';
import Edit from './Edit'
import { DisplayTypesInRoleScreensConstant } from "../../../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant";
function RoleScreens(props) {

    return (
        <div className="content py-0 d-flex flex-column flex-column-fluid mx-0 px-0">

            <div className="post d-flex flex-column-fluid" id="kt_post" >
                <div id="kt_content_container" className="kt_content_container container-xxl px-0">
                    <>
                        {props.displayTypeInRoleScreens === DisplayTypesInRoleScreensConstant.LIST &&
                            (<RoleScreensList {...props} />)}
                        {props.displayTypeInRoleScreens === DisplayTypesInRoleScreensConstant.ADD &&
                            (<Add {...props} />)}
                        {props.displayTypeInRoleScreens === DisplayTypesInRoleScreensConstant.Edit &&
                            (<Edit {...props} />)}
                    </>

                </div>
            </div>
        </div>

    );
}
export default RoleScreens
