import {
    GET_GALLERIES_IN_GALLERIES,
    GET_GALLERIES_IN_GALLERIES_SUCCESS,
    GET_GALLERIES_IN_GALLERIES_FAILURE
} from '../../actions/Galleries/getGalleriesInGalleriesActions';

export function getGalleriesInGalleriesReducer(state, action) {
    switch (action.type) {
        case GET_GALLERIES_IN_GALLERIES:
            return {
                ...state,
                isGetGalleriesInGalleries: true,
                getGalleriesInGalleriesError: null,
            };
        case GET_GALLERIES_IN_GALLERIES_SUCCESS:
            console.log(action.payload.galleries)
            return {
                ...state,
                isGetGalleriesInGalleries: false,
                getGalleriesInGalleriesError: null,
                galleriesInGalleries: action.payload.galleries.data,
                 galleriesPageNumberInGalleries: action.payload.pageNumber,
                galleriesPageSizeInGalleries: action.payload.pageSize,
                galleriesTotalCountInGalleries: action.payload.galleries.total,
            };
        case GET_GALLERIES_IN_GALLERIES_FAILURE:
            return {
                ...state,
                isGetGalleriesInGalleries: false,
                getGalleriesInGalleriesError: action.payload.message,
            };
        default:
            return state;
}}