import React from "react";
import strings from "../../localizations/strings";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import logo from "../../images/ic_logo.png";
import moment from 'moment';

export class Print extends React.PureComponent {
    render() {
      return (
        <div className="row d-flex justify-content-center text-center">
          <img src={logo} className="row w-50 d-block"/>
          <div className="row text-center">
          <h2>
              { this.props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? this.props.reservationDetailsInReservations.reservationTypeNameAr : this.props.reservationDetailsInReservations.reservationTypeNameEn) : ""}
            </h2>
            <h2>
              {this.props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? this.props.reservationDetailsInReservations.userNameAr : this.props.reservationDetailsInReservations.userNameEn) : ""}
            </h2>
            <h2>
              {this.props.reservationDetailsInReservations !== null ? this.props.reservationDetailsInReservations.reservationCode : ""}
            </h2>
            <h2>
              {this.props.reservationDetailsInReservations !== null ? this.props.reservationDetailsInReservations.phoneNumber : ""}
            </h2>
            <h2>
              { this.props.reservationDetailsInReservations !== null ? moment(this.props.reservationDetailsInReservations.reservationDate).format("DD MMM yyyy") : ""}
            </h2>
            <h2>
              { this.props.reservationDetailsInReservations !== null ? moment(this.props.reservationDetailsInReservations.fromTime).locale(strings.getLanguage() === LanguagesConstant.AR ? "ar-sa" :"en").format("hh:mm A") : ""}
            </h2>
            <h2>
              {this.props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? this.props.reservationDetailsInReservations.ticketTypeListAr?.join('-') : this.props.reservationDetailsInReservations.ticketTypeListEn?.join('-')) : ""}
            </h2>
            <h2>
            {this.props.reservationDetailsInReservations !== null ? (strings.getLanguage() === LanguagesConstant.AR ? this.props.reservationDetailsInReservations.ticketOptionListAr?.join('-') : this.props.reservationDetailsInReservations.ticketOptionListEn?.join('-')) : ""}
            </h2>
          </div>
        </div>
      );
    }
  }
  