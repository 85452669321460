import {
    GET_USERS_IN_RESERVATIONS,
    GET_USERS_IN_RESERVATIONS_SUCCESS,
    GET_USERS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getUsersInReservationsActions';

export function getUsersInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_USERS_IN_RESERVATIONS:
            return {
                ...state,
                isGetUsersInReservations: true,
                getUsersInReservationsError: null,
            };
        case GET_USERS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetUsersInReservations: false,
                getUsersInReservationsError: null,
                usersInReservations: action.payload.users,
            };
        case GET_USERS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetUsersInReservations: false,
                getUsersInReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}