import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, retry, takeUntil, mapTo, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
    UPDATE_TO_TIMES_IN_REQUESTS,
    updateToTimesInRequestsSuccess,
    updateToTimesInRequestsFailure
} from '../../actions/Requests/updateToTimesInRequestsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function updateToTimesInRequestsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_TO_TIMES_IN_REQUESTS),
        map(action => ({
            selectedTime: action.selectedTime
        })),
        map(response =>
            updateToTimesInRequestsSuccess(response.selectedTime)
        ),
        catchError(error => of(updateToTimesInRequestsFailure
            (strings.somethingWrongHappened)))
    )
}