import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import strings from '../../../localizations/strings';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Pagination from "react-js-pagination";
import { LanguagesConstant } from "../../../constants/LanguagesConstant";
import RoleUsers from "../RoleUsers/RoleUsers";
import RoleScreens from "../RoleScreens/RoleScreens";
import { DisplayTypesInRoleUsersConstant } from "../../../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant";
import { DisplayTypesInRoleScreensConstant } from "../../../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Details(props) {

    function handlePageButtonChange(pageNumber) {
        if (pageNumber != props.rolesPageNumberInRoles) {
            props.getRoles(pageNumber, props.rolesPageSizeInRoles);
        }
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        if (newValue == 0) {
            props.updateDisplayTypeInRoleUsers(DisplayTypesInRoleUsersConstant.LIST);
        } else if (newValue == 1) {
            props.updateDisplayTypeInRoleScreens(DisplayTypesInRoleScreensConstant.LIST);
        }
        setValue(newValue);
    };

    return (
        <div className="">
            <div className="container p-0">
                <div className="row px-3">
                    <div className="col-sm-1 bg-white overflow-auto mx-0 px-0 mt-2">
                        <div className="bg-white">
                            <div className="border-bottom border-end border-start text-center w-100 mx-0 px-0 row left-col-2"
                                style={{ paddingBottom: "0.55rem", paddingTop: "0.45rem", backgroundColor: "rgb(239 239 239)" }}>
                                <div className="px-0 mx-0 w-100">{strings.roleName}</div>
                            </div>
                            {
                                props.rolesInRoles.map((role, index) => {
                                    return (
                                        <div onClick={e => {
                                            if(value == 0){
                                                props.getRoleUsers(role.id);
                                            }
                                            if(value == 1){
                                                props.getRoleScreens(role.id);
                                            }
                                            props.getRole(role.id);
                                        }}>
                                            <div className="row line"></div>
                                            <div className={` row border-bottom py-3 cursor-pointer ${role.id == props.roleInRoles.id ? 'bg-new' : 'bg-white'} `}>
                                                <div className="ps-3">{strings.getLanguage() === LanguagesConstant.EN ? role.nameEn : role.nameAr}</div>
                                            </div>
                                        </div>)
                                })
                            }
                        </div>
                    </div>
                    <div className="col-sm-11 bg-white px-0">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={strings.users} {...a11yProps(0)} />
                                    <Tab label={strings.screens} {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <RoleUsers {...props} />
                            </TabPanel>
                            <TabPanel className="py-0" value={value} index={1}>
                                <RoleScreens {...props} />
                            </TabPanel>
                        </Box>
                        {/* <div className="row role-head py-5 px-2">
         <ul className="nav nav-tabs" id="myTab" role="tablist">
         <li className="nav-item" role="presentation">
            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{strings.Users}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{strings.screens}</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Contact</button>
          </li>
         </ul>
        </div>
        <div className="row bg-secondary bg-opacity-75 p-5">
        <div className="tab-content" id="myTabContent">
         <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
         <RoleUsers {...props} />
         </div>
         <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
             <RoleScreens {...props} />
        </div>
         <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
       </div>
       
         </div> */}
                    </div>
                </div>
            </div>
            <div className="card-footer">
                {
                    props.rolesTotalCountInRoles !== null
                    && props.rolesTotalCountInRoles > 0
                    && (
                        <div className="d-flex flex-stack flex-wrap justify-content-between">
                            <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.rolesPageNumberInRoles - 1) * props.rolesPageSizeInRoles) + 1} {strings.to} {(props.rolesPageNumberInRoles * props.rolesPageSizeInRoles) > props.rolesTotalCountInRoles ? props.rolesTotalCountInRoles : (props.rolesPageNumberInRoles * props.rolesPageSizeInRoles)} {strings.of} {props.rolesTotalCountInRoles} {strings.entries}</div>
                            <Pagination
                                innerClass="pagination"
                                itemClass="page-item"
                                linkClass="page-link"
                                activeClass="active"
                                activePage={props.rolesPageNumberInRoles}
                                itemsCountPerPage={props.rolesPageSizeInRoles}
                                totalItemsCount={props.rolesTotalCountInRoles}
                                onChange={handlePageButtonChange}
                            />
                        </div>
                    )}
            </div>
        </div>
    );
}
export default Details
