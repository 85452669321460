import {
    GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS,
    GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/getAttendedReservationsInReservationsActions';

export function getAttendedReservationsInReservationsReducer(state, action) {
    switch (action.type) {
        case GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS:
            return {
                ...state,
                isGetAttendedReservationsInReservations: true,
                getAttendedReservationsInReservationsError: null,
                attendedReservationsInReservations: [],
            };
        case GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isGetAttendedReservationsInReservations: false,
                getAttendedReservationsInReservationsError: null,
                attendedReservationsInReservations: action.payload.attendedReservations.data,
                attendedReservationsPageNumberInReservations: action.payload.pageNumber,
                attendedReservationsPageSizeInReservations: action.payload.pageSize,
                attendedReservationsTotalCountInReservations: action.payload.attendedReservations.total,
            };
        case GET_ATTENDED_RESERVATIONS_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGetAttendedReservationsInReservations: false,
                getAttendedReservationsInReservationsError: action.payload.message,
            };
        default:
            return state;
}}