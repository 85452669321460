import {
    UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS,
    UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE
} from '../../actions/ReservationTypeSettings/updateReservationTypesInReservationTypeSettingsActions';

export function updateReservationTypesInReservationTypeSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS:
            return {
                ...state,
                isUpdateReservationTypesInReservationTypeSettings: true,
                updateReservationTypesInReservationTypeSettingsError: null,
            };
        case UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_SUCCESS:
            return {
                ...state,
                isUpdateReservationTypesInReservationTypeSettings: false,
                updateReservationTypesInReservationTypeSettingsError: null,
                reservationTypeInReservationTypeSettings: action.payload.reservationType,
            };
        case UPDATE_RESERVATION_TYPES_IN_RESERVATION_TYPE_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdateReservationTypesInReservationTypeSettings: false,
                updateReservationTypesInReservationTypeSettingsError: action.payload.message,
            };
        default:
            return state;
}}