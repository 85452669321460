import {
    GET_EDITED_RESERVATION,
    GET_EDITED_RESERVATION_SUCCESS,
    GET_EDITED_RESERVATION_FAILURE
} from '../../actions/Reservations/getEditedReservationActions';

export function getEditedReservationReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_RESERVATION:
            return {
                ...state,
                isGetEditedReservation: true,
                getEditedReservationError: null, 
            };
        case GET_EDITED_RESERVATION_SUCCESS:
            return {
                ...state,
                isGetEditedReservation: false,
                getEditedReservationError: null,
                editedReservationInReservations:state.reservationsInReservations.filter(x=> x.id == action.payload.reservationId)[0],
            };
        case GET_EDITED_RESERVATION_FAILURE:
            return {
                ...state,
                isGetEditedReservation: false,
                getEditedReservationError: action.payload.message,
            };
        default:
            return state;
}}