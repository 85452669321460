export const ADD_NEW_REQUEST = 'ADD_NEW_REQUEST';
export const ADD_NEW_REQUEST_SUCCESS = 'ADD_NEW_REQUEST_SUCCESS';
export const ADD_NEW_REQUEST_FAILURE = 'ADD_NEW_REQUEST_FAILURE';

export const addNewRequest = (requestData) => ({
    type: ADD_NEW_REQUEST,
    requestData
});

export const addNewRequestSuccess = (data) => ({
    type: ADD_NEW_REQUEST_SUCCESS,
    payload: { data }
});

export const addNewRequestFailure = (message) => ({
    type: ADD_NEW_REQUEST_FAILURE,
    payload: { message }
});