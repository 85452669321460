import {
    UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS,
    UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS,
    UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE
} from '../../actions/TicketOptions/updateReservationTypesInTicketOptionsActions';

export function updateReservationTypesInTicketOptionsReducer(state, action) {
    switch (action.type) {
        case UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS:
            return {
                ...state,
                isUpdateReservationTypesInTicketOptions: true,
                updateReservationTypesInTicketOptionsError: null,
            };
        case UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS:
            return {
                ...state,
                isUpdateReservationTypesInTicketOptions: false,
                updateReservationTypesInTicketOptionsError: null,
                reservationTypeInTicketOptions: action.payload.reservationType,
            };
        case UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE:
            return {
                ...state,
                isUpdateReservationTypesInTicketOptions: false,
                updateReservationTypesInTicketOptionsError: action.payload.message,
            };
        default:
            return state;
}}