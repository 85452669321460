import {
    EDIT_RESERVATION_TYPE,
    EDIT_RESERVATION_TYPE_SUCCESS,
    EDIT_RESERVATION_TYPE_FAILURE
} from '../../actions/ReservationTypes/editReservationTypeActions';

import { DisplayTypesInReservationTypesConstant } from '../../constants/ReservationTypes/DisplayTypesInReservationTypesConstant';

export function editReservationTypeReducer(state, action) {
    switch (action.type) {
        case EDIT_RESERVATION_TYPE:
            return {
                ...state,
                isEditingReservationType: true,
                editReservationTypeError: null,
            };
        case EDIT_RESERVATION_TYPE_SUCCESS:
            return {
                ...state,
                isEditingReservationType: false,
                editReservationTypeError: null,
                editReservationTypeResult: action.payload.data,
               // displayTypeInReservationTypes: DisplayTypesInReservationTypesConstant.LIST,
            };
        case EDIT_RESERVATION_TYPE_FAILURE:
            return {
                ...state,
                isEditingReservationType: false,
                editReservationTypeError: action.payload.message,
            };
        default:
            return state;
}}