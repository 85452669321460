import {
    CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS,
    CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_SUCCESS,
    CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/changeIsPaidInCompanyReservationsActions';

export function changeIsPaidInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isChangingIsPaidInCompanyReservations: true,
                changeIsPaidInCompanyReservationsError: null,
            };
        case CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isChangingIsPaidInCompanyReservations: false,
                changeIsPaidInCompanyReservationsError: null,
                changeIsPaidInCompanyReservationsResult: action.payload.data,
                companyReservationsInCompanyReservations: state.companyReservationsInCompanyReservations.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isPaid: !item.isPaid
                        }
                    }
                    return item;
                })
            };
        case CHANGE_IS_PAID_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isChangingIsPaidInCompanyReservations: false,
                changeIsPaidInCompanyReservationsError: action.payload.message,
            };
        default:
            return state;
    }
}