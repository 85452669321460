import {
    EDIT_SCHOOL_TOUR_RANGE,
    EDIT_SCHOOL_TOUR_RANGE_SUCCESS,
    EDIT_SCHOOL_TOUR_RANGE_FAILURE
} from '../../actions/SchoolTourRanges/editSchoolTourRangeActions';

import { DisplayTypesInSchoolTourRangesConstant } from '../../constants/SchoolTourRanges/DisplayTypesInSchoolTourRangesConstant';

export function editSchoolTourRangeReducer(state, action) {
    switch (action.type) {
        case EDIT_SCHOOL_TOUR_RANGE:
            return {
                ...state,
                isEditingSchoolTourRange: true,
                editSchoolTourRangeError: null,
            };
        case EDIT_SCHOOL_TOUR_RANGE_SUCCESS:
            return {
                ...state,
                isEditingSchoolTourRange: false,
                editSchoolTourRangeError: null,
                editSchoolTourRangeResult: action.payload.data,
               // displayTypeInSchoolTourRanges: DisplayTypesInSchoolTourRangesConstant.LIST,
            };
        case EDIT_SCHOOL_TOUR_RANGE_FAILURE:
            return {
                ...state,
                isEditingSchoolTourRange: false,
                editSchoolTourRangeError: action.payload.message,
            };
        default:
            return state;
}}