import {
    ADD_NEW_NOTIFICATION_SETTING,
    ADD_NEW_NOTIFICATION_SETTING_SUCCESS,
    ADD_NEW_NOTIFICATION_SETTING_FAILURE
} from '../../actions/NotificationSettings/addNewNotificationSettingActions';

import { DisplayTypesInNotificationSettingsConstant } from '../../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant';

export function addNewNotificationSettingReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_NOTIFICATION_SETTING:
            return {
                ...state,
                isAddNewNotificationSetting: true,
                addNewNotificationSettingError: null, 
            };
        case ADD_NEW_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                isAddNewNotificationSetting: false,
                addNewNotificationSettingError: null,
                addNewNotificationSettingResult: action.payload.data,
              //  displayTypeInNotificationSettings: DisplayTypesInNotificationSettingsConstant.LIST,
            };
        case ADD_NEW_NOTIFICATION_SETTING_FAILURE:
            return {
                ...state,
                isAddNewNotificationSetting: false,
                addNewNotificationSettingError: action.payload.message,
            };
        default:
            return state;
}}