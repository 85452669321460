export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAILURE = 'ADD_NEW_USER_FAILURE';

export const addNewUser = (userData) => ({
    type: ADD_NEW_USER,
    userData
});

export const addNewUserSuccess = (data) => ({
    type: ADD_NEW_USER_SUCCESS,
    payload: { data }
});

export const addNewUserFailure = (message) => ({
    type: ADD_NEW_USER_FAILURE,
    payload: { message }
});