import React, { Component, useState, useEffect, useRef } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link, NavLink } from "react-router-dom";
import strings from "../../../localizations/strings";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from "react-pro-sidebar";
import { FaTachometerAlt, FaBriefcase, FaMobileAlt, FaChartArea, FaList, FaCogs, FaHeadset, FaRegLaughWink, FaDatabase, FaTags } from 'react-icons/fa';
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
//#4995d1 blue color
//#eb6618 orange color
import $ from "jquery";
import ReactTooltip from 'react-tooltip';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

const SideBar = ({ props,image, collapsed, rtl, toggle, handleToggleSidebar,handletoggle, userSideBar,location }) => {
  let menuRef = useRef();
  useEffect(() =>{
    document.addEventListener("mousedown" , (event) =>{
      if(event.target.classList.contains('overlay')){
      handletoggle(false)
      }
    })
  })

  return (
    <>
    <div ref={menuRef} className="sidebar">
      <ProSidebar
        image={false}
        rtl={strings.getLanguage() === LanguagesConstant.AR ? rtl : false}
        collapsed={false}
         style={{ position: "fixed" }}
        toggled={toggle}
        breakPoint={'md'}
        // width={82}
      >
          <SidebarContent>
            
            {
              userSideBar !== null
              && userSideBar.length !== 0
              && userSideBar.map((tab, index) => {

                return <Menu key={index} iconShape="square"  >
                  <MenuItem active={location.pathname === (strings.getLanguage() === LanguagesConstant.EN ? "/en" : "/ar") + tab.link}
                  // icon={
                  //   <div className="menu-item ">
                  //     <NavLink
                  //       exact
                  //       to={`/${strings.getLanguage()}/startup`}
                  //       className={`menu-link`}
                  //       isActive={(match, location) => {
                  //         if (match) {
                  //           setKey(0);
                  //           return "active2";
                  //         }
                  //       }}
                  //     >
                  //       <span className="menu-icon ">
                  //         <span className="svg-icon2 svg-icon-2x">
                  //           <svg
                  //             xmlns="http://www.w3.org/2000/svg"
                  //             width="24"
                  //             height="24"
                  //             viewBox="0 0 24 24"
                  //           >
                  //             <rect x="2" y="2" width="9" height="9" rx="2" />
                  //             <rect
                  //               opacity="0.3"
                  //               x="13"
                  //               y="2"
                  //               width="9"
                  //               height="9"
                  //               rx="2"
                  //             />
                  //             <rect
                  //               opacity="0.3"
                  //               x="13"
                  //               y="13"
                  //               width="9"
                  //               height="9"
                  //               rx="2"
                  //             />
                  //             <rect
                  //               opacity="0.3"
                  //               x="2"
                  //               y="13"
                  //               width="9"
                  //               height="9"
                  //               rx="2"
                  //             />
                  //           </svg>
                  //         </span>
                  //       </span>
                  //     </NavLink>
                  //   </div>
                  // }
                  icon={<i className={tab.iconCss} />}
                  data-tip={strings.getLanguage() === LanguagesConstant.AR ? tab.nameAr : tab.nameEn}
                    //title={strings.getLanguage() === LanguagesConstant.AR ? tab.nameAr : tab.nameEn}
                  >
                    <NavLink className="my-2" to={(strings.getLanguage() === LanguagesConstant.EN ? "/en" : "/ar") + tab.link} activeClassName="selectedmenu"> {strings.getLanguage() === LanguagesConstant.AR ? tab.nameAr : tab.nameEn}
                     </NavLink>
                  </MenuItem>
                  {/* <ReactTooltip place={strings.getLanguage() === LanguagesConstant.AR ? "left" : "right"} type="info" effect="float"/> */}
                  {/* <SubMenu
                defaultOpen={true}
                icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 1 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title={strings.reservationType}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/ReservationTypes`}
                  className={`menu-link`}
                    isActive={(match, location) => {
                    if (match) {
                      setKey(1);

                      return "active2";
                    } 
                  }}

                >
                  {strings.reservationType}
                </NavLink>
              </MenuItem>


             
            </SubMenu>
            
            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 2 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z" />
                          <path d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z" />
                          <path
                            opacity="0.3"
                            d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z"
                          />
                          <path
                            opacity="0.3"
                            d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z"
                          />
                          <path
                            opacity="0.3"
                            d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z"
                          />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title={strings.discounts}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Discounts`}
                
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(2);
                      return "active2";
                    }
                   ;
                  }}
                >
                  {strings.discounts}
                 
                </NavLink>
              </MenuItem>
            </SubMenu>

            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 3 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-truck"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title={strings.delivery}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/ReservationSchedules`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(3);
                      return "active2";
                    }
                   ;
                  }}
                >
                  {strings.schedules}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/DeliveryRequests`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(3);
                      return "active2";
                    }
                  }}
                >
                  {strings.activateAccountOrders}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/ReservationTypeSettings`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(3);
                      return "active2";
                    }
                  }}
                >
                  {strings.reservationSettings}
                </NavLink>
              </MenuItem>
            </SubMenu>

            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 4 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-gear-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title={strings.settings}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Bank`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                   ;
                  }}
                >
                  {strings.banks}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Country`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.countries}
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/City`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.cities}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Currency`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.currency}
                </NavLink>
              </MenuItem>

            
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/MainCategory`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.mainCategories}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/SubCategory`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.subCategories}
                </NavLink>
              </MenuItem>


              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Product`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.products}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Feature`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.features}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Settings/Activity`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(4);
                      return "active2";
                    }
                  }}
                >
                  {strings.activities}
                </NavLink>
              </MenuItem>
            </SubMenu>

            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 8 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="16" 
                          height="16" 
                          // fill="currentColor" 
                          className="bi bi-border-width" 
                          viewBox="0 0 16 16">
                        <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0                       0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
                      </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title={strings.ordersAndOffers}
            >
          
           
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Orders`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(8);
                      return "active2";
                    }
                  }}
                >
                  {strings.orders}
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Offers`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(8);
                      return "active2";
                    }
                  }}
                >
                  {strings.offers}
                </NavLink>
              </MenuItem>
         
            </SubMenu>


            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 5 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="bi bi-wallet-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                      </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title= {strings.bundles}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Bundle`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(5);
                      return "active2";
                    }
                   ;
                  }}
                >
                   {strings.bundles}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Pricing`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(5);
                      return "active2";
                    }
                  }}
                >
                  {strings.pricing}
                </NavLink>
              </MenuItem>
            </SubMenu>


            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 6 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       className="bi bi-credit-card-2-back-fill"
                       viewBox="0 0 16 16"
                     >
                       <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z" />
                     </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title= {strings.payments}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Payments/SellerPayment`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(6);
                      return "active2";
                    }
                   ;
                  }}
                >
                   {strings.sellerPayment}
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/Payments/DiscountPayment`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(6);
                      return "active2";
                    }
                  }}
                >
                 {strings.discountPayment}
                </NavLink>
              </MenuItem>
            </SubMenu>

            <SubMenu
              icon={
                <div className="menu-item ">
                  <span className={`menu-link ${key === 7 ? "active2" : ""}`}>
                    <span className="menu-icon ">
                      <span className="svg-icon2 svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="bi bi-list-stars"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
                        />
                        <path d="M2.242 2.194a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.256-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53z" />
                      </svg>
                      </span>
                    </span>
                  </span>
                </div>
              }
              title=   {strings.complaintsAndReviews}
            >
              <MenuItem>
                <NavLink
                  exact
                  to={`/${strings.getLanguage()}/ComplaintAndRating`}
                  className={`menu-link`}
                  isActive={(match, location) => {
                    if (match) {
                      setKey(7);
                      return "active2";
                    }
                   ;
                  }}
                >
                     {strings.complaintsAndReviews}
                </NavLink>
              </MenuItem> 
            </SubMenu> */}
                </Menu>
              })


            }
          </SidebarContent>
        </ProSidebar>
        </div>
    </>
  );
};

export default SideBar;
