import React,{useState,useEffect} from "react";
import { DisplayTypesInReservationTypesConstant } from "../../../src/constants/ReservationTypes/DisplayTypesInReservationTypesConstant";
import ReservationType from './ReservationType';
import Edit from './Edit';
import Add from './Add';
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function ReservationTypes(props){

    return (
        <>
                  <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInReservationTypes === DisplayTypesInReservationTypesConstant.LIST ? strings.reservationType:
                        // props.displayTypeInReservationTypes === DisplayTypesInReservationTypesConstant.ADD ? strings.create:
                         props.displayTypeInReservationTypes === DisplayTypesInReservationTypesConstant.EDIT ? strings.edit:
                        ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                            props.displayTypeInReservationTypes  !== DisplayTypesInReservationTypesConstant.LIST ?
                                <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInReservationTypes(DisplayTypesInReservationTypesConstant.LIST)}>
                                    {strings.reservationType}
                                </Button> :null}

                            {/* {props.displayTypeInReservationTypes  !== DisplayTypesInReservationTypesConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => {props.updateDisplayTypeInReservationTypes(DisplayTypesInReservationTypesConstant.ADD)}}>
                                    {strings.new}
                                </Button> :null} */}

                          
                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                           
                            { props.displayTypeInReservationTypes  === DisplayTypesInReservationTypesConstant.LIST &&
                            (<ReservationType {...props} />)}
                            {props.displayTypeInReservationTypes  === DisplayTypesInReservationTypesConstant.ADD &&
                             (<Add {...props} />)}
                            {props.displayTypeInReservationTypes  === DisplayTypesInReservationTypesConstant.EDIT &&
                              (<Edit {...props} />)}
                            
                        </>

                    </div>
                </div>
            </div>
                        

        </>
    );
}
export default ReservationTypes