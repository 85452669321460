export const UPDATE_RETURN_MONEY_STATUS_RESERVATION = 'UPDATE_RETURN_MONEY_STATUS_RESERVATION';
export const UPDATE_RETURN_MONEY_STATUS_RESERVATION_SUCCESS = 'UPDATE_RETURN_MONEY_STATUS_RESERVATION_SUCCESS';
export const UPDATE_RETURN_MONEY_STATUS_RESERVATION_FAILURE = 'UPDATE_RETURN_MONEY_STATUS_RESERVATION_FAILURE';

export const updateReturnMoneyStatusReservation = (cancelledReservationId) => ({
    type: UPDATE_RETURN_MONEY_STATUS_RESERVATION,
    cancelledReservationId
});

export const updateReturnMoneyStatusReservationSuccess = (data) => ({
    type: UPDATE_RETURN_MONEY_STATUS_RESERVATION_SUCCESS,
    payload: { data }
});

export const updateReturnMoneyStatusReservationFailure = (message) => ({
    type: UPDATE_RETURN_MONEY_STATUS_RESERVATION_FAILURE,
    payload: { message }
});