import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import {
    UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES,
    updateDisplayTypeInPausedTimesSuccess,
    updateDisplayTypeInPausedTimesFailure
} from '../../../src/actions/PausedTimes/updateDisplayTypeInPausedTimesActions';
import strings from '../../../src/localizations/strings';

export function updateDisplayTypeInPausedTimesEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES),
        map(action => ({
            displayType: action.displayType,
        })),
        map(response =>{
            console.log(response)
         return   updateDisplayTypeInPausedTimesSuccess(response.displayType)
            }
        ),
        catchError(error => Observable.of(updateDisplayTypeInPausedTimesFailure(strings.somethingWrongHappened)))
    )
}

