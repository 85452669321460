import {
    GET_USERS_IN_REQUESTS,
    GET_USERS_IN_REQUESTS_SUCCESS,
    GET_USERS_IN_REQUESTS_FAILURE
} from '../../actions/Requests/getUsersInRequestsActions';

export function getUsersInRequestsReducer(state, action) {
    switch (action.type) {
        case GET_USERS_IN_REQUESTS:
            return {
                ...state,
                isGetUsersInRequests: true,
                getUsersInRequestsError: null,
            };
        case GET_USERS_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isGetUsersInRequests: false,
                getUsersInRequestsError: null,
                usersInRequests: action.payload.users,
                userInRequests: state.editedRequestInRequests !== null ? action.payload.users.filter(x => x.value === state.editedRequestInRequests.userId)[0] : null,
            };
        case GET_USERS_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isGetUsersInRequests: false,
                getUsersInRequestsError: action.payload.message,
            };
        default:
            return state;
    }
}