import {
    UPDATE_STATUS_ROLE_SCREEN,
    UPDATE_STATUS_ROLE_SCREEN_SUCCESS,
    UPDATE_STATUS_ROLE_SCREEN_FAILURE
} from '../../../actions/Roles/RoleScreens/updateStatusRoleScreenActions';

export function updateStatusRoleScreenReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_ROLE_SCREEN:
            return {
                ...state,
                isUpdatingStatusRoleScreen: true,
                updateStatusRoleScreenError: null,
            };
        case UPDATE_STATUS_ROLE_SCREEN_SUCCESS:
            return {
                ...state,
                isUpdatingStatusRoleScreen: false,
                updateStatusRoleScreenError: null,
                updateStatusRoleScreenResult: action.payload.data,
                roleScreensInRoleScreens: state.roleScreensInRoleScreens.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_ROLE_SCREEN_FAILURE:
            return {
                ...state,
                isUpdatingStatusRoleScreen: false,
                updateStatusRoleScreenError: action.payload.message,
            };
        default:
            return state;
}}