export const ADD_NEW_DISCOUNT = 'ADD_NEW_DISCOUNT';
export const ADD_NEW_DISCOUNT_SUCCESS = 'ADD_NEW_DISCOUNT_SUCCESS';
export const ADD_NEW_DISCOUNT_FAILURE = 'ADD_NEW_DISCOUNT_FAILURE';

export const addNewDiscount = (discountData) =>{
    console.log("action")
    return ({
    type: ADD_NEW_DISCOUNT,
    discountData
});
}
export const addNewDiscountSuccess = (data) => ({
    type: ADD_NEW_DISCOUNT_SUCCESS,
    payload: { data }
});

export const addNewDiscountFailure = (message) => ({
    type: ADD_NEW_DISCOUNT_FAILURE,
    payload: { message }
});