import React, { useEffect } from "react";
import strings from '../../localizations/strings';
import PieChart from './Charts/Pie'
import LineChart from './Charts/LineChart';
import './admin.css';
import { LanguagesConstant } from "../../constants/LanguagesConstant";
function Dashboard(props) {

  useEffect(() => {
    props.getChartsData();
  }, [])

  return (
    <div className="card w-100 border-0 container3">
    <div className="p-5">
    <h1 className="card-label fw-bolder fs-3 text-dark">{strings.dashboard}</h1>
    </div>
    <div className="row d-flex justify-content-around w-100 p-3">
      <div className="col mb-5">
        <LineChart value={props.chartsDataValueInAdmin} labels={strings.getLanguage() === LanguagesConstant.AR ? props.chartsDataLabelArInAdmin : props.chartsDataLabelEnInAdmin} />
      </div>
      <div className="col">
        <PieChart id="rateReport" value={props.chartsDataValueInAdmin} labels={strings.getLanguage() === LanguagesConstant.AR ? props.chartsDataLabelArInAdmin : props.chartsDataLabelEnInAdmin} />
      </div>
    </div>
    </div>
  );
}

export default Dashboard;