export const EDIT_NOTIFICATION_SETTING = 'EDIT_NOTIFICATION_SETTING';
export const EDIT_NOTIFICATION_SETTING_SUCCESS = 'EDIT_NOTIFICATION_SETTING_SUCCESS';
export const EDIT_NOTIFICATION_SETTING_FAILURE = 'EDIT_NOTIFICATION_SETTING_FAILURE';

export const editNotificationSetting = (notificationSettingData) => ({
    type: EDIT_NOTIFICATION_SETTING,
    notificationSettingData
});

export const editNotificationSettingSuccess = (data) => ({
    type: EDIT_NOTIFICATION_SETTING_SUCCESS,
    payload: { data }
});

export const editNotificationSettingFailure = (message) => ({
    type: EDIT_NOTIFICATION_SETTING_FAILURE,
    payload: { message }
});