export const GET_RESERVATION_DETAILS_WITH_CODE = 'GET_RESERVATION_DETAILS_WITH_CODE';
export const GET_RESERVATION_DETAILS_WITH_CODE_SUCCESS = 'GET_RESERVATION_DETAILS_WITH_CODE_SUCCESS';
export const GET_RESERVATION_DETAILS_WITH_CODE_FAILURE = 'GET_RESERVATION_DETAILS_WITH_CODE_FAILURE';

export const getReservationDetailsWithCode = (code) => ({
    type: GET_RESERVATION_DETAILS_WITH_CODE,
    code
});

export const getReservationDetailsWithCodeSuccess = (reservationViewModel) => ({
    type: GET_RESERVATION_DETAILS_WITH_CODE_SUCCESS,
    payload: { reservationViewModel }
});

export const getReservationDetailsWithCodeFailure = (message) => ({
    type: GET_RESERVATION_DETAILS_WITH_CODE_FAILURE,
    payload: message
});