export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const editUser = (userData) =>({
    type: EDIT_USER,
    userData
});

export const editUserSuccess = (data) => ({
    type: EDIT_USER_SUCCESS,
    payload: { data }
});

export const editUserFailure = (message) => ({
    type: EDIT_USER_FAILURE,
    payload: { message }
});