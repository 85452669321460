export const GET_REQUEST_DETAILS = 'GET_REQUEST_DETAILS';
export const GET_REQUEST_DETAILS_SUCCESS = 'GET_REQUEST_DETAILS_SUCCESS';
export const GET_REQUEST_DETAILS_FAILURE = 'GET_REQUEST_DETAILS_FAILURE';

export const getRequestDetails = (requestId) => ({
    type: GET_REQUEST_DETAILS,
    requestId
});

export const getRequestDetailsSuccess = (requestId) => ({
    type: GET_REQUEST_DETAILS_SUCCESS,
    payload: { requestId }
});

export const getRequestDetailsFailure = (message) => ({
    type: GET_REQUEST_DETAILS_FAILURE,
    payload: { message }
});
