import {
    MOBILE_VERIFIED_USER,
    MOBILE_VERIFIED_USER_SUCCESS,
    MOBILE_VERIFIED_USER_FAILURE
} from '../../actions/Users/mobileVerifiedUserActions';

export function mobileVerifiedUserReducer(state, action) {
    switch (action.type) {
        case MOBILE_VERIFIED_USER:
            return {
                ...state,
                isUpdatingStatusUser: true,
                mobileVerifiedUserError: null,
            };
        case MOBILE_VERIFIED_USER_SUCCESS:
            return {
                ...state,
                isUpdatingStatusUser: false,
                mobileVerifiedUserError: null,
                mobileVerifiedUserResult: action.payload.data,
                usersInUsers: state.usersInUsers.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isMobileVerified: !item.isMobileVerified
                        }
                    }
                    return item;
                })
            };
        case MOBILE_VERIFIED_USER_FAILURE:
            return {
                ...state,
                isUpdatingStatusUser: false,
                mobileVerifiedUserError: action.payload.message,
            };
        default:
            return state;
    }
}