export const UPDATE_STATUS_NEW = 'UPDATE_STATUS_NEW';
export const UPDATE_STATUS_NEW_SUCCESS = 'UPDATE_STATUS_NEW_SUCCESS';
export const UPDATE_STATUS_NEW_FAILURE = 'UPDATE_STATUS_NEW_FAILURE';

export const updateStatusNew = (newId) => ({
    type: UPDATE_STATUS_NEW,
    newId
});

export const updateStatusNewSuccess = (data) => ({
    type: UPDATE_STATUS_NEW_SUCCESS,
    payload: { data }
});

export const updateStatusNewFailure = (message) => ({
    type: UPDATE_STATUS_NEW_FAILURE,
    payload: { message }
});