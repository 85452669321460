import React from "react";

const Toolbar = (props)=>{
    return(
        <>
            {props.pagename?(

                <div className="toolbar sticky-top custome-top" id="kt_toolbar">


                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack py-1">
                        <div  className="page-title d-flex align-items-center flex-wrap">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{props.pagename}</h1>
                            {props.parentpage?(
<>
                                    <span className="h-20px border-gray-200 border-start mx-4"></span>

                                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">


                                    <li className="breadcrumb-item text-muted">
                                        <a href="" className="text-muted text-hover-primary" onClick={(e)=>e.preventDefault()}>{props.parentpage}</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                    </li>

                                    <li className="breadcrumb-item text-dark">{props.pagename}</li>
                                </ul>

</>
                            ):('')}
                        </div>
                        {props.Listbtns?(
                            <div className="d-flex align-items-center py-1">
                                {props.Listbtns}
                            </div>):('')}
                    </div>
                </div>
            ):('')}
        </>
    )
}

export default Toolbar;