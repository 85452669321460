export const DELETE_RESERVATION = 'DELETE_RESERVATION';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAILURE = 'DELETE_RESERVATION_FAILURE';

export const deleteReservation = (reservationId) => ({
    type: DELETE_RESERVATION,
    reservationId
});

export const deleteReservationSuccess = (data) => ({
    type: DELETE_RESERVATION_SUCCESS,
    payload: { data }
});

export const deleteReservationFailure = (message) => ({
    type: DELETE_RESERVATION_FAILURE,
    payload: { message }
});