import React, { useEffect,useState } from "react";
import strings from "../../localizations/strings";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import logo1 from "../../images/ic_logo.png";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login(props) {
    const formikRef = React.useRef(null);
    const eye = <FontAwesomeIcon icon={faEye} />;
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    useEffect(() => {
        localStorage.removeItem("AquaContext");

    }, []);

    useEffect(() => {
        if (props.loggedUserResult !== null) {
            localStorage.setItem("AquaContext", props.loggedUserResult);
            // props.history.push({
            //     pathname: `/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/home`,
            //     state: null
            // });
            //setTimeout(()=>{
            //props.getUserSideBar();
            window.location.href = `/${strings.getLanguage() === LanguagesConstant.EN ? "en" : "ar"}/home`;
            //},200);
        }

    }, [props.loggedUserResult]);

    useEffect(() => {
        if (props.getLoggedUserError !== null) {

            Swal.fire(
                `${strings.error}`,
                props.getLoggedUserError,
                'error'
            )

        }

    }, [props.getLoggedUserError]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .required(strings.emailRequired)
                    .email(strings.emailIsInvalid),
                password: Yup.string()
                    .required(strings.passwordRequired)
            })}
            onSubmit={fields => {
                const loginViewModel = {
                    email: fields.email,
                    password: fields.password
                }
                props.loginUser(loginViewModel);

            }}
            render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue }) => (

                <div id="kt_body" className="bg-body">
                    <div className="d-flex flex-column flex-root">
                        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-image-login">
                            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                                <a href="" className="mb-12 d-flex">
                                    <img alt="Logo" src={logo1} className="h-65px mx-2" />
                                    <h3 className="h-65px d-flex flex-center text-black fw-bolder">Aqua Kids</h3>
                                </a>
                                <Form>
                                    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                                        <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework mt-0">
                                            <div className="text-center mb-10">
                                                <h1 className="text-dark mb-3">{strings.signInToAquaKids}</h1>
                                            </div>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                                <label className="form-label fs-6 fw-bolder text-dark">{strings.email}</label>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                                    placeholder={`${strings.enterEmail} *`}
                                                />
                                                <ErrorMessage name="email" component="div"
                                                    className="invalid-feedback" />
                                            </div>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                                <div className="d-flex flex-stack mb-2">
                                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">{strings.password}</label>
                                                    <a href="/metronic8/demo2/../demo2/authentication/layouts/basic/password-reset.html" className="link-primary fs-6 fw-bolder">{strings.forgetPass}</a>
                                                </div>
                                                <div className="pass-wrapper">
                                                <Field
                                                    name="password"
                                                    type={passwordShown ? "text" : "password"}
                                                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                                    placeholder={`${strings.enterPassword} *`}
                                                />
                                                 <i 
                                                  onClick={togglePasswordVisiblity}>{eye}</i>
                                                   {"  "}
                                                <ErrorMessage name="password" component="div"
                                                    className="invalid-feedback" />
                                               </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                                                    <span className="indicator-label">{strings.signIn}</span>
                                                </button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
            )}

        />
    )
}

export default Login;