import {
    GET_EDITED_GALLERY,
    GET_EDITED_GALLERY_SUCCESS,
    GET_EDITED_GALLERY_FAILURE
} from '../../actions/Galleries/getEditedGalleryActions';

import { DisplayTypesInGalleriesConstant } from '../../constants/Galleries/DisplayTypesInGalleriesConstant';

export function getEditedGalleryReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_GALLERY:
            return {
                ...state,
                isGetEditedGallery: true,
                getEditedGalleryError: null, 
            };
        case GET_EDITED_GALLERY_SUCCESS:
            console.log('get edited gallery reducer');
            return {
                ...state,
                isGetEditedGallery: false,
                getEditedGalleryError: null,
                editedGalleryInGalleries:state.galleriesInGalleries.filter(x=>x.id == action.payload.galleryId )[0],

            };
        case GET_EDITED_GALLERY_FAILURE:
            console.log('get edited gallery fail reducer');
            return {
                ...state,
                isGetEditedGallery: false,
                getEditedGalleryError: action.payload.message,
            };
        default:
            return state;
}}