import {
    DELETE_TICKET_OPTION,
    DELETE_TICKET_OPTION_SUCCESS,
    DELETE_TICKET_OPTION_FAILURE
} from '../../actions/TicketOptions/deleteTicketOptionActions';

import { DisplayTypesInTicketOptionsConstant } from '../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant';

export function deleteTicketOptionReducer(state, action) {
    switch (action.type) {
        case DELETE_TICKET_OPTION:
            return {
                ...state,
                isDeletingTicketOption: true,
                deleteTicketOptionError: null,
            };
        case DELETE_TICKET_OPTION_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingTicketOption: false,
                deleteTicketOptionError: null,
                deleteTicketOptionResult: action.payload.data,
                //displayTypeInTicketOptions: DisplayTypesInTicketOptionsConstant.LIST,
            };
        case DELETE_TICKET_OPTION_FAILURE:
            return {
                ...state,
                isDeletingTicketOption: false,
                deleteTicketOptionError: action.payload.message,
            };
        default:
            return state;
}}