export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const deleteRole = (roleId) => ({
    type: DELETE_ROLE,
    roleId
});

export const deleteRoleSuccess = (data) => ({
    type: DELETE_ROLE_SUCCESS,
    payload: { data }
});

export const deleteRoleFailure = (message) => ({
    type: DELETE_ROLE_FAILURE,
    payload: { message }
});