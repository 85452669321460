import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import strings from '../../localizations/strings';
import RequestFilter from "./RequestFilter";
import ReservationFilter from "./ReservationFilter";
import CompanyReservationsFilter from "./CompanyReservationsFilter";

function ReportTabs(props) {
    return (
  <Tabs className={"p-5"}>
    <TabList>
      <Tab>{strings.reservation}</Tab>
      <Tab>{strings.request}</Tab>
      <Tab>{strings.companyReservations}</Tab>
    </TabList>

    <TabPanel>
     <ReservationFilter {...props}/>
    </TabPanel>
    <TabPanel>
     <RequestFilter {...props}/>
    </TabPanel>
    <TabPanel>
     <CompanyReservationsFilter {...props}/>
    </TabPanel>
  </Tabs>
    );
}
export default ReportTabs