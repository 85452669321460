import {
    UPDATE_STATUS_SMS_SETTING,
    UPDATE_STATUS_SMS_SETTING_SUCCESS,
    UPDATE_STATUS_SMS_SETTING_FAILURE
} from '../../actions/SmsSettings/updateStatusSmsSettingActions';

import { DisplayTypesInSmsSettingsConstant } from '../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant';

export function updateStatusSmsSettingReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_SMS_SETTING:
            return {
                ...state,
                isUpdatingStatusSmsSetting: true,
                updateStatusSmsSettingError: null,
            };
        case UPDATE_STATUS_SMS_SETTING_SUCCESS:
            return {
                ...state,
                isUpdatingStatusSmsSetting: false,
                updateStatusSmsSettingError: null,
                updateStatusSmsSettingResult: action.payload.data,
                smsSettingsInSmsSettings: state.smsSettingsInSmsSettings.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
                //  displayTypeInSmsSettings: DisplayTypesInSmsSettingsConstant.LIST,
            };
        case UPDATE_STATUS_SMS_SETTING_FAILURE:
            return {
                ...state,
                isUpdatingStatusSmsSetting: false,
                updateStatusSmsSettingError: action.payload.message,
            };
        default:
            return state;
    }
}