//publish local
// export const url = "/api/"
// export const urlHub = "/hub/"
// export const urlFile = "/Uploads/"
//publish
export const url = "https://mohammeddarwesh-001-site1.itempurl.com/api/"
 export const urlHub = "https://mohammeddarwesh-001-site1.itempurl.com/hub/"
 export const urlFile="https://mohammeddarwesh-001-site1.itempurl.com/Uploads/"
//development
// export const url = "http://192.168.1.11:8080/api/"
// export const urlHub = "http://192.168.1.11:8080/hub/"
// export const urlFile="http://192.168.1.11:8080/Uploads/"
//development local
// export const url = "https://localhost:44379/api/"
// export const urlHub = "https://localhost:44379/hub/"
// export const urlFile="https://localhost:44379/Uploads/"
//token
export const token=`Bearer ${localStorage.getItem('AquaContext')}`

