import {
    UPDATE_FROM_TIMES_IN_REQUESTS,
    UPDATE_FROM_TIMES_IN_REQUESTS_SUCCESS,
    UPDATE_FROM_TIMES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/updateFromTimesInRequestsActions';

export function updateFromTimesInRequestsReducer(state, action) {
    switch (action.type) {
        case UPDATE_FROM_TIMES_IN_REQUESTS:
            return {
                ...state,
                isUpdatingFromTimesInRequests: true,
                updateFromTimesInRequestsError: null,
            };
        case UPDATE_FROM_TIMES_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isUpdatingFromTimesInRequests: false,
                fromTimeInRequests: action.payload.selectedTime,
                updateFromTimesInRequestsError: null
            };
        case UPDATE_FROM_TIMES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isUpdatingFromTimesInRequests: false,
                updateFromTimesInRequestsError: action.payload
            };
        default:
            return state;
    }
}