import {
    GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS,
    GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../actions/NotificationSettings/getNotificationTypesInNotificationSettingsActions';

export function getNotificationTypesInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isGetNotificationTypesInNotificationSettings: true,
                getNotificationTypesInNotificationSettingsError: null,
            };
        case GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                isGetNotificationTypesInNotificationSettings: false,
                getNotificationTypesInNotificationSettingsError: null,
                notificationTypesInNotificationSettings: action.payload.notificationTypes,
                notificationTypeInNotificationSettings:
                    state.editedNotificationSettingInNotificationSettings !== null
                        ? action.payload.notificationTypes.filter(
                            x => x.value == state.editedNotificationSettingInNotificationSettings.notificationTypeId
                        )[0]
                        : null,
            };
        case GET_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isGetNotificationTypesInNotificationSettings: false,
                getNotificationTypesInNotificationSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}