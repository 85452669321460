import React, { useEffect, forwardRef, useState } from "react";
import Select from 'react-select';
import { LanguagesConstant } from '../../constants/LanguagesConstant';
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DisplayTypesInRequestsConstant } from "../../constants/Reservations/DisplayTypesInRequestsConstant";
import { ReservationTypesConstant } from "../../constants/ReservationTypesConstant";
import strings from "../../localizations/strings";
import DatePicker, { registerLocale } from "react-datepicker";
import Loading from "../Shared/Loading/Loading";
import moment from 'moment';
import "moment/locale/ar-sa";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar-SA"; // the locale you want
import en from "date-fns/locale/en-US";

registerLocale("ar", ar); // register it with the name you want
registerLocale("en", en);


function Edit(props) {
  const formikRef = React.useRef(null);

  const [userError, setUserError] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reservationTypeError, setReservationTypeError] = useState(null);
  const [fromTimeError, setFromTimeError] = useState(null);
  const [toTimeError, setToTimeError] = useState(null);
  const [reservationDateError, setReservationDateError] = useState(null);
  const [optionIds, setOptionIds] = useState([]);
  const [countPrice, setCountPrice] = useState(0);

  useEffect(() => {
    props.getUsersInRequests();
    props.getFromTimesInRequests(moment(props.editedRequestInRequests.reservationDate).format("YYYY-MM-DD HH:mm:ss"));
    props.getToTimesInRequests(moment(props.editedRequestInRequests.fromTime).format("YYYY-MM-DD HH:mm:ss"));
    props.getTicketOptionsInRequests(props.editedRequestInRequests.reservationTypeId);
    if (props.editedRequestInRequests.reservationTypeId == ReservationTypesConstant.SchoolTrip) {
      props.getSchoolTourRangesInRequests();
    }
    if (props.editedRequestInRequests.ticketOptionsViewModel != null) {
      setSelectedItems([...props.editedRequestInRequests.ticketOptionsViewModel]);
      setOptionIds(props.editedRequestInRequests.ticketOptionsViewModel.map(x => x.id))
    }
  }, [])

  useEffect(() => {
    if (props.schoolTourRangesInRequests !== []) {
      changeSchoolTourCount(props.editedRequestInRequests.count)
    }
  }, [props.schoolTourRangesInRequests])

  useEffect(() => {
    if (props.editRequestResult !== null && props.editRequestResult.isSuccess) {
      Swal.fire(
        `! ${strings.successfullyEdited}`,
        props.editRequestResult.message,
        'success'
      ).then(result => {
        props.updateDisplayTypeInRequests(DisplayTypesInRequestsConstant.LIST)
      }
      )
    }
  }, [props.editRequestResult])

  useEffect(() => {
    if (props.editRequestError !== null) {
      Swal.fire(
        `! ${strings.error}`,
        props.editRequestError,
        'error'
      ).then(result => {
      }
      )
    }
  }, [props.editRequestError])

  function handleUserChanges(selectedOption) {
    if (selectedOption == null) {
      setUserError(strings.pleaseSelectUser)
    } else {
      setUserError(null)
    }
    props.updateUsersInRequests(selectedOption);
  }

  function handleReservationTypeChanges(selectedOption) {
    if (selectedOption == null) {
      setReservationTypeError(strings.pleaseSelectReservationType)
    } else {
      setReservationTypeError(null);
      props.getTicketOptionsInRequests(selectedOption.value);
      if (selectedOption.reservationTypeId == ReservationTypesConstant.SchoolTrip) {
        props.getSchoolTourRangesInRequests();
      } else {
        formikRef.current.setFieldValue("total", selectedOption.price);
      }
    }
    props.updateReservationTypesInRequests(selectedOption, moment(props.selectedDateInRequests).format("YYYY-MM-DD HH:mm:ss"));
  }

  function handleFromTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setFromTimeError(strings.pleaseSelectFromTime)
    } else {
      setFromTimeError(null);
      let startDate = moment(formikRef.current.values.reservationDate).toDate();
      const splittedStartTimeArr = selectedOption.fromTime.split(":");
      startDate.setHours(splittedStartTimeArr[0]);
      startDate.setMinutes(splittedStartTimeArr[1]);
      props.getToTimesInRequests(moment(startDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    props.updateFromTimesInRequests(selectedOption);
  }

  function handleToTimeChanges(selectedOption) {
    if (selectedOption == null) {
      setToTimeError(strings.pleaseSelectToTime)
    } else {
      setToTimeError(null);
    }
    props.updateToTimesInRequests(selectedOption);
  }

  function handleReservationDateChange(value) {
    if (!moment(value).isValid()) {
      setReservationDateError(strings.dateNotValid);
    } else {
      setReservationDateError(null);
      formikRef.current.setFieldValue("reservationDate", value);
      props.getFromTimesInRequests(moment(value).format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  function changeSchoolTourCount(countValue) {
    var totalValue = 0;
    const schoolTourRange = props.schoolTourRangesInRequests.filter(x => x.countFrom <= countValue && x.countTo >= countValue)[0];
    if (schoolTourRange == null) {
      setCountPrice(0);
      formikRef.current.setFieldValue("total", (formikRef.current.values.total - countPrice));
      formikRef.current.setFieldError("count", strings.pleaseEnterCorrectCount)
    } else {
      selectedItems.forEach(item => {
        totalValue += item.count * item.price;
      })
      totalValue += schoolTourRange.price * countValue;
      setCountPrice(schoolTourRange.price * countValue)
      formikRef.current.setFieldValue("total", totalValue);
    }
  }

  function changeCount(id, price, count) {
    var total = props.reservationTypeInRequests.reservationTypeId == ReservationTypesConstant.Birthday ? props.reservationTypeInRequests.price : countPrice;
    let updatedOption = selectedItems.length > 0 ? selectedItems.filter(x => x.id !== id) : selectedItems;
    if (count > 0) {
      updatedOption = [...updatedOption, { id: id, price: price, count: count }];
    }
    setSelectedItems(updatedOption);
    setOptionIds(updatedOption.map(x => x.id));
    updatedOption.forEach(item => {
      total += item.count * item.price;
    })
    formikRef.current.setFieldValue("total", total);
  }

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <input type="text"
      ref={ref}
      placeholder={`${strings.enterReservationDate} *`}
      name="reservationDate"
      value={value}
      onClick={onClick}
      className={"form-control form-control1 form-control-lg form-control-solid"} />
  ));

  return (
    <>
      {props.isEditingRequest && (<Loading />)}
      {!props.isEditingRequest &&
        <div className="customer-data cus2 mt-5">
          <div className="card animate__animated  animate__backInLeft">
            <div className="card-header border-0 d-flex align-items-center">
              <h3 className="card-title col-9">
                <span className="card-label fw-bolder text-dark">
                  {strings.editRequest}
                </span>
              </h3>
            </div>
            <div id="kt_account_profile_details" className="collapse show">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  total: props.editedRequestInRequests.totalAmount,
                  count: props.editedRequestInRequests.count,
                  reservationDate: new Date(props.editedRequestInRequests.reservationDate),
                }}
                validationSchema={Yup.object().shape({
                  count: Yup.number()
                    .integer(strings.invalidInputs)
                })}
                onSubmit={(fields) => {
                  let isValid = true;

                  if (props.userInRequests == null) {
                    setUserError(strings.pleaseSelectUser)
                    isValid = false;
                  }

                  if (props.reservationTypeInRequests == null) {
                    setReservationTypeError(strings.pleaseSelectReservationType)
                    isValid = false;
                  }

                  if (props.fromTimeInRequests == null) {
                    setFromTimeError(strings.pleaseSelectFromTime);
                    isValid = false;
                  }
                  if (props.toTimeInRequests == null) {
                    setToTimeError(strings.pleaseSelectToTime);
                    isValid = false;
                  }

                  if (props.reservationTypeInRequests !== null && props.reservationTypeInRequests.reservationTypeId == ReservationTypesConstant.SchoolTrip && fields.count < 1) {
                    formikRef.current.setFieldError("count", strings.pleaseEnterCorrectCount)
                    isValid = false;
                  }

                  if (isValid) {

                    let startDate = moment(fields.reservationDate).toDate();
                    const splittedStartTimeArr = props.fromTimeInRequests.fromTime.split(":");
                    startDate.setHours(splittedStartTimeArr[0]);
                    startDate.setMinutes(splittedStartTimeArr[1]);

                    let endDate = moment(fields.reservationDate).toDate();
                    const splittedEndTimeArr = props.toTimeInRequests.toTime.split(":");
                    endDate.setHours(splittedEndTimeArr[0]);
                    endDate.setMinutes(splittedEndTimeArr[1]);

                    const requestViewModel = {
                      id: props.editedRequestInRequests.id,
                      userId: props.userInRequests.value,
                      reservationTypeSettingId: props.reservationTypeInRequests.value,
                      reservationScheduleId: props.fromTimeInRequests.reservationScheduleId,
                      reservationDate: moment(fields.reservationDate).format("YYYY-MM-DD HH:mm:ss"),
                      fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                      count: fields.count,
                      amount: fields.total,
                      totalAmount: fields.total,
                      ticketOptionsViewModel: selectedItems,
                      fromTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                      toTime: moment(endDate).format("YYYY-MM-DD HH:mm:ss")
                    };

                    props.editRequest(requestViewModel);
                  }
                }}

                render={({
                  errors,
                  touched,
                  isValidating,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  setFieldError,
                  handleBlur,
                  handleChange
                }) => (
                  <Form id="kt_account_profile_details_form" className="form1">
                    <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.userName}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.usersInRequests}
                            placeholder={strings.selectUser}
                            value={props.userInRequests}
                            onChange={handleUserChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGetUsersInRequests}
                            className={userError != null ? "is-invalid" : ""}
                          />
                          {userError && (
                            <div className="invalid-feedback block">
                              {userError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.reservationType}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="reservationType"
                            options={props.reservationTypesInRequests}
                            placeholder={strings.selectReservationType}
                            value={props.reservationTypeInRequests}
                            onChange={handleReservationTypeChanges}
                            isSearchable
                            isLoading={props.isGetReservationTypesInRequests}
                            className={reservationTypeError != null ? "is-invalid" : ""}
                          />
                          {reservationTypeError && (
                            <div className="invalid-feedback block">
                              {reservationTypeError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.reservationDate}
                        </label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <DatePicker placeholderText={strings.from}
                            customInput={<StartDateInput />}
                            locale={strings.getLanguage() === LanguagesConstant.AR ?
                              "ar" : "en"}
                            selected={values.reservationDate}
                            onChange={handleReservationDateChange}
                            minDate={new Date(moment())}
                            dateFormat="dd/MM/yyyy"
                            className={reservationDateError !== null ? 'red-border' : ''} />
                          {
                            reservationDateError && (
                              <div className="invalid-feedback block">
                                {reservationDateError}
                              </div>
                            )
                          }

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">{strings.fromTime}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="fromTime"
                            options={props.fromTimesInRequests}
                            placeholder={strings.selectFromTime}
                            value={props.fromTimeInRequests}
                            onChange={handleFromTimeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGettingFromTimesInRequests}
                            className={fromTimeError != null ? "is-invalid" : ""}
                          />
                          {
                            fromTimeError && (
                              <div className="invalid-feedback d-block">
                                {fromTimeError}
                              </div>
                            )
                          }

                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">{strings.toTime}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Select
                            name="toTime"
                            options={props.toTimesInRequests}
                            placeholder={strings.selectToTime}
                            value={props.toTimeInRequests}
                            onChange={handleToTimeChanges}
                            isSearchable
                            isClearable
                            isLoading={props.isGettingToTimesInRequests}
                            className={toTimeError != null ? "is-invalid" : ""}
                          />
                          {
                            toTimeError && (
                              <div className="invalid-feedback d-block">
                                {toTimeError}
                              </div>
                            )
                          }
                        </div>
                      </div>
                      {
                        props.reservationTypeInRequests != null
                        && props.reservationTypeInRequests.reservationTypeId == ReservationTypesConstant.SchoolTrip
                        && (
                          <div className="col-lg-6 col-sm-12 mb-3">
                            <label className="col-lg-6 col-form-label required">
                              {strings.count}</label>
                            <div className="col-lg-10 col-sm-12 fv-row">
                              <Field
                                type="number"
                                name="count"
                                maxLength={3}
                                className={
                                  "form-control" +
                                  (errors.count &&
                                    touched.count
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder={`${strings.enterCount} *`}
                                onChange={(e) => {
                                  handleChange(e);
                                  changeSchoolTourCount(e.target.value);
                                }}
                              />
                              <ErrorMessage
                                name="count"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                          </div>
                        )
                      }
                      <div className="col-lg-6 col-sm-12 mb-3">
                        <label className="col-lg-6 col-form-label required">
                          {strings.totalAmount}</label>
                        <div className="col-lg-10 col-sm-12 fv-row">
                          <Field
                            type="text"
                            name="total"
                            className={
                              "form-control" +
                              (errors.total &&
                                touched.total
                                ? " is-invalid"
                                : "")
                            }
                            //placeholder={`${strings.enterCount} *`}
                            disabled={true}
                          />
                        </div>

                      </div>
                      {
                        props.ticketOptionsInRequests !== []
                        && props.ticketOptionsInRequests.length > 0
                        && (
                          <div className="row border p-5 my-5">
                            <span className="card-label fw-bolder text-dark">
                              {strings.ticketOptions}
                            </span>
                            {
                              props.ticketOptionsInRequests.map((item, index) => {
                                return <div key={index} className="col-lg-6 col-sm-12 my-5">
                                  <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">{item.label}</label>
                                    <div class="col-sm-8">
                                      {
                                        item.isInput ?
                                          <input maxLength={3} type="number" class="form-control" placeholder={`${strings.enterCount} *`} onBlur={(e) => {
                                            if (e.target.value != "") {
                                              changeCount(item.value, item.price, parseInt(e.target.value));
                                            } else {
                                              changeCount(item.value, item.price, 0);
                                            }
                                          }} /> :
                                          <div id={`flexCheckDev${index}`} className="form-check mt-1">
                                            <input className="form-check-input mt-1" checked={optionIds.includes(item.value)} onChange={() => {
                                              if (optionIds.includes(item.value)) {
                                                changeCount(item.value, item.price, 0);
                                              } else {
                                                changeCount(item.value, item.price, 1);
                                              }
                                            }} type="checkbox" id={`flexCheckDefault${index}`} />
                                          </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              })
                            }
                          </div>
                        )
                      }



                      <div className="card-footer d-flex justify-content-start py-6 px-9" style={{ backgroundColor: "white" }}>
                        <div className="col-11">
                          <button type="submit" className="btn fs-6" style={{ backgroundColor: "#009ef7", borderColor: "#009ef7", color: "white", borderRadius: "0.75rem" }} id="kt_account_profile_details_submit"
                          >
                            {strings.save}
                          </button>
                        </div>

                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}
export default Edit;
