export const ADD_NEW_RESERVATION_TYPE_SETTING = 'ADD_NEW_RESERVATION_TYPE_SETTING';
export const ADD_NEW_RESERVATION_TYPE_SETTING_SUCCESS = 'ADD_NEW_RESERVATION_TYPE_SETTING_SUCCESS';
export const ADD_NEW_RESERVATION_TYPE_SETTING_FAILURE = 'ADD_NEW_RESERVATION_TYPE_SETTING_FAILURE';

export const addNewReservationTypeSetting = (reservationTypeSettingData) => ({
    type: ADD_NEW_RESERVATION_TYPE_SETTING,
    reservationTypeSettingData
});

export const addNewReservationTypeSettingSuccess = (data) => ({
    type: ADD_NEW_RESERVATION_TYPE_SETTING_SUCCESS,
    payload: { data }
});

export const addNewReservationTypeSettingFailure = (message) => ({
    type: ADD_NEW_RESERVATION_TYPE_SETTING_FAILURE,
    payload: { message }
});