import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import strings from '../../../localizations/strings';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Modal, Button, Container, Row, Col, InputGroup, FormControl, Pagination } from "react-bootstrap";
import { DisplayTypesInRolesConstant } from '../../../constants/Roles/Roles/DisplayTypesInRolesConstant';
import Loading from "../../Shared/Loading/Loading";

function Edit(props) {

    const formikRef = React.useRef(null);

    useEffect(() => {
        if (props.editRoleResult !== null && props.editRoleResult.isSuccess) {
            Swal.fire(
                `! ${strings.editedSuccessfully}`,
                props.editRoleResult.message,
                'success'
            ).then(result => {
                props.updateDisplayTypeInRoles(DisplayTypesInRolesConstant.LIST)
            }
            )
        }
    }, [props.editRoleResult])

    useEffect(() => {
        if (props.editRoleError !== null) {
            Swal.fire(
                `! ${strings.error}`,
                props.editRoleError,
                'error'
            ).then(result => {

            }
            )
        }
    }, [props.editRoleError])

    function checkRoleNameEnAvailabilityInRoles(value) {
        if (value !== undefined && value !== "") {
            props.checkRoleNameEnAvailabilityInRoles(value,props.editedRoleInRoles.id);
        }
    }

    function checkRoleNameArAvailabilityInRoles(value) {
        if (value !== undefined && value !== "") {
            props.checkRoleNameArAvailabilityInRoles(value,props.editedRoleInRoles.id);
        }
    }

    return (
        <>
            {props.isCreateNewRole && (<Loading />)}
            {!props.isCreateNewRole &&
                (<div className="customer-data cus2 mt-5">
                    <div className="card animate__animated  animate__backInLeft">
                        <div className="card-header border-0 d-flex align-items-center">
                            <h3 className="card-title col-9">
                                <span className="card-label fw-bolder text-dark">
                                    {strings.editRole}
                                </span>
                            </h3>
                        </div>
                        <div id="kt_account_profile_details" className="collapse show">
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize={true}
                                initialValues={{
                                    arabicName: props.editedRoleInRoles !== null ? props.editedRoleInRoles.nameAr : '',
                                    englishName: props.editedRoleInRoles !== null ? props.editedRoleInRoles.nameEn : '',
                                }}
                                validationSchema={Yup.object().shape({
                                    arabicName: Yup.string()
                                        .required(strings.nameArRequired),
                                    englishName: Yup.string()
                                        .required(strings.nameEnRequired)
                                })}
                                onSubmit={fields => {
                                    var role = {
                                        id:props.editedRoleInRoles.id,
                                        nameAr: fields.arabicName,
                                        nameEn: fields.englishName,
                                    }

                                    props.editRole(role);

                                }}
                                render={({ errors, status, touched, handleChange, handleBlur, values, setFieldValue,setFieldTouched }) => (
                                    <Form id="kt_account_profile_details_form" className="form1">
                                        <div className="card-body row mx-0 border-top d-flex" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                                            <div className="col-lg-6 col-sm-12 mb-3">
                                                <label className="col-lg-6 col-form-label required">
                                                    {strings.arabicName}
                                                </label>
                                                <div className="col-lg-10 col-sm-12 fv-row">
                                                    <Field
                                                        type="text"
                                                        name="arabicName"
                                                        className={"form-control form-control1 form-control-lg form-control-solid" + (errors.arabicName && touched.arabicName ? ' is-invalid' : '')}
                                                        placeholder={`${strings.enterNameAr} *`}
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setFieldTouched('arabicName');
                                                        }}
                                                        onBlur={e => {
                                                            handleBlur(e);
                                                            checkRoleNameArAvailabilityInRoles(e.target.value);
                                                        }}
                                                    />
                                                    {
                                                        errors.arabicName && touched.arabicName
                                                        && <ErrorMessage name="arabicName" component="div"
                                                            className="invalid-feedback" />
                                                    }
                                                    {
                                                        !errors.arabicName
                                                        && !props.isRoleNameArAvailableInRoles
                                                        && (
                                                            <div className="invalid-feedback d-inline-block">
                                                                {strings.arabicNameEnteredBefore}
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12 mb-3">
                                                <label className="col-lg-6 col-form-label required">
                                                    {strings.englishName}
                                                </label>
                                                <div className="col-lg-10 col-sm-12 fv-row">
                                                    <Field
                                                        type="text"
                                                        name="englishName"
                                                        className={"form-control form-control1 form-control-lg form-control-solid" + (errors.englishName && touched.englishName ? ' is-invalid' : '')}
                                                        placeholder={`${strings.enterNameEn} *`}
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setFieldTouched('englishName');
                                                        }}
                                                        onBlur={e => {
                                                            handleBlur(e);
                                                            checkRoleNameEnAvailabilityInRoles(e.target.value);
                                                        }}
                                                    />
                                                    {
                                                        errors.englishName && touched.englishName
                                                        && <ErrorMessage name="englishName" component="div"
                                                            className="invalid-feedback" />
                                                    }
                                                    {
                                                        !errors.englishName
                                                        && !props.isRoleNameEnAvailableInRoles
                                                        && (
                                                            <div className="invalid-feedback d-inline-block">
                                                                {strings.englishNameEnteredBefore}
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-between p-3">
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn fs-6"
                                                    style={{
                                                        backgroundColor: "#009ef7",
                                                        borderColor: "#009ef7",
                                                        color: "white",
                                                        borderRadius: "0.75rem",
                                                    }}
                                                    id="kt_account_profile_details_submit"
                                                //	onClick={done}
                                                >
                                                    {strings.save}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </div>
                </div>
                )
            }
        </>
    );

}
export default Edit
