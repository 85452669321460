import {
    GET_EDITED_NEW,
    GET_EDITED_NEW_SUCCESS,
    GET_EDITED_NEW_FAILURE
} from '../../actions/News/getEditedNewActions';

import { DisplayTypesInNewsConstant } from '../../constants/News/DisplayTypesInNewsConstant';

export function getEditedNewReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_NEW:
            return {
                ...state,
                isGetEditedNew: true,
                getEditedNewError: null, 
            };
        case GET_EDITED_NEW_SUCCESS:
            console.log('get edited new reducer');
            return {
                ...state,
                isGetEditedNew: false,
                getEditedNewError: null,
                editedNewInNews:state.newsInNews.filter(x=>x.id == action.payload.newId )[0],

            };
        case GET_EDITED_NEW_FAILURE:
            console.log('get edited new fail reducer');
            return {
                ...state,
                isGetEditedNew: false,
                getEditedNewError: action.payload.message,
            };
        default:
            return state;
}}