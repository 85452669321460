export const GET_CHARTS_DATA = 'GET_CHARTS_DATA';
export const GET_CHARTS_DATA_SUCCESS = 'GET_CHARTS_DATA_SUCCESS';
export const GET_CHARTS_DATA_FAILURE = 'GET_CHARTS_DATA_FAILURE';

export const getChartsData = () => ({
    type: GET_CHARTS_DATA,
});

export const getChartsDataSuccess = (data) => ({
    type: GET_CHARTS_DATA_SUCCESS,
    payload: { data }
});

export const getChartsDataFailure = (message) => ({
    type: GET_CHARTS_DATA_FAILURE,
    payload: { message }
});