export const UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS = 'UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS';
export const UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS = 'UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS';
export const UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE = 'UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE';

export const updateReservationTypesInTicketOptions = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS,
    reservationType
}); 

export const updateReservationTypesInTicketOptionsSuccess = (reservationType) => ({
    type: UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_SUCCESS,
    payload: { reservationType }
});

export const updateReservationTypesInTicketOptionsFailure = (message) => ({
    type: UPDATE_RESERVATION_TYPES_IN_TICKET_OPTIONS_FAILURE,
    payload: { message }
});