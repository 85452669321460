export const UPDATE_STATUS_RESERVATION_SCHEDULE = 'UPDATE_STATUS_RESERVATION_SCHEDULE';
export const UPDATE_STATUS_RESERVATION_SCHEDULE_SUCCESS = 'UPDATE_STATUS_RESERVATION_SCHEDULE_SUCCESS';
export const UPDATE_STATUS_RESERVATION_SCHEDULE_FAILURE = 'UPDATE_STATUS_RESERVATION_SCHEDULE_FAILURE';

export const updateStatusReservationSchedule = (reservationScheduleId) => ({
    type: UPDATE_STATUS_RESERVATION_SCHEDULE,
    reservationScheduleId
});

export const updateStatusReservationScheduleSuccess = (data) => ({
    type: UPDATE_STATUS_RESERVATION_SCHEDULE_SUCCESS,
    payload: { data }
});

export const updateStatusReservationScheduleFailure = (message) => ({
    type: UPDATE_STATUS_RESERVATION_SCHEDULE_FAILURE,
    payload: { message }
});