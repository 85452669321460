import {
    GET_EDITED_REQUEST,
    GET_EDITED_REQUEST_SUCCESS,
    GET_EDITED_REQUEST_FAILURE
} from '../../actions/Requests/getEditedRequestActions';

export function getEditedRequestReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_REQUEST:
            return {
                ...state,
                isGetEditedRequest: true,
                getEditedRequestError: null, 
            };
        case GET_EDITED_REQUEST_SUCCESS:
            return {
                ...state,
                isGetEditedRequest: false,
                getEditedRequestError: null,
                editedRequestInRequests:state.requestsInReservations.filter(x=> x.id == action.payload.requestId)[0],
            };
        case GET_EDITED_REQUEST_FAILURE:
            return {
                ...state,
                isGetEditedRequest: false,
                getEditedRequestError: action.payload.message,
            };
        default:
            return state;
}}