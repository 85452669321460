import React, { useEffect,useState } from "react";
import strings from '../../localizations/strings';
import { DisplayTypesInNotificationSettingsConstant } from "../../constants/NotificationSettings/DisplayTypesInNotificationSettingsConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import { deleteRow } from "./Delete";
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import Loading from '../Shared/Loading/Loading'

function NotificationSetting(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getNotificationSettingsInNotificationSettings(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getNotificationSettingsInNotificationSettings();
  }, [])

  useEffect(() => {
    if (
      props.deleteNotificationSettingResult !== null &&
      props.deleteNotificationSettingResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deleted}`, props.deleteNotificationSettingResult.message, "success").then(result => {
        props.getNotificationSettingsInNotificationSettings(props.notificationSettingsPageNumberInNotificationSettings, props.notificationSettingsPageSizeInNotificationSettings,search);
      });
    }
  }, [props.deleteNotificationSettingResult]);

  useEffect(() => {
    if (
      props.deleteNotificationSettingError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteNotificationSettingError, "error");
    }
  }, [props.deleteNotificationSettingError]);

  useEffect(() => {
    if (props.updateStatusNotificationSettingResult !== null && props.updateStatusNotificationSettingResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusNotificationSettingResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusNotificationSettingResult]);

  useEffect(() => {
    if (props.updateStatusNotificationSettingError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusNotificationSettingError, "error");
    }
  }, [props.updateStatusNotificationSettingError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.notificationSettingsPageNumberInNotificationSettings) {
      props.getNotificationSettingsInNotificationSettings(pageNumber, props.notificationSettingsPageSizeInNotificationSettings,search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getNotificationSettingsInNotificationSettings(1, props.notificationSettingsPageSizeInNotificationSettings, selected.target.value)
  }

  return (
    <>
      <div className="customer-data mt-5">
      {
                   props.isGetNotificationSettingsInNotificationSettings
                     && (<Loading />)
                         }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.notificationSettingsTotalCountInNotificationSettings}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.notificationSettingsPageSizeInNotificationSettings)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.notificationSettingsTotalCountInNotificationSettings}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-3">
            <table className="table align-middle text-center table-bordered border-bottom">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                  <th className="min-w-125px px-0">{strings.notificationTypeName}</th>
                  <th className="min-w-125px px-0">{strings.roles}</th>
                  <th className="min-w-125px px-0">{strings.textEn}</th>
                  <th className="min-w-125px px-0">{strings.textAr}</th>
                  <th className="min-w-125px px-0">{strings.titleEn}</th>
                  <th className="min-w-125px px-0">{strings.titleAr}</th>
                  <th className='text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn'>{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
              
                {
                  props.notificationSettingsInNotificationSettings !== []
                  && props.notificationSettingsInNotificationSettings.length > 0
                  && props.notificationSettingsInNotificationSettings.map((notificationSetting, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{index + 1}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() == LanguagesConstant.EN ? notificationSetting.notificationTypeNameEn : notificationSetting.notificationTypeNameAr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() == LanguagesConstant.EN ? notificationSetting.notificationRoleListNameEn : notificationSetting.notificationRoleListNameAr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {notificationSetting.textEn}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {notificationSetting.textAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {notificationSetting.titleEn}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {notificationSetting.titleAr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action1">
                              <a onClick={() => {
                                props.getEditedNotificationSetting(notificationSetting.id);
                                props.updateDisplayTypeInNotificationSettings(
                                  DisplayTypesInNotificationSettingsConstant.EDIT
                                )
                              }}>

                                <i className="fas fa-edit edit"></i>
                              </a>

                            </div>
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, notificationSetting.id) }}>
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {
              props.notificationSettingsTotalCountInNotificationSettings !== null
              && props.notificationSettingsTotalCountInNotificationSettings > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.notificationSettingsPageNumberInNotificationSettings - 1) * props.notificationSettingsPageSizeInNotificationSettings) + 1} {strings.to} {(props.notificationSettingsPageNumberInNotificationSettings * props.notificationSettingsPageSizeInNotificationSettings) > props.notificationSettingsTotalCountInNotificationSettings ? props.notificationSettingsTotalCountInNotificationSettings : (props.notificationSettingsPageNumberInNotificationSettings * props.notificationSettingsPageSizeInNotificationSettings)} {strings.of} {props.notificationSettingsTotalCountInNotificationSettings} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.notificationSettingsPageNumberInNotificationSettings}
                    itemsCountPerPage={props.notificationSettingsPageSizeInNotificationSettings}
                    totalItemsCount={props.notificationSettingsTotalCountInNotificationSettings}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationSetting;