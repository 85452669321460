import {
    UPDATE_STATUS_DISCOUNT,
    UPDATE_STATUS_DISCOUNT_SUCCESS,
    UPDATE_STATUS_DISCOUNT_FAILURE
} from '../../actions/Discounts/updateStatusDiscountActions';

import { DisplayTypesInDiscountsConstant } from '../../constants/Discounts/DisplayTypesInDiscountsConstant';

export function updateStatusDiscountReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_DISCOUNT:
            return {
                ...state,
                isUpdatingStatusDiscount: true,
                updateStatusDiscountError: null,
            };
        case UPDATE_STATUS_DISCOUNT_SUCCESS:
            return {
                ...state,
                isUpdatingStatusDiscount: false,
                updateStatusDiscountError: null,
                updateStatusDiscountResult: action.payload.data,
                discountsInDiscounts: state.discountsInDiscounts.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
                //  displayTypeInDiscounts: DisplayTypesInDiscountsConstant.LIST,
            };
        case UPDATE_STATUS_DISCOUNT_FAILURE:
            return {
                ...state,
                isUpdatingStatusDiscount: false,
                updateStatusDiscountError: action.payload.message,
            };
        default:
            return state;
    }
}