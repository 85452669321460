export const GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS = 'GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS';
export const GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_SUCCESS = 'GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_SUCCESS';
export const GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_FAILURE = 'GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_FAILURE';

export const getUnpaidReservationsInReservations = (pageNumber = 1, pageSize = 10,search = null,selectedDate=null) => ({
    type: GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS,
    pageNumber,
    pageSize,
    search,
    selectedDate
});

export const getUnpaidReservationsInReservationsSuccess = (unpaidReservations, pageNumber, pageSize) => ({
    type: GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    payload: { unpaidReservations, pageNumber, pageSize }
});

export const getUnpaidReservationsInReservationsFailure = (message) => ({
    type: GET_UN_PAID_RESERVATIONS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});