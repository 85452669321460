import React,{useState,useEffect} from "react";
import strings from "../../localizations/strings";
import {Button,Pagination} from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
import { DisplayTypesInReservationSchedulesConstant } from "../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant";
import ReservationSchedule from './ReservationSchedule';
import Add from './Add';
import Edit from './Edit';
function ReservationSchedules(props) {

    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.LIST ? strings.reservationSchedule :
                            props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.ADD ? strings.create :
                                props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.EDIT ? strings.edit : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>

                            {
                                props.displayTypeInReservationSchedules !== DisplayTypesInReservationSchedulesConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInReservationSchedules(DisplayTypesInReservationSchedulesConstant.LIST)}>
                                        {strings.reservationSchedule}
                                    </Button> : null}

                            {props.displayTypeInReservationSchedules !== DisplayTypesInReservationSchedulesConstant.ADD ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInReservationSchedules(DisplayTypesInReservationSchedulesConstant.ADD) }}>
                                    {strings.new}
                                </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>

                            {props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.LIST && (<ReservationSchedule {...props} />)}
                            {props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.ADD && (<Add {...props} />)}
                            {props.displayTypeInReservationSchedules === DisplayTypesInReservationSchedulesConstant.EDIT && (<Edit {...props} />)}

                        </>

                    </div>
                </div>
            </div>
        </>
    );
}
export default ReservationSchedules