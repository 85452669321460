export const DELETE_COMPANY_RESERVATION = 'DELETE_COMPANY_RESERVATION';
export const DELETE_COMPANY_RESERVATION_SUCCESS = 'DELETE_COMPANY_RESERVATION_SUCCESS';
export const DELETE_COMPANY_RESERVATION_FAILURE = 'DELETE_COMPANY_RESERVATION_FAILURE';

export const deleteCompanyReservation = (companyReservationId) => ({
    type: DELETE_COMPANY_RESERVATION,
    companyReservationId
});

export const deleteCompanyReservationSuccess = (data) => ({
    type: DELETE_COMPANY_RESERVATION_SUCCESS,
    payload: { data }
});

export const deleteCompanyReservationFailure = (message) => ({
    type: DELETE_COMPANY_RESERVATION_FAILURE,
    payload: { message }
});