import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
   CHECK_MOBILE_AVAILABILITY_IN_USERS,
    checkMobileAvailabilityInUsersSuccess,
    checkMobileAvailabilityInUsersFailure
} from  '../../actions/Users/checkMobileAvailabilityInUsersActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";


export function checkMobileAvailabilityInUsersEpic(action$) {
    return action$.pipe(
        ofType(CHECK_MOBILE_AVAILABILITY_IN_USERS),
        switchMap(action => {

            const queryString= action.userId !== null ? `?mobile=${action.mobile.replace('+','%2B')}&userId=${action.userId}` : `?mobile=${action.mobile.replace('+','%2B')}`;

            const ajaxConfigObj = {
                url:  url + `User/CheckMobileAvailability${queryString}`,
                method: 'Post',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
               return of(checkMobileAvailabilityInUsersSuccess(response.data));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(checkMobileAvailabilityInUsersFailure(message));
            }
        }),
        catchError(error =>  of(checkMobileAvailabilityInUsersFailure(strings.somethingWrongHappened)))
    )
}

