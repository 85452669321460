import {
    GET_RESERVATION_DETAILS_WITH_CODE,
    GET_RESERVATION_DETAILS_WITH_CODE_SUCCESS,
    GET_RESERVATION_DETAILS_WITH_CODE_FAILURE
} from '../../actions/ReservationDetails/getReservationDetailsWithCodeActions';

export function getReservationDetailsWithCodeReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_DETAILS_WITH_CODE:
            return {
                ...state,
                isGetReservationDetailsWithCode: true,
                getReservationDetailsWithCodeError: null,
            };
        case GET_RESERVATION_DETAILS_WITH_CODE_SUCCESS:
            return {
                ...state,
                isGetReservationDetailsWithCode: false,
                reservationDetailsWithCode: action.payload.reservationViewModel,
                getReservationDetailsWithCodeError: null
            };
        case GET_RESERVATION_DETAILS_WITH_CODE_FAILURE:
            return {
                ...state,
                isGetReservationDetailsWithCode: false,
                getReservationDetailsWithCodeError: action.payload
            };
        default:
            return state;
    }
}