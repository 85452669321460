export const GET_DISCOUNTS_IN_DISCOUNTS = 'GET_DISCOUNTS_IN_DISCOUNTS';
export const GET_DISCOUNTS_IN_DISCOUNTS_SUCCESS = 'GET_DISCOUNTS_IN_DISCOUNTS_SUCCESS';
export const GET_DISCOUNTS_IN_DISCOUNTS_FAILURE = 'GET_DISCOUNTS_IN_DISCOUNTS_FAILURE';

export const getDiscountsInDiscounts = (pageNumber = 1, pageSize = 10,search = null) => ({
    type: GET_DISCOUNTS_IN_DISCOUNTS,
    pageNumber,
    pageSize,
    search
});

export const getDiscountsInDiscountsSuccess = (discounts, pageNumber, pageSize) => ({
    type: GET_DISCOUNTS_IN_DISCOUNTS_SUCCESS,
    payload: { discounts, pageNumber, pageSize }
});

export const getDiscountsInDiscountsFailure = (message) => ({
    type: GET_DISCOUNTS_IN_DISCOUNTS_FAILURE,
    payload: { message }
});