import {
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE
} from '../../actions/Users/editUserActions';

export function editUserReducer(state, action) {
    switch (action.type) {
        case EDIT_USER:
            return {
                ...state,
                isEditingUser: true,
                editUserError: null,
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                isEditingUser: false,
                editUserError: null,
                editUserResult: action.payload.data,
            };
        case EDIT_USER_FAILURE:
            return {
                ...state,
                isEditingUser: false,
                editUserError: action.payload.message,
            };
        default:
            return state;
}}