export const UPDATE_STATUS_ROLE = 'UPDATE_STATUS_ROLE';
export const UPDATE_STATUS_ROLE_SUCCESS = 'UPDATE_STATUS_ROLE_SUCCESS';
export const UPDATE_STATUS_ROLE_FAILURE = 'UPDATE_STATUS_ROLE_FAILURE';

export const updateStatusRole = (roleId,notes = null) => ({
    type: UPDATE_STATUS_ROLE,
    roleId,
    notes
});

export const updateStatusRoleSuccess = (data) => ({
    type: UPDATE_STATUS_ROLE_SUCCESS,
    payload: { data }
});

export const updateStatusRoleFailure = (message) => ({
    type: UPDATE_STATUS_ROLE_FAILURE,
    payload: { message }
});