import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import { 
    UPDATE_USERS_IN_RESERVATIONS,
    updateUsersInReservationsSuccess,
    updateUsersInReservationsFailure
} from '../../actions/Reservations/updateUsersInReservationsActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateUsersInReservationsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_USERS_IN_RESERVATIONS),
        map(action => ({
            user:action.user
        })),
        map(response => 
            updateUsersInReservationsSuccess(response.user)
            ),
        catchError(error =>  of(updateUsersInReservationsFailure(strings.somethingWrongHappened)))
    )
}

