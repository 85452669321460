import {
    UPDATE_STATUS_USER,
    UPDATE_STATUS_USER_SUCCESS,
    UPDATE_STATUS_USER_FAILURE
} from '../../actions/Users/updateStatusUserActions';

export function updateStatusUserReducer(state, action) {
    switch (action.type) {
        case UPDATE_STATUS_USER:
            return {
                ...state,
                isUpdatingStatusUser: true,
                updateStatusUserError: null,
            };
        case UPDATE_STATUS_USER_SUCCESS:
            return {
                ...state,
                isUpdatingStatusUser: false,
                updateStatusUserError: null,
                updateStatusUserResult: action.payload.data,
                usersInUsers: state.usersInUsers.map((item, index) => {
                    if (action.payload.data.data == item.id) {
                        return {
                            ...item,
                            isActive: !item.isActive
                        }
                    }
                    return item;
                })
            };
        case UPDATE_STATUS_USER_FAILURE:
            return {
                ...state,
                isUpdatingStatusUser: false,
                updateStatusUserError: action.payload.message,
            };
        default:
            return state;
    }
}