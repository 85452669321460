import {
    GET_EDITED_TICKET_OPTION,
    GET_EDITED_TICKET_OPTION_SUCCESS,
    GET_EDITED_TICKET_OPTION_FAILURE
} from '../../actions/TicketOptions/getEditedTicketOptionActions';

import { DisplayTypesInTicketOptionsConstant } from '../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant';

export function getEditedTicketOptionReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_TICKET_OPTION:
            return {
                ...state,
                isGetEditedTicketOption: true,
                getEditedTicketOptionError: null, 
            };
        case GET_EDITED_TICKET_OPTION_SUCCESS:
            console.log('get edited ticketOption reducer');
            return {
                ...state,
                isGetEditedTicketOption: false,
                getEditedTicketOptionError: null,
                editedTicketOptionInTicketOptions:state.ticketOptionsInTicketOptions.filter(x=>x.id == action.payload.ticketOptionId)[0],
            };
        case GET_EDITED_TICKET_OPTION_FAILURE:
            console.log('get edited ticketOption fail reducer');
            return {
                ...state,
                isGetEditedTicketOption: false,
                getEditedTicketOptionError: action.payload.message,
            };
        default:
            return state;
}}