import {
    UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES,
    UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_FAILURE
} from '../../../src/actions/PausedTimes/updateDisplayTypeInPausedTimesActions';

export function updateDisplayTypeInPausedTimesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES:
            return {
                ...state,
                isUpdatingDisplayTypeInPausedTimes: true,
                updateDisplayTypeErrorInPausedTimes: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInPausedTimes: false,
                displayTypeInPausedTimes: action.payload.displayType,
                updateDisplayTypeErrorInPausedTimes: null,

                isAddNewPausedTime: false,
                addNewPausedTimeError: null,
                addNewPausedTimeResult: null,

                isDeletingPausedTime: false,
                deletePausedTimeError: null,
                deletePausedTimeResult: null,

                isEditingPausedTime: false,
                editPausedTimeError: null,
                editPausedTimeResult: null,

                isUpdatingStatusPausedTime: false,
                updateStatusPausedTimeError: null,
                updateStatusPausedTimeResult: null,

                ischeckingPausedTimeNameEnAvailabilityInPausedTimes: false,
                isPausedTimeNameEnAvailableInPausedTimes: true,
                checkPausedTimeNameEnAvailabilityErrorInPausedTimes: null,

                ischeckingPausedTimeNameArAvailabilityInPausedTimes: false,
                isPausedTimeNameArAvailableInPausedTimes: true,
                checkPausedTimeNameArAvailabilityErrorInPausedTimes: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_PAUSED_TIMES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInPausedTimes: false,
                updateDisplayTypeErrorInPausedTimes: action.payload
            };
        default:
            return state;
    }
}