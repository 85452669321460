import {
    ADD_NEW_GALLERY,
    ADD_NEW_GALLERY_SUCCESS,
    ADD_NEW_GALLERY_FAILURE
} from '../../actions/Galleries/addNewGalleryActions';

import { DisplayTypesInGalleriesConstant } from '../../constants/Galleries/DisplayTypesInGalleriesConstant';

export function addNewGalleryReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_GALLERY:
            return {
                ...state,
                isAddNewGallery: true,
                addNewGalleryError: null,
                addNewGalleryResult:null,
            };
        case ADD_NEW_GALLERY_SUCCESS:
            return { 
                ...state,
                isAddNewGallery: false,
                addNewGalleryError: null,
                addNewGalleryResult: action.payload.data,
                // displayTypeInGalleries: DisplayTypesInGalleriesConstant.LIST,
            };
        case ADD_NEW_GALLERY_FAILURE:
            return {
                ...state,
                isAddNewGallery: false,
                addNewGalleryError: action.payload.message,
            };
        default:
            return state;
}}