export const UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS = 'UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS';
export const UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateSelectedDateInCompanyReservations = (companyReservationTypeSettingId,selectedDate) => ({
    type: UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS,
    companyReservationTypeSettingId,
    selectedDate
});

export const updateSelectedDateInCompanyReservationsSuccess = (selectedDate) => ({
    type: UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { selectedDate }
});

export const updateSelectedDateInCompanyReservationsFailure = (message) => ({
    type: UPDATE_SELECTED_DATE_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});