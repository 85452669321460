import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInNewsConstant } from "../../constants/News/DisplayTypesInNewsConstant";
import strings from "../../localizations/strings";
import { urlFile } from "../../config";
import { NOIMG } from '../Shared/sharedCons';
import Loading from "../Shared/Loading/Loading";

function New(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getNewsInNews(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getNewsInNews();
  }, []);

  useEffect(() => {
    if (
      props.deleteNewResult !== null &&
      props.deleteNewResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteNewResult.message, "success").then(
        props.getNewsInNews(props.newsPageNumberInNews,props.newsPageSizeInNews,search)
      );
    }
  }, [props.deleteNewResult]);

  useEffect(() => {
    if (props.updateStatusNewResult !== null && props.updateStatusNewResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusNewResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusNewResult]);

  useEffect(() => {
    if (props.updateStatusNewError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusNewError, "error");
    }
  }, [props.updateStatusNewError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.newsPageNumberInNews) {
      props.getNewsInNews(pageNumber, props.newsPageSizeInNews, search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getNewsInNews(1, props.newsPageSizeInNews, selected.target.value)
  }
  return (
    <>
      <div className="customer-data mt-5">
      {
                             props.isGetNewsInNews
                             && (<Loading />)
                         }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.newsTotalCountInNews}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.newsPageSizeInNews)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.newsTotalCountInNews}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-0">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                  <th className="min-w-125px px-0">{strings.textAr}</th>
                  <th className="min-w-125px px-0">{strings.textEn}</th>
                  <th className="min-w-125px px-0">{strings.image}</th>
                  <th className="min-w-125px px-0">{strings.status}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                    {strings.action}
                  </th>
                </tr>
              </thead>
              <tbody className="tableBody">
             
                {props.newsInNews !== [] &&
                  props.newsInNews.length > 0 &&
                  props.newsInNews.map((newDto, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{index + 1}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{newDto.textAr}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{newDto.textEn}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              {
                                newDto.imageUrl !== null
                                && (
                                  <img
                                    src={`${urlFile}${newDto.imageUrl}`} width={50} height={50}
                                    className="h-50" alt={strings.image} />
                                )
                              }
                              {
                                newDto.imageUrl === null
                                && (
                                  <img
                                    src={NOIMG} width={50} height={50}
                                    className="h-50" alt={strings.image} />
                                )
                              }
                            </div>
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className="form-check form-switch">
                            <input className="form-check-input"
                              style={{ float: "unset", width: "50px", height: "27px" }}
                              type="checkbox"
                              id={`flexSwitchCheckChecked${index}`}
                              onChange={e =>
                                props.updateStatusNew(newDto.id)}
                              checked={newDto.isActive}
                            />
                          </div>
                        </td>
                        <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                          <div className="d-flex justify-content-center">
                            <div
                              className="actions"
                              style={{ backgroundColor: "#e4e6ef" }}
                            >
                              <div className="action1">
                                <a onClick={() => {
                                  props.getEditedNew(newDto.id);
                                  props.updateDisplayTypeInNews(
                                    DisplayTypesInNewsConstant.EDIT
                                  )
                                }}>

                                  <i className="fas fa-edit edit"></i>
                                </a>

                              </div>
                              <div className="action2">
                                <a
                                  onClick={() => { Delete(props, newDto.id) }}>
                                  <i className="fas fa-trash delete"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="card-footer">
            {
              props.newsTotalCountInNews !== null
              && props.newsTotalCountInNews > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.newsPageNumberInNews - 1) * props.newsPageSizeInNews) + 1} {strings.to} {(props.newsPageNumberInNews * props.newsPageSizeInNews) > props.newsTotalCountInNews ? props.newsTotalCountInNews : (props.newsPageNumberInNews * props.newsPageSizeInNews)} {strings.of} {props.newsTotalCountInNews} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.newsPageNumberInNews}
                    itemsCountPerPage={props.newsPageSizeInNews}
                    totalItemsCount={props.newsTotalCountInNews}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
export default New;
