import {
    ADD_NEW_COMPANY_RESERVATION,
    ADD_NEW_COMPANY_RESERVATION_SUCCESS,
    ADD_NEW_COMPANY_RESERVATION_FAILURE
} from '../../actions/CompanyReservations/addNewCompanyReservationActions';

export function addNewCompanyReservationReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_COMPANY_RESERVATION:
            return {
                ...state,
                isAddNewCompanyReservation: true,
                addNewCompanyReservationError: null,
                addNewCompanyReservationResult:null,

                isUpdateCompaniesInCompanyReservations: false,
                updateCompaniesInCompanyReservationsError: null,
                companyInCompanyReservations:null,

                isUpdatingCompanyReservationTimesInCompanyReservations: false,
                companyReservationTimeInCompanyReservations: null,
                updateCompanyReservationTimesInCompanyReservationsError: null,

                isUpdateReservationTypesInCompanyReservations: false,
                updateReservationTypesInCompanyReservationsError: null,
                reservationTypeInCompanyReservations: null,

                isUpdatingToTimesInCompanyReservations: false,
                toTimeInCompanyReservations: null,
                updateToTimesInCompanyReservationsError: null,
            };
        case ADD_NEW_COMPANY_RESERVATION_SUCCESS:
            return { 
                ...state,
                isAddNewCompanyReservation: false,
                addNewCompanyReservationError: null,
                addNewCompanyReservationResult: action.payload.data,
            };
        case ADD_NEW_COMPANY_RESERVATION_FAILURE:
            return {
                ...state,
                isAddNewCompanyReservation: false,
                addNewCompanyReservationError: action.payload.message,
            };
        default:
            return state;
}}