export const UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS = 'UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS';
export const UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_FAILURE';

export const updateDisplayTypeInSmsSettings = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS,
    displayType
});

}
export const updateDisplayTypeInSmsSettingsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInSmsSettingsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_SMS_SETTINGS_FAILURE,
    payload: message
});