import {
    MARK_ALL_AS_READ,
    MARK_ALL_AS_READ_SUCCESS,
    MARK_ALL_AS_READ_FAILURE
} from '../../actions/Notifications/markAllAsReadActions';

export function markAllAsReadReducer(state, action) {
    switch (action.type) {
        case MARK_ALL_AS_READ:
            return {
                ...state,
                isMarkAllAsRead: true,
                markAllAsReadError: null,
            };
        case MARK_ALL_AS_READ_SUCCESS:
            return {
                ...state,
                isMarkAllAsRead: false,
                markAllAsReadError: null,
                markAllAsReadResult: action.payload.data,
                notificationsUser:state.notificationsUser.map(item => {
                    if(!item.isRead){
                        return {
                            ...item,
                            isRead:true
                        };
                    }
                    return item;
                }),
                unReadNotificationsUserCount:0
            };
        case MARK_ALL_AS_READ_FAILURE:
            return {
                ...state,
                isMarkAllAsRead: false,
                markAllAsReadError: action.payload.message,
            };
        default:
            return state;
}}