export const ADD_NEW_SMS_SETTING = 'ADD_NEW_SMS_SETTING';
export const ADD_NEW_SMS_SETTING_SUCCESS = 'ADD_NEW_SMS_SETTING_SUCCESS';
export const ADD_NEW_SMS_SETTING_FAILURE = 'ADD_NEW_SMS_SETTING_FAILURE';

export const addNewSmsSetting = (smsSettingData) =>{
    console.log("action")
    return ({
    type: ADD_NEW_SMS_SETTING,
    smsSettingData
});
}
export const addNewSmsSettingSuccess = (data) => ({
    type: ADD_NEW_SMS_SETTING_SUCCESS,
    payload: { data }
});

export const addNewSmsSettingFailure = (message) => ({
    type: ADD_NEW_SMS_SETTING_FAILURE,
    payload: { message }
});