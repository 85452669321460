export const UPDATE_STATUS_USER = 'UPDATE_STATUS_USER';
export const UPDATE_STATUS_USER_SUCCESS = 'UPDATE_STATUS_USER_SUCCESS';
export const UPDATE_STATUS_USER_FAILURE = 'UPDATE_STATUS_USER_FAILURE';

export const updateStatusUser = (userId) => ({
    type: UPDATE_STATUS_USER,
    userId
});

export const updateStatusUserSuccess = (data) => ({
    type: UPDATE_STATUS_USER_SUCCESS,
    payload: { data }
});

export const updateStatusUserFailure = (message) => ({
    type: UPDATE_STATUS_USER_FAILURE,
    payload: { message }
});