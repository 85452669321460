import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    UPDATE_SCREENS_IN_ROLE_SCREENS,
    updateScreensInRoleScreensSuccess,
    updateScreensInRoleScreensFailure
} from '../../../actions/Roles/RoleScreens/updateScreensInRoleScreensActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function updateScreensInRoleScreensEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_SCREENS_IN_ROLE_SCREENS),
        map(action => ({
           screen:action.screen
        })),
        map(response => 
             
           updateScreensInRoleScreensSuccess(response.screen)
        ),
        catchError(error =>  of(updateScreensInRoleScreensFailure(strings.somethingWrongHappened)))
    )
}

