import React, { useState, useEffect } from "react";
import { DisplayTypesInReportsConstant } from "../../constants/Reports/DisplayTypesInReportsConstant";
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
import Report from './Report';
import ReportTabs from "./Tabs";
import RequestReport from "./RequestReport";
import CompanyReservationReport from "./CompanyReservationReport";
function Reports(props) {

    return (
        <>

            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                         props.displayTypeInReports === DisplayTypesInReportsConstant.LIST ? strings.reservation :
                         props.displayTypeInReports === DisplayTypesInReportsConstant.REQ_LIST ? strings.requests :
                         props.displayTypeInReports === DisplayTypesInReportsConstant.RES_COM_LIST ? strings.companyReservations :
                         props.displayTypeInReports === DisplayTypesInReportsConstant.TABS ? strings.search : 
                        ""

                    }
                    parentpage={strings.reports}
                    Listbtns={(
                        <>

                            {props.displayTypeInReports !== DisplayTypesInReportsConstant.TABS ?
                                <Button variant="sm btn-primary mx-1 text-white animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInReports(DisplayTypesInReportsConstant.TABS) }}>
                                    {strings.search}
                                </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInReports === DisplayTypesInReportsConstant.LIST &&
                                (<Report {...props} />)}
                                 {props.displayTypeInReports === DisplayTypesInReportsConstant.REQ_LIST &&
                                (<RequestReport {...props} />)}
                                 {props.displayTypeInReports === DisplayTypesInReportsConstant.RES_COM_LIST &&
                                (<CompanyReservationReport {...props} />)}
                            {/* {props.displayTypeInReports === DisplayTypesInReportsConstant.FILTER &&
                                (<Filter {...props} />)} */}
                                 {props.displayTypeInReports === DisplayTypesInReportsConstant.TABS &&
                                (<ReportTabs {...props} />)}
                        </>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Reports