import {
    GET_EDITED_USER,
    GET_EDITED_USER_SUCCESS,
    GET_EDITED_USER_FAILURE
} from '../../actions/Users/getEditedUserActions';

export function getEditedUserReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_USER:
            return {
                ...state,
                isGetEditedUser: true,
                getEditedUserError: null, 
            };
        case GET_EDITED_USER_SUCCESS:
            return {
                ...state,
                isGetEditedUser: false,
                getEditedUserError: null,
                editedUserInUsers:state.usersInUsers.filter(x=> x.id == action.payload.userId)[0],
            };
        case GET_EDITED_USER_FAILURE:
            return {
                ...state,
                isGetEditedUser: false,
                getEditedUserError: action.payload.message,
            };
        default:
            return state;
}}