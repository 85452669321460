export const UPDATE_USERS_IN_RESERVATIONS = 'UPDATE_USERS_IN_RESERVATIONS';
export const UPDATE_USERS_IN_RESERVATIONS_SUCCESS = 'UPDATE_USERS_IN_RESERVATIONS_SUCCESS';
export const UPDATE_USERS_IN_RESERVATIONS_FAILURE = 'UPDATE_USERS_IN_RESERVATIONS_FAILURE';

export const updateUsersInReservations = (user) => ({
    type: UPDATE_USERS_IN_RESERVATIONS,
    user
}); 

export const updateUsersInReservationsSuccess = (user) => ({
    type: UPDATE_USERS_IN_RESERVATIONS_SUCCESS,
    payload: { user }
});

export const updateUsersInReservationsFailure = (message) => ({
    type: UPDATE_USERS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});