export const UPDATE_STATUS_ROLE_USER = 'UPDATE_STATUS_ROLE_USER';
export const UPDATE_STATUS_ROLE_USER_SUCCESS = 'UPDATE_STATUS_ROLE_USER_SUCCESS';
export const UPDATE_STATUS_ROLE_USER_FAILURE = 'UPDATE_STATUS_ROLE_USER_FAILURE';

export const updateStatusRoleUser = (roleUserId) => ({
    type: UPDATE_STATUS_ROLE_USER,
    roleUserId,
});

export const updateStatusRoleUserSuccess = (data) => ({
    type: UPDATE_STATUS_ROLE_USER_SUCCESS,
    payload: { data }
});

export const updateStatusRoleUserFailure = (message) => ({
    type: UPDATE_STATUS_ROLE_USER_FAILURE,
    payload: { message }
});