import {
    DELETE_REQUEST,
    DELETE_REQUEST_SUCCESS,
    DELETE_REQUEST_FAILURE
} from '../../actions/Requests/deleteRequestActions';

export function deleteRequestReducer(state, action) {
    switch (action.type) {
        case DELETE_REQUEST:
            return {
                ...state,
                isDeletingRequest: true,
                deleteRequestError: null,
            };
        case DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                isDeletingRequest: false,
                deleteRequestError: null,
                deleteRequestResult: action.payload.data,
            };
        case DELETE_REQUEST_FAILURE:
            return {
                ...state,
                isDeletingRequest: false,
                deleteRequestError: action.payload.message,
            };
        default:
            return state;
}}