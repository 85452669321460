import {
    ADD_NEW_RESERVATION,
    ADD_NEW_RESERVATION_SUCCESS,
    ADD_NEW_RESERVATION_FAILURE
} from '../../actions/Reservations/addNewReservationActions';

export function addNewReservationReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_RESERVATION:
            return {
                ...state,
                isAddNewReservation: true,
                addNewReservationError: null,
                addNewReservationResult:null,
            };
        case ADD_NEW_RESERVATION_SUCCESS:
            return { 
                ...state,
                isAddNewReservation: false,
                addNewReservationError: null,
                addNewReservationResult: action.payload.data,
            };
        case ADD_NEW_RESERVATION_FAILURE:
            return {
                ...state,
                isAddNewReservation: false,
                addNewReservationError: action.payload.message,
            };
        default:
            return state;
}}