import {
    GET_EDITED_ROLE_USER,
    GET_EDITED_ROLE_USER_SUCCESS,
    GET_EDITED_ROLE_USER_FAILURE
} from '../../../actions/Roles/RoleUsers/getEditedRoleUserActions';

export function getEditedRoleUserReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_ROLE_USER:
            return {
                ...state,
                isGetEditedRoleUser: true,
                getEditedRoleUserError: null,
            };
        case GET_EDITED_ROLE_USER_SUCCESS:
            return {
                ...state,
                isGetEditedRoleUser: false,
                getEditedRoleUserError: null,
                editedRoleUserInRoleUsers: state.roleUsersInRoleUsers.filter(x=> x.id === action.payload.roleUserId)[0],
            };
        case GET_EDITED_ROLE_USER_FAILURE:
            return {
                ...state,
                isGetEditedRoleUser: false,
                getEditedRoleUserError: action.payload.message,
            };
        default:
            return state;
}}