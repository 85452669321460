import {
    DELETE_COMPANY,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAILURE
} from '../../actions/Companies/deleteCompanyActions';

export function deleteCompanyReducer(state, action) {
    switch (action.type) {
        case DELETE_COMPANY:
            return {
                ...state,
                isDeletingCompany: true,
                deleteCompanyError: null,
            };
        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                isDeletingCompany: false,
                deleteCompanyError: null,
                deleteCompanyResult: action.payload.data,
            };
        case DELETE_COMPANY_FAILURE:
            return {
                ...state,
                isDeletingCompany: false,
                deleteCompanyError: action.payload.message,
            };
        default:
            return state;
}}