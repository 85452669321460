import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_ROLE,
    getEditedRoleSuccess,
    getEditedRoleFailure
} from '../../../actions/Roles/Roles/getEditedRoleActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function getEditedRoleEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_ROLE),
        map(action => ({
            roleId:action.roleId
        })),
        map(response => 
             
            getEditedRoleSuccess(response.roleId)
            
        ),
        catchError(error => of(getEditedRoleFailure(strings.somethingWrongHappened)))
    )
}

