import {
    ADD_NEW_SMS_SETTING,
    ADD_NEW_SMS_SETTING_SUCCESS,
    ADD_NEW_SMS_SETTING_FAILURE
} from '../../actions/SmsSettings/addNewSmsSettingActions';

import { DisplayTypesInSmsSettingsConstant } from '../../constants/SmsSettings/DisplayTypesInSmsSettingsConstant';

export function addNewSmsSettingReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_SMS_SETTING:
            return {
                ...state,
                isAddNewSmsSetting: true,
                addNewSmsSettingError: null, 
            };
        case ADD_NEW_SMS_SETTING_SUCCESS:
            return {
                ...state,
                isAddNewSmsSetting: false,
                addNewSmsSettingError: null,
                addNewSmsSettingResult: action.payload.data,
              //  displayTypeInSmsSettings: DisplayTypesInSmsSettingsConstant.LIST,
            };
        case ADD_NEW_SMS_SETTING_FAILURE:
            return {
                ...state,
                isAddNewSmsSetting: false,
                addNewSmsSettingError: action.payload.message,
            };
        default:
            return state;
}}