import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS,
    updateNotificationTypesInNotificationSettingsSuccess,
    updateNotificationTypesInNotificationSettingsFailure
} from '../../actions/NotificationSettings/updateNotificationTypesInNotificationSettingsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateNotificationTypesInNotificationSettingsEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS),
        map(action => ({
            notificationType: action.notificationType
        })),
        map(response =>

            updateNotificationTypesInNotificationSettingsSuccess(response.notificationType)

        ),
        catchError(error => of(updateNotificationTypesInNotificationSettingsFailure(strings.somethingWrongHappened)))
    )
}

