import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_ROLE_USER,
    getEditedRoleUserSuccess,
    getEditedRoleUserFailure
} from '../../../actions/Roles/RoleUsers/getEditedRoleUserActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function getEditedRoleUserEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_ROLE_USER),
        map(action => ({
            roleUserId:action.roleUserId
        })),
        map(response => 
             
            getEditedRoleUserSuccess(response.roleUserId)
            
        ),
        catchError(error => of(getEditedRoleUserFailure(strings.somethingWrongHappened)))
    )
}

