import {
    UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS,
    UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateCompanyReservationTimesInCompanyReservationsActions';

export function updateCompanyReservationTimesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdatingCompanyReservationTimesInCompanyReservations: true,
                updateCompanyReservationTimesInCompanyReservationsError: null,
            };
        case UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingCompanyReservationTimesInCompanyReservations: false,
                companyReservationTimeInCompanyReservations: action.payload.selectedTime,
                updateCompanyReservationTimesInCompanyReservationsError: null

            };
        case UPDATE_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingCompanyReservationTimesInCompanyReservations: false,
                updateCompanyReservationTimesInCompanyReservationsError: action.payload
            };
        default:
            return state;
    }
}