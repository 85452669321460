import {
    GET_RESERVATION_REPORT,
    GET_RESERVATION_REPORT_SUCCESS,
    GET_RESERVATION_REPORT_FAILURE
} from '../../actions/Reports/getReservationReportActions';

export function getReservationReportReducer(state, action) {
    switch (action.type) {
        case GET_RESERVATION_REPORT:
            return {
                ...state,
                isGetReservationReport: true,
                getReservationReportError: null,
                reservationReport: [],
            };
        case GET_RESERVATION_REPORT_SUCCESS:
            return {
                ...state,
                isGetReservationReport: false,
                getReservationReportError: null,
                reservationReport: action.payload.reservationReport.data,
                reservationReportPageNumber: action.payload.reportData.pageNumber,
                reservationReportPageSize: action.payload.reportData.pageSize,
                reservationReportTotalCount: action.payload.reservationReport.total,
                reportDataFilter:action.payload.reportData
            };
        case GET_RESERVATION_REPORT_FAILURE:
            return {
                ...state,
                isGetReservationReport: false,
                getReservationReportError: action.payload.message,
            };
        default:
            return state;
}}