export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const deleteUser = (userId) => ({
    type: DELETE_USER,
    userId
});

export const deleteUserSuccess = (data) => ({
    type: DELETE_USER_SUCCESS,
    payload: { data }
});

export const deleteUserFailure = (message) => ({
    type: DELETE_USER_FAILURE,
    payload: { message }
});