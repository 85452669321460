import React, { useState, useEffect } from "react";
import "moment/locale/ar-ly";

function Loading(props) {
   
return (
        <div className="row g-6 g-xl-9 bg-white d-flex justify-content-center align-items-center text-center position-absolute mx-0 rounded"
        style={{zIndex:"1",width:"100%",height:"100%"}}>
        <div className="col-lg-12 col-xxl-12 rounded">
                 <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                   </div>
                 <div class="spinner-grow text-primary" role="status">
               <span class="visually-hidden">Loading...</span>
                  </div>
                 <div class="spinner-grow text-primary" role="status">
               <span class="visually-hidden">Loading...</span>
                  </div>
        </div>
        </div>
    );
}
export default Loading
