export const UPDATE_DISPLAY_TYPE_IN_COMPANIES = 'UPDATE_DISPLAY_TYPE_IN_COMPANIES';
export const UPDATE_DISPLAY_TYPE_IN_COMPANIES_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_COMPANIES_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_COMPANIES_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_COMPANIES_FAILURE';

export const updateDisplayTypeInCompanies = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANIES,
    displayType
});

export const updateDisplayTypeInCompaniesSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANIES_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInCompaniesFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANIES_FAILURE,
    payload: message
});