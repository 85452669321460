export const GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS = 'GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS';
export const GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_SUCCESS = 'GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_SUCCESS';
export const GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_FAILURE = 'GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_FAILURE';

export const getReservationTypeSettingsInReservationTypeSettings = (pageNumber=1,pageSize=25,search=null) => {
    console.log('get reservationTypeSettings action')
return({
    type: GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS,
    pageNumber,
    pageSize,
    search
}); 
}
export const getReservationTypeSettingsInReservationTypeSettingsSuccess = (reservationTypeSettings,pageNumber,pageSize) => ({
    type: GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_SUCCESS,
    payload: { reservationTypeSettings ,pageNumber,pageSize}
});

export const getReservationTypeSettingsInReservationTypeSettingsFailure = (message) => ({
    type: GET_RESERVATION_TYPE_SETTINGS_IN_RESERVATION_TYPE_SETTINGS_FAILURE,
    payload: { message }
});