import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import TableHeader from "../../Shared/tableHeader";
import strings from '../../../localizations/strings';
import TableStat from '../../Shared/tablestat';
import { deleteRow, deleteItems } from './Delete';
import { DisplayTypesInRoleUsersConstant } from "../../../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant";
import moment from 'moment';
import "moment/locale/ar-sa";
import { urlFile } from "../../../config";
import Pagination from "react-js-pagination";
import { LanguagesConstant } from "../../../constants/LanguagesConstant";
import Loading from "../../Shared/Loading/Loading";

function RoleUser(props) {

    const [Export, setExport] = useState(null),
        [search, setSearch] = useState(null),
        handleTableLength = (selected) => { props.getRoleUsers(props.roleInRoles.id, 1, selected.value, search); },
        handleTableExport = (selected) => { setExport(selected) };


    useEffect(() => {
        props.getRoleUsers(props.roleInRoles.id);
    }, []);

    useEffect(() => {
        if (
            props.deleteRoleUserResult !== null &&
            props.deleteRoleUserResult.isSuccess
        ) {
            Swal.fire(` ${strings.deleted}`, props.deleteRoleUserResult.message, "success").then(res => {
                props.getRoleUsers(props.roleInRoles.id, props.usersPageNumberInRoleUsers, props.usersPageSizeInRoleUsers, search);
            }
            );
        }
    }, [props.deleteRoleUserResult]);

    useEffect(() => {
        if (props.updateStatusRoleUserResult !== null && props.updateStatusRoleUserResult.isSuccess) {
            Swal.fire(
                ` ${strings.changed}`,
                props.updateStatusRoleUserResult.message,
                "success"
            ).then(res => {
                //props.getRoleUsers(props.roleInRoles.id, props.usersPageNumberInRoleUsers, props.usersPageSizeInRoleUsers, search);
            }
            );
        }
    }, [props.updateStatusRoleUserResult]);

    useEffect(() => {
        if (
            props.deleteRoleUserError !== null
        ) {
            Swal.fire(`! ${strings.error}`, props.deleteRoleUserError, "error");
        }
    }, [props.deleteRoleUserError]);

    useEffect(() => {
        if (props.updateStatusRoleUserError !== null) {
            Swal.fire(`! ${strings.error}`, props.updateStatusRoleUserError, "error");
        }
    }, [props.updateStatusRoleUserError]);

    function handlePageButtonChange(pageNumber) {
        if (pageNumber != props.usersPageNumberInRoleUsers) {
            props.getRoleUsers(props.roleInRoles.id, pageNumber, props.usersPageSizeInRoleUsers, search);
        }
    }

    function handleinputSearch(selected) {
        setSearch(selected.target.value);
        props.getRoleUsers(props.roleInRoles.id, 1, props.usersPageSizeInRoleUsers, selected.target.value)
    }

    return (
        <>
            <div className="customer-data">
            {props.isGetRoleUsers && (<Loading />)}
            <div className="card rounded-0 animate__animated  animate__backInLeft p-4"style={{backgroundColor:"#f5f8fa"}}>
                        <div className={`w-100 d-flex px-3 py-1 ${strings.getLanguage() === LanguagesConstant.EN ? 'flex-row-reverse' : 'flex-row' }`}>
                        <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInRoleUsers(DisplayTypesInRoleUsersConstant.ADD) }}>
                            {strings.new}
                        </Button>
                        </div>
                        <div className="card-header d-flex flex-column flex-column-fluid">
                            <TableHeader
                                searchPlaceholder={strings.search}
                                ExportPlaceholder={strings.Export}
                                Length={[...TableStat.Length,{label:strings.all,value:props.usersTotalCountInRoleUsers}]}
                                Export={TableStat.Export}
                                selectedLength={TableStat.Length.filter(x => x.value == props.usersPageSizeInRoleUsers)[0]}
                                selectedExport={Export}
                                cardname={strings.show}
                                rowsnumber={props.usersTotalCountInRoleUsers}
                                handleTableLength={handleTableLength}
                                handleTableExport={handleTableExport}
                                handleinputSearch={handleinputSearch}
                            />
                        </div>
                       <div className="table-responsive p-0">
                       <table className="table align-middle text-center table-bordered border-bottom m-0">
                             <thead className="tableHeader"style={{ position: "sticky", width: "100%" }}>
                               <tr className="fw-bolder text-muted text-center bg-light">
                                     <th className="min-w-125px px-0 bg-white">{strings.userName}</th>
                                     <th className="min-w-125px px-0 bg-white">{strings.email}</th>
                                     <th className="min-w-150px px-0 bg-white">{strings.status}</th>
                                     <th className="min-w-150px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                                 </tr>
                               </thead>
              <tbody className="tableBody">
            
                {props.roleUsersInRoleUsers !== [] &&
                    props.roleUsersInRoleUsers.length > 0 &&
                    props.roleUsersInRoleUsers.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                <div className='d-flex align-items-center'>
                                 <div className='d-flex justify-content-start m-auto flex-column'>
                                   <h4> {strings.getLanguage() === LanguagesConstant.AR ? user.userNameAr : user.userNameEn}</h4>
                                     </div>
                                 </div>
                                </td>

                                <td>
                                <div className='d-flex align-items-center'>
                                 <div className='d-flex justify-content-start m-auto flex-column'>
                                   <h4>{user.email}</h4>
                                     </div>
                                 </div>
                                </td>
                                <td className='text-center'>
                                  <div className="form-check form-switch">
                                    <input 
                                      className="form-check-input"
                                      style={{ float: "unset", width: "47px", height: "25px" }}
                                      type="checkbox"
                                      name="FlashesColors"
                                      id={`chkActive${index}`}
                                      checked={user.isActive}
                                      onChange={e =>
                                          props.updateStatusRoleUser(user.id)}
                                    />
                                  </div>
                                </td>
                               
                                <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                                  <div className='d-flex justify-content-center'>
                                    <div className='actions'  style={{ backgroundColor: "#e4e6ef" }}>
                                      <div className="action1">
                                      <a onClick={() => {
                                                    props.getEditedRoleUser(user.id)
                                                    props.updateDisplayTypeInRoleUsers(DisplayTypesInRoleUsersConstant.Edit)
                                                }}>

                                                    <i className="fas fa-edit edit"></i>
                                                </a>
                                      </div>
                                      <div className="action2">
                                      <a
                                                    onClick={() => { deleteRow(props, user.id) }}>
                                                    <i className="fas fa-trash delete"></i>
                                                </a>
                                      </div>
                                    </div>
                                  </div>
                                  </td>
                            </tr>
                                         );
                                                    })}
                               </tbody>

                           </table>
                       </div>
                        <div className="card-footer">
                            {
                                props.usersTotalCountInRoleUsers !== null
                                && props.usersTotalCountInRoleUsers > 0
                                && (
                                    <div className="d-flex flex-stack flex-wrap justify-content-between">
                                        <div className="fs-6 fw-bold text-gray-700"style={{padding:"6px 20px 6px 6px"}}>{strings.showing} {((props.usersPageNumberInRoleUsers - 1) * props.usersPageSizeInRoleUsers) + 1} {strings.to} {(props.usersPageNumberInRoleUsers * props.usersPageSizeInRoleUsers) > props.usersTotalCountInRoleUsers ? props.usersTotalCountInRoleUsers : (props.usersPageNumberInRoleUsers * props.usersPageSizeInRoleUsers)} {strings.of} {props.usersTotalCountInRoleUsers} {strings.entries}</div>
                                        <Pagination
                                            innerClass="pagination"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activeClass="active"
                                            activePage={props.usersPageNumberInRoleUsers}
                                            itemsCountPerPage={props.usersPageSizeInRoleUsers}
                                            totalItemsCount={props.usersTotalCountInRoleUsers}
                                            onChange={handlePageButtonChange}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
            </div>




        </>

    );
}
export default RoleUser
