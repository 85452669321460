import {
    GET_FROM_TIMES_IN_REQUESTS,
    GET_FROM_TIMES_IN_REQUESTS_SUCCESS,
    GET_FROM_TIMES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/getFromTimesInRequestsActions';

import moment from 'moment';
import "moment/locale/ar-sa";

export function getFromTimesInRequestsReducer(state, action) {
    switch (action.type) {
        case GET_FROM_TIMES_IN_REQUESTS:
            return {
                ...state,
                isGettingFromTimesInRequests: true,
                getFromTimesInRequestsError: null,
                fromTimesInRequests:[],
                fromTimeInRequests:null,
                toTimesInRequests:[],
                toTimeInRequests:null,
            };
        case GET_FROM_TIMES_IN_REQUESTS_SUCCESS:
            let indexKey = 0;
            var arr = state.editedRequestInRequests !== null ? action.payload.timeResultList.map((item, index) => {
                const reservationDate = moment(state.editedRequestInRequests.reservationDate).format("YYYY-MM-DD");
                const selectedDate = moment(action.payload.selectedDate).format("YYYY-MM-DD");
                const isSameDate = moment(reservationDate).isSame(moment(selectedDate))
                const editedReservationTime = moment(state.editedRequestInRequests.fromTime).format("HH:mm").split(":");
                const splittedStartTimeArr = item.fromTime.split(":");
                if (isSameDate && splittedStartTimeArr[0] == editedReservationTime[0] && splittedStartTimeArr[1] == editedReservationTime[1]) {
                    indexKey = index;
                }
            }) : null;
            return {
                ...state,
                isGettingFromTimesInRequests: false,
                fromTimesInRequests: action.payload.timeResultList,
                fromTimeInRequests: state.editedRequestInRequests !== null ? action.payload.timeResultList[indexKey] : null,
                selectedDateInRequests: action.payload.selectedDate,
                getFromTimesInRequestsError: null,
            };
        case GET_FROM_TIMES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isGettingFromTimesInRequests: false,
                getFromTimesInRequestsError: action.payload
            };
        default:
            return state;
    }
}