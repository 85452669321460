import {
    GET_TO_TIMES_IN_COMPANY_RESERVATIONS,
    GET_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    GET_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/getToTimesInCompanyReservationsActions';

import moment from 'moment';
import "moment/locale/ar-sa";

export function getToTimesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case GET_TO_TIMES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isGettingToTimesInCompanyReservations: true,
                getToTimesInCompanyReservationsError: null,
                toTimesInCompanyReservations:[],
                toTimeInCompanyReservations:null,
            };
        case GET_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS:
            let indexKey = 0;
            var arr = state.editedCompanyReservationInCompanyReservations !== null ? action.payload.timeResultList.map((item, index) => {
                const reservationDate = moment(state.editedCompanyReservationInCompanyReservations.reservationDate).format("YYYY-MM-DD");
                const selectedDate = moment(action.payload.selectedDate).format("YYYY-MM-DD");
                const isSameDate = moment(reservationDate).isSame(moment(selectedDate))
                const editedReservationTime = moment(state.editedCompanyReservationInCompanyReservations.toTime).format("HH:mm").split(":");
                const splittedStartTimeArr = item.toTime.split(":");
                if (isSameDate && splittedStartTimeArr[0] == editedReservationTime[0] && splittedStartTimeArr[1] == editedReservationTime[1]) {
                    indexKey = index;
                }
            }) : null;
            return {
                ...state,
                isGettingToTimesInCompanyReservations: false,
                toTimesInCompanyReservations: action.payload.timeResultList,
                toTimeInCompanyReservations: state.editedCompanyReservationInCompanyReservations !== null ? action.payload.timeResultList[indexKey] : null,
                getToTimesInCompanyReservationsError: null,
            };
        case GET_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isGettingToTimesInCompanyReservations: false,
                getToTimesInCompanyReservationsError: action.payload
            };
        default:
            return state;
    }
}