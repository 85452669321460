import {
    CANCEL_RESERVATION,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_FAILURE
} from '../../actions/Reservations/cancelReservationActions';

export function cancelReservationReducer(state, action) {
    switch (action.type) {
        case CANCEL_RESERVATION:
            return {
                ...state,
                isCancellingReservation: true,
                cancelReservationError: null,
            };
        case CANCEL_RESERVATION_SUCCESS:
            return {
                ...state,
                isCancellingReservation: false,
                cancelReservationResult:action.payload.data,
                cancelReservationError: null,
            };
        case CANCEL_RESERVATION_FAILURE:
            return {
                ...state,
                isCancellingReservation: false,
                cancelReservationError: action.payload
            };
        default:
            return state;
    }
}