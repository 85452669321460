import {
    UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS,
    UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateDisplayTypeInAttendedReservationsActions';

export function updateDisplayTypeInAttendedReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS:
            return {
                ...state,
                isUpdatingDisplayTypeInAttendedReservations: true,
                updateDisplayTypeErrorInAttendedReservations: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingDisplayTypeInAttendedReservations: false,
                displayTypeInAttendedReservations: action.payload.displayType,
                updateDisplayTypeErrorInAttendedReservations: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_ATTENDED_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInAttendedReservations: false,
                updateDisplayTypeErrorInAttendedReservations: action.payload
            };
        default:
            return state;
    }
}