import {
    UPDATE_SELECTED_DATE_IN_RESERVATIONS,
    UPDATE_SELECTED_DATE_IN_RESERVATIONS_SUCCESS,
    UPDATE_SELECTED_DATE_IN_RESERVATIONS_FAILURE
} from '../../actions/Reservations/updateSelectedDateInReservationsActions';

export function updateSelectedDateInReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_SELECTED_DATE_IN_RESERVATIONS:
            return {
                ...state,
                isUpdatingSelectedDateInReservations: true,
                updateSelectedDateInReservationsError: null,
            };
        case UPDATE_SELECTED_DATE_IN_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingSelectedDateInReservations: false,
                selectedDateInReservations: action.payload.selectedDate,
                updateSelectedDateInReservationsError: null

            };
        case UPDATE_SELECTED_DATE_IN_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingSelectedDateInReservations: false,
                updateSelectedDateInReservationsError: action.payload
            };
        default:
            return state;
    }
}