export const DELETE_CANCELLED_RESERVATION = 'DELETE_CANCELLED_RESERVATION';
export const DELETE_CANCELLED_RESERVATION_SUCCESS = 'DELETE_CANCELLED_RESERVATION_SUCCESS';
export const DELETE_CANCELLED_RESERVATION_FAILURE = 'DELETE_CANCELLED_RESERVATION_FAILURE';

export const deleteCancelledReservation = (cancelledReservationId) => ({
    type: DELETE_CANCELLED_RESERVATION,
    cancelledReservationId
});

export const deleteCancelledReservationSuccess = (data) => ({
    type: DELETE_CANCELLED_RESERVATION_SUCCESS,
    payload: { data }
});

export const deleteCancelledReservationFailure = (message) => ({
    type: DELETE_CANCELLED_RESERVATION_FAILURE,
    payload: { message }
});