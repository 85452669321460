import {
    UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS,
    UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../actions/NotificationSettings/updateNotificationTypesInNotificationSettingsActions';

export function updateNotificationTypesInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isUpdateNotificationTypesInNotificationSettings: true,
                updateNotificationTypesInNotificationSettingsError: null,
            };
        case UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                isUpdateNotificationTypesInNotificationSettings: false,
                updateNotificationTypesInNotificationSettingsError: null,
                notificationTypeInNotificationSettings: action.payload.notificationType,
            };
        case UPDATE_NOTIFICATION_TYPES_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isUpdateNotificationTypesInNotificationSettings: false,
                updateNotificationTypesInNotificationSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}