import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { concatMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
 import {
    CHANGE_PASSWORD,
    changePasswordSuccess,
    changePasswordFailure
} from '../../actions/Account/changePasswordActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function changePasswordEpic(action$) {
    return action$.pipe(
        ofType(CHANGE_PASSWORD),
        switchMap(action => {
            
            const ajaxConfigObj = {
                url:  url + `AccountApi/ChangePassword`,
                method: 'POST',
                headers: {
                    'Authorization':token,
                    "Content-Type": "application/json",
                },
                body:action.changePasswordViewModel,
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj)
            .pipe(map(data => data.response))
        }),
        mergeMap(response => {
            if (response.isSuccess) {
               return of(changePasswordSuccess(response));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(changePasswordFailure(message));
            }
        }),
        catchError(error => of(changePasswordFailure(strings.somethingWrongHappened)))
    )
}

