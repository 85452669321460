import React, { useState, useEffect } from "react";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../../Shared/toolbar";
import strings from '../../../localizations/strings';
import RoleUsersList from './RoleUser'
import Add from './Add';
import Edit from './Edit'
import { DisplayTypesInRoleUsersConstant } from "../../../constants/Roles/RoleUsers/DisplayTypesInRoleUsersConstant";

function RoleUsers(props) {

    return (
        <div className="content py-0 d-flex flex-column flex-column-fluid mx-0 px-0">

            <div className="post d-flex flex-column-fluid" id="kt_post" >
                <div id="kt_content_container" className="kt_content_container container-xxl px-0">
                    <>
                        {props.displayTypeInRoleUsers === DisplayTypesInRoleUsersConstant.LIST &&
                            (<RoleUsersList {...props} />)}
                        {props.displayTypeInRoleUsers === DisplayTypesInRoleUsersConstant.ADD &&
                            (<Add {...props} />)}
                        {props.displayTypeInRoleUsers === DisplayTypesInRoleUsersConstant.Edit &&
                            (<Edit {...props} />)}
                    </>

                </div>
            </div>
        </div>

    );
}
export default RoleUsers
