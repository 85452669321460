import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_EDITED_PAUSED_TIME,
    getEditedPausedTimeSuccess,
    getEditedPausedTimeFailure
} from '../../actions/PausedTimes/getEditedPausedTimeActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedPausedTimeEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_PAUSED_TIME),
        map(action => ({
            pausedTimeId: action.pausedTimeId
        })),
        map(response =>
            getEditedPausedTimeSuccess(response.pausedTimeId)

        ),
        catchError(error => of(getEditedPausedTimeFailure(strings.somethingWrongHappened)))
    )
}

