export const EDIT_TICKET_OPTION = 'EDIT_TICKET_OPTION';
export const EDIT_TICKET_OPTION_SUCCESS = 'EDIT_TICKET_OPTION_SUCCESS';
export const EDIT_TICKET_OPTION_FAILURE = 'EDIT_TICKET_OPTION_FAILURE';

export const editTicketOption = (ticketOptionData) =>{
    console.log(ticketOptionData) 
    return ({
    type: EDIT_TICKET_OPTION,
    ticketOptionData
});
}
export const editTicketOptionSuccess = (data) => ({
    type: EDIT_TICKET_OPTION_SUCCESS,
    payload: { data }
});

export const editTicketOptionFailure = (message) => ({
    type: EDIT_TICKET_OPTION_FAILURE,
    payload: { message }
});