import react, {Component, Fragment} from "react";
import Select from "react-select";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import strings from "../../localizations/strings";
import { LanguagesConstant } from "../../constants/LanguagesConstant";

class TableHeader extends Component{


    render() {
        return(
            <Fragment>
                <div className="d-flex flex-wrap flex-stack pb-7 mt-1">
                    <div className="d-flex flex-wrap align-items-center">
                        <h3 className="card-title align-items-start flex-column ms-2">
                            <span className="card-label fw-bold fs-5 mb-1">{this.props.cardname} ({this.props.rowsnumber})</span>
                        </h3>
                         {!this.props.hideSearchInput && (
                             <div className="d-flex align-items-center position-relative my-1 mx-3"style={{width:"30%"}}>
                             <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                      viewBox="0 0 24 24" fill="none">
                                                     <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                                           height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                                           fill="black"></rect>
                                                     <path
                                                         d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                         fill="black"></path>
                                                 </svg>
                                             </span>
                             <input type="text" id="kt_filter_search"
                                    className="form-control form-control-sm ps-9"
                                    placeholder={this.props.inputsearch} onChange={this.props.handleinputSearch}/>
                         </div>
                         )}

                         { this.props.showDateInput && (
                        <div className="d-flex align-items-center position-relative my-1"style={{width:"30%"}}>
                          <DatePicker 
                            placeholderText={strings.selectDate}
                            // locale={strings.getLanguage() === LanguagesConstant.AR ?
                            //   "ar" : "en"}
                            selected={this.props.selectedDate}
                            onChange={this.props.handleDateChange}
                           // minDate={new Date(moment())}
                           isClearable
                            dateFormat="dd/MM/yyyy"
                            className="form-control form-control-sm ps-3"
                            />
                      </div>
                         )}

                    </div>
                    <div className="d-flex flex-wrap py-2">
                         <div className="d-flex my-0">
                            <Select
                                className=" me-1"
                                value={this.props.selectedLength}
                                onChange={this.props.handleTableLength}
                                options={this.props.Length}
                            />
                            {/*{this.props.TypeHide?(''):(
                            <Select
                                className="me-1"
                                isSearchable={true}
                                defaultValue={this.props.selectedExport}
                                value={this.props.selectedExport}
                                onChange={this.props.handleTableExport}
                                options={this.props.Export}
                                placeholder={this.props.ExportPlaceholder}
                            />
                            )}*/}

                        </div> 
                    </div>
                </div>
            </Fragment>
        )
    }


}
export default TableHeader