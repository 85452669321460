import {
    GET_EDITED_TICKET_TYPE,
    GET_EDITED_TICKET_TYPE_SUCCESS,
    GET_EDITED_TICKET_TYPE_FAILURE
} from '../../actions/TicketTypes/getEditedTicketTypeActions';

import { DisplayTypesInTicketTypesConstant } from '../../constants/TicketTypes/DisplayTypesInTicketTypesConstant';

export function getEditedTicketTypeReducer(state, action) {
    switch (action.type) {
        case GET_EDITED_TICKET_TYPE:
            return {
                ...state,
                isGetEditedTicketType: true,
                getEditedTicketTypeError: null, 
            };
        case GET_EDITED_TICKET_TYPE_SUCCESS:
            console.log('get edited ticketType reducer');
            return {
                ...state,
                isGetEditedTicketType: false,
                getEditedTicketTypeError: null,
                editedTicketTypeInTicketTypes:state.ticketTypesInTicketTypes.filter(x=>x.id == action.payload.ticketTypeId )[0],
                // displayTypeInTicketTypes: DisplayTypesInTicketTypesConstant.EDIT,

                // isDeletingTicketType: false,
                // deleteTicketTypeError: null,
                // deleteTicketTypeResult:null,

            };
        case GET_EDITED_TICKET_TYPE_FAILURE:
            console.log('get edited ticketType fail reducer');
            return {
                ...state,
                isGetEditedTicketType: false,
                getEditedTicketTypeError: action.payload.message,
            };
        default:
            return state;
}}