export const EDIT_SCHOOL_TOUR_RANGE = 'EDIT_SCHOOL_TOUR_RANGE';
export const EDIT_SCHOOL_TOUR_RANGE_SUCCESS = 'EDIT_SCHOOL_TOUR_RANGE_SUCCESS';
export const EDIT_SCHOOL_TOUR_RANGE_FAILURE = 'EDIT_SCHOOL_TOUR_RANGE_FAILURE';

export const editSchoolTourRange = (schoolTourRangeData) =>{
    console.log(schoolTourRangeData) 
    return ({
    type: EDIT_SCHOOL_TOUR_RANGE,
    schoolTourRangeData
});
}
export const editSchoolTourRangeSuccess = (data) => ({
    type: EDIT_SCHOOL_TOUR_RANGE_SUCCESS,
    payload: { data }
});

export const editSchoolTourRangeFailure = (message) => ({
    type: EDIT_SCHOOL_TOUR_RANGE_FAILURE,
    payload: { message }
});