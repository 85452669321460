export const GET_EDITED_GALLERY = 'GET_EDITED_GALLERY';
export const GET_EDITED_GALLERY_SUCCESS = 'GET_EDITED_GALLERY_SUCCESS';
export const GET_EDITED_GALLERY_FAILURE = 'GET_EDITED_GALLERY_FAILURE';

export const getEditedGallery = (galleryId) => {
    console.log('get edited gallery action')
    return ({
    type: GET_EDITED_GALLERY,
    galleryId
});
}
export const getEditedGallerySuccess = (galleryId) => {
    console.log('get edited gallery success action')
    return ({
    type: GET_EDITED_GALLERY_SUCCESS,
    payload: { galleryId }
});
}
export const getEditedGalleryFailure = (message) => {
    console.log('get edited gallery fail action')
    return ({
    type: GET_EDITED_GALLERY_FAILURE,
    payload: { message }
});
}