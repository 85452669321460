import React, { useState, useEffect } from "react";
import { DisplayTypesInCancelledReservationsConstant } from "../../constants/Reservations/DisplayTypesInCancelledReservationsConstant";
import CancelledReservation from './CancelledReservation';
import strings from "../../localizations/strings";
import { Button, Pagination } from "react-bootstrap";
import Toolbar from "../Shared/toolbar";
function CancelledReservations(props) {


    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid pt-0">
                <Toolbar
                    pagename={
                        props.displayTypeInCancelledReservations === DisplayTypesInCancelledReservationsConstant.LIST ? strings.cancelledReservation :
                            props.displayTypeInCancelledReservations === DisplayTypesInCancelledReservationsConstant.DETAILS ? strings.details : ""

                    }
                    parentpage={strings.Items}
                    Listbtns={(
                        <>
                            {
                                props.displayTypeInCancelledReservations !== DisplayTypesInCancelledReservationsConstant.LIST ?
                                    <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => props.updateDisplayTypeInCancelledReservations(DisplayTypesInCancelledReservationsConstant.LIST)}>
                                        {strings.cancelledReservation}
                                    </Button> : null}


                        </>
                    )} />

                <div className="post d-flex flex-column-fluid" id="kt_post" >
                    <div id="kt_content_container" className="kt_content_container container-xxl">
                        <>
                            {props.displayTypeInCancelledReservations === DisplayTypesInCancelledReservationsConstant.LIST && (<CancelledReservation {...props} />)}

                        </>

                    </div>
                </div>
            </div>


        </>
    );
}
export default CancelledReservations