export const GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE';

export const getNotificationRolesInNotificationSettings = (notificationSettingId = null) => ({
    type: GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    notificationSettingId
});

export const getNotificationRolesInNotificationSettingsSuccess = (notificationRoles) => ({
    type: GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationRoles }
});

export const getNotificationRolesInNotificationSettingsFailure = (message) => ({
    type: GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: { message }
});