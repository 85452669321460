export const UPDATE_FROM_TIMES_IN_REQUESTS = 'UPDATE_FROM_TIMES_IN_REQUESTS';
export const UPDATE_FROM_TIMES_IN_REQUESTS_SUCCESS = 'UPDATE_FROM_TIMES_IN_REQUESTS_SUCCESS';
export const UPDATE_FROM_TIMES_IN_REQUESTS_FAILURE = 'UPDATE_FROM_TIMES_IN_REQUESTS_FAILURE';

export const updateFromTimesInRequests = (selectedTime) => ({
    type: UPDATE_FROM_TIMES_IN_REQUESTS,
    selectedTime
});

export const updateFromTimesInRequestsSuccess = (selectedTime) => ({
    type: UPDATE_FROM_TIMES_IN_REQUESTS_SUCCESS,
    payload: { selectedTime }
});

export const updateFromTimesInRequestsFailure = (message) => ({
    type: UPDATE_FROM_TIMES_IN_REQUESTS_FAILURE,
    payload: message
});