import {
    GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../actions/NotificationSettings/getNotificationRolesInNotificationSettingsActions';

export function getNotificationRolesInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isGetNotificationRolesInNotificationSettings: true,
                getNotificationRolesInNotificationSettingsError: null,
            };
        case GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                isGetNotificationRolesInNotificationSettings: false,
                getNotificationRolesInNotificationSettingsError: null,
                notificationRolesInNotificationSettings: action.payload.notificationRoles,
                notificationRoleInNotificationSettings:
                    state.editedNotificationSettingInNotificationSettings !== null
                        ? action.payload.notificationRoles.filter(
                            x => state.editedNotificationSettingInNotificationSettings.roleIds.includes(x.value)
                        )
                        : [],
            };
        case GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isGetNotificationRolesInNotificationSettings: false,
                getNotificationRolesInNotificationSettingsError: action.payload.message,
            };
        default:
            return state;
    }
}