import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Delete from "./Delete";
import Pagination from "react-js-pagination";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import { DisplayTypesInGalleriesConstant } from "../../constants/Galleries/DisplayTypesInGalleriesConstant";
import strings from "../../localizations/strings";
import { urlFile } from "../../config";
import { NOIMG } from '../Shared/sharedCons';
import Loading from "../Shared/Loading/Loading";

function Gallery(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getGalleriesInGalleries(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };
  useEffect(() => {
    props.getGalleriesInGalleries();
  }, []);

  useEffect(() => {
    if (
      props.deleteGalleryResult !== null &&
      props.deleteGalleryResult.isSuccess
    ) {
      Swal.fire("Deleted!", props.deleteGalleryResult.message, "success").then(
        props.getGalleriesInGalleries(props.galleriesPageNumberInGalleries,props.galleriesPageSizeInGalleries,search)
      );
    }
  }, [props.deleteGalleryResult]);

  useEffect(() => {
    if (props.updateStatusGalleryResult !== null && props.updateStatusGalleryResult.isSuccess) {
      Swal.fire(
        ` ${strings.changed}`,
        props.updateStatusGalleryResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusGalleryResult]);

  useEffect(() => {
    if (props.updateStatusGalleryError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusGalleryError, "error");
    }
  }, [props.updateStatusGalleryError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.galleriesPageNumberInGalleries) {
      props.getGalleriesInGalleries(pageNumber, props.galleriesPageSizeInGalleries, search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getGalleriesInGalleries(1, props.galleriesPageSizeInGalleries, selected.target.value)
  }
  return (
    <>
      <div className="customer-data mt-5">
        {props.isGetGalleriesInGalleries && (<Loading />)}
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={TableStat.Length}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.galleriesPageSizeInGalleries)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.galleriesTotalCountInGalleries}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-0">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center align-middle bg-light">
                  <th className="ps-4 min-w-125px px-0">{strings.number}</th>
                  <th className="min-w-125px px-0">{strings.image}</th>
                  <th className="min-w-125px px-0">{strings.status}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                    {strings.action}
                  </th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {props.galleriesInGalleries !== [] &&
                  props.galleriesInGalleries.length > 0 &&
                  props.galleriesInGalleries.map((galleryDto, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              <h4>{index + 1}</h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start m-auto flex-column">
                              {
                                galleryDto.picture !== null
                                && (
                                  <img
                                    src={`${urlFile}${galleryDto.picture}`} width={50} height={50}
                                    className="h-50" alt={strings.image} />
                                )
                              }
                              {
                                galleryDto.picture === null
                                && (
                                  <img
                                    src={NOIMG} width={50} height={50}
                                    className="h-50" alt={strings.image} />
                                )
                              }
                            </div>
                          </div>
                        </td>
                        <td className='text-center'>
                          <div className="form-check form-switch">
                            <input className="form-check-input"
                              style={{ float: "unset", width: "50px", height: "27px" }}
                              type="checkbox"
                              id={`flexSwitchCheckChecked${index}`}
                              onChange={e =>
                                props.updateStatusGallery(galleryDto.id)}
                              checked={galleryDto.isActive}
                            />
                          </div>
                        </td>
                        <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                          <div className="d-flex justify-content-center">
                            <div
                              className="actions"
                              style={{ backgroundColor: "#e4e6ef" }}
                            >
                              <div className="action1">
                                <a onClick={() => {
                                  props.getEditedGallery(galleryDto.id);
                                  props.updateDisplayTypeInGalleries(
                                    DisplayTypesInGalleriesConstant.EDIT
                                  )
                                }}>

                                  <i className="fas fa-edit edit"></i>
                                </a>

                              </div>
                              <div className="action2">
                                <a
                                  onClick={() => { Delete(props, galleryDto.id) }}>
                                  <i className="fas fa-trash delete"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="card-footer">
            {
              props.galleriesTotalCountInGalleries !== null
              && props.galleriesTotalCountInGalleries > 0
              && (
                <div className="d-flex flex-stack flex-wrap ">
                  <div className="fs-6 fw-bold text-gray-700">{strings.showing} {((props.galleriesPageNumberInGalleries - 1) * props.galleriesPageSizeInGalleries) + 1} {strings.to} {(props.galleriesPageNumberInGalleries * props.galleriesPageSizeInGalleries) > props.galleriesTotalCountInGalleries ? props.galleriesTotalCountInGalleries : (props.galleriesPageNumberInGalleries * props.galleriesPageSizeInGalleries)} {strings.of} {props.galleriesTotalCountInGalleries} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.galleriesPageNumberInGalleries}
                    itemsCountPerPage={props.galleriesPageSizeInGalleries}
                    totalItemsCount={props.galleriesTotalCountInGalleries}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Gallery;
