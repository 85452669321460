import {
    GET_ROLE_USERS,
    GET_ROLE_USERS_SUCCESS,
    GET_ROLE_USERS_FAILURE
} from '../../../actions/Roles/RoleUsers/getRoleUsersActions';

export function getRoleUsersReducer(state, action) {
    switch (action.type) {
        case GET_ROLE_USERS:
            return {
                ...state,
                isGetRoleUsers: true,
                getRoleUsersError: null,
            };
        case GET_ROLE_USERS_SUCCESS:
            return {
                ...state,
                isGetRoleUsers: false,
                getRoleUsersError: null,
                roleUsersInRoleUsers: action.payload.users.data,
                usersPageNumberInRoleUsers: action.payload.pageNumber,
                usersPageSizeInRoleUsers: action.payload.pageSize,
                usersTotalCountInRoleUsers: action.payload.users.total,
            };
        case GET_ROLE_USERS_FAILURE:
            return {
                ...state,
                isGetRoleUsers: false,
                getRoleUsersError: action.payload.message,
            };
        default:
            return state;
}}