export const UPDATE_USERS_IN_REQUESTS = 'UPDATE_USERS_IN_REQUESTS';
export const UPDATE_USERS_IN_REQUESTS_SUCCESS = 'UPDATE_USERS_IN_REQUESTS_SUCCESS';
export const UPDATE_USERS_IN_REQUESTS_FAILURE = 'UPDATE_USERS_IN_REQUESTS_FAILURE';

export const updateUsersInRequests = (user) => ({
    type: UPDATE_USERS_IN_REQUESTS,
    user
}); 

export const updateUsersInRequestsSuccess = (user) => ({
    type: UPDATE_USERS_IN_REQUESTS_SUCCESS,
    payload: { user }
});

export const updateUsersInRequestsFailure = (message) => ({
    type: UPDATE_USERS_IN_REQUESTS_FAILURE,
    payload: { message }
});