export const UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS = 'UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS';
export const UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_FAILURE';

export const updateDisplayTypeInTicketOptions = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS,
    displayType
});

}
export const updateDisplayTypeInTicketOptionsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInTicketOptionsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_TICKET_OPTIONS_FAILURE,
    payload: message
});