export const GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS = 'GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS';
export const GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS = 'GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS';
export const GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE = 'GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE';

export const getCompanyReservationTimesInCompanyReservations = (companyReservationTypeSettingId,selectedDate) => ({
    type: GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS,
    companyReservationTypeSettingId,
    selectedDate
});

export const getCompanyReservationTimesInCompanyReservationsSuccess = (timeResultList,selectedDate) => ({
    type: GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { timeResultList,selectedDate }
});

export const getCompanyReservationTimesInCompanyReservationsFailure = (message) => ({
    type: GET_COMPANY_RESERVATION_TIMES_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});