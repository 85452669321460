export const UPDATE_DISPLAY_TYPE_IN_DISCOUNTS = 'UPDATE_DISPLAY_TYPE_IN_DISCOUNTS';
export const UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_FAILURE';

export const updateDisplayTypeInDiscounts = (displayType) =>
{
    console.log(displayType)
    return({
    type: UPDATE_DISPLAY_TYPE_IN_DISCOUNTS,
    displayType
});

}
export const updateDisplayTypeInDiscountsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInDiscountsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_DISCOUNTS_FAILURE,
    payload: message
});