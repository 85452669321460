import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    getNotificationRolesInNotificationSettingsSuccess,
    getNotificationRolesInNotificationSettingsFailure
} from '../../actions/NotificationSettings/getNotificationRolesInNotificationSettingsActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getNotificationRolesInNotificationSettingsEpic(action$) {
    return action$.pipe(
        ofType(GET_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS),
        switchMap(action => {
            const queryString= action.notificationSettingId !== null ? `?notificationSettingId=${action.notificationSettingId}` : ``;

            const ajaxConfigObj = {
                url: url + `NotificationSetting/GetNotificationRoles${queryString}`,
                method: 'POST',
                headers: {
                    'Authorization': token,
                    //  'content-type': 'application/json'
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response
                ))
        }),
        mergeMap(response => {

            if (response.isSuccess) {
                const notificationRoles = response.data.map(item => ({
                    label: strings.getLanguage() === LanguagesConstant.EN ? item.nameEn : item.nameAr,
                    value: item.id
                }));
                return of(getNotificationRolesInNotificationSettingsSuccess(notificationRoles));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(getNotificationRolesInNotificationSettingsFailure(message));
            }
        }),
        catchError(error => of(getNotificationRolesInNotificationSettingsFailure(strings.somethingWrongHappened)))
    )
}

