import {
    UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS,
    UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS,
    UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE
} from '../../actions/CompanyReservations/updateToTimesInCompanyReservationsActions';

export function updateToTimesInCompanyReservationsReducer(state, action) {
    switch (action.type) {
        case UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS:
            return {
                ...state,
                isUpdatingToTimesInCompanyReservations: true,
                updateToTimesInCompanyReservationsError: null,
            };
        case UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_SUCCESS:
            return {
                ...state,
                isUpdatingToTimesInCompanyReservations: false,
                toTimeInCompanyReservations: action.payload.selectedTime,
                updateToTimesInCompanyReservationsError: null
            };
        case UPDATE_TO_TIMES_IN_COMPANY_RESERVATIONS_FAILURE:
            return {
                ...state,
                isUpdatingToTimesInCompanyReservations: false,
                updateToTimesInCompanyReservationsError: action.payload
            };
        default:
            return state;
    }
}