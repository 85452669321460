export const GET_SCREENS_IN_ROLE_SCREENS = 'GET_SCREENS_IN_ROLE_SCREENS';
export const GET_SCREENS_IN_ROLE_SCREENS_SUCCESS = 'GET_SCREENS_IN_ROLE_SCREENS_SUCCESS';
export const GET_SCREENS_IN_ROLE_SCREENS_FAILURE = 'GET_SCREENS_IN_ROLE_SCREENS_FAILURE';

export const getScreensInRoleScreens = (roleId,roleScreenId=null) => ({
    type: GET_SCREENS_IN_ROLE_SCREENS,
    roleId,
    roleScreenId
});

export const getScreensInRoleScreensSuccess = (screens) => ({
    type: GET_SCREENS_IN_ROLE_SCREENS_SUCCESS,
    payload: { screens }
});

export const getScreensInRoleScreensFailure = (message) => ({
    type: GET_SCREENS_IN_ROLE_SCREENS_FAILURE,
    payload: { message }
});