export const UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE';

export const updateNotificationRolesInNotificationSettings = (notificationRole) => ({
    type: UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS,
    notificationRole
});

export const updateNotificationRolesInNotificationSettingsSuccess = (notificationRole) => ({
    type: UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_SUCCESS,
    payload: { notificationRole }
});

export const updateNotificationRolesInNotificationSettingsFailure = (message) => ({
    type: UPDATE_NOTIFICATION_ROLES_IN_NOTIFICATION_SETTINGS_FAILURE,
    payload: { message }
});