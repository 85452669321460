import {
    UPDATE_USERS_IN_REQUESTS,
    UPDATE_USERS_IN_REQUESTS_SUCCESS,
    UPDATE_USERS_IN_REQUESTS_FAILURE
} from '../../actions/Requests/updateUsersInRequestsActions';

export function updateUsersInRequestsReducer(state, action) {
    switch (action.type) {
        case UPDATE_USERS_IN_REQUESTS:
            return {
                ...state,
                isUpdateUsersInRequests: true,
                updateUsersInRequestsError: null,
            };
        case UPDATE_USERS_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isUpdateUsersInRequests: false,
                updateUsersInRequestsError: null,
                userInRequests: action.payload.user,
            };
        case UPDATE_USERS_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isUpdateUsersInRequests: false,
                updateUsersInRequestsError: action.payload.message,
            };
        default:
            return state;
}}