export const GET_EDITED_RESERVATION_TYPE = 'GET_EDITED_RESERVATION_TYPE';
export const GET_EDITED_RESERVATION_TYPE_SUCCESS = 'GET_EDITED_RESERVATION_TYPE_SUCCESS';
export const GET_EDITED_RESERVATION_TYPE_FAILURE = 'GET_EDITED_RESERVATION_TYPE_FAILURE';

export const getEditedReservationType = (reservationTypeId) => {
    console.log('get edited reservationType action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE,
    reservationTypeId
});
}
export const getEditedReservationTypeSuccess = (reservationTypeId) => {
    console.log('get edited reservationType success action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE_SUCCESS,
    payload: { reservationTypeId }
});
}
export const getEditedReservationTypeFailure = (message) => {
    console.log('get edited reservationType fail action')
    return ({
    type: GET_EDITED_RESERVATION_TYPE_FAILURE,
    payload: { message }
});
}