import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    GET_EDITED_RESERVATION,
    getEditedReservationSuccess,
    getEditedReservationFailure
} from '../../actions/Reservations/getEditedReservationActions';

import { url, token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function getEditedReservationEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_RESERVATION),
        map(action => ({
            reservationId: action.reservationId
        })),
        map(response =>
            getEditedReservationSuccess(response.reservationId)

        ),
        catchError(error => of(getEditedReservationFailure(strings.somethingWrongHappened)))
    )
}

