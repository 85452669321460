import {
    ADD_NEW_REQUEST,
    ADD_NEW_REQUEST_SUCCESS,
    ADD_NEW_REQUEST_FAILURE
} from '../../actions/Requests/addNewRequestActions';

export function addNewRequestReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_REQUEST:
            return {
                ...state,
                isAddNewRequest: true,
                addNewRequestError: null,
            };
        case ADD_NEW_REQUEST_SUCCESS:
            return { 
                ...state,
                isAddNewRequest: false,
                addNewRequestError: null,
                addNewRequestResult: action.payload.data,
            };
        case ADD_NEW_REQUEST_FAILURE:
            return {
                ...state,
                isAddNewRequest: false,
                addNewRequestError: action.payload.message,
            };
        default:
            return state;
}}