export const EDIT_DISCOUNT = 'EDIT_DISCOUNT';
export const EDIT_DISCOUNT_SUCCESS = 'EDIT_DISCOUNT_SUCCESS';
export const EDIT_DISCOUNT_FAILURE = 'EDIT_DISCOUNT_FAILURE';

export const editDiscount = (discountData) => ({
    type: EDIT_DISCOUNT,
    discountData
});

export const editDiscountSuccess = (data) => ({
    type: EDIT_DISCOUNT_SUCCESS,
    payload: { data }
});

export const editDiscountFailure = (message) => ({
    type: EDIT_DISCOUNT_FAILURE,
    payload: { message }
});