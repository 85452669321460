import {
    UPDATE_DISPLAY_TYPE_IN_USERS,
    UPDATE_DISPLAY_TYPE_IN_USERS_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_USERS_FAILURE
} from '../../../src/actions/Users/updateDisplayTypeInUsersActions';

export function updateDisplayTypeInUsersReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_USERS:
            return {
                ...state,
                isUpdatingDisplayTypeInUsers: true,
                updateDisplayTypeErrorInUsers: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_USERS_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInUsers: false,
                displayTypeInUsers: action.payload.displayType,
                updateDisplayTypeErrorInUsers: null,

                isAddNewUser: false,
                addNewUserError: null,
                addNewUserResult: null,

                isDeletingUser: false,
                deleteUserError: null,
                deleteUserResult: null,

                isEditingUser: false,
                editUserError: null,
                editUserResult: null,

                isUpdatingStatusUser: false,
                updateStatusUserError: null,
                updateStatusUserResult: null,

                ischeckingUserNameEnAvailabilityInUsers: false,
                isUserNameEnAvailableInUsers: true,
                checkUserNameEnAvailabilityErrorInUsers: null,

                ischeckingUserNameArAvailabilityInUsers: false,
                isUserNameArAvailableInUsers: true,
                checkUserNameArAvailabilityErrorInUsers: null,

            };
        case UPDATE_DISPLAY_TYPE_IN_USERS_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInUsers: false,
                updateDisplayTypeErrorInUsers: action.payload
            };
        default:
            return state;
    }
}