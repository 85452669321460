import React from "react";
import $ from "jquery";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import strings from "../../localizations/strings";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from '../../images/img1.jpg'
import img2 from '../../images/img2.jpg'
import img3 from '../../images/img3.jpg'
import img4 from '../../images/img4.jpg'
import img5 from '../../images/img5.jpg'
import img6 from '../../images/img6.jpg'
import img7 from '../../images/img7.jpg'
import img11 from '../../images/img11.jpg'
import vid1 from '../../images/vid1.m4v'
import facebook from "../../images/facebook-4.svg";
import insta from "../../images/Instagram.svg";
import whatsapp from "../../images/WhatsApp.png";
import mobile from "../../images/mobile.png";
import email from "../../images/email2.png";
import GooglePlay from "../../images/google-play2.png";
import appStore from "../../images/app-store.png";
import './homeStyle.css'
class Home extends React.Component {
  state = {
    Notifications: [
      {
        id: 1,
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        avatarurl: "/images/notifi.svg",
      },
      {
        id: 2,
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hr",
        avatarurl: "/images/notifi2.svg",
      },
    ],
    ar: true,
    Searchresult: [],
    search: "",
  };
  componentDidMount() {
    let url = window.location.href;
    let lan = localStorage.getItem("language-code");
    if (url.indexOf("/ar/") !== -1 && lan === "ar") {
      this.setState({ ar: true });
    } else if (url.indexOf("/en/") !== -1 && lan === "en") {
      this.setState({ ar: false });
    }

    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    $(".SearchCard").removeClass("active");
    $(".icon-search").removeClass("searchbtnActive");
    $("#Search-form").removeClass("active");
    this.setState({ Searchresult: [], search: "" });
  };
  handleGetSearch = (e) => {
    console.log(e.target.value);
    //function search here
    this.setState({ Searchresult: e.target.value, search: e.target.value });
  };

  handleSearch = () => {
    this.setState({ Searchresult: [], search: "" });
    $(".icon-search").toggleClass("searchbtnActive");
    $(".SearchCard").toggleClass("active");
    $("#Search-form").toggleClass("active");
  };
  handlehidesearch = () => {
    $(".SearchCard").removeClass("active");
    $(".icon-search").removeClass("searchbtnActive");
    this.setState({ Searchresult: [], search: "" });
  };
  handleLanChange = (e) => {
    let url = window.location.href;
    if (strings.getLanguage() === LanguagesConstant.EN) {
      if (url.indexOf("/en/") !== -1) {
        url = url.replace("/en/", "/ar/");
      }

      strings.setLanguage(LanguagesConstant.AR);

      let arabicStyleLink = document.querySelector("#arabicStyleLink");
      if (arabicStyleLink === null) {
        arabicStyleLink = document.createElement("link");
        arabicStyleLink.href = "/StyleAr.css";
        arabicStyleLink.id = "arabicStyleLink";
        arabicStyleLink.rel = "stylesheet";

        document.querySelector("head").appendChild(arabicStyleLink);
      }
    } else {
      if (url.indexOf("/ar/") !== -1) {
        url = url.replace("/ar/", "/en/");
      }
      strings.setLanguage(LanguagesConstant.EN);

      const arabicStyleLink = document.querySelector("#arabicStyleLink");
      if (arabicStyleLink !== null) {
        document.querySelector("head").removeChild(arabicStyleLink);
      }
    }
    localStorage.setItem("language-code", strings.getLanguage());
    window.location.href = `/${strings.getLanguage()}${this.props.location.pathname.replace("/en", "").replace("/ar", "") === "/"
        ? ""
        : `${this.props.location.pathname
          .replace("/en", "")
          .replace("/ar", "")}`
      }`;

    this.setState({ ar: e });
  };
  render() {
    return (
      <div className="animate__animated  animate__backInLeft">
        <div className="container3">
          <div className="row justify-content-center align-items-center pt-1  p-5">
            <div className="row justify-content-center align-items-center pt-0 p-3 pb-0 gy-5">
              <div className="col-xl-3">
                <div className="card">
                  <div className="card-body py-3">
                    <h3 className="card-title fs-1" style={{ color: "#156ecf" }}>{strings.aquaKids}</h3>
                    <p className="card-text fs-4">{strings.aquaInfo2}</p>
                  </div>
                  <div className="ratio ratio-4x3">
                    <video className="embed-responsive-item" controls>
                      <source src={vid1} type="video/mp4"></source>
                    </video>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <img src={img1} className="img-fluid img-thumbnail" />
              </div>

            </div>
            <div className="row gy-5">
              <div id="carouselExampleControls" className="carousel slide gy-5" data-bs-ride="carousel">
                <div className="carousel-inner gy-5">
                  <div className="carousel-item active gy-5">
                    <div className="row justify-content-center align-items-center pt-0 gy-5">
                      <div className="col px-0">
                        <img src={img2} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img4} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img3} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img5} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img6} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img7} className="img-thumbnail" />
                      </div>
                    </div>
                  </div>
                  {/* <div className="carousel-item">
                    <div className="row justify-content-center align-items-center pt-0 gy-5">
                      <div className="col px-0">
                        <img src={img8} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img9} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img10} className="img-thumbnail h-50" />
                      </div>
                      <div className="col px-0">
                        <img src={img11} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img12} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img13} className="img-thumbnail" />
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row justify-content-center align-items-center pt-0 gy-5">
                      <div className="col px-0">
                        <img src={img14} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img15} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img16} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img17} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img19} className="img-thumbnail" />
                      </div>
                      <div className="col px-0">
                        <img src={img18} className="img-thumbnail" />
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <button className={`${strings.getLanguage() == LanguagesConstant.AR ? "carousel-control-next" : "carousel-control-prev"}`} type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide={`${strings.getLanguage() == LanguagesConstant.AR ? "next" : "prev"}`}>
                  <span className={`${strings.getLanguage() == LanguagesConstant.AR ? "carousel-control-next-icon" : "carousel-control-prev-icon"}`} aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className={`${strings.getLanguage() == LanguagesConstant.AR ? "carousel-control-prev" : "carousel-control-next"}`} type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide={`${strings.getLanguage() == LanguagesConstant.AR ? "prev" : "next"}`}>
                  <span className={`${strings.getLanguage() == LanguagesConstant.AR ? "carousel-control-prev-icon" : "carousel-control-next-icon"}`} aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button> */}
              </div>
            </div>


          </div>
          <div className="row mb-5 p-5 justify-content-center rounded-3">
            <div className="card mb-5 rounded-3">
              <div className="row g-0 rounded-3">
                <div className="col-md-6">
                  <img src={img11} className="img-fluid" alt="..." />
                </div>
                <div className="col-md-6 p-5 d-flex align-items-center bg-primary text-white">
                  <div className="card-body p-5">
                    <h1 className="card-title p-5 pb-0 text-white">{strings.aboutUs}</h1>
                    <p className="card-text fs-3 p-5 pt-2 lh-lg">{strings.aquaInfo1}{strings.aquaInfo2}{strings.aquaInfo3}{strings.aquaInfo4}</p>
                    {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-5 g-xl-8 mb-5">
            <div className="col-xl-6">
              <div className="card mb-xl-3 h-100" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="card-header border-0 py-5">
                  <h1>
                    {strings.contactUs}
                  </h1>
                </div>
                <div className="card-body d-flex flex-column">
                  <div>
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex align-items-center me-2">
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light">
                            <img
                              src={facebook}
                              className="h-50"
                              alt=""
                            />
                          </div>
                        </div>
                        <div>
                          <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">
                            {strings.facebook}
                          </a>
                          <div className="fs-7 text-muted fw-bold mt-1">
                            aquamarine trading company
                            {/* {strings.visitOurPageOnFacebook} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex align-items-center me-2">
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light">
                            <img
                              src={insta}
                              className="h-50"
                              alt=""
                            />
                          </div>
                        </div>
                        <div>
                          <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">
                            {strings.instagram}
                          </a>
                          <div className="fs-7 text-muted fw-bold mt-1">
                          aquamarineexotic
                            {/* {strings.visitOurPageOnInstagram} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex align-items-center me-2">
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light">
                            <img
                              src={whatsapp}
                              className="h-50"
                              alt=""
                            />
                          </div>
                        </div>
                        <div>
                          <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">
                            {strings.whatsapp}
                          </a>
                          <div className="fs-7 text-muted fw-bold mt-1">
                            97995563
                            {/* {strings.visitOurPageOnInstagram} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex align-items-center me-2">
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light">
                            <img
                              src={mobile}
                              className="h-50"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">
                            {strings.mobile}
                          </a>
                          <div className="fs-7 text-muted fw-bold mt-1">
                            24751134 / 24745907
                            {/* {strings.visitOurPageOnTwitter} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-stack mb-5">
                      <div className="d-flex align-items-center me-2">
                        <div className="symbol symbol-50px me-3">
                          <div className="symbol-label bg-light">
                            <img
                              src={email}
                              className="h-50"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="py-1">
                          <a className="text-decoration-none fs-6 text-gray-800 text-hover-primary fw-bolder">
                            {strings.email}
                          </a>
                          <div className="fs-7 text-muted fw-bold mt-1">
                            Info@Q8aquamarine.Com
                            {/* {strings.visitOurPageOnTwitter} */}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card mb-xl-3 px-5 h-100" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="row text-center">
                  <div className="border-0 py-5">
                    <h1>
                      {strings.reservation}
                    </h1>
                    <span className="fs-7 text-muted fw-bold">{strings.downloadTheApp}</span>
                  </div>
                </div>
                <div className="row justify-content-center align-items-center p-5">
                  <div className="row p-5 justify-content-center">
                    <a href="https://play.google.com/store/apps/details?id=com.aquakids.aquakids" className="card w-50 px-0">
                      <img className="w-100" src={GooglePlay}></img>
                    </a>
                  </div>
                  <div className="row p-5 justify-content-center">
                    <a href="https://apps.apple.com/us/app/aquakids-safari/id6443710050" className="card   w-50 px-0">
                      <img className="w-100" src={appStore} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer mb-5 p-5">
            <h2 className="text-white p-5 pb-0">{strings.howToBookATicket}</h2>
            <div className="how d-flex justify-content-between mt-5 p-5">
              <div className="item w-25">
                <i className="fas fa-download fa-3x mb-4"></i><h5>{strings.downloadTheApp}</h5>
              </div>

              <i className={`fas ${strings.getLanguage() == LanguagesConstant.AR ? "fa-angle-double-left" : "fa-angle-double-right"}  fa-3x mt-5 pt-3`}></i>

              <div className="item w-25">
                <i className="fas fa-clock fa-3x mb-4"></i><h5>{strings.selectTime}</h5>
              </div>

              <i className={`fas ${strings.getLanguage() == LanguagesConstant.AR ? " fa-angle-double-left" : " fa-angle-double-right"} fa-3x mt-5 pt-3`}></i>
              <div className="item w-25">
                <i className="fas fa-search fa-3x mb-4"></i><h5>{strings.bookYourTicket}</h5>
              </div>

            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default Home;
