import {
    GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS,
    GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_FAILURE
} from '../../actions/NotificationSettings/getNotificationSettingsInNotificationSettingsActions';

export function getNotificationSettingsInNotificationSettingsReducer(state, action) {
    switch (action.type) {
        case GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS:
            return {
                ...state,
                isGetNotificationSettingsInNotificationSettings: true,
                getNotificationSettingsInNotificationSettingsError: null,
            };
        case GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_SUCCESS:
            console.log(action.payload.notificationSettings)
            return {
                ...state,
                isGetNotificationSettingsInNotificationSettings: false,
                getNotificationSettingsInNotificationSettingsError: null,
                notificationSettingsInNotificationSettings: action.payload.notificationSettings.data,
                notificationSettingsPageNumberInNotificationSettings: action.payload.pageNumber,
                notificationSettingsPageSizeInNotificationSettings: action.payload.pageSize,
                notificationSettingsTotalCountInNotificationSettings: action.payload.notificationSettings.total,
            };
        case GET_NOTIFICATION_SETTINGS_IN_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                isGetNotificationSettingsInNotificationSettings: false,
                getNotificationSettingsInNotificationSettingsError: action.payload.message,
            };
        default:
            return state;
}}