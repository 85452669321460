import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap,map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
    UPDATE_STATUS_PAUSED_TIME,
    updateStatusPausedTimeSuccess,
    updateStatusPausedTimeFailure
} from '../../actions/PausedTimes/updateStatusPausedTimeActions';

import { url,token } from "../../config";
import strings from '../../localizations/strings';
import { LanguagesConstant } from "../../constants/LanguagesConstant";

export function updateStatusPausedTimeEpic(action$) {
    return action$.pipe(
        ofType(UPDATE_STATUS_PAUSED_TIME),
        switchMap(action => {
            const ajaxConfigObj = {
                url:  url + `PausedTime/ChangeIsActive?pausedTimeId=${action.pausedTimeId}`,
                method: 'POST',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
                return of(updateStatusPausedTimeSuccess(response));
            } else {
                 
                return of(updateStatusPausedTimeFailure(response.message));
            }
        }),
        catchError(error =>  of(updateStatusPausedTimeFailure(strings.somethingWrongHappened)))
    )
}

