import {
    ADD_NEW_TICKET_OPTION,
    ADD_NEW_TICKET_OPTION_SUCCESS,
    ADD_NEW_TICKET_OPTION_FAILURE
} from '../../actions/TicketOptions/addNewTicketOptionActions';

import { DisplayTypesInTicketOptionsConstant } from '../../constants/TicketOptions/DisplayTypesInTicketOptionsConstant';

export function addNewTicketOptionReducer(state, action) {
    switch (action.type) {
        case ADD_NEW_TICKET_OPTION:
            return {
                ...state,
                isAddNewTicketOption: true,
                addNewTicketOptionError: null,
                addNewTicketOptionResult:null,
            };
        case ADD_NEW_TICKET_OPTION_SUCCESS:
            return { 
                ...state,
                isAddNewTicketOption: false,
                addNewTicketOptionError: null,
                addNewTicketOptionResult: action.payload.data,
                // displayTypeInTicketOptions: DisplayTypesInTicketOptionsConstant.LIST,
            };
        case ADD_NEW_TICKET_OPTION_FAILURE:
            return {
                ...state,
                isAddNewTicketOption: false,
                addNewTicketOptionError: action.payload.message,
            };
        default:
            return state;
}}