import {
    UPDATE_TO_TIMES_IN_REQUESTS,
    UPDATE_TO_TIMES_IN_REQUESTS_SUCCESS,
    UPDATE_TO_TIMES_IN_REQUESTS_FAILURE
} from '../../actions/Requests/updateToTimesInRequestsActions';

export function updateToTimesInRequestsReducer(state, action) {
    switch (action.type) {
        case UPDATE_TO_TIMES_IN_REQUESTS:
            return {
                ...state,
                isUpdatingToTimesInRequests: true,
                updateToTimesInRequestsError: null,
            };
        case UPDATE_TO_TIMES_IN_REQUESTS_SUCCESS:
            return {
                ...state,
                isUpdatingToTimesInRequests: false,
                toTimeInRequests: action.payload.selectedTime,
                updateToTimesInRequestsError: null
            };
        case UPDATE_TO_TIMES_IN_REQUESTS_FAILURE:
            return {
                ...state,
                isUpdatingToTimesInRequests: false,
                updateToTimesInRequestsError: action.payload
            };
        default:
            return state;
    }
}