import {
    GET_COMPANY_RESERVATION_DETAILS,
    GET_COMPANY_RESERVATION_DETAILS_SUCCESS,
    GET_COMPANY_RESERVATION_DETAILS_FAILURE
} from '../../actions/CompanyReservations/getCompanyReservationDetailsActions';

export function getCompanyReservationDetailsReducer(state, action) {
    switch (action.type) {
        case GET_COMPANY_RESERVATION_DETAILS:
            return {
                ...state,
                isGetCompanyReservationDetails: true,
                getCompanyReservationDetailsError: null,
            };
        case GET_COMPANY_RESERVATION_DETAILS_SUCCESS:
            return {
                ...state,
                isGetCompanyReservationDetails: false,
                companyReservationDetailsInCompanyReservations: action.payload.companyReservationViewModel,
                getCompanyReservationDetailsError: null
            };
        case GET_COMPANY_RESERVATION_DETAILS_FAILURE:
            return {
                ...state,
                isGetCompanyReservationDetails: false,
                isAvailableCompanyReservationInCompanyReservation:false,
                getCompanyReservationDetailsError: action.payload
            };
        default:
            return state;
    }
}