export const GET_TICKET_OPTIONS_IN_RESERVATIONS = 'GET_TICKET_OPTIONS_IN_RESERVATIONS';
export const GET_TICKET_OPTIONS_IN_RESERVATIONS_SUCCESS = 'GET_TICKET_OPTIONS_IN_RESERVATIONS_SUCCESS';
export const GET_TICKET_OPTIONS_IN_RESERVATIONS_FAILURE = 'GET_TICKET_OPTIONS_IN_RESERVATIONS_FAILURE';

export const getTicketOptionsInReservations = () => ({
    type: GET_TICKET_OPTIONS_IN_RESERVATIONS,
}); 

export const getTicketOptionsInReservationsSuccess = (ticketOptions) => ({
    type: GET_TICKET_OPTIONS_IN_RESERVATIONS_SUCCESS,
    payload: { ticketOptions }
});

export const getTicketOptionsInReservationsFailure = (message) => ({
    type: GET_TICKET_OPTIONS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});