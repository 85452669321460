export const ADD_NEW_NEW = 'ADD_NEW_NEW';
export const ADD_NEW_NEW_SUCCESS = 'ADD_NEW_NEW_SUCCESS';
export const ADD_NEW_NEW_FAILURE = 'ADD_NEW_NEW_FAILURE';

export const addNewNew = (newData) => ({
    type: ADD_NEW_NEW,
    newData
});

export const addNewNewSuccess = (data) => ({
    type: ADD_NEW_NEW_SUCCESS,
    payload: { data }
});

export const addNewNewFailure = (message) => ({
    type: ADD_NEW_NEW_FAILURE,
    payload: { message }
});