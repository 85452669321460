export const ADD_NEW_SCHOOL_TOUR_RANGE = 'ADD_NEW_SCHOOL_TOUR_RANGE';
export const ADD_NEW_SCHOOL_TOUR_RANGE_SUCCESS = 'ADD_NEW_SCHOOL_TOUR_RANGE_SUCCESS';
export const ADD_NEW_SCHOOL_TOUR_RANGE_FAILURE = 'ADD_NEW_SCHOOL_TOUR_RANGE_FAILURE';

export const addNewSchoolTourRange = (schoolTourRangeData) => ({
    type: ADD_NEW_SCHOOL_TOUR_RANGE,
    schoolTourRangeData
});

export const addNewSchoolTourRangeSuccess = (data) => ({
    type: ADD_NEW_SCHOOL_TOUR_RANGE_SUCCESS,
    payload: { data }
});

export const addNewSchoolTourRangeFailure = (message) => ({
    type: ADD_NEW_SCHOOL_TOUR_RANGE_FAILURE,
    payload: { message }
});