export const GET_RESERVATIONS_IN_RESERVATIONS = 'GET_RESERVATIONS_IN_RESERVATIONS';
export const GET_RESERVATIONS_IN_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_IN_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_IN_RESERVATIONS_FAILURE = 'GET_RESERVATIONS_IN_RESERVATIONS_FAILURE';

export const getReservationsInReservations = (pageNumber = 1, pageSize = 10,search = null,selectedDate=null) => ({
    type: GET_RESERVATIONS_IN_RESERVATIONS,
    pageNumber,
    pageSize,
    search,
    selectedDate
});

export const getReservationsInReservationsSuccess = (reservations, pageNumber, pageSize) => ({
    type: GET_RESERVATIONS_IN_RESERVATIONS_SUCCESS,
    payload: { reservations, pageNumber, pageSize }
});

export const getReservationsInReservationsFailure = (message) => ({
    type: GET_RESERVATIONS_IN_RESERVATIONS_FAILURE,
    payload: { message }
});