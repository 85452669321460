import {
    DELETE_GALLERY,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAILURE
} from '../../actions/Galleries/deleteGalleryActions';

import { DisplayTypesInGalleriesConstant } from '../../constants/Galleries/DisplayTypesInGalleriesConstant';

export function deleteGalleryReducer(state, action) {
    switch (action.type) {
        case DELETE_GALLERY:
            return {
                ...state,
                isDeletingGallery: true,
                deleteGalleryError: null,
            };
        case DELETE_GALLERY_SUCCESS:
            console.log( action.payload.data)
            return {
                ...state,
                isDeletingGallery: false,
                deleteGalleryError: null,
                deleteGalleryResult: action.payload.data,
                //displayTypeInGalleries: DisplayTypesInGalleriesConstant.LIST,
            };
        case DELETE_GALLERY_FAILURE:
            return {
                ...state,
                isDeletingGallery: false,
                deleteGalleryError: action.payload.message,
            };
        default:
            return state;
}}