export const UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS = 'UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS';
export const UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_SUCCESS = 'UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_SUCCESS';
export const UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_FAILURE = 'UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_FAILURE';

export const updateDisplayTypeInCompanyReservations = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS,
    displayType
});

export const updateDisplayTypeInCompanyReservationsSuccess = (displayType) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_SUCCESS,
    payload: { displayType  }
});

export const updateDisplayTypeInCompanyReservationsFailure = (message) => ({
    type: UPDATE_DISPLAY_TYPE_IN_COMPANY_RESERVATIONS_FAILURE,
    payload: message
});