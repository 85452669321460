import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
   CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS,
    checkEmailAvailabilityInRoleUsersSuccess,
    checkEmailAvailabilityInRoleUsersFailure
} from  '../../../actions/Roles/RoleUsers/checkEmailAvailabilityInRoleUsersActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";


export function checkEmailAvailabilityInRoleUsersEpic(action$) {
    return action$.pipe(
        ofType(CHECK_EMAIL_AVAILABILITY_IN_ROLE_USERS),
        switchMap(action => {

            const queryString= action.userId !== null ? `?email=${action.email}&userId=${action.userId}` : `?email=${action.email}`;

            const ajaxConfigObj = {
                url:  url + `RoleUser/CheckEmailAvailability${queryString}`,
                method: 'Post',
                headers: {
                	'Authorization':token 
                },
                createXHR: () => new XMLHttpRequest(),
                crossDomain: true,
            };

            return ajax(ajaxConfigObj).pipe(
                map(data => data.response))
        }),
        mergeMap(response => {
             
            if (response.isSuccess) {
               return of(checkEmailAvailabilityInRoleUsersSuccess(response.data));
            } else {
                let message = response.message;
                if (response.localizedMessageKey !== ""
                 && strings[response.localizedMessageKey] !== undefined) {
                    message = strings[response.localizedMessageKey];
                }
                return of(checkEmailAvailabilityInRoleUsersFailure(message));
            }
        }),
        catchError(error =>  of(checkEmailAvailabilityInRoleUsersFailure(strings.somethingWrongHappened)))
    )
}

