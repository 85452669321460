import {
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from '../../actions/Users/deleteUserActions';

export function deleteUserReducer(state, action) {
    switch (action.type) {
        case DELETE_USER:
            return {
                ...state,
                isDeletingUser: true,
                deleteUserError: null,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isDeletingUser: false,
                deleteUserError: null,
                deleteUserResult: action.payload.data,
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                isDeletingUser: false,
                deleteUserError: action.payload.message,
            };
        default:
            return state;
}}