export const UPDATE_DAYS_IN_RESERVATION_SCHEDULES = 'UPDATE_DAYS_IN_RESERVATION_SCHEDULES';
export const UPDATE_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS = 'UPDATE_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS';
export const UPDATE_DAYS_IN_RESERVATION_SCHEDULES_FAILURE = 'UPDATE_DAYS_IN_RESERVATION_SCHEDULES_FAILURE';

export const updateDaysInReservationSchedules = (day) => ({
    type: UPDATE_DAYS_IN_RESERVATION_SCHEDULES,
    day
});

export const updateDaysInReservationSchedulesSuccess = (day) => ({
    type: UPDATE_DAYS_IN_RESERVATION_SCHEDULES_SUCCESS,
    payload: { day }
});

export const updateDaysInReservationSchedulesFailure = (message) => ({
    type: UPDATE_DAYS_IN_RESERVATION_SCHEDULES_FAILURE,
    payload: { message }
});