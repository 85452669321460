export const EDIT_COMPANY_RESERVATION = 'EDIT_COMPANY_RESERVATION';
export const EDIT_COMPANY_RESERVATION_SUCCESS = 'EDIT_COMPANY_RESERVATION_SUCCESS';
export const EDIT_COMPANY_RESERVATION_FAILURE = 'EDIT_COMPANY_RESERVATION_FAILURE';

export const editCompanyReservation = (companyReservationViewModel) => ({
    type: EDIT_COMPANY_RESERVATION,
    companyReservationViewModel
});

export const editCompanyReservationSuccess = (companyReservationViewModel) => ({
    type: EDIT_COMPANY_RESERVATION_SUCCESS,
    payload: { companyReservationViewModel }
});

export const editCompanyReservationFailure = (message) => ({
    type: EDIT_COMPANY_RESERVATION_FAILURE,
    payload: message
});