import {
    EDIT_REQUEST,
    EDIT_REQUEST_SUCCESS,
    EDIT_REQUEST_FAILURE
} from '../../actions/Requests/editRequestActions';

export function editRequestReducer(state, action) {
    switch (action.type) {
        case EDIT_REQUEST:
            return {
                ...state,
                isEditingRequest: true,
                editRequestError: null,
            };
        case EDIT_REQUEST_SUCCESS:
            return {
                ...state,
                isEditingRequest: false,
                editRequestResult: action.payload.requestViewModel,
                // requestsInRequests: state.requestsInRequests.map((item, index) => {
                //     if (item.id === action.payload.requestViewModel.id) {
                //         return action.payload.requestViewModel
                //     }
                //     return item
                // }),
                editRequestError: null
            };
        case EDIT_REQUEST_FAILURE:
            return {
                ...state,
                isEditingRequest: false,
                editRequestError: action.payload
            };
        default:
            return state;
    }
}