import React from 'react';
import { from, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
 
import {
    GET_EDITED_ROLE_SCREEN,
    getEditedRoleScreenSuccess,
    getEditedRoleScreenFailure
} from '../../../actions/Roles/RoleScreens/getEditedRoleScreenActions';

import { url,token } from "../../../config";
import strings from '../../../localizations/strings';
import { LanguagesConstant } from "../../../constants/LanguagesConstant";

export function getEditedRoleScreenEpic(action$) {
    return action$.pipe(
        ofType(GET_EDITED_ROLE_SCREEN),
        map(action => ({
            roleScreenId:action.roleScreenId
        })),
        map(response => 
             
            getEditedRoleScreenSuccess(response.roleScreenId)
            
        ),
        catchError(error => of(getEditedRoleScreenFailure(strings.somethingWrongHappened)))
    )
}

