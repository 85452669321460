import React, { useEffect, useState } from "react";
import strings from '../../localizations/strings';
import { DisplayTypesInReservationSchedulesConstant } from "../../constants/ReservationSchedules/DisplayTypesInReservationSchedulesConstant";
import { LanguagesConstant } from "../../constants/LanguagesConstant";
import Pagination from "react-js-pagination";
import { deleteRow } from "./Delete";
import TableHeader from '../Shared/tableHeader';
import TableStat from '../Shared/tablestat';
import Swal from "sweetalert2";
import { urlFile } from '../../config'
import moment from 'moment';
import "moment/locale/ar-sa";
import Loading from '../Shared/Loading/Loading'

function ReservationSchedule(props) {
  const [Export, setExport] = useState(null),
    [search, setSearch] = useState(null),
    handleTableLength = (selected) => { props.getReservationSchedulesInReservationSchedules(1, selected.value, search); },
    handleTableExport = (selected) => { setExport(selected) };

  useEffect(() => {
    props.getReservationSchedulesInReservationSchedules();
  }, [])

  useEffect(() => {
    if (
      props.deleteReservationScheduleResult !== null &&
      props.deleteReservationScheduleResult.isSuccess
    ) {
      Swal.fire(`! ${strings.deleted}`, props.deleteReservationScheduleResult.message, "success").then(result => {
        props.getReservationSchedulesInReservationSchedules(props.reservationSchedulesPageNumberInReservationSchedules, props.reservationSchedulesPageSizeInReservationSchedules,search);
      });
    }
  }, [props.deleteReservationScheduleResult]);

  useEffect(() => {
    if (
      props.deleteReservationScheduleError !== null
    ) {
      Swal.fire(`! ${strings.error}`, props.deleteReservationScheduleError, "error");
    }
  }, [props.deleteReservationScheduleError]);

  useEffect(() => {
    if (props.updateStatusReservationScheduleResult !== null && props.updateStatusReservationScheduleResult.isSuccess) {
      Swal.fire(
        ` ${strings.changedSuccessfully}`,
        props.updateStatusReservationScheduleResult.message,
        "success"
      ).then(
      );
    }
  }, [props.updateStatusReservationScheduleResult]);

  useEffect(() => {
    if (props.updateStatusReservationScheduleError !== null) {
      Swal.fire(`! ${strings.error}`, props.updateStatusReservationScheduleError, "error");
    }
  }, [props.updateStatusReservationScheduleError]);

  function handlePageButtonChange(pageNumber) {
    if (pageNumber != props.reservationSchedulesPageNumberInReservationSchedules) {
      props.getReservationSchedulesInReservationSchedules(pageNumber, props.reservationSchedulesPageSizeInReservationSchedules,search);
    }
  }

  function handleinputSearch(selected) {
    setSearch(selected.target.value);
    props.getReservationSchedulesInReservationSchedules(1, props.reservationSchedulesPageSizeInReservationSchedules, selected.target.value)
  }

  return (
    <>
      <div className="customer-data mt-5">
      {
                  props.isGetReservationSchedulesInReservationSchedules
                  && (<Loading />)
                }
        <div className="card animate__animated  animate__backInLeft">
          <div className="card-header d-flex flex-column flex-column-fluid">
            <TableHeader
              searchPlaceholder={strings.search}
              ExportPlaceholder={strings.export}
              Length={[...TableStat.Length,{label:strings.all,value:props.reservationSchedulesTotalCountInReservationSchedules}]}
              Export={TableStat.Export}
              selectedLength={TableStat.Length.filter(x => x.value == props.reservationSchedulesPageSizeInReservationSchedules)[0]}
              selectedExport={Export}
              cardname={strings.show}
              rowsnumber={props.reservationSchedulesTotalCountInReservationSchedules}
              handleTableLength={handleTableLength}
              handleTableExport={handleTableExport}
              handleinputSearch={handleinputSearch}
            />
          </div>

          <div className="table-responsive p-0">
            <table className="table align-middle text-center table-bordered border-bottom m-0">
              <thead className="tableHeader" style={{ position: "sticky", width: "100%" }}>
                <tr className="fw-bolder text-muted text-center bg-light">
                  <th className="min-w-125px px-0">{strings.day}</th>
                  <th className="min-w-125px px-0">{strings.fromTime}</th>
                  <th className="min-w-125px px-0">{strings.toTime}</th>
                  <th className="min-w-125px px-0">{strings.officialVacation}</th>
                  <th className="min-w-125px px-0">{strings.vacationDate}</th>
                  <th className="min-w-125px px-0">{strings.status}</th>
                  <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">{strings.action}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
             
                {
                  props.reservationSchedulesInReservationSchedules !== []
                  && props.reservationSchedulesInReservationSchedules.length > 0
                  && props.reservationSchedulesInReservationSchedules.map((reservationSchedule, index) => {
                    return <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>{strings.getLanguage() === LanguagesConstant.EN ? reservationSchedule.dayNameEn : reservationSchedule.dayNameAr}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {reservationSchedule.fromTimeStr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {reservationSchedule.toTimeStr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {reservationSchedule.isOfficialVacation ? strings.yes : strings.no}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start m-auto flex-column'>
                            <h4>
                              {reservationSchedule.officialVacationDateStr}
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            style={{ float: "unset", width: "47px", height: "25px" }}
                            type="checkbox"
                            name="FlashesColors"
                            id={`chkActive${index}`}
                            checked={reservationSchedule.isActive}
                            onChange={e =>
                              props.updateStatusReservationSchedule(reservationSchedule.id)}
                          />
                        </div>
                      </td>
                      <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                        <div className="d-flex justify-content-center">
                          <div
                            className="actions"
                            style={{ backgroundColor: "#e4e6ef" }}
                          >
                            <div className="action1">
                              <a
                                onClick={() => {
                                  props.getEditedReservationSchedule(reservationSchedule.id);
                                  props.updateDisplayTypeInReservationSchedules(DisplayTypesInReservationSchedulesConstant.EDIT);
                                }}
                              >
                                <i className="fas fa-edit edit"></i>
                              </a>
                            </div>
                            <div className="action2">
                              <a
                                onClick={() => { deleteRow(props, reservationSchedule.id); }}
                              >
                                <i className="fas fa-trash delete"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }

              </tbody>
            </table>
          </div>
          <div className="card-footer">
            {
              props.reservationSchedulesTotalCountInReservationSchedules !== null
              && props.reservationSchedulesTotalCountInReservationSchedules > 0
              && (
                <div className="d-flex flex-stack flex-wrap justify-content-between">
                  <div className="fs-6 fw-bold text-gray-700" style={{ padding: "6px 20px 6px 6px" }}>{strings.showing} {((props.reservationSchedulesPageNumberInReservationSchedules - 1) * props.reservationSchedulesPageSizeInReservationSchedules) + 1} {strings.to} {(props.reservationSchedulesPageNumberInReservationSchedules * props.reservationSchedulesPageSizeInReservationSchedules) > props.reservationSchedulesTotalCountInReservationSchedules ? props.reservationSchedulesTotalCountInReservationSchedules : (props.reservationSchedulesPageNumberInReservationSchedules * props.reservationSchedulesPageSizeInReservationSchedules)} {strings.of} {props.reservationSchedulesTotalCountInReservationSchedules} {strings.entries}</div>
                  <Pagination
                    innerClass="pagination1"
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={props.reservationSchedulesPageNumberInReservationSchedules}
                    itemsCountPerPage={props.reservationSchedulesPageSizeInReservationSchedules}
                    totalItemsCount={props.reservationSchedulesTotalCountInReservationSchedules}
                    onChange={handlePageButtonChange}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReservationSchedule;