import {
    DELETE_COMPANY_RESERVATION,
    DELETE_COMPANY_RESERVATION_SUCCESS,
    DELETE_COMPANY_RESERVATION_FAILURE
} from '../../actions/CompanyReservations/deleteCompanyReservationActions';

import { DisplayTypesInCompanyReservationsConstant } from '../../constants/CompanyReservations/DisplayTypesInCompanyReservationsConstant';

export function deleteCompanyReservationReducer(state, action) {
    switch (action.type) {
        case DELETE_COMPANY_RESERVATION:
            return {
                ...state,
                isDeletingCompanyReservation: true,
                deleteCompanyReservationError: null,
            };
        case DELETE_COMPANY_RESERVATION_SUCCESS:
            return {
                ...state,
                isDeletingCompanyReservation: false,
                deleteCompanyReservationError: null,
                deleteCompanyReservationResult: action.payload.data,
                //displayTypeInCompanyReservations: DisplayTypesInCompanyReservationsConstant.LIST,
            };
        case DELETE_COMPANY_RESERVATION_FAILURE:
            return {
                ...state,
                isDeletingCompanyReservation: false,
                deleteCompanyReservationError: action.payload.message,
            };
        default:
            return state;
}}