import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import TableHeader from "../../Shared/tableHeader";
import strings from '../../../localizations/strings';
import TableStat from '../../Shared/tablestat';
import { deleteRow, deleteItems } from './Delete';
import {DisplayTypesInRoleScreensConstant} from "../../../constants/Roles/RoleScreens/DisplayTypesInRoleScreensConstant";
import moment from 'moment';
import "moment/locale/ar-sa";
import { urlFile } from "../../../config";
import Pagination from "react-js-pagination";
import { LanguagesConstant } from "../../../constants/LanguagesConstant";
import Loading from "../../Shared/Loading/Loading";

function RoleScreen(props) {

    const [Export, setExport] = useState(null),
        [search, setSearch] = useState(null),
        handleTableLength = (selected) => {  props.getRoleScreens(props.roleInRoles.id,1,selected.value,search); },
        handleTableExport = (selected) => { setExport(selected) };


    useEffect(() => {
        props.getRoleScreens(props.roleInRoles.id);
    }, []);

    useEffect(() => {
        if (
          props.deleteRoleScreenResult !== null &&
          props.deleteRoleScreenResult.isSuccess
        ) {
          Swal.fire( ` ${strings.deleted}`, props.deleteRoleScreenResult.message, "success").then(res =>{
            props.getRoleScreens(props.roleInRoles.id,props.roleScreensPageNumberInRoleScreens, props.roleScreensPageSizeInRoleScreens, search);
          }
        );
        }
      }, [props.deleteRoleScreenResult]);

      useEffect(() => {
        if (props.updateStatusRoleScreenResult !== null && props.updateStatusRoleScreenResult.isSuccess) {
          Swal.fire(
            ` ${strings.changed}`,
            props.updateStatusRoleScreenResult.message,
            "success"
          ).then(res =>{
           // props.getRoleScreens(props.roleScreensPageNumberInRoleScreens, props.roleScreensPageSizeInRoleScreens, search);
          }
        );
        }
      }, [props.updateStatusRoleScreenResult]);

      useEffect(() => {
        if (
          props.deleteRoleScreenError !== null
        ) {
            Swal.fire( `! ${strings.error}`, props.deleteRoleScreenError, "error");
        }
      }, [props.deleteRoleScreenError]);

      useEffect(() => {
        if (props.updateStatusRoleScreenError !== null) {
            Swal.fire( `! ${strings.error}`, props.updateStatusRoleScreenError, "error");
        }
      }, [props.updateStatusRoleScreenError]);

      function handlePageButtonChange(pageNumber) {
        if (pageNumber != props.roleScreensPageNumberInRoleScreens) {
            props.getRoleScreens(props.roleInRoles.id,pageNumber, props.roleScreensPageSizeInRoleScreens, search);
        }
    }

    function handleinputSearch(selected) {
        setSearch(selected.target.value);
            props.getRoleScreens(props.roleInRoles.id,1, props.roleScreensPageSizeInRoleScreens, selected.target.value)
    }

    return (
        <>
            <div className="customer-data">
            {props.isGetRoleScreens && (<Loading />)}
            <div className="card rounded-0 animate__animated  animate__backInLeft p-4"style={{backgroundColor:"#f5f8fa"}}>
            <div className={`w-100 d-flex px-3 py-1 ${strings.getLanguage() === LanguagesConstant.EN ? 'flex-row-reverse' : 'flex-row' }`}>
                        <Button variant="sm btn-primary mx-1 animate__animated  animate__fadeInDown" onClick={() => { props.updateDisplayTypeInRoleScreens(DisplayTypesInRoleScreensConstant.ADD) }}>
                            {strings.new}
                        </Button>
                        </div>
                        <div className="card-header d-flex flex-column flex-column-fluid">
                            <TableHeader
                                searchPlaceholder={strings.search}
                                ExportPlaceholder={strings.Export}
                                Length={[...TableStat.Length,{label:strings.all,value:props.roleScreensTotalCountInRoleScreens}]}
                                Export={TableStat.Export}
                                selectedLength={TableStat.Length.filter(x => x.value == props.roleScreensPageSizeInRoleScreens)[0]}
                                selectedExport={Export}
                                cardname={strings.show}
                                rowsnumber={props.roleScreensTotalCountInRoleScreens}
                                handleTableLength={handleTableLength}
                                handleTableExport={handleTableExport}
                                handleinputSearch={handleinputSearch}
                            />
                        </div>
                         <div className="table-responsive p-0">
                             <table className="table align-middle text-center table-bordered border-bottom m-0">
                             <thead className="tableHeader"style={{ position: "sticky", width: "100%" }}>
                               <tr className="fw-bolder text-muted text-center bg-light">
                               <th className="ps-4 min-w-125px px-0 bg-white">{strings.number}</th>
                                   <th className="min-w-125px px-0 bg-white">{strings.arabicName}</th>
                                   <th className="min-w-125px px-0 bg-white">{strings.englishName}</th>
                                   <th className="min-w-150px px-0 bg-white">{strings.status}</th>
                                   <th className="text-center action min-w-100px  px-0 bg-secondary fixedleftcolumn">
                              {strings.action}
                            </th>
                               </tr>
                                 </thead>
    <tbody className="tableBody">
   
     {props.roleScreensInRoleScreens !== [] &&
      props.roleScreensInRoleScreens.length > 0 &&
      props.roleScreensInRoleScreens.map((roleScreen, index) => {
      return (
          <tr key={index}>
              <td>
               <div className='d-flex align-items-center'>
               <div className='d-flex justify-content-start m-auto flex-column'>
                 <h4>{index + 1}</h4>
                   </div>
               </div>
              </td>

              <td>
              <div className='d-flex align-items-center'>
               <div className='d-flex justify-content-start m-auto flex-column'>
                 <h4>{roleScreen.screenNameAr}</h4>
                   </div>
               </div>
              </td>

              <td>
              <div className='d-flex align-items-center'>
               <div className='d-flex justify-content-start m-auto flex-column'>
                 <h4>{roleScreen.screenNameEn}</h4>
                   </div>
               </div>
              </td>

              <td className='text-center'>
                        <div className="form-check form-switch">
                          <input 
                            className="form-check-input"
                            style={{ float: "unset", width: "47px", height: "25px" }}
                            type="checkbox"
                            name="FlashesColors"
                            id={`chkActive${index}`}
                            checked={roleScreen.isActive}
                            onChange={e =>
                                props.updateStatusRoleScreen(roleScreen.id)}
                          />
                        </div>
              </td>
              <td className="min-w-100px  bg-secondary fixedleftcolumn px-0">
                <div className='d-flex justify-content-center'>
                  <div className='actions'  style={{ backgroundColor: "#e4e6ef" }}>
                    <div className="action1">
                    <a onClick={() => {
                              props.getEditedRoleScreen(roleScreen.id)
                                  props.updateDisplayTypeInRoleScreens(DisplayTypesInRoleScreensConstant.Edit)
                              }}>
                        <i className="fas fa-edit edit"></i>
                      </a>
                    </div>
                    <div className="action2">
                    <a
                      onClick={() => { deleteRow(props, roleScreen.id) }}>
                      <i className="fas fa-trash delete"></i>
                              </a>
                    </div>
                  </div>
                </div>
                </td>
          </tr>
          );
         })}
    </tbody>

                             </table>
                         </div>
                        <div className="card-footer">
                            {
                                props.roleScreensTotalCountInRoleScreens !== null
                                && props.roleScreensTotalCountInRoleScreens > 0
                                && (
                                    <div className="d-flex flex-stack flex-wrap justify-content-between">
                                        <div className="fs-6 fw-bold text-gray-700"style={{padding:"6px 20px 6px 6px"}}>{strings.showing} {((props.roleScreensPageNumberInRoleScreens - 1) * props.roleScreensPageSizeInRoleScreens) + 1} {strings.to} {(props.roleScreensPageNumberInRoleScreens * props.roleScreensPageSizeInRoleScreens) > props.roleScreensTotalCountInRoleScreens ? props.roleScreensTotalCountInRoleScreens : (props.roleScreensPageNumberInRoleScreens * props.roleScreensPageSizeInRoleScreens)} {strings.of} {props.roleScreensTotalCountInRoleScreens} {strings.entries}</div>
                                        <Pagination
                                            innerClass="pagination"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activeClass="active"
                                            activePage={props.roleScreensPageNumberInRoleScreens}
                                            itemsCountPerPage={props.roleScreensPageSizeInRoleScreens}
                                            totalItemsCount={props.roleScreensTotalCountInRoleScreens}
                                            onChange={handlePageButtonChange}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>




        </>

    );
}
export default RoleScreen
