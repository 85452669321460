import React from 'react';
import logo from "../../images/ic_logo.png";
class LoadingApp extends React.Component {
   
    render() {
        return (
            <div className="w-100 h-100 d-flex top-0 position-absolute justify-content-center align-items-center bg-white"style={{zIndex:"1030"}}>
             <img src={logo}></img>
          </div>
          
        );
    }
}

export default LoadingApp;