export const ADD_NEW_GALLERY = 'ADD_NEW_GALLERY';
export const ADD_NEW_GALLERY_SUCCESS = 'ADD_NEW_GALLERY_SUCCESS';
export const ADD_NEW_GALLERY_FAILURE = 'ADD_NEW_GALLERY_FAILURE';

export const addNewGallery = (galleryData) => ({
    type: ADD_NEW_GALLERY,
    galleryData
});

export const addNewGallerySuccess = (data) => ({
    type: ADD_NEW_GALLERY_SUCCESS,
    payload: { data }
});

export const addNewGalleryFailure = (message) => ({
    type: ADD_NEW_GALLERY_FAILURE,
    payload: { message }
});