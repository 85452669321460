import {
    UPDATE_DISPLAY_TYPE_IN_GALLERIES,
    UPDATE_DISPLAY_TYPE_IN_GALLERIES_SUCCESS,
    UPDATE_DISPLAY_TYPE_IN_GALLERIES_FAILURE
} from '../../../src/actions/Galleries/updateDisplayTypeInGalleriesActions';

export function updateDisplayTypeInGalleriesReducer(state, action) {
    switch (action.type) {
        case UPDATE_DISPLAY_TYPE_IN_GALLERIES:
            return {
                ...state,
                isUpdatingDisplayTypeInGalleries: true,
                updateDisplayTypeErrorInGalleries: null,
            };
        case UPDATE_DISPLAY_TYPE_IN_GALLERIES_SUCCESS:
            console.log(action.payload.displayType)
            return {
                ...state,
                isUpdatingDisplayTypeInGalleries: false,
                displayTypeInGalleries: action.payload.displayType,
                updateDisplayTypeErrorInGalleries: null,

                isEditingGallery: false,
                editGalleryError: null,
                editGalleryResult:null,

                isDeletingGallery: false,
                deleteGalleryError: null,
                deleteGalleryResult:null,

                isAddNewGallery: false,
                addNewGalleryError: null,
                addNewGalleryResult:null,

            };
        case UPDATE_DISPLAY_TYPE_IN_GALLERIES_FAILURE:
            return {
                ...state,
                isUpdatingDisplayTypeInGalleries: false,
                updateDisplayTypeErrorInGalleries: action.payload
            };
        default:
            return state;
    }
}